import { Box, IBoxProps, VStack } from 'native-base';
import { map, padStart } from 'lodash';

import { Text } from '@pimm/base';
import { CalendarEvent } from '../context/calendar-events-context';
import { BadgeCalendarEvent } from './badge-calendar-event';

type DailyEventsProps = IBoxProps & {
  date: Date;
  events: CalendarEvent[];
  hideDay?: boolean;
  isSameMonth?: boolean;
};

export const DailyEvents = ({ date, events, hideDay = false, isSameMonth, ...props }: DailyEventsProps) => {
  return (
    <Box flexDirection={{ md: 'column', xl: 'row' }} px={{ md: 1.5, xl: 2 }} {...props}>
      {!hideDay && (
        <Box pt={3} pr={3}>
          <Text size="xl" color={isSameMonth ? 'black' : 'gray.500'}>
            {padStart(`${date.getDate()}`, 2, '0')}
          </Text>
        </Box>
      )}
      <VStack pt={{ md: hideDay ? 3 : 1, xl: 3 }} flex={1} space={1}>
        {map(events, (event, index) => (
          <BadgeCalendarEvent key={`${date}.${index}`} w="full" rounded="sm" date={date} event={event} />
        ))}
      </VStack>
    </Box>
  );
};
