import { HStack } from 'native-base';

import { Text } from '@pimm/base';
import { ChartStatisticsDto } from '@pimm/services/lib/store-equipment';
import { tempValue } from '@app/utils/string-formatter';

type ChartStatisticsProps = {
  statistics?: ChartStatisticsDto;
};

export const ChartStatistics = ({ statistics }: ChartStatisticsProps) => {
  return (
    <HStack space={2}>
      {[
        { title: 'Avg', value: tempValue(statistics?.Average) ?? '-' },
        { title: 'Min', value: tempValue(statistics?.Min) ?? '-' },
        { title: 'Max', value: tempValue(statistics?.Max) ?? '-' },
        { title: 'Std Dev', value: `${statistics?.Average?.toFixed(1) ?? '-'}` },
        { title: 'In-Temp', value: `${statistics?.InTempPct?.toFixed(1) ?? 0}%` },
        { title: 'Uptime', value: `${statistics?.DataUploadPct?.toFixed(1) ?? 0}%` },
      ].map((stat, index) => (
        <HStack
          key={index}
          rounded={{ base: 'md', xl: 'lg' }}
          bgColor="gray.50"
          alignItems="center"
          py={{ base: 1, xl: 2 }}
          px={{ base: 1.5, xl: 2 }}
          space={2}
        >
          <Text size={{ base: 'sm', xl: 'md' }} color="black" fontWeight={400}>
            {stat.title}
          </Text>
          <Text size={{ base: 'sm', xl: 'md' }} color="black" fontWeight={700}>
            {stat.value}
          </Text>
        </HStack>
      ))}
    </HStack>
  );
};
