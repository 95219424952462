import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { merge } from 'lodash';

import { RecursivePartial } from '@pimm/base';

export interface FormDataUserInformation {
  defaultSiteId: string;
  defaultLanguage: string;
  username: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  role: string;
  contactDetails: {
    email: string;
    mobile: string;
    phone: string;
  };
}

export const FormUserInformationSchema = yup.object({
  defaultSiteId: yup.string().required('Please choose a default site').default(''),
  defaultLanguage: yup.string().required('Please choose a language').default(''),
  username: yup.string().required('Please provide username').default(''),
  jobTitle: yup.string().notRequired().default(''),
  firstName: yup.string().required('Please provide first name').default(''),
  lastName: yup.string().required('Please provide last name').default(''),
  role: yup.string().required('Please select a role').default(''),
  contactDetails: yup.object({
    email: yup.string().email('Please provide a valid email').nullable().default(''),
    mobile: yup.string().nullable().optional(),
    phone: yup.string().nullable().optional(),
  }),
});

export type FormUserInformationProps = {
  defaultValues?: RecursivePartial<FormDataUserInformation>;
};

export const useFormUserInformation = (props?: FormUserInformationProps) => {
  return useForm({
    defaultValues: merge(FormUserInformationSchema.getDefault(), props?.defaultValues),
    mode: 'onChange',
    resolver: yupResolver(FormUserInformationSchema),
  });
};
