import { GenericState } from '@pimm/common';
import {
  AsyncThunk,
  createSlice,
  CreateSliceOptions,
  PayloadAction,
  SliceCaseReducers,
  ValidateSliceCaseReducers,
} from '@reduxjs/toolkit';
import deepmerge from 'deepmerge';
import StatusCode from 'status-code-enum';

// Documentation:
// https://redux-toolkit.js.org/api/createSlice

type GenericSliceOptions = Omit<CreateSliceOptions, 'reducers'>;
type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;

type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

export type GenericError = { code?: StatusCode; message?: string };
export type GenericStateStatus = 'idle' | 'pending' | 'fulfilled' | 'rejected';

export type GenericReducers<T> = SliceCaseReducers<GenericState<T>>

export const createGenericSlice = <
  T,
>({
  name = '',
  initialState,
  reducers,
  extraReducers,
}: {
  reducers: ValidateSliceCaseReducers<GenericState<T>, GenericReducers<T>>;
} & GenericSliceOptions): any => {
  return createSlice({
    name,
    initialState: deepmerge.all([
      { status: 'idle' },
      initialState,
    ]) as GenericState<T>,
    reducers: reducers,
    extraReducers: builder => {
      builder
        .addMatcher(
          ({ type }: PendingAction) => type === `${name}/pending`,
          (state, { meta }) => {
            state.status = meta.requestStatus;
          },
        )
        .addMatcher(
          ({ type }: RejectedAction) => type === `${name}/rejected`,
          (state, { meta, payload }) => {
            state.status = meta.requestStatus;
            state.error = payload;
          },
        )
        .addMatcher(
          ({ type }: FulfilledAction) => type === `${name}/fulfilled`,
          (state, { meta, payload }) => {
            state.status = meta.requestStatus;
            state.data = payload;
          },
        )
        .addMatcher(
          ({ type }: PendingAction) => type === 'AUTH/LOGOUT',
          state => {
            return initialState;
          },
        );
    },
  });
};
