import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { GenericError, GenericState } from '@pimm/common';
import { UserProfileDto } from '@pimm/services/lib/sms-tenants';
import { appReducer } from './slices/appSlice';
import { appConfigReducer } from './slices/appConfigSlice';
import { storeListReducer } from './slices/storeListSlice';
import { thresholdProfileReducer } from './slices/thresholdProfileSlice';
import { storeFocusReducer } from './slices/storeFocusSlice';
import { userProfileReducer } from './slices/userProfileSlice';
import { AppState, AppConfigState, StoreFocusState, StoreListState, ThresholdProfilesState } from './state';

// ReactNative AsyncStorage + Persist Implementation:
// https://blog.jscrambler.com/how-to-use-redux-persist-in-react-native-with-asyncstorage

const persistConfig = {
  // debug: true,
  key: 'pimm',
  version: 1,
  // storage: AsyncStorage,
  storage,
  // store the following state's
  whitelist: ['app', 'storeRoutine'],
};

const reducer = combineReducers({
  app: appReducer,
  appConfig: appConfigReducer,
  storeFocus: storeFocusReducer,
  storeList: storeListReducer,
  thresholdProfiles: thresholdProfileReducer,
  userProfile: userProfileReducer,
});
const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  // reducer: reducer
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// here we subscribe to the store changes
// store.subscribe(
//   // we use debounce to save the state once each 800ms
//   // for better performances in case multiple changes occur in a short time
//   debounce(() => {
//     saveState(store.getState());
//   }, 800)
// );`

// @ts-ignore
export type RootState = ReturnType<typeof store.getState>;

export type RootStore = {
  app: AppState;
  appConfig: AppConfigState;
  storeList: GenericState<StoreListState>;
  storeFocus: GenericState<StoreFocusState>;
  thresholdProfiles: GenericState<ThresholdProfilesState>;
  userProfile: GenericState<UserProfileDto>;
};

export type AsyncThunkConfig = {
  rejectValue: GenericError;
  state: RootState;
};

export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
type DispatchFunc = () => AppDispatch;
export const useAppDispatch: DispatchFunc = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootStore> = useSelector;

export default store;
