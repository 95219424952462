import { memo } from 'react';
import { Box, HStack, Pressable } from 'native-base';
import { range } from 'lodash';
import moment from 'moment';

import { Text } from '@pimm/base';
import { Employee } from '@pimm/services/lib/sms-workforce';
import { convertMinutesToHoursAndMinutes } from '@app/utils/date-formatter';

export type EmployeeWeeklyItemProps = Pick<Employee, 'id' | 'firstName' | 'lastName' | 'title'> & {
  shifts?: { startTime?: Date; endTime?: Date; duration?: number; isImport?: boolean; isMultipleSchedules?: boolean }[];
  duration?: number;
  index: number;
};

const EmployeeWeeklyItem = ({
  index,
  duration = 0,
  firstName = '',
  lastName = '',
  title = '',
  shifts = [],
  ...props
}: EmployeeWeeklyItemProps) => {
  const isEvenRow = index % 2 === 0;
  return (
    <HStack
      key={props.id}
      w="full"
      alignItems="center"
      height="60px"
      width={{ md: 1500, xl: 'full' }}
      backgroundColor={isEvenRow ? 'white' : 'gray.200'}
      borderBottomWidth={1}
    >
      <HStack
        alignItems="center"
        justifyContent="space-between"
        pl={{ md: 2, xl: 4 }}
        pr={{ md: 2, xl: 3 }}
        h="full"
        width={{ md: 200, xl: 260 }}
        borderRightWidth={1}
      >
        <Box flex={1}>
          <Text
            size="lg"
            fontWeight={600}
            color="gray.900"
            lineHeight="sm"
            textTransform="capitalize"
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {`${firstName} ${lastName}`}
          </Text>
          <Text size="sm" fontWeight={500} color="gray.600" lineHeight="xs" numberOfLines={1} ellipsizeMode="tail">
            {title}
          </Text>
        </Box>
        <Box
          flex={1}
          px={0.5}
          h={8}
          width="50px"
          justifyContent="center"
          maxWidth={54}
          borderRadius="md"
          backgroundColor="success.200"
        >
          <Text size="lg" fontWeight={700} color="black" textAlign="center" numberOfLines={1} ellipsizeMode="tail" lineHeight="xs">
            {convertMinutesToHoursAndMinutes(duration)}
          </Text>
        </Box>
      </HStack>
      {range(7).map(day => {
        const shift = shifts[day];
        let startTime, endTime;

        if (shift) {
          startTime = moment(shift.startTime);
          endTime = moment(shift.endTime);
        }

        return (
          <HStack
            key={day}
            flex={1}
            space={2}
            alignItems="center"
            px={1.5}
            h="full"
            borderRightWidth={1}
            bg={shift && (shift.isMultipleSchedules ? 'red.500' : !shift.isImport && 'warning.200') || undefined}
          >
            {/* Pressable start time */}
            <Pressable flex={1} h={9}>
              <Box h="full" justifyContent="center" borderRadius="md" borderWidth={1} backgroundColor="white">
                {startTime ? (
                  <Text size="md" fontWeight={600} color="gray.900" textAlign="center" lineHeight="2xs">
                    {startTime.format('h:mm')}{' '}
                    <Text size="sm" fontWeight={500} color="gray.500" textAlign="center" lineHeight="2xs">
                      {startTime.format('A')}
                    </Text>
                  </Text>
                ) : (
                  <Text size="sm" fontWeight={500} color="gray.500" textAlign="center" lineHeight="xs">
                    Start
                  </Text>
                )}
              </Box>
            </Pressable>
            {/* Pressable end time */}
            <Pressable flex={1} h={9}>
              <Box h="full" justifyContent="center" borderRadius="md" borderWidth={1} backgroundColor="white">
                {endTime ? (
                  <Text size="md" fontWeight={600} color="gray.900" textAlign="center" lineHeight="2xs">
                    {endTime.format('h:mm')}{' '}
                    <Text size="sm" fontWeight={500} color="gray.500" textAlign="center" lineHeight="2xs">
                      {endTime.format('A')}
                    </Text>
                  </Text>
                ) : (
                  <Text size="sm" fontWeight={500} color="gray.500" textAlign="center">
                    End
                  </Text>
                )}
              </Box>
            </Pressable>
            <Box flex={1} h={9} maxWidth={50} justifyContent="center" borderRadius="md" backgroundColor="blue.200">
              <Text size="md" fontWeight={700} color="black" textAlign="center" numberOfLines={1} ellipsizeMode="tail" lineHeight="xs">
                {convertMinutesToHoursAndMinutes(shift?.duration ?? 0)}
              </Text>
            </Box>
          </HStack>
        );
      })}
    </HStack>
  );
};

export default memo(EmployeeWeeklyItem);
