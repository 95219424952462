import { Box, HStack, Progress, VStack, useTheme } from 'native-base';
import { get, last } from 'lodash';
import moment from 'moment';

import { ArrowSquareUpRightIcon, Button, ConnectedDevicesIcon, Text } from '@pimm/base';
import { formatToShortDateOnly, formatToTimeOnly } from '@app/utils/date-formatter';
import { EmpLearningPathStatusEnum, LearningPathStatDto } from '@pimm/services/lib/sms-workforce';
import { getLearningPathMetaStats, learningPathProgressValue } from '../_helper';
import { LearningPathBadge } from './learning-path-badge';

type LearningPathCardProps = {
  learningPath: LearningPathStatDto;
};

export const LearningPathCard = ({ learningPath }: LearningPathCardProps) => {
  const { colors } = useTheme();

  const progress = learningPathProgressValue(learningPath.learningPathProgress);
  const stats = getLearningPathMetaStats(learningPath.learningStatus);
  const lastActivityTime = learningPath.lastItemCompleteDateTimeUtc
    ? moment.utc(learningPath.lastItemCompleteDateTimeUtc).local().toDate()
    : undefined;

  const handlePressNewTab = (url?: string) => () => {
    window.open(url, '_blank', 'noreferrer');
  };

  return (
    <Box
      rounded="xl"
      pt={3}
      pb={4}
      px={4}
      borderColor="gray.200"
      borderWidth={1}
      bg="white"
      style={{
        shadowColor: colors.gray[500],
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.4,
        shadowRadius: 1,
      }}
    >
      <HStack alignItems="center" justifyContent="space-between">
        <Text size="xl" fontWeight={600} color="black" lineHeight="xs">
          {learningPath.learningPath ?? ''}
        </Text>

        <Button
          onPress={handlePressNewTab(learningPath.learningPathUrl)}
          alternate
          outline
          minH={9}
          endIcon={<ArrowSquareUpRightIcon size="sm" color="black" />}
        >
          {stats.actionText}
        </Button>
      </HStack>

      <HStack space={2} alignItems="center">
        <LearningPathBadge status={learningPath.learningStatus ?? EmpLearningPathStatusEnum.Pending} />
        <Progress
          flex={1}
          focusable={false}
          value={progress}
          w="full"
          maxWidth="80px"
          _filledTrack={{
            bg: get(colors, stats.fillColor),
          }}
        />
        <Box w={8}>
          <Text size="md" fontWeight={400} color="gray.600">{`${Math.round(progress)}%`}</Text>
        </Box>
      </HStack>

      {learningPath.lastItemName && (
        <Box>
          <Text size="md" fontWeight={600} color="gray.600" lineHeight="xs" mt={3}>
            Last completed activity
          </Text>

          <VStack space={2} mt={2}>
            <HStack rounded="lg" alignItems="center" justifyContent="space-between" py={2} px={3} borderWidth={1}>
              <Box>
                <Text size="md" fontWeight={600} color="black" lineHeight="md">
                  {learningPath.lastCourseName}
                </Text>
                <HStack space={1} alignItems="center" minH={5}>
                  <ConnectedDevicesIcon size="sm" color={colors.gray[500]} />
                  <Text size="md" fontWeight={400} color="black" lineHeight="xs" mt={1}>
                    {learningPath.lastItemName}
                  </Text>
                </HStack>
              </Box>

              {!!lastActivityTime && (
                <Text size="md" fontWeight={500} color="gray.700" lineHeight="xs" textAlign="center">
                  {formatToShortDateOnly(lastActivityTime)}
                  {`\n`}
                  {formatToTimeOnly(lastActivityTime)}
                </Text>
              )}
            </HStack>
          </VStack>
        </Box>
      )}
    </Box>
  );
};
