import { Fragment } from 'react';

import { Modal } from '@pimm/base';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { DayBlock, useSiteTime } from '@app/features/store-core';
import { DailyStaffReview } from '@app/features/kitchen-positioning';

type ModalStaffReviewProps = {
  dayBlock: DayBlock;
  siteId: string;
  onNavigate?: () => void;
  onReset?: () => void;
  trigger: (props: { onPress: () => void }) => React.ReactNode;
};

export const ModalStaffReview = ({ dayBlock, siteId, trigger, ...props }: ModalStaffReviewProps) => {
  const siteTime = useSiteTime();
  const modal = useModalFocus();

  const dayBlocks = siteTime.toDayBlocks();
  const isEnabled = dayBlocks[0] && dayBlock.startTime >= dayBlocks[0].startTime;

  const handleNavigateTo = () => {
    modal.setHide();
    if (props.onNavigate) props.onNavigate();
  };

  const handleCloseAndReset = (reset?: boolean) => {
    if (reset && props.onReset) props.onReset();
    modal.setHide();
  };

  return (
    <Fragment>
      {isEnabled && trigger({ onPress: modal.setOpen })}
      <Modal
        size="full"
        isOpen={modal.isOpen}
        hideClose
        noPadding
        _content={{
          height: '95%',
          width: '96%',
          maxWidth: dayBlocks.length < 5 ? 1200 : undefined,
          maxHeight: 720,
        }}
      >
        <DailyStaffReview selectedDate={dayBlock.startTime} siteId={siteId} onNavigate={handleNavigateTo} onClose={handleCloseAndReset} />
      </Modal>
    </Fragment>
  );
};
