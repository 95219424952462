import { useEffect, useState } from 'react';
import { Checkbox, HStack, useTheme } from 'native-base';
import { chain, identity } from 'lodash';

import { Text } from '@pimm/base';
import { CalendarEventType } from '../context/calendar-events-context';
import { CalendarEventColor } from '../_helper';

const CheckInputs: {
  label: string;
  value: CalendarEventType;
}[] = [
  { label: 'Birthdays', value: 'Birthday' },
  { label: 'Anniversaries', value: 'Anniversary' },
];

export type FilterByEvents = Record<CalendarEventType, boolean>;

export const FilterToolEvents = (props: { onChange?: (eventTypes: CalendarEventType[]) => void }) => {
  const { colors } = useTheme();
  const [inputValues, setInputValues] = useState<FilterByEvents>({
    Anniversary: true,
    Birthday: true,
  });

  const handleChange = value => isSelected => {
    setInputValues({ ...inputValues, [value]: isSelected });
  };

  useEffect(() => {
    if (props.onChange) {
      const checkedValues = chain(inputValues).pickBy(identity).keys().value();
      props.onChange(checkedValues as CalendarEventType[]);
    }
  }, [inputValues]);

  return (
    <HStack alignItems="center" space={4}>
      {CheckInputs.map(input => {
        const color = colors[CalendarEventColor[input.value]];
        return (
          <Checkbox
            key={input.value}
            value={input.value}
            isChecked={inputValues[input.value] || false}
            borderWidth={1}
            _important={{
              borderColor: color[500],
              backgroundColor: color[100],
            }}
            _icon={{
              borderColor: color[500],
              color: color[500],
            }}
            onChange={handleChange(input.value)}
          >
            <Text size="md" fontWeight={500} color="gray.700">
              {input.label}
            </Text>
          </Checkbox>
        );
      })}
    </HStack>
  );
};
