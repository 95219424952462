import { GenericState } from '@pimm/common';
import { ASYNC_THRESHOLD_PROFILES } from '../consts';
import { ThresholdProfilesState } from '../state';
import { createGenericSlice } from './genericSlice';

export const ThresholdProfileStateDefault: GenericState<ThresholdProfilesState> =
  {
    status: 'idle',
    data: {
      eventTypes: [],
    },
  };

const thresholdProfileSlice = createGenericSlice({
  name: ASYNC_THRESHOLD_PROFILES,
  initialState: ThresholdProfileStateDefault,
  reducers: {},
});

export const thresholdProfileReducer = thresholdProfileSlice.reducer;
