import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { RecursivePartial } from '@pimm/base';
import { SiteGoalDto } from '@pimm/services/lib/sms-drivethru/types';

export interface FormSiteGoalItem {
  daypart: {
    label: string;
    partNumber: number;
    dpStartTime: string;
  };
  goal: SiteGoalDto;
}

export interface FormDataSiteGoals {
  isSevenDays: boolean
  weekdays: FormSiteGoalItem[];
  weekends: FormSiteGoalItem[];
}

export const SiteGoalDailySchema = yup.object().shape({
  name: yup.string(),
  partNumber: yup.number(),
  profile: yup.string(),
  goalProfileId: yup.number().optional(),
  goalProfileName: yup.string(),
  greet: yup.number().default(0),
  menuBoard: yup.number().default(0),
  pickup: yup.number().default(0),
  total: yup.number().default(0),
});

export const FormSiteGoalsSchema = yup.object({
  isSevenDays: yup.boolean().default(true),
  weekdays: yup.array().of(SiteGoalDailySchema).default([]),
  weekends: yup.array().of(SiteGoalDailySchema).default([]),
});

export type FormSiteGoalsProps = {
  defaultValues?: RecursivePartial<FormDataSiteGoals>;
};

export const useFormSiteGoals = (props?: FormSiteGoalsProps) => {
  return useForm<FormDataSiteGoals>({
    defaultValues: { ...FormSiteGoalsSchema.getDefault(), ...props?.defaultValues },
    resolver: yupResolver(FormSiteGoalsSchema),
  });
};
