import { useBoolean } from 'usehooks-ts';
import { Box, ChevronDownIcon, ChevronUpIcon, HStack, IconButton, useBreakpointValue, VStack } from 'native-base';
import Feather from '@expo/vector-icons/Feather';
import { map } from 'lodash';

import { Text } from '@pimm/base';
import { SensorEquipment, SensorGroup } from '../context';
import { GraphDiagnostics } from './graph-diagnostics';
import { GraphPerformance } from './graph-performance';
import { SensorCard } from './sensor-card';

type CollapsibleSensorsProps = {
  equipment?: SensorGroup['equipment'];
  isPerformance?: boolean;
  title: string;
  deviceType?: string;
  onOpenModal?: (_equipment: SensorEquipment, isDoor?: boolean) => void;
};

export const CollapsibleSensors = ({ equipment, isPerformance, title, deviceType, ...props }: CollapsibleSensorsProps) => {
  const cols = useBreakpointValue({ sm: 4, xl: 5 });
  const isCollapsed = useBoolean(true);

  const handleOpenModal = (_equipment: SensorEquipment, isDoor?: boolean) => () => {
    if (props.onOpenModal) props.onOpenModal(_equipment, isDoor);
  };

  return (
    <VStack w="full">
      <HStack alignItems="center" justifyContent="space-between" px={1.5} minH={9}>
        <Text size="xl" fontWeight={700} color="gray.900" lineHeight="xl">
          {title}
        </Text>

        <IconButton
          p={1}
          _pressed={{ bg: 'gray.100' }}
          onPress={isCollapsed.toggle}
          icon={isCollapsed.value ? <ChevronUpIcon size={4} color="black" /> : <ChevronDownIcon size={4} color="black" />}
        />
      </HStack>

      {isCollapsed.value && (
        <HStack w="full" flexWrap="wrap">
          {map(equipment, _equipment => {
            if (!_equipment.temperature) return null;
            const hasDoor = !isPerformance && !!_equipment.door;
            return (
              <HStack
                key={`${title}.${_equipment.temperature?.TemperatureInstanceId}`}
                position="relative"
                justifyContent="center"
                w={`${hasDoor ? 2 : 1}/${cols}`}
              >
                {/* Temperature Sensor */}
                <Box p={1.5} w={hasDoor ? '1/2' : 'full'}>
                  <SensorCard
                    deviceType={deviceType}
                    equipment={_equipment}
                    isPerformance={isPerformance}
                    onOpenModal={handleOpenModal(_equipment)}
                  >
                    {isPerformance ? (
                      <GraphPerformance
                        _container={{ justifyContent: 'center', px: { base: 2, xl: 4 }, minH: { base: '120px', xl: '160px' } }}
                        sensor={_equipment}
                      />
                    ) : (
                      <HStack>
                        <GraphDiagnostics _container={{ px: { base: 2, xl: 4 } }} sensor={_equipment.temperature} />
                      </HStack>
                    )}
                  </SensorCard>
                </Box>

                {hasDoor && (
                  <Box p={1.5} w="1/2">
                    <SensorCard deviceType={deviceType} equipment={_equipment} isDoor onOpenModal={handleOpenModal(_equipment, true)}>
                      <GraphDiagnostics _container={{ px: { base: 2, xl: 4 } }} sensor={_equipment.door!} />
                    </SensorCard>
                  </Box>
                )}

                {hasDoor && (
                  <VStack position="absolute" top={10} w={10} h={5} borderRadius={20} bgColor="gray.50">
                    <Box flex={1} m="2px" borderRadius={20} bgColor="gray.900" alignItems="center" justifyContent="center">
                      <Feather name="link-2" size={12} color="white" />
                    </Box>
                  </VStack>
                )}
              </HStack>
            );
          })}
        </HStack>
      )}
    </VStack>
  );
};
