import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { keyBy } from 'lodash';

import { KitchenPositionDto } from '@pimm/services/lib/sms-positioning';
import { useGetKitchenLayout } from '../hooks/get-kitchen-layout.hook';

interface KitchenLayoutContextReturn {
  kitchenLayout: ReturnType<typeof useGetKitchenLayout>[0];
  positionLookup: Record<string, KitchenPositionDto>;
}

type KitchenLayoutProviderProps = {
  children: React.ReactNode;
  kitchenLayoutId?: string;
  siteId?: string;
};

const KitchenLayoutContext = createContext<KitchenLayoutContextReturn>(undefined!);

export const KitchenLayoutProvider = ({ children, kitchenLayoutId, siteId }: KitchenLayoutProviderProps) => {
  const [kitchenLayout, setKitchenParams] = useGetKitchenLayout();
  const positionLookup = useMemo(() => {
    return keyBy(kitchenLayout.data?.availablePositions, 'positionId');
  }, [kitchenLayout.data]);

  useEffect(() => {
    if (kitchenLayoutId && siteId) setKitchenParams({ id: kitchenLayoutId, siteId: siteId });
  }, [kitchenLayoutId, siteId]);

  return <KitchenLayoutContext.Provider value={{ kitchenLayout, positionLookup }}>{children}</KitchenLayoutContext.Provider>;
};

export const KitchenLayoutConsumer = KitchenLayoutContext.Consumer;

export const useKitchenLayout = () => useContext(KitchenLayoutContext);
