import { useBoolean } from 'usehooks-ts';
import { Pressable, HStack, Text, Popover, ChevronDownIcon, Spacer, VStack } from 'native-base';
import { find, map } from 'lodash';

import { NumberText } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { CurrencyDollarCircle } from '@app/features/flow-chart/icons';
import { useGetBrandSalesVolumeProfiles } from '../hooks';

type SelectSalesVolumeProfilesProps = {
  opsPhaseTitle?: string;
  salesVolumeProfileId?: number;
  salesVolumeProfiles: ReturnType<typeof useGetBrandSalesVolumeProfiles>[0];
  onChange?: (salesVolumeProfileId: number | undefined) => void;
};

export const SelectSalesVolumeProfiles = ({
  opsPhaseTitle,
  salesVolumeProfiles,
  salesVolumeProfileId,
  ...props
}: SelectSalesVolumeProfilesProps) => {
  const { translate } = useAppLocale();
  const isOpenPopover = useBoolean();
  const salesVolumeProfile = find(salesVolumeProfiles.data, ['id', salesVolumeProfileId]);

  const handleChange = (profileId: number) => () => {
    if (salesVolumeProfileId !== profileId && props.onChange) props.onChange(profileId);
    isOpenPopover.setFalse();
  };
  return (
    <Popover
      isKeyboardDismissable
      offset={5}
      isOpen={isOpenPopover.value}
      onClose={isOpenPopover.setFalse}
      placement="bottom right"
      trigger={triggerProps => (
        <Pressable
          {...triggerProps}
          onPress={isOpenPopover.setTrue}
          rounded="lg"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          px={2}
          h={9}
          minW={120}
          w={{ xl: 160 }}
          borderWidth={1}
          borderColor="gray.300"
          bg="white"
        >
          <HStack space={2} alignItems="center">
            <CurrencyDollarCircle color="primary.700" size={4} />
            <Text fontWeight={500} color="black" fontSize="lg">
              {translate(salesVolumeProfile?.category, salesVolumeProfile?.translations) ?? 'Select Category'}
            </Text>
          </HStack>
          <Spacer />
          <ChevronDownIcon color="gray.700" size="xs" />
        </Pressable>
      )}
    >
      <Popover.Content accessibilityLabel="Sales Volume Profile Selector" shadow="1">
        <Popover.Body p={0} w="auto" minWidth="220px" borderColor="gray.100">
          <VStack px={3} py={2} borderBottomWidth={1} borderBottomColor="gray.200">
            <Text fontWeight={600} color="gray.700" textAlign="center" fontSize="md">
              Select a Sales Volume for:
            </Text>
            <Text fontWeight={600} color="black" textAlign="center" fontSize="md">
              {opsPhaseTitle ?? 'Phase Not Available'}
            </Text>
          </VStack>
          <VStack>
            {map(salesVolumeProfiles.data, profile => (
              <Pressable key={profile.id} onPress={handleChange(profile.id ?? 0)}>
                {({ isHovered }) => {
                  const isActive = profile.id === salesVolumeProfileId;
                  return (
                    <HStack alignItems="center" py={2} px={4} bg={isHovered ? 'gray.50' : undefined} justifyContent="space-between">
                      <Text
                        fontWeight={isActive ? 700 : 500}
                        color={isActive ? 'black' : 'gray.700'}
                        textTransform="capitalize"
                        fontSize="md"
                      >
                        {translate(profile.category, profile?.translations)}
                      </Text>
                      <NumberText
                        size="sm"
                        fontWeight={isActive ? 700 : 400}
                        color={isActive ? 'black' : 'gray.500'}
                        prefix="$"
                        value={profile?.minimumAmount}
                      />
                    </HStack>
                  );
                }}
              </Pressable>
            ))}
          </VStack>
        </Popover.Body>
      </Popover.Content>
    </Popover>
  );
};
