import { QueryKey, UseQueryResult, useQuery } from 'react-query';

import { RollupDateRange } from '@pimm/services/lib/store-equipment';
import { GetSensorSummary } from '@pimm/services/lib/store-equipment/services';

export type GetSensorSummaryParams = {
  equipmentId?: string;
  siteId?: string;
  range?: RollupDateRange;
};

export const useGetSensorSummary = (
  query?: GetSensorSummaryParams,
  queryKey?: string,
): UseQueryResult<Awaited<ReturnType<typeof GetSensorSummary>>> => {
  const sensorSummary = useQuery({
    enabled: !!query?.siteId && !!query?.range,
    queryKey: [queryKey ?? 'GetSensorSummary', query] as [QueryKey, GetSensorSummaryParams],
    queryFn: ({ queryKey: [key, params] }) =>
      GetSensorSummary(params.siteId!, {
        range: params.range!,
        EquipmentId: params.equipmentId,
      }),
  });
  return sensorSummary;
};
