import { Box } from 'native-base';
import { Text } from '@pimm/base';

export const BadgeCount = ({
  count = 0,
  color,
}: {
  count: number;
  color: 'red' | 'yellow';
}) => (
  <Box
    px={1}
    w="full"
    h={4}
    justifyContent="center"
    backgroundColor={color === 'red' ? 'red.500' : 'yellow.400'}
    rounded="sm"
  >
    <Text size="sm" color="white" fontWeight={700} textAlign="center" lineHeight="xs">
      {count}
    </Text>
  </Box>
);
