import { Dispatch, SetStateAction, useState } from 'react';
import { QueryKey, UseQueryResult, useQuery } from 'react-query';

import { GetBrandSalesVolumeProfiles } from '@pimm/services/lib/sms-tenants/services';
import { GetBrandSalesVolumeProfilesQueryParams } from '@pimm/services/lib/sms-tenants';

export const useGetBrandSalesVolumeProfiles = (props?: {
  queryKey?: string;
  query: GetBrandSalesVolumeProfilesQueryParams;
}): [UseQueryResult<Awaited<ReturnType<typeof GetBrandSalesVolumeProfiles>>>, Dispatch<SetStateAction<GetBrandSalesVolumeProfilesQueryParams>>] => {
  const [queryKey] = useState<string>(props?.queryKey ?? 'GetBrandSalesVolumeProfiles');
  const [query, setQuery] = useState<GetBrandSalesVolumeProfilesQueryParams>({ ...props?.query });

  const salesVolumeProfiles = useQuery({
    enabled: !!query.brandId && !!query.marketId,
    queryKey: [queryKey, query] as [QueryKey, GetBrandSalesVolumeProfilesQueryParams],
    queryFn: ({ queryKey: [, params] }) => GetBrandSalesVolumeProfiles(params),
  });

  return [salesVolumeProfiles, setQuery];
};
