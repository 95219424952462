import { QueryKey, UseQueryResult, useQuery } from 'react-query';

import { GetSiteGoals } from '@pimm/services/lib/sms-drivethru/services';

export const useGetSiteGoals = (siteId?: string, queryKey?: string): UseQueryResult<Awaited<ReturnType<typeof GetSiteGoals>>> => {
  const siteGoals = useQuery({
    enabled: !!siteId,
    queryKey: [queryKey ?? 'GetSiteGoals', siteId] as [QueryKey, string],
    queryFn: ({ queryKey: [key, siteId] }) => GetSiteGoals({ siteId }),
  });

  return siteGoals;
};
