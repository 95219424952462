import { useEffect, useState } from 'react';
import { Box, HStack } from 'native-base';
import { isUndefined, padStart } from 'lodash';
import moment, { Duration } from 'moment';

import { Text } from '@pimm/base';
import { DayBlock, useSiteTime } from '@app/features/store-core';

export const DayblockCountdown = () => {
  const siteTime = useSiteTime();
  const [dayBlock, setDayBlock] = useState<DayBlock>();
  const [countdown, setCountdown] = useState<Duration>();

  useEffect(() => {
    if (!isUndefined(siteTime.utcOffsetInMinutes)) {
      setDayBlock(siteTime.toDayBlockNow());
    }
  }, [siteTime.utcOffsetInMinutes]);

  // Responsible for countdown effect
  useEffect(() => {
    let interval;

    if (dayBlock) {
      interval = setInterval(() => {
        const diff = dayBlock.endTime!.getTime() - siteTime.today().getTime();
        const duration: Duration = moment.duration(diff, 'milliseconds');

        if (duration.asMilliseconds() > 0) {
          setCountdown(duration);
        } else {
          clearInterval(interval);
          setDayBlock(siteTime.toDayBlockNow());
        }
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [dayBlock]);

  if (!dayBlock) return null;

  return (
    <HStack alignItems="center" space={2}>
      <Text color="white" size="md" fontWeight={800}>
        {dayBlock?.title?.toUpperCase()}
      </Text>
      <Text color="white" size="md" fontWeight={500}>
        ends in
      </Text>
      <HStack space={0.5} alignItems="center">
        <Box w={6} h={6} borderRadius="md" justifyContent="center" bgColor="white">
          <Text fontWeight={700} color="black" textAlign="center" lineHeight="xs">
            {padStart(`${countdown?.hours() ?? ''}`, 2, '0')}
          </Text>
        </Box>
        <Text color="white" size="md" fontWeight={700}>
          :
        </Text>
        <Box w={6} h={6} borderRadius="md" justifyContent="center" bgColor="white">
          <Text fontWeight={700} color="black" textAlign="center" lineHeight="xs">
            {padStart(`${countdown?.minutes() ?? ''}`, 2, '0')}
          </Text>
        </Box>
        <Text color="white" size="md" fontWeight={700}>
          :
        </Text>
        <Box w={6} h={6} borderRadius="md" justifyContent="center" bgColor="white">
          <Text fontWeight={700} color="black" textAlign="center" lineHeight="xs">
            {padStart(`${countdown?.seconds() ?? ''}`, 2, '0')}
          </Text>
        </Box>
      </HStack>
    </HStack>
  );
};
