import { Icon, IIconProps } from 'native-base';
import { Path } from 'react-native-svg';

export const BatteryLowIcon = ({ size = 'sm', ...props }: IIconProps) => {
  return (
    <Icon size={size} viewBox="0 0 24 25" fill="none" {...props}>
      <Path
        d="M6.5 10.7034V14.7034M22 13.7034V11.7034M6.8 18.7034H14.2C15.8802 18.7034 16.7202 18.7034 17.362 18.3764C17.9265 18.0888 18.3854 17.6298 18.673 17.0653C19 16.4236 19 15.5835 19 13.9034V11.5034C19 9.82321 19 8.98313 18.673 8.3414C18.3854 7.77691 17.9265 7.31797 17.362 7.03035C16.7202 6.70337 15.8802 6.70337 14.2 6.70337H6.8C5.11984 6.70337 4.27976 6.70337 3.63803 7.03035C3.07354 7.31797 2.6146 7.77691 2.32698 8.3414C2 8.98313 2 9.82321 2 11.5034V13.9034C2 15.5835 2 16.4236 2.32698 17.0653C2.6146 17.6298 3.07354 18.0888 3.63803 18.3764C4.27976 18.7034 5.11984 18.7034 6.8 18.7034Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Icon>
  );
};
