import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { HStack, VStack, View, IconButton } from 'native-base';
import { isEmpty, map } from 'lodash';

import { ButtonGroup, LightBulbIcon, Modal, Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { OpsPhaseProfileDto } from '@pimm/services/lib/sms-workforce';
import { MainScreenProps } from '@app/navigations/root';
import { formatToShortTimeOnly, stringToDateLocal } from '@app/utils/date-formatter';
import { MainContainer } from '@app/components/core';
import { ModuleTitle } from '@app/features/app';
import { useSiteConfig, useSiteTime } from '@app/features/store-core';
import {
  FlowChartChecklist,
  FlowChartChecklistProvider,
  SelectConfirmSalesVolumeProfile,
  SelectSalesVolumeProfiles,
  useGetBrandSalesVolumeProfiles,
  useGetOpsTasksFlowChartAggLive,
} from '@app/features/flow-chart';
import { ResourceLoader } from '@app/components/shared';
import { ModalAppDocuments } from '@app/features/store-info/components';
import { useModalFocus } from '@app/hooks/modal-focus.hook';

export type FlowChartScreenProps = MainScreenProps<'FlowChart'>;

export const FlowChartScreen: React.FC<FlowChartScreenProps> = ({ navigation, route }) => {
  const queryClient = useQueryClient();
  const { translate } = useAppLocale();
  const { siteConfig } = useSiteConfig();
  const siteTime = useSiteTime();
  const modalConfirm = useModalFocus<number>();
  const [flowChartAgg, setFlowChartAggParams] = useGetOpsTasksFlowChartAggLive();
  const [salesVolumeProfiles, setSalesVolumeProfilesParams] = useGetBrandSalesVolumeProfiles();
  const [opsPhaseProfiles, setOpsPhaseProfiles] = useState<OpsPhaseProfileDto[]>([]);
  const [opsPhaseFocusId, setOpsPhaseFocusId] = useState<number | undefined>(
    route.params?.opsPhaseId ? Number(route.params.opsPhaseId) : undefined,
  );

  const activeOpsPhaseId = opsPhaseFocusId || flowChartAgg.data?.flowChartData?.opsPhaseId;
  const startDateTime = stringToDateLocal(flowChartAgg.data?.flowChartData?.startDateTime);
  const isDisabled = !startDateTime || siteTime.today() < new Date(startDateTime.getTime() - 60 * 60 * 1000);

  const handleConfirmRefetch = () => {
    queryClient.invalidateQueries({ queryKey: ['GetOpsTasksFlowChartAggLive'] });
    flowChartAgg.refetch();
  };

  const handleChangeFocusOpsPhaseId = (opsPhaseId: number) => {
    // Remember the last known selected opsPhaseId
    navigation.setParams({ ...route.params, opsPhaseId: opsPhaseId });
    setOpsPhaseFocusId(opsPhaseId);
  };

  useEffect(() => {
    if (siteConfig) {
      setSalesVolumeProfilesParams({
        brandId: siteConfig.companyInfo?.brand?.id,
        marketId: siteConfig.config?.marketId,
      });
    }
  }, [siteConfig]);

  useEffect(() => {
    if (siteConfig.id) {
      setFlowChartAggParams({
        opsPhaseId: opsPhaseFocusId,
        siteId: siteConfig.id,
      });
    }
  }, [opsPhaseFocusId, siteConfig?.id]);

  useEffect(() => {
    if (flowChartAgg.data?.opsPhaseProfiles?.length) {
      setOpsPhaseProfiles(flowChartAgg.data.opsPhaseProfiles);
    }
  }, [flowChartAgg.data?.opsPhaseProfiles]);

  return (
    <MainContainer>
      <VStack w="full" h="full">
        <HStack px={4} height={60} alignItems="center" borderWidth={1}>
          <HStack flex={1} alignItems="center">
            <ModuleTitle name={route.name} defaultText="Flow Chart" />
            <ModalAppDocuments
              identifierName="Module.OpsTasks"
              moduleType="Ops Tasks"
              trigger={triggerProps => {
                if (!triggerProps.documents?.length) return undefined;
                return (
                  <IconButton
                    rounded="lg"
                    borderWidth={1}
                    borderColor="gray.300"
                    ml={2}
                    p={0}
                    w={9}
                    h={9}
                    icon={<LightBulbIcon size="md" />}
                    onPress={triggerProps.onPress}
                  />
                );
              }}
            />
          </HStack>

          {!isEmpty(opsPhaseProfiles) && (
            <ButtonGroup value={activeOpsPhaseId} onChange={handleChangeFocusOpsPhaseId}>
              {map(opsPhaseProfiles, opsPhase => {
                const isActive = opsPhase.opsPhaseId === activeOpsPhaseId;
                const startTime = stringToDateLocal(opsPhase.rangeStartTime);
                return (
                  <ButtonGroup.Item key={opsPhase.opsPhaseId} value={opsPhase.opsPhaseId}>
                    <View flexDirection={{ md: 'column', xl: 'row' }} alignItems="center">
                      <Text
                        color={isActive ? 'black' : 'gray.500'}
                        fontWeight={isActive ? 700 : 600}
                        size={{ md: 'sm', xl: 'md' }}
                        lineHeight="xs"
                      >
                        {translate(opsPhase.phase, opsPhase?.translations)}
                      </Text>
                      <View
                        px={{ xl: 1 }}
                        ml={{ xl: 1 }}
                        borderWidth={{ xl: 1 }}
                        borderRadius={4}
                        borderColor={isActive ? 'black' : 'gray.300'}
                        bg={{ xl: isActive ? 'black' : 'gray.200' }}
                      >
                        <Text
                          size="xs"
                          fontWeight={600}
                          color={{ xl: isActive ? 'white' : 'gray.500', base: isActive ? 'gray.600' : 'gray.500' }}
                        >
                          {formatToShortTimeOnly(startTime)}
                        </Text>
                      </View>
                    </View>
                  </ButtonGroup.Item>
                );
              })}
            </ButtonGroup>
          )}

          <HStack flex={1} space={3} alignItems="center" justifyContent="flex-end">
            {salesVolumeProfiles.isSuccess && !!flowChartAgg.data?.flowChartData && (
              <SelectSalesVolumeProfiles
                opsPhaseTitle={flowChartAgg.data?.flowChartData?.opsPhase?.phase}
                salesVolumeProfileId={flowChartAgg.data?.flowChartData?.salesVolumeProfileId}
                salesVolumeProfiles={salesVolumeProfiles}
                onChange={modalConfirm.setOpen}
              />
            )}
          </HStack>
        </HStack>
        <View flex={1}>
          <ResourceLoader h="full" w="full" isLoading={flowChartAgg.isIdle || flowChartAgg.isLoading}>
            {!!siteConfig.id && (
              <FlowChartChecklistProvider opsTaskFlowChartAgg={flowChartAgg} siteId={siteConfig.id}>
                <FlowChartChecklist isDisabled={isDisabled} />
              </FlowChartChecklistProvider>
            )}
          </ResourceLoader>
        </View>
      </VStack>

      <Modal
        hideClose
        isOpen={modalConfirm.isOpen}
        onClose={modalConfirm.setHide}
        size="md"
        _content={{ rounded: 'xl', p: 5, pt: 4, maxW: 400 }}
      >
        <SelectConfirmSalesVolumeProfile
          opsPhaseId={flowChartAgg.data?.flowChartData?.opsPhaseId}
          salesVolumeProfileId={modalConfirm.payload}
          siteId={siteConfig.id}
          confirmRefetch={handleConfirmRefetch}
          onClose={modalConfirm.setHide}
        />
      </Modal>
    </MainContainer>
  );
};
