import { Icon, IIconProps } from 'native-base';
import { Path } from 'react-native-svg';

export const OutlineBadgeCheckIcon = ({ size = 'sm', ...props }: IIconProps) => {
  return (
    <Icon size={size} viewBox="0 0 12 13" fill="none" {...props}>
      <Path
        d="M4.33346 6.5L5.44457 7.61111L7.66679 5.38889M3.68609 2.4428C4.08472 2.41099 4.46315 2.25424 4.76752 1.99486C5.47779 1.38957 6.52245 1.38957 7.23272 1.99486C7.53709 2.25424 7.91553 2.41099 8.31416 2.4428C9.2444 2.51704 9.98309 3.25573 10.0573 4.18597C10.0891 4.5846 10.2459 4.96303 10.5053 5.2674C11.1106 5.97767 11.1106 7.02233 10.5053 7.7326C10.2459 8.03697 10.0891 8.41541 10.0573 8.81404C9.98309 9.74428 9.2444 10.483 8.31416 10.5572C7.91553 10.589 7.53709 10.7458 7.23272 11.0051C6.52245 11.6104 5.47779 11.6104 4.76752 11.0051C4.46315 10.7458 4.08472 10.589 3.68609 10.5572C2.75585 10.483 2.01716 9.74428 1.94293 8.81404C1.91112 8.41541 1.75436 8.03697 1.49498 7.7326C0.889694 7.02233 0.889694 5.97767 1.49498 5.2674C1.75436 4.96303 1.91112 4.5846 1.94293 4.18597C2.01716 3.25573 2.75585 2.51704 3.68609 2.4428Z"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Icon>
  );
};