import { HStack, VStack } from 'native-base';

import { Text } from '@pimm/base';
import { parseDuration } from '../_helper';

type TimeInputsProps = {
  value?: string;
};

export const TimeInputs = ({ value }: TimeInputsProps) => {
  const values = parseDuration(value);
  return (
    <HStack space={{ base: 1, xl: 1.5 }}>
      {[{ label: 'dd' }, { label: 'hh' }, { label: 'mm' }].map((_, i) => {
        return (
          <VStack key={_.label} rounded="md" alignItems="center" justifyContent="center" py={{ base: 1, xl: 1 }} flex={1} bg="white">
            <Text size="sm" fontWeight={400} color="black" lineHeight="md" textTransform="uppercase">
              {_.label}
            </Text>
            <Text size="md" fontWeight={700} color="black" lineHeight="xs">
              {values[i] ?? '00'}
            </Text>
          </VStack>
        );
      })}
    </HStack>
  );
};
