import { useMemo } from 'react';
import { Box, HStack, VStack } from 'native-base';
import { find, get, map, range } from 'lodash';
import moment from 'moment';

import { Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { PositionInsightDto } from '@pimm/services/lib/sms-workforce';
import { DayBlock, useSiteTime } from '@app/features/store-core';
import { stringToDateLocal } from '@app/utils/date-formatter';
import { assignmentMapping } from '../_helper';
import { ProgressStatus } from './progress-status';

type WeeklyAssignmentsProps = {
  dayBlocks: DayBlock[];
  startOfWeek: Date;
  positionInsights?: PositionInsightDto[];
};

export const WeeklyAssignments = ({ startOfWeek, positionInsights, ...props }: WeeklyAssignmentsProps) => {
  const siteTime = useSiteTime();
  const { locale, translate } = useAppLocale();

  const today = siteTime.today();

  const blocks = useMemo(() => {
    const startDate = moment(startOfWeek);
    const weekSummary = range(7).map(day => {
      const dt = moment(startOfWeek).add(day, 'day');
      const dayBlocks = siteTime.toDayBlocks(dt.toDate());

      const insights = dayBlocks.map(_dayBlock => {
        const insight = find(positionInsights, _ => {
          const startTime = stringToDateLocal(_.blockStartDateTime);
          const endTime = stringToDateLocal(_.blockEndDateTime);
          return !!startTime && !!endTime && startTime >= _dayBlock.startTime && endTime <= _dayBlock.endTime;
        });

        return {
          blockNumber: _dayBlock.blockNumber,
          startTime: _dayBlock.startTime,
          endTime: _dayBlock.endTime,
          data: assignmentMapping.map(data => {
            const _insight = get(insight, data.dataField);
            return {
              ...data,
              filled: _insight?.filledCount ?? 0,
              totalCount: _insight?.totalCount ?? 0,
            };
          }),
        };
      });
      return insights;
    });

    const dayBlocks = siteTime.toDayBlocks(startDate.toDate());
    const result = dayBlocks.map(_dayBlock => {
      return {
        title: _dayBlock.title,
        titleTranslations: _dayBlock.titleTranslations,
        days: weekSummary.map(days => find(days, _ => _.blockNumber === _dayBlock.blockNumber)),
      };
    });
    return result;
  }, [props.dayBlocks, positionInsights, startOfWeek]);

  return (
    <VStack key={`weekly-assignments.[${locale}]`} space={3}>
      {map(blocks, _block => (
        <VStack key={_block.title} rounded="lg" borderWidth={1} bg="white">
          <HStack roundedTop="lg" alignItems="center" minH={8} bg="gray.100">
            <Box px={2.5} w="1/3">
              <Text size="lg" fontWeight={700} color="black" lineHeight="xs">
                {translate(_block.title, _block.titleTranslations)}
              </Text>
            </Box>
            <HStack alignItems="center" w="2/3" h="full">
              {map(_block.days, (_day, num) => (
                <Box key={`${_block.title}.${num}`} alignItems="center" justifyContent="center" flex={1} h="full" borderLeftWidth={1}>
                  <Text size="md" fontWeight={600} color="gray.600" lineHeight="xs" textTransform="uppercase">
                    {_day?.startTime ? moment(_day?.startTime).format('ddd') : '--'}
                  </Text>
                </Box>
              ))}
            </HStack>
          </HStack>
          {map(assignmentMapping, _category => {
            return (
              <HStack key={`${_block.title}.${_category.dataField}`} alignItems="center" minH={9} borderTopWidth={1}>
                <Box px={2.5} w="1/3">
                  <Text size="md" fontWeight={500} color="black" lineHeight="xs">
                    {_category.title}
                  </Text>
                </Box>
                <HStack alignItems="center" w="2/3" h="full">
                  {_block.days.map((_day, index) => {
                    const category = find(_day?.data, _ => _.dataField === _category.dataField);

                    const isNotFilled = !category?.filled && !!_day?.endTime && today < _day?.endTime;

                    const filledCount = isNotFilled ? -1 : category?.filled ?? 0;
                    const totalCount = isNotFilled ? -1 : category?.totalCount ?? 0;

                    return (
                      <Box
                        key={`${_block.title}.${_category.dataField}[${index}]`}
                        alignItems="center"
                        justifyContent="center"
                        flex={1}
                        h="full"
                        borderLeftWidth={1}
                      >
                        <ProgressStatus total={totalCount} value={filledCount} isWeekly={true} />
                      </Box>
                    );
                  })}
                </HStack>
              </HStack>
            );
          })}
        </VStack>
      ))}
    </VStack>
  );
};
