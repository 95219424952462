import React, { createContext, useCallback, useContext, useMemo, useReducer } from 'react';
import { useBoolean } from 'usehooks-ts';
import jwt from 'jwt-decode';
import moment from 'moment';

import { Authorization, IdentityApi, JwtUser } from '@pimm/services/lib/access';
import { camelCase, isArray, last, map, uniq } from 'lodash';
import { useAuthPermissions } from './auth-permissions.context';

type AuthorizationStatus = 'Authorized' | 'Expired' | 'Idle';

export interface AuthorizedUser {
  brandId?: string;
  customerId: string;
  role: string[];
  tenantId?: string;
  userId: string;
  username: string;
}

export interface AuthorizationState {
  status: AuthorizationStatus;
  data?: Authorization;
}

type AuthorizationAction = { type: 'Authorized'; payload: Authorization } | { type: 'Expired' } | { type: 'Idle' };

export interface AuthTokenProviderProps {
  children: React.ReactNode;
  keepAlive?: boolean;
}

export interface AuthTokenContextReturn {
  authorization?: AuthorizationState;
  sessionTimeout: ReturnType<typeof useBoolean>;
  user?: AuthorizedUser;
  signout?: () => void;
  refreshAuthorization: () => void;
  setAuthorization: (auth: Authorization) => void;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const AuthTokenContext = createContext<AuthTokenContextReturn>(undefined!);

export const AuthTokenProvider = ({ children }: AuthTokenProviderProps) => {
  const reducer = (state: AuthorizationState, action: AuthorizationAction): AuthorizationState => {
    const nextState = { ...state, status: action.type };
    switch (action.type) {
      case 'Authorized':
        return { ...nextState, data: action.payload };
      case 'Expired':
        return nextState;
      default:
        return { status: action.type };
    }
  };

  const cacheToken = localStorage.getItem('token');
  const initialState: AuthorizationState = {
    status: 'Idle',
    data: undefined,
  };

  // Making sure to check the cache data before we register the initial state
  if (cacheToken) {
    const now = new Date();
    const initialData = JSON.parse(cacheToken);
    initialState.status = initialData.expires_in < now.getTime() ? 'Expired' : 'Authorized';
    initialState.data = initialData;
  }

  const [authorization, dispatch] = useReducer(reducer, initialState);
  const sessionTimeout = useBoolean();

  const user: AuthorizedUser | undefined = useMemo(() => {
    if (authorization.data?.access_token) {
      const { permissions, ...user }: JwtUser = jwt(authorization.data.access_token) ?? {};

      // Create a global state of user permissions
      useAuthPermissions.getState().setPermissions(typeof permissions === 'string' ? [permissions] : permissions);

      return {
        brandId: user.brand_id,
        customerId: user.customer_id,
        role: user.role,
        tenantId: user.tenant_id,
        userId: user.user_id,
        username: user.username,
      };
    }
    return undefined;
  }, [authorization]);

  const setAuthorization = useCallback(auth => {
    const now = new Date();
    const payload = {
      ...auth,
      // Notify that the token will expire after an hour
      expires_in: now.getTime() + auth.expires_in * 1000,
    };
    // TODO: find a better AuthTokenroach on where to store the refresh token
    localStorage.setItem('token', JSON.stringify(payload));
    dispatch({ type: 'Authorized', payload: payload });
  }, []);

  const refreshAuthorization = useCallback(() => {
    if (authorization.data) {
      const diff = moment(authorization.data.expires_in).diff(new Date(), 'minutes');
      // make sure that the refresh token is still valid
      if (diff <= 0) dispatch({ type: 'Expired' });
      else {
        IdentityApi.RefreshToken().then(setAuthorization);
      }
    }
  }, [authorization.data]);

  return (
    <AuthTokenContext.Provider
      value={{
        authorization: authorization,
        sessionTimeout: sessionTimeout,
        user: user,
        refreshAuthorization,
        setAuthorization: setAuthorization,
      }}
    >
      {children}
    </AuthTokenContext.Provider>
  );
};

export const AuthTokenConsumer = AuthTokenContext.Consumer;

export const useAuthToken = () => {
  // get the context
  const context = useContext(AuthTokenContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useAuthToken was used outside of its Provider');
  }
  return context;
};
