import { createAsyncThunk } from '@reduxjs/toolkit';
import StatusCode from 'status-code-enum';

import { GenericError } from '@pimm/common';
import { ThresholdProfilesApi } from '@pimm/services/lib/threshold-profiles';
import { ASYNC_THRESHOLD_PROFILES } from '@app/store/consts';
import { ThresholdProfilesState } from '../state';
import { RootState } from '../store';

const getThresholdProfiles = createAsyncThunk<ThresholdProfilesState, string, { rejectValue: GenericError; state: RootState }>(
  ASYNC_THRESHOLD_PROFILES,
  async (brandId, { getState, rejectWithValue }) => {
    try {
      const eventTypes = await ThresholdProfilesApi.GetEventTypes(brandId);
      return {
        eventTypes: eventTypes || eventTypes,
      };
    } catch (error: any) {
      return rejectWithValue({
        code: StatusCode.ServerErrorInternal,
        message: error?.message || 'Failed to fetch adviser data',
      });
    }
  },
);

export default getThresholdProfiles;
