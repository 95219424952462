import React, { createContext, useContext, useEffect } from 'react';

import { useGetGoals, useGetWeeklyFocusSettings } from '../hooks';

interface GoalSettingsContextReturn {
  goals: ReturnType<typeof useGetGoals>[0];
  weeklyFocusSettings: ReturnType<typeof useGetWeeklyFocusSettings>[0];
}

type GoalSettingsProviderProps = {
  children: React.ReactNode;
  brandId?: string;
  customerId?: string;
  marketId?: number;
  siteId?: string;
};

const GoalSettingsContext = createContext<GoalSettingsContextReturn>(undefined!);

export const GoalSettingsProvider = ({ children, brandId, customerId, marketId }: GoalSettingsProviderProps) => {
  const [weeklyFocusSettings, setWeeklyFocusSettingsParams] = useGetWeeklyFocusSettings();
  const [goals, setGoalsParams] = useGetGoals();

  useEffect(() => {
    if (brandId && customerId) {
      setWeeklyFocusSettingsParams({ brandId, customerId, marketId });
      setGoalsParams({ brandId, customerId, marketId });
    }
  }, [brandId, customerId, marketId]);

  return <GoalSettingsContext.Provider value={{ goals, weeklyFocusSettings }}>{children}</GoalSettingsContext.Provider>;
};

export const GoalSettingsConsumer = GoalSettingsContext.Consumer;

export const useGoalSettings = () => {
  // get the context
  const context = useContext(GoalSettingsContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useGoalSettings was used outside of its Provider');
  }
  return context;
};
