import { Icon, IIconProps } from 'native-base';
import { Path } from 'react-native-svg';

export const ShieldTickIcon = ({ size = 'sm', ...props }: IIconProps) => {
  return (
    <Icon size={size} viewBox="0 0 12 12" fill="none" {...props}>
      <Path
        d="M4.5 5.75006L5.5 6.75006L7.75 4.50006M10 6.00006C10 8.45428 7.32302 10.2393 6.349 10.8075C6.2383 10.8721 6.18295 10.9044 6.10484 10.9211C6.04422 10.9341 5.95578 10.9341 5.89516 10.9211C5.81705 10.9044 5.7617 10.8721 5.65101 10.8075C4.67698 10.2393 2 8.45428 2 6.00006V3.60886C2 3.2091 2 3.00922 2.06538 2.83741C2.12314 2.68563 2.21699 2.5502 2.33883 2.44282C2.47675 2.32128 2.6639 2.2511 3.0382 2.11073L5.7191 1.10539C5.82305 1.06641 5.87502 1.04692 5.92849 1.0392C5.97592 1.03234 6.02408 1.03234 6.07151 1.0392C6.12498 1.04692 6.17695 1.06641 6.2809 1.10539L8.9618 2.11073C9.3361 2.2511 9.52325 2.32128 9.66117 2.44282C9.78301 2.5502 9.87686 2.68563 9.93462 2.83741C10 3.00922 10 3.2091 10 3.60886V6.00006Z"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Icon>
  );
};
