import { decode } from 'html-entities';
import { isUndefined } from 'lodash';

export const hexToRGBA = (hex?: string, opacity?: number): string | undefined => {
  if (typeof hex !== 'string' || !hex.match(/^#[0-9A-Fa-f]{6}$/)) return undefined;
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity ?? 1})`;
};

export const hideEmail = (email: string): string => {
  return email.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, '$1***@$2');
};

export const tempValue = (value?: number | string): string | undefined => {
  let temp: number = Number(value ?? 0);

  if (isNaN(temp)) temp = 0;
  return `${temp.toFixed(1)}${decode('&deg;')}F`;
};
