import { useMemo } from 'react';
import { Box, HStack, ScrollView, useBreakpointValue } from 'native-base';
import { get, map } from 'lodash';

import { Modal, Text } from '@pimm/base';
import { EquipmentInstancesDto, RollupDateRange, SensorSummaryDto } from '@pimm/services/lib/store-equipment';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { diagnosticsFilter } from '../_helper';
import { SensorEquipment, useEquipmentSummary } from '../context';
import { useGetHardwareConfig } from '../hooks';
import { CollapsibleSensors } from './collapsible-sensors';
import { DiagnosticsChartData } from './diagnostics-chart-data';
import { FilterDisplay } from './filter-buttons';
import { GatewayCard } from './gateway-card';

type DiagnosticsSensorViewProps = {
  hardwareConfig: ReturnType<typeof useGetHardwareConfig>;
  filter: FilterDisplay;
  rollupDays: RollupDateRange;
};

export const DiagnosticsSensorView = ({ filter, hardwareConfig, rollupDays }: DiagnosticsSensorViewProps) => {
  const flexWidth = useBreakpointValue({ sm: '1/4', xl: '1/5' });
  const modalEquipment = useModalFocus<{ isDoor?: boolean; equipment?: EquipmentInstancesDto; summary?: SensorSummaryDto } | undefined>(
    undefined,
  );

  const sensorGroups = useEquipmentSummary();
  const deviceType = hardwareConfig?.data?.DeviceType;

  const filteredSection = useMemo(() => {
    const sections = sensorGroups
      .map(_group => {
        const filteredEquipment = _group.equipment.filter(_ => diagnosticsFilter(filter, _.temperature));
        return {
          displayGroup: _group.displayGroup,
          equipment: filteredEquipment,
        };
      })
      .filter(section => section.equipment.length > 0 && section.equipment.some(equip => !!equip.temperature));
    return sections;
  }, [sensorGroups, filter]);

  const handleOpenDiagnostics = (_equipment: SensorEquipment, isDoor?: boolean) => {
    modalEquipment.setOpen({
      isDoor: isDoor,
      equipment: _equipment.equipment,
      summary: isDoor ? _equipment.door : _equipment.temperature,
    });
  };

  return (
    <>
      <ScrollView flex={1} py={2} px={1.5} h="full" bg="gray.100" _contentContainerStyle={{ flexGrow: 1 }}>
        {/* Gateway */}
        <HStack mb={1}>
          {map(hardwareConfig.data?.Hubs, _hub => {
            const isPrimary = get(_hub.AdditionalProperties, 'IsPrimary') ?? false;
            return (
              <Box key={_hub.Serial} px={1.5} w={flexWidth} h="full">
                <GatewayCard hub={_hub} isPrimary={isPrimary} deviceType={deviceType} />
              </Box>
            );
          })}
        </HStack>

        {filteredSection.length > 0 ? (
          map(filteredSection, _group => {
            return (
              <CollapsibleSensors
                key={_group.displayGroup}
                title={_group.displayGroup}
                equipment={_group.equipment}
                deviceType={deviceType}
                onOpenModal={handleOpenDiagnostics}
              />
            );
          })
        ) : (
          <Box flex={1} alignItems="center" justifyContent="center" p={3} h="full" textAlign="center">
            <Text size="lg" fontWeight={600} color="gray.500">
              No Data Available
            </Text>
          </Box>
        )}
      </ScrollView>

      <Modal size="xl" _content={{ rounded: 'xl', maxW: 1100, minH: 520 }} isOpen={modalEquipment.isOpen} noPadding hideClose>
        <DiagnosticsChartData
          key={modalEquipment.payload?.equipment?.EquipmentId}
          rollupDays={rollupDays}
          onClose={modalEquipment.setHide}
          {...modalEquipment.payload}
        />
      </Modal>
    </>
  );
};
