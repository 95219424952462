import { create } from 'zustand';
import { camelCase, map, uniq } from 'lodash';

interface Permission {
  write?: boolean;
}

type AuthPermissionsState = {
  permissions?: Record<string, Permission>;
  setPermissions: (_permissions: string[]) => void;
  clear: () => void;
};

export const useAuthPermissions = create<AuthPermissionsState>(set => ({
  setPermissions: _permissions => {
    // Get all unique identifiers
    const identifiers = uniq(
      map(_permissions, identifier => {
        if (/\S*([^/\s]*(view|edit)\b\S*)/.test(identifier)) {
          return identifier.split('.').slice(0, -1).join('.');
        }
        return identifier;
      }),
    );
    // Check each identifier if contain atleast 1 "edit"
    const permissions = identifiers.reduce((permissions: Record<string, Permission>, _key) => {
      const arr = _permissions.filter(_ => _.includes(_key));
      let isWrite = false;

      arr.forEach(identifier => {
        if (/\S*([^/\s]*edit\b\S*)/.test(identifier)) isWrite = true;
      });

      return { ...permissions, [camelCase(_key)]: { write: isWrite } };
    }, {});

    set({ permissions });
  },
  clear: () => set({ permissions: undefined }),
}));
