import { useState } from 'react';
import { StyleSheet, Platform } from 'react-native';
import { WebView } from 'react-native-webview';
import { GenericState } from '@pimm/common';
import { MessageAttachmentResult, MessageCategoryEnum, StoreMessageLogDto } from '@pimm/services/lib/message-board';

interface MessageBodyProps {
  message: StoreMessageLogDto;
}

export const MessageBody = ({ message }: MessageBodyProps) => {
  const [status, setStatus] = useState<Partial<GenericState>>();
  const attachments = (message.attachments ?? []) as MessageAttachmentResult[];
  const isHtmlContent = attachments.length === 0;
  let srcData = isHtmlContent ? atob(message.body ?? '') : attachments[0].url;

  if (!isHtmlContent && message.category !== MessageCategoryEnum.Video) {
    // TODO: Remove https://wendys.pimm.us
    srcData = `https://wendys.pimm.us/WebPimm5${attachments[0]?.url}/${attachments[0]?.attachmentName}`;
  }

  if (srcData) {
    if (Platform.OS === 'web') {
      return (
        <iframe
          {...(isHtmlContent ? { srcDoc: srcData } : { src: `${srcData}#view=FitV&zoom=page-fit` })}
          style={styles.container}
          onLoad={() => setStatus({ status: 'fulfilled' })}
          onError={() => setStatus({ status: 'rejected', error: { message: 'Error occurred while loading the IFrame' } })}
          allowFullScreen
        />
      );
    }
    return <WebView bounces={false} scrollEnabled={false} source={isHtmlContent ? { html: srcData } : { uri: srcData }} />;
  }
};

const styles = StyleSheet.create({
  container: {
    border: 'none',
    height: '100%',
    width: '100%',
  },
});
