import { HStack, View, Box, useTheme, IconButton, CloseIcon } from 'native-base';

import { Button, Text, UploadCloudIcon } from '@pimm/base';
import { AddUpdateOpsPhaseProfileRequest } from '@pimm/services/lib/sms-workforce';

type PositioningConfirmUnSaveProps = AddUpdateOpsPhaseProfileRequest & {
  onClose?: () => void;
  onConfirm?: () => void;
};

export const PositioningConfirmUnSave = (props: PositioningConfirmUnSaveProps) => {
  const { colors } = useTheme();

  return (
    <View>
      <HStack alignItems="flex-start" justifyContent="space-between" mb={4}>
        <Box flex="none" rounded="full" p={2} bg="warning.50">
          <Box rounded="full" p={2} bg="warning.100">
            <UploadCloudIcon size={5} color={colors.warning[600]} />
          </Box>
        </Box>
        <IconButton
          p={0}
          w={8}
          h={8}
          _pressed={{ bg: 'gray.50' }}
          _hover={{ bg: 'gray.50' }}
          icon={<CloseIcon size={4} color="gray.700" />}
          onPress={props.onClose}
        />
      </HStack>

      <Box mb={3}>
        <Text color="black" fontWeight={600} size="xl" mb={2} lineHeight="sm">
          Unsaved changes
        </Text>
        <Text color="gray.900" fontWeight={500} size="md" mb={3} lineHeight="sm">
          Do you want to discard changes?
        </Text>
      </Box>

      <HStack space={2}>
        <Button flex={1} borderWidth={1} borderColor="gray.300" h={9} alternate outline onPress={props.onClose}>
          Cancel
        </Button>
        <Button flex={1} h={9} onPress={props.onConfirm}>
          Confirm
        </Button>
      </HStack>
    </View>
  );
};
