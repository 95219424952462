import { Box, HStack, VStack, useTheme } from 'native-base';
import { map, startCase, toLower } from 'lodash';

import { Card, Text } from '@pimm/base';
import { formatToTimeOnly, stringToDateLocal } from '@app/utils/date-formatter';
import { AggScheduledEmployee } from '@pimm/services/lib/sms-workforce';

type KitchenAvailableEmployeesProps = {
  employees: AggScheduledEmployee[];
};

export const KitchenAvailableEmployees = ({ employees }: KitchenAvailableEmployeesProps) => {
  const { colors } = useTheme();

  return (
    <Card
      rounded="xl"
      p={2}
      borderWidth={0}
      bg="white"
      style={{
        shadowColor: colors.gray[500],
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.8,
        shadowRadius: 3,
      }}
    >
      <HStack
        alignItems="center"
        justifyContent="space-between"
        px={2.5}
        height="40px"
        borderTopRadius="lg"
        borderWidth={1}
        borderColor="grayBlue.200"
        bg="grayBlue.200"
      >
        <Text size="md" fontWeight={700} color="black" lineHeight="xs">
          Available for Service
        </Text>
        <Text size="md" fontWeight={700} color="black" lineHeight="xs">
          {employees.length}
        </Text>
      </HStack>
      <VStack borderBottomRadius="lg" borderWidth={1} borderTopWidth={0}>
        {map(employees, (employee, index) => {
          const startTime = stringToDateLocal(employee.shiftStartTime);
          const endTime = stringToDateLocal(employee.shiftEndTime);
          return (
            <Box key={employee.employeeId} justifyContent="center" px={3} minH={10} borderTopWidth={index ? 1 : 0}>
              <Text size="sm" fontWeight={700} color="black" lineHeight="xs" numberOfLines={1} ellipsizeMode="tail">
                {startCase(toLower(employee.name))}
              </Text>
              <Text size="sm" fontWeight={600} color="gray.800">
                {`${formatToTimeOnly(startTime)} - ${formatToTimeOnly(endTime)}`}
              </Text>
            </Box>
          );
        })}
      </VStack>
    </Card>
  );
};
