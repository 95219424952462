import { Box, HStack, Popover, VStack } from 'native-base';
import { Button, HelpCircleIcon, Text, UserCheckIcon, UserPlusIcon, UserXIcon } from '@pimm/base';

export const PopoverTooltipShiftCheckPoints = () => {
  return (
    <Popover
      isKeyboardDismissable
      trigger={popoverProps => {
        return (
          <Button
            accessibilityLabel="Shift Checkpoints Tooltip"
            alternate
            outline
            w={9}
            minH={9}
            bg="white"
            startIcon={<HelpCircleIcon size={5} />}
            {...popoverProps}
          />
        );
      }}
    >
      <Popover.Content accessibilityLabel="Shift Checkpoints Tooltip" bg="white" rounded="lg">
        <Popover.Arrow zIndex={1} />
        <Popover.Body p={3} w="300" bg="white">
          <Box>
            <Text size="lg" fontWeight={700} color="black" lineHeight="sm">
              Shift Checkpoints
            </Text>
            <Text size="md" fontWeight={500} color="gray.700" lineHeight="sm">
              Each tab corresponds to the scheduled start time for a manager or employee.
            </Text>

            <VStack space={1} mt={3}>
              <Text size="md" fontWeight={600} color="black" lineHeight="sm" mb={1}>
                Status Indicators
              </Text>
              <HStack space={2} alignItems="center">
                <UserCheckIcon size={5} color="success.500" />
                <Text size="md" fontWeight={500} color="gray.700" lineHeight="sm">
                  Fully positioned
                </Text>
              </HStack>
              <HStack space={2} alignItems="center">
                <UserPlusIcon size={5} color="warning.500" />
                <Text size="md" fontWeight={500} color="gray.700" lineHeight="sm">
                  Partially positioned
                </Text>
              </HStack>
              <HStack space={2} alignItems="center">
                <UserXIcon size={5} color="gray.700" />
                <Text size="md" fontWeight={500} color="gray.700" lineHeight="sm">
                  No position plan set
                </Text>
              </HStack>
            </VStack>
          </Box>
        </Popover.Body>
      </Popover.Content>
    </Popover>
  );
};
