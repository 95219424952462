import { Box, HStack, IconButton, Pressable, Tooltip, VStack, useTheme } from 'native-base';
import AntDesignIcons from 'react-native-vector-icons/AntDesign';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import { some, startCase, toLower } from 'lodash';

import { useDrag } from 'react-dnd';

import { DragVerticalIcon, Text } from '@pimm/base';
import { KitchenEmployee } from '../types';
import { PositionChip } from './position-chip';
import { useKitchenPositioningConfig } from '../context';
import { EmployeeTimeline } from './employee-timeline';

type DraggableAssigneeProps = {
  employee: KitchenEmployee;
  isChecked?: boolean;
  isDisabled?: boolean;
  isUnassigned?: boolean;
  showLastKnownPosition?: boolean;
  onToggleEmployee?: () => void;
};

export const DraggableAssignee = ({
  employee,
  isChecked,
  isDisabled,
  isUnassigned,
  showLastKnownPosition,
  ...props
}: DraggableAssigneeProps) => {
  const { colors } = useTheme();
  const { isConfirmed, isEditEnabled } = useKitchenPositioningConfig();
  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: 'PositionAssignee',
    item: employee,
    canDrag: isConfirmed && isEditEnabled,
    // For reference only
    // end: (item, monitor) => {
    //   const dropResult = monitor.getDropResult<Timeslot>();
    // },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const lastPosition = employee.lastPosition;
  const isNonService = some(employee.timeslots, timeslot => timeslot.isNonService);

  return (
    <Box ref={preview} pt={2} pb={1.5} px={3.5} minHeight="68px" opacity={isDragging ? 0.6 : 1} bg="white">
      <VStack space={2}>
        <HStack alignItems="center" justifyContent="space-between" w="full">
          <Box flex={1}>
            <Text size="md" fontWeight={700} color="black" lineHeight="sm" numberOfLines={1} ellipsizeMode="tail">
              {`${startCase(toLower(employee.name ?? ''))}`}
            </Text>
            <Text size="sm" fontWeight={500} color="gray.900" lineHeight="xs">
              {employee?.title ?? 'Unknown'}
            </Text>
          </Box>

          {isConfirmed && isEditEnabled && isUnassigned && (
            <Pressable ref={drag} mr={-2}>
              <DragVerticalIcon size="22px" color={colors.gray[400]} />
              {/* <MaterialIcons name="drag-indicator" size={16} color={colors.gray[400]} /> */}
            </Pressable>
          )}

          {!isConfirmed && isEditEnabled && (
            <Tooltip label="This employee will be mark as Assigned (Non-Service)" openDelay={300} placement="top">
              <IconButton
                p={1.5}
                rounded="lg"
                bg="primary.50"
                accessibilityHint="This employee will be mark as Assigned (Non-Service)"
                icon={<AntDesignIcons name={isNonService ? 'pluscircleo' : 'minuscircleo'} size={16} color={colors.primary[600]} />}
                onPress={props.onToggleEmployee}
              />
            </Tooltip>
          )}
        </HStack>
      </VStack>

      {showLastKnownPosition ? (
        <HStack alignItems="center" justifyContent="space-between" minH={6}>
          <Text size="sm" fontWeight={500} color="gray.700" lineHeight="xs">
            Last known
          </Text>

          {!!lastPosition?.positionId ? (
            <PositionChip _text={{ size: 'sm' }} positionId={lastPosition.positionId} title={lastPosition.title} intensity={50} />
          ) : (
            <Text size="sm" fontWeight={500} color="gray.500" lineHeight="xs">
              Not found
            </Text>
          )}
        </HStack>
      ) : (
        <EmployeeTimeline employee={employee} isUnassigned={isUnassigned} />
      )}
    </Box>
  );
};
