import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { ThemeComponentSizeType } from 'native-base/lib/typescript/components/types';
import { filter, find, get, keys, merge, reduce, sortBy } from 'lodash';

import { BrandDto, ModuleConfig, NavAppTypeSectionsDto, NavSectionSubModuleDto, StringTranslation } from '@pimm/services/lib/sms-tenants';

export interface AppSidebar {
  collapsed: boolean;
}

export type ModuleScreen<P = any> = {
  name: string;
  component?: React.ElementType;
  title?: string;
  titleTranslations?: StringTranslation[];
  subModules?: NavSectionSubModuleDto[];
  active?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  icon?: any;
  iconProps?: {
    size?: ThemeComponentSizeType<'Icon'>;
  };
  items?: ModuleScreen<P>[];
  identifierId?: string;
  moduleType?: string;
  payload?: P;
};

export interface AppModulesContextProps {
  sidebar: AppSidebar;
  screens: ModuleScreen[];
  toggleSidebar: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const AppModulesContext = createContext<AppModulesContextProps>(undefined!);

// TODO: fix @typescript-eslint/explicit-module-boundary-types
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export type AppModulesProviderProps = {
  brand?: BrandDto;
  children: React.ReactNode;
  config?: {
    sidebar?: { collapsed?: boolean } & Omit<AppSidebar, 'collapsed'>;
  };
  moduleConfig?: ModuleConfig;
  screens?: ModuleScreen[];
};

export const AppModulesProvider = ({ brand, children, config, moduleConfig, ...props }: AppModulesProviderProps) => {
  const [sidebar, setSidebar] = useState<AppSidebar>(merge({ collapsed: false, items: [] }, config?.sidebar));

  const screens: ModuleScreen[] = useMemo(() => {
    const navSections = find<NavAppTypeSectionsDto>(brand?.navSections, _ => _.appType?.toLowerCase() === 'dop')?.sections;

    // Group screen by nav section and get the correct title and translation for each screen
    const navScreens = sortBy(navSections, ['seq']).flatMap(section => {
      const modules: ModuleScreen[] = [];

      sortBy(section.modules, ['seq']).forEach(_module => {
        const screen = find(props.screens, ['identifierId', _module.identifier]);
        if (!!screen) {
          const configs = screen.payload.hasConfigs;
          const paths = keys(configs);
          let visible = paths.length === 0;

          if (moduleConfig && !visible) {
            // Check moduleConfig if has path and value === true
            visible = paths.some(path => get(moduleConfig, path) === configs[path]);
          }

          if (visible) {
            const _screen = {
              ...screen,
              title: _module.title,
              titleTranslations: _module.titleTranslations,
              subModules: _module.subModules,
              payload: {
                ...screen.payload,
                sectionTitle: section.title,
                sectionTitleTranslations: section.titleTranslations,
              },
            };
            modules.push(_screen);
          }
        }
      });

      return modules;
    });

    return navScreens;
  }, [brand?.navSections, moduleConfig, props.screens]);

  const toggleSidebar = useCallback(() => {
    setSidebar(prev => ({ ...prev, collapsed: !prev?.collapsed }));
  }, []);

  return (
    <AppModulesContext.Provider
      value={{
        sidebar,
        screens,
        toggleSidebar,
      }}
    >
      {children}
    </AppModulesContext.Provider>
  );
};

export const AppModulesConsumer = AppModulesContext.Consumer;

export const useAppModules = () => useContext(AppModulesContext);
