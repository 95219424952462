import { useMutation } from 'react-query';
import { Box, HStack, IconButton, Pressable, useTheme } from 'native-base';
import { startCase, toLower } from 'lodash';

import { useDrag, useDrop } from 'react-dnd';

import { ArrowCircleBrokenLeftIcon, DragVerticalIcon, MinusCircleIcon, ReverseLeftIcon, Text } from '@pimm/base';
import { formatToISOString, stringToDateLocal } from '@app/utils/date-formatter';
import { DayBlock, useSiteTime } from '@app/features/store-core';
import { SwapAssignees } from '@pimm/services/lib/sms-workforce/services';
import { PositioningSlot } from '../reducers';
import { EmployeeShiftTime } from './employee-shift-time';

type PositioningNonServiceCardProps = {
  dayBlock: DayBlock;
  position: Partial<PositioningSlot>;
  isAvailable?: boolean;
  isDisabled?: boolean;
  isDragEnabled?: boolean;
  isPending?: boolean;
  subBlockTime?: string;
  onChange?: (position: Partial<PositioningSlot>, _position?: Partial<PositioningSlot>) => void;
  onPress?: () => void;
};

export const PositioningNonServiceCard = ({
  dayBlock,
  position,
  isAvailable,
  isDisabled,
  isDragEnabled,
  isPending,
  subBlockTime,
  ...props
}: PositioningNonServiceCardProps) => {
  const { colors } = useTheme();
  const siteTime = useSiteTime();

  const swapAssignees = useMutation({ mutationFn: SwapAssignees });

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: 'PositioningAssignee',
    item: position,
    canDrop: () => !isDisabled,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: 'PositioningAssignee',
    canDrop: () => !isDisabled,
    drop: async (_dropped: PositioningSlot) => {
      const timeNow = siteTime.today();
      const blockStartTime = stringToDateLocal(subBlockTime) ?? dayBlock?.startTime;
      const startTime = blockStartTime && blockStartTime > timeNow ? blockStartTime : timeNow;

      // For assign, replace or swap
      if ('assignee' in _dropped) {
        // Swap position assignees
        const _position = _dropped as PositioningSlot;
        if (position.assignee?.employeeId !== _position.assignee?.employeeId) {
          swapAssignees.mutate({
            positionJobId1: _position.positionJobId,
            employeeId1: _position.assignee!.employeeId,
            positionJobId2: position.positionJobId,
            employeeId2: position.assignee?.employeeId,
            subBlockDateTime: formatToISOString(startTime),
          });
          if (props.onChange) props.onChange(position, _position);
        }
        return;
      }
    },
    collect: monitor => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
  }));

  return (
    <Box ref={drop}>
      <HStack
        ref={preview}
        rounded="lg"
        alignItems="center"
        justifyContent="space-between"
        py={1}
        pl={isDragEnabled ? 1 : 3.5}
        pr={2.5}
        minH="48px"
        borderWidth={1}
        borderColor={isOver ? 'gray.500' : 'gray.300'}
        bg="white"
        style={{
          shadowColor: colors.gray[500],
          shadowOffset: { width: 0, height: 1 },
          shadowOpacity: 0.5,
          shadowRadius: 2,
        }}
      >
        <HStack space={1} alignItems="center">
          {isDragEnabled && (
            <Pressable ref={drag}>
              <DragVerticalIcon size={{ base: '18px', lg: '22px' }} color={colors.gray[400]} />
            </Pressable>
          )}

          <Box>
            <Text size="lg" fontWeight={700} color="black" lineHeight="xs">
              {startCase(toLower(position.assignee?.name ?? ''))}
            </Text>

            {!!dayBlock && !!position.assignee && <EmployeeShiftTime dayBlock={dayBlock} employee={position.assignee} />}
          </Box>
        </HStack>

        {!isDisabled && (
          <IconButton
            p={1.5}
            rounded="lg"
            shadow={1}
            borderWidth={1}
            borderColor="gray.300"
            bg="white"
            _pressed={{ borderColor: 'gray.400', bg: 'white' }}
            _hover={{ borderColor: 'gray.400', bg: 'white' }}
            icon={
              isAvailable ? (
                <MinusCircleIcon size={5} color={colors.gray[700]} />
              ) : isPending ? (
                <ReverseLeftIcon size={3.5} color={colors.gray[700]} />
              ) : (
                <ArrowCircleBrokenLeftIcon size={5} color={colors.gray[700]} />
              )
            }
            onPress={props.onPress}
          />
        )}
      </HStack>
    </Box>
  );
};
