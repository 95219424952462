import { TouchableOpacity } from 'react-native';
import { Box, HStack, IBoxProps } from 'native-base';

import { Text } from '@pimm/base';

export type AttentionType = 'all' | 'action_required' | 'low_levels';

export type AttentionTabs = IBoxProps & {
  activeTab?: AttentionType;
  lowLevels: number;
  requireActions: number;
  onChangeTab: (value: AttentionType) => void;
};

const AttentionTabs = ({ activeTab = 'all', lowLevels, requireActions, onChangeTab, ...props }: AttentionTabs) => {
  const tabItems: Record<string, AttentionType> = {
    All: 'all',
    'Action Required': 'action_required',
    'Low Levels': 'low_levels',
  };

  return (
    <HStack p={1} backgroundColor="gray.200" rounded="md" {...props}>
      {Object.keys(tabItems).map(title => {
        const value = tabItems[title] as AttentionType;
        const count = value === 'action_required' ? requireActions : lowLevels;
        return (
          <Box key={value} flex={1}>
            <TouchableOpacity disabled={count === 0} onPress={() => onChangeTab(value)}>
              <HStack
                alignItems="center"
                justifyContent="center"
                opacity={count ? 1 : 0.2}
                space={2}
                height={9}
                backgroundColor={value === activeTab ? 'white' : undefined}
                rounded="md"
              >
                <Text size="sm" fontWeight={700} color="black">
                  {title}
                </Text>
                {value !== 'all' && (
                  <Box width={10} backgroundColor={value === 'action_required' ? 'red.500' : 'yellow.500'} rounded="md">
                    <Text size="md" fontWeight={700} color="white" textAlign="center">
                      {count}
                    </Text>
                  </Box>
                )}
              </HStack>
            </TouchableOpacity>
          </Box>
        );
      })}
    </HStack>
  );
};

export default AttentionTabs;
