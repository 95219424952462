import React from 'react';
import { Box, IBoxProps } from 'native-base';

import { Holder } from '@pimm/services/lib/coke-freestyle';
import { Text } from '@pimm/base';
import { StyleSheet } from 'react-native';

const OtherCard = ({ holder, ...props }: { holder: Holder } & IBoxProps) => {
  return (
    <Box {...props}>
      <Box backgroundColor="green.300" rounded="lg" style={styles.container}>
        <Text size="sm" fontWeight={700} color="black">
          {holder['ingredientName']}
        </Text>
        {/*
        <Text size="sm" fontWeight={700} color="black">
          Carbonator timed out
        </Text>
      */}
      </Box>
    </Box>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 4,
    height: 72,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

export default OtherCard;
