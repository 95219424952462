import { ContactCategoryEnum, EmergencyMainContactTypeEnum, StoreContactDto } from '@pimm/services/lib/store-management';
import { ElectricityIcon, FireIcon, PoisonIcon, PoliceIcon, GasIcon, HospitalIcon, RescueIcon, WaterIcon } from './icons';

export const EmergencyContacts: StoreContactDto[] = [
  {
    category: ContactCategoryEnum.EMERGENCY_MAIN,
    emergencyMainContactType: EmergencyMainContactTypeEnum.ELECTRIC_COMPANY,
    contactName: 'Electric Company',
    contactCompany: 'Electric Company',
    phone1: '911',
    phone1Label: 'Emergency',
  },
  {
    category: ContactCategoryEnum.EMERGENCY_MAIN,
    emergencyMainContactType: EmergencyMainContactTypeEnum.FIRE_DEPT,
    contactName: 'Fire Department',
    contactCompany: 'Fire Department',
    phone1: '911',
    phone1Label: 'Emergency',
  },
  {
    category: ContactCategoryEnum.EMERGENCY_MAIN,
    emergencyMainContactType: EmergencyMainContactTypeEnum.GAS_COMPANY,
    contactName: 'Gas Company',
    contactCompany: 'Gas Company',
    phone1: '911',
    phone1Label: 'Emergency',
  },
  {
    category: ContactCategoryEnum.EMERGENCY_MAIN,
    emergencyMainContactType: EmergencyMainContactTypeEnum.HOSPITAL,
    contactName: 'Hospital',
    contactCompany: 'Hospital',
    phone1: '911',
    phone1Label: 'Emergency',
  },
  {
    category: ContactCategoryEnum.EMERGENCY_MAIN,
    emergencyMainContactType: EmergencyMainContactTypeEnum.POISON_CONTROL,
    contactName: 'Poison Control',
    contactCompany: 'Poison Control',
    phone1: '911',
    phone1Label: 'Emergency',
  },
  {
    category: ContactCategoryEnum.EMERGENCY_MAIN,
    emergencyMainContactType: EmergencyMainContactTypeEnum.POLICE,
    contactName: 'Police Department',
    contactCompany: 'Police Department',
    phone1: '911',
    phone1Label: 'Emergency',
  },
  {
    category: ContactCategoryEnum.EMERGENCY_MAIN,
    emergencyMainContactType: EmergencyMainContactTypeEnum.RESCUE_SQUAD,
    contactName: 'Rescue Squad',
    contactCompany: 'Rescue Squad',
    phone1: '911',
    phone1Label: 'Emergency',
  },
  {
    category: ContactCategoryEnum.EMERGENCY_MAIN,
    emergencyMainContactType: EmergencyMainContactTypeEnum.WATER_COMPANY,
    contactName: 'Water Company',
    contactCompany: 'Water Company',
    phone1: '911',
    phone1Label: 'Emergency',
  },
];

export const EmergencyIcons = {
  [EmergencyMainContactTypeEnum.UNDEFINED]: undefined,
  [EmergencyMainContactTypeEnum.ELECTRIC_COMPANY]: ElectricityIcon,
  [EmergencyMainContactTypeEnum.FIRE_DEPT]: FireIcon,
  [EmergencyMainContactTypeEnum.POISON_CONTROL]: PoisonIcon,
  [EmergencyMainContactTypeEnum.POLICE]: PoliceIcon,
  [EmergencyMainContactTypeEnum.GAS_COMPANY]: GasIcon,
  [EmergencyMainContactTypeEnum.HOSPITAL]: HospitalIcon,
  [EmergencyMainContactTypeEnum.RESCUE_SQUAD]: RescueIcon,
  [EmergencyMainContactTypeEnum.WATER_COMPANY]: WaterIcon,
};
