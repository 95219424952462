import { useEffect, useState } from 'react';
import { Box, CloseIcon, HStack, IIconProps, IconButton, ScrollView, VStack, useBreakpointValue, useTheme } from 'native-base';
import { createElement } from 'react-native';
import { find, findKey, first, get, keys, values } from 'lodash';
import moment from 'moment';

import { ButtonGroup, CircularProgress, Text } from '@pimm/base';
import {
  EquipmentInstancesDto,
  EquipmentSummaryDto,
  LinkedInstanceChartDataDto,
  RollupDateRange,
  SensorSummaryDto,
  PerformanceMetricsDto,
} from '@pimm/services/lib/store-equipment';
import { tempValue } from '@app/utils/string-formatter';
import { ResourceLoader } from '@app/components/shared';
import { useSiteConfig, useSiteTime } from '@app/features/store-core';

import {
  AlertOctagonIcon,
  BatteryLowIcon,
  DoorEventIcon,
  OutlineBadgeCheckIcon,
  PlusSquareIcon,
  Setting01Icon,
  Thermometer02Icon,
  WifiOffIcon,
} from '../icons';
import { useGetChartData, useGetMetrics, useGetSensorSummary } from '../hooks';
import { TemperatureChart } from './temperature-chart';
import { ChartStatistics } from './chart-statistics';
import { TimeInputs } from './time-inputs';
import { decode } from 'html-entities';
import { AverageTemperature } from './average-temperature';

const RollupDays = {
  [RollupDateRange.SevenDay]: -7,
  [RollupDateRange.ThirtyDay]: -30,
  [RollupDateRange.NinetyDay]: -90,
} as const;

export type DiagnosticsChartDataProps = {
  days?: number;
  isDoor?: boolean;
  equipment?: EquipmentInstancesDto;
  rollupDays?: RollupDateRange;
  summary?: SensorSummaryDto;
  isPerformance?: boolean;
  performance?: PerformanceMetricsDto;
  onClose?: () => void;
};

export const DiagnosticsChartData = ({
  equipment,
  isDoor,
  rollupDays = RollupDateRange.SevenDay,
  summary,
  isPerformance,
  performance,
  ...props
}: DiagnosticsChartDataProps) => {
  const { colors } = useTheme();
  const { siteConfig } = useSiteConfig();
  const siteTime = useSiteTime();
  const circularRadius = isPerformance ? useBreakpointValue({ sm: 30, xl: 35 }) : useBreakpointValue({ sm: 20, xl: 22 });
  const circularStroke = isPerformance ? useBreakpointValue({ sm: 13, xl: 15 }) : useBreakpointValue({ sm: 8, xl: 9 });
  const iconSize = useBreakpointValue({ sm: '16px', xl: '18px' });
  const pattern = /^100(\.0)?$/;

  const [linkedTemperature, setLinkedTemperature] = useState<LinkedInstanceChartDataDto>();

  const [queryDates, setQueryDates] = useState<{ startDate: Date; endDate: Date }>({
    startDate: moment(siteTime.today()).add(RollupDays[rollupDays], 'day').startOf('day').toDate(),
    endDate: siteTime.today().toNearestNextMinutes(5),
  });
  const [sensorSummary, setSensorSummary] = useState<SensorSummaryDto | undefined>(summary);
  const [performanceMetrics, setPerformanceMetrics] = useState<PerformanceMetricsDto | undefined>(performance);
  const tempPercentage = performanceMetrics?.InTempPercentage;

  const numOfDays = moment(queryDates.endDate).diff(queryDates.startDate, 'days') * -1;
  const queryChartData = useGetChartData({ ...queryDates, siteId: siteConfig?.id, equipmentId: equipment?.EquipmentId });

  const querySummary = useGetSensorSummary(
    !isPerformance
      ? {
          range: keys(RollupDays).find(key => RollupDays[key] === numOfDays) as RollupDateRange,
          siteId: siteConfig?.id,
          equipmentId: equipment?.EquipmentId,
        }
      : undefined,
  );

  const queryMetrics = useGetMetrics(
    isPerformance
      ? {
          range: keys(RollupDays).find(key => RollupDays[key] === numOfDays) as RollupDateRange,
          siteId: siteConfig?.id,
          equipmentId: equipment?.EquipmentId,
        }
      : undefined,
  );

  const thresholds = equipment?.TemperatureMetrics?.Temperature?.Thresholds;

  const handleChangeDays = (days: number) => {
    const today = siteTime.today();
    const startDate = moment(today).add(days, 'day').startOf('day').toDate();
    const endDate = siteTime.today().toNearestNextMinutes(5);

    setQueryDates({
      startDate: startDate,
      endDate: endDate,
    });
  };

  useEffect(() => {
    const linkedTemperatureDto = find<LinkedInstanceChartDataDto>(queryChartData.data, _ => !!_.Temperature);
    setLinkedTemperature(linkedTemperatureDto);
  }, [queryChartData.data, queryMetrics.data]);

  useEffect(() => {
    if (!isPerformance && querySummary.data) {
      const _summary = find<EquipmentSummaryDto>(
        querySummary.data,
        _equipment => !!_equipment.Sensors?.some(_ => _.TemperatureInstanceId === equipment?.TemperatureMetrics?.Temperature?.InstanceId),
      );
      const temperatureSensor = _summary?.Sensors?.find(_ => !!_.TemperatureInstanceId);
      const doorSensor = _summary?.Sensors?.find(_ => !!_.DoorInstanceId);

      setSensorSummary(isDoor ? doorSensor : temperatureSensor);
    }

    if (isPerformance && queryMetrics.data) {
      const _metrics = find<PerformanceMetricsDto>(
        queryMetrics.data,
        _performance => _performance.EquipmentId === performance?.EquipmentId,
      );
      setPerformanceMetrics(_metrics);
    }
  }, [equipment, querySummary.data, queryMetrics.data]);

  return (
    <ScrollView h="full" contentContainerStyle={{ height: '100%' }}>
      <VStack>
        {/* Title */}
        <HStack alignItems="center" justifyContent="space-between" px={3} h="58px" borderBottomWidth={1}>
          <HStack space={3} alignItems="center">
            <HStack space={2} alignItems="center">
              <Text size="2xl" color="black" fontWeight={600}>
                {sensorSummary?.Label ?? equipment?.TemperatureMetrics?.Temperature?.Description ?? ''}
              </Text>
              {/* Thresholds */}
              <HStack space={1} alignItems="center">
                {/* Lower Red */}
                <HStack rounded="full" borderWidth={1} borderColor="gray.100" alignItems="center" p={1} pr={2} space={2}>
                  <Box rounded="full" alignItems="center" justifyContent="center" p={0.5} bgColor="error.600">
                    <AlertOctagonIcon color="white" size={4} />
                  </Box>
                  <Text size="md" color="gray.700" fontWeight={600} lineHeight="xs">
                    {`<${tempValue(thresholds?.LowerRed)}`}
                  </Text>
                </HStack>
                {/* Lower and Upper Green */}
                <HStack rounded="full" borderWidth={1} borderColor="gray.100" alignItems="center" p={1} pr={2} space={2}>
                  <Box rounded="full" bgColor="blue.600" alignItems="center" p={0.5}>
                    <OutlineBadgeCheckIcon color="white" size={4} />
                  </Box>
                  <Text size="md" color="gray.700" fontWeight={600} lineHeight="xs">
                    {`${`${(thresholds?.LowerGreen ?? 0).toFixed(1)} -`} ${`${tempValue(thresholds?.UpperGreen)}`}`}
                  </Text>
                </HStack>
                {/* Upper Red */}
                <HStack rounded="full" borderWidth={1} borderColor="gray.100" alignItems="center" p={1} pr={2} space={2}>
                  <Box rounded="full" bgColor="error.600" alignItems="center" p={0.5}>
                    <AlertOctagonIcon color="white" size={4} />
                  </Box>
                  <Text size="md" color="gray.700" fontWeight={600} lineHeight="xs">
                    {`>${tempValue(thresholds?.UpperRed)}`}
                  </Text>
                </HStack>
              </HStack>
            </HStack>
          </HStack>

          <HStack space={3} alignItems="center">
            <ButtonGroup value={numOfDays} onChange={handleChangeDays}>
              <ButtonGroup.Item value={-7}>7 Days</ButtonGroup.Item>
              <ButtonGroup.Item value={-30}>30 Days</ButtonGroup.Item>
              <ButtonGroup.Item value={-90}>90 Days</ButtonGroup.Item>
            </ButtonGroup>
            {!!props.onClose && (
              <IconButton
                p={2}
                _pressed={{ bg: 'gray.100' }}
                _hover={{ bg: 'gray.50' }}
                icon={<CloseIcon size={3.5} color="gray.700" />}
                onPress={props.onClose}
              />
            )}
          </HStack>
        </HStack>
        <HStack space={3} py={4} px={3}>
          {/* Chart */}
          <VStack flex={1} space={5}>
            <HStack space={2} justifyContent="space-between">
              {/* Chart Stats UI */}
              <ChartStatistics statistics={linkedTemperature?.Temperature?.Statistics} />

              {/* Action Tools */}
              <HStack space={2} alignItems="center" justifyContent="center">
                <IconButton p={0} disabled icon={<PlusSquareIcon color={colors.gray[400]} size={6} />} />
                <IconButton p={0} disabled icon={<Setting01Icon color={colors.gray[400]} size={6} />} />
              </HStack>
            </HStack>

            {/* Line Chart  */}
            <VStack space={2}>
              <ResourceLoader height={350} isLoading={queryChartData.isIdle || queryChartData.isLoading}>
                <TemperatureChart
                  _container={{ height: 346 }}
                  startDate={queryDates.startDate}
                  endDate={queryDates.endDate}
                  linkedInstances={queryChartData.data}
                  thresholds={thresholds}
                />
              </ResourceLoader>
            </VStack>
          </VStack>

          {isPerformance ? (
            <VStack space={3} width={{ base: 160, xl: 170 }}>
              <VStack flex={1} rounded="lg" alignItems="center" py={2} p={{ base: 2, xl: 3 }} bg="gray.100">
                <Text size="lg" fontWeight={600} color="black" textAlign="center" lineHeight="xs">
                  {`Average Temp (${decode('&deg;')}F)`}
                </Text>
                <HStack flex={1} alignItems="center" justifyContent="center" w="full">
                  {!!performance?.AverageTemperature && (
                    <AverageTemperature
                      size={{ base: 'xl', xl: 'xl' }}
                      value={performanceMetrics?.AverageTemperature}
                      alarmLevel={equipment?.TemperatureMetrics?.Temperature?.AlarmLevel}
                    />
                  )}
                </HStack>
              </VStack>

              <VStack space={2} flex={1} rounded="lg" alignItems="center" py={2} p={{ base: 2, xl: 3 }} bg="gray.100">
                <Text size="lg" fontWeight={600} color="black" textAlign="center" lineHeight="xs">
                  In-Temp %
                </Text>
                <Box flex={1} justifyContent="center">
                  <CircularProgress
                    _text={{ size: 'lg', decimalScale: 1, fixedDecimalScale: pattern.test(tempPercentage?.toString() ?? ''), suffix: '' }}
                    activeStrokeColor={colors.primary[500]}
                    inActiveStrokeColor={colors.gray[300]}
                    radius={circularRadius}
                    strokeWidth={circularStroke}
                    value={isNaN(Number(tempPercentage)) ? 0 : Number(tempPercentage ?? 0)}
                  />
                </Box>
              </VStack>

              <VStack
                space={{ base: 1, xl: 2 }}
                flex={1}
                rounded="lg"
                alignItems="center"
                py={2}
                p={{ base: 1.5, xl: 2 }}
                minW={{ base: '100px', xl: '110px' }}
                bg="gray.100"
              >
                <Text size="lg" fontWeight={600} color="black" textAlign="center">
                  Events
                </Text>

                <VStack space={1} w="full">
                  {[
                    { icon: Thermometer02Icon, dataField: 'TemperatureFlagCounts' },
                    { icon: DoorEventIcon, dataField: 'DoorFlagCounts' },
                  ].map(_ => {
                    const red = isDoor ? 0 : Number(get(performanceMetrics, `${_.dataField}.Red`, 0));
                    const black = isDoor ? 0 : Number(get(performanceMetrics, `${_.dataField}.Black`, 0));
                    return (
                      <HStack
                        key={_.dataField}
                        space={2}
                        rounded="lg"
                        alignItems="center"
                        justifyContent="space-between"
                        py={1}
                        px={2}
                        bg="white"
                      >
                        {createElement<IIconProps>(_.icon, { size: iconSize, color: 'black' })}

                        <HStack space={1}>
                          <Box
                            rounded={{ base: 'md', xl: 'lg' }}
                            alignItems="center"
                            justifyContent="center"
                            minWidth={{ base: 5, xl: 6 }}
                            h={{ base: '24px', xl: '26px' }}
                            bg="error.500"
                          >
                            <Text size={{ base: 'md', xl: 'lg' }} fontWeight={600} color="white" lineHeight={{ base: 'md', xl: 'lg' }}>
                              {red}
                            </Text>
                          </Box>

                          <Box
                            rounded={{ base: 'md', xl: 'lg' }}
                            alignItems="center"
                            justifyContent="center"
                            minWidth={{ base: 5, xl: 6 }}
                            h={{ base: '24px', xl: '26px' }}
                            bg="black"
                          >
                            <Text size={{ base: 'md', xl: 'lg' }} fontWeight={600} color="white" lineHeight={{ base: 'md', xl: 'lg' }}>
                              {black}
                            </Text>
                          </Box>
                        </HStack>
                      </HStack>
                    );
                  })}
                </VStack>
              </VStack>
            </VStack>
          ) : (
            <VStack space={3} width={{ base: 160, xl: 170 }}>
              {/* Diagnostic */}
              <VStack space={3}>
                {[
                  { dataField: 'UptimePct', title: 'Sensor Uptime %' },
                  { dataField: 'UploadPct', title: 'Data Upload %' },
                ].map(_ => {
                  const value = Number(get(sensorSummary, _.dataField) ?? 0);
                  return (
                    <HStack
                      key={_.dataField}
                      space={{ base: 1, xl: 3 }}
                      flex={1}
                      alignItems="center"
                      justifyContent="space-between"
                      rounded="xl"
                      py={2.5}
                      px={3}
                      bg="gray.50"
                    >
                      <Box flex={1} pr={6}>
                        <Text size="md" fontWeight={600} color="black" lineHeight="xs" numberOfLines={2}>
                          {_.title}
                        </Text>
                      </Box>

                      <CircularProgress
                        _text={{ size: { base: 'sm', xl: 'md' }, decimalScale: 0, suffix: '' }}
                        activeStrokeColor={colors.primary[600]}
                        inActiveStrokeColor={colors.gray[300]}
                        radius={circularRadius}
                        strokeWidth={circularStroke}
                        value={Math.round(value)}
                      />
                    </HStack>
                  );
                })}
              </VStack>

              <VStack space={3} rounded="lg" justifyContent="center" p={3} bg="gray.50">
                <Text size="md" fontWeight={600} color="black" lineHeight="xs">
                  Offline
                </Text>
                <TimeInputs value={sensorSummary?.OfflineDuration} />

                <Text size="md" fontWeight={600} color="black" lineHeight="xs" mt={1}>
                  Disabled (OOS)
                </Text>
                <TimeInputs value={sensorSummary?.OosDuration} />
              </VStack>

              <VStack space={3} rounded="lg" p={3} bg="gray.50">
                <Text size="md" fontWeight={600} color="black">
                  Events
                </Text>
                <VStack space={1}>
                  {[
                    { icon: WifiOffIcon, dataField: 'OfflineEvents' },
                    { icon: BatteryLowIcon, dataField: 'BatteryEvents' },
                  ].map(_ => {
                    const severity3 = Number(get(sensorSummary, `${_.dataField}.Severity3`) ?? 0);
                    const severity4 = Number(get(sensorSummary, `${_.dataField}.Severity4`) ?? 0);
                    return (
                      <HStack
                        key={_.dataField}
                        space={2}
                        rounded="lg"
                        alignItems="center"
                        justifyContent="space-between"
                        py={1}
                        px={2}
                        bg="white"
                      >
                        {createElement<IIconProps>(_.icon, { size: '18px', color: 'black' })}

                        <HStack space={1}>
                          <Box
                            rounded={{ base: 'md', xl: 'lg' }}
                            alignItems="center"
                            justifyContent="center"
                            minWidth={6}
                            h={{ base: '24px', xl: '26px' }}
                            bg="error.500"
                          >
                            <Text size={{ base: 'md', xl: 'lg' }} fontWeight={600} color="white" lineHeight="xs">
                              {severity3}
                            </Text>
                          </Box>

                          <Box
                            rounded={{ base: 'md', xl: 'lg' }}
                            alignItems="center"
                            justifyContent="center"
                            minWidth={6}
                            h={{ base: '24px', xl: '26px' }}
                            bg="black"
                          >
                            <Text size={{ base: 'md', xl: 'lg' }} fontWeight={600} color="white" lineHeight="xs">
                              {severity4}
                            </Text>
                          </Box>
                        </HStack>
                      </HStack>
                    );
                  })}
                </VStack>
              </VStack>
            </VStack>
          )}
        </HStack>
      </VStack>
    </ScrollView>
  );
};
