import { ASYNC_STORE_LIST } from '../consts';
import { StoreListState } from '../state';
import { createGenericSlice } from './genericSlice';

export const StoreListStateDefault = {
  status: 'idle',
  data: [],
};

const storeListSlice = createGenericSlice<StoreListState>({
  name: ASYNC_STORE_LIST,
  initialState: StoreListStateDefault,
  reducers: {},
});

export const storeListReducer = storeListSlice.reducer;
