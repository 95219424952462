import { QueryKey, UseQueryResult, useQuery } from 'react-query';

import { GetDocument } from '@pimm/services/lib/sms-documents/services';

export const useGetLinkDocument = (query?: {
  queryKey?: string;
  documentId?: string;
}): UseQueryResult<Awaited<ReturnType<typeof GetDocument>>> => {
  const document = useQuery({
    cacheTime: 0,
    enabled: !!query?.documentId,
    queryKey: [query?.queryKey ?? 'GetLinkDocument', query?.documentId] as [QueryKey, string],
    queryFn: ({ queryKey: [key, documentId] }) => {
      return GetDocument(documentId);
    },
  });

  return document;
};
