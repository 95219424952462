import { Fragment, useDeferredValue, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FlatList, HStack, VStack, useTheme } from 'native-base';
import { last, max, orderBy, range, size, startCase, toLower } from 'lodash';

import { Button, CalendarClockIcon, Modal, Text } from '@pimm/base';
import { formatToTimeOnly, stringToDateLocal } from '@app/utils/date-formatter';
import { AggScheduledEmployee, ShiftDto } from '@pimm/services/lib/sms-workforce';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { useSiteTime } from '@app/features/store-core';
import { ArrowDownBoxIcon, ArrowUpBoxIcon } from '../icons';

type KitchenAssignees = (AggScheduledEmployee | ShiftDto)[];

type ModalPlanAssigneesProps = {
  assignees?: KitchenAssignees;
  maxRows?: number;
  title: string;
  type?: 'ArrivalSoon';
  trigger: (props: { assignees: KitchenAssignees; onPress: () => void }) => React.ReactNode;
  onCancel?: () => void;
  onNavigate?: () => void;
};

export const ModalPlanAssignees = ({ maxRows = 8, title, trigger, ...props }: ModalPlanAssigneesProps) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const siteTime = useSiteTime();
  const modal = useModalFocus();

  // Making sure that we sort the list by name
  const [assignees, setAssignees] = useState<KitchenAssignees>([]);
  const deferredAssignees = useDeferredValue(assignees);
  const numOfRows = range(max([size(deferredAssignees), maxRows]) ?? maxRows);

  useEffect(() => {
    let timeoutRef;

    const populateAssignees = (query: { assignees?: KitchenAssignees; type?: ModalPlanAssigneesProps['type'] }) => {
      let sortedAssignees = orderBy(query.assignees, ['name', 'employee.firstName', 'employee.lastName']);
      let milli: number | undefined;

      // Get all scheduled employees that will arrive soon within 30 minutes
      if (query.type === 'ArrivalSoon') {
        const today = siteTime.today();
        // Refetch the api using the nearest quarter of/to hour (15, 30, 45 or 0)
        const nearestQuarterHour = today.toNearestQuarterHour();
        const endTime = new Date(today.getTime() + 60 * 30 * 1000); // add 30 minutes

        milli = nearestQuarterHour.getTime() - today.getTime();

        sortedAssignees = sortedAssignees.filter(assignee => {
          const employee = assignee as ShiftDto;
          const shiftStartTime = stringToDateLocal(employee.startTime);
          return shiftStartTime && shiftStartTime >= today && shiftStartTime <= endTime;
        });
      }

      setAssignees(sortedAssignees);

      if (milli) {
        // Re-run every quarter of/to the hour
        return setTimeout(() => populateAssignees(query), milli);
      }
    };

    timeoutRef = populateAssignees({
      assignees: props.assignees,
      type: props.type,
    });

    return () => clearInterval(timeoutRef);
  }, [props.assignees, props.type]);

  return (
    <Fragment>
      {trigger({ assignees: assignees, onPress: modal.setOpen })}
      <Modal size="md" isOpen={modal.isOpen} hideClose noPadding>
        <VStack space={4} w="full" h="full" p={4}>
          <Text size="xl" fontWeight={600} color="gray.900" lineHeight="xs">
            {title}
          </Text>

          <FlatList
            maxH="300px"
            data={numOfRows}
            extraData={[deferredAssignees]}
            stickyHeaderIndices={[0]}
            scrollEnabled
            overflow="hidden"
            ListHeaderComponent={() => (
              <HStack minH={8} borderWidth={1} borderBottomWidth={0} borderTopRadius="lg" bg="gray.200">
                <Box flex={1} justifyContent="center" px={3} maxW="2/4" borderRightWidth={1}>
                  <Text size="md" fontWeight={700} color="black">
                    {t('common:name')}
                  </Text>
                </Box>
                <HStack space={1} flex={1} alignItems="center" justifyContent="center" px={2} maxW="1/4" borderRightWidth={1}>
                  <ArrowDownBoxIcon size={4} />
                  <Text size="md" fontWeight={700} color="black">
                    {t('common:shift_start')}
                  </Text>
                </HStack>
                <HStack space={1} flex={1} alignItems="center" justifyContent="center" px={2} maxW="1/4">
                  <ArrowUpBoxIcon size={4} />
                  <Text size="md" fontWeight={700} color="black">
                    {t('common:shift_end')}
                  </Text>
                </HStack>
              </HStack>
            )}
            ListEmptyComponent={() => (
              <Box justifyContent="center" height="100px" borderWidth={1} borderTopWidth={0} borderBottomRadius="lg">
                <Text size="md" fontWeight={500} color="gray.500" lineHeight="xs" textAlign="center">
                  {t('common:no_available_employees')}
                </Text>
              </Box>
            )}
            renderItem={({ index }) => {
              const obj = deferredAssignees[index];
              const isLast = last(numOfRows) === index;
              let name, startTime, endTime;

              if (obj) {
                if ('employee' in obj) {
                  const { employee, ...shift } = obj as ShiftDto;
                  name = [employee?.firstName, employee?.lastName].filter(Boolean).join(' ');
                  startTime = stringToDateLocal(shift.startTime);
                  endTime = stringToDateLocal(shift.endTime);
                } else {
                  const assignee = obj as AggScheduledEmployee;
                  name = assignee.name;
                  startTime = stringToDateLocal(assignee.shiftStartTime);
                  endTime = stringToDateLocal(assignee.shiftEndTime);
                }
              }

              return (
                <HStack minH={8} borderWidth={1} borderTopWidth={0} borderBottomRadius={isLast ? 'lg' : undefined}>
                  <Box flex={1} justifyContent="center" px={3} maxW="2/4" borderRightWidth={1}>
                    <Text size="md" fontWeight={500} color="black">
                      {startCase(toLower(name))}
                    </Text>
                  </Box>
                  <Box flex={1} justifyContent="center" px={2} maxW="1/4" borderRightWidth={1}>
                    <Text size="sm" fontWeight={600} color="gray.800" textAlign="center">
                      {!!startTime && formatToTimeOnly(startTime)}
                    </Text>
                  </Box>
                  <Box flex={1} justifyContent="center" px={2} maxW="1/4">
                    <Text size="sm" fontWeight={600} color="gray.800" textAlign="center">
                      {!!endTime && formatToTimeOnly(endTime)}
                    </Text>
                  </Box>
                </HStack>
              );
            }}
          />

          <HStack space={3} alignItems="center">
            <Button flex={1} size="sm" alternate outline onPress={modal.setHide}>
              {t('common:cancel')}
            </Button>

            <Button
              flex={1}
              size="sm"
              outline
              bg="primary.50"
              startIcon={<CalendarClockIcon size={4} color={colors.primary[600]} />}
              _hover={{ bg: 'primary.50' }}
              _pressed={{ bg: 'primary.50' }}
              _text={{ color: 'primary.600' }}
              onPress={props.onNavigate}
            >
              {t('common:position_plan')}
            </Button>
          </HStack>
        </VStack>
      </Modal>
    </Fragment>
  );
};
