import { Dispatch, SetStateAction, useState } from 'react';
import { QueryKey, UseQueryResult, useQuery } from 'react-query';

import { GetSiteManagementUsers } from '@pimm/services/lib/sms-tenants/services';
import { MgmtUserDto } from '@pimm/services/lib/sms-tenants';

type GetSiteManagementQueryParams = {
  customerId?: string;
  siteId?: string;
};

export const useGetManagementTeam = (props?: {
  queryKey?: string;
  query?: GetSiteManagementQueryParams;
}): [UseQueryResult<MgmtUserDto[]>, Dispatch<SetStateAction<GetSiteManagementQueryParams | undefined>>] => {
  const [query, setQuery] = useState<GetSiteManagementQueryParams | undefined>({
    ...props?.query,
  });

  const managementTeam = useQuery({
    enabled: !!query?.siteId && !!query?.customerId,
    queryKey: [props?.queryKey ?? 'GetSiteManagementUsers', { customerId: query?.customerId, siteId: query?.siteId }] as [
      QueryKey,
      GetSiteManagementQueryParams,
    ],
    queryFn: ({ queryKey }) => {
      const [, queryParams] = queryKey as [string, GetSiteManagementQueryParams];
      return GetSiteManagementUsers(queryParams.customerId || '', queryParams.siteId || '');
    },
  });

  return [managementTeam, setQuery];
};
