import { Icon, IIconProps } from 'native-base';
import { Path } from 'react-native-svg';

export const DigitalPhoneIcon = ({ size = 'sm', ...props }: IIconProps) => {
  return (
    <Icon size={size} viewBox="0 0 16 16" fill="none" {...props}>
      <Path
        d="M8 11.6667H8.00667M5.46667 14.6667H10.5333C11.2801 14.6667 11.6534 14.6667 11.9387 14.5213C12.1895 14.3935 12.3935 14.1895 12.5213 13.9386C12.6667 13.6534 12.6667 13.2801 12.6667 12.5333V3.46666C12.6667 2.71992 12.6667 2.34656 12.5213 2.06134C12.3935 1.81046 12.1895 1.60648 11.9387 1.47865C11.6534 1.33333 11.2801 1.33333 10.5333 1.33333H5.46667C4.71993 1.33333 4.34656 1.33333 4.06135 1.47865C3.81046 1.60648 3.60649 1.81046 3.47866 2.06134C3.33333 2.34656 3.33333 2.71993 3.33333 3.46666V12.5333C3.33333 13.2801 3.33333 13.6534 3.47866 13.9386C3.60649 14.1895 3.81046 14.3935 4.06135 14.5213C4.34656 14.6667 4.71993 14.6667 5.46667 14.6667ZM8.33333 11.6667C8.33333 11.8508 8.1841 12 8 12C7.81591 12 7.66667 11.8508 7.66667 11.6667C7.66667 11.4826 7.81591 11.3333 8 11.3333C8.1841 11.3333 8.33333 11.4826 8.33333 11.6667Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Icon>
  );
};
