import _ from 'lodash';
import { AddressDto } from '@pimm/services/lib/store-management';

export function completeAddress(address?: AddressDto, multiline?: boolean) {
  if (!address) return '';
  const line1 = _.filter([address.address1, address.address2, address.city], str => !_.isEmpty(str)).join(', ')
  const line2 = _.filter([address.state, address.zipCode], str => !_.isEmpty(str)).join(', ')
 
  if (_.isEmpty(line2)) return line1 || ''
  if (multiline) return `${line1}\n${line2}`
  return `${line1}, ${line2}`
}
