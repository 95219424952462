import { Icon, IIconProps } from 'native-base';
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg';

export const DoorEventIcon = ({ color = 'warning.500', size = 'sm', ...props }: IIconProps) => {
  return (
    <Icon size={size} viewBox="0 0 18 18" color={color} fill="none" {...props}>
      <G clipPath="url(#clip0_3701_2638)">
        <Path
          d="M8.98562 0.0675521C8.71485 -0.0469538 8.34088 -0.00640964 8.12065 0.118106C7.90045 0.242453 7.78991 0.392075 7.70552 0.533215C7.53675 0.815664 7.47485 1.0993 7.47485 1.41397V16.8308C7.47485 17.1428 7.5357 17.4271 7.70552 17.706C7.79043 17.8454 7.9062 17.9906 8.10915 18.1099C8.31209 18.2291 8.63552 18.2867 8.90488 18.2052L15.0572 16.3483C15.6167 16.1794 16.0317 15.5977 16.0317 14.9739V4.01148C16.0317 3.38212 15.5676 2.84675 15.138 2.66504L8.98562 0.0675521ZM9.03758 1.74495L14.4691 4.0451V14.9346L9.03758 16.5728V1.74495Z"
          fill="currentColor"
        />
        <Path
          d="M3.6108 2.59779C2.799 2.59779 2.25 3.30093 2.25 4.01719V14.2218C2.25 14.938 2.79904 15.6412 3.6108 15.6412H7.83832C8.26758 15.6397 8.61522 15.3014 8.61678 14.8838C8.61853 14.4639 8.26982 14.1223 7.83832 14.121H3.81255V4.11781H7.83832C8.26984 4.11645 8.61852 3.7748 8.61678 3.35496C8.61521 2.93713 8.26756 2.59905 7.83832 2.59753L3.6108 2.59779Z"
          fill="currentColor"
        />
        <Path
          d="M12.2916 9.12157C12.2916 9.80269 11.724 10.3548 11.0239 10.3548C10.3238 10.3548 9.75635 9.80264 9.75635 9.12157C9.75635 8.44049 10.3239 7.88831 11.0239 7.88831C11.7241 7.88831 12.2916 8.44049 12.2916 9.12157Z"
          fill="currentColor"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_3701_2638">
          <Rect width="18" height="18" fill="white" />
        </ClipPath>
      </Defs>
    </Icon>
  );
};
