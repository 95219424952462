import { Dispatch, SetStateAction, useState } from 'react';
import { QueryKey, UseQueryResult, useQuery } from 'react-query';

import { GetWeeklyFocus } from '@pimm/services/lib/sms-workforce/services';
import { formatToISOString } from '@app/utils/date-formatter';

type GetWeeklyGoalsQueryParams = {
  siteId?: string;
  weekStartDate?: Date;
};

type GetWeeklyFocusReturn = UseQueryResult<Awaited<ReturnType<typeof GetWeeklyFocus>>> & {
  queryKey: string;
  queryParams: GetWeeklyGoalsQueryParams;
};

export const useGetWeeklyFocus = (props?: {
  queryKey?: string;
  query?: GetWeeklyGoalsQueryParams;
}): [GetWeeklyFocusReturn, Dispatch<SetStateAction<GetWeeklyGoalsQueryParams>>] => {
  const [queryKey] = useState<string>(props?.queryKey ?? 'GetWeeklyFocus');
  const [query, setQuery] = useState<GetWeeklyGoalsQueryParams>({ ...props?.query });

  const weeklyGoals = useQuery({
    enabled: !!query.siteId && !!query.weekStartDate,
    queryKey: [queryKey, query] as [QueryKey, GetWeeklyGoalsQueryParams],
    queryFn: ({ queryKey }) => {
      return GetWeeklyFocus({
        siteId: queryKey[1].siteId,
        weekStartDate: formatToISOString(queryKey[1].weekStartDate!, true),
      });
    },
  });

  return [{ ...weeklyGoals, queryKey, queryParams: query }, setQuery];
};
