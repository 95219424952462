import { Icon, IIconProps } from 'native-base';
import { Path } from 'react-native-svg';

export const BatteryEventIcon = ({ color = 'yellow.400', size = 'sm', ...props }: IIconProps) => {
  return (
    <Icon size={size} viewBox="0 0 21 20" fill="none" color={color} {...props}>
      <Path
        d="M7.75 9.5V11.5M15.5 11V10M7.9 13.5H11.6C12.4401 13.5 12.8601 13.5 13.181 13.3365C13.4632 13.1927 13.6927 12.9632 13.8365 12.681C14 12.3601 14 11.9401 14 11.1V9.9C14 9.05992 14 8.63988 13.8365 8.31901C13.6927 8.03677 13.4632 7.8073 13.181 7.66349C12.8601 7.5 12.4401 7.5 11.6 7.5H7.9C7.05992 7.5 6.63988 7.5 6.31901 7.66349C6.03677 7.8073 5.8073 8.03677 5.66349 8.31901C5.5 8.63988 5.5 9.05992 5.5 9.9V11.1C5.5 11.9401 5.5 12.3601 5.66349 12.681C5.8073 12.9632 6.03677 13.1927 6.31901 13.3365C6.63988 13.5 7.05992 13.5 7.9 13.5Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Icon>
  );
};
