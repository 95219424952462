import { useMemo, useState } from 'react';
import { Box, HStack, VStack, useMediaQuery, useTheme } from 'native-base';
import { filter, find, flatMap, range } from 'lodash';
import moment from 'moment';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import { ButtonGroup, Modal, Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { CleaningScheduleTypeEnum, CleaningTaskStatusEnum } from '@pimm/services/lib/sms-workforce/types';
import { formatTo, formatToISOString } from '@app/utils/date-formatter';
import { useSiteTime } from '@app/features/store-core';
import { CleaningTasksTable, useCleaningAssignments } from '@app/features/cleaning-tasks';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { CleaningDocuments } from './cleaning-documents';
import { CleaningTaskItem } from '../reducers';

type CleaningFormProps = {
  scheduleType?: CleaningScheduleTypeEnum;
};

export const CleaningForm = ({ ...props }: CleaningFormProps) => {
  const { colors } = useTheme();
  const [isLargeScreen] = useMediaQuery({ maxWidth: 1200 });
  const { locale } = useAppLocale();
  const siteTime = useSiteTime();
  const { assignments, week } = useCleaningAssignments();
  const modalCleaningTask = useModalFocus<CleaningTaskItem>();
  const [scheduleType, setScheduleType] = useState<CleaningScheduleTypeEnum>(props.scheduleType ?? CleaningScheduleTypeEnum.Daily);
  const [tabItems] = useState<{ title: string; value: string }[]>([
    { title: 'Weekly Tasks', value: CleaningScheduleTypeEnum.Weekly },
    { title: 'Monthly Tasks', value: CleaningScheduleTypeEnum.Monthly },
  ]);

  const selectedDate =
    scheduleType === CleaningScheduleTypeEnum.Daily
      ? range(7).reduce((_date: Date | undefined, day) => {
          const today = siteTime.today();
          const date = moment(week.startDate).add(day, 'day').toDate();
          const { startTime, endTime } = siteTime.toStartEndOfBlock(date);

          if (today >= startTime! && today < endTime!) return date;
          return _date;
        }, week.startDate)
      : undefined;

  const [tabFocus, setTabFocus] = useState<string>(selectedDate ? formatToISOString(selectedDate) : CleaningScheduleTypeEnum.Weekly);

  const { cleaningPlan, isEditable } = useMemo(() => {
    const cleaningPlan = assignments.find(_ => {
      return _.scheduleType === scheduleType && (scheduleType !== CleaningScheduleTypeEnum.Daily || formatToISOString(_.date) === tabFocus);
    });

    const date = cleaningPlan?.date;
    const today = siteTime.today();
    let endTime: Date | undefined;

    if (cleaningPlan?.scheduleType === CleaningScheduleTypeEnum.Monthly) {
      const endOfMOnth = moment(today).endOf('month').toDate();
      endTime = siteTime.toStartEndOfBlock(endOfMOnth).endTime;
    } else {
      // NOTE: We need to take the selected week from calendar not from siteTime
      endTime = siteTime.toStartEndOfBlock(week.endDate).endTime;
    }

    const isEditable = !!date && !!endTime && today < endTime;

    return {
      cleaningPlan: cleaningPlan,
      isEditable: isEditable,
    };
  }, [assignments, scheduleType, tabFocus]);

  const handleChangeDay = (tab: string) => {
    setScheduleType(CleaningScheduleTypeEnum.Daily);
    setTabFocus(tab);
  };

  return (
    <VStack h="full" w="full">
      <HStack justifyContent="space-between" py={2} px={4} borderBottomWidth={1}>
        {(isLargeScreen || scheduleType === CleaningScheduleTypeEnum.Daily) && (
          <HStack key={locale} alignItems="center" justifyContent="space-between" w={isLargeScreen ? undefined : 'full'}>
            {!isLargeScreen && (
              <Box flex={1} display={{ base: 'none', xl: 'flex' }}>
                <Text size="xl" fontWeight={600} color="black" lineHeight="sm">
                  Daily Tasks
                </Text>
                <Text size="md" fontWeight={400} color="gray.600" lineHeight="xs">
                  {`${formatTo(week.startDate, 'MMM DD')} - ${formatTo(week.startDate.addDays(6), 'MMM DD')}`}
                </Text>
              </Box>
            )}
            <ButtonGroup value={scheduleType === CleaningScheduleTypeEnum.Daily ? tabFocus : undefined} onChange={handleChangeDay}>
              {range(7).map(day => {
                const date = week.startDate.addDays(day);
                const tasks = find(
                  assignments,
                  _ =>
                    _.date.getMonth() === date.getMonth() &&
                    _.date.getDate() === date.getDate() &&
                    _.scheduleType === CleaningScheduleTypeEnum.Daily,
                )?.tasks;

                const completedCount = tasks?.filter(_ => _.assignee?.status === CleaningTaskStatusEnum.Complete).length;

                return (
                  <ButtonGroup.Item
                    key={formatToISOString(date)}
                    pr={1.5}
                    endIcon={
                      !!completedCount ? (
                        <MaterialCommunityIcons
                          name="check-circle-outline"
                          size={20}
                          color={completedCount === tasks?.length ? colors.success[500] : colors.warning[500]}
                        />
                      ) : (
                        <MaterialCommunityIcons name="checkbox-blank-circle-outline" size={20} color={colors.gray[500]} />
                      )
                    }
                    value={formatToISOString(date)}
                  >
                    {formatTo(date, 'ddd')}
                  </ButtonGroup.Item>
                );
              })}
            </ButtonGroup>
          </HStack>
        )}

        {(isLargeScreen || scheduleType !== CleaningScheduleTypeEnum.Daily) && (
          <ButtonGroup value={scheduleType} onChange={setScheduleType}>
            {tabItems.map(tab => {
              const tasks = flatMap(
                filter(assignments, _ => _.scheduleType === tab.value),
                _ => _.tasks,
              );

              const completedCount = tasks.filter(_ => _.assignee?.status === CleaningTaskStatusEnum.Complete).length;

              return (
                <ButtonGroup.Item
                  key={tab.value}
                  pr={1.5}
                  endIcon={
                    !!completedCount ? (
                      <MaterialCommunityIcons
                        name="check-circle-outline"
                        size={20}
                        color={completedCount === tasks?.length ? colors.success[500] : colors.warning[500]}
                      />
                    ) : (
                      <MaterialCommunityIcons name="checkbox-blank-circle-outline" size={20} color={colors.gray[500]} />
                    )
                  }
                  value={tab.value}
                >
                  {tab.title}
                </ButtonGroup.Item>
              );
            })}
          </ButtonGroup>
        )}
      </HStack>

      <Box flex={1} pt={2} pb={3} px={4}>
        {!!cleaningPlan && (
          <CleaningTasksTable
            key={`${scheduleType}:${cleaningPlan.date.toISOString()}`}
            isEditable={isEditable}
            plan={cleaningPlan}
            onOpenLinkDocument={modalCleaningTask.setOpen}
          />
        )}
      </Box>

      <Modal
        _content={{ rounded: '2xl', w: '90%', h: '95%', maxWidth: '1200', overflow: 'hidden' }}
        size="full"
        noPadding
        hideClose
        isOpen={modalCleaningTask.isOpen}
        onClose={modalCleaningTask.setHide}
      >
        {!!modalCleaningTask.payload && <CleaningDocuments cleaningTask={modalCleaningTask.payload} onClose={modalCleaningTask.setHide} />}
      </Modal>
    </VStack>
  );
};
