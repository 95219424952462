import { Icon, IIconProps } from 'native-base';
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg';

export const CheckCircleBrokenIcon = ({ size = 'sm', ...props }: IIconProps) => {
  return (
    <Icon size={size} viewBox="0 0 16 16" fill="none" {...props}>
      <G clipPath="url(#clip0_16462_33155)">
        <Path
          d="M14.6668 7.39052V8.00386C14.666 9.44147 14.2005 10.8403 13.3397 11.9917C12.4789 13.1432 11.269 13.9855 9.8904 14.3931C8.51178 14.8007 7.03834 14.7518 5.68981 14.2536C4.34128 13.7554 3.18993 12.8346 2.40747 11.6286C1.62501 10.4225 1.25336 8.9959 1.34795 7.5614C1.44254 6.1269 1.9983 4.76141 2.93235 3.66857C3.8664 2.57574 5.12869 1.81411 6.53096 1.49729C7.93323 1.18046 9.40034 1.32541 10.7135 1.91052M14.6668 2.66671L8.00016 9.34004L6.00016 7.34004"
          stroke="currentColor"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_16462_33155">
          <Rect width="16" height="16" fill="white" />
        </ClipPath>
      </Defs>
    </Icon>
  );
};
