import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppConfigEmptyState } from '../state/AppConfigState';

export const appConfigSlice = createSlice({
  name: 'appConfig',
  initialState: AppConfigEmptyState,
  reducers: {
    toggleWidget(state, { payload }: PayloadAction<boolean | undefined>) {
      state.widgetIsOpen = payload ?? !state.widgetIsOpen;
    },
  },
});

export const appConfigReducer = appConfigSlice.reducer;
