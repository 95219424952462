import React, { useState } from 'react';
import { Box, HStack, Spacer, TextArea, View } from 'native-base';
import { isEmpty } from 'lodash';
import { useBoolean } from 'usehooks-ts';

import { Button, Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { WeeklyFocusCategoryDto } from '@pimm/services/lib/sms-positioning';
import { WeeklyFocusItemInput } from '@pimm/services/lib/sms-workforce';

type GoalSelectionProps = {
  category: WeeklyFocusCategoryDto;
  input?: WeeklyFocusItemInput;
  title?: React.ReactNode;
  onPressCancel?: () => void;
  onPressSave?: (goal: WeeklyFocusItemInput) => void;
};

export const WeeklyFocusInputBox = ({ category, input, title, ...props }: GoalSelectionProps) => {
  const { translate } = useAppLocale();
  const [description, setDescription] = useState<string>(input?.description ?? '');
  const isDisabled = useBoolean();

  const handlePressSave = () => {
    if (!isEmpty(description) && props.onPressSave) {
      isDisabled.setTrue();

      props.onPressSave({
        ...(input ?? {
          weeklyFocusCategoryId: category.id,
        }),
        description: description,
      });
    }
  };

  return (
    <View px={5}>
      <HStack alignItems="center" minHeight="55px">
        <HStack alignItems="center" space={2}>
          <Box justifyContent="center" h={6} w={6} bg="primary.100" rounded="lg">
            <Text size="md" fontWeight={700} color="black" lineHeight="xs" textAlign="center">
              {category.seq}
            </Text>
          </Box>
          <Text size="lg" fontWeight={700} color="black" lineHeight="xs">
            {translate(category.title, category.translations)}
          </Text>
        </HStack>
      </HStack>

      <TextArea
        _stack={{ py: 2 }}
        autoCapitalize="sentences"
        autoCompleteType={false}
        autoFocus
        height={400}
        fontFamily="body"
        fontSize="md"
        placeholder="Please enter a goal"
        value={description ?? ''}
        onChangeText={setDescription}
      />

      <HStack space={2} py={4}>
        <Spacer />
        <Button size="sm" h={9} minWidth={90} alternate outline onPress={props.onPressCancel}>
          Cancel
        </Button>
        <Button size="sm" h={9} minWidth={90} disabled={isEmpty(description) || isDisabled.value} onPress={handlePressSave}>
          {input ? 'Update' : 'Save'}
        </Button>
      </HStack>
    </View>
  );
};
