import { useMemo } from 'react';
import { VStack, HStack, Pressable } from 'native-base';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { chain, range, countBy } from 'lodash';

import { Text } from '@pimm/base';
import { MessagePriorityEnum, MessageRefTypeEnum } from '@pimm/services/lib/message-board';
import { useMessageBoard } from '@app/features/message-board';
import { WidgetBox, WidgetBoxProps } from '../widget-box';

interface MessageSummary {
  title: string;
  color: string;
  counts: Record<MessagePriorityEnum, number>;
}

type WidgetMessagesProps = WidgetBoxProps & {
  onPressItem: () => void;
};

export const WidgetMessages = ({ title = 'Messages', ...props }: WidgetMessagesProps) => {
  const { messages } = useMessageBoard();

  const summarize: MessageSummary[] = useMemo(() => {
    const categories = [
      { title: 'Brand', color: 'blue' },
      { title: 'Franchise', color: 'violet' },
      { title: 'Store', color: 'gray' },
    ];
    const result = chain(messages.data)
      .groupBy('refType')
      .map((values, refType) => {
        return {
          name: MessageRefTypeEnum[refType],
          messages: countBy(values, 'priority'),
        };
      })
      .reduce((obj: Record<string, Record<number, number>>, item) => {
        return { ...obj, [item.name]: item.messages };
      }, {})
      .value();
    return categories.map(category => {
      return { ...category, counts: result[category.title] };
    });
  }, [messages.data]);

  return (
    <WidgetBox isLoading={messages.isLoading} title={title} {...props}>
      {!messages.isLoading && (
        <VStack w="full" h="full" space={1}>
          {summarize.map((item, num) => {
            return (
              <Pressable key={item.title} onPress={props.onPressItem}>
                <HStack
                  key={item.title}
                  justifyContent="space-between"
                  alignItems="center"
                  rounded="md"
                  py={1}
                  px={2}
                  bgColor={`${item.color}.100`}
                >
                  <Text size="sm" color={`${item.color}.800`} fontWeight={600}>
                    {item.title ?? ''}
                  </Text>
                  <HStack space={2}>
                    {range(2).map(index => {
                      const priority = index ? MessagePriorityEnum.Standard : MessagePriorityEnum.High;
                      return (
                        <HStack
                          key={`${item.title}.${priority}`}
                          px={1}
                          minW="44px"
                          h={5}
                          rounded="sm"
                          bgColor={`${item.color}.400`}
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <MaterialCommunityIcons
                            name={index === 0 ? 'message-alert-outline' : 'message-processing-outline'}
                            color="white"
                            size={16}
                          />
                          <Text flex={1} size="sm" color="white" fontWeight={700} textAlign="center">
                            {(item.counts ? item.counts[priority] : 0) ?? 0}
                          </Text>
                        </HStack>
                      );
                    })}
                  </HStack>
                </HStack>
              </Pressable>
            );
          })}
        </VStack>
      )}
    </WidgetBox>
  );
};
