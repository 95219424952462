import { useEffect, useState } from 'react';
import { HStack, IconButton, View, useMediaQuery } from 'native-base';
import moment from 'moment';

import { ButtonGroup, LightBulbIcon } from '@pimm/base';
import { MainScreenProps } from '@app/navigations/root';
import { formatToISOString } from '@app/utils/date-formatter';
import { MainContainer } from '@app/components/core';
import { ModuleTitle } from '@app/features/app';
import { useSiteConfig, useSiteTime } from '@app/features/store-core';
import { ScreenLayout } from '@app/components/layout';
import { GoalSettingsProvider } from '@app/features/ops-plan/context';
import { useDayBlockFocus } from '@app/hooks/day-blocks.hook';
import { useGetWeeklyFocus } from '@app/features/ops-plan/hooks';
import { useGoalTypes } from '@app/features/store-info';
import { ModalAppDocuments } from '@app/features/store-info/components';
import { DailyPositionalGoals } from './components/daily-positional-goals';
import { WeeklyPositionalGoals } from './components/weekly-positional-goals';

export type OpsPlanScreenProps = MainScreenProps<'OpsPlan'>;

export const OpsPlanScreen: React.FC<OpsPlanScreenProps> = ({ navigation, route: { params, ...route } }) => {
  const goalTypes = useGoalTypes();
  const { siteConfig } = useSiteConfig();
  const siteTime = useSiteTime();
  const dayBlockFocus = useDayBlockFocus();
  const [weeklyFocus, setWeeklyFocusParams] = useGetWeeklyFocus();

  const [isSmallScreen] = useMediaQuery({ maxWidth: 1200 });
  const [tabFocus, setTabFocus] = useState<number>(0);
  const [week, setWeek] = useState<ReturnType<ReturnType<typeof useSiteTime>['toStartEndOfWeek']>>();

  const handleChangeWeek = (date: Date) => {
    setWeek(siteTime.toStartEndOfWeek(date));
  };

  useEffect(() => {
    if (week?.startDate) {
      // Listen and remember the last state
      navigation.setParams({
        ...params,
        startOfWeek: formatToISOString(week.startDate, true),
      });
    }
  }, [week]);

  useEffect(() => {
    if (dayBlockFocus.selected && !week) {
      setWeek(siteTime.toStartEndOfWeek(dayBlockFocus.selected.startTime));
    }
  }, [dayBlockFocus.selected, week]);

  useEffect(() => {
    if (siteConfig?.id && week?.startDate) {
      setWeeklyFocusParams({ siteId: siteConfig.id, weekStartDate: week.startDate });
    }
  }, [siteConfig?.id, week]);

  useEffect(() => {
    if (siteTime.siteId) {
      const startOfWeek = params.startOfWeek ? moment(params.startOfWeek) : undefined;
      const _week = siteTime.toStartEndOfWeek(startOfWeek?.isValid() ? startOfWeek.toDate() : undefined);
      setWeek(_week);
    }
  }, [siteTime.siteId]);

  return (
    <MainContainer>
      <ScreenLayout
        title={
          <HStack alignItems="center">
            <ModuleTitle name={route.name} defaultText="Ops Plan" />
            <ModalAppDocuments
              identifierName="Module.OpsPlan"
              moduleType="Ops Plan"
              trigger={triggerProps => {
                if (!triggerProps.documents?.length) return undefined;
                return (
                  <IconButton
                    rounded="lg"
                    borderWidth={1}
                    borderColor="gray.300"
                    ml={2}
                    p={0}
                    w={9}
                    h={9}
                    icon={<LightBulbIcon size="md" />}
                    onPress={triggerProps.onPress}
                  />
                );
              }}
            />
          </HStack>
        }
        actionTool={
          <HStack space={2} justifyContent="flex-end">
            {isSmallScreen && (
              <ButtonGroup value={tabFocus} onChange={setTabFocus}>
                <ButtonGroup.Item alignItems="center" value={0} minW={120}>
                  Talk to Me
                </ButtonGroup.Item>
                <ButtonGroup.Item alignItems="center" value={1}>
                  Positional Goals
                </ButtonGroup.Item>
              </ButtonGroup>
            )}
          </HStack>
        }
      >
        {!!week && !!siteConfig?.id && (
          <GoalSettingsProvider
            brandId={siteConfig.companyInfo?.brand?.id}
            customerId={siteConfig?.companyInfo?.customerId}
            marketId={siteConfig?.config?.marketId}
          >
            <View display="flex" flexDirection={{ md: 'column', xl: 'row' }} h="full" w="full">
              {(!isSmallScreen || tabFocus === 0) && (
                <WeeklyPositionalGoals
                  goalTypes={goalTypes.data}
                  siteId={siteConfig.id}
                  week={week}
                  weeklyFocus={weeklyFocus}
                  onChangeWeek={handleChangeWeek}
                />
              )}
              {(!isSmallScreen || tabFocus === 1) && (
                <DailyPositionalGoals
                  siteId={siteConfig.id}
                  goalTypeIds={weeklyFocus?.data?.goalTypeIds}
                  marketId={siteConfig?.config?.marketId}
                  brandId={siteConfig.companyInfo?.brand?.id}
                />
              )}
            </View>
          </GoalSettingsProvider>
        )}
      </ScreenLayout>
    </MainContainer>
  );
};
