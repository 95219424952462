import { useTheme } from 'native-base';

import { CheckCircle, MinusCircleIcon } from '@pimm/base';
import { Ellipse51Icon, EllipsePartialIcon, EllipseCompletedIcon } from '../icons';

type ProgressStatusProps = {
  isWeekly: boolean;
  total?: number;
  value?: number;
};

export const ProgressStatus = ({ total = 0, value = 0, isWeekly = false }: ProgressStatusProps) => {
  const { colors } = useTheme();

  if (value === -1 || total === -1) {
    return <Ellipse51Icon size={5} color={colors.gray[400]} />;
  }

  if (value && total) {
    if (value === total) {
      return isWeekly ? (
        <EllipseCompletedIcon size={5} color={colors.success[500]} />
      ) : (
        <CheckCircle size={5} color={colors.success[500]} />
      );
    }
    return <EllipsePartialIcon size={5} color={colors.warning[400]} />;
  }
  return <MinusCircleIcon size={5} color={colors.error[500]} />;
};
