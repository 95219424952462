import React, { createContext, useCallback, useContext, useEffect, useReducer } from 'react';
import { map, sortBy } from 'lodash';

import { formatToISOString, stringToDateLocal } from '@app/utils/date-formatter';
import { CleaningScheduleTypeEnum, CleaningTaskAggDto } from '@pimm/services/lib/sms-workforce/types';
import { useSiteTime } from '@app/features/store-core';
import { CleaningAssignmentsAction, CleaningAssignmentsReducer, CleaningPlan, CleaningTaskAssignee, CleaningTaskItem } from '../reducers';
import { useGetCleaningEmployees, useGetCleaningPlan } from '../hooks';

type Week = {
  weekNumber: number;
  startDate: Date;
  endDate: Date;
};

export type CleaningAssignmentsContextReturn = {
  siteId: string;
  assignments: CleaningPlan[];
  employees: ReturnType<typeof useGetCleaningEmployees>[0];
  updateCleaningTask: (payload: Partial<CleaningTaskItem>) => void;
  week: ReturnType<ReturnType<typeof useSiteTime>['toStartEndOfWeek']>;
};

type CleaningAssignmentsProviderProps = Pick<CleaningAssignmentsContextReturn, 'employees' | 'week'> & {
  children: React.ReactNode;
  cleaningPlan: ReturnType<typeof useGetCleaningPlan>[0];
  siteId: string;
};

const CleaningAssignmentsContext = createContext<CleaningAssignmentsContextReturn>(undefined!);

export const CleaningAssignmentsProvider = ({ children, cleaningPlan, employees, siteId, week }: CleaningAssignmentsProviderProps) => {
  const siteTime = useSiteTime();
  const [assignments, dispatch] = useReducer(CleaningAssignmentsReducer, []);

  const updateCleaningTask: CleaningAssignmentsContextReturn['updateCleaningTask'] = useCallback(
    payload => {
      dispatch({
        type: CleaningAssignmentsAction.UPDATE,
        payload,
      });
    },
    [assignments],
  );

  useEffect(() => {
    let payload: CleaningPlan[] = [];

    if (cleaningPlan.data) {
      payload = map(cleaningPlan.data, day => {
        const tasks =
          day?.categories?.reduce((cleaningTasks: CleaningTaskItem[], _) => {
            const _cleaningTasks =
              _?.schedules?.map(schedule => {
                const _cleaningTasksItems: CleaningTaskItem[] =
                  sortBy(schedule?.cleaningTasks, ['seq'])?.map((task: CleaningTaskAggDto) => {
                    const startTime = schedule.startTime
                      ? new Date(`${formatToISOString(day.date!, true)}T${schedule.startTime}`)
                      : undefined;
                    const endTime = startTime ? startTime.toTimeValue(schedule.endTime) : undefined

                    const taskAssignment = task?.cleaningTaskAssignment;
                    const assignee: CleaningTaskAssignee | undefined = taskAssignment
                      ? {
                          id: taskAssignment.id,
                          employeeId: taskAssignment.employeeId,
                          firstName: taskAssignment.firstName,
                          lastName: taskAssignment.lastName,
                          dueDate: stringToDateLocal(taskAssignment?.dueDate) ?? endTime,
                          status: taskAssignment?.cleaningTaskStatus,
                          statusDate: stringToDateLocal(taskAssignment?.statusDate),
                        }
                      : endTime
                      ? { dueDate: endTime }
                      : undefined;

                    const taskItem: CleaningTaskItem = {
                      id: task?.cleaningTaskId,
                      assignee: assignee,
                      category: _?.title,
                      categoryTranslations: _?.translations,
                      description: task.description,
                      descriptionTranslations: task.descriptionTranslations,
                      linkedDoc: task.linkedDoc,
                      positionId: task.positionId,
                      positionTitle: task?.position?.title,
                      positionTitleTranslations: task?.position?.translations,
                      title: task.title,
                      startTime: startTime,
                      endTime: endTime,
                      translations: task?.titleTranslations,
                    };

                    return taskItem;
                  }) ?? [];

                return _cleaningTasksItems;
              }) ?? [];

            return cleaningTasks.concat(..._cleaningTasks);
          }, []) ?? [];

        const _cleaningPlan: CleaningPlan = {
          date: day?.date ?? siteTime.today(),
          scheduleType: day.scheduleType as CleaningScheduleTypeEnum,
          tasks,
        };

        return _cleaningPlan;
      });
    }

    dispatch({ type: CleaningAssignmentsAction.RESET, payload: payload });
  }, [cleaningPlan.data]);

  return (
    <CleaningAssignmentsContext.Provider value={{ assignments, updateCleaningTask, employees, siteId, week }}>
      {children}
    </CleaningAssignmentsContext.Provider>
  );
};

export const CleaningAssignmentsConsumer = CleaningAssignmentsContext.Consumer;

export const useCleaningAssignments = () => {
  // get the context
  const context = useContext(CleaningAssignmentsContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useCleaningAssignments was used outside of its Provider');
  }
  return context;
};
