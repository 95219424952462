import { CircleIcon, HStack, useMediaQuery, useTheme } from 'native-base';

import { Text } from '@pimm/base';
import { formatToShortTimeOnly, formatToTimeOnly, stringToDateLocal } from '@app/utils/date-formatter';
import { DayBlock } from '@app/features/store-core';
import { PositioningEmployee } from '../reducers';

type EmployeeShiftTimeProps = {
  dayBlock: DayBlock;
  employee: PositioningEmployee;
};

export const EmployeeShiftTime = ({ dayBlock, employee }: EmployeeShiftTimeProps) => {
  const { colors } = useTheme();
  const [isLargeScreen] = useMediaQuery([{ maxWidth: 1200 }]);
  const startTime = stringToDateLocal(employee.shiftStartTime);
  const endTime = stringToDateLocal(employee.shiftEndTime);
  let circleColor = colors.gray[500];

  // When the persons Shift End time is before the active Dayblock end time
  if (dayBlock && endTime) {
    circleColor = colors.success[500];

    if (startTime && startTime < dayBlock.endTime) {
      circleColor = colors.warning[500];
    }
  }

  return (
    <HStack space={1} alignItems="center">
      <Text size="sm" fontWeight={600} color="black" lineHeight="xs" numberOfLines={1}>
        {isLargeScreen
          ? `${formatToShortTimeOnly(startTime)?.replace(' ', '')} - ${formatToShortTimeOnly(endTime)?.replace(' ', '')}`
          : `${formatToTimeOnly(startTime)} - ${formatToTimeOnly(endTime)}`}
      </Text>
      <CircleIcon size={2} color={circleColor} />
    </HStack>
  );
};
