import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDebounce } from 'usehooks-ts';

type DelayedValue<T> = T | undefined;

type DelayedState<T = unknown> = {
  delay?: number;
  initialValue?: T;
  onChange?: (value: T) => void;
};

export const useDelayedState = <T = unknown>(
  props?: DelayedState<T>,
): { value: DelayedValue<T>; setValue: Dispatch<SetStateAction<DelayedValue<T>>> } => {
  const [value, setValue] = useState<DelayedValue<T>>(props?.initialValue);

  const debouncedValue = useDebounce<DelayedValue<T>>(value, props?.delay ?? 500);

  useEffect(() => {
    if (debouncedValue) {
      if (props?.onChange) props.onChange(debouncedValue);
    }
  }, [debouncedValue]);

  return { value: debouncedValue, setValue: setValue };
};
