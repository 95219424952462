import { Dispatch, SetStateAction, useState } from 'react';
import { QueryKey, UseQueryResult, useQuery } from 'react-query';

import { GetDashboardUtilization } from '@pimm/services/lib/sms-workforce/services';
import { GetDashboardUtilizationQueryParams } from '@pimm/services/lib/sms-workforce';

export const useGetDashboardUtilization = (props?: {
  queryKey?: string;
  query?: GetDashboardUtilizationQueryParams;
}): [UseQueryResult<Awaited<ReturnType<typeof GetDashboardUtilization>>>, Dispatch<SetStateAction<GetDashboardUtilizationQueryParams>>] => {
  const [query, setQuery] = useState<GetDashboardUtilizationQueryParams>(props?.query ?? {});
  const dashboardUtilization = useQuery({
    enabled: !!query.weekStartDate && !!query.siteId,
    queryKey: [props?.queryKey ?? 'GetDashboardUtilization', query] as [QueryKey, GetDashboardUtilizationQueryParams],
    queryFn: ({ queryKey: [key, params] }) => {
      return GetDashboardUtilization({
        ...params,
        weekStartDate: params.weekStartDate,
      });
    },
  });

  return [dashboardUtilization, setQuery];
};
