import { memo, useState } from 'react';
import { Box, HStack, Pressable, VStack, View, useMediaQuery, useTheme } from 'native-base';
import { useMutation, useQueryClient } from 'react-query';
import { isEmpty, some } from 'lodash';
import moment from 'moment';

import { Text } from '@pimm/base';
import {
  MessageStatusEnum,
  MessagePriorityEnum,
  MessageBoardApi,
  UpdateStoreMessageStatusRequest,
  StoreMessageLogDto,
} from '@pimm/services/lib/message-board';
import { GetStoreMessageById } from '@pimm/services/lib/message-board/services';
import { ResourceLoader } from '@app/components/shared';
import { useGetStoreMessageById } from './hooks/useGetStoreMessageById';
import { MessageBody } from './components/message-body';
import { DaysCounter } from './components/days-counter';
import { RefTypeIcon } from './components/ref-type-icon';
import { SmallIcon } from './components/small-icon';
import { ResponseButton } from './components/response-button';
import { Chip } from './components/chip';

type MessagePreviewProps = {
  message: ReturnType<typeof useGetStoreMessageById>[0];
  storeName?: string;
  onChange?: (messageStatusRequest: UpdateStoreMessageStatusRequest) => void;
};

const MessagePreview = ({ message, storeName, onChange }: MessagePreviewProps) => {
  const queryClient = useQueryClient();
  const { colors } = useTheme();
  const [isSmallScreen] = useMediaQuery({ maxWidth: 1200 });
  const [isHighPriority] = useState<boolean>(message.data?.priority === MessagePriorityEnum.High);
  const [requireAction] = useState<boolean>(
    some(['Ackd', 'Delivered', 'Read'], key => MessageStatusEnum[key] === message.data?.status),
  );

  const responseColor = isHighPriority ? 'white' : colors.blue[500];
  const publisher = message.data?.publisher;

  const updateStatus = useMutation(MessageBoardApi.UpdateStoreMessageStatus, {
    onSuccess: (data, variables) => {
      const { id, status } = variables;
      // TODO: update specific message data in the context
      if (onChange) onChange({ id, status });

      // Update related message content
      queryClient.setQueryData<Awaited<ReturnType<typeof GetStoreMessageById>>>(
        ['GetStoreMessageById', message.data?.id],
        (message): StoreMessageLogDto => {
          return { ...message, status: status };
        },
      );
    },
  });

  const onPressResponse = () => {
    let status = message.data?.status ?? MessageStatusEnum.Undefined;

    const payload: UpdateStoreMessageStatusRequest = {
      id: message.data?.id,
      status: MessageStatusEnum.Delivered,
    };

    if (status <= MessageStatusEnum.Delivered) {
      payload.status = isHighPriority ? MessageStatusEnum.Ackd : MessageStatusEnum.Read;
    }
    updateStatus.mutate(payload);
  };

  return (
    <View p={{ md: 2, xl: 5 }} w="full" h="full" backgroundColor="gray.50">
      <ResourceLoader h="full" isEmpty={message.isError} emptyMessage="Failed to load message" _text={{ size: 'lg' }}>
        {!!message.data && (
          <VStack
            p={{ md: 4, xl: 5 }}
            h="full"
            rounded="xl"
            overflow="hidden"
            borderWidth="1"
            _web={{
              shadow: 2,
              borderWidth: 0,
              backgroundColor: colors.white,
            }}
          >
            <VStack space={2} pb={3} borderBottomWidth={1}>
              <HStack alignItems="center" justifyContent="space-between">
                <Box flex={1}>
                  <Text
                    size={{ md: '2xl', xl: '4xl' }}
                    fontWeight={700}
                    color="gray.900"
                    lineHeight="sm"
                    numberOfLines={1}
                    ellipsizeMode="tail"
                    width={{ xl: 'full', md: 'half' }}
                  >
                    {message.data.subject ?? ''}
                  </Text>

                  <Text size={{ md: 'md', xl: 'lg' }} fontWeight={700} color="gray.600" lineHeight="sm">
                    From:{' '}
                    <Text size={{ md: 'md', xl: 'lg' }} fontWeight={700} color="gray.900" numberOfLines={1} ellipsizeMode="tail">
                      {!!publisher?.firstName ? [publisher?.firstName, publisher?.lastName].filter(Boolean).join(' ') : `Wendy's`}{' '}
                      {publisher?.email ? `(${publisher.email})` : ''}
                    </Text>
                  </Text>
                </Box>

                {requireAction && (
                  <HStack space={3} alignItems="center" rounded="3xl" py={1}>
                    {isHighPriority && message.data.status === MessageStatusEnum.Delivered && (
                      <HStack space={2} alignItems="center">
                        <SmallIcon important size="18px" />
                        {!isSmallScreen && (
                          <Text size="sm" fontWeight={500} color="error.500">
                            Acknowledgement by manager required
                          </Text>
                        )}
                      </HStack>
                    )}

                    {message.data.status === MessageStatusEnum.Ackd ? (
                      <Box alignItems="flex-end" flex={1}>
                        <Pressable onPress={onPressResponse}>
                          <Chip size="md" value="Acknowledged" _container={{ backgroundColor: colors.success[500] }} />
                        </Pressable>
                      </Box>
                    ) : (
                      // Acknowledged, Mark as Read or Unread
                      <ResponseButton
                        py={0}
                        minWidth="74px"
                        minHeight={{ md: 8, xl: 9 }}
                        disabled={updateStatus.isSuccess && message.isLoading}
                        isLoading={updateStatus.isLoading}
                        _text={{ color: responseColor }}
                        _icon={{ color: responseColor }}
                        _spinner={{ color: responseColor, size: 18 }}
                        isHighPriority={isHighPriority}
                        status={message.data?.status}
                        onPress={onPressResponse}
                      />
                    )}
                  </HStack>
                )}
              </HStack>

              <HStack justifyContent="space-between">
                <HStack space={2} alignItems="center">
                  <RefTypeIcon size={7} refType={message.data.refType} />

                  {/* Display store name */}
                  {!isEmpty(storeName) && (
                    <Text size={{ md: 'md', xl: 'lg' }} fontWeight={700} color="gray.600">
                      To:{' '}
                      <Text size={{ md: 'md', xl: 'lg' }} fontWeight={700} color="gray.900">
                        {storeName}
                      </Text>
                    </Text>
                  )}
                </HStack>

                <HStack space={1} alignItems="center">
                  <SmallIcon category={message.data.category} />

                  {/* Display expiration in days */}
                  <DaysCounter startDate={message.data.expirationDate} />

                  {/* Display publish date */}
                  {!isEmpty(message.data.publishDate) && (
                    <Text size={{ md: 'md', xl: 'lg' }} color="gray.700">
                      {moment(message.data.publishDate).format('MM/DD/YY')}
                    </Text>
                  )}
                </HStack>
              </HStack>
            </VStack>

            {/* Message Body or Document Preview */}
            <View flex={1} pt={3}>
              <MessageBody message={message.data} />
            </View>
          </VStack>
        )}
      </ResourceLoader>
    </View>
  );
};

export default memo(MessagePreview);
