import { Box, Divider, HStack, SectionList, Spacer, VStack } from 'native-base';
import { map, startCase, toLower } from 'lodash';

import { Text } from '@pimm/base';
import { formatToTimeOnly } from '@app/utils/date-formatter';
import { DayBlock } from '@app/features/store-core';

type DailyStaffReviewListProps = {
  dayBlock: DayBlock;
  bgColor?: string;
  color?: string;
  sections: {
    title: string;
    bgColor: string | undefined;
    color: string | undefined;
    data: {
      name: string;
      startTime: Date | undefined;
      endTime: Date | undefined;
    }[][];
  }[];
};

export const DailyStaffReviewList = ({ dayBlock, sections }: DailyStaffReviewListProps) => {
  return (
    <Box h="full">
      <VStack space={1} rounded="xl" p={{ md: 2, xl: 2.5 }} maxHeight="100%" borderWidth={1} bg="gray.50">
        <HStack justifyContent="space-between" pb={2}>
          <Box justifyContent="flex-start">
            <Box justifyContent="center" mb={1} h={5}>
              <Text size="lg" fontWeight={600} color="gray.900" lineHeight="xs">
                {dayBlock.title}
              </Text>
            </Box>
            <Text size="md" fontWeight={400} color="gray.600" lineHeight="xs">
              {dayBlock.subTitle}
            </Text>
          </Box>

          <Box alignItems="flex-end">
            <Box justifyContent="center" mb={2} w="75px" h={5} borderRadius="sm" bg="white">
              <Text size="lg" fontWeight={600} color="gray.900" lineHeight="xs" textAlign="center">
                {formatToTimeOnly(dayBlock.staffStartTime ?? dayBlock.startTime)}
              </Text>
            </Box>

            <HStack
              space={2}
              alignItems="center"
              justifyContent="space-between"
              rounded="full"
              pl={2}
              pr={1}
              h={7}
              borderWidth={1}
              borderColor="blueLight.700"
              bg="blueLight.200"
            >
              <Text size="sm" fontWeight={500} color="blueLight.700" lineHeight="xs">
                Starting Staff
              </Text>

              <Box alignItems="center" justifyContent="center" rounded="full" w={5} h={5} bg="white">
                <Text size="sm" fontWeight={500} color="blueLight.700" lineHeight="xs">
                  {sections[0].data[0].length}
                </Text>
              </Box>
            </HStack>
          </Box>
        </HStack>

        <Box flex={1}>
          <SectionList
            borderRadius="lg"
            borderWidth={1}
            borderColor="gray.200"
            overflow="hidden"
            removeClippedSubviews
            scrollEnabled
            stickyHeaderIndices={[0]}
            sections={sections}
            ListHeaderComponent={() => (
              <HStack alignItems="center" pl={2.5} h={7} bg="gray.200">
                <Box flex={1}>
                  <Text size="sm" fontWeight={500} color="gray.900">
                    Name
                  </Text>
                </Box>
                <HStack space={1} alignItems="center" justifyContent="center" w="70px">
                  <Text size="sm" fontWeight={500} color="gray.900">
                    Shift Start
                  </Text>
                </HStack>
              </HStack>
            )}
            renderSectionHeader={({ section }) => (
              <HStack alignItems="center" pl={2.5} h={5} bg={section.bgColor ?? 'gray.600'}>
                <Text size="sm" fontWeight={600} color={section.color ?? 'white'}>
                  {section.title}
                </Text>
              </HStack>
            )}
            renderItem={({ item: staffs, section }) => {
              return (
                <VStack bg={section.bgColor ? 'blueLight.50' : 'white'}>
                  {/* Display empty placeholder */}
                  {staffs.length === 0 && (
                    <Box justifyContent="center" minH={6}>
                      <Text size="sm" fontWeight={500} color="gray.500" textAlign="center">
                        {`No employees ${toLower(section.title)}`}
                      </Text>
                    </Box>
                  )}
                  {/* Render each staff information */}
                  {map(staffs, (staff, index) => (
                    <HStack key={`${section.title}:${index}`} alignItems="center" pl={2.5} minH={7} borderTopWidth={index ? 1 : 0}>
                      <Text size="sm" fontWeight={500} color="gray.900" lineHeight="sm" numberOfLines={1} ellipsizeMode="tail">
                        {startCase(toLower(staff.name))}
                      </Text>
                      <Spacer />
                      <Divider orientation="vertical" bg="gray.200" />
                      <Box alignItems="center" w="70px">
                        <Text size="sm" fontWeight={500} color="gray.900">
                          {formatToTimeOnly(staff.startTime)}
                        </Text>
                      </Box>
                    </HStack>
                  ))}
                </VStack>
              );
            }}
          />
        </Box>
      </VStack>
    </Box>
  );
};
