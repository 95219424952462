import { useState, useMemo, useEffect } from 'react';
import { Box, HStack, FlatList, Pressable, VStack, IBoxProps, useMediaQuery, useTheme } from 'native-base';
import FeatherIcons from 'react-native-vector-icons/Feather';
import { chain, countBy } from 'lodash';

import { ButtonGroup, Text, SearchField } from '@pimm/base';
import { MessagePriorityEnum, MessageRefTypeEnum, MessageStatusEnum, StoreMessageLogDto } from '@pimm/services/lib/message-board';
import { formatTo } from '@app/utils/date-formatter';
import { ResourceLoader } from '@app/components/shared';
import { useGetStoreMessages } from './hooks/useGetStoreMessages';
import { DaysCounter } from './components/days-counter';
import { SmallIcon } from './components/small-icon';
import { Counter } from './components/counter';
import { RefTypeIcon } from './components/ref-type-icon';
import { AlertTriangleIcon, MessageNotificationIcon } from './icons';

type MessageFilterBy = 'unread' | 'important' | 'all';

type MessageInboxProps = {
  _container?: IBoxProps;
  autoSelectFirstMessage?: Boolean;
  messages: ReturnType<typeof useGetStoreMessages>[0];
  messageId?: string;
  storeName?: string;
  onFilteredMessages?: (messages: StoreMessageLogDto[]) => void;
  onPressMessage?: (message?: StoreMessageLogDto) => void;
};

export const MessageInbox = ({ _container, autoSelectFirstMessage, messageId, messages, storeName, ...props }: MessageInboxProps) => {
  const { colors } = useTheme();
  const [filterBy, setFilterBy] = useState<MessageFilterBy>('all');
  const [keyword, setKeyword] = useState<string>();
  const [isSmallScreen] = useMediaQuery({ maxWidth: 1600 });

  const filteredMessages = useMemo(() => {
    return chain(messages.data)
      .filter(
        message =>
          !!message.status &&
          message.status <= MessageStatusEnum.Ackd &&
          (filterBy === 'all' ||
            (filterBy === 'unread' ? message.status <= MessageStatusEnum.Delivered : message.priority === MessagePriorityEnum.High)) &&
          (!keyword || (message.subject?.toLowerCase().includes(keyword?.toLowerCase()) ?? false)),
      )
      .orderBy(['publishDate'], ['desc'])
      .value();
  }, [messages.data, filterBy, keyword]);

  const messageCounts = useMemo(() => {
    const allCount = countBy(messages.data, message => message.status && message?.status <= MessageStatusEnum.Ackd).true;
    const unreadCount = countBy(messages.data, message => message.status && message?.status <= MessageStatusEnum.Delivered).true;
    const importantCount = countBy(messages.data, message => message.priority === MessagePriorityEnum.High).true;
    return { unreadCount, importantCount, allCount };
  }, [messages.data]);

  const handlePressMessage = (message?: StoreMessageLogDto) => {
    if (props.onPressMessage) props.onPressMessage(message);
  };

  const handleChangeFilterBy = value => {
    if (autoSelectFirstMessage) {
      handlePressMessage(undefined);
    }
    setFilterBy(value);
  };

  useEffect(() => {
    if (props?.onFilteredMessages) props.onFilteredMessages(filteredMessages);
  }, [filteredMessages]);

  return (
    <VStack h="full" w="full" {..._container}>
      <VStack space={2.5} pt={2.5} pb={2} px={{ md: 3, xl: 4 }} borderBottomWidth={1}>
        <ButtonGroup w="full" value={filterBy} onChange={handleChangeFilterBy}>
          <ButtonGroup.Item
            flex={1}
            px={{ md: 1 }}
            maxW={{ md: '90px', xl: undefined }}
            value="unread"
            startIcon={<MessageNotificationIcon size={18} />}
            endIcon={!isSmallScreen ? <Counter count={messageCounts.unreadCount} /> : undefined}
          >
            Unread
          </ButtonGroup.Item>
          <ButtonGroup.Item
            flex={1}
            px={{ md: 1 }}
            value="important"
            startIcon={<AlertTriangleIcon color={colors.gray[600]} size={17} />}
            endIcon={!isSmallScreen ? <Counter count={messageCounts.importantCount} /> : undefined}
          >
            Important
          </ButtonGroup.Item>
          <ButtonGroup.Item
            flex={{ xl: 1 }}
            px={{ md: 1 }}
            value="all"
            minWidth={{ md: '80px' }}
            maxW={{ xl: '110px' }}
            startIcon={<FeatherIcons name="layers" size={16} />}
            endIcon={!isSmallScreen ? <Counter count={messageCounts.allCount} /> : undefined}
          >
            All
          </ButtonGroup.Item>
        </ButtonGroup>
        <SearchField onChangeText={setKeyword} onClear={() => setKeyword(undefined)} />
      </VStack>

      <Box flex={1}>
        <ResourceLoader h="full" isEmpty={filteredMessages.length === 0} isLoading={messages.isLoading}>
          <FlatList
            h="full"
            w="full"
            data={filteredMessages}
            scrollEnabled
            renderItem={({ item: message }) => {
              const isActive = message.id === messageId;
              const status = message.status ?? MessageStatusEnum.Undefined;

              let receiverName = message.chainName || storeName;

              if (message.refType === MessageRefTypeEnum.Brand) {
                receiverName = 'All Stores';
              }

              return (
                <Pressable w="full" onPress={() => handlePressMessage(message)}>
                  {({ isHovered }) => (
                    <HStack
                      space={2}
                      py={2}
                      pl={{ md: 3, xl: 4 }}
                      pr={{ md: 2, xl: 4 }}
                      minHeight="50px"
                      borderBottomWidth={1}
                      backgroundColor={isActive || isHovered ? 'gray.50' : 'white'}
                    >
                      <HStack position="relative" alignItems="center" pl={1}>
                        {status <= MessageStatusEnum.Delivered && (
                          <Box
                            position="absolute"
                            left={-7}
                            w={1.5}
                            h={1.5}
                            rounded="full"
                            bg={message.priority ? colors.error[500] : colors.blue[500]}
                          />
                        )}
                        <RefTypeIcon refType={message.refType} />
                      </HStack>

                      <Box flex={1}>
                        <HStack alignItems="center" justifyContent="space-between">
                          <Text size={{ sm: 'sm', md: 'md', xl: 'lg' }} fontWeight={700} color="gray.900">
                            {receiverName?.toUpperCase()}
                          </Text>

                          {!!message.publishDate && (
                            <Text size={{ sm: 'sm', xl: 'md' }} color="gray.900">
                              {formatTo(message.publishDate, 'MM/DD/YY')}
                            </Text>
                          )}
                        </HStack>

                        <HStack alignItems="center" justifyContent="space-between">
                          <Box flex={1}>
                            <Text size={{ md: 'md', xl: 'lg' }} color="gray.900" numberOfLines={1} ellipsizeMode="tail">
                              {message.subject ?? ''}
                            </Text>
                          </Box>

                          <HStack flex={1} space={1} alignItems="center" justifyContent="flex-end">
                            <SmallIcon important={!!message.priority} size={{ md: '16px', xl: '18px' }} />
                            <SmallIcon category={message.category} size={{ md: '16px', xl: '18px' }} />
                            <DaysCounter startDate={message.expirationDate} />
                          </HStack>
                        </HStack>
                      </Box>
                    </HStack>
                  )}
                </Pressable>
              );
            }}
          />
        </ResourceLoader>
      </Box>
    </VStack>
  );
};
