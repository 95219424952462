import { useMutation } from 'react-query';
import { Box, HStack, IconButton, Spinner, useTheme } from 'native-base';
import FeatherIcons from 'react-native-vector-icons/Feather';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { startCase, toLower } from 'lodash';

import { PressableSelect, Text } from '@pimm/base';
import { ShiftDto } from '@pimm/services/lib/sms-workforce';
import { DeleteSchedule } from '@pimm/services/lib/sms-workforce/services';
import { convertMinutesToHoursAndMinutes, formatToTimeOnly } from '@app/utils/date-formatter';

type EmployeeScheduleProps = {
  employee: ShiftDto;
  isDisabled?: boolean;
  isMultipleSchedules?: boolean;
  onEdit?: (employee: ShiftDto) => void;
  onDelete?: (id: number) => void;
};

export const EmployeeSchedule = ({ employee, isDisabled, isMultipleSchedules, ...props }: EmployeeScheduleProps) => {
  const { colors } = useTheme();

  const deleteSchedule = useMutation({
    mutationFn: DeleteSchedule,
    onSuccess: (data, params) => {
      if (props.onDelete) props.onDelete(params?.id!);
    },
  });

  const handlePressEdit = () => {
    if (props.onEdit) props.onEdit(employee);
  };

  const handlePressDelete = () => {
    deleteSchedule.mutate({ id: employee.id });
  };

  return (
    <HStack h="54px" borderBottomWidth={1}>
      <Box flex={1} h="full" pl={4} pr={2} justifyContent="center" borderRightWidth={1}>
        <Text size="lg" fontWeight={600} color="gray.900">
          {startCase(toLower([employee.employee?.firstName, employee.employee?.lastName].join(' ')))}
        </Text>
      </Box>
      <Box
        flex={1}
        position="relative"
        h="full"
        px={2}
        maxW={144}
        justifyContent="center"
        borderRightWidth={1}
        bg={(employee && (isMultipleSchedules ? 'red.500' : !employee.isImport && 'warning.200')) || undefined}
      >
        {!isDisabled ? (
          <PressableSelect
            h={9}
            value={formatToTimeOnly(employee.startTime) ?? ''}
            onPress={handlePressEdit}
            borderColor={employee.isImport ? 'gray.300' : 'white'}
            bgColor="white"
          />
        ) : (
          <Text size="lg" fontWeight={600} color="gray.900" textAlign="center" lineHeight="xs">
            {formatToTimeOnly(employee.startTime) ?? ''}
          </Text>
        )}
      </Box>
      <Box
        flex={1}
        h="full"
        px={2}
        maxW={144}
        justifyContent="center"
        borderRightWidth={1}
        bg={(employee && (isMultipleSchedules ? 'red.500' : !employee.isImport && 'warning.200')) || undefined}
      >
        {!isDisabled ? (
          <PressableSelect
            h={9}
            value={formatToTimeOnly(employee.endTime) ?? ''}
            onPress={handlePressEdit}
            borderColor={employee && isMultipleSchedules && 'red.500' || 'gray.300'}
            bgColor="white"
          />
        ) : (
          <Text size="lg" fontWeight={600} color="gray.900" textAlign="center" lineHeight="xs">
            {formatToTimeOnly(employee.endTime) ?? ''}
          </Text>
        )}
      </Box>
      <Box flex={1} h="full" px={2} maxW={20} justifyContent="center" alignItems="center" borderRightWidth={1}>
        {employee.startTime && employee.endTime && employee.startTime.slice(0, 10) !== employee.endTime.slice(0, 10) ? (
          <MaterialCommunityIcons name="clock-plus-outline" size={20} color={colors.blue[500]} />
        ) : null}
      </Box>
      <Box flex={1} h="full" maxW={90} alignItems="center" justifyContent="center" borderRightWidth={1}>
        <Text size="lg" fontWeight={600} color="gray.900" backgroundColor="gray.400">
          {convertMinutesToHoursAndMinutes(employee.durationInMinutes ?? 0)}
        </Text>
      </Box>
      {!isDisabled && (
        <Box flex={1} h="full" maxW={70} alignItems="center" justifyContent="center">
          {!deleteSchedule.isLoading ? (
            <IconButton p={1} icon={<FeatherIcons name="trash-2" size={18} color={colors.primary[500]} />} onPress={handlePressDelete} />
          ) : (
            <Spinner size={18} color="gray.400" />
          )}
        </Box>
      )}
    </HStack>
  );
};
