import { useState } from 'react';
import { useMutation } from 'react-query';
import { Box, CheckIcon, HStack, Pressable, VStack } from 'native-base';
import { filter, first, map, range, some } from 'lodash';
import moment from 'moment';

import { Button, Text } from '@pimm/base';
import { useStoreHoursConfig } from '@pimm/common';
import { CopyDailyPositionGoalsRequest, SmsWorkforceApi } from '@pimm/services/lib/sms-workforce';
import { formatTo, formatToISOString } from '@app/utils/date-formatter';
import { useDailyPositionGoals } from '../context';

type CopyDailyGoalsProps = {
  onPressCancel?: () => void;
};

export const CopyDailyGoals = (props: CopyDailyGoalsProps) => {
  const { selectISOWeek, siteId, utcOffset } = useStoreHoursConfig();
  const { dayBlocks, dailyGoals } = useDailyPositionGoals();
  const copyFromGoalDate = dayBlocks && dayBlocks[0].startTime;

  const startOfWeek = selectISOWeek(first(dayBlocks)?.startTime).startDate;
  const today = new Date().targetOffset(utcOffset);

  const [weekDays, setWeekDays] = useState<{ date: Date; isChecked: boolean; isDisabled?: boolean }[]>(
    range(7).map(day => {
      const date = moment(startOfWeek).add(day, 'days').toDate();

      return {
        date: date,
        isChecked: false,
        isDisabled:
          moment(date).isBefore(today, 'day') || (moment(date).isSame(today, 'day') && moment(copyFromGoalDate).isSame(date, 'day')),
      };
    }),
  );

  const enableSave = some(weekDays, day => day.isChecked === true);

  const copyDailyGoals = useMutation({
    mutationFn: SmsWorkforceApi.CopyDailyPositionGoals,
    onSuccess: () => dailyGoals.refetch(),
  });

  const handlePressSelect = (index: number) => () => {
    // If the index is -1, select all
    setWeekDays(prev =>
      map(prev, (day, i) => {
        return {
          ...day,
          isChecked: (!day.isDisabled && index === -1) || day.isChecked !== (i === index),
        };
      }),
    );
  };

  const handlePressSave = async () => {
    const checkedDates = filter(weekDays, day => day.isChecked);

    if (checkedDates.length) {
      const payload: CopyDailyPositionGoalsRequest = {
        copyFromGoalDate: formatToISOString(copyFromGoalDate || today),
        copyToGoalDates: map(checkedDates, day => formatToISOString(day.date)),
        siteId: siteId,
      };
      await copyDailyGoals.mutateAsync(payload);
      // Force close this modal
      if (props.onPressCancel) props.onPressCancel();
    }
  };

  return (
    <Box py={4} px={5}>
      <Box minHeight={10}>
        <Text size="xl" fontWeight={700} color="black">
          {`Copy goals from ${formatTo(copyFromGoalDate || today, 'MMM D')}`}
        </Text>
      </Box>

      <HStack alignItems="center" justifyContent="space-between">
        <Text size="md" fontWeight={500} color="gray.700">
          Select dates to copy goals to:
        </Text>
        <Button size="sm" py={0} minH={7} h={8} background="primary.25" _text={{ color: 'primary.600' }} onPress={handlePressSelect(-1)}>
          Select all
        </Button>
      </HStack>

      <HStack rounded="lg" my={2} borderWidth={1} overflow="hidden">
        {weekDays.map((day, index) => {
          return (
            <VStack flex={1} key={index} bg={day.isDisabled ? 'gray.25' : undefined}>
              <Box p={2} borderBottomWidth={1} borderLeftWidth={index ? 1 : 0}>
                <Text size="md" fontWeight={600} color="gray.700" textAlign="center" lineHeight="sm">
                  {`${formatTo(day.date, 'dddd')}\n${formatTo(day.date, 'MMM D')}`}
                </Text>
              </Box>
              <Box alignItems="center" justifyContent="center" py={3} borderLeftWidth={index ? 1 : 0}>
                <Pressable rounded="md" disabled={day.isDisabled} onPress={handlePressSelect(index)}>
                  {({ isHovered }) => (
                    <Box
                      rounded="md"
                      alignItems="center"
                      justifyContent="center"
                      w={6}
                      h={6}
                      borderWidth={1}
                      borderColor={day.isChecked || isHovered ? 'primary.600' : 'gray.300'}
                      bgColor={day.isChecked || isHovered ? 'primary.50' : day.isDisabled ? 'gray.100' : undefined}
                    >
                      <CheckIcon size={4} color="primary.600" opacity={day.isChecked ? 1 : isHovered ? 0.5 : 0} />
                    </Box>
                  )}
                </Pressable>
              </Box>
            </VStack>
          );
        })}
      </HStack>

      <Text size="md" fontWeight={500} color="gray.700">
        Only dates in the future are allowed.
      </Text>

      <HStack space={2} justifyContent="flex-end">
        <Button size="sm" h={9} minWidth={90} alternate outline onPress={props.onPressCancel}>
          Cancel
        </Button>
        <Button size="sm" h={9} minWidth={90} disabled={!enableSave} isLoading={copyDailyGoals.isLoading} onPress={handlePressSave}>
          Save
        </Button>
      </HStack>
    </Box>
  );
};
