import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en_US from './en-US';
import es_US from './es-US';

const resources = {
  'en-US': en_US,
  'es-US': es_US,
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    compatibilityJSON: 'v3', //To make it work for Android devices, add this line.
    resources,
    lng: 'en-US', // default language to use.
    fallbackLng: 'en-US', // language to use if translations in user language are not available.
    // if you're using a language detector, do not define the lng option
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
