import { Icon, IIconProps } from 'native-base';
import { Path } from 'react-native-svg';

export const Thermometer02Icon = ({ size = 'sm', ...props }: IIconProps) => {
  return (
    <Icon size={size} viewBox="0 0 8 16" fill="none" {...props}>
      <Path
        d="M5.90623 2.99992C5.90623 2.07944 5.16004 1.33325 4.23956 1.33325C3.31909 1.33325 2.5729 2.07944 2.5729 2.99992V9.17177C1.76891 9.70994 1.23956 10.6264 1.23956 11.6666C1.23956 13.3234 2.58271 14.6666 4.23956 14.6666C5.89642 14.6666 7.23956 13.3234 7.23956 11.6666C7.23956 10.6264 6.71022 9.70994 5.90623 9.17177V2.99992Z"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <Path
        d="M4.23956 12.3333C4.60775 12.3333 4.90623 12.0348 4.90623 11.6666C4.90623 11.2984 4.60775 10.9999 4.23956 10.9999C3.87137 10.9999 3.5729 11.2984 3.5729 11.6666C3.5729 12.0348 3.87137 12.3333 4.23956 12.3333Z"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Icon>
  );
};