import { useMutation } from 'react-query';
import { HStack, View, Box, useTheme, IconButton, CloseIcon } from 'native-base';

import { AlertCircleIcon, Button, Text } from '@pimm/base';
import { AddUpdateOpsPhaseProfile } from '@pimm/services/lib/sms-workforce/services';
import { AddUpdateOpsPhaseProfileRequest } from '@pimm/services/lib/sms-workforce';

type SelectConfirmSalesVolumeProfileProps = AddUpdateOpsPhaseProfileRequest & {
  confirmRefetch?: () => void;
  onClose?: () => void;
};

export const SelectConfirmSalesVolumeProfile = ({
  opsPhaseId,
  salesVolumeProfileId,
  siteId,
  ...props
}: SelectConfirmSalesVolumeProfileProps) => {
  const { colors } = useTheme();
  const addUpdateOpsPhaseProfile = useMutation({ mutationFn: AddUpdateOpsPhaseProfile });

  const handlePressConfirm = async () => {
    if (opsPhaseId && salesVolumeProfileId && siteId) {
      await addUpdateOpsPhaseProfile.mutateAsync({
        opsPhaseId: opsPhaseId,
        salesVolumeProfileId: salesVolumeProfileId,
        siteId: siteId,
      });
      if (props.confirmRefetch) props.confirmRefetch();
      if (props.onClose) props.onClose();
    }
  };

  return (
    <View>
      <HStack alignItems="flex-start" justifyContent="space-between" mb={4}>
        <Box flex="none" rounded="full" p={2} bg="error.50">
          <Box rounded="full" p={2} bg="error.100">
            <AlertCircleIcon size={5} color={colors.error[600]} />
          </Box>
        </Box>
        <IconButton
          p={0}
          w={8}
          h={8}
          _pressed={{ bg: 'gray.50' }}
          _hover={{ bg: 'gray.50' }}
          icon={<CloseIcon size={4} color="gray.700" />}
          onPress={props.onClose}
        />
      </HStack>

      <Box mb={3}>
        <Text color="black" fontWeight={600} size="xl" mb={2} lineHeight="sm">
          Warning
        </Text>
        <Text color="gray.900" fontWeight={500} size="md" mb={3} lineHeight="sm">
          Changing the Sales Volume will erase all task history.{`\n\n`}
          Do you want to proceed?
        </Text>
      </Box>

      <HStack space={2}>
        <Button
          flex={1}
          borderWidth={1}
          borderColor="gray.300"
          h={9}
          alternate
          outline
          disabled={addUpdateOpsPhaseProfile.isLoading}
          onPress={props.onClose}
        >
          Cancel
        </Button>
        <Button
          flex={1}
          h={9}
          borderWidth={1}
          borderColor="error.600"
          bgColor="error.600"
          isLoading={addUpdateOpsPhaseProfile.isLoading}
          onPress={handlePressConfirm}
        >
          Continue
        </Button>
      </HStack>
    </View>
  );
};
