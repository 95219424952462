import { useMemo } from 'react';
import { Box, HStack, ScrollView, VStack } from 'native-base';
import { flatten, map, partition, sum, sumBy, values } from 'lodash';

import { Text } from '@pimm/base';
import { diagnosticsFilter } from '../_helper';
import { FilterDisplay } from './filter-buttons';
import { useEquipmentSummary } from '../context';

interface SensorSummary {
  description?: string;
  upTimePercentage?: number;
  batteryEvents?: number;
  offlineEvents?: number;
  oos?: string;
  data?: number;
  offlineDuration?: string;
}

type DiagnosticsStoreViewProps = {
  filter: FilterDisplay;
};

export const DiagnosticsStoreView = ({ filter }: DiagnosticsStoreViewProps) => {
  const sensorGroups = useEquipmentSummary();

  const tableData = useMemo(() => {
    const _displayGroups: Record<
      string,
      {
        name: string;
        equipment: SensorSummary[];
        total: {
          batteryEvents: number;
          offlineEvents: number;
        };
      }
    > = {};

    sensorGroups.forEach(_group => {
      _group.equipment.forEach(_equipment => {
        [_equipment.temperature, _equipment.door].forEach(_sensor => {
          const isValid = !!_sensor && diagnosticsFilter(filter, _sensor);

          if (isValid) {
            const groupName = _sensor?.DoorInstanceId ? 'Diagnostics' : _group.displayGroup?.trim();
            const _sensorSummary: SensorSummary = {
              description: _sensor.Label ?? _equipment.equipment?.TemperatureMetrics?.Temperature?.Description,
              upTimePercentage: _sensor?.UptimePct ?? 0,
              batteryEvents: sum(values(_sensor?.BatteryEvents).filter(Boolean)),
              offlineEvents: sum(values(_sensor?.OfflineEvents).filter(Boolean)),
              oos: _sensor?.OosDuration,
              data: _sensor?.UploadPct ?? 0,
              offlineDuration: _sensor?.OfflineDuration,
            };

            const items = [...(_displayGroups[groupName]?.equipment ?? []), _sensorSummary];

            _displayGroups[groupName] = {
              name: groupName,
              equipment: items,
              total: {
                batteryEvents: sumBy(items, 'batteryEvents'),
                offlineEvents: sumBy(items, 'offlineEvents'),
              },
            };
          }
        });
      });
    }, []);

    return {
      sections: flatten(partition(values(_displayGroups), _ => _.name !== 'Diagnostics')),
    };
  }, [sensorGroups, filter]);

  return (
    <ScrollView flex={1} p={3} h="full" _contentContainerStyle={{ flexGrow: 1 }}>
      {tableData.sections.length > 0 ? (
        map(tableData.sections, _section => (
          <VStack key={_section.name} space={2} overflow="hidden">
            <Text size="xl" fontWeight={700} color="gray.900">
              {_section.name}
            </Text>

            <VStack rounded="lg" mb={3} borderWidth={1}>
              {/* Header */}
              <HStack roundedTop="lg" height={12} bg="gray.50" borderBottomWidth={1}>
                <Box justifyContent="center" pr={3} pl={4} h="full" w={{ base: '30%', xl: '40%' }}>
                  <Text size="md" fontWeight={700} color="gray.700" lineHeight="xs" textTransform="uppercase">
                    Sensor
                  </Text>
                </Box>
                <HStack flex={1} h="full">
                  <Box justifyContent="center" px={3} h="full" w="1/6" borderLeftWidth={1}>
                    <Text size="md" fontWeight={700} color="gray.700" lineHeight="xs" textTransform="uppercase" numberOfLines={2}>
                      Sensor Uptime (%)
                    </Text>
                  </Box>
                  <Box justifyContent="center" px={3} h="full" w="1/6" borderLeftWidth={1}>
                    <Text size="md" fontWeight={700} color="gray.700" lineHeight="xs" textTransform="uppercase" numberOfLines={2}>
                      Data Upload (%)
                    </Text>
                  </Box>
                  <Box justifyContent="center" px={3} h="full" w="1/6" borderLeftWidth={1}>
                    <Text size="md" fontWeight={700} color="gray.700" lineHeight="xs" textTransform="uppercase" numberOfLines={2}>
                      Offline Duration
                    </Text>
                  </Box>
                  <Box justifyContent="center" px={3} pr={{ base: 1, xl: 3 }} h="full" w="1/6" borderLeftWidth={1}>
                    <Text size="md" fontWeight={700} color="gray.700" lineHeight="xs" textTransform="uppercase" numberOfLines={2}>
                      Disabled (OOS) Duration
                    </Text>
                  </Box>
                  <Box justifyContent="center" px={3} h="full" w="1/6" borderLeftWidth={1}>
                    <Text size="md" fontWeight={700} color="gray.700" lineHeight="xs" textTransform="uppercase" numberOfLines={2}>
                      Offline{`\n`}Events
                    </Text>
                  </Box>
                  <Box justifyContent="center" px={3} h="full" w="1/6" borderLeftWidth={1}>
                    <Text size="md" fontWeight={700} color="gray.700" lineHeight="xs" textTransform="uppercase" numberOfLines={2}>
                      Battery{`\n`}Events
                    </Text>
                  </Box>
                </HStack>
              </HStack>

              {/* Body */}
              {map(_section.equipment, _equipment => {
                const { upTimePercentage, data, offlineDuration, oos, offlineEvents, batteryEvents, description } = _equipment;
                return (
                  <HStack key={_section.name} height={10} borderBottomWidth={1} bg="white">
                    <Box justifyContent="center" pr={3} pl={4} h="full" w={{ base: '30%', xl: '40%' }}>
                      <Text size="md" fontWeight={500} color="black" lineHeight="xs">
                        {description}
                      </Text>
                    </Box>
                    <HStack flex={1} h="full">
                      <Box justifyContent="center" px={3} h="full" w="1/6" borderLeftWidth={1}>
                        <Text size="md" fontWeight={500} color="black" lineHeight="xs">
                          {upTimePercentage?.toFixed(1)}
                        </Text>
                      </Box>
                      <Box justifyContent="center" px={3} h="full" w="1/6" borderLeftWidth={1}>
                        <Text size="md" fontWeight={500} color="black" lineHeight="xs">
                          {data?.toFixed(1)}
                        </Text>
                      </Box>
                      <Box justifyContent="center" px={3} h="full" w="1/6" borderLeftWidth={1}>
                        <Text size="md" fontWeight={500} color="black" lineHeight="xs">
                          {offlineDuration ?? '00:00:00'}
                        </Text>
                      </Box>
                      <Box justifyContent="center" px={3} h="full" w="1/6" borderLeftWidth={1}>
                        <Text size="md" fontWeight={500} color="black" lineHeight="xs">
                          {oos ?? '00:00:00'}
                        </Text>
                      </Box>
                      <Box justifyContent="center" px={3} h="full" w="1/6" borderLeftWidth={1}>
                        <Text size="md" fontWeight={500} color="black" lineHeight="xs">
                          {offlineEvents}
                        </Text>
                      </Box>
                      <Box justifyContent="center" px={3} h="full" w="1/6" borderLeftWidth={1}>
                        <Text size="md" fontWeight={500} color="black" lineHeight="xs">
                          {batteryEvents}
                        </Text>
                      </Box>
                    </HStack>
                  </HStack>
                );
              })}

              {/* Footer */}
              <HStack roundedBottom="lg" height={10} bg="white">
                <Box alignItems="flex-end" justifyContent="center" px={3} h="full" w={{ base: '30%', xl: '40%' }}>
                  <Text size="md" fontWeight={700} color="black" lineHeight="xs" textTransform="uppercase">
                    Total
                  </Text>
                </Box>
                <HStack flex={1} h="full">
                  <Box h="full" w="1/6" borderLeftWidth={1} bg="gray.50" />
                  <Box h="full" w="1/6" borderLeftWidth={1} bg="gray.50" />
                  <Box h="full" w="1/6" borderLeftWidth={1} bg="gray.50" />
                  <Box h="full" w="1/6" borderLeftWidth={1} bg="gray.50" />
                  <Box justifyContent="center" px={3} h="full" w="1/6" borderLeftWidth={1}>
                    <Text size="md" fontWeight={700} color="black" lineHeight="xs" textTransform="uppercase">
                      {_section.total.batteryEvents}
                    </Text>
                  </Box>
                  <Box justifyContent="center" px={3} h="full" w="1/6" borderLeftWidth={1}>
                    <Text size="md" fontWeight={700} color="black" lineHeight="xs" textTransform="uppercase">
                      {_section.total.offlineEvents}
                    </Text>
                  </Box>
                </HStack>
              </HStack>
            </VStack>
          </VStack>
        ))
      ) : (
        <Box flex={1} alignItems="center" justifyContent="center" p={3} textAlign="center">
          <Text size="lg" fontWeight={600} color="gray.500">
            No Data Available
          </Text>
        </Box>
      )}
    </ScrollView>
  );
};
