import { Icon, IIconProps } from 'native-base';
import { Path } from 'react-native-svg';

export const AlertOctagonIcon = ({ size = 'sm', ...props }: IIconProps) => {
  return (
    <Icon size={size} viewBox="0 0 12 13" fill="none" {...props}>
      <Path
        d="M6.00016 4.33337V6.50004M6.00016 8.66671H6.00558M0.583496 4.61653V8.38356C0.583496 8.51604 0.583496 8.58229 0.598462 8.64462C0.611731 8.69989 0.633617 8.75273 0.663316 8.80119C0.696814 8.85586 0.743655 8.9027 0.837337 8.99638L3.50382 11.6629C3.5975 11.7565 3.64435 11.8034 3.69901 11.8369C3.74747 11.8666 3.80031 11.8885 3.85558 11.9017C3.91792 11.9167 3.98416 11.9167 4.11665 11.9167H7.88368C8.01616 11.9167 8.08241 11.9167 8.14475 11.9017C8.20002 11.8885 8.25285 11.8666 8.30132 11.8369C8.35598 11.8034 8.40282 11.7565 8.4965 11.6629L11.163 8.99638C11.2567 8.9027 11.3035 8.85586 11.337 8.80119C11.3667 8.75273 11.3886 8.69989 11.4019 8.64462C11.4168 8.58229 11.4168 8.51604 11.4168 8.38356V4.61653C11.4168 4.48404 11.4168 4.4178 11.4019 4.35546C11.3886 4.30019 11.3667 4.24735 11.337 4.19889C11.3035 4.14422 11.2567 4.09738 11.163 4.0037L8.4965 1.33721C8.40282 1.24353 8.35598 1.19669 8.30132 1.16319C8.25285 1.1335 8.20002 1.11161 8.14475 1.09834C8.08241 1.08337 8.01616 1.08337 7.88368 1.08337H4.11665C3.98416 1.08337 3.91792 1.08337 3.85558 1.09834C3.80031 1.11161 3.74747 1.1335 3.69901 1.16319C3.64435 1.19669 3.5975 1.24353 3.50382 1.33721L0.837337 4.0037C0.743655 4.09738 0.696814 4.14422 0.663316 4.19889C0.633617 4.24735 0.611731 4.30019 0.598462 4.35546C0.583496 4.4178 0.583496 4.48404 0.583496 4.61653Z"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Icon>
  );
};