import { useMutation } from 'react-query';
import { useBoolean } from 'usehooks-ts';
import { Box, CheckIcon, FlatList, HStack, Popover, Pressable, Spacer, useTheme } from 'native-base';
import { find } from 'lodash';

import { ChevronSelectorVerticalIcon, Text } from '@pimm/base';
import { AddUpdateSlot } from '@pimm/services/lib/sms-workforce/services';
import { hexToRGBA } from '@app/utils/string-formatter';
import { useKitchenLayout } from '@app/features/kitchen-positioning';
import { PositioningSlot } from '../reducers';

type PopoverSelectOpsLeaderProps = {
  isDisabled?: boolean;
  positionSlots?: PositioningSlot[];
  onChange: (position: Partial<PositioningSlot>, _position?: Partial<PositioningSlot>) => void;
};

export const PopoverSelectOpsLeader = ({ isDisabled, positionSlots, ...props }: PopoverSelectOpsLeaderProps) => {
  const { colors } = useTheme();
  const { positionLookup } = useKitchenLayout();
  const popoverIsOpen = useBoolean();

  const addUpdateSlot = useMutation({ mutationFn: AddUpdateSlot });

  const opsLeader = find(positionSlots, ['isOpsLeader', true]);

  const handlePressSelect = (position: PositioningSlot) => () => {
    if (!position.isOpsLeader && opsLeader?.id !== position.id) {
      addUpdateSlot.mutate({ positionGroupId: position.positionGroupId, positionSlotId: position.id, isOpsLeader: true });
      props.onChange({ id: position.id, isOpsLeader: true });
    }
    popoverIsOpen.setFalse();
  };

  return (
    <Popover
      isKeyboardDismissable
      isOpen={popoverIsOpen.value}
      onClose={popoverIsOpen.setFalse}
      onOpen={popoverIsOpen.setTrue}
      trigger={popoverProps => {
        return (
          <Pressable
            rounded="lg"
            ml={1}
            h={8}
            minW="120px"
            borderWidth={1}
            borderColor="gray.300"
            bg="white"
            isDisabled={isDisabled}
            _disabled={{ borderColor: 'gray.300', bg: 'white' }}
            {...popoverProps}
          >
            <HStack space={1.5} alignItems="center" justifyContent="space-between" pl={3} pr={2} h="full">
              <Text size="md" fontWeight={600} color="gray.700">
                {opsLeader?.title ?? 'Select Position'}
              </Text>

              <ChevronSelectorVerticalIcon size={4} color={isDisabled ? 'gray.400' : 'gray.700'} />
            </HStack>
          </Pressable>
        );
      }}
    >
      <Popover.Content accessibilityLabel="Select Ops Leader" rounded="lg">
        <Popover.Arrow zIndex={1} />
        <Popover.Body py={1.5} px={1.5} w="240">
          <FlatList
            maxHeight={500}
            data={positionSlots}
            renderItem={({ item: position }) => {
              const kitchenPosition = position?.positionId && positionLookup ? positionLookup[position.positionId] : undefined;
              const color = kitchenPosition?.color ?? colors.gray[500];
              return (
                <Pressable key={position.id} zIndex={2} onPress={handlePressSelect(position)}>
                  {({ isHovered }) => (
                    <HStack space={3} rounded="md" alignItems="center" px={1.5} py={1} minH={9} bg={isHovered ? 'gray.50' : undefined}>
                      <Box rounded="md" alignContent="center" justifyContent="center" size={7} bg={hexToRGBA(color, 0.4)}>
                        <Text size="md" color="black" fontWeight={700} textAlign="center" lineHeight="xs">
                          {position.slotNumber}
                        </Text>
                      </Box>

                      <Text
                        size="md"
                        color={opsLeader?.id === position.id ? 'black' : 'gray.700'}
                        fontWeight={opsLeader?.id === position.id ? 600 : 500}
                        lineHeight="xs"
                        numberOfLines={2}
                      >
                        {position?.title}
                      </Text>

                      <Spacer />

                      {!!position.isOpsLeader && <CheckIcon size={4} color="primary.500" />}
                    </HStack>
                  )}
                </Pressable>
              );
            }}
          />
        </Popover.Body>
      </Popover.Content>
    </Popover>
  );
};
