const translations = {
  adjusted_staff_total: 'Adjusted Staff Total',
  arriving_soon: 'Arriving Soon',
  cleaning_tasks_current_week: 'Cleaning Tasks (Current week)',
  current_staff_total: 'Current Staff Total',
  in_the_service_plan: 'In the Service Plan',
  no_assigned_goals: 'No assigned goals',
  no_assigned_responsibilities: 'No assigned responsibility',
  no_employee_assigned: 'No Employee Assigned',
  no_goals_today: 'No Goals Today',
  no_position_assigned: 'No Position Assigned',
  not_in_service_plan: 'Not in Service Plan',
  empty_message_responsibilities: 'Responsibilities will be listed here once a Service Position is assigned.',
  shift_changes_s: 'Shift Change(s)',
  shift_ends: 'Shift Ends',
  shift_in_progress: 'Shift In Progress',
  shift_scope: 'Shift Scope',
  shift_starts: 'Shift Starts',
};

export default translations;
