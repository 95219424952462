import { Icon, IIconProps } from 'native-base';
import { Path } from 'react-native-svg';

export const StarIcon = ({ size = 'sm', ...props }: IIconProps) => {
  return (
    <Icon viewBox="0 0 12 12" size={size} fill="none" {...props}>
      <Path
        d="M5.6174 1.44174C5.74032 1.1927 5.80179 1.06818 5.88523 1.0284C5.95782 0.993788 6.04216 0.993788 6.11476 1.0284C6.1982 1.06818 6.25966 1.1927 6.38259 1.44174L7.54882 3.80439C7.58511 3.87791 7.60325 3.91467 7.62977 3.94321C7.65325 3.96848 7.68141 3.98895 7.71269 4.0035C7.74801 4.01993 7.78858 4.02586 7.8697 4.03771L10.4784 4.41901C10.7531 4.45916 10.8904 4.47924 10.954 4.54633C11.0093 4.60471 11.0353 4.68493 11.0248 4.76465C11.0127 4.85628 10.9132 4.95313 10.7144 5.14684L8.82742 6.98472C8.7686 7.04202 8.73919 7.07066 8.72021 7.10475C8.70341 7.13493 8.69263 7.16808 8.68847 7.20237C8.68378 7.2411 8.69072 7.28156 8.7046 7.3625L9.14983 9.95843C9.19679 10.2322 9.22027 10.3691 9.17614 10.4504C9.13775 10.521 9.06951 10.5706 8.99042 10.5853C8.89952 10.6021 8.77658 10.5375 8.53071 10.4082L6.19859 9.18175C6.12592 9.14353 6.08959 9.12443 6.05131 9.11692C6.01742 9.11027 5.98256 9.11027 5.94867 9.11692C5.9104 9.12443 5.87406 9.14353 5.8014 9.18175L3.46928 10.4082C3.22341 10.5375 3.10047 10.6021 3.00957 10.5853C2.93048 10.5706 2.86224 10.521 2.82384 10.4504C2.77972 10.3691 2.8032 10.2322 2.85016 9.95843L3.29539 7.3625C3.30927 7.28156 3.31621 7.2411 3.31151 7.20237C3.30736 7.16808 3.29658 7.13493 3.27977 7.10475C3.2608 7.07066 3.23138 7.04202 3.17256 6.98472L1.28562 5.14684C1.08674 4.95313 0.987298 4.85628 0.975198 4.76465C0.96467 4.68493 0.990679 4.60471 1.04598 4.54633C1.10955 4.47924 1.2469 4.45916 1.52161 4.41901L4.13028 4.03771C4.21141 4.02586 4.25197 4.01993 4.2873 4.0035C4.31858 3.98895 4.34674 3.96848 4.37022 3.94321C4.39673 3.91467 4.41488 3.87791 4.45117 3.80439L5.6174 1.44174Z"
        fill="none"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
