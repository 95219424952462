import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { Box, Center, HStack, Pressable, Spinner, VStack } from 'native-base';

import { FormHelperText, Text } from '@pimm/base';
import { FormResetPasswordStep, FormResetPasword, useFormResetPassword } from '@pimm/common';
import { ChangePassword, VerifyForgotPassword } from '@pimm/services/lib/sms-tenants/services';
import { ChangePasswordRequest } from '@pimm/services/lib/sms-tenants';
import { RootScreenProps } from '@app/navigations/root';
import { Wrapper } from '@app/features/app';
import { BrandLogo } from './components/brand-logo';

type ResetPasswordQueryParams = {
  uid: string;
  c: string;
};

export type ResetPasswordScreenProps = RootScreenProps<'ResetPassword'>;

export const ResetPasswordScreen = ({ navigation, route }: ResetPasswordScreenProps) => {
  const params = route.params as unknown as ResetPasswordQueryParams;
  const formReset = useFormResetPassword({
    defaultValues: {
      step: FormResetPasswordStep.ChangePassword,
      username: params?.uid ?? '',
    },
  });

  const changePassword = useMutation({
    mutationFn: async ({ accessToken, ...params }: ChangePasswordRequest & { accessToken: string }) => {
      const result = await ChangePassword(params, {
        Authorization: `Bearer ${accessToken}`,
      } as any);
      return result;
    },
    onSuccess: () => {
      formReset.setValue('step', FormResetPasswordStep.Completed);
    },
  });

  const verifyCode = useMutation({
    mutationFn: VerifyForgotPassword,
    onSuccess: () => {
      formReset.clearErrors();
    },
  });

  const handleResetPassword = () => {
    if (verifyCode.data?.accessToken && verifyCode.data?.userId) {
      changePassword.mutate({
        accessToken: verifyCode.data.accessToken,
        newPassword: btoa(formReset.getValues().newPassword),
        userId: verifyCode.data.userId,
        verificationCode: params.c,
      });
    }
  };

  useEffect(() => {
    if (params.c && params.uid) {
      verifyCode.mutate({ userName: params.uid, verificationCode: params.c });
    }
  }, [params.c, params.uid]);

  return (
    <Wrapper>
      <VStack flex={1} alignItems="center" justifyContent="center" w="full" maxH={780}>
        <VStack space={5} w="full" maxW={420}>
          <Box rounded="2xl" bg="white" px={10} pt={7} pb={4} shadow={3}>
            <Center>
              <BrandLogo />
            </Center>

            <VStack space={4} justifyContent="center" pt={2}>
              {verifyCode.isLoading && (
                <HStack space={3} rounded="lg" alignItems="center" justifyContent="center" py={6} bg="gray.50">
                  <Spinner color="black" />
                  <Text size="lg" fontWeight={600} color="black">
                    Please wait
                  </Text>
                </HStack>
              )}

              {verifyCode.isError && (
                <HStack space={3} rounded="lg" alignItems="center" justifyContent="center" py={6} bg="gray.50">
                  <FormHelperText error visible>
                    Confirmation link has expired or is invalid.
                  </FormHelperText>
                </HStack>
              )}

              {verifyCode.isSuccess && (
                <>
                  {!changePassword.isSuccess && (
                    <Text size="md" fontWeight={400} color="black" lineHeight="md">
                      Please enter a new password to complete the reset password on your account
                    </Text>
                  )}

                  <Box>
                    {!changePassword.isSuccess && (
                      <>
                        {/* Reset Password Form */}
                        <FormResetPasword form={formReset} isLoading={changePassword.isLoading} onSubmit={handleResetPassword} />
                        {changePassword.isError && (
                          <FormHelperText error visible mt={1}>
                            Sorry, something went wrong there. Please try again
                          </FormHelperText>
                        )}
                      </>
                    )}

                    {changePassword.isSuccess && (
                      <VStack space={4} rounded="lg" alignItems="center" my={2} px={4} py={6} bg="gray.50">
                        <Text size="3xl" fontWeight={700} color="success.700" lineHeight="md">
                          Success!
                        </Text>

                        <Text size="md" fontWeight={500} color="success.700" lineHeight="md">
                          Your password has been reset successfully. Please use your new password to login.
                        </Text>
                      </VStack>
                    )}
                  </Box>
                </>
              )}
            </VStack>

            <HStack justifyContent="center" textAlign="center" mt={4} mb={1}>
              <Pressable onPress={() => navigation.replace('Login')}>
                <Text size="lg" color="black" fontWeight={600}>
                  {'\u2190'} Back to Login
                </Text>
              </Pressable>
            </HStack>
          </Box>
        </VStack>
      </VStack>
    </Wrapper>
  );
};
