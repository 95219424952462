import { IIconProps, useTheme } from 'native-base';

import { MessageCategoryEnum } from '@pimm/services/lib/message-board';
import { AlertTriangleIcon, AnnouncementIcon, DocumentIcon, VideoIcon } from '../icons';

export const SmallIcon = ({ category, important, size = '18px' }: IIconProps & { category?: MessageCategoryEnum; important?: boolean }) => {
  const { colors } = useTheme();
  
  if (important) {
    return <AlertTriangleIcon size={size} color={colors.error[500]} fill={colors.error[200]} />;
  }

  switch (category) {
    case MessageCategoryEnum.Announcement:
      return <AnnouncementIcon size={size} color={colors.cyan[600]} />;
    case MessageCategoryEnum.Post:
      return <DocumentIcon size={size} color={colors.primary[500]} />;
    case MessageCategoryEnum.Video:
      return <VideoIcon size={size} color={colors.greenLight[500]} />;
    default:
      return undefined;
  }
};
