import { Box } from 'native-base';
import { Text } from '@pimm/base';
import { hexToRGBA } from '@app/utils/string-formatter';

type ChipGoalProps = {
  color?: string;
  text?: string;
  textColor?: string
};

export const ChipGoal = ({ color, text, textColor }: ChipGoalProps) => {
  return (
    <Box
      rounded="md"
      alignItems="center"
      justifyContent="center"
      py={1}
      px={1.5}
      borderWidth={1}
      borderColor={color}
      bg={hexToRGBA(color, 0.2)}
    >
      <Text size="sm" fontWeight={600} color={textColor ?? color} lineHeight="xs">
        {text}
      </Text>
    </Box>
  );
};
