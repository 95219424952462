import React, { createContext, useContext, useMemo } from 'react';
import { filter, orderBy } from 'lodash';
import moment from 'moment';

import { Employee } from '@pimm/services/lib/sms-workforce';

export type CalendarEventType = 'Birthday' | 'Anniversary';

export interface CalendarEvent<T = unknown> {
  dateTime: Date;
  type: CalendarEventType;
  referenceId?: string;
  name: string;
  description?: string;
  data?: T;
}

export interface CalendarEventsContextReturn {
  events: CalendarEvent[];
  getEventsByDate: (today: Date) => CalendarEvent[];
}

export const CalendarEventsContext = createContext<CalendarEventsContextReturn>(undefined!);

export type CalendarEventsProviderProps = {
  children: React.ReactNode;
  employees?: Employee[];
};

export const CalendarEventsProvider = ({ children, employees }: CalendarEventsProviderProps) => {
  const events = useMemo(() => {
    const employeeEvents: CalendarEvent[] = [];
    employees?.forEach(({ birthDate, hireDate, firstName, lastName, ...employee }) => {
      const name = firstName ? `${firstName} ${lastName}` : employee.userName;
      if (birthDate) {
        const dateInUTC = moment.utc(birthDate);
        employeeEvents.push({
          dateTime: new Date(dateInUTC.format('l')),
          referenceId: employee.userId,
          name: name || '',
          type: 'Birthday',
          data: {
            firstName,
            lastName,
          },
        });
      }
      if (hireDate) {
        const dateInUTC = moment.utc(hireDate);
        employeeEvents.push({
          dateTime: new Date(dateInUTC.format('l')),
          referenceId: employee.userId,
          name: name || '',
          type: 'Anniversary',
          data: {
            firstName,
            lastName,
          },
        });
      }
    });
    return orderBy(employeeEvents, ['dateTime']);
  }, [employees]);

  const getEventsByDate = (today: Date) => {
    return filter(events || [], ({ dateTime }) => dateTime.getMonth() === today.getMonth() && dateTime.getDate() === today.getDate());
  };

  return (
    <CalendarEventsContext.Provider value={{ events: events, getEventsByDate: getEventsByDate }}>{children}</CalendarEventsContext.Provider>
  );
};

export const CalendarEventsConsumer = CalendarEventsContext.Consumer;

export const useCalendarEvents = () => useContext(CalendarEventsContext);
