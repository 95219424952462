import React, { useMemo } from 'react';
import { Box, FlatList, HStack, View, useMediaQuery } from 'native-base';
import { filter, first, flatMap, range, sumBy } from 'lodash';
import moment from 'moment';

import { CurrencyText, Text } from '@pimm/base';
import { DaypartSales } from '../types';

type SalesForecastProps = {
  daypartSales: DaypartSales[];
};

export const SalesForecast = ({ daypartSales }: SalesForecastProps) => {
  const [isSmallScreen] = useMediaQuery({ maxWidth: 1024 });

  const hours: { time: number; actual: number; forecast: number }[] = useMemo(() => {
    const startOfDay = first(daypartSales)?.startTime;
    const sales = flatMap(daypartSales, _ => _.sales);

    if (startOfDay) {
      const tickValues = range(24).map(hr => new Date(startOfDay.getTime() + 3600 * 1000 * hr));
      const datasets = tickValues.map(time => {
        const endTime = new Date(time.getTime() + 60 * 60 * 1000);
        const hourlySales = filter(sales, sale => sale && sale.time >= time && sale.time < endTime);

        return {
          time: time.getTime(),
          actual: sumBy(hourlySales, 'actual'),
          forecast: sumBy(hourlySales, 'forecast'),
        };
      });

      return datasets;
    }

    return [];
  }, [daypartSales]);

  return (
    <View w="full" borderWidth={1} rounded="lg">
      <HStack alignItems="center" height={8} backgroundColor="gray.50" borderTopRadius="lg">
        <Box width={90} h="full" justifyContent="center" px={3} borderBottomWidth={1} />
        {hours.map(_ => {
          return (
            <Box key={_.time} flex={1} h="full" justifyContent="center" borderLeftWidth={1} borderBottomWidth={1}>
              <Text size={{ md: 'xs', xl: 'sm' }} fontWeight={500} color="black" textAlign="center">
                {moment(_.time).format('ha')}
              </Text>
            </Box>
          );
        })}
      </HStack>
      <View position="relative" w="full" backgroundColor="gray.200" borderBottomRightRadius="lg">
        {['Sales', 'Labor'].map((section, index) => {
          return (
            <React.Fragment key={section}>
              <Box position="absolute" top={index * 98} left={-22} w={5} style={{ height: 98 }} alignItems="center" justifyContent="center">
                <Text size={{ md: 'sm', xl: 'md' }} fontWeight={700} color="black" style={{ transform: [{ rotate: '-90deg' }] }}>
                  {section}
                </Text>
              </Box>
              <FlatList
                w="full"
                mt={index ? 0.5 : 0}
                data={['Actual', 'Forecast', 'Variance']}
                backgroundColor="white"
                borderBottomRightRadius={index ? 'lg' : undefined}
                borderBottomLeftRadius={index ? 'lg' : undefined}
                ItemSeparatorComponent={() => <Box h="1px" backgroundColor="gray.200" />}
                renderItem={({ item }) => {
                  return (
                    <HStack alignItems="center" height={8}>
                      <Box width={90} h="full" justifyContent="center" px={3} backgroundColor="gray.50">
                        <Text size={{ md: 'xs', xl: 'sm' }} fontWeight={700} color="gray.900">
                          {item}
                        </Text>
                      </Box>
                      {hours.map(_ => {
                        let value = item === 'Actual' ? _.actual : _.forecast;
                        if (item === 'Variance') {
                          value = section === 'Sales' ? _.actual - _.forecast : 0;
                        }
                        return (
                          <HStack
                            key={`${item}.${_?.time}`}
                            flex={1}
                            h="full"
                            alignItems="center"
                            justifyContent="center"
                            borderLeftWidth={1}
                          >
                            <Box
                              py={{ xl: 0.5 }}
                              px={{ md: 0.5, xl: 1.5 }}
                              minW={{ xl: 8 }}
                              alignItems="center"
                              rounded="sm"
                              backgroundColor={value && item === 'Variance' ? (value > 0 ? 'success.200' : 'error.200') : undefined}
                            >
                              {section === 'Sales' ? (
                                <CurrencyText
                                  hideFlatZero={isSmallScreen}
                                  size={{ md: 'xs', xl: 'sm' }}
                                  fontWeight={item === 'Variance' ? 700 : 500}
                                  color="gray.900"
                                  value={value}
                                />
                              ) : (
                                <Text size={{ md: 'xs', xl: 'sm' }} fontWeight={item === 'Variance' ? 700 : 500} color="gray.900">
                                  0
                                </Text>
                              )}
                            </Box>
                          </HStack>
                        );
                      })}
                    </HStack>
                  );
                }}
              />
            </React.Fragment>
          );
        })}
      </View>
    </View>
  );
};
