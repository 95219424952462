import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, HStack, VStack } from 'native-base';
import FontAwesomeIcons from 'react-native-vector-icons/FontAwesome';
import Ionicons from 'react-native-vector-icons/Ionicons';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { chain, chunk, keys } from 'lodash';

import { Text } from '@pimm/base';
import { EventPriority } from '@pimm/services/lib/store-equipment';
import { getEventPriorityColor, useGetInstances } from '@app/features/store-equipment';
import { WidgetBox, WidgetBoxProps } from '../widget-box';

interface EquipmentBoxProps {
  value: Number;
  bgColor: string | undefined;
  icon: React.ReactNode;
}

interface EventCounts {
  [key: string]: {
    count: number;
    eventPriority: EventPriority | undefined;
  };
}

type WidgetEquipmentProps = WidgetBoxProps & {
  instances: ReturnType<typeof useGetInstances>;
};

export const WidgetEquipment = ({ instances, title = 'Equipment Events', ...props }: WidgetEquipmentProps) => {
  const { t } = useTranslation();

  const chunks: EquipmentBoxProps[][] = useMemo(() => {
    // Get all instance with event priority other than "None"
    const events: EventCounts = chain(instances.data)
      .reduce((instanceData, device) => {
        keys(device.Instances ?? {}).forEach(name => {
          const instance = device.Instances?.[name];
          // Check for valid event priority
          if (instance?.EventPriority && instance.EventPriority !== 'None') {
            // Initialize or update the count and eventPriority
            if (!instanceData[name]) {
              instanceData[name] = { count: 1, eventPriority: instance.EventPriority };
            } else {
              instanceData[name].count += 1;
              // Update the eventPriority if needed
              instanceData[name].eventPriority = instance.EventPriority;
            }
          }
        });
        return instanceData;
      }, {})
      .value();

    // List of Equipment Display
    const equipment: EquipmentBoxProps[] = [
      {
        icon: <Ionicons name="thermometer-outline" size={14} color="white" />,
        value: events?.Temperature?.count ?? 0,
        bgColor: getEventPriorityColor(events?.Temperature?.eventPriority) || 'gray.600',
      },
      {
        icon: <MaterialCommunityIcons name="door-open" size={14} color="white" />,
        value: events?.Door?.count ?? 0,
        bgColor: getEventPriorityColor(events?.Door?.eventPriority) || 'gray.600',
      },
      {
        icon: <MaterialIcons name="wifi-off" size={14} color="white" />,
        value: events?.SignalStrength?.count ?? 0,
        bgColor: getEventPriorityColor(events?.SignalStrength?.eventPriority) || 'gray.600',
      },
      {
        icon: <FontAwesomeIcons name="battery-quarter" size={10} color="white" />,
        value: events?.BatteryVoltage?.count ?? 0,
        bgColor: getEventPriorityColor(events?.BatteryVoltage?.eventPriority) || 'gray.600',
      },
    ];

    return chunk(equipment, 2);
  }, [instances]);

  return (
    <WidgetBox title={t('common:equipment_events')} {...props}>
      <VStack space={1.5}>
        {chunks.map((items, index) => {
          return (
            <HStack key={index} space={1.5} justifyContent="center">
              {items.map((item, subIndex) => (
                <HStack
                  key={subIndex}
                  flex={1}
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                  pl={1}
                  h={8}
                  rounded="lg"
                  bgColor="gray.100"
                >
                  <Box p={1} rounded="full" bgColor={item.bgColor}>
                    {item.icon}
                  </Box>
                  <Text size="md" color="black" fontWeight={700}>
                    {item.value.toString()}
                  </Text>
                </HStack>
              ))}
            </HStack>
          );
        })}

        <HStack justifyContent="space-between" rounded="md" py={1} px={2} backgroundColor="success.500">
          <Text size="sm" color="white" fontWeight={500}>
            Gateway
          </Text>
          <Text size="sm" color="white" fontWeight={700} textTransform="uppercase">
            ONLINE
          </Text>
        </HStack>
      </VStack>
    </WidgetBox>
  );
};
