import { Menu, MenuOption, MenuOptions, MenuTrigger } from 'react-native-popup-menu';
import { HStack, useTheme } from 'native-base';
import { useBoolean } from 'usehooks-ts';
import { find, last, map } from 'lodash';

import { PressableSelect, Text } from '@pimm/base';
import { GoalProfileDto } from '@pimm/services/lib/sms-drivethru/types';
import { ChipGoal } from './chip-goal';

export interface GoalMenuOption {
  label: string;
  caption?: string;
  goal?: GoalProfileDto;
  textColor?: string;
}

type PopoverSelectProfileProps = {
  options?: GoalMenuOption[];
  value?: string;
  onChange?: (value?: string) => void;
};

export const PopoverSelectProfile = ({ options, value, ...props }: PopoverSelectProfileProps) => {
  const { colors } = useTheme();
  const isOpen = useBoolean();

  const selectedOption = find(options, _ => _.goal?.name === value ?? 'Custom') ?? last(options);

  const handleChange = (value?: string) => {
    if (props.onChange) props.onChange(value);
    isOpen.setFalse();
  };

  return (
    <Menu
      opened={isOpen.value}
      rendererProps={{ preferredPlacement: 'right' }}
      onBackdropPress={isOpen.setFalse}
      onClose={isOpen.setFalse}
      onSelect={handleChange}
    >
      <MenuTrigger style={{ height: 34 }}>
        <PressableSelect rounded="lg" h={9} w="full" bg="white" onPress={() => isOpen.setTrue()}>
          <HStack alignItems="center" space={2}>
            <Text size="md" color="black">
              {selectedOption?.label}
            </Text>

            {selectedOption?.goal && (
              <ChipGoal color={selectedOption?.goal.color} text={selectedOption?.goal.name} textColor={selectedOption?.textColor} />
            )}
          </HStack>
        </PressableSelect>
      </MenuTrigger>
      <MenuOptions
        customStyles={{ optionsContainer: { marginTop: 24 } }}
        optionsContainerStyle={{
          shadowOpacity: 0.5,
          minWidth: 180,
          borderRadius: 5,
          backgroundColor: 'white',
          borderWidth: 1,
          borderColor: colors.gray[300],
          shadowColor: colors.gray[300],
          shadowOffset: { width: 0, height: 2 },
        }}
      >
        {map(options, _option => {
          return (
            <MenuOption style={{ paddingHorizontal: 10 }} value={_option.goal?.name ?? 'Custom'}>
              <HStack alignItems="center" space={2} minHeight={6}>
                <Text size="md" fontWeight={500} color="black">
                  {_option.label}
                </Text>
                {_option.goal && <ChipGoal color={_option.goal.color} text={_option.goal.name} textColor={_option.textColor} />}
              </HStack>
            </MenuOption>
          );
        })}
      </MenuOptions>
    </Menu>
  );
};
