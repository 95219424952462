import { useMutation } from 'react-query';
import { useWatch } from 'react-hook-form';
import { Box, Divider, HStack, ScrollView, Select, VStack } from 'native-base';
import { Locale } from 'locale-enum';

import { Button, ControlInput, SelectInput, Text } from '@pimm/base';
import { AddUpdateUserProfile, GetUserProfile } from '@pimm/services/lib/sms-tenants/services';
import { AddUpdateUserProfileRequest, CultureData, UserProfileDto } from '@pimm/services/lib/sms-tenants';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { useFormUserInformation } from '../hooks';
import DropdownCultures from './dropdown-cultures';
import { ModalConfirm } from './modal-confirm';
import { ListItem } from './list-item';

type FormUserInformationProps = {
  cultures?: CultureData[];
  isEditable?: boolean;
  user?: UserProfileDto;
  onClose?: () => void;
  onEndChange: (payload?: UserProfileDto) => void;
};

export const FormUserInformation = ({ cultures, isEditable, user, ...props }: FormUserInformationProps) => {
  const modalConfirm = useModalFocus();
  const form = useFormUserInformation({
    defaultValues: {
      defaultSiteId: user?.defaultSiteId,
      defaultLanguage: user?.culture ?? Locale.en_US,
      firstName: user?.firstName,
      lastName: user?.lastName,
      jobTitle: user?.jobTitle,
      username: user?.userName,
      role: [user?.roles ?? []].join(' '), // TODO: need to revisit
      contactDetails: {
        email: user?.contactEmail ?? '',
        mobile: user?.mobilePhone ?? '',
        phone: user?.officePhone ?? '',
      },
    },
  });
  const culture = useWatch({ control: form.control, name: 'defaultLanguage' });

  const addUpdateProfile = useMutation({
    mutationFn: async (payload: AddUpdateUserProfileRequest) => {
      await AddUpdateUserProfile(payload);
      // Get profile after successful update
      const profile = await GetUserProfile({ userId: payload.userId });
      return profile;
    },
    onSuccess: profile => props.onEndChange(profile),
  });

  const handleConfirmChanges = () => {
    if (!isEditable) return false;

    const formValues = form.getValues();
    const payload: AddUpdateUserProfileRequest = {
      userId: user?.userId,
      defaultSiteId: formValues.defaultSiteId,
      culture: formValues.defaultLanguage,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      jobTitle: formValues.jobTitle,
      contactEmail: formValues.contactDetails.email ?? '',
      mobilePhone: formValues.contactDetails.mobile ?? '',
      officePhone: formValues.contactDetails.phone ?? '',
    };
    addUpdateProfile.mutate(payload);
    modalConfirm.setHide();
  };

  return (
    <VStack h="full" bg="gray.25" borderBottomRightRadius="xl" overflow="hidden">
      <ScrollView h="full">
        <VStack space={4} p={4}>
          {/* User Defaults */}
          <ListItem text="User Defaults">
            <HStack space={3} flex={1}>
              <Box flex={1} justifyContent="center" py={3} px={4} borderRadius="lg" borderWidth={1} borderColor="gray.200" bgColor="white">
                <VStack space={1} zIndex={1000}>
                  <ControlInput
                    control={form.control}
                    name="defaultSiteId"
                    label="Default site access"
                    placeholder="Choose default site access"
                    disabled={!isEditable}
                    element={
                      <SelectInput _actionSheetContent={{ bgColor: 'rose.100' }}>
                        {user?.sites?.map(_site => (
                          <Select.Item key={_site.id} value={_site.id!} label={_site.name ?? ''} />
                        ))}
                      </SelectInput>
                    }
                  />
                </VStack>
              </Box>
              <Box py={3} px={4} w="2/6" borderRadius="lg" justifyContent="center" bgColor="white" borderWidth={1} borderColor="gray.200">
                <VStack zIndex={1000}>
                  <Text size="md" fontWeight={500} color="gray.700" mb={0.5}>
                    Language
                  </Text>
                  <DropdownCultures
                    defaultCulture={culture}
                    cultures={cultures}
                    disabled={!isEditable}
                    onPressCulture={_culture => form.setValue('defaultLanguage', _culture, { shouldDirty: true })}
                  />
                </VStack>
              </Box>
            </HStack>
          </ListItem>

          <Divider bg="gray.200" />

          {/* PIMM info */}
          <ListItem text="PIMM Info">
            <Box flex={1} py={3} px={4} borderRadius="lg" justifyContent="center" bgColor="white" borderWidth={1} borderColor="gray.200">
              <VStack space={2}>
                <HStack space={3}>
                  <Box flex={1}>
                    <ControlInput control={form.control} label="First name" name="firstName" disabled={!isEditable} />
                  </Box>
                  <Box flex={1}>
                    <ControlInput control={form.control} label="Last name" name="lastName" disabled={!isEditable} />
                  </Box>
                </HStack>

                <Box flex={1}>
                  <ControlInput control={form.control} name="jobTitle" label="Job title" disabled={!isEditable} />
                </Box>
                <Box flex={1}>
                  <ControlInput control={form.control} disabled name="role" label="User role(s)" />
                </Box>
                <Box flex={1}>
                  <ControlInput control={form.control} disabled name="username" label="Username" />
                </Box>
              </VStack>
            </Box>
          </ListItem>

          <Divider bg="gray.200" />

          {/* Contact info */}
          <ListItem text="Contact Info">
            <Box flex={1} py={3} px={4} borderRadius="lg" justifyContent="center" bgColor="white" borderWidth={1} borderColor="gray.200">
              <VStack space={3}>
                <Box flex={1}>
                  <ControlInput control={form.control} name="contactDetails.email" label="Contact email address" disabled={!isEditable} />
                </Box>
                <HStack space={2}>
                  <Box flex={1}>
                    <ControlInput control={form.control} name="contactDetails.mobile" label="Mobile phone" disabled={!isEditable} />
                  </Box>
                  <Box flex={1}>
                    <ControlInput control={form.control} name="contactDetails.phone" label="Office phone" disabled={!isEditable} />
                  </Box>
                </HStack>
              </VStack>
            </Box>
          </ListItem>
        </VStack>
      </ScrollView>

      {isEditable && (
        <HStack space={3} alignItems="center" justifyContent="flex-end" py={3} px={4} borderTopWidth={1}>
          <Button size="sm" h={9} minWidth={90} alternate outline disabled={addUpdateProfile.isLoading} onPress={props.onClose}>
            Cancel
          </Button>

          <Button
            size="sm"
            h={9}
            minWidth={120}
            isLoading={addUpdateProfile.isLoading}
            disabled={!form.formState.isDirty}
            onPress={form.handleSubmit(modalConfirm.setOpen)}
          >
            Save Changes
          </Button>
        </HStack>
      )}

      <ModalConfirm isOpen={modalConfirm.isOpen} onClose={modalConfirm.setHide} onConfirm={handleConfirmChanges} />
    </VStack>
  );
};
