import { Dispatch, SetStateAction, useState } from 'react';
import { QueryKey, UseQueryResult, useQuery } from 'react-query';
import { first, isEmpty, last, reverse } from 'lodash';

import { GetPositionScheduleLive } from '@pimm/services/lib/sms-workforce/services';
import { formatToISOString, stringToDateLocal } from '@app/utils/date-formatter';
import { DayBlock, useSiteTime } from '@app/features/store-core';

type GetPositionScheduleLiveParams = {
  dayBlock: DayBlock;
  siteId?: string;
  subBlockStartTime?: Date;
};

export const useGetPositionScheduleLive = (props?: {
  queryKey?: string;
  query?: GetPositionScheduleLiveParams;
}): [
  UseQueryResult<Awaited<ReturnType<typeof GetPositionScheduleLive>>>,
  Dispatch<SetStateAction<GetPositionScheduleLiveParams | undefined>>,
] => {
  const siteTime = useSiteTime();
  const [query, setQuery] = useState<GetPositionScheduleLiveParams | undefined>(props?.query);

  const liveSchedules = useQuery({
    cacheTime: 0,
    staleTime: 0,
    enabled: !!query?.siteId && !!query?.dayBlock.startTime && !!query?.dayBlock.endTime,
    queryKey: [props?.queryKey ?? 'GetPositionScheduleLive', query] as [QueryKey, GetPositionScheduleLiveParams],
    queryFn: async ({ queryKey: [key, { dayBlock, subBlockStartTime, ...params }] }) => {
      const today = siteTime.today();
      let liveStartTime = subBlockStartTime ?? dayBlock.startTime;

      // Check if the current dayBlock is now, change liveStartTime using store current time
      if (today >= dayBlock?.startTime && today < dayBlock.endTime) {
        liveStartTime = today;

        if (subBlockStartTime && today < subBlockStartTime) {
          liveStartTime = subBlockStartTime;
        }
      }

      let isoSubBlockStartTime = subBlockStartTime ? formatToISOString(subBlockStartTime) : undefined;

      // Try and fetch live position group
      const result = await GetPositionScheduleLive({
        ...params,
        subBlockDateTime: isoSubBlockStartTime,
        blockStartDateTime: formatToISOString(dayBlock.startTime),
        liveStartDateTime: formatToISOString(liveStartTime),
      });

      if (result) {
        // Find pre-selected shiftSubBlock
        if (!isEmpty(result.shiftSubBlocks) && !result.positionGroup?.subBlockTime) {
          const shiftSubBlock =
            reverse([...(result.shiftSubBlocks ?? [])]).find(shift => {
              const startTime = stringToDateLocal(shift.subBlockDateTime);
              return startTime && startTime <= siteTime.today();
            }) ?? first(result.shiftSubBlocks);

          // Tell the ui to use this pre-selected shiftSubBlock startTime
          result.positionGroup = {
            subBlockTime: isoSubBlockStartTime ?? shiftSubBlock?.subBlockDateTime,
          };
        }
      }
      return result;
    },
  });

  return [liveSchedules, setQuery];
};
