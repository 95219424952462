import { Fragment, useState } from 'react';
import { Box, CheckIcon, CloseIcon, HStack, IconButton, Pressable, Spacer, VStack } from 'native-base';
import { map } from 'lodash';

import { Button, Modal, Text } from '@pimm/base';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { GoalMenuOption } from './popover-select-profile';
import { ChipGoal } from './chip-goal';

type SelectGoalProfileProps = {
  options: GoalMenuOption[];
  trigger: (props: { onPress: () => void }) => React.ReactNode;
  onChange?: (value?: string) => void;
};

export const SelectGoalProfile = ({ options, trigger, ...props }: SelectGoalProfileProps) => {
  const modal = useModalFocus();
  const [selectedOption, setSelectedOption] = useState<GoalMenuOption>();

  const handleApply = () => {
    modal.setHide();

    if (selectedOption !== undefined && props.onChange) {
      props.onChange(selectedOption?.goal?.name ?? 'Custom');
    }
    setSelectedOption(undefined);
  };

  return (
    <Fragment>
      {trigger({ onPress: modal.setOpen })}
      <Modal _content={{ rounded: 'xl', maxWidth: 460 }} size="md" isOpen={modal.isOpen} noPadding hideClose onClose={modal.setHide}>
        <IconButton
          position="absolute"
          top={3}
          right={3}
          p={2}
          zIndex={2}
          _pressed={{ bg: 'gray.100' }}
          _hover={{ bg: 'gray.100' }}
          icon={<CloseIcon size="14px" color="gray.700" />}
          onPress={modal.setHide}
        />

        <VStack space={4} p={4}>
          <Box>
            <Text size="xl" color="gray.900" fontWeight={600}>
              Select Goal Profile
            </Text>
            <Text size="md" color="gray.700" fontWeight={400}>
              To be applied to all Dayparts
            </Text>
          </Box>

          <VStack space={2.5}>
            {map(options, (_option, index) => {
              const isActive = !!selectedOption && _option.goal?.name === selectedOption?.goal?.name;
              return (
                <Pressable
                  key={`${_option.label}.${index}`}
                  rounded="xl"
                  borderWidth={1}
                  borderColor={isActive ? 'primary.600' : 'gray.200'}
                  bgColor={isActive ? 'primary.50' : 'white'}
                  onPress={() => setSelectedOption(_option)}
                  shadow={1}
                >
                  <HStack space={3} rounded="xl" justifyContent="center" alignItems="center" px={3} minHeight="50px">
                    <VStack>
                      <Text size="md" fontWeight={500} color={isActive ? 'primary.600' : 'black'} lineHeight="xs">
                        {_option.label}
                      </Text>
                      {!!_option.caption && (
                        <Text size="sm" fontWeight={400} color={isActive ? 'primary.600' : 'gray.700'} numberOfLines={2}>
                          {_option.caption}
                        </Text>
                      )}
                    </VStack>

                    {_option.goal && <ChipGoal color={_option.goal.color} text={_option.goal.name} textColor={_option.textColor} />}
                    <Spacer />

                    <Box
                      alignItems="center"
                      justifyContent="center"
                      boxSize={4}
                      borderRadius="full"
                      borderWidth={1}
                      borderColor={isActive ? 'primary.600' : 'gray.300'}
                      bgColor={isActive ? 'primary.600' : 'white'}
                    >
                      <CheckIcon size="12px" color="white" />
                    </Box>
                  </HStack>
                </Pressable>
              );
            })}
          </VStack>

          <HStack space={3} justifyContent="space-between" pt={2}>
            <Button flex={1} minH={9} alternate outline onPress={modal.setHide}>
              Cancel
            </Button>
            <Button flex={1} minH={9} disabled={selectedOption === undefined} onPress={handleApply}>
              Apply Profile
            </Button>
          </HStack>
        </VStack>
      </Modal>
    </Fragment>
  );
};
