import { createSelector } from '@reduxjs/toolkit';
import { RootStore } from '../store';

const storeCokeFreestyleState = (_: RootStore) => _.storeFocus.data?.store?.config?.dashboardConfig?.cokeFreeStyle;
const storeConfigState = (_: RootStore) => _.storeFocus.data?.store;
const storeEmployees = (_: RootStore) => _.storeFocus.data?.employees;
const storeFocusState = (_: RootStore) => _.storeFocus;
const storeSiteIdState = (_: RootStore) => _.storeFocus.data?.store?.id;
const storeUserState = (_: RootStore) => _.userProfile.data;

export const selectStoreEmployees = createSelector([storeEmployees], _ => _);
export const selectStoreFocus = createSelector([storeFocusState], _ => _);
export const selectStoreConfig = createSelector([storeConfigState], _ => _);
export const selectCokeFreestyle = createSelector([storeCokeFreestyleState], _ => _);
export const selectStoreId = createSelector([storeSiteIdState], _ => _);
export const selectUserProfile = createSelector([storeUserState], _ => _);
