import { useEffect, useState } from 'react';
import { Box, HStack, Image, Spacer, Spinner, Switch, VStack, useTheme } from 'native-base';

import { Chip, Text } from '@pimm/base';
import { InstanceDetailsDto, RelayConfigDto, SensorConfigDto } from '@pimm/services/lib/store-equipment';
import { useAuthPermissions } from '@app/features/app';
import { useMutateDisableEnable } from '../hooks';
import { DoorEventIcon, Thermometer02Icon } from '../icons';
import { EnableDisableSensor } from './sensor-setting-confirmation';

type SensorInfoProps = {
  deviceType?: string;
  equipmentId?: string;
  isDoor?: boolean;
  isExternal?: boolean;
  isHubInfo?: boolean;
  isPrimary?: boolean;
  instance?: InstanceDetailsDto;
  sensor?: RelayConfigDto | SensorConfigDto;
  sensorName?: string;
  siteId?: string;
  onConfirmDisable?: (_enableDisableSensor?: EnableDisableSensor) => void;
};

export const SensorInfo = ({
  deviceType,
  equipmentId,
  isDoor,
  isHubInfo,
  isExternal,
  isPrimary,
  siteId,
  instance,
  sensor,
  sensorName,
  ...props
}: SensorInfoProps) => {
  const { colors } = useTheme();

  const [isOutOfService, setIsOutOfService] = useState<boolean>(instance?.OutOfService ?? true);

  const enableSensor = useMutateDisableEnable();
  const permissions = useAuthPermissions(state => state.permissions);

  const isEditEnabled = !!instance?.InstanceId && !!equipmentId && permissions?.equipmentInstance?.write;
  const isTeltonika = deviceType?.toUpperCase() === 'TELTONIKA';

  const handleChangeValue = () => {
    const oos = !isOutOfService;

    if (oos) {
      props?.onConfirmDisable?.({
        isEnabled: true,
        instanceId: instance?.InstanceId,
        equipmentId: equipmentId,
        sensorName: sensorName,
        isDoor: isDoor,
      });
      return;
    }

    // Enabling sensor from isEnabled = false to true
    if (siteId && instance?.InstanceId && equipmentId) {
      enableSensor.mutate({
        siteId: siteId,
        InstanceId: instance.InstanceId,
        EquipmentId: equipmentId,
        isEnabled: false,
      });
    }
  };

  useEffect(() => {
    setIsOutOfService(instance?.OutOfService ?? true);
  }, [instance?.OutOfService]);

  return (
    <VStack flex={1} rounded="xl" p={2} backgroundColor="white">
      <VStack h="2xs" rounded="xl" justifyContent="flex-end">
        <HStack space={2} alignItems="center" justifyContent={isHubInfo ? 'space-between' : undefined}>
          {!isHubInfo && (isDoor ? <DoorEventIcon size="md" color="black" /> : <Thermometer02Icon size="md" color="black" />)}
          <Text size="lg" fontWeight={700} color="black" lineHeight="xs" numberOfLines={2}>
            {sensorName ?? '--'}
          </Text>

          {isHubInfo && isPrimary && <Chip value="Primary" variant="success" color="success.600" textAlign="center" size="sm" />}
        </HStack>

        <VStack flex={1} alignItems="center" justifyContent="center">
          {!!sensor?.AdditionalProperties?.ImageUrl ? (
            <Image
              source={{
                uri: sensor?.AdditionalProperties?.ImageUrl,
              }}
              style={{ aspectRatio: 1, width: '90%', height: '90%' }}
              alt={`${sensorName} - ${sensor.Serial}`}
              resizeMode="contain"
              justifyContent="center"
            />
          ) : (
            <Text size="md" fontWeight={400} color="gray.600">
              No Image Available
            </Text>
          )}
        </VStack>
      </VStack>
      {!isExternal ? (
        <VStack flex={1} rounded="xl" p={3} backgroundColor="gray.50">
          {isTeltonika && ( //Show model when device type is teltonica
            <Text size="md" fontWeight={700} lineHeight="lg" color="black">
              {sensor?.AdditionalProperties?.Model ?? ''}
            </Text>
          )}
          <Text size="md" color="gray.900" fontWeight={500} lineHeight="md">
            S/N{' '}
            <Text size="md" color="gray.900" fontWeight={700} lineHeight="md">
              {sensor?.Serial ?? '--'}
            </Text>
          </Text>
        </VStack>
      ) : (
        <Spacer />
      )}
      {!isHubInfo && (
        <HStack flex={1} pt={2} px={1} justifyContent="space-between" alignItems="center">
          <Text size="md" color="gray.900" fontWeight={700} lineHeight="md">
            {!isOutOfService ? 'Enabled' : 'Disabled '}
          </Text>
          <Box
            h="container"
            w="container"
            p="2px"
            rounded="xl"
            bgColor={enableSensor.isLoading ? 'white' : !isOutOfService ? colors.success[400] : colors.error[400]}
            opacity={isEditEnabled ? 1 : 0.5}
          >
            {enableSensor.isLoading ? (
              <Spinner size="sm" variant="primary" />
            ) : (
              <Switch
                disabled={!isEditEnabled || enableSensor.isLoading}
                isChecked={!isOutOfService}
                trackColor={{ false: colors.error[400], true: colors.success[400] }}
                onToggle={handleChangeValue}
              />
            )}
          </Box>
        </HStack>
      )}
    </VStack>
  );
};
