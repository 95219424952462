import { Chip, ChipVariant } from '@pimm/base';
import { startCase } from 'lodash';

export const ViolationTrigger = ({ value }: { value: string }) => {
  let variant: ChipVariant = 'ghost';
  switch (value?.toLowerCase()) {
    case 'l3':
      variant = 'error';
      break;
    case 'l2':
      variant = 'warning';
      break;
    case 'l1':
      variant = 'success';
      break;
    default:
  }

  return <Chip _container={{ rounded: 'md' }} color={!variant ? 'black' : undefined} flat value={startCase(value)} variant={variant} />;
};
