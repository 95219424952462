import { useMemo, useState } from 'react';
import { Box, Divider, HStack, ScrollView, VStack } from 'native-base';
import { first, map, partition, sortBy } from 'lodash';

import { ArrowSquareUpRightIcon, Button, Text } from '@pimm/base';
import { HubStatDto } from '@pimm/services/lib/sms-workforce';
import { ResourceLoader } from '@app/components/shared';
import { useGetHubStats } from '../hooks';
import { LearningHubCard } from './learning-hub-card';
import { LearningHubPreview } from './learning-hub-preview';

type LearningHubProps = {
  queryHubStats: ReturnType<typeof useGetHubStats>;
};

export const LearningHub = ({ queryHubStats }: LearningHubProps) => {
  const [hubStatFocus, setHubStatFocus] = useState<HubStatDto>();

  const [hubStats, smsStore] = useMemo(() => {
    return partition(queryHubStats.data || [], ({ role }) => role?.toLowerCase() !== 'sms store').map(group =>
      sortBy(group, ['firstName', 'lastName']),
    );
  }, [queryHubStats.data]);

  const handlePressEmployee = (hubStat: HubStatDto) => () => {
    setHubStatFocus(hubStat);
  };

  const learningPathUrl = first(smsStore)?.availableLearningPathsUrl;

  return (
    <ResourceLoader w="full" h="full" isLoading={queryHubStats.isLoading}>
      <HStack h="full">
        <ScrollView flex={1} h="full" bg="gray.50">
          <HStack h="full" w="full" flexWrap="wrap" p={2}>
            {map(hubStats, _hubStat => {
              const isSelected = _hubStat?.id === hubStatFocus?.id;
              return (
                <LearningHubCard
                  key={`learning-hub.${_hubStat.id}`}
                  _container={{ w: '1/3' }}
                  hubStat={_hubStat}
                  isSelected={isSelected}
                  onPress={handlePressEmployee(_hubStat)}
                />
              );
            })}
          </HStack>
        </ScrollView>

        <Divider orientation="vertical" bgColor="gray.200" />

        <VStack w="2/5" bg="gray.50">
          <HStack space={2} alignItems="center" justifyContent="space-between" py={2} px={4} minH="58px" borderBottomWidth={1} bg="white">
            <Box>
              <Text size="lg" fontWeight={600} color="black" lineHeight="md">
                Open Academy
              </Text>
              <Text size="md" fontWeight={400} color="gray.600" lineHeight="xs">
                Access additional training & troubleshooting content.
              </Text>
            </Box>

            <Button
              alternate
              outline
              borderColor="#FCB816"
              bg="#FCB816"
              disabled={!learningPathUrl}
              onPress={() => window.open(learningPathUrl!, '_blank', 'noreferrer')}
              _hover={{ bg: '#FCB816' }}
              _pressed={{ bg: '#FCB816' }}
              _text={{ color: 'black' }}
              endIcon={<ArrowSquareUpRightIcon size="sm" color="black" />}
            >
              Academy
            </Button>
          </HStack>

          {hubStatFocus ? (
            <LearningHubPreview key={hubStatFocus.id} hubStat={hubStatFocus} />
          ) : (
            <Box flex={1} alignItems="center" justifyContent="center">
              <Text size="xl" fontWeight={600} color="gray.600">
                Select a user from the left to see more details
              </Text>
            </Box>
          )}
        </VStack>
      </HStack>
    </ResourceLoader>
  );
};
