import { useTranslation } from 'react-i18next';
import { Box, FlatList, HStack, useTheme } from 'native-base';
import { map } from 'lodash';

import { Button, TargetIcon, Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { AggWeeklyFocusCategoryDto } from '@pimm/services/lib/sms-workforce';

type KitchenGoalsProps = {
  categories?: AggWeeklyFocusCategoryDto[];
  onEditGoals?: () => void;
};

export const KitchenWeeklyGoals = ({ categories, ...props }: KitchenGoalsProps) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { translate } = useAppLocale();
  return (
    <FlatList
      pb={3}
      h="full"
      bg="gray.50"
      data={categories}
      stickyHeaderIndices={[0]}
      scrollEnabled
      ListHeaderComponent={() => (
        <HStack alignItems="center" justifyContent="space-between" py={2} px={2} bg="gray.50">
          <Text size="xl" fontWeight={700} color="black" lineHeight="xs">
            {t('common:goals')}
          </Text>

          {!!props.onEditGoals && (
            <Button
              size="sm"
              px={2}
              minH={7}
              bg="primary.50"
              endIcon={<TargetIcon size="16px" color={colors.primary[600]} />}
              _pressed={{ bg: 'primary.50' }}
              _hover={{ bg: 'primary.50' }}
              _text={{ color: 'primary.600' }}
              outline
              onPress={props.onEditGoals}
            >
              {t('common:edit_goals')}
            </Button>
          )}
        </HStack>
      )}
      renderItem={({ item: category, index }) => {
        const isEmpty = (category.categoryGoals ?? []).length === 0;
        return (
          <Box rounded="lg" mt={index ? 2 : 0} borderWidth={1} bg="white" mx={2}>
            <HStack space={2} alignItems="center" py={2} px={2} minH={6} borderBottomWidth={1}>
              <Box justifyContent="center" rounded="md" w={5} h={5} bg={isEmpty ? 'gray.100' : 'primary.100'}>
                <Text size="md" fontWeight={700} color="black" textAlign="center" lineHeight="xs">
                  {category.seq}
                </Text>
              </Box>
              <Text size="md" fontWeight={700} color="gray.800" textTransform="uppercase" lineHeight="xs" numberOfLines={2}>
                {`${translate(category.title, category.translations)}`}
              </Text>
            </HStack>

            <Box justifyContent="center" py={1.5} px={2.5} minH={10}>
              {isEmpty ? (
                <Text size={{ md: 'md', xl: 'lg' }} fontWeight={500} color="gray.300" lineHeight="sm">
                  {t('common:no_goals_today')}
                </Text>
              ) : (
                <Text size={{ md: 'md', xl: 'lg' }} fontWeight={500} color="black" lineHeight="sm">
                  {map(category.categoryGoals, goal => goal.description ?? '').join('\n')}
                </Text>
              )}
            </Box>
          </Box>
        );
      }}
    />
  );
};
