import { ComponentProps } from 'react';
import { useQueryClient } from 'react-query';
import { Box, HStack, VStack } from 'native-base';
import { map } from 'lodash';

import { useAppLocale } from '@pimm/common';
import { Modal, Text } from '@pimm/base';
import { formatToShortTimeOnly, formatToTimeOnly } from '@app/utils/date-formatter';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { useOpsPhasePositioning } from '../context';
import { OpsPhaseAssignment, OpsPhasePositionAssignment } from '../reducers/ops-phase-positioning.reducer';
import { SelectSalesVolumeProfiles } from './select-sales-volume-profiles';
import { SelectConfirmSalesVolumeProfile } from './select-confirm-sales-volume-profile';
import { SelectEligableEmployee } from './select-eligable-employee';
import PressablePositionAssignee from './pressable-position-assignee';

type OpsPhasePositionsProps = {
  opsPhase: OpsPhaseAssignment;
  onPressSelect?: (position: OpsPhasePositionAssignment) => void;
};

export const OpsPhasePositions = ({ opsPhase, ...props }: OpsPhasePositionsProps) => {
  const queryClient = useQueryClient();
  const { translate } = useAppLocale();
  const { opsTasksPositioning, salesVolumeProfiles, siteId, updatePosition } = useOpsPhasePositioning();
  const modalConfirm = useModalFocus<number>();
  const modalPosition = useModalFocus<OpsPhasePositionAssignment>();

  const handlePressSelect = (position: OpsPhasePositionAssignment) => () => {
    modalPosition.setOpen(position);
  };

  const handleChangeAssignee: ComponentProps<typeof SelectEligableEmployee>['onChange'] = (assignee, isDelete) => {
    if (isDelete) {
      const selectedAssignee = opsPhase.positions.find(s => s?.assignee?.id === assignee?.id);
      updatePosition(opsPhase.id, { ...selectedAssignee, assignee: undefined });
    } else {
      updatePosition(opsPhase.id, { ...modalPosition.payload, assignee });
      modalPosition.setHide();
    }
  };

  const handleConfirmRefetch = () => {
    queryClient.invalidateQueries({ queryKey: ['GetOpsTasksPositioning'] });
    opsTasksPositioning.refetch();
  };
  return (
    <VStack space={2} p={4} pt={3} h="full" borderRadius="xl" bg="light.50">
      <HStack justifyContent="space-between" alignItems="center">
        <VStack>
          <Text size={{ md: 'lg', xl: 'xl' }} fontWeight={700} color="gray.900" textTransform="uppercase" lineHeight="xs">
            {translate(opsPhase.phase, opsPhase?.translations)}
          </Text>
          <Text size="md" fontWeight={700} color="gray.900" textTransform="uppercase">
            {formatToShortTimeOnly(opsPhase?.startTime) ?? ''} - {formatToShortTimeOnly(opsPhase?.endTime) ?? ''}
          </Text>
        </VStack>

        <SelectSalesVolumeProfiles
          opsPhaseTitle={opsPhase.phase}
          salesVolumeProfileId={opsPhase.salesVolumeProfileId}
          salesVolumeProfiles={salesVolumeProfiles}
          onChange={modalConfirm.setOpen}
        />
      </HStack>

      <Box borderColor="gray.200" borderWidth={1} borderRadius="xl" bg="white" overflow="hidden">
        <HStack alignItems="center" h={9} bg="gray.200">
          <Box flex={1} pl={3} pr={2} maxW="180px">
            <Text size="sm" fontWeight={500} color="gray.600">
              Position
            </Text>
          </Box>
          <Box flex={1} px={2}>
            <Text size="sm" fontWeight={500} color="gray.600">
              Assigned To
            </Text>
          </Box>
          <Box flex={1} pr={3} maxW="110px">
            <Text size="sm" fontWeight={500} color="gray.600" textAlign="center">
              Start Time
            </Text>
          </Box>
        </HStack>
        {map(opsPhase.positions, position => {
          return (
            <HStack key={position.id} alignItems="center" py={1} height="50px" borderColor="gray.100" borderTopWidth={1}>
              <Box pl={3} pr={2} flex={1} maxW="180px">
                <Text size="md" fontWeight={500} color="black" lineHeight="xs">
                  {translate(position.title, position?.translations)}
                </Text>
              </Box>
              <Box flex={1} px={2}>
                <PressablePositionAssignee
                  opsPhaseId={opsPhase.id}
                  positionId={position.id}
                  assignee={position.assignee}
                  onClear={handleChangeAssignee}
                  onPress={handlePressSelect(position)}
                />
              </Box>
              <Box flex={1} pr={3} maxW="110px">
                <Text size="md" fontWeight={500} color="black" textAlign="center">
                  {formatToTimeOnly(position?.startTime) ?? ''}
                </Text>
              </Box>
            </HStack>
          );
        })}
      </Box>

      <Modal
        hideClose
        isOpen={modalConfirm.isOpen}
        onClose={modalConfirm.setHide}
        size="md"
        _content={{ rounded: 'xl', p: 5, pt: 4, maxW: 400 }}
      >
        <SelectConfirmSalesVolumeProfile
          opsPhaseId={opsPhase.id}
          salesVolumeProfileId={modalConfirm.payload}
          siteId={siteId}
          confirmRefetch={handleConfirmRefetch}
          onClose={modalConfirm.setHide}
        />
      </Modal>

      <Modal
        size="full"
        _content={{ rounded: 'xl', maxWidth: { md: 1080, xl: 1200 }, height: '95%', maxHeight: 900 }}
        isOpen={modalPosition.isOpen}
        noPadding
        hideClose
      >
        {!!modalPosition.payload && (
          <SelectEligableEmployee
            assignee={modalPosition.payload.assignee}
            employees={opsPhase.eligableEmployees}
            opsPhaseId={opsPhase.id}
            positionId={modalPosition.payload.id}
            salesVolumeProfileId={opsPhase.salesVolumeProfileId!}
            siteId={siteId}
            startOfTask={opsPhase.startTime}
            onChange={handleChangeAssignee}
            onClose={modalPosition.setHide}
          />
        )}
      </Modal>
    </VStack>
  );
};
