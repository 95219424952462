import { ComponentType, createElement, useEffect, useState } from 'react';
import { HStack, IIconProps, Pressable, VStack, View } from 'native-base';
import { map } from 'lodash';

import { Text } from '@pimm/base';
import { CultureData, UserProfileDto } from '@pimm/services/lib/sms-tenants';
import { GetUserProfile } from '@pimm/services/lib/sms-tenants/services';
import { useAuthPermissions } from '@app/features/app';
import { FormUserInformation } from './form-user-information';
import { UserSecurity, UserSecurityChangeEvent } from './user-security';
import { Flag05Icon, LockKeyholeSquareIcon } from '../icons';

type UserSettingsTab = 'information' | 'security';

export type UserSettingChangeEvent = { name: 'update-profile'; data?: UserProfileDto } | UserSecurityChangeEvent;

type UserSettingsProps = {
  cultures?: CultureData[];
  user?: UserProfileDto;
  onClose?: () => void;
  onEndChange: (changeEvent?: UserSettingChangeEvent) => void;
};

export const UserSettings = ({ user, cultures, ...props }: UserSettingsProps) => {
  const permissions = useAuthPermissions(state => state.permissions);
  const [tabFocus, setTabFocus] = useState<UserSettingsTab>('information');
  const [tabItems] = useState<{ icon: ComponentType; value: UserSettingsTab; label: string; hidden?: boolean }[]>([
    { icon: Flag05Icon, value: 'information', label: 'Information', hidden: !permissions?.userSettingsInfo },
    { icon: LockKeyholeSquareIcon, value: 'security', label: 'Security', hidden: !permissions?.userSettingsSecurity?.write },
  ]);
  const [profile, setProfile] = useState<UserProfileDto | undefined>(user);

  const handleSecurityChange = (changeEvent: UserSecurityChangeEvent) => {
    if (props.onEndChange) props.onEndChange(changeEvent);
  };

  const handleProfileChange = (data?: UserProfileDto) => {
    setProfile(data);
    if (props.onEndChange) props.onEndChange({ name: 'update-profile', data: data });
  };

  const handleTabChange = (_value: UserSettingsTab) => () => {
    setTabFocus(_value);
  };

  useEffect(() => {
    const fetchProfile = async (userId: string) => {
      // Skipping the use of react-query to get the latest profile
      const profile = await GetUserProfile({ userId });
      // Get latest profile, this is to make sure that we have the latest copy before doing any changes
      if (profile) setProfile(profile);
    };
    if (user?.userId) fetchProfile(user.userId);
  }, [user?.userId]);

  return (
    <VStack height="full" w="full">
      <HStack alignItems="center" px={4} h="52px" borderBottomWidth={1}>
        <Text size="xl" fontWeight={700} color="gray.900">
          User Settings
        </Text>
      </HStack>

      <HStack flex={1}>
        <VStack w="1/4" borderRightColor="gray.200" borderRightWidth={1} p={4} space={3}>
          <Text fontWeight={500} size="lg" color="gray.900">
            Settings
          </Text>
          <VStack space={1}>
            {map(tabItems, _tab => {
              const isActive = _tab.value === tabFocus;
              if (_tab.hidden) return;
              return (
                <Pressable key={_tab.value} rounded="lg" onPress={handleTabChange(_tab.value)}>
                  {({ isHovered }) => (
                    <HStack space={3} rounded="lg" alignItems="center" px={3} minH={10} bg={isActive || isHovered ? 'gray.50' : undefined}>
                      {createElement<IIconProps>(_tab.icon, { color: 'gray.500' })}
                      <Text size="lg" fontWeight={600} color="gray.700">
                        {_tab.label}
                      </Text>
                    </HStack>
                  )}
                </Pressable>
              );
            })}
          </VStack>
        </VStack>

        <View flex={1} h="full">
          {tabFocus === 'information' && !!permissions?.userSettingsInfo && (
            <FormUserInformation
              cultures={cultures}
              isEditable={permissions?.userSettingsInfo?.write}
              user={profile}
              onClose={props.onClose}
              onEndChange={handleProfileChange}
            />
          )}

          {tabFocus === 'security' && <UserSecurity user={profile} onEndChange={handleSecurityChange} />}
        </View>
      </HStack>
    </VStack>
  );
};
