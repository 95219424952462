import { Icon, IIconProps } from 'native-base';
import { Path } from 'react-native-svg';

export const Building02Icon = (props: IIconProps) => {
  return (
    <Icon viewBox="0 0 18 18" fill="none" {...props}>
      <Path
        d="M11.5 16.5V12C11.5 11.5333 11.5 11.2999 11.4092 11.1217C11.3293 10.9649 11.2018 10.8374 11.045 10.7575C10.8667 10.6667 10.6334 10.6667 10.1667 10.6667H7.83333C7.36662 10.6667 7.13327 10.6667 6.95501 10.7575C6.79821 10.8374 6.67072 10.9649 6.59083 11.1217C6.5 11.2999 6.5 11.5333 6.5 12V16.5M1.5 4.83333C1.5 6.21405 2.61929 7.33333 4 7.33333C5.38071 7.33333 6.5 6.21405 6.5 4.83333C6.5 6.21405 7.61929 7.33333 9 7.33333C10.3807 7.33333 11.5 6.21405 11.5 4.83333C11.5 6.21405 12.6193 7.33333 14 7.33333C15.3807 7.33333 16.5 6.21405 16.5 4.83333M4.16667 16.5H13.8333C14.7668 16.5 15.2335 16.5 15.59 16.3183C15.9036 16.1586 16.1586 15.9036 16.3183 15.59C16.5 15.2335 16.5 14.7668 16.5 13.8333V4.16667C16.5 3.23325 16.5 2.76654 16.3183 2.41002C16.1586 2.09641 15.9036 1.84144 15.59 1.68166C15.2335 1.5 14.7668 1.5 13.8333 1.5H4.16667C3.23325 1.5 2.76654 1.5 2.41002 1.68166C2.09641 1.84144 1.84144 2.09641 1.68166 2.41002C1.5 2.76654 1.5 3.23325 1.5 4.16667V13.8333C1.5 14.7668 1.5 15.2335 1.68166 15.59C1.84144 15.9036 2.09641 16.1586 2.41002 16.3183C2.76654 16.5 3.23325 16.5 4.16667 16.5Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Icon>
  );
};
