import { memo, useEffect, useState } from 'react';
import { Box, ChevronDownIcon, ChevronUpIcon, HStack, Pressable, SectionList } from 'native-base';
import { reduce } from 'lodash';

import { Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { Category, Document, StringTranslation } from '@pimm/services/lib/sms-documents';

interface DocumentSection<T> {
  id?: string;
  title: string;
  collapse?: boolean;
  data: T[];
  translations?: StringTranslation[];
}

type DocumentSectionsProps = {
  category?: Category;
  documentId?: string;
  keyword?: string;
  onPressDocument?: (document: Document) => void;
};

const DocumentSections = ({ category, documentId, keyword, ...props }: DocumentSectionsProps) => {
  const { translate } = useAppLocale();
  const [sections, setSections] = useState<DocumentSection<Document>[]>([]);

  const handleChangeCollapse = id => () => {
    setSections(prev =>
      prev.map(section => {
        if (section.id !== id || section.data.length === 0) return section;
        return { ...section, collapse: !section.collapse };
      }),
    );
  };

  const handlePressItem = document => () => {
    if (props.onPressDocument) props.onPressDocument(document);
  };

  useEffect(() => {
    // prettier-ignore
    const sections = reduce(category?.children, (arr: DocumentSection<Document>[], child) => {
      let documents = child.documents ?? [];
      // Filter group document with keyword
      if (keyword) {
        documents = child.documents?.filter(({ title }) => {
          return title?.toLowerCase().includes(keyword.toLowerCase())
        }) as Document[];
      }
      // Display list of group with documents
      if (!!keyword && documents.length === 0) return arr;
      return arr.concat({
        id: child.id,
        title: child.title || '',
        data: documents,
        collapse: documents.length > 0,
        translations: child.translations
      })
    }, [])
    setSections(sections);
  }, [category, keyword]);

  return (
    <SectionList
      pt={4}
      px={{ md: 3, xl: 4 }}
      h="full"
      w="full"
      sections={sections}
      renderSectionHeader={({ section }) => {
        const isEmpty = section.data.length === 0;
        return (
          <Pressable w="full" mb={{ md: 1, xl: 2 }} onPress={handleChangeCollapse(section.id)}>
            <HStack alignItems="center" justifyContent="space-between">
              <Text size={{ md: 'md', xl: 'lg' }} fontWeight={600} color="black">
                {translate(section.title, section?.translations)}
              </Text>
              {!isEmpty && (
                <>{section.collapse ? <ChevronUpIcon size={3.5} color="black" /> : <ChevronDownIcon size={3} color="black" />}</>
              )}
            </HStack>
          </Pressable>
        );
      }}
      renderSectionFooter={() => <Box mb={{ md: 3, xl: 4 }} />}
      renderItem={({ item, index, section }) => {
        const isLastItem = section.data.length === index + 1;
        if (!section.collapse) return <></>;
        return (
          <Box
            borderWidth={1}
            borderTopWidth={index ? 0 : 1}
            borderTopLeftRadius={index ? undefined : 'lg'}
            borderTopRightRadius={index ? undefined : 'lg'}
            borderBottomLeftRadius={isLastItem ? 'lg' : undefined}
            borderBottomRightRadius={isLastItem ? 'lg' : undefined}
            overflow="hidden"
          >
            <Pressable
              w="full"
              h="full"
              py={1}
              px={{ md: 3, xl: 4 }}
              minHeight={{ md: 9, xl: 10 }}
              justifyContent="center"
              backgroundColor={documentId === item.id ? 'gray.200' : undefined}
              _hover={{ backgroundColor: documentId === item.id ? 'gray.200' : 'gray.50' }}
              onPress={handlePressItem(item)}
            >
              <Text size="md" fontWeight={500} color="gray.900" lineHeight="xs">
                {translate(item?.title, item?.translations)}
              </Text>
            </Pressable>
          </Box>
        );
      }}
    />
  );
};

export default memo(DocumentSections);
