import { createContext, PropsWithChildren, useContext } from 'react';

import { useGetSettingsAndGoals } from '../hooks';

type QuerySettingsAndGoalsProviderProps = PropsWithChildren & {
  settingsAndGoals: ReturnType<typeof useGetSettingsAndGoals>;
};

const QuerySettingsAndGoalsContext = createContext<ReturnType<typeof useGetSettingsAndGoals>>(undefined!);

export const QuerySettingsAndGoalsProvider = ({ children, settingsAndGoals }: QuerySettingsAndGoalsProviderProps) => {
  return <QuerySettingsAndGoalsContext.Provider value={settingsAndGoals}>{children}</QuerySettingsAndGoalsContext.Provider>;
};

export const QuerySettingsAndGoalsConsumer = QuerySettingsAndGoalsContext.Consumer;

export const useQuerySettingsAndGoals = () => {
  // get the context
  const context = useContext(QuerySettingsAndGoalsContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useQuerySettingsAndGoals was used outside of its Provider');
  }
  return context;
};
