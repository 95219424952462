import React, { createContext, useContext, useEffect, useMemo } from 'react';

import { PositionSlotDto } from '@pimm/services/lib/sms-workforce';
import { useGetPositionSchedules } from '../hooks';
import { first } from 'lodash';

export type KitchenPositionsContextReturn = {
  isLoading?: boolean;
  positionSlots: PositionSlotDto[];
};

export const KitchenPositionsContext = createContext<KitchenPositionsContextReturn>(undefined!);

export type KitchenPositionsProviderProps = {
  children: React.ReactNode;
  positionSchedules: ReturnType<typeof useGetPositionSchedules>[0];
};

export const KitchenPositionsProvider = ({ children, positionSchedules }: KitchenPositionsProviderProps) => {
  const { positionSlots } = useMemo(() => {
    const positionGroup = first(positionSchedules.data);
    const positionSlots = positionGroup?.positionSlots;
    return {
      positionSlots: positionSlots ?? [],
    };
  }, [positionSchedules]);
  return (
    <KitchenPositionsContext.Provider value={{ isLoading: positionSchedules.isLoading, positionSlots: positionSlots }}>
      {children}
    </KitchenPositionsContext.Provider>
  );
};

export const KitchenPositionsConsumer = KitchenPositionsContext.Consumer;

export const useKitchenPositions = () => {
  // Get the context
  const context = useContext(KitchenPositionsContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useKitchenPositions was used outside of its Provider');
  }
  return context;
};
