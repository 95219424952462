import { UseQueryResult, useQuery } from 'react-query';
import { filter, map } from 'lodash';

import { GetEquipmentInstances } from '@pimm/services/lib/store-equipment/services';
import { EquipmentInstancesDto } from '@pimm/services/lib/store-equipment';

type GetDisplayGroupsReturn = { displayGroup: string; equipment: EquipmentInstancesDto[] }[];

export const useGetDisplayGroups = (siteId?: string, queryKey?: string): UseQueryResult<GetDisplayGroupsReturn> => {
  const displayGroups = useQuery({
    enabled: !!siteId,
    queryKey: [queryKey ?? 'GetDisplayGroups', siteId],
    queryFn: async ({ queryKey }) => {
      const instances = await GetEquipmentInstances({ SiteId: siteId! });
      const displayGroups = map(['walk', 'cooler', 'freezer', 'other'], key =>
        filter<EquipmentInstancesDto>(instances, instance => {
          return (
            !!instance.TemperatureMetrics?.Temperature &&
            (instance.TemperatureMetrics?.Temperature?.ThresholdProfile?.DisplayGroup?.toLowerCase() || 'other').includes(key)
          );
        }),
      )
        .filter(items => items.length)
        .map(items => ({
          displayGroup: items[0]?.TemperatureMetrics?.Temperature?.ThresholdProfile?.DisplayGroup ?? 'Other',
          equipment: items,
        }));
      return displayGroups;
    },
  });

  return displayGroups;
};
