import { useCallback } from 'react';
import { Box, FlatList, useBreakpointValue } from 'native-base';

import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { useOpsPhasePositioning } from '../context';
import { OpsPhaseAssignment, OpsPhasePositionAssignment } from '../reducers/ops-phase-positioning.reducer';
import { OpsPhasePositions } from './ops-phase-positions';

type OpsPhasesPositioningProps = {
  editable?: boolean;
};

export const OpsPhasesPositioning = ({ editable }: OpsPhasesPositioningProps) => {
  const numColumns = useBreakpointValue({ sm: 2, xl: 3 });
  const { positioning } = useOpsPhasePositioning();
  const modalAssignee = useModalFocus<OpsPhaseAssignment>();

  const handleOpenAssignment = useCallback(
    (opsPhase: OpsPhaseAssignment) => (position: OpsPhasePositionAssignment) => {
      if (editable) modalAssignee.setOpen({ ...opsPhase, positions: [position] });
    },
    [editable, positioning.opsPhases],
  );

  return (
    <FlatList
      p={2}
      h="full"
      w="full"
      data={positioning.opsPhases}
      extraData={[numColumns]}
      numColumns={numColumns}
      renderItem={({ item: opsPhase }) => (
        <Box p={1} w={`1/${numColumns}`}>
          <Box>
            <OpsPhasePositions opsPhase={opsPhase} onPressSelect={handleOpenAssignment(opsPhase)} />
          </Box>
        </Box>
      )}
    />
  );
};
