import React, { useEffect, useMemo, useState } from 'react';
import { ArrowBackIcon, ArrowForwardIcon, HStack, View, VStack } from 'native-base';
import { filter, isSafeInteger, range } from 'lodash';
import moment from 'moment';

import { Button, Text } from '@pimm/base';
import { useSiteTime } from '@app/features/store-core';
import { CalendarEvent } from './context/calendar-events-context';
import { DailyEvents } from './components';
import { calendarDateSpan, isSameMonthDate } from './_helper';

export type CalendarEventsProps = {
  _container?: React.ComponentProps<typeof VStack>;
  _dayProps?: React.ComponentProps<typeof View>;
  date?: Date;
  events: CalendarEvent[];
  weekly?: boolean;
};

const CalendarEvents = ({ _container, events, weekly = false, _dayProps, ...props }: CalendarEventsProps) => {
  const siteTime = useSiteTime();
  const [dateFocus, setDateFocus] = useState<Date>();

  const calendar = useMemo(() => {
    if (!dateFocus) return undefined;
    return calendarDateSpan(dateFocus, siteTime.firstDayOfWeek, weekly);
  }, [dateFocus, siteTime.firstDayOfWeek, weekly]);

  const handleChange = (amount?: number) => () => {
    let nextDate: Date;

    if (amount === undefined) {
      nextDate = siteTime.today();
    } else {
      const momentDate = moment(dateFocus).add(amount, weekly ? 'weeks' : 'months');
      const currentMonthNumber = momentDate.month();

      if (!weekly && currentMonthNumber !== props.date?.getMonth()) {
        nextDate = momentDate.date(1).toDate();
      } else {
        nextDate = momentDate.toDate();
      }
    }
    setDateFocus(nextDate);
  };

  useEffect(() => {
    setDateFocus(props.date || siteTime.today());
  }, [props.date]);

  return (
    <VStack h="full" w="full" borderWidth={1} {..._container}>
      <HStack space={2} flexDirection="row" alignItems="center" px={4} minHeight="54px">
        <Button w="74px" alternate outline onPress={handleChange()}>
          Today
        </Button>
        {[-1, 1].map(num => (
          <Button key={num} alternate outline onPress={handleChange(num)}>
            {num === -1 ? <ArrowBackIcon size={18} color="gray.700" /> : <ArrowForwardIcon size={18} color="gray.700" />}
          </Button>
        ))}
        {!!calendar && (
          <Text color="gray.900" size="4xl" fontWeight={600} ml={2}>
            {!weekly
              ? moment(dateFocus).format('MMMM yyyy')
              : [moment(calendar?.startOfDate).format('MMM DD'), moment(calendar?.endOfDate).format('ll')].join(' - ')}
          </Text>
        )}
      </HStack>

      <View flex={1} borderTopWidth={1}>
        {!!calendar && (
          <VStack w="full" h="full">
            <HStack w="full" h={10}>
              {range(7).map(num => {
                const day = moment(calendar.startOfDate).add(num, 'days');
                return (
                  <HStack
                    key={num}
                    flex={1}
                    space={1.5}
                    alignItems="center"
                    justifyContent="center"
                    backgroundColor="gray.25"
                    borderRightWidth={num < 6 ? 1 : 0}
                  >
                    <Text size="2xl" color="gray.600" fontWeight={600}>
                      {day.format('ddd')}
                    </Text>
                    {weekly && (
                      <Text size="2xl" color="black" fontWeight={600}>
                        {day.format('DD')}
                      </Text>
                    )}
                  </HStack>
                );
              })}
            </HStack>

            <HStack flex={1} flexWrap="wrap" w="full">
              {range(weekly ? 7 : 42).map(num => {
                const day = moment(calendar.startOfDate).add(num, 'days');
                const dailyEvents = filter(events || [], ({ dateTime }) => isSameMonthDate(dateTime, day.toDate()));
                return (
                  <DailyEvents
                    key={day.format()}
                    position="relative"
                    w="14.285%"
                    h={weekly ? 'full' : '1/6'}
                    borderTopWidth={1}
                    borderRightWidth={!isSafeInteger((num + 1) / 7) ? 1 : 0}
                    date={day.toDate()}
                    events={dailyEvents}
                    hideDay={weekly}
                    isSameMonth={dateFocus?.getMonth() === day.month()}
                    {..._dayProps}
                  />
                );
              })}
            </HStack>
          </VStack>
        )}
      </View>
    </VStack>
  );
};

export default React.memo(CalendarEvents);
