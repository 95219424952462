import { useEffect, useState } from 'react';
import { Box, IBoxProps } from 'native-base';
import { isUndefined } from 'lodash';
import moment from 'moment';

import { Text } from '@pimm/base';
import { useSiteTime } from '@app/features/store-core';

type ClockProps = IBoxProps & {
  _text?: React.ComponentProps<typeof Text>;
};

export const Clock = ({ _text, ...props }: ClockProps) => {
  const siteTime = useSiteTime();
  const [time, setTime] = useState<string>();

  useEffect(() => {
    let interval;

    if (!isUndefined(siteTime.utcOffsetInMinutes)) {
      interval = setInterval(() => setTime(moment(siteTime.today()).format('hh:mm A')), 1000);
    }
    return () => clearInterval(interval);
  }, [siteTime?.utcOffsetInMinutes]);

  if (!time) return null;
  return (
    <Box {...props}>
      <Text {..._text}>{time}</Text>
    </Box>
  );
};
