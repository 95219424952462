import { Box, IBoxProps } from 'native-base';
import { useKitchenLayout } from '../context/kitchen-layout.context';

type PositionBoxProps = IBoxProps & {
  positionId?: string;
  intensity?: number;
};

export const PositionBox = ({ children, intensity, positionId, ...props }: PositionBoxProps) => {
  const { positionLookup } = useKitchenLayout();
  const position = positionId ? positionLookup[positionId] : undefined;
  const color = position?.color ?? 'gray.100';
  const opacity = (intensity ?? 100) / 100;

  return (
    <Box position="relative" {...props}>
      <Box
        position="absolute"
        w="100%"
        h="100%"
        top={0}
        left={0}
        zIndex={0}
        rounded={props.rounded}
        bgColor={props.bgColor ?? color}
        borderColor={props.borderColor ?? color}
        opacity={opacity}
      />
      <Box>{children}</Box>
    </Box>
  );
};
