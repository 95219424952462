import { CleaningScheduleTypeEnum, CleaningTaskStatusEnum, StringTranslation } from '@pimm/services/lib/sms-workforce/types';

export interface CleaningTaskAssignee {
  id?: number;
  employeeId?: string;
  firstName?: string;
  lastName?: string;
  dueDate?: Date;
  status?: CleaningTaskStatusEnum;
  statusDate?: Date;
}

export interface CleaningTaskItem {
  id?: number;
  assignee?: CleaningTaskAssignee;
  category?: string;
  categoryTranslations?: StringTranslation[];
  startTime?: Date;
  endTime?: Date;
  description?: string;
  descriptionTranslations?: StringTranslation[];
  linkedDoc?: string;
  positionId?: string;
  positionTitle?: string;
  positionTitleTranslations?: StringTranslation[];
  title?: string;
  translations?: StringTranslation[];
}

export type CleaningPlan = {
  date: Date;
  scheduleType: CleaningScheduleTypeEnum;
  tasks: CleaningTaskItem[];
};

export enum CleaningAssignmentsAction {
  UPDATE = 'UPDATE',
  RESET = 'RESET',
}

// Define the action types
type Action =
  | { type: CleaningAssignmentsAction.UPDATE; payload?: Partial<CleaningTaskItem> }
  | { type: CleaningAssignmentsAction.RESET; payload?: CleaningPlan[] };

// Create the reducer
export const CleaningAssignmentsReducer = (state: CleaningPlan[], action: Action): CleaningPlan[] => {
  switch (action.type) {
    case CleaningAssignmentsAction.RESET:
      return action.payload ?? [];

    case CleaningAssignmentsAction.UPDATE:
      const newState = state.map(cleaningPlan => {
        return {
          ...cleaningPlan,
          tasks: cleaningPlan.tasks.map(task => {
            if (task.id === action.payload?.id) {
              return {
                ...task,
                ...action.payload,
              };
            }
            return task;
          }),
        };
      });

      return newState;
    default:
      return state;
  }
};
