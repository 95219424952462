import { useState } from 'react';
import { Box, HStack, View } from 'native-base';
import { range } from 'lodash';

import { NumberText, Text } from '@pimm/base';

export const LaborHourGuidelines = () => {
  const [size, setSize] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
  const laborHourGuidelineColors = [
    '#FCE7B9',
    '#FBE09B',
    '#FADA83',
    '#F9D66E',
    '#E2CE62',
    '#C7C85D',
    '#B3C459',
    '#8CBA54',
    '#44A24A',
    '#4E8541',
    '#557C3E',
  ];

  const handleChangeLayout = ({ nativeEvent: { layout } }) => {
    setSize({ width: layout.width, height: layout.height });
  };

  return (
    <View w="full">
      <HStack h={{ md: 6, xl: 9 }}>
        <Box width={120} justifyContent="center" h="full">
          <Text size={{ md: 'sm', xl: 'md' }} fontWeight={700} color="gray.700">
            Labor Hours
          </Text>
        </Box>
        <HStack flex={1} h="full" rounded="lg" overflow="hidden">
          {range(11).map(index => {
            return (
              <Box
                key={`labor-hours.${index}`}
                flex={1}
                alignItems="center"
                justifyContent="center"
                h="full"
                backgroundColor={laborHourGuidelineColors[index]}
              >
                <Text size={{ md: 'sm', xl: 'md' }} fontWeight={700} color="black">
                  {index + 4}
                </Text>
              </Box>
            );
          })}
        </HStack>
      </HStack>
      <HStack h={{ md: 6, xl: 9 }}>
        <Box width={120} justifyContent="center" h="full">
          <Text size={{ md: 'sm', xl: 'md' }} fontWeight={700} color="gray.700">
            Hourly Sales
          </Text>
        </Box>
        <HStack flex={1} h="full" rounded="lg" overflow="hidden" onLayout={handleChangeLayout}>
          {size.width &&
            range(12).map(index => {
              return (
                <Box
                  key={`hourly-sales.${index}`}
                  flex={1}
                  maxWidth={index === 0 || index === 11 ? size.width / 12 / 2 : undefined}
                  alignItems={index === 0 ? 'flex-start' : index === 11 ? 'flex-end' : 'center'}
                  justifyContent="center"
                  h="full"
                >
                  <NumberText
                    size={{ md: 'sm', xl: 'md' }}
                    fontWeight={700}
                    color="gray.700"
                    prefix="$"
                    value={[0, 100, 250, 400, 550, 700, 850, 1000, 1150, 1300, 1450, 1600][index]}
                  />
                </Box>
              );
            })}
        </HStack>
      </HStack>
    </View>
  );
};
