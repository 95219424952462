import { ComponentProps, PropsWithChildren } from 'react';
import { Box, HStack, IconButton, Popover, VStack, useTheme } from 'native-base';
import Feather from '@expo/vector-icons/Feather';
import { useBoolean } from 'usehooks-ts';

import { InfoCircleIcon, LineChartIcon, Modal, Text } from '@pimm/base';
import { DoorEventIcon, Thermometer02Icon } from '../icons';
import { SensorEquipment } from '../context';
import { SensorInfo } from './sensor-info';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { EnableDisableSensor, SensorSettingConfirmation } from './sensor-setting-confirmation';

type SensorCardProps = PropsWithChildren & {
  equipment: SensorEquipment;
  isDoor?: boolean;
  isPerformance?: boolean;
  deviceType?: string;
  onPressInfo?: () => void;
  onOpenModal?: () => void;
};

export const SensorCard = ({ children, equipment, isDoor, isPerformance, deviceType, ...props }: SensorCardProps) => {
  const { colors } = useTheme();
  const popoverIsOpen = useBoolean();
  const modalConfirm = useModalFocus<EnableDisableSensor>();

  const handlePopoverClose = () => {
    popoverIsOpen.setFalse();
  };

  const hasDoor = !!equipment.door;
  const hardware = equipment.hardwares;

  return (
    <Box
      rounded="xl"
      pt={{ base: 0, xl: 1 }}
      pb={{ base: 2.5, xl: 4 }}
      bg="white"
      style={{
        shadowColor: colors.gray[500],
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.8,
        shadowRadius: 2,
      }}
    >
      <HStack space={5} pl={{ base: 2, xl: 3 }} pr={{ base: 2, xl: 4 }} alignItems="center" justifyContent="space-between" minH="50px">
        <HStack space={1.5} flex={1} alignItems="center">
          {isDoor ? <DoorEventIcon size="md" color="black" /> : <Thermometer02Icon size="md" color="black" />}
          <Text size="lg" fontWeight={700} color="black" lineHeight="xs" numberOfLines={2}>
            {isDoor ? equipment.door?.Label : equipment.temperature?.Label ?? '--'}
          </Text>
        </HStack>

        <HStack space={2} alignItems="center">
          <Popover
            isKeyboardDismissable
            isOpen={popoverIsOpen.value}
            onClose={handlePopoverClose}
            onOpen={popoverIsOpen.setTrue}
            trigger={triggerProps => (
              <IconButton
                rounded="full"
                p={1}
                size="md"
                icon={<InfoCircleIcon color="primary.600" />}
                onPress={props.onPressInfo}
                {...triggerProps}
              />
            )}
          >
            <Popover.Content accessibilityLabel="Sensor Info" borderWidth={0} shadow="5" borderRadius={15}>
              <Popover.Arrow zIndex={100} borderWidth={0} bgColor="gray.50" borderColor="transparent" />
              <Popover.Body py={3} w={260 + (hasDoor ? 260 : 0) + (hardware?.external ? 260 : 0)} bgColor="gray.50" justifyContent="center">
                <VStack justifyContent="center" w="full">
                  <Box px={0.5} h={8}>
                    <Text size="xl" fontWeight={700} color="black" lineHeight="xs">
                      Sensor Information
                    </Text>
                  </Box>

                  <Box position="relative" alignItems="center">
                    <HStack space={3} w="full">
                      <Box flex={1}>
                        <SensorInfo
                          deviceType={deviceType}
                          equipmentId={equipment?.equipment?.EquipmentId}
                          instance={equipment?.equipment?.TemperatureMetrics?.Temperature}
                          sensor={hardware?.temperature}
                          sensorName={equipment?.temperature?.Label}
                          siteId={equipment.siteID}
                          onConfirmDisable={modalConfirm.setOpen}
                        />
                      </Box>
                      {hasDoor && (
                        <Box flex={1}>
                          <SensorInfo
                            sensor={hardware?.door}
                            isDoor
                            deviceType={deviceType}
                            equipmentId={equipment?.equipment?.EquipmentId}
                            instance={equipment?.equipment?.DoorMetrics?.Door}
                            sensorName={equipment?.door?.Label}
                            siteId={equipment.siteID}
                            onConfirmDisable={modalConfirm.setOpen}
                          />
                        </Box>
                      )}
                      {hardware?.external && (
                        <Box flex={1}>
                          <SensorInfo isExternal sensor={hardware?.external} deviceType={deviceType} />
                        </Box>
                      )}
                    </HStack>

                    {(hasDoor || hardware?.external) && (
                      <Box position="absolute" top={10} w={10} h={5} borderRadius={20} bgColor="gray.50">
                        <Box flex={1} m="2px" borderRadius={20} bgColor="gray.900" alignItems="center" justifyContent="center">
                          <Feather name="link-2" size={12} color="white" />
                        </Box>
                      </Box>
                    )}
                  </Box>
                </VStack>
              </Popover.Body>
            </Popover.Content>
          </Popover>

          <IconButton
            boxSize={7}
            rounded="md"
            p={1.5}
            borderWidth={1}
            borderColor="gray.300"
            _hover={{ borderColor: 'primary.600', _icon: { color: colors.primary[600] } }}
            _pressed={{ borderColor: 'primary.600', _icon: { color: colors.primary[600] } }}
            icon={<LineChartIcon size="sm" color={colors.gray[700]} />}
            onPress={props.onOpenModal}
          />
        </HStack>
      </HStack>

      {children}

      <Modal size="md" _content={{ rounded: 'xl', maxWidth: 320, overflow: 'hidden' }} isOpen={modalConfirm.isOpen} noPadding hideClose>
        <SensorSettingConfirmation
          enableDisableSensor={modalConfirm.payload}
          sensorSettingType="Sensor"
          siteId={equipment.siteID}
          onClose={modalConfirm.setHide}
        />
      </Modal>
    </Box>
  );
};
