import { Icon, IIconProps } from 'native-base';
import { Path } from 'react-native-svg';

export const GiftIcon = ({
  color = '#065986',
  size = 'sm',
  ...props
}: IIconProps) => {
  return (
    <Icon
      fill="none"
      size={size}
      strokeWidth="0"
      viewBox="0 0 16 16"
      {...props}
    >
      <Path
        d="M8.14288 4.00004V14.6667M8.14288 4.00004H5.78574C5.43843 4.00004 5.10535 3.85957 4.85977 3.60952C4.61418 3.35947 4.47622 3.02033 4.47622 2.66671C4.47622 2.31309 4.61418 1.97395 4.85977 1.7239C5.10535 1.47385 5.43843 1.33337 5.78574 1.33337C7.61907 1.33337 8.14288 4.00004 8.14288 4.00004ZM8.14288 4.00004H10.5C10.8473 4.00004 11.1804 3.85957 11.426 3.60952C11.6716 3.35947 11.8095 3.02033 11.8095 2.66671C11.8095 2.31309 11.6716 1.97395 11.426 1.7239C11.1804 1.47385 10.8473 1.33337 10.5 1.33337C8.66669 1.33337 8.14288 4.00004 8.14288 4.00004ZM13.4762 7.33337V12.5334C13.4762 13.2801 13.4762 13.6535 13.3309 13.9387C13.2031 14.1896 12.9991 14.3936 12.7482 14.5214C12.463 14.6667 12.0896 14.6667 11.3429 14.6667L4.94288 14.6667C4.19615 14.6667 3.82278 14.6667 3.53756 14.5214C3.28668 14.3936 3.0827 14.1896 2.95487 13.9387C2.80955 13.6535 2.80955 13.2801 2.80955 12.5334V7.33337M1.47622 5.06671L1.47622 6.26671C1.47622 6.64008 1.47622 6.82676 1.54888 6.96937C1.61279 7.09481 1.71478 7.1968 1.84022 7.26071C1.98283 7.33337 2.16951 7.33337 2.54288 7.33337L13.7429 7.33337C14.1163 7.33337 14.3029 7.33337 14.4455 7.26071C14.571 7.1968 14.673 7.09481 14.7369 6.96937C14.8095 6.82676 14.8095 6.64008 14.8095 6.26671V5.06671C14.8095 4.69334 14.8095 4.50666 14.7369 4.36405C14.673 4.23861 14.571 4.13662 14.4455 4.0727C14.3029 4.00004 14.1163 4.00004 13.7429 4.00004L2.54288 4.00004C2.16951 4.00004 1.98283 4.00004 1.84022 4.0727C1.71478 4.13662 1.61279 4.23861 1.54888 4.36405C1.47622 4.50665 1.47622 4.69334 1.47622 5.06671Z"
        stroke={color as string}
        fill="none"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
