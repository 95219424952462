import { HStack, VStack } from 'native-base';

import { Button, Text } from '@pimm/base';

type ConfirmIncompleteTaskProps = {
  onClose?: () => void;
  onConfirm?: () => void;
};

export const ConfirmIncompleteTask = (props: ConfirmIncompleteTaskProps) => {
  return (
    <VStack space={4}>
      <Text color="black" fontWeight={600} size="xl" lineHeight="sm">
        Change Status
      </Text>
      <Text color="gray.900" fontWeight={400} size="md" lineHeight="sm">
        Do you want to change the status to incomplete? This change cannot be reversed!
      </Text>

      <HStack space={2}>
        <Button flex={1} borderWidth={1} h={9} alternate outline onPress={props.onClose}>
          Cancel
        </Button>
        <Button flex={1} h={9} borderWidth={1} onPress={props.onConfirm}>
          Confirm
        </Button>
      </HStack>
    </VStack>
  );
};
