import { Modal } from '@pimm/base';
import { useAppSelector } from '@app/store/store';
import { selectCokeFreestyle, selectStoreId } from '@app/store/selectors';
import { CokeFreestyle, useGetSiteDispensers } from '@app/features/coke-freestyle';
import { DashboardModalType, useDashboard } from '@app/features/dashboard';

export const ModalCokeFreestyle = () => {
  const cokeFreeStyle = useAppSelector(state => selectCokeFreestyle(state));
  const siteId = useAppSelector(state => selectStoreId(state));
  const { modal, toggleModal } = useDashboard();

  const siteDispensers = useGetSiteDispensers(cokeFreeStyle?.enabled ? siteId : undefined);

  if (!cokeFreeStyle?.enabled) return null;

  return (
    <Modal
      _content={{ minHeight: 700, maxWidth: { md: '98%', xl: '95%' }, maxHeight: '95%' }}
      size="full"
      noPadding
      isOpen={modal?.type === DashboardModalType.CokeFreestyle}
      onClose={toggleModal}
    >
      <CokeFreestyle dispenserId={modal?.payload?.dispenserId} siteDispenser={siteDispensers} />
    </Modal>
  );
};
