import { Icon, IIconProps } from 'native-base';
import { Path } from 'react-native-svg';

export const PlusSquareIcon = ({ size = 'sm', ...props }: IIconProps) => {
  return (
    <Icon size={size} viewBox="0 0 25 26" fill="none" {...props}>
      <Path
        d="M12.5 8.83333V17.1667M8.33333 13H16.6667M8.125 22.375H16.875C18.6252 22.375 19.5002 22.375 20.1687 22.0344C20.7567 21.7348 21.2348 21.2567 21.5344 20.6687C21.875 20.0002 21.875 19.1252 21.875 17.375V8.625C21.875 6.87484 21.875 5.99975 21.5344 5.33128C21.2348 4.74327 20.7567 4.26521 20.1687 3.9656C19.5002 3.625 18.6252 3.625 16.875 3.625H8.125C6.37484 3.625 5.49975 3.625 4.83128 3.9656C4.24327 4.26521 3.76521 4.74327 3.4656 5.33128C3.125 5.99975 3.125 6.87484 3.125 8.625V17.375C3.125 19.1252 3.125 20.0002 3.4656 20.6687C3.76521 21.2567 4.24327 21.7348 4.83128 22.0344C5.49975 22.375 6.37484 22.375 8.125 22.375Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Icon>
  );
};