import { Dispatch, SetStateAction, useState } from 'react';
import { QueryKey, UseQueryResult, useQuery } from 'react-query';

import { GetDailyPositionGoals } from '@pimm/services/lib/sms-workforce/services';
import { formatToISOString } from '@app/utils/date-formatter';

type GetDailyPositionGoalsQueryParams = {
  isAllDay?: boolean;
  siteId?: string;
  startDate?: Date;
  endDate?: Date;
};

type GetDailyPositionGoalsReturn = UseQueryResult<Awaited<ReturnType<typeof GetDailyPositionGoals>>> & {
  queryKey: [string, GetDailyPositionGoalsQueryParams];
  queryParams: GetDailyPositionGoalsQueryParams;
};

export const useGetDailyPositionGoals = (props?: {
  queryKey?: string;
  query?: GetDailyPositionGoalsQueryParams;
}): [GetDailyPositionGoalsReturn, Dispatch<SetStateAction<GetDailyPositionGoalsQueryParams>>] => {
  const [queryKey] = useState<string>(props?.queryKey ?? 'GetDailyPositionGoals');
  const [query, setQuery] = useState<GetDailyPositionGoalsQueryParams>({ ...props?.query });

  const dailyPositionalGoals = useQuery({
    enabled: !!query.siteId && !!query.startDate,
    queryKey: [queryKey, query] as [QueryKey, GetDailyPositionGoalsQueryParams],
    queryFn: ({ queryKey }) => {
      return GetDailyPositionGoals({
        ...queryKey[1],
        startDate: formatToISOString(queryKey[1].startDate!),
        endDate: queryKey[1].endDate ? formatToISOString(queryKey[1].endDate) : undefined,
      });
    },
  });

  return [{ ...dailyPositionalGoals, queryKey: [queryKey, query], queryParams: query }, setQuery];
};
