import { QueryKey, UseQueryResult, useQuery } from 'react-query';

import { GetEventTypes } from '@pimm/services/lib/threshold-profiles/services';

export const useGetEventTypes = (brandId?: string, queryKey?: string): UseQueryResult<Awaited<ReturnType<typeof GetEventTypes>>> => {
  const eventTypes = useQuery({
    staleTime: 60 * 60 * 1000, // 1 hour
    enabled: !!brandId,
    queryKey: [queryKey ?? 'GetEventTypes', brandId] as [QueryKey, string],
    queryFn: ({ queryKey: [key, brandId] }) => GetEventTypes(brandId),
  });

  return eventTypes;
};
