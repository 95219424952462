import { useEffect } from 'react';
import { useMediaQuery, useSafeArea, useTheme } from 'native-base';
import Modal from 'react-native-modal';

import { useAppDispatch, useAppSelector } from '@app/store/store';
import { appConfigSlice } from '@app/store/slices/appConfigSlice';
import { useDashboard } from '@app/features/dashboard';
import { WidgetContainer } from '@app/components/core';

export const ModalWidgets = () => {
  const { colors } = useTheme();
  const [isLargeScreen] = useMediaQuery({ maxWidth: 1400 });
  const safeAreaProps = useSafeArea({ safeAreaTop: true });
  const dispatch = useAppDispatch();
  const appConfig = useAppSelector(state => state.appConfig);
  const { modal } = useDashboard();

  const handlePressClose = () => {
    dispatch(appConfigSlice.actions.toggleWidget(false));
  };

  useEffect(() => {
    if (modal?.type && appConfig.widgetIsOpen) {
      // Let's force hide widgets if one of the dashboard modal is opened
      // Adding delay will improve sliding effect
      setTimeout(() => {
        dispatch(appConfigSlice.actions.toggleWidget(false));
      }, 300);
    }
  }, [modal]);

  if (!isLargeScreen) return null;
  return (
    <Modal
      animationIn="slideInRight"
      animationOut="slideOutRight"
      animationInTiming={500}
      animationOutTiming={1000}
      backdropOpacity={0.3}
      coverScreen
      isVisible={appConfig.widgetIsOpen}
      style={{ margin: 0 }}
      onBackdropPress={handlePressClose}
      onBackButtonPress={handlePressClose}
    >
      <WidgetContainer
        position="absolute"
        right={0}
        h="full"
        w={300}
        style={{
          shadowColor: colors.gray[700],
          shadowOffset: { width: -3, height: 0 },
          shadowOpacity: 0.2,
          shadowRadius: 4,
        }}
        {...safeAreaProps}
      />
    </Modal>
  );
};
