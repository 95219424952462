import { createContext, PropsWithChildren, useContext } from 'react';

import { useGetEventTypes } from '../hooks';

export const EventTypesContext = createContext<ReturnType<typeof useGetEventTypes>>(undefined!);

export type EventTypesProviderProps = PropsWithChildren & {
  eventTypes: ReturnType<typeof useGetEventTypes>;
};

export const EventTypesProvider = ({ children, eventTypes }: EventTypesProviderProps) => {
  return <EventTypesContext.Provider value={eventTypes}>{children}</EventTypesContext.Provider>;
};

export const useEventTypes = () => {
  // get the context
  const context = useContext(EventTypesContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useEventTypes was used outside of its Provider');
  }
  return context;
};
