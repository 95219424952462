// This context is responsible to pass down the kitchen positioning config at every level
// State: 
//  confirmed: to identify if the current positioning schedules are already confirmed
//  editable: If true, enable Drag and Drop, position assignment, select position or secondary jobs

import React, { createContext, useContext, useEffect, useMemo } from 'react';

export type KitchenPositioningConfigContextReturn = {
  isConfirmed?: boolean;
  isEditEnabled?: boolean;
};

export const KitchenPositioningConfigContext = createContext<KitchenPositioningConfigContextReturn>(undefined!);

export type KitchenPositioningConfigProviderProps = KitchenPositioningConfigContextReturn & {
  children: React.ReactNode;
};


export const KitchenPositioningConfigProvider = ({ children, ...props }: KitchenPositioningConfigProviderProps) => {
  return <KitchenPositioningConfigContext.Provider value={props}>{children}</KitchenPositioningConfigContext.Provider>;
};

export const KitchenPositioningConfigConsumer = KitchenPositioningConfigContext.Consumer;

export const useKitchenPositioningConfig = () => {
  // Get the context
  const context = useContext(KitchenPositioningConfigContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useKitchenPositioningConfig was used outside of its Provider');
  }
  return context;
};
