import { QueryKey, UseQueryResult, useQuery } from 'react-query';
import moment from 'moment';

import { GetWeeklyData } from '@pimm/services/lib/sms-drivethru/services';

type GetWeeklyDataParams = {
  currentDateTime: Date;
  siteId?: string;
};
export const useGetWeeklyData = (
  query?: GetWeeklyDataParams,
  queryKey?: string,
): UseQueryResult<Awaited<ReturnType<typeof GetWeeklyData>>> => {
  const weeklyData = useQuery({
    cacheTime: 0,
    enabled: !!query?.siteId && !!query.currentDateTime,
    queryKey: [queryKey ?? 'GetWeeklyData', query] as [QueryKey, GetWeeklyDataParams],
    queryFn: ({ queryKey: [key, params] }) =>
      GetWeeklyData({ currentDateTime: moment(query?.currentDateTime).format().slice(0, 19), siteId: params.siteId }),
  });

  return weeklyData;
};
