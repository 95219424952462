import { Box, Pressable, useTheme } from 'native-base';
import { Card } from '@pimm/base';

type CollapsibleTileProps = {
  _container?: React.ComponentProps<typeof Card>;
  children: React.ReactNode;
  isCollapsed?: boolean;
  onPress?: () => void;
};

export const CollapsibleTile = ({ children, _container, isCollapsed, ...props }: CollapsibleTileProps) => {
  const { colors } = useTheme();
  return (
    <Pressable rounded="xl" onPress={props.onPress}>
      <Box
        rounded="xl"
        p={1.5}
        shadow={0}
        bg="white"
        {..._container}
        style={{
          shadowColor: colors.gray[500],
          shadowOffset: { width: 0, height: 1 },
          shadowOpacity: 0.8,
          shadowRadius: 3,
        }}
      >
        {children}
      </Box>
    </Pressable>
  );
};
