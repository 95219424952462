import React, { createContext, useContext, useEffect } from 'react';

import { Daypart } from '@pimm/common';
import { useGetEmployeeSchedules } from '../hooks/get-employee-schedules.hook';

export interface ScheduleAssignmentContextReturn {
  daypartFocus?: Daypart;
  schedules: ReturnType<typeof useGetEmployeeSchedules>[0];
}

export const ScheduleAssignmentContext = createContext<ScheduleAssignmentContextReturn>(undefined!);

export type ScheduleAssignmentProviderProps = {
  children: React.ReactNode;
  daypart: Daypart;
  siteId?: string;
};

export const ScheduleAssignmentProvider = ({ children, daypart, siteId }: ScheduleAssignmentProviderProps) => {
  const [schedules, setScheduleParams] = useGetEmployeeSchedules();

  useEffect(() => {
    if (daypart?.startTime && siteId) {
      setScheduleParams({
        startDate: daypart?.startTime,
        siteId: siteId,
        showAll: true
      });
    }
  }, [daypart, siteId]);

  return <ScheduleAssignmentContext.Provider value={{ daypartFocus: daypart, schedules }}>{children}</ScheduleAssignmentContext.Provider>;
};

export const ScheduleAssignmentConsumer = ScheduleAssignmentContext.Consumer;

export const useScheduleAssignment = () => useContext(ScheduleAssignmentContext);
