import React, { createContext, useCallback, useContext, useReducer } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { PaletteOutput, createPaletteFromColor } from 'palettey';
import { concat, find, isEqual, take, values } from 'lodash';

import { IThemeColors } from '@pimm/base';
import { AppConfigDto, BrandDto } from '@pimm/services/lib/sms-tenants';

enum AppThemeActions {
  UPDATE = 'UPDATE',
}

type AppTheme =
  | {
      brand?: BrandDto;
      colors?: Partial<IThemeColors>;
    }
  | undefined;

// Define the action types
type Action = { type: AppThemeActions.UPDATE; payload?: AppTheme };

type AppThemeProviderProps = {
  children: React.ReactNode;
};

type AppThemeContextReturn = AppTheme & {
  setBrand: (brand?: BrandDto) => void;
};

// Create the reducer
const AppThemeReducer = (state: AppTheme, action: Action): AppTheme => {
  switch (action.type) {
    case AppThemeActions.UPDATE:
      return action.payload;

    default:
      return state;
  }
};

const AppThemeContext = createContext<AppThemeContextReturn>(undefined!);

export const AppThemeProvider = ({ children }: AppThemeProviderProps) => {
  const [initialTheme, setTheme] = useLocalStorage<AppTheme>('theme', undefined);
  const [theme, dispatch] = useReducer(AppThemeReducer, initialTheme);

  // This is responsible for generating color palette base on the given BrandDto
  const setBrand = useCallback(
    (brand?: BrandDto) => {
      if (brand) {
        const { primaryColor } = brand;
        let customColors: Partial<IThemeColors> = {};

        if (isEqual(initialTheme?.brand, brand)) return;

        if (primaryColor) {
          // This will return shades from 100 - 900
          const { dark }: PaletteOutput = createPaletteFromColor('dark', primaryColor, { useLightness: true, lMax: 90 });
          // This will return shades from 25 - 50
          const { light }: PaletteOutput = createPaletteFromColor('light', primaryColor, { useLightness: true, lMax: 100 });
          // Pick 25 - 50 and combine the dark shades
          const colors = concat(take(values(light), 2), values(dark));

          customColors.primary = {
            25: light[0],
            50: colors[1],
            100: colors[2],
            200: colors[3],
            300: colors[4],
            400: colors[5],
            500: colors[6],
            600: colors[7],
            700: colors[8],
            800: colors[8],
            900: colors[10],
          };
        }

        const payload = { brand: brand, colors: customColors };
        // Update reducer with new state
        dispatch({ type: AppThemeActions.UPDATE, payload: payload });
        // Cache last record brand
        setTheme(payload);
      }
    },
    [initialTheme],
  );

  return (
    <AppThemeContext.Provider
      value={{
        ...theme,
        setBrand: setBrand,
      }}
    >
      {children}
    </AppThemeContext.Provider>
  );
};

export const AppThemeConsumer = AppThemeContext.Consumer;

export const useAppTheme = () => {
  // get the context
  const context = useContext(AppThemeContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useAppTheme was used outside of its Provider');
  }
  return context;
};
