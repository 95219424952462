import { Box, CloseIcon, Divider, HStack, IconButton, Spacer, VStack } from 'native-base';
import { isEmpty, sortBy } from 'lodash';

import { Text } from '@pimm/base';
import { DpTimerDataDto, GoalProfileDto } from '@pimm/services/lib/sms-drivethru/types';
import { TimeBreakdown } from './time-breakdown';
import { CarPerformance } from './car-performance';

type DetailedLanelInfoProps = {
  onClose?: () => void;
  profiles: GoalProfileDto[];
  timerData?: DpTimerDataDto;
};

export const DetailedLanelInfo = ({ profiles, timerData, ...props }: DetailedLanelInfoProps) => {
  const lanes = timerData?.details?.filter(_ => _.laneNumber);
  return (
    <VStack h="full" w="full">
      <HStack alignItems="center" pl={4} pr={2} h="56px" borderBottomWidth={1}>
        <Text size="xl" fontWeight={700} color="gray.900">
          Y-Lane Time Breakdown
        </Text>

        <Spacer />

        {!!props.onClose && (
          <IconButton
            p={2}
            _pressed={{ bg: 'gray.100' }}
            _hover={{ bg: 'gray.100' }}
            icon={<CloseIcon size="14px" color="gray.700" />}
            onPress={props.onClose}
          />
        )}
      </HStack>

      <HStack space={2} py={3} px={4} bg="gray.25">
        {!isEmpty(lanes) &&
          sortBy(lanes, ['laneNumber']).map(lane => {
            return (
              <Box rounded="xl" flex={1} borderWidth={1} bg="white">
                <TimeBreakdown
                  bordered
                  _container={{ py: 3, px: 4 }}
                  title={`Lane ${lane?.laneNumber} Time`}
                  goal={timerData?.siteDaypartGoal}
                  timerDetails={lane}
                />
                <Divider bg="gray.200" />
                <CarPerformance _container={{ py: 3, px: 4 }} goal={timerData?.siteDaypartGoal} profiles={profiles} timerDetails={lane} />
              </Box>
            );
          })}
      </HStack>
    </VStack>
  );
};
