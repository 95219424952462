import { useWatch } from 'react-hook-form';
import { Box, Center, HStack, Pressable, VStack } from 'native-base';

import { FormForgotPassword, FormResetPasswordStep, useFormResetPassword } from '@pimm/common';
import { RootScreenProps } from '@app/navigations/root';
import { Wrapper } from '@app/features/app';
import { BrandLogo } from './components/brand-logo';
import { Text } from '@pimm/base';
import { useMutation } from 'react-query';
import { ForgotPassword } from '@pimm/services/lib/sms-tenants/services';

export type RecoverPasswordScreenProps = RootScreenProps<'ResetPassword'>;

export const RecoverPasswordScreen = ({ navigation }: RecoverPasswordScreenProps) => {
  const form = useFormResetPassword();
  const step = useWatch({ control: form.control, name: 'step' });

  const isVerifiedUsername = step === FormResetPasswordStep.ChangePassword;

  const forgotPassword = useMutation({
    mutationFn: ForgotPassword,
    onSuccess: () => {
      form.setValue('step', FormResetPasswordStep.ChangePassword);
    },
    onError: (e: { data: { resultCode: string } }) => {
      const errMessage =
        {
          USER_NOT_FOUND: 'Sorry, but this username does not exist',
          NO_RECOVERY_EMAIL: 'No recovery email has been set yet',
        }[e.data.resultCode] ?? 'Sorry, something went wrong there. Please try again';

      form.setError('username', { type: 'custom', message: errMessage });
    },
  });

  const handleSendVerification = () => {
    forgotPassword.mutate({
      userName: form.getValues().username,
    });
  };

  const handlePressBack = () => {
    navigation.replace('Login');
  };

  return (
    <Wrapper>
      <VStack flex={1} alignItems="center" justifyContent="center" w="full" maxH={780}>
        <VStack space={5} w="full" maxW={420}>
          <Box rounded="2xl" bg="white" px={10} pt={7} pb={4} shadow={3}>
            <Center>
              <BrandLogo />
            </Center>

            {/* Forgot Password Form */}
            <Box justifyContent="center" h={180}>
              <FormForgotPassword form={form} isLoading={forgotPassword.isLoading} onSubmit={handleSendVerification} />

              {isVerifiedUsername && (
                <VStack pt={3}>
                  <Text size="md" fontWeight={500} color="success.700" lineHeight="md">
                    A password reset message was sent to your recovery email address. Please click the link in that message to reset your
                    password.
                  </Text>
                </VStack>
              )}
            </Box>

            <HStack justifyContent="center" textAlign="center" mt={4} mb={1}>
              <Pressable onPress={handlePressBack}>
                <Text size="lg" color="black" fontWeight={600}>
                  {'\u2190'} Back to Login
                </Text>
              </Pressable>
            </HStack>
          </Box>
        </VStack>
      </VStack>
    </Wrapper>
  );
};
