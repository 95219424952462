import { Box, HStack, VStack, useMediaQuery, useTheme } from 'native-base';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';

import { CurrencyText, Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';

type SalesBoxProps = {
  highlight?: boolean;
  forecastAmount?: number;
  salesAmount?: number;
  title: string;
};

export const SalesBox = ({ highlight = false, forecastAmount = 0, salesAmount = 0, title }: SalesBoxProps) => {
  const { colors } = useTheme();
  const [isSmallScreen] = useMediaQuery({ maxWidth: 1200 });
  let increase = ((salesAmount - forecastAmount) / forecastAmount) * 100;

  if (isNaN(increase)) increase = 0;
  if (increase === Infinity) increase = 100;

  return (
    <VStack flex={1} py={2} px={{ md: 2, xl: 4 }} rounded="lg" borderWidth={1} backgroundColor={highlight ? 'orangeDark.50' : undefined}>
      <HStack alignItems="center" justifyContent="space-between">
        <Box flex={1}>
          <Text size="md" fontWeight={600} color="gray.800">
            {title}
          </Text>
        </Box>
        {increase !== 0 && (
          <HStack
            space={{ xl: 1 }}
            alignItems="center"
            rounded="lg"
            py={{ xl: 0.5 }}
            pl={{ md: 0.5, xl: 1.5 }}
            pr={{ md: 0.5, xl: 2 }}
            backgroundColor={increase > 0 ? 'success.50' : 'error.50'}
          >
            <MaterialIcons
              name={increase > 0 ? 'arrow-upward' : 'arrow-downward'}
              size={isSmallScreen ? 12 : 14}
              color={increase > 0 ? colors.success[500] : colors.error[500]}
            />
            <Text size={{ md: 'xs', xl: 'md' }} fontWeight={600} color={increase > 0 ? 'success.700' : 'error.700'}>
              {`${increase.toFixed(2).replace('.00', '')}%`}
            </Text>
          </HStack>
        )}
      </HStack>
      <HStack>
        <Text size={{ md: 'md', xl: 'xl' }} fontWeight={700} color="gray.900">
          <CurrencyText size={{ md: 'md', xl: 'xl' }} fontWeight={700} color="gray.900" value={salesAmount} /> /{' '}
          <CurrencyText size={{ md: 'sm', xl: 'lg' }} fontWeight={600} color="gray.700" value={forecastAmount} />
        </Text>
      </HStack>
    </VStack>
  );
};
