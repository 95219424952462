import { useEffect, useState } from 'react';
import { Box, HStack } from 'native-base';
import { isUndefined } from 'lodash';

import { Text } from '@pimm/base';
import { formatTo } from '@app/utils/date-formatter';
import { DayBlock, useSiteTime } from '@app/features/store-core';

export const LiveDayblock = () => {
  const siteTime = useSiteTime();
  const [dayBlock, setDayBlock] = useState<DayBlock>();

  useEffect(() => {
    if (!isUndefined(siteTime.utcOffsetInMinutes)) {
      setDayBlock(siteTime.toDayBlockNow());
    }
  }, [siteTime.utcOffsetInMinutes]);

  useEffect(() => {
    let interval;

    if (dayBlock) {
      const dayBlock = siteTime.toDayBlockNow();

      if (dayBlock?.endTime) {
        const millis = dayBlock.endTime.getTime() - siteTime.today().getTime();
        // Set up an interval to update the dayblock after the current dayblock ends
        if (millis > 0) {
          // Only set up the interval if the dayblock hasn't ended yet
          interval = setInterval(() => {
            clearInterval(interval);
            setDayBlock(siteTime.toDayBlockNow());
          }, millis);
        }
      }
    }
    return () => clearInterval(interval);
  }, [dayBlock]);

  return (
    <Box>
      {!!dayBlock && (
        <HStack rounded="lg" alignItems="center" justifyContent="center" space="8" px={3} h="46px" bg="gray.800">
          <Text size="md" fontWeight={700} color="white" lineHeight="xs" textTransform="uppercase">
            {dayBlock.title}
            {'\n'}
            <Text size="sm" fontWeight={500} color="white" lineHeight="xs" textTransform="uppercase">
              {dayBlock.subTitle}
            </Text>
          </Text>
          <Box rounded="sm" justifyContent="center" p={1.5} h={5} bg="white">
            <Text size="sm" fontWeight={600} color="black" lineHeight="xs" textAlign="center">
              {`${formatTo(dayBlock.startTime, 'h:mm A')} - ${formatTo(dayBlock.endTime, 'h:mm A')}`}
            </Text>
          </Box>
        </HStack>
      )}
    </Box>
  );
};
