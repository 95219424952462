import { map } from 'lodash';
import { OpsTaskTrackerDto, ShiftDto, StringTranslation } from '@pimm/services/lib/sms-workforce';
import { OpsPhasePositionAssignee } from '../types';

export interface OpsPhasePositionAssignment {
  id: string;
  assignee?: OpsPhasePositionAssignee;
  startTime?: Date;
  endTime?: Date;
  color?: string;
  iconUrl?: string;
  title: string;
  translations?: StringTranslation[];
}

export interface OpsPhaseAssignment {
  id: number;
  eligableEmployees?: ShiftDto[];
  phase: string;
  startTime?: Date;
  endTime?: Date;
  salesVolumeProfileId?: number;
  positions: OpsPhasePositionAssignment[];
  translations?: StringTranslation[]
}

export interface OpsPhasePositioning {
  opsPhases: OpsPhaseAssignment[];
}

export enum OpsPhasePositioningAction {
  RESET = 'RESET',
  COMPLETE = 'COMPLETE',
  UPDATE = 'UPDATE',
}

// Define the action types
type Action =
  | { type: OpsPhasePositioningAction.RESET; payload?: OpsPhasePositioning }
  | { type: OpsPhasePositioningAction.COMPLETE; payload?: OpsTaskTrackerDto & { positionId: string } }
  | { type: OpsPhasePositioningAction.UPDATE; payload?: { opsPhaseId: number; position: Partial<OpsPhasePositionAssignment> } };

export const OpsPhasePositioningInitialState: OpsPhasePositioning = {
  opsPhases: [],
};

// Create the reducer
export const OpsPhasePositioningReducer = (state: OpsPhasePositioning, action: Action): OpsPhasePositioning => {
  switch (action.type) {
    case OpsPhasePositioningAction.RESET:
      return action.payload ?? OpsPhasePositioningInitialState;

    case OpsPhasePositioningAction.COMPLETE:
      return {
        ...state,
      };

    case OpsPhasePositioningAction.UPDATE:
      return {
        ...state,
        opsPhases: map(state.opsPhases, opsPhase => {
          if (opsPhase.id === action.payload?.opsPhaseId) {
            const assignee = action.payload?.position.assignee;
            return {
              ...opsPhase,
              positions: map(opsPhase.positions, position => ({
                ...position,
                assignee: position.id === action.payload?.position.id ? assignee : position.assignee,
              })),
            };
          }
          return opsPhase;
        }),
      };

    default:
      return state;
  }
};
