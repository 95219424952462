import { QueryKey, UseQueryResult, useQuery } from 'react-query';

import { GetInstances } from '@pimm/services/lib/store-equipment/services';

export const useGetInstances = (siteId?: string, queryKey?: string): UseQueryResult<Awaited<ReturnType<typeof GetInstances>>> => {
  const instances = useQuery({
    enabled: !!siteId,
    queryKey: [queryKey ?? 'GetInstances', siteId] as [QueryKey, string],
    queryFn: ({ queryKey }) => GetInstances(queryKey[1]),
  });

  return instances;
};
