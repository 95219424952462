import { Fragment } from 'react';
import { HStack, IBoxProps, Spacer, useTheme } from 'native-base';
import FontAwesomeIcons from 'react-native-vector-icons/FontAwesome';
import { get, keys, map } from 'lodash';
import { Text } from '@pimm/base';
import { TimelineLegend } from '../_helper';

export const TimeDividers = (props: IBoxProps) => {
  const { colors } = useTheme();

  return (
    <HStack alignItems="center" justifyContent="space-between" minH={6} {...props}>
      {map(keys(TimelineLegend), (key, index) => (
        <Fragment key={key}>
          {index > 0 && <Spacer />}
          <FontAwesomeIcons name="square" size={10} color={get(colors, TimelineLegend[key])} />
          <Text size="sm" fontWeight={500} color="gray.500" ml={1} lineHeight="xs">
            {key}
          </Text>
        </Fragment>
      ))}
    </HStack>
  );
};
