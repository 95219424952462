import { View } from 'native-base';

import { Modal } from '@pimm/base';
import { GoalCategoryDto } from '@pimm/services/lib/sms-positioning';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { DailyGoalsList, DailyGoalsListProps } from './daily-goals-list';
import { DailyGoalSelection } from './daily-goal-selection';
import { CopyDailyGoals } from './copy-daily-goals';

type DailyGoalsFormProps = Omit<DailyGoalsListProps, 'onPressCopy' | 'onPressSelect'> & {};

export const DailyGoalsForm = ({ categories, disabled, goalTypeIds }: DailyGoalsFormProps) => {
  const modalCopy = useModalFocus();
  const modalGoals = useModalFocus<GoalCategoryDto>();

  return (
    <View h="full">
      <DailyGoalsList
        categories={categories}
        disabled={disabled}
        onPressCopy={modalCopy.setOpen}
        onPressSelect={modalGoals.setOpen}
        goalTypeIds={goalTypeIds}
      />

      <Modal
        isOpen={modalGoals.isOpen}
        noPadding
        _content={{ maxWidth: { md: '98%', xl: '95%' }, height: '95%', maxHeight: 900 }}
        size="full"
        hideClose
      >
        {!!modalGoals.payload && (
          <DailyGoalSelection category={modalGoals.payload} onPressCancel={modalGoals.setHide} goalTypeIds={goalTypeIds} />
        )}
      </Modal>

      <Modal isOpen={modalCopy.isOpen} noPadding size="lg" _content={{ rounded: 'xl', maxWidth: 700 }} hideClose>
        <CopyDailyGoals onPressCancel={modalCopy.setHide} />
      </Modal>
    </View>
  );
};
