import { useMemo } from 'react';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { HStack, View } from 'native-base';

import { EquipmentInstancesDto } from '@pimm/services/lib/store-equipment';
import { useSiteConfig } from '@app/features/store-core';
import { EquipmentChartData, EquipmentList, useGetDisplayGroups } from '@app/features/store-equipment';

import { MainNavigationParams } from '@app/navigations/root';

type FilterEventType = 'all' | 'temperature' | 'signal' | 'door' | 'battery';

type StatusPanelProps = {
  displayGroups: ReturnType<typeof useGetDisplayGroups>;
  instanceId?: string;
  siteId?: string;
};

export const StatusPanel = ({ displayGroups, instanceId }: StatusPanelProps) => {
  const navigation = useNavigation<NavigationProp<MainNavigationParams, 'Temperatures'>>();
  const { siteConfig } = useSiteConfig();

  const equipmentFocus = useMemo(() => {
    let equipment: EquipmentInstancesDto | undefined;

    if (instanceId && displayGroups.data) {
      equipment = displayGroups.data?.reduce((_equipment: EquipmentInstancesDto | undefined, item) => {
        return _equipment ?? item.equipment.find(_ => _.TemperatureMetrics?.Temperature?.InstanceId === instanceId);
      }, undefined);
    }
    return equipment;
  }, [instanceId, displayGroups.data]);

  const handleChangeEquipment = (_equipment?: EquipmentInstancesDto) => {
    // Remember browsed history
    navigation.setParams({
      instanceId: _equipment?.TemperatureMetrics?.Temperature?.InstanceId,
      tabFocus: 'status',
    });
  };

  return (
    <HStack h="full" w="full">
      <View flex={1} h="full" maxWidth={!!instanceId ? 320 : undefined} borderRightWidth={!!instanceId ? 1 : 0}>
        <EquipmentList
          displayGroups={displayGroups}
          equipmentFocus={equipmentFocus}
          isListView={!!instanceId}
          onChangeEquipment={handleChangeEquipment}
        />
      </View>

      {!!siteConfig.id && !!equipmentFocus && (
        <View flex={1}>
          <EquipmentChartData key={instanceId} equipmentFocus={equipmentFocus} siteId={siteConfig.id} />
        </View>
      )}
    </HStack>
  );
};
