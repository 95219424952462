import { Box, HStack, Pressable, Spacer, useTheme } from 'native-base';

import { Car01Icon, ClockStopWatchIcon, Text } from '@pimm/base';
import { formatToShortTimeOnly } from '@app/utils/date-formatter';
import { abbrevThousands } from '@app/utils/number-formatter';
import { Daypart } from '@app/features/store-core';
import { SpeedBadge } from './speed-badge';

type DaypartWidgetProps = {
  daypart?: Daypart;
  isActive?: boolean;
  title: string;
  avgTotalTime?: number;
  numOfLanes?: number;
  totalCars?: number;
  totalLane?: number;
  onPress?: () => void;
};

export const DaypartWidget = ({
  daypart,
  isActive,
  avgTotalTime = 0,
  numOfLanes = 0,
  totalCars = 0,
  totalLane = 0,
  title,
  ...props
}: DaypartWidgetProps) => {
  const { colors } = useTheme();
  const timeOnly = [daypart?.startTime, daypart?.endTime]
    .filter(Date)
    .map(dt => formatToShortTimeOnly(dt))
    .join(' - ');

  return (
    <Pressable w="full" rounded="xl" onPress={props.onPress}>
      <Box
        rounded="xl"
        p={2}
        px={3}
        minH="80px"
        borderWidth="1"
        borderColor={isActive ? 'gray.700' : 'gray.300'}
        bg={isActive ? 'gray.700' : 'gray.25'}
        style={{
          shadowColor: colors.gray[500],
          shadowOffset: { width: 0, height: 1 },
          shadowOpacity: 0.6,
          shadowRadius: 1.5,
        }}
      >
        <HStack alignItems="center" justifyContent="space-between">
          <Box>
            <Text size="lg" fontWeight={700} color={isActive ? 'white' : 'black'} lineHeight="sm">
              {title}
            </Text>
            {!!daypart?.startTime && !!daypart?.endTime && (
              <Text size="sm" fontWeight={600} color={isActive ? 'white' : 'gray.800'} lineHeight="xs">
                {timeOnly}
              </Text>
            )}
          </Box>

          <HStack space={1} alignItems="center">
            <Car01Icon size="18px" color={isActive ? 'white' : colors.gray[700]} />
            <Text size="lg" fontWeight={700} color={isActive ? 'white' : 'black'} lineHeight="sm">
              {totalCars}
            </Text>
          </HStack>
        </HStack>

        <Spacer />

        <HStack alignItems="center">
          <HStack space={0.5} alignItems="center">
            <ClockStopWatchIcon size="18px" color={isActive ? 'white' : colors.gray[700]} />
            <Text size="lg" fontWeight={700} color={isActive ? 'white' : 'black'} lineHeight="sm">
              {avgTotalTime}s
            </Text>
          </HStack>

          {!!avgTotalTime && !!numOfLanes && <SpeedBadge ml={2} actual={avgTotalTime} goal={totalLane} />}

          {!!daypart && (
            <Text size="sm" fontWeight={600} color={isActive ? 'white' : 'gray.700'} ml={1}>
              {`vs goal (${abbrevThousands(totalLane)}s)`}
            </Text>
          )}
        </HStack>
      </Box>
    </Pressable>
  );
};
