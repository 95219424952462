import React, { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import { find, first, map, reduce, some } from 'lodash';

import { PositionAssigneeDto, PositionSlotDto, PositionTypeEnum, ShiftDto } from '@pimm/services/lib/sms-workforce';
import { DayBlock } from '@app/features/store-core';
import {
  PositioningEmployee,
  PositioningPlan,
  PositioningPlanAction,
  PositioningPlanReducer,
  PositioningSlot,
} from '../reducers/positioning-plan.reducer';
import { useGetPositionScheduleLive } from '../hooks';

export type PositioningPlanProviderProps = {
  children: React.ReactNode;
  dayBlock: DayBlock;
  liveSchedules: ReturnType<typeof useGetPositionScheduleLive>[0];
  subBlockTime?: string;
};

export type PositioningPlanContextReturn = {
  changes: PositioningEmployee[];
  positioning?: PositioningPlan;
  createPositionSlot: (payload: Partial<PositioningSlot>) => void;
  updatePositionSlot: (payload: Partial<PositioningSlot>, _payload?: Partial<PositioningSlot>) => void;
  deletePositionSlot: (payload: Partial<PositioningSlot>) => void;
  deleteAssignee: (payload: Partial<PositioningSlot>) => void;
  moveTo: (payload: Partial<PositioningEmployee | PositioningSlot>, isNonService?: boolean) => void;
  replaceAssignee: (payload: Partial<PositioningSlot>, employee: Partial<PositioningEmployee>) => void;
  resetChanges: () => void;
  refetchPlan: () => void;
};

const PositioningPlanContext = createContext<PositioningPlanContextReturn>(undefined!);

export const PositioningPlanProvider = ({ children, dayBlock, subBlockTime, liveSchedules }: PositioningPlanProviderProps) => {
  const [positioning, dispatch] = useReducer(PositioningPlanReducer, undefined);

  const changes = useMemo(() => {
    let unsaveChanges: PositioningEmployee[] = [];
    // Only check un-save changes if position plan is confirmed
    if (!!positioning?.id) {
      unsaveChanges = reduce(
        positioning?.employees,
        (employees: PositioningEmployee[], employee) => {
          const positionSlots = positioning?.positionSlots ?? [];
          let hasChange = false;

          if (employee.positionSlotId) {
            hasChange =
              // position slot changes
              positionSlots.some(_ => !_.assignee && employee.positionSlotId === _.id) ||
              // non-service changes
              !positionSlots.some(_ => _.id === employee.positionSlotId);
          } else {
            // move available employee into non-service
            hasChange = positionSlots.some(_ => !_.id && !!_.assignee && _.assignee.employeeId === employee.employeeId);
          }

          if (hasChange) return [...employees, employee];
          return employees;
        },
        [],
      );
    }
    return unsaveChanges;
  }, [positioning]);

  const handleCreatePositionSlot = useCallback(
    (payload: Partial<PositioningSlot>) => {
      dispatch({ type: PositioningPlanAction.CREATE, payload: payload });
    },
    [positioning],
  );

  const handleChangePositionSlot = useCallback(
    (payload: Partial<PositioningSlot>, _payload?: Partial<PositioningSlot>) => {
      // if _payload exist, do swap
      const actionType = !!_payload ? PositioningPlanAction.SWAP : PositioningPlanAction.UPDATE;
      dispatch({ type: actionType, payload: payload, _payload: _payload });
    },
    [positioning],
  );

  const handleDeletePositionSlot = useCallback(
    (payload: Partial<PositioningSlot>) => {
      dispatch({ type: PositioningPlanAction.DELETE, payload: payload });
    },
    [positioning],
  );

  // This event will allow the user to move from positionSlot, available or non-service
  const handleMoveTo = useCallback(
    (payload: Partial<PositioningEmployee | PositioningSlot>, isNonService?: boolean) => {
      // Check if the payload is "PositioningSlot"
      if ('assignee' in payload) {
        const positionSlot = payload as PositioningSlot;

        // Remove assignee from positionSlot
        dispatch({ type: PositioningPlanAction.UPDATE, payload: { id: positionSlot.id, assignee: undefined } });

        if (isNonService) {
          // Create a pending change non-service position slot
          dispatch({
            type: PositioningPlanAction.CREATE,
            payload: {
              assignee: payload.assignee,
              positionType: PositionTypeEnum.NonService,
              title: PositionTypeEnum.NonService,
            },
          });
        }
      }
      // move employee back to its last known position
      else if (payload.positionJobId) {
        const assignee = payload as PositioningEmployee;
        const positionSlot = find<PositioningSlot>(
          positioning?.positionSlots,
          _ => _.positionJobId === assignee.positionJobId && (!isNonService || _.positionType === PositionTypeEnum.NonService),
        );

        if (positionSlot) {
          dispatch({ type: PositioningPlanAction.UPDATE, payload: { ...positionSlot, assignee: assignee } });
        } else {
          dispatch({
            type: PositioningPlanAction.CREATE,
            payload: {
              id: assignee.positionSlotId,
              assignee: assignee,
              positionId: assignee.positionJobId,
              positionType: PositionTypeEnum.NonService,
              title: PositionTypeEnum.NonService,
            },
          });
        }
      }
    },
    [positioning],
  );

  const handleResetChanges = useCallback(() => {
    changes.forEach(employee => handleMoveTo(employee));
    // Clear all un-save non-services
    positioning?.positionSlots?.forEach(slot => {
      if (!slot.id && slot.positionType === PositionTypeEnum.NonService) {
        dispatch({ type: PositioningPlanAction.DELETE, payload: slot });
      }
    });
  }, [changes, positioning]);

  const handleDeleteAssignee = useCallback(
    (payload: Partial<PositioningSlot>) => {
      dispatch({ type: PositioningPlanAction.DELETE_ASSIGNEE, payload });
    },
    [positioning],
  );

  const handleReplaceAssignee = useCallback(
    (payload: Partial<PositioningSlot>, employee: Partial<PositionAssigneeDto>) => {
      dispatch({ type: PositioningPlanAction.REPLACE_ASSIGNEE, payload, employee });
    },
    [positioning],
  );

  const handleRefetchPlan = useCallback(() => {
    liveSchedules.refetch();
  }, [liveSchedules.status]);

  useEffect(() => {
    if (dayBlock) {
      let payload: PositioningPlan | undefined = undefined;

      if (liveSchedules.data) {
        const positionGroup = liveSchedules.data.positionGroup;
        const positionSlots = positionGroup?.positionSlots;
        const employees = map(positionGroup?.employees, _ => {
          const positionSlot = find<PositionSlotDto>(positionSlots, slot =>
            some<PositionAssigneeDto>(slot.positionJob?.positionAssignees, assignee => assignee.employeeId === _.employeeId),
          );
          const employee: PositioningEmployee = {
            employeeId: _.employeeId!,
            name: _.name!,
            shiftStartTime: _.shiftStartTime,
            shiftEndTime: _.shiftEndTime,
            lastPositionId: _.lastPositionId,
            positionJobId: positionSlot?.positionJob?.id,
            positionSlotId: positionSlot?.id,
            title: _.title,
          };
          return employee;
        });

        payload = {
          id: positionGroup?.id,
          blockId: positionGroup?.blockId,
          confirmedTime: positionGroup?.confirmedTime,
          date: positionGroup?.date,
          subBlockTime: positionGroup?.subBlockTime,
          dayBlock: dayBlock,
          isLocked: positionGroup?.isLocked,
          siteId: positionGroup?.siteId,
          employees: employees,
          positionSlots: map(positionGroup?.positionSlots, _ => {
            const assignee = first(_.positionJob?.positionAssignees);
            const lastPositionId = find<PositioningEmployee>(
              employees,
              emp => !!assignee && emp.employeeId === assignee?.employeeId,
            )?.lastPositionId;
            const positionSlot: PositioningSlot = {
              id: _.id,
              assignee: assignee
                ? {
                    id: assignee.id!,
                    employeeId: assignee.employeeId!,
                    name: assignee.name!,
                    shiftStartTime: assignee.startTime,
                    shiftEndTime: assignee?.endTime,
                    title: assignee?.title,
                    lastPositionId: lastPositionId,
                  }
                : undefined,
              isOpsLeader: _.isOpsLeader,
              positionJobId: _.positionJob?.id,
              positionId: _.positionJob?.positionId,
              positionGroupId: positionGroup?.id,
              positionType: _.positionJob?.positionType,
              secondaryJobs: _.positionJob?.secondaryJobs,
              slotNumber: _.slotNumber,
              startTime: _.positionJob?.startTime,
              endTime: _.positionJob?.endTime,
              title: _.positionJob?.title,
              origPositionId: _.positionJob?.orgPositionId ?? _.positionJob?.positionId,
              origTitle: _.positionJob?.orgPositionTitle ?? _.positionJob?.title,
            };
            return positionSlot;
          }),
          shiftSubBlocks: liveSchedules.data.shiftSubBlocks,
        };

        if (!positionGroup?.confirmedTime) {
          payload.positionSlots = [];
          payload.employees = liveSchedules.data.liveSchedules?.map(_ => {
            const employee: PositioningEmployee = {
              employeeId: _.employee?.id!,
              name: [_.employee?.firstName, _.employee?.lastName].filter(Boolean).join(' '),
              shiftStartTime: _.startTime,
              shiftEndTime: _.endTime,
              lastPositionId: _.employee?.lastPositionId,
              title: _.employee?.title,
            };

            // make sure the UI recognizes this "isNonService" flag and display the employee in the right pane Non-Service pane.
            if (_.employee?.isNonService) {
              payload?.positionSlots?.push({
                positionType: PositionTypeEnum.NonService,
                assignee: employee,
                title: PositionTypeEnum.NonService,
              });
            }
            return employee;
          });
        }
      }

      dispatch({ type: PositioningPlanAction.RESET, payload: payload });
    }
  }, [dayBlock, liveSchedules.data, subBlockTime]);

  return (
    <PositioningPlanContext.Provider
      value={{
        changes,
        positioning,
        createPositionSlot: handleCreatePositionSlot,
        updatePositionSlot: handleChangePositionSlot,
        deletePositionSlot: handleDeletePositionSlot,
        deleteAssignee: handleDeleteAssignee,
        moveTo: handleMoveTo,
        replaceAssignee: handleReplaceAssignee,
        resetChanges: handleResetChanges,
        refetchPlan: handleRefetchPlan,
      }}
    >
      {children}
    </PositioningPlanContext.Provider>
  );
};

export const PositioningPlanConsumer = PositioningPlanContext.Consumer;

export const usePositioningPlan = () => {
  // get the context
  const context = useContext(PositioningPlanContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('usePositioningPlan was used outside of its Provider');
  }
  return context;
};
