import { Modal } from '@pimm/base';
import { DashboardModalType, useDashboard } from '@app/features/dashboard';
import { StoreConfig } from '@app/features/store-info/components';
import { useAppSelector } from '@app/store/store';
import { selectStoreConfig } from '@app/store/selectors';
import { useKitchenLayout } from '@app/features/kitchen-positioning';

export const ModalStoreConfig = () => {
  const storeConfig = useAppSelector(state => selectStoreConfig(state));
  const { modal, toggleModal } = useDashboard();
  const { kitchenLayout } = useKitchenLayout();
  return (
    <Modal
      _content={{ rounded: 'lg', maxWidth: 600 }}
      size="lg"
      noPadding
      isOpen={modal?.type === DashboardModalType.StoreConfig}
      onClose={toggleModal}
    >
      <StoreConfig kitchenLayout={kitchenLayout.data} siteConfig={storeConfig} onCancel={toggleModal} />
    </Modal>
  );
};
