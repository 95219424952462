import { filter, find, map } from 'lodash';
import { DayBlock } from '@app/features/store-core';
import { PositionTypeEnum, SecondaryJob, ShiftSubBlock } from '@pimm/services/lib/sms-workforce';

export interface PositioningEmployee {
  id?: string;
  employeeId: string;
  name: string;
  shiftStartTime?: string;
  shiftEndTime?: string;
  lastPositionId?: string;
  positionTitle?: string;
  positionJobId?: string;
  positionSlotId?: string;
  title?: string;
}

export interface PositioningSlot {
  id?: string;
  isOpsLeader?: boolean;
  positionJobId?: string;
  positionId?: string;
  positionGroupId?: string;
  positionType?: PositionTypeEnum;
  secondaryJobs?: SecondaryJob[];
  assignee?: PositioningEmployee;
  slotNumber?: number;
  startTime?: string;
  endTime?: string;
  title?: string;
  origPositionId?: string;
  origTitle?: string;
}

export interface PositioningPlan {
  shiftSubBlocks?: ShiftSubBlock[];
  id?: string;
  blockId?: number;
  confirmedTime?: string;
  date?: string;
  dayBlock: DayBlock;
  isLocked?: boolean;
  positionSlots?: PositioningSlot[];
  siteId?: string;
  subBlockTime?: string;
  employees?: PositioningEmployee[];
}

export enum PositioningPlanAction {
  COMPLETE = 'COMPLETE',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  DELETE_ASSIGNEE = 'DELETE_ASSIGNEE',
  REPLACE_ASSIGNEE = 'REPLACE_ASSIGNEE',
  RESET = 'RESET',
  SWAP = 'SWAP',
}

// Define the action types
type Action =
  | { type: PositioningPlanAction.CREATE; payload?: Partial<PositioningSlot> }
  | { type: PositioningPlanAction.UPDATE; payload?: Partial<PositioningSlot> }
  | { type: PositioningPlanAction.DELETE; payload?: Partial<PositioningSlot> }
  | { type: PositioningPlanAction.DELETE_ASSIGNEE; payload?: Partial<PositioningSlot> }
  | { type: PositioningPlanAction.REPLACE_ASSIGNEE; payload?: Partial<PositioningSlot>; employee?: Partial<PositioningEmployee> }
  | { type: PositioningPlanAction.RESET; payload?: PositioningPlan }
  | { type: PositioningPlanAction.SWAP; payload?: Partial<PositioningSlot>; _payload?: Partial<PositioningSlot> };

export type PositioningPlanReducerReturn = PositioningPlan | undefined;

// Create the reducer
export const PositioningPlanReducer = (state: PositioningPlanReducerReturn, action: Action): PositioningPlanReducerReturn => {
  switch (action.type) {
    case PositioningPlanAction.RESET:
      return action.payload;

    case PositioningPlanAction.CREATE:
      return {
        ...state,
        positionSlots: [...(state?.positionSlots ?? []), action.payload],
      } as PositioningPlan;

    case PositioningPlanAction.UPDATE:
      const positionSlot = action.payload;
      const prevPositionSlot = find<PositioningSlot>(state?.employees, slot => !!positionSlot?.id && slot.id === positionSlot.id);
      const employees = map(state?.employees, employee => {
        // For position assignment, remember the last known positionSlot of the employee
        if (!prevPositionSlot?.assignee && positionSlot?.assignee?.employeeId === employee.employeeId) {
          return positionSlot.assignee;
        }
        return employee;
      });

      return {
        ...state,
        employees: employees,
        positionSlots: map(state?.positionSlots, positionSlot => {
          if (positionSlot.id === action.payload?.id) return { ...positionSlot, ...action.payload };
          if (action.payload?.isOpsLeader) return { ...positionSlot, isOpsLeader: false };
          return positionSlot;
        }),
      } as PositioningPlan;

    case PositioningPlanAction.SWAP:
      const prev = action.payload;
      const next = action._payload;
      return {
        ...state,
        employees: map(state?.employees, employee => {
          if (employee.employeeId === prev?.assignee?.employeeId) {
            return { ...employee, positionSlotId: next?.id, positionJobId: next?.positionJobId };
          } else if (employee.employeeId === next?.assignee?.employeeId) {
            return { ...employee, positionSlotId: prev?.id, positionJobId: prev?.positionJobId };
          }
          return employee;
        }),
        positionSlots: map(state?.positionSlots, positionSlot => {
          if (!!next) {
            if (positionSlot.positionJobId === prev?.positionJobId) {
              return { ...positionSlot, assignee: next?.assignee };
            } else if (positionSlot.positionJobId === next?.positionJobId) {
              return { ...positionSlot, assignee: prev?.assignee };
            }
          }
          return positionSlot;
        }),
      } as PositioningPlan;

    case PositioningPlanAction.DELETE:
      return {
        ...state,
        positionSlots: filter<PositioningSlot>(state?.positionSlots, slot => {
          return !(slot.positionType === PositionTypeEnum.NonService && slot.assignee?.employeeId === action.payload?.assignee?.employeeId);
        }),
      } as PositioningPlan;

    case PositioningPlanAction.DELETE_ASSIGNEE:
      return {
        ...state,
        employees: map(state?.employees, employee => {
          if (employee.employeeId === action.payload?.assignee?.employeeId) {
            return { ...employee, positionSlotId: undefined, positionJobId: undefined };
          }
          return employee;
        }),
        positionSlots: map(state?.positionSlots, positionSlot => {
          if (positionSlot.id === action.payload?.id) return { ...positionSlot, assignee: undefined };
          return positionSlot;
        }),
      } as PositioningPlan;

    case PositioningPlanAction.REPLACE_ASSIGNEE:
      const position = action.payload;
      const employee = {
        ...action.employee,
        positionSlotId: position?.id,
        positionJobId: position?.positionJobId,
      };
      return {
        ...state,
        employees: map(state?.employees, _employee => {
          if (_employee.employeeId === position?.assignee?.employeeId) {
            return { ..._employee, positionSlotId: undefined, positionJobId: undefined };
          } else if (_employee.employeeId === action.employee?.employeeId) {
            return employee;
          }
          return _employee;
        }),
        positionSlots: map(state?.positionSlots, positionSlot => {
          if (positionSlot.id === action.payload?.id) return { ...positionSlot, assignee: employee };
          return positionSlot;
        }),
      } as PositioningPlan;

    default:
      return state;
  }
};
