import { createRef, useEffect, useMemo, useState } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { Theme, NativeBaseProvider } from 'native-base';

import { linking } from '@app/navigations/linking';
import { useAppTheme } from '@app/features/app';
import { themeConfig } from '@pimm/base';

type ThemeContainerProps = {
  children: React.ReactNode;
};

export const ThemeContainer = ({ children }: ThemeContainerProps) => {
  const navigationRef = createRef<any>();
  const { colors } = useAppTheme();
  const theme = useMemo(() => themeConfig(colors), [colors]);

  return (
    // TODO: Fix type return issue
    <NativeBaseProvider theme={theme as any}>
      <NavigationContainer
        ref={navigationRef}
        linking={linking}
        // TODO: create a reusable navigation theme
        theme={{
          dark: false,
          colors: {
            primary: theme.colors.primary[600],
            background: theme.colors.white,
            card: theme.colors.primary[600],
            text: theme.colors.white,
            border: theme.colors.primary[600],
            notification: theme.colors.white,
          },
        }}
      >
        {children}
      </NavigationContainer>
    </NativeBaseProvider>
  );
};
