import { useMemo } from 'react';
import { Box, HStack, IconButton, VStack, View, useMediaQuery, useTheme } from 'native-base';
import EvilIcons from 'react-native-vector-icons/EvilIcons';
import { filter, first, isEmpty, map, range, sumBy } from 'lodash';

import { Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { formatToISOString } from '@app/utils/date-formatter';
import { ResourceLoader } from '@app/components/shared';
import { Daypart } from '@app/features/store-core';
import { useGetForecastAmounts, useGetSaleAmounts } from '../hooks';
import { DaypartSales, SaleEntry } from '../types';
import { LaborHourGuidelines } from './labor-hour-guidelines';
import { SalesForecast } from './sales-forecast';
import { SalesChart } from './chart-sales';
import { SalesBox } from './sales-box';

type SalesTrackerProps = {
  dayparts: Daypart[];
  forecastAmounts: ReturnType<typeof useGetForecastAmounts>;
  salesAmounts: ReturnType<typeof useGetSaleAmounts>;
};

export const SalesTracker = ({ dayparts, forecastAmounts, salesAmounts }: SalesTrackerProps) => {
  const { colors } = useTheme();
  const [isSmallScreen] = useMediaQuery({ maxWidth: 1200 });
  const { translate } = useAppLocale();

  // Enumerate hourly/daypart forecast and sales
  const daypartSales = useMemo(() => {
    const startTime = first(dayparts)?.startTime;
    let daypartSales: DaypartSales[] = [];

    if (startTime) {
      const timeEntries: SaleEntry[] = range(48).map(index => {
        const time = new Date(startTime.getTime() + 30 * 60 * 1000 * index);
        const isoDate = formatToISOString(time);
        const forecastEntry = forecastAmounts?.data?.find(forecast => forecast?.startDate?.includes(isoDate));
        const salesEntry = salesAmounts?.data?.find(sale => sale?.startDate?.includes(isoDate));
        return {
          time: time,
          actual: salesEntry?.amount ?? 0,
          forecast: forecastEntry?.amount ?? 0,
        };
      });

      daypartSales = map(dayparts, daypart => {
        const sales = filter(timeEntries, entry => entry.time >= daypart.startTime && entry.time < daypart.endTime);
        const sale: DaypartSales = {
          ...daypart,
          forecast: sumBy(sales, 'forecast'),
          actual: sumBy(sales, 'actual'),
          sales: sales,
        };

        return sale;
      });
    }

    return daypartSales;
  }, [dayparts, forecastAmounts.data, salesAmounts.data]);

  const isLoading = salesAmounts.isIdle || salesAmounts.isLoading || forecastAmounts.isLoading;

  return (
    <VStack flex={1} pb={4}>
      <HStack space={{ md: 1.5, xl: 2 }} py={3} px={4}>
        {daypartSales.map(_ => (
          <SalesBox key={_.partNumber} forecastAmount={_.forecast} salesAmount={_.actual} title={translate(_.label, _.translations)} />
        ))}

        {!isSmallScreen && daypartSales.length > 0 && (
          <SalesBox
            forecastAmount={sumBy(daypartSales, 'forecast')}
            salesAmount={sumBy(daypartSales, 'actual')}
            title="Total Today"
            highlight
          />
        )}
      </HStack>

      <View flex={1} w="full" maxH={500} pl={1} pr={4}>
        <ResourceLoader h="full" isLoading={isLoading}>
          <SalesChart daypartSales={daypartSales} />
        </ResourceLoader>
      </View>

      {!isEmpty(daypartSales) && (
        <View>
          <Box pl={10} pr={4}>
            <SalesForecast daypartSales={daypartSales} />
          </Box>

          <View pt={4} px={4}>
            <HStack alignItems="center" justifyContent="space-between" pb={1}>
              <Text size={{ md: 'md', xl: 'lg' }} fontWeight={700} color="black" mb={2}>
                Labor Hour Guidelines
              </Text>
              <IconButton
                p={0}
                _hover={{ backgroundColor: 'transparent' }}
                icon={<EvilIcons name="gear" size={22} color={colors.primary[500]} />}
              />
            </HStack>
            <View w="full" pl={3}>
              <LaborHourGuidelines />
            </View>
          </View>
        </View>
      )}
    </VStack>
  );
};
