import { useMemo, useState } from 'react';
import { Box, HStack, VStack } from 'native-base';
import { useBoolean } from 'usehooks-ts';
import { flatten, groupBy, keys, map, orderBy } from 'lodash';

import { ButtonGroup, Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { GoalProfileDto } from '@pimm/services/lib/sms-drivethru/types';
import { formatToTimeOnly } from '@app/utils/date-formatter';
import { Daypart, useSiteTime } from '@app/features/store-core';
import { hexToRGBA } from '@app/utils/string-formatter';
import { useQuerySettingsAndGoals } from '../context';

type SettingsStoreGuidelinesProps = {};

export const SettingsStoreGuidelines = (props: SettingsStoreGuidelinesProps) => {
  const { translate } = useAppLocale();
  const siteTime = useSiteTime();
  const settingsAndGoals = useQuerySettingsAndGoals();
  const isFranchise = useBoolean();

  const [dayparts] = useState<Daypart[]>(siteTime.toDayparts());

  const guidelines = useMemo(() => {
    const dayparts = siteTime.toDayparts();
    const profiles = groupBy(
      isFranchise.value ? settingsAndGoals.data?.customerGoalProfiles : settingsAndGoals.data?.brandGoalProfiles,
      'dpStartTime',
    );

    const guidelines = dayparts.reduce((_dayparts: Record<number, GoalProfileDto[]>, dp) => {
      const goals = flatten(
        keys(profiles).map(dpStartTime => {
          const startTime = dp.startTime.toTimeValue(dpStartTime);
          if (startTime && startTime >= dp.startTime && startTime < dp.endTime) {
            return profiles[dpStartTime];
          }
          return [];
        }),
      );

      if (!dp.partNumber) return _dayparts;
      return { ..._dayparts, [dp.partNumber]: orderBy(goals, ['name']) };
    }, {});

    return guidelines;
  }, [isFranchise, settingsAndGoals.data]);

  return (
    <VStack pb={3}>
      <HStack alignItems="center" justifyContent="space-between" px={4} h="58px">
        <VStack>
          <Text size="xl" fontWeight={700} color="gray.900">
            Goal Guidelines
          </Text>
          <Text size="md" fontWeight={500} color="gray.900">
            For reference
          </Text>
        </VStack>

        <ButtonGroup value={isFranchise.value} onChange={isFranchise.setValue}>
          <ButtonGroup.Item value={false}>Brand</ButtonGroup.Item>
          <ButtonGroup.Item value={true}>Customer</ButtonGroup.Item>
        </ButtonGroup>
      </HStack>

      <Box px={4}>
        <VStack rounded="xl" w="full" borderWidth={1} overflow="hidden">
          <HStack roundedTop="xl" overflowY="hidden" height={10} backgroundColor="gray.25" borderBottomWidth={1}>
            <Box w="1/5"></Box>
            <HStack flex={1} h="full">
              <Box flex={1} alignItems="center" justifyContent="center" h="full" maxW="1/5" borderLeftWidth={1}>
                <Text size="sm" fontWeight={500} color="gray.600" lineHeight="xs" textAlign="center">
                  Goal Profile
                </Text>
              </Box>
              <Box alignItems="center" justifyContent="center" h="full" w="1/5" borderLeftWidth={1}>
                <Text size="sm" fontWeight={500} color="gray.600" lineHeight="xs" textAlign="center">
                  Greet
                </Text>
              </Box>
              <Box alignItems="center" justifyContent="center" h="full" w="1/5" borderLeftWidth={1}>
                <Text size="sm" fontWeight={500} color="gray.600" lineHeight="xs" textAlign="center">
                  Menu Board
                </Text>
              </Box>
              <Box alignItems="center" justifyContent="center" h="full" w="1/5" borderLeftWidth={1}>
                <Text size="sm" fontWeight={500} color="gray.600" lineHeight="xs" textAlign="center">
                  Pickup
                </Text>
              </Box>
              <Box alignItems="center" justifyContent="center" h="full" w="1/5" borderLeftWidth={1}>
                <Text size="sm" fontWeight={500} color="gray.600" lineHeight="xs" textAlign="center" textTransform="uppercase">
                  Lanel Total
                </Text>
              </Box>
            </HStack>
          </HStack>

          {map(dayparts, (_daypart, index) => {
            const goals = _daypart.partNumber ? guidelines[_daypart.partNumber] : [];
            return (
              <HStack key={_daypart.label} borderTopWidth={index ? 1 : 0}>
                <VStack justifyContent="center" py={4} pl={4} w="1/5" h="full">
                  <Text color="black" fontWeight={700} size="md" lineHeight="lg">
                    {_daypart.label}
                  </Text>
                  <Text color="black" fontWeight={500} size="sm" lineHeight="sm">
                    {[_daypart.startTime, _daypart.endTime]
                      .filter(Boolean)
                      .map(dt => formatToTimeOnly(dt))
                      .join(' - ')}
                  </Text>
                </VStack>

                <VStack flex={1}>
                  {map(goals, (goal, i) => (
                    <HStack
                      key={`${_daypart.label}.goal[${i}]`}
                      h={!!goal?.customerId ? 'full' : 8}
                      borderTopWidth={i ? 1 : 0}
                      bg={index % 2 === 0 ? 'white' : 'gray.25'}
                    >
                      <Box alignItems="center" justifyContent="center" h="full" w="1/5" borderLeftWidth={1} bg={hexToRGBA(goal?.color, 0.3)}>
                        <Text size="md" fontWeight={500} color="black" lineHeight="lg" textAlign="center">
                          {translate(goal.name, goal.translations)}
                        </Text>
                      </Box>
                      <Box alignItems="center" justifyContent="center" h="full" w="1/5" borderLeftWidth={1}>
                        <Text size="md" fontWeight={500} color="black" lineHeight="lg" textAlign="center">
                          {goal?.greet}
                        </Text>
                      </Box>
                      <Box alignItems="center" justifyContent="center" h="full" w="1/5" borderLeftWidth={1}>
                        <Text size="md" fontWeight={500} color="black" lineHeight="lg" textAlign="center">
                          {goal?.menuBoard}
                        </Text>
                      </Box>
                      <Box alignItems="center" justifyContent="center" h="full" w="1/5" borderLeftWidth={1}>
                        <Text size="md" fontWeight={500} color="black" lineHeight="lg" textAlign="center">
                          {goal?.pickup}
                        </Text>
                      </Box>
                      <Box alignItems="center" justifyContent="center" h="full" w="1/5" borderLeftWidth={1}>
                        <Text size="md" fontWeight={500} color="black" lineHeight="lg" textAlign="center">
                          {goal?.total}
                        </Text>
                      </Box>
                    </HStack>
                  ))}
                </VStack>
              </HStack>
            );
          })}
        </VStack>
      </Box>
    </VStack>
  );
};
