export interface ImageLink {
  title?: string;
  sourceUri?: string;
  href?: string;
}

export const ProcuroTextLogo: ImageLink = {
  title: 'Procuro Logo',
  sourceUri: 'https://sms-cdn.b-cdn.net/assets-web/procuro/Logos/procuro_logo.svg',
};
