import { useEffect, useState } from 'react';
import { QueryKey, useQuery } from 'react-query';
import { useBoolean } from 'usehooks-ts';
import { Box, HStack, IconButton, Pressable, Spacer, Spinner, VStack, View } from 'native-base';
import { concat, filter, find, first, isEmpty, last, map, without } from 'lodash';
import { Locale } from 'locale-enum';

import { CloseIcon, LightBulbIcon, Modal, Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { AppDocDto, SiteDocDto } from '@pimm/services/lib/sms-tenants';
import { Document } from '@pimm/services/lib/sms-documents';
import { GetDocument } from '@pimm/services/lib/sms-documents/services';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { CultureTab, ResourceLoader } from '@app/components/shared';
import { useSiteConfig } from '@app/features/store-core';

type ModuleDoc = AppDocDto | SiteDocDto;

type ModalAppDocumentsProps = {
  identifierName?: string;
  moduleType?: string;
  trigger: (props: { documents?: ModuleDoc[]; onPress: () => void }) => React.ReactNode;
};

export const ModalAppDocuments = ({ identifierName, moduleType, trigger }: ModalAppDocumentsProps) => {
  const { locale, translate } = useAppLocale();
  const { siteConfig } = useSiteConfig();
  const isLoading = useBoolean();
  const modal = useModalFocus();
  const [quickReferences, setQuickReferences] = useState<ModuleDoc[]>([]);
  const [documentFocus, setDocumentFocus] = useState<Partial<Document> & { culture?: string }>();

  // No need to create a separate hook, the query request is uniq and need to declare it here
  const queryDocuments = useQuery<Partial<Document>[]>({
    enabled: modal.isOpen,
    queryKey: ['GetDocumentReferences', moduleType] as [QueryKey, string],
    queryFn: async () => {
      const documentIds = quickReferences.map(_ => _.documentId).filter(Boolean);
      let result: Document[] = [];
      if (documentIds.length) {
        const requests = documentIds.map(documentId => GetDocument(documentId!));
        result = await Promise.all(requests);
      }

      return map(quickReferences, _reference => {
        const documentId = _reference.documentId ?? _reference.id?.toString();
        const document: Document = {
          id: documentId,
          title: _reference.altText,
          files: [{ id: documentId, orgFileName: _reference.altText, url: _reference.docUrl }],
        };
        return find(result, ['id', _reference.documentId]) ?? document;
      });
    },
  });

  const cultures = without(map(documentFocus?.files, 'culture'), undefined) as Locale[];
  const documentFile = find(documentFocus?.files, ['culture', documentFocus?.culture]) ?? first(documentFocus?.files);

  const handleChangeDocument = (document: Document) => () => {
    const file = find(documentFocus?.files, ['culture', locale]) ?? first(documentFocus?.files);
    setDocumentFocus({ ...document, culture: file?.culture });
  };

  const handleChangeCulture = (culture: string) => {
    setDocumentFocus(prev => ({ ...prev, culture: culture }));
  };

  useEffect(() => {
    if (modal.isOpen && !isEmpty(queryDocuments.data)) {
      const documentFocus = queryDocuments.data?.reduce((_document: Document | undefined, document) => {
        if (!_document && !isEmpty(document.files)) return document;
        return _document;
      }, undefined);
      const file = find(documentFocus?.files, ['culture', locale]) ?? first(documentFocus?.files);
      setDocumentFocus({ ...documentFocus, culture: file?.culture });
    }
  }, [locale, modal.isOpen, queryDocuments.data]);

  useEffect(() => {
    if (siteConfig.companyInfo || siteConfig.siteDocs) {
      const appDocuments: AppDocDto[] = siteConfig.companyInfo?.brand?.appDocs ?? [];
      const siteDocuments: SiteDocDto[] = siteConfig?.siteDocs ?? [];
      const allDocuments: ModuleDoc[] = concat(appDocuments, siteDocuments);
      const availableDocuments = filter(
        allDocuments,
        _ => !!identifierName && !!_.identifierName && _.identifierName?.toLowerCase().includes(identifierName.toLowerCase()),
      );
      const sortedDocuments = availableDocuments.sort((a, b) => {
        const prevSeq = parseInt(last(a.identifierName?.split('.')) ?? '100');
        const nextSeq = parseInt(last(b.identifierName?.split('.')) ?? '100');
        return prevSeq - nextSeq;
      });
      setQuickReferences(sortedDocuments);
    }
  }, [identifierName, siteConfig.companyInfo, siteConfig.siteDocs]);

  return (
    <>
      {trigger({ documents: quickReferences, onPress: modal.setOpen })}
      <Modal
        _content={{ rounded: '2xl', w: '90%', h: '95%', maxWidth: '1200', overflow: 'hidden' }}
        size="full"
        noPadding
        hideClose
        isOpen={modal.isOpen}
        onClose={modal.setHide}
      >
        <VStack h="full">
          <HStack space={2} alignItems="center" px={4} pr={2} h="60px" borderBottomWidth={1}>
            <LightBulbIcon size={5} />
            <Text size="2xl" fontWeight={700} color="gray.900">
              {`Quick References - ${moduleType}`}
            </Text>

            <Spacer />

            {!isEmpty(cultures) && <CultureTab languages={cultures} value={documentFile?.culture} onChange={handleChangeCulture} />}

            <IconButton
              p={2}
              _pressed={{ bg: 'gray.100' }}
              _hover={{ bg: 'gray.100' }}
              icon={<CloseIcon size="14px" color="gray.700" />}
              onPress={modal.setHide}
            />
          </HStack>

          <View flex={1}>
            <ResourceLoader h="full" isLoading={queryDocuments.isIdle || queryDocuments.isLoading}>
              <HStack h="full">
                {quickReferences.length > 1 && (
                  <Box p={4} w={{ base: 320, xl: '1/3' }} borderRightWidth={1}>
                    <VStack borderRadius="xl" borderWidth={1} overflow="hidden">
                      {map(queryDocuments.data, (_document, index) => {
                        return (
                          <Pressable key={_document.id} onPress={handleChangeDocument(_document)}>
                            {({ isHovered }) => {
                              const isActive = _document.id === documentFocus?.id;
                              return (
                                <Box
                                  key={_document.id}
                                  justifyContent="center"
                                  py={1}
                                  pl={3}
                                  pr={4}
                                  minHeight="44px"
                                  borderTopWidth={index ? 1 : 0}
                                  bg={isHovered || isActive ? 'gray.200' : undefined}
                                >
                                  <Text size="md" fontWeight={500} color="gray.900" lineHeight="xs">
                                    {translate(_document.title, _document.translations)}
                                  </Text>
                                </Box>
                              );
                            }}
                          </Pressable>
                        );
                      })}
                    </VStack>
                  </Box>
                )}

                <Box flex={1} alignItems="center" justifyContent="center" bg="gray.25">
                  {isLoading.value && <Spinner position="absolute" zIndex={2} size={28} color="gray.300" />}
                  {!!documentFile?.url && (
                    <iframe
                      key={documentFile.id}
                      title={documentFile.orgFileName}
                      style={{ border: 'none', height: '100%', width: '100%' }}
                      src={`${documentFile?.url}#view=FitV&zoom=page-fit`}
                      onChange={isLoading.setTrue}
                      onLoad={isLoading.setFalse}
                      onError={isLoading.setFalse}
                    />
                  )}
                </Box>
              </HStack>
            </ResourceLoader>
          </View>
        </VStack>
      </Modal>
    </>
  );
};
