import FeatherIcons from 'react-native-vector-icons/Feather';
import Ionicons from 'react-native-vector-icons/Ionicons';

import { Button } from '@pimm/base';
import { MessageStatusEnum } from '@pimm/services/lib/message-board';

type ResponseButtonProps = React.ComponentProps<typeof Button> & {
  isHighPriority?: boolean;
  status?: MessageStatusEnum;
};

export const ResponseButton = ({ isHighPriority, status, ...props }: ResponseButtonProps) => {
  let endIcon, message;

  if (status === MessageStatusEnum.Delivered && isHighPriority) {
    endIcon = <FeatherIcons name="thumbs-up" size={18} />;
    message = 'Acknowledged';
  } else if ((status === MessageStatusEnum.Delivered)) {
    endIcon = <Ionicons name="eye-outline" size={20} />;
    message = 'Mark as Read';
  } else if (status === MessageStatusEnum.Read) {
    endIcon = <Ionicons name="eye-off-outline" size={20} />;
    message = 'Mark as Unread';
  }

  if (!message) return null;
  return (
    <Button
      px={2}
      {...props}
      alternate={!isHighPriority}
      bgColor={isHighPriority ? 'error.500' : 'white'}
      isLoadingText={message}
      endIcon={endIcon}
    >
      {message ?? ''}
    </Button>
  );
};
