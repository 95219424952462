import { Fragment, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Box, CloseIcon, HStack, IconButton, ScrollView, Spacer, VStack, useTheme } from 'native-base';
import { camelCase, filter, isEmpty, map, orderBy, size, some, startCase, toLower } from 'lodash';

import { Button, CalendarClockIcon, Text, Modal, ZapFastIcon } from '@pimm/base';

import { AggScheduledEmployee, PositionGuideDetailsDto, ShiftDto } from '@pimm/services/lib/sms-workforce';
import { ResetConfirmAndAssignShiftChange } from '@pimm/services/lib/sms-workforce/services';
import { useSiteTime } from '@app/features/store-core';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { formatTo, formatToISOString, formatToTimeOnly, stringToDateLocal } from '@app/utils/date-formatter';
import { useKitchenPositionGuideLive } from '../context';
import { ArrowDownBoxIcon, ArrowUpBoxIcon } from '../icons';

const ShiftTitles = {
  Start: 'Shift Starts',
  InProgress: 'Shift In Progress',
  End: 'Shift Ends',
} as const;

interface ShiftPartition {
  title: string;
  translation: string;
  caption: string;
  color: string;
  data?: (AggScheduledEmployee | ShiftDto)[];
}

type ModalShiftChangesProps = {
  trigger: (props: { shiftChanges: number; onPress: () => void }) => React.ReactNode;
  onNavigate?: () => void;
};

export const ModalShiftChanges = ({ trigger, ...props }: ModalShiftChangesProps) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const siteTime = useSiteTime();
  const livePositionGuide = useKitchenPositionGuideLive();
  const modal = useModalFocus();

  const [partitions, setPartitions] = useState<ShiftPartition[]>([]);

  const adjustedStaffs = size(partitions[1]?.data) + size(partitions[2]?.data);
  const shiftChanges = size(partitions[0]?.data) + size(partitions[2]?.data);
  const currentStaffs = size(partitions[0]?.data) + size(partitions[1]?.data);

  const confirmedTime = livePositionGuide.data?.positionSchedule?.confirmedTime;

  const resetConfirmAndAssignShiftChange = useMutation({ mutationFn: ResetConfirmAndAssignShiftChange });

  const handleResetConfirmAndAssign = async () => {
    const positionSchedule = livePositionGuide.data?.positionSchedule;
    if (positionSchedule?.siteId) {
      await resetConfirmAndAssignShiftChange.mutateAsync({
        currentDateTime: formatToISOString(siteTime.today()),
        siteId: positionSchedule.siteId,
      });

      livePositionGuide.refetch();
      modal.setHide();
    }
  };

  useEffect(() => {
    let timeoutRef;

    const populateShiftChanges = (positionGuide?: PositionGuideDetailsDto) => {
      const timeNow = siteTime.today();
      const positionSchedule = positionGuide?.positionSchedule;
      const confirmedTime = stringToDateLocal(positionSchedule?.confirmedTime);

      const shiftInProgress = filter(positionSchedule?.scheduledEmployees, _ => {
        const startTime = stringToDateLocal(_.shiftStartTime);
        const endTime = stringToDateLocal(_.shiftEndTime);
        return !!startTime && !!endTime && timeNow >= startTime && timeNow < endTime;
      });
      const shiftEnds = filter(positionSchedule?.scheduledEmployees, _ => {
        const endTime = stringToDateLocal(_.shiftEndTime);
        return !!endTime && endTime < timeNow;
      });
      const assignedIds = [...shiftEnds, ...shiftInProgress].map(_ => _.employeeId);

      const shiftStarts = filter(positionGuide?.employeeSchedules, _ => {
        const startTime = stringToDateLocal(_.startTime);
        const endTime = stringToDateLocal(_.endTime);
        if (some(assignedIds, id => id === _.employee?.id)) return false;
        return !!startTime && !!endTime && !!confirmedTime && startTime > confirmedTime && startTime < timeNow;
      });

      const columns: ShiftPartition[] = [
        // List all ended schedules
        {
          title: ShiftTitles.End,
          translation: 'kitchen_positioning:shift_ends',
          caption: 'kitchen_positioning:in_the_service_plan',
          color: colors.error[200],
          data: orderBy(shiftEnds, 'endTime'),
        },
        // List of current assigned employees
        {
          title: ShiftTitles.InProgress,
          translation: 'kitchen_positioning:shift_in_progress',
          caption: 'kitchen_positioning:in_the_service_plan',
          color: colors.gray[900],
          data: orderBy(shiftInProgress, 'endTime'),
        },
        // List of all arriving employees
        {
          title: ShiftTitles.Start,
          translation: 'kitchen_positioning:shift_starts',
          caption: 'kitchen_positioning:not_in_service_plan',
          color: colors.success[200],
          data: orderBy(shiftStarts, 'startTime'),
        },
      ];

      const nearestQuarterHour = timeNow.toNearestQuarterHour();
      nearestQuarterHour.setSeconds(0, 0);

      setPartitions(columns);

      if (!positionGuide) return null;

      // Recheck every quarter, if the data is available
      return setTimeout(() => {
        populateShiftChanges(positionGuide);
      }, nearestQuarterHour.getTime() - timeNow.getTime());
    };

    timeoutRef = populateShiftChanges(livePositionGuide.data);
    return () => clearInterval(timeoutRef);
  }, [livePositionGuide.data]);

  return (
    <Fragment>
      {trigger({ shiftChanges: shiftChanges, onPress: modal.setOpen })}
      <Modal size="xl" isOpen={modal.isOpen} hideClose noPadding _content={{ height: '90%', maxWidth: '900px', maxHeight: '480px' }}>
        <VStack h="full" w="full">
          <HStack space={4} alignItems="center" px={4} h="60px" borderBottomWidth={1}>
            <HStack space={2} rounded="lg" alignItems="center" px={2} h={10} bg="error.500">
              <Text size="7xl" fontWeight={700} color="white" lineHeight="xs">
                {shiftChanges}
              </Text>
              <Text size="lg" fontWeight={700} color="white" lineHeight="xs">
                {t('kitchen_positioning:shift_changes_s')}
              </Text>
            </HStack>

            {!!confirmedTime && (
              <Text size="sm" fontWeight={500} color="gray.700" lineHeight="xs" numberOfLines={2}>
                {t('common:last_update')}:{'\n'}
                <Text size="sm" fontWeight={600} color="gray.700" lineHeight="xs" numberOfLines={1}>
                  {formatTo(confirmedTime, 'lll')}
                </Text>
              </Text>
            )}

            <Spacer />

            <Text size="lg" fontWeight={700} color="gray.600" lineHeight="xs">
              {t('kitchen_positioning:current_staff_total')}:{' '}
              <Text size="lg" fontWeight={700} color="black" lineHeight="xs">
                {currentStaffs}
              </Text>
            </Text>

            <IconButton
              p={2}
              bg="gray.50"
              _pressed={{ bg: 'gray.100' }}
              _hover={{ bg: 'gray.100' }}
              icon={<CloseIcon size={3.5} color="gray.700" />}
              onPress={modal.setHide}
            />
          </HStack>

          <ScrollView flex={1} py={3} px={4}>
            <HStack space={3}>
              {map(partitions, (partition, index) => {
                const isShiftStart = partition.title === ShiftTitles.Start;
                return (
                  <VStack key={partition.title} space={3} flex={1} rounded="lg" p={3} borderWidth={1} bg="gray.25">
                    <HStack alignItems="center" justifyContent="space-between">
                      <Box>
                        <Text size="xl" fontWeight={700} color="gray.900" lineHeight="xs">
                          {t(partition.translation)}
                        </Text>
                        <Text size="md" fontWeight={400} color="gray.600" lineHeight="xs">
                          {t(partition.caption)}
                        </Text>
                      </Box>

                      <Box alignItems="center" justifyContent="center" rounded="md" bg={partition.color} h={7} minW={6} px={1.5}>
                        <Text size="2xl" fontWeight={700} color={index === 1 ? 'white' : 'black'} lineHeight="xs">
                          {partition.data?.length ?? 0}
                        </Text>
                      </Box>
                    </HStack>

                    <VStack rounded="lg" borderWidth={1} overflow="hidden">
                      <HStack minH={7} bg="gray.200">
                        <Box flex={1} justifyContent="center" px={2} borderRightWidth={1}>
                          <Text size="sm" fontWeight={500} color="gray.900" lineHeight="xs">
                            {t('common:name')}
                          </Text>
                        </Box>
                        <HStack space={1} flex={1} alignItems="center" justifyContent="center" px={1} maxW="90px">
                          {isShiftStart ? <ArrowDownBoxIcon size={4} /> : <ArrowUpBoxIcon size={4} />}
                          <Text size="sm" fontWeight={500} color="gray.900" lineHeight="xs">
                            {isShiftStart ? t('common:shift_start') : t('common:shift_end')}
                          </Text>
                        </HStack>
                      </HStack>
                      <VStack bg="white">
                        {isEmpty(partition.data) ? (
                          <Box justifyContent="center" minH={10}>
                            <Text size="sm" fontWeight={500} color="gray.500" numberOfLines={1} lineHeight="xs" textAlign="center">
                              {t('common:no_available_employees')}
                            </Text>
                          </Box>
                        ) : (
                          map(partition.data, (obj, i) => {
                            let name, dateTime;

                            if (obj) {
                              if ('employee' in obj) {
                                const { employee, ...shift } = obj as ShiftDto;
                                name = [employee?.firstName, employee?.lastName].filter(Boolean).join(' ');
                                dateTime = stringToDateLocal(shift.endTime);

                                // Display the start time of arriving employee
                                if (partition.title === ShiftTitles.Start) {
                                  dateTime = stringToDateLocal(shift.startTime);
                                }
                              } else {
                                const assignee = obj as AggScheduledEmployee;
                                name = assignee.name;
                                dateTime = stringToDateLocal(assignee.shiftEndTime);
                              }
                            }

                            return (
                              <HStack key={`${camelCase(partition.title)}.${i}`} minH={7} borderTopWidth={i ? 1 : 0}>
                                <Box flex={1} justifyContent="center" px={2} borderRightWidth={1}>
                                  <Text size="sm" fontWeight={500} color="black" numberOfLines={1} lineHeight="xs" ellipsizeMode="tail">
                                    {startCase(toLower(name))}
                                  </Text>
                                </Box>
                                <Box flex={1} justifyContent="center" px={1} maxW="90px">
                                  <Text
                                    size="sm"
                                    fontWeight={500}
                                    color="gray.800"
                                    numberOfLines={1}
                                    lineHeight="xs"
                                    ellipsizeMode="tail"
                                    textAlign="center"
                                  >
                                    {!!dateTime && formatToTimeOnly(dateTime)}
                                  </Text>
                                </Box>
                              </HStack>
                            );
                          })
                        )}
                      </VStack>
                    </VStack>
                  </VStack>
                );
              })}
            </HStack>
          </ScrollView>

          <HStack alignItems="center" justifyContent="space-between" px={4} minH="60px" borderTopWidth={1}>
            <Text size="lg" fontWeight={700} color="gray.600" lineHeight="xs">
              {t('kitchen_positioning:adjusted_staff_total')}:{' '}
              <Text size="lg" fontWeight={700} color="black" lineHeight="xs">
                {adjustedStaffs}
              </Text>
            </Text>

            <HStack space={2}>
              <Button
                size="sm"
                outline
                minH={9}
                w="140px"
                bg="primary.50"
                startIcon={<CalendarClockIcon size={5} color={colors.primary[600]} />}
                _hover={{ bg: 'primary.50' }}
                _pressed={{ bg: 'primary.50' }}
                _text={{ color: 'primary.600' }}
                onPress={props.onNavigate}
              >
                {t('common:position_plan')}
              </Button>

              <Button
                accessibilityLabel="Reset and Auto Assign Position"
                variant="outline"
                p={0}
                minH={9}
                w="140px"
                color="secondary"
                borderColor="blueLight.500"
                bg="blueLight.100"
                _pressed={{ bg: 'blueLight.100' }}
                _hover={{ bg: 'blueLight.100', borderColor: 'blueLight.500' }}
                _spinner={{ size: 16, color: colors.blueLight[500] }}
                isLoading={resetConfirmAndAssignShiftChange.isLoading}
                isLoadingText="Auto Assign"
                onPress={handleResetConfirmAndAssign}
                _text={{ color: 'blueLight.700' }}
                startIcon={<ZapFastIcon size={5} color={colors.blueLight[500]} />}
              >
                {t('common:auto_assign')}
              </Button>
            </HStack>
          </HStack>
        </VStack>
      </Modal>
    </Fragment>
  );
};
