import { Icon, IIconProps } from 'native-base';
import { Path } from 'react-native-svg';

export const EllipsePartialIcon = ({ size = 'sm', ...props }: IIconProps) => {
  return (
    <Icon size={size} viewBox="0 0 22 23" fill="none" {...props}>
      <Path d="M11 17.75C14.5899 17.75 17.5 14.8399 17.5 11.25C17.5 7.66015 14.5899 4.75 11 4.75V17.75Z" fill="currentColor" />
      <Path
        d="M11 21.25C16.5228 21.25 21 16.7728 21 11.25C21 5.72715 16.5228 1.25 11 1.25C5.47715 1.25 1 5.72715 1 11.25C1 16.7728 5.47715 21.25 11 21.25Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <Path
        d="M11 17.75C14.5899 17.75 17.5 14.8399 17.5 11.25C17.5 7.66015 14.5899 4.75 11 4.75V17.75Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Icon>
  );
};
