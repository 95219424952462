import { QueryKey, UseQueryResult, useQuery } from 'react-query';

import { GetSiteDispensers } from '@pimm/services/lib/coke-freestyle/services';

export const useGetSiteDispensers = (siteId?: string, queryKey?: string): UseQueryResult<Awaited<ReturnType<typeof GetSiteDispensers>>> => {
  const siteDispensers = useQuery({
    staleTime: 15 * (60 * 1000), // 15 minutes
    enabled: !!siteId,
    // TODO: need to revisit
    // refetchIntervalInBackground: true,
    // refetchOnMount: 'always',
    // refetchInterval: () => {
    //   // Run to the next 5 minutes
    //   const timeNow = new Date();
    //   const timeNext = timeNow.toNearestNextMinutes(5);
    //   return timeNext.getTime() - timeNow.getTime();
    // },
    queryKey: [queryKey ?? 'GetSiteDispensers', siteId] as [QueryKey, string],
    queryFn: ({ queryKey }) => GetSiteDispensers(queryKey[1]),
  });

  return siteDispensers;
};
