import { useEffect, useState } from 'react';
import { useBoolean } from 'usehooks-ts';
import { Box, HStack, IconButton, Spacer, Spinner, VStack } from 'native-base';
import { find, first, isEmpty, map, without } from 'lodash';
import { Locale } from 'locale-enum';

import { CloseIcon, Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { DocumentFile } from '@pimm/services/lib/sms-documents';
import { CultureTab } from '@app/components/shared';
import { CleaningTaskItem } from '../reducers';
import { useGetLinkDocument } from '../hooks/get-link-document.hook';

type CleaningDocumentsProps = {
  cleaningTask: CleaningTaskItem;
  onClose?: () => void;
};

export const CleaningDocuments = ({ cleaningTask, ...props }: CleaningDocumentsProps) => {
  const { locale, translate } = useAppLocale();
  const isLoading = useBoolean(true);
  const [documentFile, setDocumentFile] = useState<DocumentFile>();

  const document = useGetLinkDocument({ documentId: cleaningTask.linkedDoc });

  const cultures = without(map(document.data?.files, 'culture'), undefined) as Locale[];

  const handleChangeCulture = (culture: string) => {
    setDocumentFile(find(document.data?.files, ['culture', culture]));
  };

  useEffect(() => {
    if (document.data) {
      const file = find(document.data.files, ['culture', locale]) ?? first(document.data.files);
      setDocumentFile(file);
    }
  }, [document.data]);

  return (
    <VStack h="full">
      <HStack space={2} alignItems="center" px={4} pr={2} h="60px" borderBottomWidth={1}>
        <Text size="2xl" fontWeight={700} color="gray.900">
          {translate(cleaningTask.title, cleaningTask.translations)}
        </Text>

        <Spacer />

        {!isEmpty(cultures) && <CultureTab languages={cultures} value={documentFile?.culture} onChange={handleChangeCulture} />}

        {!!props.onClose && (
          <IconButton
            p={2}
            _pressed={{ bg: 'gray.100' }}
            _hover={{ bg: 'gray.100' }}
            icon={<CloseIcon size="14px" color="gray.700" />}
            onPress={props.onClose}
          />
        )}
      </HStack>

      <Box flex={1}>
        <Box alignItems="center" justifyContent="center" h="full" bg="gray.25">
          {isLoading.value && <Spinner position="absolute" zIndex={2} size={28} color="gray.300" />}
          {!!documentFile?.url && (
            <iframe
              title={`${cleaningTask.title}: ${documentFile?.fileName}`}
              style={{ border: 'none', height: '100%', width: '100%' }}
              src={`${documentFile?.url}#view=FitV&zoom=page-fit`}
              onChange={isLoading.setTrue}
              onLoad={isLoading.setFalse}
              onError={isLoading.setFalse}
            />
          )}
        </Box>
      </Box>
    </VStack>
  );
};
