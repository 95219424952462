import { QueryKey, UseQueryResult, useQuery } from 'react-query';
import { find, first, map } from 'lodash';

import { PerformanceMetricsDto, RollupDateRange, SensorSummaryDto } from '@pimm/services/lib/store-equipment';
import { GetMetrics, GetSensorSummary } from '@pimm/services/lib/store-equipment/services';

export interface EquipmentSummary {
  id: string;
  performance?: PerformanceMetricsDto;
  sensors: SensorSummaryDto[];
}

export type GetEquipmentSummaryParams = {
  siteId?: string;
  range?: RollupDateRange;
};

export const useGetEquipmentSummary = (
  query?: GetEquipmentSummaryParams,
  queryKey?: string,
): UseQueryResult<Awaited<EquipmentSummary[]>> => {
  const summary = useQuery({
    enabled: !!query?.siteId && !!query?.range,
    queryKey: [queryKey ?? 'GetEquipmentSummary', query] as [QueryKey, GetEquipmentSummaryParams],
    queryFn: async ({ queryKey: [key, params] }) => {
      const sensorSummary = await GetSensorSummary(params.siteId!, {
        range: params.range!,
      });

      const metrics = await GetMetrics(params!.siteId!, { DateRange: params.range! });
      const equipment = map(sensorSummary, _ => {
        // TODO: need to revisit, for now use label for comparison
        const performance = find<PerformanceMetricsDto>(metrics, metric => metric.Label === first(_.Sensors)?.Label);

        const _equipment: EquipmentSummary = {
          id: _.EquipmentId!,
          performance: performance,
          sensors: _.Sensors ?? [],
        };
        return _equipment;
      });

      return equipment;
    },
  });
  return summary;
};
