import React from 'react';
import { Box, HStack, Pressable, useTheme } from 'native-base';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import { Text } from '@pimm/base';
import { CreateATicketLink, HelpCenterLink } from '@app/consts/hyperlinks';

export type HeaderProps = {
  _container?: React.ComponentProps<typeof HStack>;
  children?: React.ReactNode;
};

export const Header = ({ _container, children }: HeaderProps) => {
  const { colors } = useTheme();

  const handleOpenBlankPage = (url: string) => () => {
    window.open(url, '_blank');
  };

  return (
    <React.Fragment>
      <Box safeAreaTop />
      <HStack alignItems="center" justifyContent="space-between" w="full" px={5} height={60} {..._container}>
        {/* Right Component */}
        <HStack flex={1} space={5} alignItems="center" justifyContent="flex-end" h="full">
          {/* Help Center */}
          <Pressable rounded="lg" onPress={handleOpenBlankPage(HelpCenterLink)}>
            {({ isHovered }) => (
              <HStack
                space={2}
                rounded="lg"
                alignItems="center"
                py={1}
                px={3}
                minH={9}
                bg="white"
                shadow={isHovered ? 5 : 3}
              >
              <Text size="lg" fontWeight={600} color="black" lineHeight="xs">
                  Help Center
                </Text>
                <MaterialCommunityIcons name="comment-question-outline" size={18} color="black" />
              </HStack>
            )}
          </Pressable>
          {/* Create a Ticket */}
          <Pressable rounded="lg" onPress={handleOpenBlankPage(CreateATicketLink)}>
            {({ isHovered }) => (
              <HStack
                space={2}
                rounded="lg"
                alignItems="center"
                py={1}
                px={3}
                minH={9}
                bg="white"
                shadow={isHovered ? 5 : 3}
              >
                <Text size="lg" fontWeight={600} color="black" lineHeight="xs">
                  Create a Ticket
                </Text>
                <MaterialCommunityIcons name="ticket-confirmation-outline" size={18} color="black" />
              </HStack>
            )}
          </Pressable>
        </HStack>
      </HStack>
    </React.Fragment>
  );
};
