import { Button, PencilIcon, Text } from '@pimm/base';
import { Box, HStack, VStack, useTheme } from 'native-base';
import { size } from 'lodash';

import { formatTo, stringToDateLocal } from '@app/utils/date-formatter';
import { usePositioningPlan } from '../context';

type PositioningPlanConfirmationProps = {
  onOpenSchedules?: () => void;
};

export const PositioningPlanConfirmation = (props: PositioningPlanConfirmationProps) => {
  const { colors } = useTheme();
  const { positioning } = usePositioningPlan();

  const countEmployees = size(positioning?.employees) ?? 0;
  const confirmedTime = positioning?.confirmedTime ? stringToDateLocal(positioning.confirmedTime) : undefined;

  return (
    <VStack space={1} pt={2} px={3}>
      {!!confirmedTime && (
        <Box pt={2}>
          <Text size="xs" fontWeight={500} color="gray.600" mt={-2}>
            Last Update:{' '}
            <Text size="xs" fontWeight={600} color="gray.700">
              {formatTo(confirmedTime, 'lll')}
            </Text>
          </Text>
        </Box>
      )}

      <HStack
        w="full"
        alignItems="center"
        space={2}
        px={{ md: 3, xl: 4 }}
        minHeight={{ md: 50, xl: 60 }}
        borderColor="gray.300"
        borderWidth={1}
        bgColor="gray.50"
        borderRadius="lg"
      >
        <Text size="9xl" fontWeight={700} color="gray.900">
          {countEmployees}
        </Text>
        <Text flex={1} size="md" fontWeight={600} color="gray.700" lineHeight="xs">
          Scheduled{'\n'}Employees
        </Text>

        <Button
          variant="outline"
          p={0}
          minH={{ md: 8, xl: 9 }}
          w={{ md: 8, xl: 9 }}
          color="secondary"
          bg="white"
          _hover={{ bg: 'white', borderColor: 'gray.500' }}
          onPress={props.onOpenSchedules}
          startIcon={<PencilIcon size={5} color={colors.gray[700]} />}
        />
      </HStack>
    </VStack>
  );
};
