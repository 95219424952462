import { Fragment, useEffect, useMemo } from 'react';
import { Box, CircleIcon, HStack, Pressable, VStack, useMediaQuery } from 'native-base';
import { find, map, size } from 'lodash';

import { Button, CalendarCheckIcon, Modal, Text } from '@pimm/base';
import { formatTo, stringToDateLocal } from '@app/utils/date-formatter';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { DayBlock, useSiteTime } from '@app/features/store-core';
import {
  PopoverTooltipShiftCheckPoints,
  PositioningAvailableEmployees,
  PositioningConfirmUnSave,
  PositioningDropdownOptions,
  PositioningPlanConfirmation,
  PositioningPlanConsumer,
  PositioningPlanProvider,
  PositioningPlanSlots,
  PositioningSubBlocks,
  ShiftBlock,
  useGetPositionScheduleLive,
  useShiftBlockFocus,
} from '@app/features/positioning-plan';
import { ResourceLoader } from '@app/components/shared';
import { ModalEmployeeSchedules } from './modal-employee-schedules';
import { ModalStaffReview } from './modal-staff-review';

type ServicePlanProps = {
  dayBlock: DayBlock;
  editable?: boolean;
  siteId: string;
  onChangeBlockNumber: (blockNumber: number) => void;
};

export const ServicePlan = ({ dayBlock, siteId, ...props }: ServicePlanProps) => {
  const [isSmallScreen] = useMediaQuery({ maxWidth: 1024 });
  const siteTime = useSiteTime();
  const modalSchedules = useModalFocus();
  const modalUnSave = useModalFocus<{ confirm: () => void }>();
  const shiftBlockFocus = useShiftBlockFocus();
  const [liveSchedules, setLiveScheduleParams] = useGetPositionScheduleLive();

  const { dayBlocks, isDisabled, isReadOnly } = useMemo(() => {
    const timeNow = siteTime.today();
    const dayBlocks = siteTime.toDayBlocks(dayBlock.startTime);
    const isReadOnly = dayBlock.endTime < timeNow;

    let isDisabled = !dayBlock || dayBlock.endTime < timeNow;

    // Check if shiftSubBlock is available, disable past shift subBlock
    if (!isDisabled && shiftBlockFocus.selected && shiftBlockFocus.all.length) {
      const shiftStartTime = stringToDateLocal(shiftBlockFocus.selected);
      if (shiftStartTime) {
        const shiftBlock = find<ShiftBlock>(shiftBlockFocus.all, shift => {
          return !!shift.startTime && !!shift.endTime && shiftStartTime >= shift.startTime && shiftStartTime < shift.endTime;
        });

        if (shiftBlock?.startTime && shiftBlock?.endTime) {
          isDisabled = shiftBlock.endTime < timeNow;
        }
      }
    }
    return {
      dayBlocks: dayBlocks,
      isDisabled: isDisabled,
      isReadOnly: isReadOnly,
    };
  }, [dayBlock, shiftBlockFocus.all, shiftBlockFocus.selected]);

  const handleChangeShiftBlock = (shiftBlock: ShiftBlock) => {
    shiftBlockFocus.changeFocus(shiftBlock.id);
    if (shiftBlock.startTime) {
      setLiveScheduleParams(prev => ({
        ...prev!,
        subBlockStartTime: shiftBlock.startTime,
      }));
    }
  };

  const handleFullDayReset = () => {
    const allBlocks = siteTime.toDayBlocks(dayBlock.startTime);
    const dayBlockNow = siteTime.toDayBlockNow();
    const activeBlock =
      find(allBlocks, _ => {
        return _.startTime >= dayBlockNow?.startTime && dayBlockNow.startTime < _.endTime;
      }) ?? allBlocks[0];
    if (!activeBlock.blockNumber) return;
    if (activeBlock.blockNumber === dayBlock?.blockNumber) {
      liveSchedules.refetch();
    } else {
      props.onChangeBlockNumber(activeBlock.blockNumber);
    }
  };

  const handleUnSaveChanges = (callback: (p: any) => void, unsave?: boolean) => (p: any) => {
    if (!unsave) callback(p);
    else {
      modalUnSave.setOpen({
        confirm: () => {
          modalUnSave.setHide();
          callback(p);
        },
      });
    }
  };

  useEffect(() => {
    // Highlight pre-selected shiftSubBlock startTime
    if (liveSchedules.status === 'success') {
      if (liveSchedules.data) {
        const { positionGroup, shiftSubBlocks } = liveSchedules.data;
        shiftBlockFocus.reset(shiftSubBlocks, positionGroup?.subBlockTime);
      }
    }
  }, [liveSchedules.status, liveSchedules.data]);

  useEffect(() => {
    if (dayBlock?.startTime && siteId) {
      setLiveScheduleParams({ dayBlock: dayBlock, siteId: siteId });
      // Reset subBlockTime on change siteId or dayBlockFocus
      shiftBlockFocus.reset();
    }
  }, [dayBlock, siteId]);

  return (
    <VStack h="full">
      {!!dayBlock && (
        <Fragment>
          <PositioningPlanProvider dayBlock={dayBlock} liveSchedules={liveSchedules}>
            <PositioningPlanConsumer>
              {({ changes }) => (
                <Fragment>
                  {/* DayBlocks */}
                  <HStack space={2} alignItems="center" py={2} px={3} borderBottomWidth={1}>
                    <HStack flex={1} space={{ md: 1, xl: 2 }} alignItems="center">
                      {map(dayBlocks, block => {
                        const timeNow = siteTime.today();
                        const isActive = timeNow >= block.startTime && timeNow <= block.endTime;
                        const isSelected = block.blockNumber === dayBlock?.blockNumber;
                        return (
                          <Pressable
                            key={block.blockNumber}
                            flex={1}
                            rounded="lg"
                            maxW={{ md: 190, xl: 200 }}
                            onPress={handleUnSaveChanges(() => props.onChangeBlockNumber(block.blockNumber!), changes.length > 0)}
                          >
                            {({ isHovered }) => (
                              <HStack
                                space={{ md: 1, xl: 2 }}
                                rounded="lg"
                                alignItems="center"
                                px={{ md: 1, lg: 2, xl: 3 }}
                                h="44px"
                                borderWidth={1}
                                borderColor={isSelected ? 'gray.800' : isHovered ? 'gray.500' : 'gray.200'}
                                bg={isSelected ? 'gray.800' : isHovered ? 'gray.200' : 'gray.50'}
                              >
                                {isActive && <CircleIcon size="8px" color="success.500" />}
                                <Box flex={1}>
                                  <Text
                                    size={{ md: 'sm', xl: 'md' }}
                                    fontWeight={700}
                                    color={isSelected ? 'white' : 'gray.800'}
                                    lineHeight="xs"
                                    numberOfLines={1}
                                    textTransform="uppercase"
                                  >
                                    {block.title}
                                  </Text>
                                  <Text
                                    size={{ md: 'xs', xl: 'sm' }}
                                    fontWeight={500}
                                    color={isSelected ? 'white' : 'gray.800'}
                                    lineHeight="xs"
                                    numberOfLines={1}
                                    textTransform="uppercase"
                                  >
                                    {block.subTitle}
                                  </Text>
                                </Box>
                                <Box rounded="md" justifyContent="center" px={1} h={5} minW={{ xl: '60px' }} bg="white">
                                  <Text size={{ md: 'xs', xl: 'sm' }} fontWeight={700} color="black" lineHeight="xs" textAlign="center">
                                    {formatTo(block.startTime, isSmallScreen ? 'h A' : 'h:mm A')}
                                  </Text>
                                </Box>
                              </HStack>
                            )}
                          </Pressable>
                        );
                      })}
                    </HStack>

                    <ModalStaffReview
                      dayBlock={dayBlock}
                      siteId={siteId}
                      onNavigate={modalSchedules.setOpen}
                      onReset={handleFullDayReset}
                      trigger={triggerProps => (
                        <Button
                          accessibilityLabel="Schedule Overview"
                          alternate
                          outline
                          startIcon={<CalendarCheckIcon size={5} />}
                          {...triggerProps}
                          onPress={handleUnSaveChanges(triggerProps.onPress, changes.length > 0)}
                        >
                          Schedule Overview
                        </Button>
                      )}
                    />

                    {!isReadOnly &&
                      liveSchedules.status === 'success' &&
                      changes.length === 0 &&
                      size(liveSchedules.data?.shiftSubBlocks) < 2 && (
                        <PositioningDropdownOptions
                          dayBlock={dayBlock}
                          isDisabled={isDisabled}
                          liveSchedules={liveSchedules}
                          siteId={siteId}
                        />
                      )}
                  </HStack>

                  {/* Shift SubBlocks */}
                  <VStack flex={1}>
                    {shiftBlockFocus.all.length > 1 && (
                      <>
                        <HStack space={2} alignItems="center" justifyContent="space-between" px={3} h="50px" borderBottomWidth={1}>
                          <HStack space={2} alignItems="center">
                            <PositioningSubBlocks
                              shiftBlockFocus={shiftBlockFocus}
                              onChange={handleUnSaveChanges(handleChangeShiftBlock, changes.length > 0)}
                            />

                            <PopoverTooltipShiftCheckPoints />
                          </HStack>

                          {!isReadOnly && (
                            <PositioningDropdownOptions
                              dayBlock={dayBlock}
                              isDisabled={isDisabled || liveSchedules.status !== 'success'}
                              liveSchedules={liveSchedules}
                              siteId={siteId}
                            />
                          )}
                        </HStack>
                      </>
                    )}

                    <Box flex={1}>
                      <ResourceLoader h="full" isLoading={liveSchedules.status === 'idle' || liveSchedules.status === 'loading'}>
                        <HStack h="full">
                          <VStack w={280} borderRightWidth={1}>
                            <PositioningPlanConfirmation onOpenSchedules={modalSchedules.setOpen} />

                            <Box flex={1}>
                              <PositioningAvailableEmployees isDisabled={isDisabled} />
                            </Box>
                          </VStack>
                          <Box flex={1}>
                            <PositioningPlanSlots isDisabled={isDisabled} />
                          </Box>
                        </HStack>
                      </ResourceLoader>
                    </Box>
                  </VStack>

                  {/* TODO: Fix dayBlock type issue */}
                  <ModalEmployeeSchedules dayBlock={dayBlock as any} isOpen={modalSchedules.isOpen} onClose={modalSchedules.setHide} />
                </Fragment>
              )}
            </PositioningPlanConsumer>
          </PositioningPlanProvider>
        </Fragment>
      )}

      <Modal
        size="md"
        _content={{ rounded: 'xl', p: 5, pt: 4, maxW: 400 }}
        isOpen={modalUnSave.isOpen}
        noPadding
        hideClose
        onClose={modalUnSave.setHide}
      >
        <PositioningConfirmUnSave onConfirm={modalUnSave.payload?.confirm} onClose={modalUnSave.setHide} />
      </Modal>
    </VStack>
  );
};
