import { ComponentProps } from 'react';

import { Chip } from '@pimm/base';
import { AlarmLevel } from '@pimm/services/lib/store-equipment';

type AverageTemperatureProps = ComponentProps<typeof Chip> & {
  alarmLevel?: AlarmLevel;
  value?: number;
};

export const AverageTemperature = ({ alarmLevel, value, ...props }: AverageTemperatureProps) => {
  const variant = (() => {
    switch (alarmLevel) {
      case AlarmLevel.Ok:
        return 'primary';
      case AlarmLevel.Info:
        return 'success';
      case AlarmLevel.Warning:
        return 'warning';
      case AlarmLevel.Alarm:
        return 'error';
      case AlarmLevel.Dead:
        return 'ghost';
      default:
        return 'warning';
    }
  })();

  return <Chip _container={{ rounded: 'md' }} size="md" flat variant={variant} value={value?.toFixed(1) ?? 0} {...props} />;
};
