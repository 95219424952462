import { useEffect, useState } from 'react';
import { SafeAreaView } from 'react-native';
import { Box, Center, VStack, View, Spinner, useTheme, Link } from 'native-base';
import EntypoIcons from 'react-native-vector-icons/Entypo';
import jwt from 'jwt-decode';

import { Card, Text } from '@pimm/base';
import { dateDiffToMinutes } from '@app/utils/date-formatter';
import { RootScreenProps } from '@app/navigations/root';
import { Header, Wrapper, useAuthToken } from '@app/features/app';

interface AuthPayload {
  exp: number;
  iat: number;
  user_id: string;
}

export type LauncherScreenProps = RootScreenProps<'Launcher'>;

export const LauncherScreen = ({ navigation, route }: LauncherScreenProps) => {
  const { colors } = useTheme();
  const { setAuthorization } = useAuthToken();
  const [isAuthorized, setIsAuthorized] = useState<boolean>();

  const navigateToLogin = () => {
    navigation.navigate('Login');
  };

  useEffect(() => {
    const query = route.params as
      | {
          accessToken?: string;
          refreshToken?: string;
        }
      | undefined;

    if (query?.accessToken && query?.refreshToken) {
      const payload: AuthPayload = jwt(query.accessToken);

      if (payload.user_id) {
        const expTime = new Date(payload.exp * 1000);
        const iatTime = new Date(payload.iat * 1000);
        const expiresIn = dateDiffToMinutes(iatTime, expTime);
        const isValid = expTime > new Date();

        setIsAuthorized(isValid);

        if (isValid) {
          setAuthorization({
            access_token: query.accessToken,
            expires_in: expiresIn * 60,
            refresh_token: query.refreshToken,
            scope: 'unknown',
            token_type: 'Bearer',
          });
          // Redirect to main page
          setTimeout(() => {
            // @ts-ignore
            navigation.navigate('Main', { screen: 'PositionGuide' });
          }, 1000);
        }
      }
    }
  }, [route.params]);

  useEffect(() => {
    // If nothing happens after a 1 minute, return invalid request
    setTimeout(() => setIsAuthorized(false), 60000);
  }, []);

  return (
    <Wrapper>
      <VStack h="full" justifyContent="center">
        <Center>
          <Card
            rounded="xl"
            justifyContent="center"
            pt={4}
            pb={6}
            width={580}
            borderWidth={1}
            bg="white"
            style={{
              shadowColor: colors.gray[800],
              shadowOffset: { width: 0, height: 1 },
              shadowOpacity: 0.4,
              shadowRadius: 4,
            }}
          >
            <Box py={3}>
              {isAuthorized === false ? (
                <Center>
                  <EntypoIcons name="emoji-sad" size={60} color="gray.600" />
                </Center>
              ) : (
                <Spinner size={24} color="gray.600" />
              )}
            </Box>

            <Text size="lg" fontWeight={700} color="gray.900" textAlign="center">
              {isAuthorized === false ? "Sorry, but we're having trouble signing you in" : 'Please wait while we process your request.'}
            </Text>

            <Text size="md" fontWeight={500} color="gray.700" textAlign="center">
              {isAuthorized === false
                ? 'The request did not include the required tokens for the user context or expired.'
                : 'This process is automatic. You will be redirected to your requested content shortly.'}
            </Text>

            {isAuthorized === false && (
              <Box mt={5} alignItems="center">
                <Text size="md" color="black">
                  Please click here to{' '}
                  <Link onPress={navigateToLogin}>
                    <Text size="md" color="primary.600" underline>
                      Login
                    </Text>
                  </Link>
                </Text>
              </Box>
            )}
          </Card>
        </Center>
      </VStack>
    </Wrapper>
  );
};
