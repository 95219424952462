import { Box } from 'native-base';

import { Text } from '@pimm/base';
import { hexToRGBA } from '@app/utils/string-formatter';

export const Chip = ({
  _container,
  colorScheme = 'primary',
  value,
  variant = 'solid',
  ...props
}: React.ComponentProps<typeof Text> &
  Pick<React.ComponentProps<typeof Box>, 'colorScheme'> & {
    _container?: React.ComponentProps<typeof Box>;
    value: string;
    variant?: 'solid' | 'outline';
  }) => {
  const outline = variant === 'outline';
  return (
    <Box
      flex="none"
      my={0.5}
      px={2}
      minH={4}
      justifyContent="center"
      rounded="xl"
      borderWidth={1}
      backgroundColor={outline ? `${colorScheme}.50` : `${colorScheme}.700`}
      borderColor={outline ? `${colorScheme}.200` : `${colorScheme}.700`}
      {..._container}
    >
      <Text size="xs" fontWeight={500} color={colorScheme || 'black'} lineHeight="xs" {...props}>
        {value}
      </Text>
    </Box>
  );
};
