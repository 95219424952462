import { create } from 'zustand';

interface AppGlobalState {
  utcOffset?: number;
  weekday?: number;
}

type AppGlobalsGoals = AppGlobalState & {
  setUtcOffset: (_utcOffset?: number) => void;
  setWeekday: (_weekday?: number) => void;
  clear: () => void;
};

export const useAppGlobalsGoals = create<AppGlobalsGoals>(set => ({
  setUtcOffset: _utcOffset => set({ utcOffset: _utcOffset }),
  setWeekday: _weekday => set({ weekday: _weekday }),
  clear: () => set({ utcOffset: undefined, weekday: undefined }),
}));
