import { useCallback, useState } from 'react';

type ModalFocusState<P = unknown> = {
  payload?: P;
  isOpen: boolean;
};

export const useModalFocus = <P = unknown>(
  props?: Partial<ModalFocusState<P>>,
): {
  payload?: P;
  isOpen: boolean;
  setOpen: (payload?: P) => void;
  setHide: () => void;
} => {
  const [modal, setModal] = useState<ModalFocusState<P>>({
    ...props,
    isOpen: props?.isOpen ?? false,
  });

  const onOpen = useCallback((payload?: P) => {
    setModal({ isOpen: true, payload: payload });
  }, []);

  const onHide = useCallback(() => {
    setModal(prev => ({ ...prev, isOpen: false }));
  }, []);

  return {
    payload: modal?.payload,
    isOpen: modal.isOpen,
    setOpen: onOpen,
    setHide: onHide,
  };
};
