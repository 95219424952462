import { memo, useCallback, useEffect, useState } from 'react';
import { Box, HStack, IconButton, Pressable, SectionList } from 'native-base';
import FeatherIcon from 'react-native-vector-icons/Feather';
import { filter, find, forEach, get, includes, isEmpty, map, merge, set, some, values } from 'lodash';

import { Text } from '@pimm/base';
import { KitchenEmployee, KitchenPositioning, Timeslot } from '../types';
import { PositioningActionTypes, useKitchenPositioning, useKitchenPositioningConfig } from '../context';
import { DraggableAssignee } from './draggable-assignee';

export enum EmployeePartitionTitle {
  Unassigned = 'Available for Service',
  Assigned = 'Assigned (Service)',
  NonService = 'Assigned (Non-Service)',
}

type EmployeeCollapsibleSection = {
  title: string;
  collapse: boolean;
  data: KitchenEmployee[];
};

type EmployeePartitionListProps = Pick<React.ComponentProps<typeof SectionList>, 'contentContainerStyle'> & {
  positioning?: KitchenPositioning;
  showLastKnownPosition?: boolean;
  hideLegends?: boolean;
  timeslot?: Timeslot;
};

const EmployeePartitionList = ({ contentContainerStyle, positioning, showLastKnownPosition, ...props }: EmployeePartitionListProps) => {
  const { dispatch } = useKitchenPositioning();
  const { isConfirmed, isEditEnabled } = useKitchenPositioningConfig();
  const [sections, setSections] = useState<EmployeeCollapsibleSection[]>([]);

  const handlePressCollapse = useCallback(
    (title: string) => {
      setSections(prev =>
        map(prev, section => {
          if (section.title === title) return { ...section, collapse: !section.collapse };
          return section;
        }),
      );
    },
    [sections],
  );

  const handleChangeToNonService = (employee: KitchenEmployee) => () => {
    const payload = { ...employee };

    set(payload, 'timeslots[0].isNonService', !get(employee, 'timeslots[0].isNonService'));

    dispatch({
      type: PositioningActionTypes.UPDATE_EMPLOYEE,
      payload: payload,
    });
  };

  useEffect(() => {
    // Distinct employee schedules by availability
    const sections: EmployeeCollapsibleSection[] = [];

    forEach(values(EmployeePartitionTitle), title => {
      const section: EmployeeCollapsibleSection = {
        title: title,
        collapse: find(sections, s => s.title === title)?.collapse ?? true,
        data: filter(positioning?.employees, employee =>
          some<Timeslot>(employee.timeslots, timeslot => {
            if (title === EmployeePartitionTitle.Unassigned) return !timeslot.employeeId && !timeslot.isNonService;
            if (title === EmployeePartitionTitle.Assigned) return !!timeslot.employeeId && !timeslot.isNonService;
            if (title === EmployeePartitionTitle.NonService) return !!timeslot.isNonService;
            return false;
          }),
        ),
      };

      // Force to hide empty section except "Unassigned"
      if (title === EmployeePartitionTitle.Unassigned || section.data.length > 0) {
        sections.push(section);
      }
    });
    setSections(sections);
  }, [isConfirmed, positioning]);

  return (
    <SectionList
      pb={3}
      h="full"
      w="full"
      contentContainerStyle={merge({ flexGrow: 1 }, contentContainerStyle)}
      extraData={[isConfirmed, isEditEnabled]}
      removeClippedSubviews
      stickySectionHeadersEnabled
      scrollEnabled
      sections={sections}
      renderSectionHeader={({ section: { collapse, data, title } }) => {
        return (
          <Box pt={2} px={{ md: 3, xl: 4 }} bgColor="white">
            <HStack alignItems="center" justifyContent="space-between" pb={2}>
              <Text size="lg" fontWeight={700} color="gray.900">
                {title}
              </Text>
              {sections.length > 1 && data.length > 0 && (
                <IconButton
                  h={7}
                  py={1}
                  px={0}
                  onPress={() => handlePressCollapse(title)}
                  _hover={{ bgColor: 'gray.25' }}
                  icon={<FeatherIcon name={collapse ? 'chevron-up' : 'chevron-down'} size={20} color="black" />}
                />
              )}
            </HStack>

            {collapse && data.length > 0 && (
              <HStack
                px={3.5}
                h={8}
                alignItems="center"
                justifyContent="space-between"
                borderWidth={1}
                borderTopLeftRadius="md"
                borderTopRightRadius="md"
                backgroundColor="gray.25"
              >
                <Text size="sm" fontWeight={500} color="gray.600" lineHeight="sm">
                  Employee
                </Text>
                <Text size="sm" fontWeight={500} color="gray.600" lineHeight="sm">
                  Coverage
                </Text>
              </HStack>
            )}

            {data.length === 0 && (
              <Text size="md" fontWeight={600} color="gray.400" textAlign="center" pb={2}>
                No assigned employees
              </Text>
            )}
          </Box>
        );
      }}
      renderItem={({ index, item: employee, section }) => {
        const isLastItem = index === section.data.length - 1;

        if (!section.collapse) return null;

        return (
          <Box
            mx={{ md: 3, xl: 4 }}
            mb={isLastItem ? 1 : 0}
            overflow="hidden"
            borderWidth={1}
            borderTopWidth={index ? 1 : 0}
            borderBottomWidth={isLastItem ? 1 : 0}
            borderBottomLeftRadius={isLastItem ? 'md' : undefined}
            borderBottomRightRadius={isLastItem ? 'md' : undefined}
            borderColor="gray.200"
            bg="white"
          >
            <DraggableAssignee
              // Important, this will help the useDrag to return the correct item
              key={[section.title, employee.employeeId, isConfirmed ? 'Confirmed' : undefined].filter(Boolean).join('.')}
              employee={employee}
              isUnassigned={section.title === EmployeePartitionTitle.Unassigned}
              showLastKnownPosition={showLastKnownPosition}
              onToggleEmployee={handleChangeToNonService(employee)}
            />
          </Box>
        );
      }}
    />
  );
};

export default memo(EmployeePartitionList);
