import { createSlice } from '@reduxjs/toolkit';

import { AppEmptyState } from '../state/AppState';

const appSlice = createSlice({
  name: 'app',
  initialState: AppEmptyState,
  reducers: {},
});

export const appReducer = appSlice.reducer;
