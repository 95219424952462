import { Icon, IIconProps } from 'native-base';
import { Path } from 'react-native-svg';

export const Tool01Icon = ({ size = 'sm', ...props }: IIconProps) => {
  return (
    <Icon size={size} viewBox="0 0 30 29" fill="none" {...props}>
      <Path
        d="M19.2419 9.17512C18.7138 8.6471 18.4498 8.38309 18.3509 8.07865C18.2639 7.81086 18.2639 7.52239 18.3509 7.2546C18.4498 6.95017 18.7138 6.68615 19.2419 6.15813L23.0264 2.37364C22.0221 1.91946 20.9073 1.66663 19.7334 1.66663C15.3151 1.66663 11.7334 5.24835 11.7334 9.66663C11.7334 10.3213 11.812 10.9577 11.9604 11.5667C12.1193 12.2189 12.1987 12.545 12.1846 12.7511C12.1698 12.9667 12.1377 13.0815 12.0382 13.2734C11.9432 13.4568 11.7612 13.6388 11.3971 14.0029L3.06671 22.3333C1.96214 23.4379 1.96214 25.2287 3.06671 26.3333C4.17128 27.4379 5.96214 27.4379 7.06671 26.3333L15.3971 18.0029C15.7612 17.6388 15.9432 17.4568 16.1266 17.3618C16.3185 17.2623 16.4333 17.2302 16.6489 17.2154C16.855 17.2013 17.1811 17.2807 17.8333 17.4396C18.4423 17.588 19.0787 17.6666 19.7334 17.6666C24.1517 17.6666 27.7334 14.0849 27.7334 9.66663C27.7334 8.49275 27.4805 7.37792 27.0264 6.37364L23.2419 10.1581C22.7138 10.6862 22.4498 10.9502 22.1454 11.0491C21.8776 11.1361 21.5891 11.1361 21.3214 11.0491C21.0169 10.9502 20.7529 10.6862 20.2249 10.1581L19.2419 9.17512Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Icon>
  );
};
