import { Box } from 'native-base';
import { find, map } from 'lodash';

import { Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { InstanceDetailsDto, EventPriority } from '@pimm/services/lib/store-equipment';
import { BatteryEventIcon, DoorEventIcon, TemperatureEventIcon, WifiOffIcon } from '../icons';
import { getEventPriorityColor } from '../_helper';
import { useEventTypes } from '../context';

export type EventType = 'Door' | 'Battery' | 'Signal' | 'Temperature';

type EventBoxProps = {
  instance?: InstanceDetailsDto;
  eventType: EventType;
};

export const EventBox = ({ instance, eventType = 'Temperature' }: EventBoxProps) => {
  const { translate } = useAppLocale();
  const eventTypes = useEventTypes();
  let alert, fill;

  if (instance) {
    const profiles = map(instance.EnabledEventTypes, num => find(eventTypes.data, event => event.EventType === num));
    const eventPriority = instance?.EventPriority ?? EventPriority.None;
    fill = getEventPriorityColor(eventPriority, eventType === 'Temperature');
    alert = profiles[0]?.Priorities
      ? find(profiles[0]?.Priorities, ['Priority', parseInt(instance?.EventPriority?.match(/\d+/)?.[0] || '', 10)])
      : null;
  }

  const color = fill ? 'white' : 'black';
  const opacity = fill ? 1 : 0.2;

  return (
    <Box w="full" h="full" pt={0.5} alignItems="center" justifyContent="center" bgColor={fill ? 'white' : undefined}>
      <Box rounded="full" boxSize={7} alignItems="center" justifyContent="center" bgColor={fill}>
        {eventType === 'Battery' && <BatteryEventIcon size="xl" color={color} opacity={opacity} />}
        {eventType === 'Door' && <DoorEventIcon size="sm" color={color} opacity={opacity} />}
        {eventType === 'Signal' && <WifiOffIcon size="sm" color={color} opacity={opacity} />}
        {eventType === 'Temperature' && <TemperatureEventIcon size="xl" color={color} opacity={opacity} />}
      </Box>
      {!!alert?.ShortString && (
        <Text size="xs" color="black">
          {translate(alert?.ShortString, alert?.ShortString?.translations) ?? '--'}
        </Text>
      )}
    </Box>
  );
};
