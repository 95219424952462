import { useEffect, useState } from 'react';
import { Box, HStack, IconButton, VStack, useTheme } from 'native-base';
import { filter, find, map, orderBy, partition } from 'lodash';

import { useDrop } from 'react-dnd';

import { ArrowCircleBrokenLeftIcon, MinusCircleIcon, Text } from '@pimm/base';
import { PositionTypeEnum } from '@pimm/services/lib/sms-workforce';
import { PositioningEmployee, PositioningSlot } from '../reducers';
import { usePositioningPlan } from '../context';
import { UnionIcon } from '../icons';
import { PositioningNonServiceCard } from './positioning-nonservice-card';
import { PendingChangesCard } from './pending-changes';
import { PositioningActionTools } from './positioning-action-tools';

type PositioningPlanNonServicesProps = {
  isDisabled?: boolean;
  subBlockTime?: string;
};

export const PositioningPlanNonServices = ({ isDisabled, subBlockTime }: PositioningPlanNonServicesProps) => {
  const { colors } = useTheme();
  const { changes, positioning, createPositionSlot, deletePositionSlot, updatePositionSlot, moveTo } = usePositioningPlan();
  const [nonServices, setNonServices] = useState<PositioningSlot[][]>([]);

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'PositioningAssignee',
    drop: async (_dropped: PositioningEmployee | PositioningSlot) => {
      // For assigned position
      if ('assignee' in _dropped) moveTo(_dropped, true);
      else {
        createPositionSlot({
          assignee: _dropped as PositioningEmployee,
          positionType: PositionTypeEnum.NonService,
          title: 'Non Service',
        });
      }
    },
    collect: monitor => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
  }));

  const handlePressUndo = (slot: any) => () => {
    // This will remove the employee from the list un-save changes
    const employee = find(positioning?.employees, ['employeeId', slot.assignee?.employeeId]);
    if (employee) {
      moveTo(employee);
      deletePositionSlot(slot);
    }
  };

  useEffect(() => {
    const nonServices = orderBy(
      filter<PositioningSlot>(positioning?.positionSlots, _ => _.positionType === PositionTypeEnum.NonService && !!_.assignee),
      ['assignee.name'],
    );
    let sections = [nonServices];

    // Check if we have an active positionGroup then differentiate active and pending non-service
    if (!!positioning?.id) sections = partition(nonServices, _ => !!_.id);
    setNonServices(sections);
  }, [positioning]);

  return (
    <VStack space={2}>
      <VStack space={1}>
        <HStack space={1.5} alignItems="center">
          <MinusCircleIcon size={6} color={colors.gray[600]} />
          <Text size="xl" fontWeight={700} color="black" lineHeight="xs">
            Non-Service
          </Text>
        </HStack>
        <HStack space={1} alignItems="center">
          <IconButton
            p={1}
            rounded="md"
            shadow={1}
            borderWidth={1}
            borderColor="gray.300"
            bg="white"
            isDisabled
            _disabled={{ opacity: 1 }}
            icon={<ArrowCircleBrokenLeftIcon size="14px" color={colors.gray[700]} />}
          />
          <Text size="md" fontWeight={600} color="gray.800">
            to move employee to Available.
          </Text>
        </HStack>
      </VStack>

      {changes.length > 0 && <PositioningActionTools isDisabled={isDisabled} />}

      {!isDisabled && (
        <HStack
          ref={drop}
          space={2}
          rounded="xl"
          p={2}
          borderWidth={1}
          borderColor={isOver ? 'gray.400' : 'gray.200'}
          bg="white"
          style={{
            shadowColor: colors.gray[500],
            shadowOffset: { width: 0, height: 1 },
            shadowOpacity: isOver ? 0.5 : 0.2,
            shadowRadius: 3,
          }}
        >
          <HStack
            flex={1}
            space={2}
            rounded="lg"
            alignItems="center"
            justifyContent="center"
            h={10}
            borderWidth={1}
            borderStyle="dashed"
            bg={isDisabled ? 'gray.100' : 'white'}
          >
            <UnionIcon size="22px" color={colors.gray[isDisabled ? 400 : 600]} />
            <Text size="md" color={colors.gray[isDisabled ? 400 : 600]} fontWeight={500} lineHeight="xs">
              Drag and drop
            </Text>
          </HStack>
        </HStack>
      )}

      {/* Pending Non-Service */}
      {nonServices[1]?.length > 0 && (
        <PendingChangesCard>
          {!!positioning?.dayBlock &&
            map(nonServices[1], (slot, index) => (
              <PositioningNonServiceCard
                key={`pending-nonservice[${index}]`}
                dayBlock={positioning.dayBlock}
                position={slot}
                isDisabled={isDisabled}
                isPending
                onPress={handlePressUndo(slot)}
              />
            ))}
        </PendingChangesCard>
      )}

      {/* Assigned Non-Service */}
      {!!positioning?.dayBlock && nonServices[0]?.length > 0 && (
        <VStack
          space={1.5}
          p={2}
          borderRadius="xl"
          borderWidth={1}
          bg="white"
          style={{
            shadowColor: colors.gray[500],
            shadowOffset: { width: 0, height: 1 },
            shadowOpacity: 0.2,
            shadowRadius: 3,
          }}
        >
          {map(nonServices[0], (slot, index) => (
            <PositioningNonServiceCard
              key={`assigned-nonservice[${index}][${slot.positionJobId}][${slot.assignee?.employeeId}]`}
              dayBlock={positioning.dayBlock}
              position={slot}
              isDisabled={isDisabled}
              isDragEnabled={!isDisabled}
              subBlockTime={subBlockTime}
              onPress={() => deletePositionSlot(slot)}
              onChange={updatePositionSlot}
            />
          ))}
        </VStack>
      )}
    </VStack>
  );
};
