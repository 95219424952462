import { Box, HStack } from 'native-base';

import { Text } from '@pimm/base';
import { dateDiffToMinutes, formatToTimeOnly, millisToMinutes, stringToDateLocal } from '@app/utils/date-formatter';
import { PositioningEmployee } from '../reducers';
import { usePositioningPlan } from '../context';

type EmployeeTimelineProps = {
  employee: PositioningEmployee;
};

export const EmployeeTimeline = ({ employee }: EmployeeTimelineProps) => {
  const { positioning } = usePositioningPlan();
  const dayBlock = positioning?.dayBlock;

  let startTime = dayBlock?.startTime;
  let endTime = dayBlock?.endTime;
  let shiftStartTime = stringToDateLocal(employee.shiftStartTime);
  let shiftEndTime = stringToDateLocal(employee.shiftEndTime);
  let durationInMinutes = 0;
  let totalDuration = 0;
  let offsetDuration = 0;
  let offset = 0;
  let width = 0;

  if (startTime && endTime) {
    totalDuration = dateDiffToMinutes(startTime, endTime);

    if (shiftStartTime && shiftEndTime) {
      shiftStartTime = new Date(Math.max(startTime.getTime(), shiftStartTime.getTime()));
      shiftEndTime = new Date(Math.min(endTime.getTime(), shiftEndTime.getTime()));
      durationInMinutes = dateDiffToMinutes(shiftStartTime, shiftEndTime);

      offsetDuration = millisToMinutes(shiftStartTime.getTime() - startTime.getTime());
      offset = (offsetDuration / totalDuration) * 100;
      width = (durationInMinutes / totalDuration) * 100;
    }
  }

  return (
    <HStack space={2} alignItems="center" justifyContent="space-between" mt={2} w="full">
      <Text size="xs" fontWeight={500} color="gray.500" lineHeight="xs">
        {startTime ? formatToTimeOnly(startTime) : ''}
      </Text>

      <HStack position="relative" flex={1} height="6px" rounded="lg" bgColor="gray.500" overflow="hidden">
        <Box ml={`${offset}%`} w={`${width}%`} bg="success.400" />
      </HStack>

      <Text size="xs" fontWeight={500} color="gray.500" lineHeight="xs">
        {endTime ? formatToTimeOnly(endTime) : ''}
      </Text>
    </HStack>
  );
};
