import React, { useEffect, useState } from 'react';
import { Box, HStack, IconButton } from 'native-base';
import { find, map, sortBy } from 'lodash';

import { ButtonGroup, LightBulbIcon, Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { ModuleTitle, useAppModules } from '@app/features/app';
import { useSiteConfig } from '@app/features/store-core';
import { MainNavigationParams, MainScreenProps } from '@app/navigations/root';
import { MainContainer } from '@app/components/core';
import { ScreenLayout } from '@app/components/layout';
import { ModalAppDocuments } from '@app/features/store-info/components';
import { DashboardUtilizationPanel } from './components/dashboard-utilization-panel';
import { LearningHubPanel } from './components/learning-hub-panel';

type AppInsightTabIdentifier = 'dashboardUtilization' | 'learningHub';

interface AppInsightsTabItem {
  identifier: AppInsightTabIdentifier;
  isDisabled?: boolean;
  seq?: number;
  title: string;
}

export type AppInsightsScreenProps = MainScreenProps<'StoreInfo'>;

export const AppInsightsScreen: React.FC<AppInsightsScreenProps> = ({ navigation, route }) => {
  const { locale, translate } = useAppLocale();
  const { siteConfig } = useSiteConfig();
  const { screens } = useAppModules();
  const [tabFocus, setTabFocus] = useState<AppInsightTabIdentifier>();
  const [tabItems, setTabItems] = useState<AppInsightsTabItem[]>([]);

  const isNothingToDisplay = tabItems.filter(_ => !_.isDisabled).length === 0;

  const handleNavigateTo = (screen: string, params?: any) => {
    navigation.navigate(screen as keyof MainNavigationParams, {
      siteId: route.params.siteId,
      ...params,
    });
  };

  useEffect(() => {
    const appInsightsConfig = siteConfig.config?.appInsightsConfig;
    const screen = find(screens, _ => _.name === route.name);

    if (appInsightsConfig) {
      const items: AppInsightsTabItem[] = sortBy(
        [
          {
            seq: 1,
            identifier: 'dashboardUtilization',
            isDisabled: !appInsightsConfig.dashboardUtilizationSettings?.enabled,
            title: 'Dashboard Utilization',
          },
          {
            seq: 2,
            identifier: 'learningHub',
            isDisabled: !appInsightsConfig.learningHubSettings?.enabled,
            title: 'Learning Hub',
          },
        ].map(item => {
          const subModule = find(screen?.subModules, _ => _.identifier === item.identifier);
          const title = translate(subModule?.title ?? item.title, subModule?.titleTranslations);
          return {
            ...item,
            seq: subModule?.seq,
            title: title,
          } as AppInsightsTabItem;
        }),
        ['seq'],
      );

      setTabItems(items);
      // if tabFocus is undefined, select the first non-disabled tab
      if (!tabFocus) setTabFocus(find(items, _ => !_.isDisabled)?.identifier);
    }
  }, [locale, route.name, screens, siteConfig]);

  return (
    <MainContainer>
      <ScreenLayout
        title={
          <HStack flex={1} alignItems="center">
            <ModuleTitle name={route.name} defaultText="App Insights" />
            <ModalAppDocuments
              identifierName="Module.AppInsights"
              moduleType="App Insights"
              trigger={triggerProps => {
                if (!triggerProps.documents?.length) return undefined;
                return (
                  <IconButton
                    rounded="lg"
                    borderWidth={1}
                    borderColor="gray.300"
                    ml={2}
                    p={0}
                    w={9}
                    h={9}
                    icon={<LightBulbIcon size="md" />}
                    onPress={triggerProps.onPress}
                  />
                );
              }}
            />
          </HStack>
        }
        actionTool={
          <HStack space={2} justifyContent="flex-end">
            {tabItems.length > 1 && (
              <ButtonGroup key={locale} value={tabFocus} onChange={setTabFocus}>
                {map(tabItems, tab => (
                  <ButtonGroup.Item key={tab.identifier} alignItems="center" value={tab.identifier} minW={90} isDisabled={tab.isDisabled}>
                    {tab.title}
                  </ButtonGroup.Item>
                ))}
              </ButtonGroup>
            )}
          </HStack>
        }
      >
        {tabFocus === 'dashboardUtilization' && <DashboardUtilizationPanel onNavigateTo={handleNavigateTo} />}
        {tabFocus === 'learningHub' && <LearningHubPanel />}
        {tabItems.length > 0 && isNothingToDisplay && (
          <Box alignItems="center" justifyContent="center" p={3} h="full" textAlign="center">
            <Text size="lg" fontWeight={600} color="gray.500">
              Nothing to display.{`\n`}
              <Text size="md" fontWeight={400} color="gray.500">
                This module is not setup correctly. Please contact your system administrator for more details.
              </Text>
            </Text>
          </Box>
        )}
      </ScreenLayout>
    </MainContainer>
  );
};
