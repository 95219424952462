import React from 'react';
import { View, Box } from 'native-base';
import { isEmpty } from 'lodash';

import { Text } from '@pimm/base';
import { useAppModules } from '@app/features/app';
import { useSiteConfig } from '@app/features/store-core';
import { MainScreenProps } from '@app/navigations/root';

export type NotFoundScreenProps = MainScreenProps<'NotFound'>;

export const NotFoundScreen: React.FC<NotFoundScreenProps> = () => {
  const { screens } = useAppModules();
  const { siteConfig } = useSiteConfig();
  const isNothingToDisplay = isEmpty(screens) || !siteConfig;

  return (
    <View size="full" overflowY="hidden" bg="gray.50">
      <Box alignItems="center" justifyContent="center" p={3} h="full" textAlign="center">
        <Text size="lg" fontWeight={600} color="gray.500">
          {isNothingToDisplay ? 'Nothing to display.' : 'Page not found.'}
          {`\n`}
          <Text size="md" fontWeight={400} color="gray.500" lineHeight="sm">
            {isNothingToDisplay
              ? 'This account is not setup correctly. Please contact your system administrator for more details.'
              : "The page you were looking for doesn't exist or an other error occured."}
          </Text>
        </Text>
      </Box>
    </View>
  );
};
