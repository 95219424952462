import { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';

import { Modal } from '@pimm/base';
import { RootStackNavigation } from '@app/navigations/root';
import { useAppDispatch, useAppSelector } from '@app/store/store';
import { selectStoreConfig, selectUserProfile } from '@app/store/selectors';
import { userProfileSlice } from '@app/store/slices/userProfileSlice';
import { useAuthPermissions } from '@app/features/app';
import { DashboardModalType, useDashboard } from '@app/features/dashboard';
import { UserSettingChangeEvent, UserSettings } from '@app/features/account-settings';

export const ModalUserSettings = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation<RootStackNavigation>();
  const userProfile = useAppSelector(selectUserProfile);
  const storeConfig = useAppSelector(selectStoreConfig);
  const permissions = useAuthPermissions(state => state.permissions);
  const { modal, toggleModal } = useDashboard();

  const handleEndChange = useCallback(
    (event?: UserSettingChangeEvent) => {
      switch (event?.name) {
        case 'update-profile':
        case 'update-recovery-email':
          dispatch(userProfileSlice.actions.update(event.data));
          break;

        case 'update-password':
          toggleModal();
          // clear token and force user to re-login
          localStorage.removeItem('token');
          navigation.navigate('Login');
          break;

        case 'update-pin':
          dispatch(userProfileSlice.actions.update({ pinStatusText: '0 day old' }));
          break;

        default:
          break;
      }
    },
    [userProfile],
  );

  return (
    <Modal
      _content={{ rounded: 'xl', w: { base: 900, xl: 1024 }, h: '90%', maxH: 900 }}
      size="full"
      noPadding
      isOpen={
        modal?.type === DashboardModalType.AccountMenu && (!!permissions?.userSettingsInfo || !!permissions?.userSettingsSecurity?.write)
      }
      onClose={toggleModal}
    >
      <UserSettings
        user={userProfile}
        cultures={storeConfig?.companyInfo?.brand?.availableCultures}
        onClose={toggleModal}
        onEndChange={handleEndChange}
      />
    </Modal>
  );
};
