import { Icon, IIconProps } from 'native-base';
import { Path } from 'react-native-svg';

export const ArrowDownBoxIcon = ({ size = 'sm', ...props }: IIconProps) => {
  return (
    <Icon viewBox="0 0 19 18" size={size} fill="none" {...props}>
      <Path
        d="M17.5 12.675C17.5 14.1451 17.5 14.8802 17.2139 15.4417C16.9622 15.9357 16.5607 16.3372 16.0667 16.5889C15.5052 16.875 14.7701 16.875 13.3 16.875L5.95 16.875C4.47986 16.875 3.74479 16.875 3.18328 16.5889C2.68935 16.3372 2.28778 15.9356 2.03611 15.4417C1.75 14.8802 1.75 14.1451 1.75 12.675L1.75 5.325C1.75 3.85486 1.75 3.11979 2.03611 2.55827C2.28778 2.06435 2.68935 1.66277 3.18328 1.41111C3.7448 1.125 4.47986 1.125 5.95 1.125L13.3 1.125C14.7701 1.125 15.5052 1.125 16.0667 1.41111C16.5607 1.66278 16.9622 2.06435 17.2139 2.55828C17.5 3.11979 17.5 3.85486 17.5 5.325L17.5 12.675Z"
        fill="#D1FADF"
      />
      <Path d="M6.125 9L9.625 12.5L13.125 9" fill="#D1FADF" />
      <Path
        d="M6.125 9L9.625 12.5M9.625 12.5L13.125 9M9.625 12.5L9.625 5.5M13.3 1.125L5.95 1.125C4.47986 1.125 3.7448 1.125 3.18328 1.41111C2.68935 1.66277 2.28778 2.06435 2.03611 2.55827C1.75 3.11979 1.75 3.85486 1.75 5.325L1.75 12.675C1.75 14.1451 1.75 14.8802 2.03611 15.4417C2.28778 15.9356 2.68935 16.3372 3.18328 16.5889C3.74479 16.875 4.47986 16.875 5.95 16.875L13.3 16.875C14.7701 16.875 15.5052 16.875 16.0667 16.5889C16.5607 16.3372 16.9622 15.9357 17.2139 15.4417C17.5 14.8802 17.5 14.1451 17.5 12.675L17.5 5.325C17.5 3.85486 17.5 3.11979 17.2139 2.55828C16.9622 2.06435 16.5607 1.66278 16.0667 1.41111C15.5052 1.125 14.7701 1.125 13.3 1.125Z"
        stroke="#12B76A"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Icon>
  );
};
