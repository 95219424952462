import { Box, HStack, Pressable } from 'native-base';
import FontAwesomeIcons from 'react-native-vector-icons/FontAwesome';
import { filter, find, includes, isEmpty, map, sortBy } from 'lodash';

import { Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { AggGoalTypeDto } from '@pimm/services/lib/sms-workforce';
import { hexToRGBA } from '@app/utils/string-formatter';

type GoalTypesSelectionProps = {
  _container?: React.ComponentProps<typeof HStack>;
  _caption?: React.ComponentProps<typeof Text>;
  _score?: React.ComponentProps<typeof Text>;
  disabled?: boolean;
  goals?: number[];
  goalTypes?: AggGoalTypeDto[];
  hideOSAT?: boolean;
  onAddGoalType?: (goalType: AggGoalTypeDto) => void;
};

export const GoalTypesSelection = ({ _caption, _container, _score, disabled, goals, hideOSAT, ...props }: GoalTypesSelectionProps) => {
  const { translate } = useAppLocale();
  const osat = find(props.goalTypes, ['seq', 0]);
  const sortedGoals = filter(sortBy(props.goalTypes, 'seq'), _ => _.seq !== 0);

  const handlePressGoal = (goalType: AggGoalTypeDto) => () => {
    if (props.onAddGoalType) props.onAddGoalType(goalType);
  };

  return (
    <HStack space={0.5} height="48px" {..._container}>
      {!isEmpty(sortedGoals) && !hideOSAT && (
        <Box
          flex={1}
          position="relative"
          alignItems="center"
          justifyContent="center"
          rounded="lg"
          py={1}
          h="full"
          borderWidth={1}
          borderColor="blue.600"
          bg="blue.600"
        >
          <Text size="sm" fontWeight={600} color="white" mb={0.5} numberOfLines={1} ellipsizeMode="tail">
            {translate(osat?.title, osat?.translations)}
          </Text>
          <Text size="md" fontWeight={700} color="white" lineHeight="xs" {..._score}>
            {`${osat?.score ?? 0}%`}
          </Text>
        </Box>
      )}

      {map(sortedGoals, goalType => {
        const isActive = includes(goals, goalType.id);
        return (
          <Pressable
            key={`weekly-goals[${goalType.id}]`}
            flex={1}
            rounded="lg"
            h="full"
            disabled={disabled}
            onPress={handlePressGoal(goalType)}
          >
            {({ isHovered }) => (
              <Box
                position="relative"
                alignItems="center"
                justifyContent="center"
                rounded="lg"
                py={1}
                h="full"
                borderWidth={1}
                bg={isActive || isHovered ? hexToRGBA(goalType?.color, 0.1) : 'white'}
                borderColor={isActive || isHovered ? hexToRGBA(goalType?.color, 0.2) : undefined}
              >
                <Text
                  size="xs"
                  fontWeight={600}
                  color={isActive ? goalType?.color : 'black'}
                  mb={0.5}
                  numberOfLines={1}
                  ellipsizeMode="tail"
                  {..._caption}
                >
                  {translate(goalType?.title, goalType?.translations)}
                </Text>
                <Text size="md" fontWeight={700} color={isActive ? goalType?.color : 'black'} lineHeight="xs" {..._score}>
                  {`${goalType.score ?? 0}%`}
                </Text>
                {isActive && (
                  <Box
                    position="absolute"
                    bottom={-10}
                    alignItems="center"
                    justifyContent="center"
                    rounded="full"
                    w={4}
                    h={4}
                    bg={goalType?.color}
                  >
                    <FontAwesomeIcons name="star" size={12} color="white" />
                  </Box>
                )}
              </Box>
            )}
          </Pressable>
        );
      })}
    </HStack>
  );
};
