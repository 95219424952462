import { Box, HStack, VStack, View, Spacer } from 'native-base';
import { isEmpty } from 'lodash';

import { Button, Text, TargetIcon, ResponsiveScrollView } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { GoalInsightDto } from '@pimm/services/lib/sms-workforce';
import { ResourceLoader } from '@app/components/shared';

export type TTMGoalsProps = {
  goalInsights?: GoalInsightDto[];
  onNavigateTo: (screen: string, params?: any) => void;
};

export const TTMGoals = ({ goalInsights, onNavigateTo }: TTMGoalsProps) => {
  const { translate } = useAppLocale();

  return (
    <View w="full" h="full">
      <HStack alignItems="center" px={4} h="56px" borderBottomWidth={1}>
        <Text size="2xl" fontWeight={700} color="gray.900">
          TTM Goals
        </Text>
      </HStack>

      <ResourceLoader h="full" w="full" emptyMessage="No available goals" isEmpty={isEmpty(goalInsights)}>
        <ResponsiveScrollView showScroll contentContainerStyle={{ flexGrow: 1 }}>
          <VStack flex={1} p={4} space={3} bg="gray.25">
            {goalInsights?.map(goal => {
              return (
                <Box key={`goal-[${goal.id}]`} rounded="lg" borderWidth={1} bg="white">
                  <HStack space={2} alignItems="center" px={2} minH={9} borderBottomWidth={1}>
                    <Box justifyContent="center" rounded="md" w={5} h={5} bg={isEmpty(goal.focusItems) ? 'gray.100' : 'primary.100'}>
                      <Text size="md" fontWeight={700} color="black" textAlign="center" lineHeight="xs">
                        {goal.id}
                      </Text>
                    </Box>
                    <Text size="xl" fontWeight={700} color="black" textTransform="uppercase" lineHeight="md" numberOfLines={1}>
                      {translate(goal.title, goal.translations)}
                    </Text>
                  </HStack>

                  <Box justifyContent="center" py={1.5} px={2.5} minH={10}>
                    {isEmpty(goal.focusItems) ? (
                      <Text size={{ md: 'md', xl: 'lg' }} fontWeight={500} color="gray.500" lineHeight="sm">
                        No goals set
                      </Text>
                    ) : (
                      <Text size={{ md: 'md', xl: 'lg' }} fontWeight={500} color="gray.900" lineHeight="sm">
                        {goal?.focusItems?.map(goalFocus => (goalFocus.description ?? '').replace('|', '\n')).join('\n')}
                      </Text>
                    )}
                  </Box>
                </Box>
              );
            })}
          </VStack>
        </ResponsiveScrollView>
      </ResourceLoader>

      <HStack space={2} alignItems="center" py={4} px={4} borderTopWidth={1}>
        <Spacer />

        <Button
          h={9}
          minH={0}
          minWidth={100}
          alternate
          outline
          onPress={() => onNavigateTo('OpsPlan')}
          startIcon={<TargetIcon size="md" />}
        >
          Ops Plan
        </Button>
      </HStack>
    </View>
  );
};
