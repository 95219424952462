import { useMemo, useState } from 'react';
import { Box, HStack, SectionList } from 'native-base';
import { chain, filter, first, isEmpty, last, map, range, sumBy } from 'lodash';
import moment from 'moment';

import { Text } from '@pimm/base';
import { ShiftDto } from '@pimm/services/lib/sms-workforce';
import { convertMinutesToHoursAndMinutes } from '@app/utils/date-formatter';
import EmployeeWeeklyItem, { EmployeeWeeklyItemProps } from './employee-weekly-item';
import { useScheduleEmployees } from '../context/schedule-employees.context';

type EmployeeWeeklySchedulesProps = {
  schedules?: ShiftDto[];
  startOfWeek: Date;
  searchKeyword?: string;
};

// TODO: Fix performance issue loading hundred of records
const EmployeeWeeklySchedules = ({ schedules = [], startOfWeek, searchKeyword }: EmployeeWeeklySchedulesProps) => {
  const { employees } = useScheduleEmployees();
  const [weekDates] = useState<Date[]>(range(7).map(day => moment(startOfWeek).add(day, 'day').toDate()));

  const sections = useMemo(() => {
    const weekDates = range(7).map(day => moment(startOfWeek).add(day, 'day').format('YYYY-MM-DD'));
    // expected result: { employeeId: { duration, shifts: range(7)[{ startTime, endTime, duration }] } }
    const shifts = chain(schedules)
      .sortBy(['startTime'])
      .groupBy(schedule => schedule.employee?.id)
      .values()
      .reduce((obj, arr) => {
        const employeeId = arr[0].employee?.id!;
        const shifts = weekDates.map(dt => {
          const shiftsForDay = filter(arr, shift => shift.startTime?.includes(dt)) as ShiftDto[];
          if (isEmpty(shiftsForDay)) return undefined;

          const startTime = first(shiftsForDay)?.startTime;
          const endTime = last(shiftsForDay)?.endTime;
          const totalDuration = shiftsForDay.reduce((sum, shift) => sum + (shift.durationInMinutes ?? 0), 0);
          const isImport = shiftsForDay.every(shift => shift?.isImport);
          const isMultipleSchedules = (shiftsForDay?.length ?? 0) > 1;

          return {
            startTime: moment(startTime?.slice(0, 19)).toDate(),
            endTime: moment(endTime?.slice(0, 19)).toDate(),
            duration: totalDuration,
            isImport: isImport,
            isMultipleSchedules: isMultipleSchedules,
          };
        });
        return { ...obj, [employeeId]: { duration: sumBy(shifts, 'duration'), shifts: shifts } };
      }, {})
      .value();
    // expected result: { title: 'Crew/Manager', data: { id, firstName, lastName, shifts }[] }[]
    const sections = chain(employees ?? [])
      .filter(({ firstName, lastName }) => {
        if (!searchKeyword || searchKeyword.trim().length === 0) return true;
        return [firstName, lastName].filter(Boolean).join(' ').toLowerCase().includes(searchKeyword.trim().toLowerCase());
      })
      .sortBy(['firstName', 'lastName'])
      .partition(({ title }) => title?.toLowerCase() !== 'crew')
      .value()
      .reduce((arr, employees, index) => {
        if (isEmpty(employees)) return arr;
        return [
          ...arr,
          {
            title: index ? 'Crew' : 'Managers',
            data: map(employees, employee => ({
              firstName: employee.firstName,
              lastName: employee.lastName,
              title: employee.title,
              id: employee.id,
              ...shifts[employee.id!],
            })),
          },
        ];
      }, []) as { title: string; data: EmployeeWeeklyItemProps[] }[];
    return sections;
  }, [employees, schedules, startOfWeek, searchKeyword]);

  return (
    <SectionList
      h="full"
      width="full"
      borderColor="gray.200"
      borderWidth={1}
      borderRadius="lg"
      extraData={[weekDates]}
      sections={sections}
      initialNumToRender={20}
      ItemSeparatorComponent={() => <Box h="1px" backgroundColor="gray.200" />}
      keyExtractor={item => item.id!}
      showsHorizontalScrollIndicator
      ListHeaderComponent={() => (
        <HStack position="sticky" top={0} w="full" alignItems="center" h={8} width={{ md: 1500, xl: 'full' }} bgColor="gray.25">
          <HStack
            alignItems="center"
            justifyContent="space-between"
            pl={{ md: 2, xl: 4 }}
            pr={{ md: 2, xl: 3 }}
            h="full"
            width={{ md: 200, xl: 260 }}
            borderRightWidth={1}
            borderColor="gray.300"
          >
            <Text size="sm" fontWeight={500} color="gray.600">
              Employee Name
            </Text>
            <Text size="sm" fontWeight={500} color="gray.600">
              Weekly Hrs
            </Text>
          </HStack>
          {weekDates.map((weekDate, index) => (
            <HStack
              key={index}
              flex={1}
              space={1.5}
              alignItems="center"
              justifyContent="space-between"
              px={1.5}
              h="full"
              borderRightWidth={1}
              borderColor="gray.300"
            >
              <Text size="md" fontWeight={600} color="gray.700">
                {moment(weekDate).format('ddd, MMM D')}
              </Text>
              <Text size="sm" fontWeight={500} color="gray.600">
                Daily Hrs
              </Text>
            </HStack>
          ))}
        </HStack>
      )}
      renderSectionHeader={({ section }) => {
        return (
          <HStack w="full" alignItems="center" h={8} width={{ md: 1500, xl: 'full' }} bgColor="gray.700">
            <HStack
              alignItems="center"
              justifyContent="space-between"
              pl={{ md: 2, xl: 4 }}
              pr={{ md: 2, xl: 3 }}
              h="full"
              width={{ md: 200, xl: 260 }}
              borderRightWidth={1}
              borderColor="gray.400"
            >
              <Text size="sm" fontWeight={600} color="white">
                {section.title}
              </Text>
              <Box
                flex={1}
                px={0.5}
                h={6}
                justifyContent="center"
                width="50px"
                maxWidth={50}
                borderRadius="md"
                backgroundColor="success.600"
              >
                <Text size="md" fontWeight={700} color="white" textAlign="center" numberOfLines={1} ellipsizeMode="tail" lineHeight="xs">
                  {convertMinutesToHoursAndMinutes(sumBy(section.data, 'duration') ?? 0)}
                </Text>
              </Box>
            </HStack>
            {weekDates.map((weekDate, index) => (
              <HStack
                key={index}
                flex={1}
                space={1.5}
                alignItems="center"
                justifyContent="flex-end"
                px={1.5}
                h="full"
                borderRightWidth={1}
                borderColor="gray.400"
              >
                <Box flex={1} px={0.5} h={6} maxWidth={50} justifyContent="center" borderRadius="md" backgroundColor="blue.500">
                  <Text size="md" fontWeight={700} color="white" textAlign="center" numberOfLines={1} ellipsizeMode="tail" lineHeight="xs">
                    {convertMinutesToHoursAndMinutes(sumBy(section.data, `shifts.${index}.duration`) ?? 0 ?? 0)}
                  </Text>
                </Box>
              </HStack>
            ))}
          </HStack>
        );
      }}
      removeClippedSubviews
      renderItem={({ item, index }) => {
        return <EmployeeWeeklyItem {...item} index={index} />;
      }}
      stickySectionHeadersEnabled={true}
      stickyHeaderIndices={[0]}
      scrollEnabled={true}
    />
  );
};

export default EmployeeWeeklySchedules;
