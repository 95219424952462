import { QueryKey, UseQueryResult, useQuery } from 'react-query';

import { GetWidgetData } from '@pimm/services/lib/sms-drivethru/services';
import { formatToISOString } from '@app/utils/date-formatter';

type GetWidgetDataParams = {
  date?: Date;
  siteId?: string;
};

export const useGetWidgetData = (
  query?: GetWidgetDataParams,
  queryKey?: string,
// TODO: need to revisit
// ): UseQueryResult<Awaited<ReturnType<typeof GetWidgetData>>> => {
): UseQueryResult<Awaited<any>> => {
  const timerData = useQuery({
    enabled: !!query?.date && !!query?.siteId,
    refetchIntervalInBackground: true,
    refetchOnMount: 'always',
    refetchInterval: () => {
      const timeNow = new Date();
      // Refetch the timerData every 10 minutes
      const nearestMinutes = timeNow.toNearestNextMinutes(10);
      return nearestMinutes.getTime() - timeNow.getTime();
    },
    queryKey: [queryKey ?? 'GetDriveThruWidgetData', query] as [QueryKey, GetWidgetDataParams],
    queryFn: ({ queryKey: [key, params] }) => GetWidgetData({ dateTime: formatToISOString(params.date!), siteId: params.siteId }),
  });

  return timerData;
};
