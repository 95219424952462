import _ from 'lodash';
import {
  HolderResult,
  HolderStatusEnum,
} from '@pimm/services/lib/coke-freestyle';
import { ChunkHolders, HolderIssue, HolderVariant } from './types';

const REGEX_OTHER_CATRIDGE = /hfcs|nns|water|carb|ice/;
const REGEX_ROWS = [
  '^S+[1-9]$|^S+[1][0-2]$',
  '^S+[1][3-9]$|^S+[2][0-4]$',
  '^A+[1-9]$|^A+[1][0-2]$',
];

const CRITICAL_LEVEL = 10;
const WARNING_LEVEL = 30;

const sortedHolders = (holders: HolderResult[]) => {
  // Sort it by holderName
  const sortedHolders: HolderResult[] = holders.sort((prev, next) => {
    const prevNum = Number(prev.holderName?.replace(/\D/g, '')) || 0;
    const nextNum = Number(next.holderName?.replace(/\D/g, '')) || 0;
    return prevNum - nextNum;
  });
  // Distinct by the following rows S1 - S12 | S13 - S24 | A1 - A12
  const orderedBy: any = _.reduce(
    REGEX_ROWS,
    (rows, x) => {
      const chunk: any = _.reduce(
        sortedHolders,
        (arr: HolderResult[], holder) => {
          if (new RegExp(x).test(holder.holderName as string))
            return arr.concat(holder);
          return arr;
        },
        [],
      );
      return rows.concat(chunk);
    },
    [],
  );
  return orderedBy;
};

export const chunkHolders = (holders: HolderResult[]): ChunkHolders => {
  const chunks = _.reduce(
    holders,
    (chunks: ChunkHolders, holder) => {
      // Remove available = false and issueType = NOT_AVAILABLE
      if (!holder.available && holder.issueType === 'NOT_AVAILABLE')
        return chunks;
      if (!REGEX_OTHER_CATRIDGE.test(holder.holderName || '')) {
        return [chunks[0].concat(holder), chunks[1]];
      }
      return [chunks[0], chunks[1].concat(holder)];
    },
    [[], []],
  );
  return [sortedHolders(chunks[0]), chunks[1]];
};

export const distinctHolders = (
  holders: HolderResult[],
  columns = 12,
): {
  chunks: ChunkHolders;
  partition: HolderResult[][];
} => {
  const chunks = chunkHolders(holders || []);
  const copyHolders: HolderResult[] = [...(chunks[0] || [])];
  const partition: HolderResult[][] = [];
  // manually create chunk of hstack
  while (copyHolders.length > 0) {
    const chunk: HolderResult[] = [];
    let cells = 0;
    while (cells < columns) {
      const index = _.findIndex(
        copyHolders,
        ({ doubleCartridge }) => cells + (doubleCartridge ? 2 : 1) <= columns,
      );
      if (index === -1) cells = columns;
      else {
        cells += copyHolders[index]?.doubleCartridge ? 2 : 1;
        chunk.push(copyHolders[index]);
        copyHolders.splice(index, 1);
      }
    }
    partition.push(chunk);
  }
  return {
    chunks,
    partition,
  };
};

export const dispenserName = type => {
  if (type) {
    if (Number(type) === 9000) return 'Dining Room';
    if (Number(type) === 8000) return 'Pickup Window';
  }
  return 'Unknown';
};

export const gaugeLevelVariant = level => {
  if (level <= CRITICAL_LEVEL) return 'error';
  if (level < WARNING_LEVEL) return 'warning';
  return 'success';
};

export const typeOfIssue = (issueType: string, level: number): HolderIssue => {
  const variant = gaugeLevelVariant(level);

  if (/unavailable|not_available/.test(issueType || '')) return 'Not Available';
  if (/purge trouble|purge_trouble/.test(issueType || ''))
    return 'Purge Trouble';
  if (/prime trouble|prime_trouble/.test(issueType || ''))
    return 'Prime Trouble';
  if (/sold out|sold_out/.test(issueType || '')) {
    return 'Sold Out';
  }
  if (variant === 'error' || variant === 'warning') {
    return 'Warning';
  }
  return 'Available';
};

export const attentionText = (holderStatus?: string | null) => {
  if (!holderStatus) return '';
  switch (holderStatus) {
    case 'Empty':
      return 'Sold Out';
    case 'Normal':
      return '';
    default:
      return holderStatus;
  }
};
