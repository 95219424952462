import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import _, { filter, find, map, some } from 'lodash';

import { EquipmentInstancesDto, PerformanceMetricsDto, SensorConfigDto, SensorSummaryDto } from '@pimm/services/lib/store-equipment';
import { EquipmentSummary, useGetDisplayGroups, useGetEquipmentSummary, useGetHardwareConfig } from '../hooks';

export interface SensorEquipment {
  siteID?: string;
  equipment?: EquipmentInstancesDto;
  door?: SensorSummaryDto;
  performance?: PerformanceMetricsDto;
  hardwares?: {
    door?: SensorConfigDto;
    temperature?: SensorConfigDto;
    external?: SensorConfigDto;
  };
  temperature?: SensorSummaryDto;
}

export interface SensorGroup {
  displayGroup: string;
  equipment: SensorEquipment[];
}

export const EquipmentSummaryContext = createContext<SensorGroup[]>(undefined!);

export type EquipmentSummaryProviderProps = PropsWithChildren & {
  displayGroups: ReturnType<typeof useGetDisplayGroups>;
  equipmentSummary: ReturnType<typeof useGetEquipmentSummary>;
  hardwareSummary: ReturnType<typeof useGetHardwareConfig>;
};

// This context is responsible for combining groups of instances and finding each relevant sensor.
export const EquipmentSummaryProvider = ({ children, displayGroups, equipmentSummary, hardwareSummary }: EquipmentSummaryProviderProps) => {
  const summary = useMemo(() => {
    const _sensorGroups: SensorGroup[] = map(displayGroups.data, group => {
      const equipment = map(group.equipment, _equipment => {
        const tempInstance = _equipment.TemperatureMetrics?.Temperature;

        const temperature = find<EquipmentSummary>(equipmentSummary.data, ({ sensors }) =>
          some(sensors, _ => _.TemperatureInstanceId === tempInstance?.InstanceId),
        );

        const tempSensor = temperature?.sensors.find(_ => !!_.TemperatureInstanceId);
        const doorSensor = temperature?.sensors.find(_ => !!_.DoorInstanceId);

        const hardwares = filter(hardwareSummary.data?.Sensors, sensor => sensor.EquipmentId === _equipment?.EquipmentId);
        const tempHardwareSensor = hardwares?.find(sensor => !sensor.Label?.toLowerCase().includes('door'));
        const doorHardwareSensor = hardwares?.find(sensor => sensor.Label?.toLowerCase().includes('door'));

        // find the external sensor
        let externalSensor = find(hardwareSummary.data?.Sensors, hardware => {
          return find(
            hardwares,
            sensor => hardware.AdditionalProperties?.External && hardware.AdditionalProperties?.LinkedTsu === sensor.Label,
          );
        });

        // reverse finding the external sensor
        if (!externalSensor) {
          hardwares.forEach(_sensor => {
            const matchedHardware = find(
              hardwareSummary?.data?.Sensors,
              __sensor => __sensor?.AdditionalProperties?.External && __sensor.Label === _sensor.Label,
            );
            if (matchedHardware) {
              externalSensor = find(
                hardwareSummary?.data?.Sensors,
                ___sensor => matchedHardware.AdditionalProperties?.LinkedTsu === ___sensor.Label,
              );
              if (externalSensor) {
                return;
              }
            }
          });
        }

        return {
          siteID: hardwareSummary.data?.SiteId,
          equipment: _equipment,
          door: doorSensor,
          hardwares: {
            temperature: tempHardwareSensor,
            door: doorHardwareSensor,
            external: externalSensor,
          },
          performance: temperature?.performance,
          temperature: tempSensor,
        };
      });

      const sensorGroup: SensorGroup = {
        displayGroup: group.displayGroup,
        equipment: equipment,
      };

      return sensorGroup;
    });
    return _sensorGroups;
  }, [displayGroups.data, equipmentSummary.data, hardwareSummary.data]);

  return <EquipmentSummaryContext.Provider value={summary}>{children}</EquipmentSummaryContext.Provider>;
};

export const useEquipmentSummary = () => {
  // get the context
  const context = useContext(EquipmentSummaryContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useEquipmentSummary was used outside of its Provider');
  }
  return context;
};
