import { Text } from '@pimm/base';
import { PositionBox } from './position-box';

type PositionChipProps = {
  _text?: React.ComponentProps<typeof Text>;
  intensity?: number;
  positionId: string;
  title?: string;
};

export const PositionChip = ({ _text, intensity, positionId, title }: PositionChipProps) => {
  return (
    <PositionBox positionId={positionId} rounded="full" intensity={intensity}>
      <Text size="md" fontWeight={500} color="gray.900" px={2} numberOfLines={1} ellipsizeMode="tail" {..._text}>
        {title ?? ''}
      </Text>
    </PositionBox>
  );
};
