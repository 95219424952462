import { QueryKey, UseQueryResult, useQuery } from 'react-query';

import { GetStoreEvents } from '@pimm/services/lib/store-equipment/services';
import { EventGroup } from '@pimm/services/lib/store-equipment';
import { formatToISOString } from '@app/utils/date-formatter';

type GetStoreEventsParams = {
  siteId?: string;
  equipmentId?: string;
  startDate?: Date;
  endDate?: Date;
  eventGroup?: EventGroup;
};

export const useGetStoreEvents = (
  query?: GetStoreEventsParams,
  queryKey?: string,
): UseQueryResult<Awaited<ReturnType<typeof GetStoreEvents>>> => {
  const storeEvents = useQuery({
    enabled: !!query?.siteId && !!query?.equipmentId && !!query?.startDate && !!query?.endDate,
    queryKey: [queryKey ?? 'GetStoreEvents', query] as [QueryKey, GetStoreEventsParams],
    queryFn: ({ queryKey: [key, params] }) =>
      GetStoreEvents(params.siteId!, {
        EquipmentId: params.equipmentId,
        StartTime: formatToISOString(params.startDate!),
        EndTime: formatToISOString(params.endDate!),
        EventGroup: params.eventGroup ?? EventGroup.All,
      }),
  });

  return storeEvents;
};
