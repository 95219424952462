import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// const passwordValidation = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

export const FormPasswordUpdateSchema = yup.object({
  currentPassword: yup.string().required('Please provide your current password').default(''),
  newPassword: yup
    .string()
    .required('Please provide your new password')
    // .matches(passwordValidation, 'Your new password format is not valid')
    .notOneOf([yup.ref('currentPassword')], 'New password must be different from the current password')
    .min(8, 'Password must contain 8 or more characters')
    .matches(/(?=.*[a-z])/, 'Password must contain at least 1 lower case letter')
    .matches(/(?=.*[A-Z])/, 'Password must contain at least 1 uppercase letter')
    .matches(/(?=.*\d)/, 'Password must contain at least 1 number')
    .matches(/(?=.*\W)/, 'Password must contain at least 1 special character')
    .default(''),
  confirmPassword: yup
    .string()
    .required('Please confirm your new password')
    .oneOf([yup.ref('newPassword')], 'Password do not match')
    .default(''),
});

export const useFormPasswordUpdate = () => {
  return useForm({ resolver: yupResolver(FormPasswordUpdateSchema) });
};
