import { Dispatch, SetStateAction, useState } from 'react';
import { QueryKey, UseQueryResult, useQuery } from 'react-query';

import { GetGoals } from '@pimm/services/lib/sms-positioning/services';
import { GetGoalsQueryParams } from '@pimm/services/lib/sms-positioning';

export const useGetGoals = (props?: {
  queryKey?: string;
  query?: GetGoalsQueryParams;
}): [UseQueryResult<Awaited<ReturnType<typeof GetGoals>>>, Dispatch<SetStateAction<GetGoalsQueryParams>>] => {
  const [queryKey] = useState<string>(props?.queryKey ?? 'GetGoals');
  const [query, setQuery] = useState<GetGoalsQueryParams>({ ...props?.query });

  const goals = useQuery({
    enabled: !!query.brandId && !!query.customerId,
    queryKey: [queryKey, query] as [QueryKey, GetGoalsQueryParams],
    queryFn: ({ queryKey }) => GetGoals(queryKey[1]),
  });

  return [goals, setQuery];
};
