import { useEffect, useMemo } from 'react';
import { Box, HStack, VStack, useMediaQuery } from 'native-base';
import { chain, find, flatMap, get, isEmpty, orderBy, size } from 'lodash';

import { Category } from '@pimm/services/lib/sms-documents';
import { DocumentSections, DocumentViewer, useStoreDocuments } from '@app/features/store-documents';
import { ResourceLoader } from '@app/components/shared';
import { CollapsibleCategories } from './collapsible-categories';
import { CategoryExtended, CategorySelectorPopover } from './popover-category-selector';

type DocumentListingProps = {
  filterBy?: { keyword?: string };
};

export const DocumentListing = ({ filterBy }: DocumentListingProps) => {
  const [isSmallScreen] = useMediaQuery({ maxWidth: 1200 });
  const { categoryFocus, cultureFocus, documentFocus, ...storeDocuments } = useStoreDocuments();
  const isLoading = storeDocuments.categories.isLoading;

  const categories: CategoryExtended[] = useMemo(() => {
    // Order category and documents by sequence
    const result = chain(storeDocuments.categories.data)
      .map(category => {
        const children = chain(category.children)
          .orderBy(['seq', 'asc'])
          .map(child => ({ ...child, documents: orderBy(child.documents, ['seq', 'asc']) }))
          .value();
        const count = size(flatMap(children, child => child.documents));
        return { ...category, count: count, children: children };
      })
      .orderBy(['seq', 'asc'])
      .value();
    return result;
  }, [storeDocuments.categories.data]);

  const handleChangeCategory = (category: Category) => {
    storeDocuments.changeCategory(find(categories, ['id', category.id]) as Category);
  };

  useEffect(() => {
    if (categories?.length && !categoryFocus) {
      if (!documentFocus) {
        // This is to make sure to get the first document from category with a linked documents
        const document = categories?.reduce((_document: Document | undefined, category) => {
          return _document ?? get(category, 'children.[0].documents[0]');
        }, undefined);
        if (document) storeDocuments.changeDocument(document);
      }
      storeDocuments.changeCategory(categories[0]);
    }
  }, [categories, categoryFocus, documentFocus]);

  return (
    <HStack w="full" h="full">
      {/* Category Selector */}
      <VStack display={{ md: 'none', xl: 'block' }} flex={1} maxWidth="1/5" py={4} px={{ md: 3, xl: 4 }} h="full" borderRightWidth={1}>
        <ResourceLoader h="full" isEmpty={!isLoading && isEmpty(storeDocuments.categories.data)} isLoading={isLoading}>
          <CollapsibleCategories
            title="Categories"
            hideToggle
            selectedId={categoryFocus?.id}
            items={categories}
            onPressItem={handleChangeCategory}
          />
        </ResourceLoader>
      </VStack>

      {/* Document Selector */}
      <Box flex={1} maxWidth={{ md: 300, xl: '1/5' }} borderRightWidth={1} borderColor="gray.200">
        <ResourceLoader h="full" isEmpty={!isLoading && isEmpty(storeDocuments.categories.data)} isLoading={isLoading}>
          {isSmallScreen && (
            <>
              <Box py={2} px={3} bg="light.50">
                <CategorySelectorPopover onChangeCategory={handleChangeCategory} items={categories} selectedCategory={categoryFocus} />
              </Box>
            </>
          )}

          <DocumentSections
            category={categoryFocus}
            documentId={documentFocus?.id}
            keyword={filterBy?.keyword}
            onPressDocument={storeDocuments.changeDocument}
          />
        </ResourceLoader>
      </Box>

      {/* Document Preview  */}
      <VStack flex={1} h="full">
        {!!documentFocus && <DocumentViewer key={documentFocus.id} culture={cultureFocus} document={documentFocus} />}
      </VStack>
    </HStack>
  );
};
