import { useBoolean } from 'usehooks-ts';
import { Popover, Box, FlatList, HStack, Pressable, Divider } from 'native-base';

import { useAppLocale } from '@pimm/common';
import { PressableSelect, Text } from '@pimm/base';
import { Category } from '@pimm/services/lib/sms-documents';

export interface CategoryExtended extends Category {
  count: number;
}

type CategorySelectorPopoverProps = {
  onChangeCategory?: (category: Category) => void;
  items: CategoryExtended[];
  selectedCategory?: Category;
};

export const CategorySelectorPopover = ({ items, selectedCategory, ...props }: CategorySelectorPopoverProps) => {
  const { translate } = useAppLocale();
  const isOpen = useBoolean();

  const handlePressSelect = (category: Category) => () => {
    if (props.onChangeCategory) props.onChangeCategory(category);
    isOpen.setFalse();
  };

  // TODO: Fix PressableSelect forwardRef
  return (
    <Popover
      isKeyboardDismissable
      isOpen={isOpen.value}
      onClose={isOpen.setFalse}
      onOpen={isOpen.setTrue}
      placement="right"
      trigger={triggerProps => (
        <PressableSelect
          minHeight={9}
          bg="white"
          disabled={items ? items.length < 1 : false}
          placeholder="Please select"
          onPress={isOpen.setTrue}
          {...triggerProps}
        >
          {selectedCategory ? (
            <HStack flex={1} alignItems="center" justifyContent="space-between" pl={1}>
              <Text size="md" color="black" fontWeight={600}>
                {selectedCategory.title}
              </Text>
              <Box
                alignItems="center"
                justifyContent="center"
                bgColor="white"
                minW={6}
                height={6}
                rounded="md"
                mr={1}
                px={1}
                borderWidth="1"
                alignSelf="center"
              >
                <Text size="sm" color="black" fontWeight={500}>
                  {`${selectedCategory['count'] ?? 0}`}
                </Text>
              </Box>
            </HStack>
          ) : undefined}
        </PressableSelect>
      )}
    >
      {isOpen.value && (
        <Popover.Content accessibilityLabel="Select Category" zIndex={100} w="270px" borderColor="gray.100" shadow={5}>
          <Popover.Arrow zIndex={0} borderColor="gray.100" />
          <Popover.Body p={0}>
            <FlatList
              data={items}
              maxHeight={300}
              backgroundColor="white"
              keyExtractor={(item, index) => item.id + index.toString()}
              ItemSeparatorComponent={() => <Divider backgroundColor="gray.200" />}
              renderItem={({ item: category }) => {
                const isActive = selectedCategory && selectedCategory.id === category.id;

                return (
                  <Pressable onPress={handlePressSelect(category)}>
                    <HStack
                      justifyContent="space-between"
                      alignItems="center"
                      minHeight={10}
                      w="full"
                      py={0.5}
                      px={3}
                      backgroundColor={isActive ? 'gray.25' : undefined}
                    >
                      <Text size="md" fontWeight={isActive ? 600 : 500} color={isActive ? 'black' : 'gray.700'} lineHeight="sm">
                        {translate(category.title, category?.translations)}
                      </Text>
                      <Box
                        alignItems="center"
                        justifyContent="center"
                        bgColor="white"
                        minW={6}
                        height={6}
                        rounded="md"
                        px={1}
                        borderWidth="1"
                        alignSelf="center"
                      >
                        <Text size="sm" color="black" fontWeight={500}>
                          {`${category.count ?? 0}`}
                        </Text>
                      </Box>
                    </HStack>
                  </Pressable>
                );
              }}
            />
          </Popover.Body>
        </Popover.Content>
      )}
    </Popover>
  );
};
