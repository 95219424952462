import { useMemo, useState } from 'react';
import { Box, HStack, useTheme, View, VStack } from 'native-base';
import { LayoutChangeEvent, LayoutRectangle } from 'react-native';
import { flatten, map, max, min, range } from 'lodash';
import { VictoryAxis, VictoryChart, VictoryLabel, VictoryLine, VictoryScatter, VictoryTooltip } from 'victory';
import moment from 'moment';

import { ButtonGroup, Text } from '@pimm/base';
import { formatToISOString } from '@app/utils/date-formatter';
import { ResourceLoader } from '@app/components/shared';
import { Daypart, useSiteTime } from '@app/features/store-core';
import { useGetWeeklyData } from '../hooks';
import { useQuerySettingsAndGoals } from '../context';

type DataSet = { x: string; y?: number | null };

type WeeklyPerformanceProps = {
  daypart: Daypart;
};

export const WeeklyPerformance = ({ daypart }: WeeklyPerformanceProps) => {
  const { colors } = useTheme();
  const siteTime = useSiteTime();
  const settingsAndGoals = useQuerySettingsAndGoals();
  const [tabFocus, setTabFocus] = useState<'current' | 'last-7-days'>('current');
  const [layout, setLayout] = useState<LayoutRectangle>();
  const [tickValues] = useState<number[]>([0.2, 0.4, 0.6, 0.8, 1, 1.2]);

  const weeklyData = useGetWeeklyData({ currentDateTime: daypart.startTime, siteId: settingsAndGoals.data?.siteId });

  const { chartData, dates } = useMemo(() => {
    const week = siteTime.toStartEndOfWeekPart(daypart.startTime);
    const isCurrent = tabFocus === 'current';
    const startTime = isCurrent ? week.startTime : moment(daypart.startTime).add(-6, 'day').toDate();
    const values = flatten([weeklyData.data?.currentWeekData, weeklyData.data?.last7DaysData]).filter(_ => !!_);
    const numberOfTicks = 6;

    const averageTimes: DataSet[] = [];
    const totalCars: DataSet[] = [];
    const targetTimes: DataSet[] = [];
    const dates: string[] = [];

    range(7).forEach(d => {
      const dt = moment(startTime).add(d, 'day');
      const data = values.find(_ => _?.date?.includes(formatToISOString(dt.toDate(), true)));
      const isoDate = dt.format('YYYY-MM-DD');

      dates.push(isoDate);

      // Total Times
      averageTimes.push({
        x: isoDate,
        y: data?.totalTime ?? null,
      });
      // Total Cars
      totalCars.push({
        x: isoDate,
        y: data?.totalCars ?? null,
      });
      // Target Time
      targetTimes.push({
        x: isoDate,
        y: data?.targetTime ?? null,
      });
    });

    const result = [
      { label: 'Lane Total Average Times  (seconds)', color: colors.blueLight[500], datasets: averageTimes.filter(_ => !!_.y) },
      { label: '# Orders', color: colors.orangeDark[500], datasets: totalCars.filter(_ => !!_.y), symbol: 'square' },
      { label: 'Target', color: colors.green[500], datasets: targetTimes.filter(_ => !!_.y) },
    ].map(axis => {
      const maxAverageTime = max(axis.datasets.map(_ => _.y)) ?? 0;

      const maxDomain = Math.ceil(maxAverageTime / 10) * 10;
      const step = (maxDomain - 0) / (numberOfTicks - 1);
      const tickValues = range(numberOfTicks).map(i => 0 + i * step);

      return {
        label: axis.label,
        color: axis.color,
        datasets: map(axis.datasets, data => ({ ...data, symbol: axis.symbol })),
        domain: [0, Math.ceil(maxAverageTime / 10) * 10],
        tickValues: tickValues,
      };
    });
    return {
      chartData: result,
      dates: dates,
    };
  }, [daypart.startTime, siteTime.firstDayOfWeek, tabFocus, weeklyData.data]);

  const handleChangeLayout = ({ nativeEvent }: LayoutChangeEvent) => {
    if (!layout) setLayout(nativeEvent.layout);
  };

  return (
    <VStack rounded="xl" py={3} px={4} w="full" h="full" borderWidth={1} bg="white">
      <HStack justifyContent="space-between">
        <Box>
          <Text size="xl" fontWeight={700} color="gray.900" numberOfLines={2} lineHeight="xl">
            Weekly Trends by Daypart
          </Text>
          <Text size="md" fontWeight={500} color="gray.600" numberOfLines={2} lineHeight="xs">
            Track Daypart Lane Total average order times over the selected period
          </Text>
        </Box>

        <ButtonGroup value={tabFocus} onChange={setTabFocus}>
          <ButtonGroup.Item value="current">Current week</ButtonGroup.Item>
          <ButtonGroup.Item value="last-7-days">Last 7 Days</ButtonGroup.Item>
        </ButtonGroup>
      </HStack>

      <View flex={1} onLayout={handleChangeLayout}>
        <ResourceLoader h="full" isLoading={weeklyData.isLoading}>
          {!!layout?.width && (
            <VictoryChart
              domain={{ x: [0.5, 7.5], y: [min(tickValues) ?? 0, max(tickValues) ?? 0] }}
              padding={{ top: 40, left: 60, bottom: 40, right: 60 }}
              width={layout?.width}
              height={400}
            >
              <VictoryAxis
                scale="linear"
                tickValues={dates}
                tickFormat={_ => moment(_).format('ddd')}
                style={{
                  axisLabel: { padding: 40, fontFamily: "'Inter', sans-serif", fontSize: 12, fontWeight: 600, fill: colors.gray[600] },
                  axis: { stroke: colors.gray[100] },
                  tickLabels: { fontFamily: "'Inter', sans-serif", fontSize: 12, padding: 10, fill: colors.gray[600] },
                }}
              />

              {map(chartData, (_axis, i) => (
                <VictoryAxis
                  key={`axis[${i}]`}
                  dependentAxis
                  crossAxis
                  scale="linear"
                  label={_axis.label}
                  orientation={i === 0 ? 'left' : 'right'}
                  style={{
                    axisLabel: {
                      display: i === 2 ? 'none' : 'block',
                      padding: 40,
                      fontFamily: "'Inter', sans-serif",
                      fontSize: 12,
                      fontWeight: 500,
                      fill: i === 1 ? _axis.color : colors.gray[600],
                    },
                    axis: { stroke: 'none' },
                    tickLabels: {
                      display: i === 2 ? 'none' : 'block',
                      fontFamily: "'Inter', sans-serif",
                      fontSize: 12,
                      padding: 10,
                      fill: i === 1 ? _axis.color : colors.gray[600],
                    },
                    grid: { stroke: colors.gray[100], strokeWidth: 1 },
                  }}
                  domain={_axis.domain as [number, number]}
                  tickValues={tickValues}
                  tickFormat={t => {
                    if (i === 2) return Math.ceil(t * chartData[0].domain[1]);
                    return Math.ceil(t * _axis.domain[1]);
                  }}
                />
              ))}

              {map(chartData, (_line, i) => (
                <VictoryLine
                  key={`line[${i}]`}
                  data={_line.datasets}
                  style={{ data: { stroke: _line.color, strokeWidth: 2 } }}
                  y={_ => {
                    if (i === 2) return _.y / (max([chartData[0].domain[1], _line.domain[1]]) ?? 0);
                    return _.y / _line.domain[1];
                  }}
                />
              ))}

              {map(chartData, (_scatter, i) => (
                <VictoryScatter
                  key={'point' + i}
                  data={_scatter.datasets}
                  style={{
                    data: { fill: _scatter.color, strokeWidth: 2 },
                  }}
                  y={_ => {
                    if (i === 2) return _.y / (max([chartData[0].domain[1], _scatter.domain[1]]) ?? 0);
                    return _.y / _scatter.domain[1];
                  }}
                  size={5}
                  labels={({ datum }) => [`${datum?.symbol === 'square' ? '\u25A0 ' : '\u25CF '}`, `${datum.x}: ${datum.y}`]}
                  labelComponent={
                    <VictoryTooltip
                      flyoutStyle={{ stroke: _scatter.color, strokeWidth: 1.2, fill: 'white' }}
                      flyoutPadding={{ top: 0, bottom: 2, left: 15, right: 15 }}
                      labelComponent={
                        <VictoryLabel
                          inline
                          style={[
                            {
                              width: 10,
                              fontFamily: 'Inter, sans-serif',
                              fontWeight: 700,
                              fontSize: 19,
                              fill: _scatter.color,
                            },
                            {
                              alignItems: 'center',
                              fontFamily: 'Inter, sans-serif',
                              fontWeight: 700,
                              fontSize: 12,
                              backgroundColor: '#cc0000',
                            },
                          ]}
                        />
                      }
                    />
                  }
                />
              ))}
            </VictoryChart>
          )}

          <HStack space={5} alignItems="center" justifyContent="center" pb={2}>
            {map(
              [
                { color: colors.blueLight[500], label: 'Lane Total Average' },
                { color: colors.green[500], label: 'Lane Total Goal' },
                { color: colors.orangeDark[500], label: 'Total Orders', symbol: 'square' },
              ],
              (_legend, index) => {
                return (
                  <HStack key={index} space={1.5} alignItems="center">
                    <Box rounded={_legend.symbol === 'square' ? 'none' : 'full'} size={3} bg={_legend.color} />
                    <Text size="md" fontWeight={500} color="gray.600" numberOfLines={1} lineHeight="xs">
                      {_legend.label}
                    </Text>
                  </HStack>
                );
              },
            )}
          </HStack>
        </ResourceLoader>
      </View>
    </VStack>
  );
};
