import { memo } from 'react';
import { Box, FlatList, HStack } from 'native-base';

import { Text } from '@pimm/base';
import { ShiftDto } from '@pimm/services/lib/sms-workforce';
import { EmployeeSchedule } from './employee-schedule';

type TableSchedulesProps = Pick<React.ComponentProps<typeof EmployeeSchedule>, 'onDelete' | 'onEdit'> & {
  isDisabled?: boolean;
  schedules?: ShiftDto[];
};

const EmployeeDailyList = ({ isDisabled, schedules, ...props }: TableSchedulesProps) => {
  return (
    <FlatList
      h="full"
      width="full"
      data={schedules ?? []}
      borderColor="gray.200"
      borderWidth={1}
      borderRadius="lg"
      contentContainerStyle={{ flexGrow: 1 }}
      extraData={[isDisabled]}
      initialNumToRender={25}
      stickyHeaderIndices={[0]}
      scrollEnabled={true}
      ListHeaderComponent={() => (
        <HStack height={10} backgroundColor="gray.50" borderBottomWidth={1}>
          <Box flex={1} h="full" pl={4} pr={2} justifyContent="center" borderRightWidth={1}>
            <Text size="md" fontWeight={500} color="gray.600" lineHeight="xs" textAlign="center">
              Name
            </Text>
          </Box>
          <Box flex={1} h="full" px={2} maxW={144} justifyContent="center" borderRightWidth={1}>
            <Text size="md" fontWeight={500} color="gray.600" lineHeight="xs" textAlign="center">
              Shift Start
            </Text>
          </Box>
          <Box flex={1} h="full" px={2} maxW={144} justifyContent="center" borderRightWidth={1}>
            <Text size="md" fontWeight={500} color="gray.600" lineHeight="xs" textAlign="center">
              Shift End
            </Text>
          </Box>
          <Box flex={1} h="full" px={2} maxW={20} justifyContent="center" borderRightWidth={1}>
            <Text size="md" fontWeight={500} color="gray.600" lineHeight="xs" textAlign="center">
              Overnight
            </Text>
          </Box>
          <Box flex={1} h="full" px={2} maxW={90} alignItems="center" justifyContent="center" borderRightWidth={1}>
            <Text size="md" fontWeight={500} color="gray.600" lineHeight="xs" textAlign="center">
              Total
            </Text>
          </Box>
          {!isDisabled && <Box flex={1} h="full" maxW={70} />}
        </HStack>
      )}
      renderItem={({ item: employee }) => {
        const isMultipleSchedules = schedules && schedules.filter(s => s.employee?.id === employee?.employee?.id).length > 1;
        
        return (
          <EmployeeSchedule
            employee={employee}
            isMultipleSchedules={isMultipleSchedules}
            isDisabled={isDisabled}
            onEdit={props.onEdit}
            onDelete={props.onDelete}
          />
        );
      }}
    />
  );
};

export default memo(EmployeeDailyList);
