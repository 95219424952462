import { Dispatch, SetStateAction, useState } from 'react';
import { QueryKey, UseQueryResult, useQuery } from 'react-query';
import moment from 'moment';

import { GetPositionGuideData } from '@pimm/services/lib/sms-workforce/services';
import { GetPositionGuideDataQueryParams } from '@pimm/services/lib/sms-workforce';
import { useSiteTime } from '@app/features/store-core';

type GetPositionGuideDataLiveReturn = UseQueryResult<Awaited<ReturnType<typeof GetPositionGuideData>>>;

export const GetPositionGuideDataLiveKey = 'GetPositionGuideDataLive';

export const useGetPositionGuideDataLive = (props?: {
  queryKey?: string;
  query?: GetPositionGuideDataQueryParams;
}): [GetPositionGuideDataLiveReturn, Dispatch<SetStateAction<GetPositionGuideDataQueryParams>>] => {
  const siteTime = useSiteTime();
  const [query, setQuery] = useState<GetPositionGuideDataQueryParams>({ ...props?.query });

  const result = useQuery({
    cacheTime: 0,
    enabled: !!query.brandId && !!query.customerId && !!query?.siteId,
    refetchIntervalInBackground: true,
    refetchOnMount: 'always',
    refetchInterval: () => {
      const timeNow = new Date();
      // Refetch the live positions using the nearest quarter minutes (15, 30, 45 or 0)
      const nearestQuarterHour = timeNow.toNearestQuarterHour();
      return nearestQuarterHour.getTime() - timeNow.getTime();
    },
    queryKey: [GetPositionGuideDataLiveKey, query] as [QueryKey, GetPositionGuideDataQueryParams],
    queryFn: async ({ queryKey: [key, params] }) => {
      // Get the current dayBlock startTime
      const startTime = siteTime.toDayBlockNow().startTime;
      const startTimeISO = moment(startTime).format().slice(0, 19);
      const todayISO = moment(siteTime.today()).format().slice(0, 19);
      const positionGuide = await GetPositionGuideData({
        brandId: params.brandId,
        customerId: params.customerId,
        siteId: params.siteId,
        marketId: params.marketId,
        salesVolumeProfileId: params.salesVolumeProfileId,
        blockStartDateTime: startTimeISO,
        currentDateTime: todayISO,
      });

      return positionGuide;
    },
  });
  return [result, setQuery];
};
