import { useState } from 'react';
import { Box, HStack, ScrollView, VStack, useTheme } from 'native-base';
import { filter, flatMap, map, startCase, toLower } from 'lodash';

import { CircularProgress, Text } from '@pimm/base';
import { LearningPathCard } from './learning-path-card';
import { EmpLearningPathStatusEnum, HubStatDto, LearningPathStatDto } from '@pimm/services/lib/sms-workforce';

type LearningHubPreviewProps = {
  hubStat: HubStatDto;
};

export const LearningHubPreview = ({ hubStat }: LearningHubPreviewProps) => {
  const { colors } = useTheme();
  // const [activities] = useState(flatMap(employee.paths, _ => _.activities));
  const countLearningPath = hubStat.learningPathStats?.length ?? 0;
  const completed = filter<LearningPathStatDto>(
    hubStat.learningPathStats,
    _ => _.learningStatus === EmpLearningPathStatusEnum.Complete,
  ).length;

  return (
    <ScrollView p={4} pt={3}>
      <HStack alignItems="center" justifyContent="space-between" mb={3}>
        <Box>
          <Text size="3xl" fontWeight={600} color="black" lineHeight="md">
            {startCase(toLower([hubStat.firstName, hubStat.lastName].filter(Boolean).join(' ')))}
          </Text>
          <Text size={hubStat.email ? 'lg' : 'md'} fontWeight={400} color={hubStat.email ? 'gray.600' : 'gray.500'} lineHeight="xs">
            {hubStat.email ?? 'Email not available'}
          </Text>
        </Box>

        <CircularProgress
          _text={{ size: 'sm', decimalScale: 1, suffix: '%' }}
          activeStrokeColor={colors.blueLight[500]}
          inActiveStrokeColor={colors.gray[200]}
          radius={25}
          strokeWidth={10}
          value={Math.round((countLearningPath ? completed / countLearningPath : 0) * 100)}
        />
      </HStack>

      <VStack space={3}>
        {map(hubStat.learningPathStats, _learningPath => {
          return <LearningPathCard key={`${hubStat.id}.${_learningPath.learningPathId}`} learningPath={_learningPath} />;
        })}
      </VStack>
    </ScrollView>
  );
};
