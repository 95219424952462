import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export const FormPinSetupSchema = yup.object({
  currentPassword: yup.string().required(),
  pin: yup.string().required(),
});

export const useFormPinSetup = () => {
  return useForm({ resolver: yupResolver(FormPinSetupSchema) });
};
