import { ColorValue } from 'react-native';
import { useTranslation } from 'react-i18next';
import { Box, IBoxProps, Spinner, useTheme } from 'native-base';
import FeatherIcon from 'react-native-vector-icons/Feather';

import { Text, TextProps } from '@pimm/base';

type ResourceLoaderProps = IBoxProps & {
  _text?: TextProps;
  children?: React.ReactNode;
  color?: ColorValue;
  isEmpty?: boolean;
  isLoading?: boolean;
  message?: string;
  emptyMessage?: React.ReactNode;
};

export const ResourceLoader = ({
  _text,
  accessibilityLabel,
  children,
  color = 'gray.500',
  isEmpty = false,
  isLoading = false,
  message = 'Please wait',
  emptyMessage,
  ...props
}: ResourceLoaderProps) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  if (isLoading) {
    // Render loading message
    return (
      <Box alignItems="center" justifyContent="center" {...props}>
        <Spinner accessibilityLabel={accessibilityLabel} color={color} />
        <Text size="md" color={color} fontWeight={500} {..._text}>
          {message}
        </Text>
      </Box>
    );
  }
  if (isEmpty) {
    // Render empty message
    return (
      <Box alignItems="center" justifyContent="center" {...props}>
        {!emptyMessage || typeof emptyMessage === 'string' ? (
          <>
            <FeatherIcon name="box" color={colors.gray[300]} size={32} />
            <Text mt={1} size="md" color="gray.500" fontWeight={500} {..._text}>
              {emptyMessage ?? t('common:no_data_found')}
            </Text>
          </>
        ) : (
          emptyMessage
        )}
      </Box>
    );
  }
  return children;
};
