import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FlatList, HStack, IconButton, Spacer, VStack } from 'native-base';
import { filter, isEmpty, map, orderBy, sumBy, values } from 'lodash';

import { ArrowSquareUpRightIcon, ButtonGroup, CleanIcon, Modal, Text } from '@pimm/base';
import {
  AggAssignedTaskDto,
  AggCleaningTask,
  AggPositionAssigneeDto,
  AggScheduleTypeDto,
  CleaningScheduleTypeEnum,
  CleaningTaskStatusEnum,
} from '@pimm/services/lib/sms-workforce';
import { useSiteConfig, useSiteTime } from '@app/features/store-core';
import { stringToDateLocal } from '@app/utils/date-formatter';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { CleaningDocuments } from './cleaning-documents';
import { CleaningTasksItem } from './cleaning-tasks-item';

enum CleaningTasksTabItem {
  ALL = 'All',
  OVERDUE = 'Overdue',
  DUE_TODAY = 'Due Today',
  COMPLETED = 'Completed',
}

export interface CleaningTaskAssignee {
  id?: number;
  employeeId?: string;
  firstName?: string;
  lastName?: string;
  dueDate?: Date;
  cleaningTaskStatus?: CleaningTaskStatusEnum;
  scheduleType?: CleaningScheduleTypeEnum;
  cleaningTaskAssignmentId?: number;
  cleaningTaskId?: number;
  statusDate?: Date;
}

interface CleaningTasksProps {
  assignee?: AggPositionAssigneeDto;
  cleaningTasks?: AggCleaningTask;
  isSmallScreen: boolean;
  onNavigateTo: (screen: string, params?: any) => void;
}

export const CleaningTasks = ({ assignee, cleaningTasks, isSmallScreen, onNavigateTo }: CleaningTasksProps) => {
  const { t } = useTranslation();
  const siteTime = useSiteTime();
  const { siteConfig } = useSiteConfig();
  const modalCleaningTask = useModalFocus<AggAssignedTaskDto>();

  const [tabFocus, setTabFocus] = useState<string>(CleaningTasksTabItem.DUE_TODAY);
  const [scheduleTypes] = useState<CleaningScheduleTypeEnum[]>([
    CleaningScheduleTypeEnum.Daily,
    CleaningScheduleTypeEnum.Weekly,
    CleaningScheduleTypeEnum.Monthly,
  ]);

  const isEmptyCleaningTasks = isEmpty(cleaningTasks);
  const startEndOfBlock = siteTime.toStartEndOfBlock();

  const categories = useMemo(() => {
    // All tasks within each of the sections (Daily, Weekly, Mothly) should be sorted by:
    // dueDate (oldest first)
    // title (A➝Z) - used as tie-breaker
    const sortedCleaningTasks = map(cleaningTasks?.scheduleTypes, schedule => {
      return { ...schedule, assignedTasks: orderBy(schedule.assignedTasks, ['dueDate', 'title'], ['asc', 'asc']) };
    });

    const categories = values(CleaningTasksTabItem).reduce((_categories: Record<string, AggScheduleTypeDto[]>, tabItem) => {
      if (tabItem === CleaningTasksTabItem.ALL) {
        return { ..._categories, [tabItem]: sortedCleaningTasks ?? [] };
      } else if (tabItem === CleaningTasksTabItem.COMPLETED) {
        const completedTasks = map(sortedCleaningTasks, _ => ({
          ..._,
          assignedTasks: filter(_.assignedTasks, task => task.cleaningTaskStatus === CleaningTaskStatusEnum.Complete),
        }));

        return { ..._categories, [tabItem]: completedTasks };
      }

      const scheduleTypes = sortedCleaningTasks?.map(_ => {
        return {
          ..._,
          assignedTasks: _?.assignedTasks?.filter(task => {
            if (task?.cleaningTaskStatus === CleaningTaskStatusEnum.Complete) return false;

            let dueDate = stringToDateLocal(task.dueDate);
            if (_.scheduleType === CleaningScheduleTypeEnum.Weekly || _.scheduleType === CleaningScheduleTypeEnum.Monthly) {
              dueDate = siteTime.toStartEndOfBlock(dueDate).endTime!;
            }

            if (tabItem === CleaningTasksTabItem.OVERDUE) {
              return dueDate && dueDate < startEndOfBlock.startTime!;
            }
            return dueDate && dueDate >= startEndOfBlock.startTime! && dueDate <= startEndOfBlock.endTime!;
          }),
        };
      });

      return { ..._categories, [tabItem]: scheduleTypes ?? [] };
    }, {});

    return categories;
  }, [cleaningTasks]);

  return (
    <>
      <FlatList
        h="full"
        flex={1}
        m={!isSmallScreen && isEmptyCleaningTasks ? 4 : 0}
        pb={3}
        data={!isEmptyCleaningTasks ? scheduleTypes : []}
        extraData={[assignee, categories, isEmptyCleaningTasks, tabFocus, siteConfig.id]}
        borderColor="gray.200"
        borderWidth={isEmptyCleaningTasks && !isSmallScreen ? 1 : 0}
        backgroundColor={isEmptyCleaningTasks ? 'gray.25' : undefined}
        borderRadius={8}
        stickyHeaderIndices={[0]}
        contentContainerStyle={{
          flexGrow: isEmptyCleaningTasks ? 1 : 0,
        }}
        scrollEnabled
        ListHeaderComponent={() => (
          <VStack space={2} p={isEmptyCleaningTasks ? 3 : 4} pb={0} bg={isEmptyCleaningTasks ? 'gray.25' : 'white'}>
            <HStack space={2} alignItems="center">
              <CleanIcon size="md" color="black" />
              <Text size="lg" fontWeight={700} color="black">
                {t('kitchen_positioning:cleaning_tasks_current_week')}
              </Text>
              <Spacer />
              <IconButton
                p={0}
                rounded="md"
                w={8}
                h={8}
                borderWidth={1}
                borderColor="gray.300"
                backgroundColor="white"
                _hover={{ bg: undefined }}
                _pressed={{ bg: undefined }}
                icon={<ArrowSquareUpRightIcon color="gray.700" />}
                onPress={() => onNavigateTo('CleaningTasks')}
              />
            </HStack>
            {!isEmptyCleaningTasks && (
              <ButtonGroup value={tabFocus} flex={1} onChange={setTabFocus}>
                {values(CleaningTasksTabItem).map(tabItem => {
                  const count = sumBy(categories[tabItem] as AggScheduleTypeDto[], _ => _.assignedTasks?.length || 0);
                  return (
                    <ButtonGroup.Item key={tabItem} flex={1} value={tabItem} w={100} badgeIcon={{ show: true, value: count }} h={30}>
                      {tabItem}
                    </ButtonGroup.Item>
                  );
                })}
              </ButtonGroup>
            )}
          </VStack>
        )}
        ListEmptyComponent={() => (
          <Box flex={1} alignItems="center" justifyContent="center">
            <Text size="md" fontWeight={500} color="gray.700">
              {t('common:no_assigned_tasks')}
            </Text>
          </Box>
        )}
        renderItem={({ item: scheduleType, index }) => {
          const assignedTasks = categories[tabFocus]?.find(_ => _?.scheduleType === scheduleType)?.assignedTasks;

          return (
            <VStack key={scheduleType} rounded="lg" mx={4} mt={3} p={2.5} pt={1} borderWidth={1} bg="gray.25">
              <HStack>
                <Text size="lg" lineHeight="2xl" fontWeight={700} color="black" textTransform="uppercase">
                  {scheduleType}
                </Text>
                <Spacer />
                <Text size="lg" lineHeight="2xl" fontWeight={700} color="black">
                  {assignedTasks?.length || ''}
                </Text>
              </HStack>
              {isEmpty(assignedTasks) ? (
                <Box flex={1}>
                  <Text size="md" fontWeight={500} color="gray.500" lineHeight="md">
                    {t('common:no_assigned_tasks')}
                  </Text>
                </Box>
              ) : (
                <VStack space={1}>
                  {assignedTasks?.map(task => (
                    <CleaningTasksItem
                      key={`${tabFocus}.${task.cleaningTaskId}.${task.cleaningTaskAssignmentId}`}
                      cleaningTask={task}
                      employeeId={assignee?.employeeId}
                      scheduleType={scheduleType}
                      siteId={siteConfig.id}
                      onPressLinkDocument={modalCleaningTask.setOpen}
                    />
                  ))}
                </VStack>
              )}
            </VStack>
          );
        }}
      />

      <Modal
        _content={{ rounded: '2xl', w: '90%', h: '95%', maxWidth: '1200', overflow: 'hidden' }}
        size="full"
        noPadding
        hideClose
        isOpen={modalCleaningTask.isOpen}
        onClose={modalCleaningTask.setHide}
      >
        {!!modalCleaningTask.payload && <CleaningDocuments cleaningTask={modalCleaningTask.payload} onClose={modalCleaningTask.setHide} />}
      </Modal>
    </>
  );
};
