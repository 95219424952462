import React, { createContext, useContext } from 'react';

import { GetPositionCategoriesQueryParams } from '@pimm/services/lib/sms-positioning';
import { useGetPositionCategories } from '../hooks/get-position-categories.hook';

type PositionCategoriesProviderProps = GetPositionCategoriesQueryParams & {
  categories: ReturnType<typeof useGetPositionCategories>[0];
  children: React.ReactNode;
};

const PositionCategoriesContext = createContext<ReturnType<typeof useGetPositionCategories>[0]>(undefined!);

export const PositionCategoriesProvider = ({ categories, children }: PositionCategoriesProviderProps) => {
  return <PositionCategoriesContext.Provider value={categories}>{children}</PositionCategoriesContext.Provider>;
};

export const PositionCategoriesConsumer = PositionCategoriesContext.Consumer;

export const usePositionCategories = () => {
  // get the context
  const context = useContext(PositionCategoriesContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('usePositionCategories was used outside of its Provider');
  }
  return context;
};
