import { Box, HStack, useTheme } from 'native-base';
import AntDesignIcon from 'react-native-vector-icons/AntDesign';
import moment from 'moment';

import { Text } from '@pimm/base';
import { stringToDateLocal } from '@app/utils/date-formatter';

export const DaysCounter = ({ startDate }: { startDate?: string | Date }) => {
  const { colors } = useTheme();
  const dt = typeof startDate === 'string' ? stringToDateLocal(startDate) : startDate;
  let days;

  if (!!dt && dt instanceof Date) {
    days = moment(dt).diff(moment(), 'days');
  }

  return (
    <HStack space={1} alignItems="center" rounded="full" pl={1} pr={1} h={{ sm: 5, xl: 6 }} borderWidth={1} backgroundColor="white">
      <AntDesignIcon name="hourglass" size={12} color={days > 0 ? 'black' : colors.error[500]} />
      <Box alignItems="center" justifyContent="center" px={1.5} minW={6} rounded="full" backgroundColor="gray.100">
        <Text size={{ sm: 'xs', xl: 'sm' }} fontWeight={500} color="gray.900">
          {days >= 0 ? days : 'Expired'}
        </Text>
      </Box>
    </HStack>
  );
};
