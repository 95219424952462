import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import { Box, HStack, IBoxProps, ScrollView } from 'native-base';
import { find } from 'lodash';

import { Text } from '@pimm/base';
import { Dispenser } from '@pimm/services/lib/coke-freestyle';
import { MainNavigationParams } from '@app/navigations/root';
import { DashboardModalType, useDashboard } from '@app/features/dashboard';
import { useGetSiteDispensers } from '@app/features/coke-freestyle';
import { WidgetCokeFreeStyle, WidgetEquipment, WidgetCalendar, WidgetTemperatures, WidgetSpeedOfService } from '@app/features/widgets';
import { useAppModules } from '@app/features/app';
import { useSiteConfig } from '@app/features/store-core';
import { useGetInstances } from '@app/features/store-equipment';

export const WidgetContainer = (props: IBoxProps) => {
  const navigation = useNavigation();
  const { t } = useTranslation();

  const { siteConfig } = useSiteConfig();
  const { screens } = useAppModules();

  const { toggleModal } = useDashboard();

  const haDriveThru = find(screens, _ => _.identifierId === 'driveThruTimer');
  const cokeFreeStyle = siteConfig?.config?.dashboardConfig?.cokeFreeStyle;

  const siteDispensers = useGetSiteDispensers(cokeFreeStyle?.enabled ? siteConfig?.id : undefined);
  const instances = useGetInstances(siteConfig?.id);

  const handlePressDispenser = (dispenser: Dispenser) => {
    toggleModal({ type: DashboardModalType.CokeFreestyle, payload: dispenser });
  };

  const navigateToScreen = (screen: keyof MainNavigationParams) => {
    // @ts-ignore
    // TODO: Fix type issue
    navigation.navigate('Main', { screen: screen });
  };

  return (
    <Box h="full" bgColor="gray.200" {...props}>
      <HStack py={2} px={3} alignItems="center">
        <Text flex={1} mb={0} size="xl" color="black">
          {t('common:widget_center')}
        </Text>
      </HStack>

      <ScrollView flex={1} scrollEnabled>
        <HStack flexWrap="wrap" justifyContent="space-between" pb={3} px={3}>
          {cokeFreeStyle?.enabled && (
            <WidgetCokeFreeStyle mb={2} w="full" siteDispensers={siteDispensers} onPressDispenser={handlePressDispenser} />
          )}

          <WidgetTemperatures mb={2} w="full" instances={instances} />

          {haDriveThru && <WidgetSpeedOfService mb={2} w="full" onPress={() => navigateToScreen('SpeedOfService')} />}

          <WidgetEquipment mb={2} w="48.5%" instances={instances} />

          <WidgetCalendar mb={2} w="48.5%" />
        </HStack>
      </ScrollView>
    </Box>
  );
};
