import { View } from 'native-base';
import { Platform } from 'react-native';
import WebView from 'react-native-webview';
import { Locale } from 'locale-enum';
import { find, first } from 'lodash';

import { useAppLocale } from '@pimm/common';
import { Document } from '@pimm/services/lib/sms-documents';

type DocumentViewerProps = { culture?: Locale; document: Document; title?: string };

export const DocumentViewer = ({ culture, document, title }: DocumentViewerProps) => {
  const { translate } = useAppLocale();
  const documentFile = find(document?.files, ['culture', culture]) ?? first(document?.files);
  const documentTitle = title || translate(document?.title, document?.translations) || 'Unknown Document';

  // Note: For reference only
  // useEffect(() => {
  //   const loadDocumentData = async documentId => {
  //     const result = await SmsDocumentsApi.GetDocument(documentId);
  //     if (result) {
  //       setObjectUrl(URL.createObjectURL(result.data));
  //     }
  //   };

  //   if (document) {
  //     setObjectUrl(null);
  //     loadDocumentData(document.id);
  //   }
  // }, [document]);

  return (
    <View height="full" width="full" backgroundColor="gray.700">
      {!!documentFile?.url && (
        <>
          {Platform.OS === 'web' ? (
            <iframe
              title={documentTitle}
              style={{ border: 'none', height: '100%', width: '100%' }}
              src={`${documentFile.url}#view=FitV&zoom=page-fit`}
            />
          ) : (
            <WebView
              bounces={false}
              scrollEnabled={false}
              source={{
                uri: documentFile.url,
              }}
            />
          )}
        </>
      )}
    </View>
  );
};
