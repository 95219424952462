import { Box, HStack, Spacer, VStack, View } from 'native-base';
import { isEmpty } from 'lodash';

import { Button, FilterLinesIcon, SearchField, Text } from '@pimm/base';
import { Employee } from '@pimm/services/lib/sms-workforce';
import { useSiteConfig } from '@app/features/store-core';
import { LearningHub, useGetHubStats } from '@app/features/app-insights';

type LearningHubPanelProps = {
  team?: Employee[];
};

export const LearningHubPanel = ({ team }: LearningHubPanelProps) => {
  const { siteConfig } = useSiteConfig();
  const queryHubStats = useGetHubStats(siteConfig?.id);

  return (
    <VStack h="full">
      <HStack space={2} alignItems="center" py={2} px={4} minH="58px" borderBottomWidth={1}>
        <Box>
          <HStack space={2} alignItems="center" h={6}>
            <Text size="xl" fontWeight={600} color="black" lineHeight="xs">
              Team members
            </Text>
            {!isEmpty(queryHubStats.data) && (
              <Box rounded="full" justifyContent="center" py={1} px={2} borderColor="primary.600" borderWidth={1}>
                <Text size="sm" fontWeight={600} color="primary.600" lineHeight="xs">
                  {`${queryHubStats.data?.length ?? 0} users`}
                </Text>
              </Box>
            )}
          </HStack>
          <Text size="md" fontWeight={400} color="gray.600" lineHeight="xs">
            Track your team members training progress here.
          </Text>
        </Box>

        <Spacer />

        <SearchField minWidth={300} />

        <Button alternate outline startIcon={<FilterLinesIcon size="sm" color="black" />}>
          Filters
        </Button>
      </HStack>
      <View flex={1}>
        <LearningHub key={siteConfig?.id} queryHubStats={queryHubStats} />
      </View>
    </VStack>
  );
};
