import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { find, first, map, without } from 'lodash';
import { Locale } from 'locale-enum';

import { Category, Document } from '@pimm/services/lib/sms-documents';
import { useGetDocumentStructure } from '../hooks/get-document-structure.hooks';

export interface StoreDocumentsContextReturn {
  categories: UseQueryResult<Category[]>;
  categoryFocus?: Category;
  cultureFocus?: Locale;
  cultures: Locale[];
  documentFocus?: Document;
  changeCategory: (category: Category) => void;
  changeCulture: (value: Locale) => void;
  changeDocument: (document: Document) => void;
}

export const StoreDocumentsContext = createContext<StoreDocumentsContextReturn>(undefined!);

export type StoreDocumentsProviderProps = {
  children: React.ReactNode;
  brandId?: string;
  defaultLocale?: Locale;
  marketId?: number;
  regionId?: number;
};

export const StoreDocumentsProvider = ({ children, brandId, defaultLocale = Locale.en_US, marketId }: StoreDocumentsProviderProps) => {
  const [categories, setCategoryParams] = useGetDocumentStructure();
  const [categoryFocus, setCategoryFocus] = useState<Category>();
  const [cultureFocus, setCultureFocus] = useState<Locale>();
  const [documentFocus, setDocumentFocus] = useState<Document>();

  const cultures = without(map(documentFocus?.files, 'culture'), undefined) as Locale[];

  const handleChangeDocument = useCallback(
    (document: Document) => {
      const file =
        find(document.files, ['culture', defaultLocale]) ?? find(document.files, ['culture', Locale.en_US]) ?? first(document.files);
      setCultureFocus((file?.culture ?? defaultLocale) as Locale);
      setDocumentFocus(document);
    },
    [defaultLocale],
  );

  useEffect(() => {
    if (brandId && marketId) {
      setCategoryParams({ brandId: brandId, marketId: marketId });
    }
  }, [brandId, marketId]);

  return (
    <StoreDocumentsContext.Provider
      value={{
        categories,
        categoryFocus,
        cultureFocus: cultureFocus ?? defaultLocale,
        cultures,
        documentFocus,
        changeCategory: setCategoryFocus,
        changeCulture: setCultureFocus,
        changeDocument: handleChangeDocument,
      }}
    >
      {children}
    </StoreDocumentsContext.Provider>
  );
};

export const StoreDocumentsConsumer = StoreDocumentsContext.Consumer;

export const useStoreDocuments = () => useContext(StoreDocumentsContext);
