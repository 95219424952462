import { useEffect, useMemo, useState } from 'react';
import { Box, Divider, HStack, IconButton, ScrollView, Spacer, VStack } from 'native-base';
import { chain, filter } from 'lodash';

import { ListIcon, Modal, Text } from '@pimm/base';
import { GoalInsightDto } from '@pimm/services/lib/sms-workforce';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { ResourceLoader } from '@app/components/shared';
import { formatToISOString } from '@app/utils/date-formatter';
import { DayBlock, useSiteTime } from '@app/features/store-core';
import { useGetDashboardUtilization } from '../hooks';
import { DailyAssignments } from './daily-assignments';
import { CleaningTasks } from './cleaning-tasks';
import { TalkToMe } from './talk-to-me';
import { WeeklyAssignments } from './weekly-assignments';
import { TTMGoals } from './ttm-goals';

type DashboardUtilizationProps = {
  date: Date;
  isWeekly?: boolean;
  queryDashboardUtilization: ReturnType<typeof useGetDashboardUtilization>[0];
  onNavigateTo: (screen: string, params?: any) => void;
};

export const DashboardUtilization = ({ date, isWeekly, queryDashboardUtilization, onNavigateTo }: DashboardUtilizationProps) => {
  const siteTime = useSiteTime();
  const [dayBlocks, setDayBlocks] = useState<DayBlock[]>([]);
  const modalTTMGoals = useModalFocus<GoalInsightDto[]>();

  useEffect(() => {
    if (date) {
      setDayBlocks(siteTime.toDayBlocks(date));
    }
  }, [date]);

  return (
    <ResourceLoader w="full" h="full" isLoading={queryDashboardUtilization.isLoading}>
      <HStack h="full">
        <VStack flex={1} h="full">
          <ScrollView w="full" h="full">
            <VStack space={3} py={2} px={4}>
              <Box>
                <HStack alignItems="center" justifyContent="space-between" h="44px">
                  <Text size="xl" fontWeight={700} color="black" textTransform="uppercase" lineHeight="xs">
                    Daily Assignments
                  </Text>
                </HStack>
                {!!isWeekly ? (
                  <WeeklyAssignments
                    dayBlocks={dayBlocks}
                    startOfWeek={date}
                    positionInsights={queryDashboardUtilization?.data?.positionInsights}
                  />
                ) : (
                  <DailyAssignments
                    date={date}
                    dayBlocks={dayBlocks}
                    positionInsights={queryDashboardUtilization?.data?.positionInsights}
                  />
                )}
              </Box>
            </VStack>
          </ScrollView>
        </VStack>

        <Divider orientation="vertical" bgColor="gray.200" />

        <Box flex={1} h="full" bg="gray.25">
          <ScrollView w="full" h="full">
            <VStack space={3} py={2} px={4}>
              {/* Talk to Me */}
              <Box>
                <HStack space={2} alignItems="center" justifyContent="space-between" h="44px">
                  <Text size="xl" fontWeight={700} color="black" textTransform="uppercase" lineHeight="xs">
                    TTM
                  </Text>

                  <Spacer />

                  <Text size="2xl" fontWeight={700} color="black" lineHeight="xs">
                    {filter(queryDashboardUtilization?.data?.goalInsights, { hasGoal: true }).length}
                    <Text size="2xl" fontWeight={500} color="gray.600" lineHeight="xs">
                      /{queryDashboardUtilization?.data?.goalInsights?.length}
                    </Text>
                  </Text>

                  <IconButton
                    rounded="md"
                    p={1}
                    borderWidth={1}
                    borderColor="gray.300"
                    bg="white"
                    icon={<ListIcon color="gray.600" />}
                    onPress={() => modalTTMGoals.setOpen()}
                  />
                </HStack>
                <TalkToMe goalInsights={queryDashboardUtilization?.data?.goalInsights} />
              </Box>

              {/* Cleaning Tasks */}
              <Box>
                <HStack alignItems="center" justifyContent="space-between" h="44px">
                  <Text size="xl" fontWeight={700} color="black" textTransform="uppercase" lineHeight="xs">
                    Cleaning Tasks
                  </Text>
                </HStack>
                <CleaningTasks
                  isWeekly={isWeekly}
                  startOfWeek={date}
                  cleaningInsights={queryDashboardUtilization?.data?.cleaningInsights}
                />
              </Box>
            </VStack>
          </ScrollView>
        </Box>
      </HStack>
      <Modal
        size="lg"
        _content={{ rounded: 'xl', maxWidth: { md: 1080, xl: 1200 }, height: '75%', maxHeight: 900 }}
        isOpen={modalTTMGoals.isOpen}
        noPadding
        onClose={modalTTMGoals.setHide}
      >
        <TTMGoals goalInsights={queryDashboardUtilization?.data?.goalInsights} onNavigateTo={onNavigateTo} />
      </Modal>
    </ResourceLoader>
  );
};
