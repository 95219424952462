import { Dispatch, SetStateAction, useState } from 'react';
import { QueryKey, UseQueryResult, useQuery } from 'react-query';
import { GetPositionCategories } from '@pimm/services/lib/sms-positioning/services';
import { GetPositionCategoriesQueryParams } from '@pimm/services/lib/sms-positioning';

export const useGetPositionCategories = (props?: {
  queryKey?: string;
  query?: GetPositionCategoriesQueryParams;
}): [UseQueryResult<Awaited<ReturnType<typeof GetPositionCategories>>>, Dispatch<SetStateAction<GetPositionCategoriesQueryParams>>] => {
  const [query, setQuery] = useState<GetPositionCategoriesQueryParams>(props?.query ?? {});
  const positionCategories = useQuery({
    cacheTime: 0,
    enabled: !!query.brandId && !!query.marketId,
    queryKey: [props?.queryKey ?? 'GetPositionCategories', query] as [QueryKey, GetPositionCategoriesQueryParams],
    queryFn: ({ queryKey }) => {
      return GetPositionCategories(queryKey[1]);
    },
  });

  return [positionCategories, setQuery];
};
