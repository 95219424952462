import { createAsyncThunk } from '@reduxjs/toolkit';
import StatusCode from 'status-code-enum';

import { SmsTenantsApi, UserProfileDto } from '@pimm/services/lib/sms-tenants';
import { GenericError } from '@pimm/common';
import { ASYNC_PROFILE } from '@app/store/consts';
import { RootState } from '../store';

const getUserProfile = createAsyncThunk<UserProfileDto, string, { rejectValue: GenericError; state: RootState }>(
  ASYNC_PROFILE,
  async (userId, { rejectWithValue }) => {
    try {
      const profile = await SmsTenantsApi.GetUserProfile({ userId: userId });
      return profile;
    } catch (error: any) {
      return rejectWithValue({
        code: StatusCode.ServerErrorInternal,
        message: error?.message || 'Failed to fetch user profile',
      });
    }
  },
);

export default getUserProfile;
