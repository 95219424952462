import React from 'react';
import { Box, HStack, Pressable, VStack } from 'native-base';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import { map } from 'lodash';

import { Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { AlarmLevel as AlarmLevelEnum, InstanceDetailsDto, LinkedInstancesDto } from '@pimm/services/lib/store-equipment';
import { tempValue } from '@app/utils/string-formatter';
import { getSignalState } from '../_helper';
import { EventBox, EventType } from './event-box';
import { OpenDoorIcon } from '../icons';
import { formatToShortDateTime } from '@app/utils/date-formatter';

export const TempColors = {
  [AlarmLevelEnum.Info]: 'moss',
  [AlarmLevelEnum.Warning]: 'warning',
  [AlarmLevelEnum.Alarm]: 'error',
  [AlarmLevelEnum.Dead]: 'gray',
  [AlarmLevelEnum.Ok]: 'blue',
} as const;

export type InstanceTemperatureCardProps = {
  _container?: React.ComponentProps<typeof VStack>;
  instance: LinkedInstancesDto;
  doorInstance?: LinkedInstancesDto;
  isFocused?: boolean;
  onPress?: () => void;
};

export const InstanceTemperatureCard = ({ instance, doorInstance, isFocused, ...props }: InstanceTemperatureCardProps) => {
  const { translate } = useAppLocale();
  const { BatteryVoltage, Door, SignalStrength, Temperature } = instance;
  const signal = getSignalState(SignalStrength?.AlarmLevel as AlarmLevelEnum);
  const isDead = Temperature?.AlarmLevel === AlarmLevelEnum.Dead;
  const isOffline = !Temperature?.IsOnline;
  const isOutOfService = Temperature?.OutOfService;

  const color = isOutOfService || isOffline ? 'gray' : TempColors[Temperature?.AlarmLevel ?? AlarmLevelEnum.Dead];
  const events: { eventType: EventType; instance?: InstanceDetailsDto }[] = [
    {
      eventType: 'Temperature',
      instance: Temperature,
    },
    {
      eventType: 'Signal',
      instance: SignalStrength,
    },
    {
      eventType: 'Door',
      instance: Door,
    },
    {
      eventType: 'Battery',
      instance: BatteryVoltage,
    },
  ];

  return (
    <Pressable
      rounded="lg"
      w="full"
      borderWidth={2}
      borderColor={isFocused ? 'primary.600' : `${color}.200`}
      bgColor={`${color}.200`}
      onPress={props.onPress}
    >
      <VStack space={4} p={2} w="full" {...props._container}>
        <HStack space={{ md: 1, xl: 3 }} justifyContent="space-between" mb={0.5} pt={1} h={10}>
          <Text
            flex={1}
            pr={2}
            size="xl"
            lineHeight="xs"
            fontWeight={700}
            color={isOutOfService || isOffline ? 'black' : `${color}.900`}
            numberOfLines={2}
            ellipsizeMode="tail"
          >
            {/* Property 'translations' does not exist on type 'InstanceDetailsDto'. */}
            {translate(Temperature?.Description, Temperature ? Temperature['translations'] : undefined)}
          </Text>

          {isOutOfService ? (
            <Text size="xl" fontWeight={700} color="gray.700" mt={-1} lineHeight="xs">
              Sensor Disabled
            </Text>
          ) : (
            <VStack alignItems="flex-end" justifyContent="space-between" minWidth="1/4">
              {Temperature?.IsOnline ? (
                <>
                  <Text size="3xl" lineHeight="xs" fontWeight={700} color={isDead ? 'white' : `${color}.900`}>
                    {tempValue(Temperature?.StaticValue)}
                  </Text>

                  <HStack space={1}>
                    {!!doorInstance && (
                      <HStack space={1} alignItems="center" rounded="lg" px={1} h={4} bgColor="gray.700">
                        {doorInstance.Door?.StateValue?.toLowerCase() === 'open' ? (
                          <OpenDoorIcon size={3} color="white" />
                        ) : (
                          <MaterialCommunityIcons name="door" size={10} color="white" />
                        )}
                        <Text size="xs" color="white" lineHeight="xs">
                          {doorInstance.Door?.StateValue?.toLowerCase() === 'open' ? 'Open' : 'Closed'}
                        </Text>
                      </HStack>
                    )}

                    <HStack space={1} alignItems="center" rounded="lg" px={1} h={4} bgColor={signal.color}>
                      <MaterialCommunityIcons name="signal" size={10} color="white" />
                      <Text size="xs" color="white" lineHeight="xs">
                        {signal.text}
                      </Text>
                    </HStack>
                  </HStack>
                </>
              ) : (
                <Text size="xl" fontWeight={700} color="gray.700" mt={-1} lineHeight="xs">
                  Offline
                </Text>
              )}
            </VStack>
          )}
        </HStack>

        {isOutOfService ? (
          <Box flex={1} minH={10} justifyContent="end">
            <Text size="sm" fontWeight={600} color="gray.700" mt={-1} lineHeight="xs" bgColor="rose.100" textAlign="end">
              {`Last Update : ${formatToShortDateTime(Temperature.LastUpdateDate)}`}
            </Text>
          </Box>
        ) : (
          <HStack w="full" space={1}>
            {map(events, _event => (
              <Box key={_event.eventType} flex={1} rounded="md" h={10} overflow="hidden" bgColor={`${color}.100`}>
                <EventBox instance={_event.instance} eventType={_event.eventType} />
              </Box>
            ))}
          </HStack>
        )}
      </VStack>
    </Pressable>
  );
};
