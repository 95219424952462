import { Dispatch, SetStateAction, useState } from 'react';
import { QueryKey, UseQueryResult, useQuery } from 'react-query';

import { GetGoalTypesQueryParams } from '@pimm/services/lib/sms-workforce';
import { GetGoalTypes } from '@pimm/services/lib/sms-workforce/services';

export const useGetGoalTypes = (props?: {
  queryKey?: string;
  query?: GetGoalTypesQueryParams;
}): [UseQueryResult<Awaited<ReturnType<typeof GetGoalTypes>>>, Dispatch<SetStateAction<GetGoalTypesQueryParams>>] => {
  const [query, setQuery] = useState<GetGoalTypesQueryParams>({ ...props?.query });

  const goalTypes = useQuery({
    enabled: !!query?.siteId,
    queryKey: [props?.queryKey ?? 'GetGoalTypes', query] as [QueryKey, GetGoalTypesQueryParams],
    queryFn: ({ queryKey }) => GetGoalTypes(queryKey[1]),
  });

  return [goalTypes, setQuery];
};
