import { Dispatch, SetStateAction, useState } from 'react';
import { QueryKey, UseQueryResult, useQuery } from 'react-query';
import moment from 'moment';

import { GetSchedules } from '@pimm/services/lib/sms-workforce/services';

export type GetPlanStaffsParams = {
  date?: Date;
  siteId?: string;
};

export const useGetPlanStaffs = (props?: {
  queryKey?: string;
  query?: GetPlanStaffsParams;
}): [UseQueryResult<Awaited<ReturnType<typeof GetSchedules>>>, Dispatch<SetStateAction<GetPlanStaffsParams | undefined>>] => {
  const [query, setQuery] = useState<GetPlanStaffsParams | undefined>(props?.query);

  const schedules = useQuery({
    cacheTime: 0,
    enabled: !!query?.siteId && !!query?.date,
    queryKey: [props?.queryKey ?? 'GetPlanStaffs', query] as [QueryKey, GetPlanStaffsParams],
    queryFn: ({ queryKey: [key, params] }) => {
      return GetSchedules({
        siteId: params.siteId,
        pageSize: 9999,
        startDate: moment(params.date).startOf('day').format().slice(0, 19),
        endDate: moment(params.date).endOf('day').format().slice(0, 19),
      });
    },
  });

  return [schedules, setQuery];
};
