import { Dispatch, SetStateAction, useState } from 'react';
import { QueryKey, UseQueryResult, useQuery } from 'react-query';
import moment from 'moment';

import { GetOpsTasksAssignments } from '@pimm/services/lib/sms-workforce/services';
import { useSiteTime } from '@app/features/store-core';

type GetOpsTasksAssignmentsLiveParams = {
  salesVolumeProfileId?: number;
  siteId: string;
};

export const useGetOpsTasksAssignmentsLive = (props?: {
  queryKey?: string;
  query: GetOpsTasksAssignmentsLiveParams;
}): [
  UseQueryResult<Awaited<ReturnType<typeof GetOpsTasksAssignments>>>,
  Dispatch<SetStateAction<GetOpsTasksAssignmentsLiveParams | undefined>>,
] => {
  const siteTime = useSiteTime();
  const [queryKey] = useState<string>(props?.queryKey ?? 'GetOpsTasksAssignmentsLive');
  const [query, setQuery] = useState<GetOpsTasksAssignmentsLiveParams | undefined>(props?.query);

  const opsTasksAssignments = useQuery({
    cacheTime: 0,
    enabled: !!query,
    refetchIntervalInBackground: true,
    refetchOnMount: 'always',
    refetchInterval: () => {
      const timeNow = new Date();
      // Refetch the live positions using the nearest quarter minutes (15, 30, 45 or 0)
      const nearestQuarterHour = timeNow.toNearestQuarterHour();
      return nearestQuarterHour.getTime() - timeNow.getTime();
    },
    queryKey: [queryKey, query] as [QueryKey, GetOpsTasksAssignmentsLiveParams],
    queryFn: ({ queryKey: [key, params] }) => {
      const startTime = siteTime.toDayBlockNow().startTime;

      return GetOpsTasksAssignments({
        ...params,
        date: moment(startTime).format().slice(0, 19),
      });
    },
  });

  return [opsTasksAssignments, setQuery];
};
