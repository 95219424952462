import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Box, HStack, IconButton, ScrollView, VStack, useTheme } from 'native-base';
import { filter, find, isEmpty, map, partition, size, some } from 'lodash';

import { useDrop } from 'react-dnd';

import { ArrowCircleBrokenLeftIcon, ButtonGroup, MinusCircleIcon, Text } from '@pimm/base';
import { DeleteAssignee } from '@pimm/services/lib/sms-workforce/services';
import { PositionTypeEnum } from '@pimm/services/lib/sms-workforce';
import { usePositioningPlan } from '../context';
import { PositioningEmployee, PositioningSlot } from '../reducers';
import { UnionIcon } from '../icons';
import { DraggableEmployee } from './draggable-employee';
import { PositioningNonServiceCard } from './positioning-nonservice-card';
import { PendingChangesCard } from './pending-changes';

type PositioningAvailableEmployeesProps = {
  isDisabled?: boolean;
};

export const PositioningAvailableEmployees = ({ isDisabled }: PositioningAvailableEmployeesProps) => {
  const { colors } = useTheme();
  const { changes, positioning, createPositionSlot, deleteAssignee: deletePlanAssignee, deletePositionSlot, moveTo } = usePositioningPlan();
  const [tabFocus, setTabFocus] = useState<0 | 1>(0);
  const [employees, setEmployees] = useState<Partial<PositioningSlot>[][]>([]);

  const deleteAssignee = useMutation({ mutationFn: DeleteAssignee });

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'PositioningAssignee',
    drop: async (_dropped: PositioningSlot) => {
      if (_dropped.positionType === PositionTypeEnum.NonService) {
        // Drop non-service as pending change
        deletePositionSlot(_dropped);
      } else {
        deleteAssignee.mutate({ positionJobId: _dropped.positionJobId, employeeId: _dropped.assignee?.employeeId });
        deletePlanAssignee(_dropped);
      }
    },
    collect: monitor => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
  }));

  const handleChangeToNonService = (employee: PositioningEmployee) => () => {
    // Check if employee is already assigned in a non-service positions
    const positionSlot = find<PositioningSlot>(positioning?.positionSlots, slot => {
      return !slot.id && slot.positionType === PositionTypeEnum.NonService && slot.assignee?.employeeId === employee.employeeId;
    });

    if (positionSlot) {
      deletePositionSlot(positionSlot);
    } else {
      // Mimic blank position slot with assignee
      createPositionSlot({ assignee: employee, positionType: PositionTypeEnum.NonService, title: 'Non Service' });
    }
  };

  useEffect(() => setTabFocus(0), [positioning?.id]);

  useEffect(() => {
    const availableEmployees = filter(
      positioning?.employees,
      _ => !some<PositioningSlot>(positioning?.positionSlots, slot => slot.assignee?.employeeId === _.employeeId),
    ).map(employee => ({ assignee: employee }));
    setEmployees(partition(availableEmployees, _ => !_.assignee?.positionJobId));
  }, [positioning]);

  return (
    <VStack space={2} h="full">
      <Box px={3}>
        <ButtonGroup mt={2} value={tabFocus} onChange={setTabFocus}>
          <ButtonGroup.Item flex={1} alignItems="center" value={0}>
            Coverage
          </ButtonGroup.Item>
          <ButtonGroup.Item flex={1} alignItems="center" value={1}>
            Position History
          </ButtonGroup.Item>
        </ButtonGroup>
      </Box>

      <Box flex={1} borderTopWidth={1}>
        <ScrollView>
          <VStack space={2} p={3} pt={2} bgColor="white">
            <VStack space={1} mb={1.5}>
              <HStack space={1.5} alignItems="center">
                <ArrowCircleBrokenLeftIcon size={6} color={colors.gray[600]} />
                <Text size="xl" fontWeight={700} color="black" lineHeight="xs">
                  Available
                </Text>
              </HStack>
              <HStack space={1} alignItems="center">
                <IconButton
                  p={1}
                  rounded="md"
                  shadow={1}
                  borderWidth={1}
                  borderColor="gray.300"
                  bg="white"
                  isDisabled
                  _disabled={{ opacity: 1 }}
                  icon={<MinusCircleIcon size="14px" color={colors.gray[700]} />}
                />
                <Text size="md" fontWeight={600} color="gray.800">
                  to move employee to Non-Service.
                </Text>
              </HStack>
            </VStack>

            {!isDisabled && !!positioning?.confirmedTime && size(positioning.employees) > 0 && (
              <HStack
                ref={drop}
                space={2}
                rounded="xl"
                mt={-2}
                p={1.5}
                borderWidth={1}
                borderColor={isOver ? 'gray.400' : 'gray.200'}
                bg="white"
                style={{
                  shadowColor: colors.gray[500],
                  shadowOffset: { width: 0, height: 1 },
                  shadowOpacity: isOver ? 0.5 : 0.2,
                  shadowRadius: 3,
                }}
              >
                <HStack
                  flex={1}
                  space={2}
                  rounded="lg"
                  alignItems="center"
                  justifyContent="center"
                  h={10}
                  borderWidth={1}
                  borderStyle="dashed"
                  bg={isDisabled ? 'gray.100' : 'white'}
                >
                  <UnionIcon size="22px" color={colors.gray[isDisabled ? 400 : 600]} />
                  <Text size="md" color={colors.gray[isDisabled ? 400 : 600]} fontWeight={500} lineHeight="xs">
                    Drag and drop
                  </Text>
                </HStack>
              </HStack>
            )}

            {/* Pending Changes */}
            {!isEmpty(employees[1]) && (
              <PendingChangesCard>
                {!!positioning?.dayBlock &&
                  map(employees[1], (slot, index) => (
                    <PositioningNonServiceCard
                      key={`pending-available-employee.${index}.${slot.assignee?.employeeId}`}
                      dayBlock={positioning.dayBlock}
                      position={slot}
                      isAvailable
                      isDisabled={isDisabled}
                      onPress={() => moveTo(slot.assignee!, true)}
                    />
                  ))}
              </PendingChangesCard>
            )}

            {/* Available Employees */}
            {!isEmpty(employees[0]) && (
              <VStack rounded="md" borderWidth={1} overflow="hidden">
                <HStack px={3.5} h={8} alignItems="center" justifyContent="space-between" borderBottomWidth={1} backgroundColor="gray.25">
                  <Text size="sm" fontWeight={500} color="gray.600" lineHeight="sm">
                    Employee
                  </Text>
                </HStack>
                {map(employees[0], (slot, index) => {
                  const employeeId = slot.assignee?.employeeId;
                  return (
                    <Box
                      key={`available-employees.${index}.${employeeId}`}
                      overflow="hidden"
                      borderTopWidth={index ? 1 : 0}
                      borderColor="gray.200"
                      bg="white"
                    >
                      <DraggableEmployee
                        // Important, this will help the useDrag to return the correct item
                        key={`available-employees.${employeeId}.${positioning?.confirmedTime ?? ''}`}
                        employee={slot.assignee!}
                        isConfirmed={!!positioning?.confirmedTime}
                        isDisabled={isDisabled}
                        showLastKnownPosition={tabFocus === 1}
                        onToggleEmployee={handleChangeToNonService(slot.assignee!)}
                      />
                    </Box>
                  );
                })}
              </VStack>
            )}
          </VStack>
        </ScrollView>
      </Box>
    </VStack>
  );
};
