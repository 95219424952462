import { Fragment, useMemo } from 'react';
import { Divider, HStack, View, useTheme } from 'native-base';
import AntDesignIcons from 'react-native-vector-icons/AntDesign';
import { chain, findIndex, forEach, orderBy, partition } from 'lodash';

import { Button, Text, Modal } from '@pimm/base';
import { ShiftDto } from '@pimm/services/lib/sms-workforce';
import { ResourceLoader } from '@app/components/shared';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { useGetEmployeeSchedules } from '../hooks';
import { useScheduleEmployees } from '../context/schedule-employees.context';
import EmployeeDailyList from './employee-daily-list';
import { EmployeeAddSchedule } from './employee-add-schedule';

type EmployeeDailySchedulesProps = {
  entries?: ShiftDto[];
  employeeSchedules: ReturnType<typeof useGetEmployeeSchedules>[0];
  onPressEdit: (shift: ShiftDto) => void;
  onDeleted: (id: number) => void;
};

const EmployeeDailySchedules = ({ entries, employeeSchedules, ...props }: EmployeeDailySchedulesProps) => {
  const { colors } = useTheme();
  const { isEditable } = useScheduleEmployees();
  const modalScheduler = useModalFocus<boolean>();

  const isLoading = employeeSchedules.isIdle || employeeSchedules.isLoading;

  const sections = useMemo(() => {
    // TODO: enable order/sort by  table column
    return chain(orderBy(employeeSchedules.data?.data, ['startTime', 'endTime', 'employee.firstName', 'employee.lastName']))
      .thru(data => partition(data, ({ employee }) => employee?.title?.toLowerCase() === 'crew'))
      .tap(result => {
        if (entries?.length) {
          forEach(entries, entry => {
            const section = entry.employee?.title?.toLowerCase() === 'crew' ? 0 : 1;
            const index = findIndex(result[section], ['id', entry['id']]);
            if (index !== -1) {
              result[section][index] = entry;
            }
          });
        }
      })
      .value();
  }, [entries, employeeSchedules.data?.data]);

  const handlePressAddSchedule = (isManager: boolean) => () => {
    modalScheduler.setOpen(isManager);
  };

  return (
    <Fragment>
      <View flexDirection={{ md: 'column', xl: 'row' }} h="full" w="full">
        {/* Managers */}
        <View flex={{ xl: 1 }} px={4} h={{ xl: 'full' }}>
          <HStack alignItems="center" justifyContent="space-between" h="50px">
            <Text size="lg" fontWeight={700} color="gray.900">
              Managers
            </Text>
            {isEditable && !isLoading && (
              <Button
                h={9}
                minH="0"
                borderWidth={0}
                backgroundColor="primary.50"
                startIcon={<AntDesignIcons name="addusergroup" size={20} color={colors.primary[700]} />}
                _text={{ color: 'primary.700' }}
                onPress={handlePressAddSchedule(true)}
              >
                Add Manager
              </Button>
            )}
          </HStack>
          <View flex={1} pb={3}>
            <ResourceLoader h="full" isEmpty={employeeSchedules.data?.totalCount === 0} isLoading={isLoading}>
              <EmployeeDailyList isDisabled={!isEditable} schedules={sections[1]} onEdit={props.onPressEdit} onDelete={props.onDeleted} />
            </ResourceLoader>
          </View>
        </View>

        <Divider orientation="vertical" display={{ md: 'none', xl: 'block' }} h="full" bgColor="gray.200" />
        <Divider orientation="horizontal" display={{ md: 'block', xl: 'none' }} my={{ md: 2 }} w="full" bgColor="gray.200" />

        {/* Employees */}
        <View flex={{ xl: 1 }} px={4} h={{ xl: 'full' }}>
          <HStack alignItems="center" justifyContent="space-between" h="50px">
            <Text size="lg" fontWeight={700} color="gray.900">
              Employees
            </Text>
            {isEditable && !isLoading && (
              <Button
                h={9}
                minH="0"
                borderWidth={0}
                backgroundColor="primary.50"
                startIcon={<AntDesignIcons name="addusergroup" size={20} color={colors.primary[700]} />}
                _text={{ color: 'primary.700' }}
                onPress={handlePressAddSchedule(false)}
              >
                Add Employee
              </Button>
            )}
          </HStack>
          <View flex={1} pb={3}>
            <ResourceLoader h="full" isEmpty={employeeSchedules.data?.totalCount === 0} isLoading={isLoading}>
              <EmployeeDailyList isDisabled={!isEditable} schedules={sections[0]} onEdit={props.onPressEdit} onDelete={props.onDeleted} />
            </ResourceLoader>
          </View>
        </View>

        <Modal
          size="full"
          _content={{ rounded: 'xl', maxWidth: { md: 1080, xl: 1200 }, height: '95%', maxHeight: 900 }}
          isOpen={modalScheduler.isOpen}
          noPadding
          hideClose
        >
          <EmployeeAddSchedule isManager={modalScheduler.payload} employeeSchedules={employeeSchedules} onCancel={modalScheduler.setHide} />
        </Modal>
      </View>
    </Fragment>
  );
};

export default EmployeeDailySchedules;
