import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { RecoveryEmailStatusEnum } from '@pimm/services/lib/sms-tenants';

export enum FormEmailRecoveryStep {
  Update,
  Verify,
}

interface FormEmailRecovery {
  isVerified: boolean;
  status: RecoveryEmailStatusEnum;
  step: FormEmailRecoveryStep;
  email: string;
  password: string;
  code: string;
}

export const FormEmailRecoverySchema = yup.object({
  status: yup.mixed().oneOf(Object.values(RecoveryEmailStatusEnum)).default(RecoveryEmailStatusEnum.NotSet),
  step: yup.mixed().oneOf(Object.values(FormEmailRecoveryStep)).default(FormEmailRecoveryStep.Update),
  email: yup.string().email('Please provide a valid email address').default(''),
  password: yup.string(),
  code: yup.string().optional(),
});

export type FormEmailRecoveryProps = {
  defaultValues?: Partial<FormEmailRecovery>;
};

export const useFormEmailRecovery = ({ defaultValues }: FormEmailRecoveryProps) => {
  return useForm({
    defaultValues: { ...FormEmailRecoverySchema.getDefault(), ...defaultValues },
    resolver: yupResolver(FormEmailRecoverySchema),
  });
};
