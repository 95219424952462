import { Icon, IIconProps } from 'native-base';
import { Path } from 'react-native-svg';

export const Cellphone = ({ color = 'currentColor', size = 22, ...props }: IIconProps) => {
  return (
    <Icon color={color} size={size} strokeWidth="0" viewBox="0 0 12 16" {...props}>
      <Path
        d="M5.99992 11.6667H6.00659M3.46659 14.6667H8.53325C9.27999 14.6667 9.65336 14.6667 9.93857 14.5214C10.1895 14.3935 10.3934 14.1895 10.5213 13.9387C10.6666 13.6534 10.6666 13.2801 10.6666 12.5333V3.46668C10.6666 2.71994 10.6666 2.34657 10.5213 2.06136C10.3934 1.81047 10.1895 1.6065 9.93857 1.47867C9.65336 1.33334 9.27999 1.33334 8.53325 1.33334H3.46659C2.71985 1.33334 2.34648 1.33334 2.06126 1.47867C1.81038 1.6065 1.60641 1.81047 1.47858 2.06136C1.33325 2.34657 1.33325 2.71994 1.33325 3.46668V12.5333C1.33325 13.2801 1.33325 13.6534 1.47858 13.9387C1.60641 14.1895 1.81038 14.3935 2.06126 14.5214C2.34648 14.6667 2.71985 14.6667 3.46659 14.6667ZM6.33325 11.6667C6.33325 11.8508 6.18401 12 5.99992 12C5.81582 12 5.66659 11.8508 5.66659 11.6667C5.66659 11.4826 5.81582 11.3333 5.99992 11.3333C6.18401 11.3333 6.33325 11.4826 6.33325 11.6667Z"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Icon>
  );
};
