import { createAsyncThunk } from '@reduxjs/toolkit';
import StatusCode from 'status-code-enum';
import { find, isEqual, set, uniq, uniqBy, uniqWith } from 'lodash';

import { GenericError } from '@pimm/common';
import { SmsTenantsApi } from '@pimm/services/lib/sms-tenants';
import { ASYNC_STORE_FOCUS } from '@app/store/consts';
import { StoreFocusStateDefault } from '../slices/storeFocusSlice';
import { StoreFocusState } from '../state';

const changeStoreFocus = createAsyncThunk<StoreFocusState, string | undefined, { rejectValue: GenericError; state }>(
  ASYNC_STORE_FOCUS,
  async (siteId, { getState, rejectWithValue }) => {
    try {
      const stores = getState().userProfile.data.sites;
      const store = find(stores, ['id', siteId]) ?? stores[0];
      // Fetch full information about the store
      const storeConfig = await SmsTenantsApi.GetSiteById(store.customerId, store.id);

      if (storeConfig) {
        const config = storeConfig.config;

        if (config?.storeHoursConfig?.dayparts) {
          // Making sure to remove duplicate daypart
          set(
            config,
            'storeHoursConfig.dayparts',
            uniqWith(storeConfig.config?.storeHoursConfig?.dayparts, (a, b) => isEqual(a, b)),
          );
        }

        return {
          ...StoreFocusStateDefault,
          store: storeConfig,
        };
      }

      return rejectWithValue({
        code: StatusCode.ClientErrorExpectationFailed,
        message: 'Failed to load store information',
      });
    } catch (error: any) {
      return rejectWithValue({
        code: StatusCode.ServerErrorInternal,
        message: error?.message || 'Failed to fetch adviser data',
      });
    }
  },
);

export default changeStoreFocus;
