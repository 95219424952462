import { Box, HStack, IconButton, Pressable, Tooltip, VStack, useTheme } from 'native-base';
import { startCase, toLower } from 'lodash';

import { useDrag } from 'react-dnd';

import { DragVerticalIcon, MinusCircleIcon, Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { useKitchenLayout } from '@app/features/kitchen-positioning';
import { hexToRGBA } from '@app/utils/string-formatter';
import { PositioningEmployee } from '../reducers';
import { EmployeeTimeline } from './employee-timeline';

type DraggableEmployeeProps = {
  employee: PositioningEmployee;
  isChecked?: boolean;
  isConfirmed?: boolean;
  isDisabled?: boolean;
  showLastKnownPosition?: boolean;
  onToggleEmployee?: () => void;
};

export const DraggableEmployee = ({
  employee,
  isChecked,
  isConfirmed,
  isDisabled,
  showLastKnownPosition,
  ...props
}: DraggableEmployeeProps) => {
  const { colors } = useTheme();
  const { translate } = useAppLocale();
  const { positionLookup } = useKitchenLayout();

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: 'PositioningAssignee',
    item: employee,
    canDrag: !isDisabled,
    // For reference only
    // end: (item, monitor) => {
    //   const dropResult = monitor.getDropResult<Timeslot>();
    // },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const lastKnownPosition = employee.lastPositionId ? positionLookup[employee.lastPositionId] : undefined;

  return (
    <HStack ref={preview} alignItems="center" pt={2} pb={1.5} pl={1} pr={3} minHeight="68px" opacity={isDragging ? 0.6 : 1} bg="white">
      {!isDisabled && (
        <Pressable ref={drag} mr={-1}>
          <DragVerticalIcon size="22px" color={colors.gray[400]} />
        </Pressable>
      )}

      <Box flex={1} pl={2}>
        <VStack space={2}>
          <HStack alignItems="center" justifyContent="space-between" w="full">
            <Box flex={1}>
              <Text size="md" fontWeight={700} color="black" lineHeight="sm" numberOfLines={1} ellipsizeMode="tail">
                {`${startCase(toLower(employee.name ?? ''))}`}
              </Text>
              <Text size="sm" fontWeight={500} color="gray.900" lineHeight="xs">
                {employee?.title ?? 'Unknown'}
              </Text>
            </Box>

            {!isDisabled && (
              <Tooltip label="This will move employee to Non-Service" openDelay={300} placement="top">
                <IconButton
                  p={1}
                  rounded="lg"
                  shadow={1}
                  borderWidth={1}
                  borderColor="gray.300"
                  bg="white"
                  _pressed={{ borderColor: 'gray.400', bg: 'white' }}
                  _hover={{ borderColor: 'gray.400', bg: 'white' }}
                  icon={<MinusCircleIcon size={5} color={colors.gray[700]} />}
                  onPress={props.onToggleEmployee}
                />
              </Tooltip>
            )}
          </HStack>
        </VStack>

        {showLastKnownPosition ? (
          <HStack space={2} flexWrap="wrap" alignItems="center" justifyContent="space-between" minH={6}>
            <Text size="sm" fontWeight={500} color="gray.700" lineHeight="xs">
              Last known
            </Text>

            {!!lastKnownPosition ? (
              <Box
                flex="none"
                maxW="100%"
                height={4}
                borderRadius="full"
                bg={hexToRGBA(lastKnownPosition?.color ?? colors.gray[400], 0.5)}
                overflow="hidden"
              >
                <Text size="sm" fontWeight={500} color="gray.900" px={1.5} numberOfLines={1} ellipsizeMode="tail">
                  {translate(lastKnownPosition?.title, lastKnownPosition.translations)}
                </Text>
              </Box>
            ) : (
              <Text size="sm" fontWeight={500} color="gray.500" lineHeight="xs">
                Not found
              </Text>
            )}
          </HStack>
        ) : (
          <EmployeeTimeline employee={employee} />
        )}
      </Box>
    </HStack>
  );
};
