import { useMemo } from 'react';
import { Box, HStack, ScrollView, VStack } from 'native-base';
import { isNaN, map, sum, sumBy, values } from 'lodash';

import { Text } from '@pimm/base';
import { AverageTemperature } from './average-temperature';
import { useEquipmentSummary } from '../context';
import { FilterDisplay } from './filter-buttons';
import { performanceFilter } from '../_helper';

type PerformanceStoreViewProps = {
  filter: FilterDisplay;
};

export const PerformanceStoreView = ({ filter }: PerformanceStoreViewProps) => {
  const equipmentSummary = useEquipmentSummary();

  const tableData = useMemo(() => {
    const sections = equipmentSummary
      .map(_group => {
        const filteredEquipment = _group.equipment
          .filter(equipment => !!equipment.temperature && performanceFilter(filter, equipment))
          .map(({ equipment, performance }) => ({
            equipmentId: equipment?.EquipmentId,
            description: equipment?.TemperatureMetrics?.Temperature?.Description,
            averageTemperature: performance?.AverageTemperature,
            inTempPercentage: performance?.InTempPercentage,
            redFlags: performance?.TemperatureFlagCounts?.Red,
            blackFlags: performance?.TemperatureFlagCounts?.Black,
            doorOpening: sum(values(performance?.DoorFlagCounts).filter(Boolean)),
            alarmLevel: equipment?.TemperatureMetrics?.Temperature?.AlarmLevel,
          }));

        const total = {
          redFlags: sumBy(filteredEquipment, 'redFlags'),
          blackFlags: sumBy(filteredEquipment, 'blackFlags'),
          doorOpening: sumBy(filteredEquipment, 'doorOpening'),
        };

        return {
          name: _group.displayGroup,
          equipment: filteredEquipment,
          total,
        };
      })
      .filter(section => section.equipment.length > 0); //

    return {
      sections: sections,
    };
  }, [equipmentSummary, filter]);

  return (
    <ScrollView flex={1} p={3} h="full" _contentContainerStyle={{ flexGrow: 1 }}>
      {tableData.sections.length > 0 ? (
        map(tableData.sections, _section => {
          return (
            <VStack key={_section.name} space={2} overflow="hidden">
              <Text size="xl" fontWeight={700} color="gray.900">
                {_section.name}
              </Text>

              <VStack rounded="lg" mb={3} borderWidth={1}>
                {/* Header */}
                <HStack roundedTop="lg" height={12} bg="gray.50" borderBottomWidth={1}>
                  <Box justifyContent="center" pr={3} pl={4} h="full" w={{ base: '30%', xl: '50%' }}>
                    <Text size="md" fontWeight={700} color="gray.700" lineHeight="xs" textTransform="uppercase">
                      Sensor
                    </Text>
                  </Box>
                  <HStack flex={1} h="full">
                    <Box justifyContent="center" px={3} h="full" w="1/5" borderLeftWidth={1}>
                      <Text size="md" fontWeight={700} color="gray.700" lineHeight="xs" textTransform="uppercase" numberOfLines={2}>
                        Average {`\n`}Temperature ({'\u00B0'}F)
                      </Text>
                    </Box>
                    <Box justifyContent="center" px={3} h="full" w="1/5" borderLeftWidth={1}>
                      <Text size="md" fontWeight={700} color="gray.700" lineHeight="xs" textTransform="uppercase" numberOfLines={2}>
                        In Temp (%)
                      </Text>
                    </Box>
                    <Box justifyContent="center" px={3} h="full" w="1/5" borderLeftWidth={1}>
                      <Text size="md" fontWeight={700} color="gray.700" lineHeight="xs" textTransform="uppercase" numberOfLines={2}>
                        Temperature {`\n`}Events (Red)
                      </Text>
                    </Box>
                    <Box justifyContent="center" px={3} h="full" w="1/5" borderLeftWidth={1}>
                      <Text size="md" fontWeight={700} color="gray.700" lineHeight="xs" textTransform="uppercase" numberOfLines={2}>
                        Temperature {`\n`}Events (Black)
                      </Text>
                    </Box>
                    <Box justifyContent="center" px={3} h="full" w="1/5" borderLeftWidth={1}>
                      <Text size="md" fontWeight={700} color="gray.700" lineHeight="xs" textTransform="uppercase" numberOfLines={2}>
                        Door Open {`\n`}Events
                      </Text>
                    </Box>
                  </HStack>
                </HStack>

                {/* Body */}
                {map(_section.equipment, _equipment => {
                  return (
                    <HStack key={`${_section.name}.${_equipment?.equipmentId}`} height={10} borderBottomWidth={1} bg="white">
                      <Box justifyContent="center" pr={3} pl={4} h="full" w={{ base: '30%', xl: '50%' }}>
                        <Text size="md" fontWeight={500} color="black" lineHeight="xs">
                          {_equipment?.description}
                        </Text>
                      </Box>
                      <HStack flex={1} h="full">
                        <Box alignItems="flex-start" justifyContent="center" px={1.5} h="full" w="1/5" borderLeftWidth={1}>
                          {!!_equipment.averageTemperature && (
                            <AverageTemperature value={_equipment.averageTemperature} alarmLevel={_equipment?.alarmLevel} />
                          )}
                        </Box>
                        <Box justifyContent="center" px={3} h="full" w="1/5" borderLeftWidth={1}>
                          <Text size="md" fontWeight={500} color="black" lineHeight="xs">
                            {isNaN(Number(_equipment?.inTempPercentage)) ? '0.0' : _equipment?.inTempPercentage?.toFixed(1) ?? '0.0'}
                          </Text>
                        </Box>
                        <Box justifyContent="center" px={3} h="full" w="1/5" borderLeftWidth={1}>
                          <Text size="md" fontWeight={500} color="black" lineHeight="xs">
                            {_equipment.redFlags ?? 0}
                          </Text>
                        </Box>
                        <Box justifyContent="center" px={3} h="full" w="1/5" borderLeftWidth={1}>
                          <Text size="md" fontWeight={500} color="black" lineHeight="xs">
                            {_equipment.blackFlags ?? 0}
                          </Text>
                        </Box>
                        <Box justifyContent="center" px={3} h="full" w="1/5" borderLeftWidth={1}>
                          <Text size="md" fontWeight={500} color="black" lineHeight="xs">
                            {_equipment.doorOpening ?? 0}
                          </Text>
                        </Box>
                      </HStack>
                    </HStack>
                  );
                })}

                {/* Footer */}
                <HStack roundedBottom="lg" height={10} bg="white">
                  <Box alignItems="flex-end" justifyContent="center" px={3} h="full" w={{ base: '30%', xl: '50%' }}>
                    <Text size="md" fontWeight={700} color="gray.700" lineHeight="xs" textTransform="uppercase">
                      Total
                    </Text>
                  </Box>
                  <HStack flex={1} h="full">
                    <Box h="full" w="1/5" borderLeftWidth={1} bg="gray.50" />
                    <Box h="full" w="1/5" borderLeftWidth={1} bg="gray.50" />
                    <Box justifyContent="center" px={3} h="full" w="1/5" borderLeftWidth={1}>
                      <Text size="md" fontWeight={700} color="black" lineHeight="xs" textTransform="uppercase">
                        {_section.total.redFlags ?? 0}
                      </Text>
                    </Box>
                    <Box justifyContent="center" px={3} h="full" w="1/5" borderLeftWidth={1}>
                      <Text size="md" fontWeight={700} color="black" lineHeight="xs" textTransform="uppercase">
                        {_section.total.blackFlags ?? 0}
                      </Text>
                    </Box>
                    <Box justifyContent="center" px={3} h="full" w="1/5" borderLeftWidth={1}>
                      <Text size="md" fontWeight={700} color="black" lineHeight="xs" textTransform="uppercase">
                        {_section.total.doorOpening ?? 0}
                      </Text>
                    </Box>
                  </HStack>
                </HStack>
              </VStack>
            </VStack>
          );
        })
      ) : (
        <Box flex={1} alignItems="center" justifyContent="center" p={3} textAlign="center">
          <Text size="lg" fontWeight={600} color="gray.500">
            No Data Available
          </Text>
        </Box>
      )}
    </ScrollView>
  );
};
