import { QueryKey, UseQueryResult, useQuery } from 'react-query';

import { GetSettingsAndGoals } from '@pimm/services/lib/sms-drivethru/services';
import { useSiteTime } from '@app/features/store-core';

export const useGetSettingsAndGoals = (
  siteId?: string,
  queryKey?: string,
): UseQueryResult<Awaited<ReturnType<typeof GetSettingsAndGoals>>> => {
  const siteTime = useSiteTime();
  let refetchInBackground = false;

  const settingsAndGoals = useQuery({
    enabled: !!siteId,
    refetchIntervalInBackground: refetchInBackground,
    refetchOnMount: 'always',
    refetchInterval: () => {
      const timeNow = new Date();
      // Refetch the GetSettingsAndGoals every 10 minutes
      const nearestMinutes = timeNow.toNearestNextMinutes(30);
      return nearestMinutes.getTime() - timeNow.getTime();
    },
    queryKey: [queryKey ?? 'GetSettingsAndGoals', siteId] as [QueryKey, string],
    queryFn: ({ queryKey: [key, siteId] }) => GetSettingsAndGoals({ siteId: siteId }),
  });

  return settingsAndGoals;
};
