import { LinkingOptions } from '@react-navigation/native';
import { RootNavigationParams } from './root';

export const linking: LinkingOptions<RootNavigationParams> = {
  prefixes: [],
  config: {
    screens: {
      Launcher: 'launcher',
      Login: 'login',
      RecoverPassword: 'recover-password',
      ResetPassword: 'reset-password',
      Main: {
        initialRouteName: 'PositionGuide',
        path: ':siteId?',
        screens: {
          AppInsights: 'app-insights',
          Calendar: 'calendar',
          CleaningTasks: 'cleaning',
          DailyPlan: 'position-plan/:tabFocus/:schedule?/:blockNumber?',
          Documents: 'documents',
          FlowChart: 'flow-chart/:opsPhaseId?',
          // MessageBoard: 'messages/:messageId?',
          OpsPlan: 'ops-plan/:startOfWeek?',
          PositionGuide: 'position-guide',
          SalesTracker: 'sales',
          SpeedOfService: 'speed-of-service',
          StoreInfo: 'profile',
          Temperatures: 'equipment/:tabFocus?',
          NotFound: 'not-found',
        },
      },
      // TODO: implement page not found
      // NotFound: '*',
    },
  },
};
