import moment from 'moment';

export const stringToDateLocal = (value?: string): Date | undefined => {
  return value ? new Date(value?.replace('Z', '')) : undefined;
};

export const formatTo = (value: Date | string | number | undefined, format: string) => {
  let datetime;
  if (typeof value === 'string') {
    datetime = stringToDateLocal(value);
  }
  return value ? moment(datetime ?? value).format(format) : null;
};

export const formatToISOString = (value: Date, dateOnly?: boolean) => {
  return moment(value)
    .format()
    .slice(0, dateOnly ? 10 : 19);
};

export const formatToDateOnly = (value?: Date | string | number) => {
  return value ? formatTo(value, 'DD MMMM YYYY') : null;
};

export const formatToShortDateOnly = (value?: Date | string | number) => {
  return value ? formatTo(value, 'L') : null;
};

export const formatToShortDateTime = (value?: Date | string | number) => {
  return value ? formatTo(value, 'L hh:mm A') : null;
};

export const formatToLongDateTime = (value?: Date | string | number) => {
  return value ? formatTo(value, 'LLL') : null;
};

export const formatToLongDateOnly = (value?: Date | string | number) => {
  return value ? formatTo(value, 'll') : null;
};

export const formatToTimeOnly = (value?: Date | string | number) => {
  return value ? formatTo(value, 'hh:mm A') : null;
};

export const formatToShortTimeOnly = (value?: Date | string | number) => {
  return value ? formatTo(value, 'h:mm A') : null;
};

export const timeStringToDate = (value?: string) => {
  return value ? moment(value, 'HH:mm:ss').toDate() : undefined;
};

export const millisToMinutes = (millis: number) => {
  return Math.floor(millis / 60000);
};

export const dateDiffToMinutes = (startDate: Date, endDate: Date) => {
  return millisToMinutes(endDate.getTime() - startDate.getTime());
};

export function startOfWeek(dt: Date, firstDayOfWeek?: number): Date {
  const date = moment(dt);
  const weekday = firstDayOfWeek ?? 1;
  if (date.day() === weekday) return date.toDate();
  return date.isoWeekday(weekday).toDate();
}

export const roundTimeToNearestQuarterHour = (value: Date) => {
  const minutes = value.getMinutes();
  const roundedMinutes = Math.round(minutes / 15) * 15; // Round to the nearest 15 minutes
  const newTime = new Date(value);
  newTime.setMinutes(roundedMinutes);
  return newTime;
};

export const convertMinutesToHoursAndMinutes = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const formattedMinutes = (minutes % 60).toString().padStart(2, '0');

  return `${hours}:${formattedMinutes}`;
};
