import { useCallback, useState } from 'react';
import { Box, CloseIcon, Divider, FlatList, HStack, IconButton, Spacer, VStack, useTheme } from 'native-base';
import { find, first, get } from 'lodash';

import { useAppLocale } from '@pimm/common';
import { ClockIcon, PressableSelect, Text } from '@pimm/base';
import { useSiteTime } from '@app/features/store-core';
import { formatToTimeOnly } from '@app/utils/date-formatter';
import { FlowChartPosition, FlowChartPositionTask } from '../reducers/flow-chart-checklist.reducer';
import { KeyIcon, DigitalPhoneIcon, ShieldTickIcon, OfflineCircleIcon } from '../icons';
import { useFlowChartChecklist } from '../context';
import { FlowTimeline } from './flow-timeline';

type FlowPositionDetailedProps = {
  position: FlowChartPosition;
  taskId?: string;
  onClose?: () => void;
};

export const FlowPositionDetailed = ({ position, taskId, ...props }: FlowPositionDetailedProps) => {
  const { translate } = useAppLocale();
  const { colors } = useTheme();
  const siteTime = useSiteTime();
  const { completeTask } = useFlowChartChecklist();
  const [selectedTask, setSelectedTask] = useState<FlowChartPositionTask | undefined>(
    find(position.tasks, ['opsTaskId', taskId]) ?? first(position.tasks),
  );

  const isDisabled = !position.assignee || !position.startTime || siteTime.today() < position.startTime;

  const handleCompletion = useCallback(
    opsTaskTracker => {
      completeTask(position.positionId, opsTaskTracker);
    },
    [position],
  );

  const handlePressTask = useCallback(
    (taskId: string) => {
      const task = find(position.tasks, ['opsTaskId', taskId]);
      if (task) setSelectedTask(task);
    },
    [position.tasks],
  );

  const [badgeConfig] = useState([
    { prop: 'isDigital', label: 'Digital', icon: <DigitalPhoneIcon size="14px" color={colors.blue[700]} />, color: 'blue' },
    { prop: 'isFoodSafety', label: 'Food Safety', icon: <ShieldTickIcon size="14px" color={colors.error[700]} />, color: 'error' },
    { prop: 'isOffline', label: 'Offline', icon: <OfflineCircleIcon size="14px" color={colors.gray[700]} />, color: 'gray' },
    { prop: 'isPreClose', label: 'Preclose', icon: <KeyIcon size="14px" color={colors.success[700]} />, color: 'success' },
  ]);

  return (
    <VStack h="full">
      <HStack space={2} alignItems="center" px={4} minHeight={60} borderBottomWidth={1}>
        <Text size="xl" fontWeight={700} color={position.color ?? 'gray.900'} lineHeight="xs" textTransform="uppercase">
          {translate(position.title, position?.translations)}
        </Text>

        <Spacer />

        <Text size="lg" fontWeight={600} color="primary.700">
          Current Time
        </Text>

        <Box flex="none" justifyContent="center" rounded="md" px={2} h={7} borderWidth={1} borderColor="primary.700">
          <Text size="lg" fontWeight={600} color="primary.700">
            {formatToTimeOnly(siteTime.today())}
          </Text>
        </Box>

        {!!props.onClose && (
          <IconButton
            p={2}
            _pressed={{ bg: 'gray.100' }}
            _hover={{ bg: 'gray.50' }}
            icon={<CloseIcon size={3.5} color="gray.700" />}
            onPress={props.onClose}
          />
        )}
      </HStack>

      <HStack flex={1}>
        <VStack w="1/3" maxW={380} borderRightWidth={1}>
          <HStack space={3} pt={3} px={4} pb={4} borderBottomWidth={1}>
            <VStack flex={1} space={1}>
              <Text size="md" color="gray.700" fontWeight={500} lineHeight="xs">
                Assigned To
              </Text>
              <PressableSelect
                minH={9}
                bg="white"
                hideIcon
                _placeholder={{ color: 'gray.500' }}
                placeholder="Not assigned"
                value={position.assignee?.name ?? ''}
              />
            </VStack>
          </HStack>

          <Box flex={1}>
            <FlowTimeline
              highlightTaskId={selectedTask?.opsTaskId}
              isDisabled={isDisabled}
              opsTaskAssignmentId={position.opsTaskAssignmentId}
              tasks={position.tasks}
              onPressTask={handlePressTask}
              onComplete={handleCompletion}
            />
          </Box>
        </VStack>

        {/* Content */}
        <Box flex={1} p={4} borderBottomRightRadius="xl" bg="gray.25">
          <VStack space={4} rounded="xl" py={4} w="full" h="full" borderWidth={1} bg="white">
            <VStack space={1} px={5}>
              <Text size="xl" fontWeight={700} color="black" lineHeight="xs" numberOfLines={2} ellipsizeMode="tail">
                {translate(selectedTask?.title, selectedTask?.translations)}
              </Text>

              <HStack>
                {!!selectedTask && (
                  <HStack space={1} rounded="md" py={1} px={2} bg="gray.50">
                    <ClockIcon size={18} color="black" />
                    <Text size="md" fontWeight={600} color="black">
                      {`${formatToTimeOnly(selectedTask.startTime)} - ${formatToTimeOnly(selectedTask.endTime)} (${
                        selectedTask.durationInMinutes
                      } mins)`}
                    </Text>
                  </HStack>
                )}
              </HStack>
            </VStack>

            <Box px={5}>
              <FlatList
                rounded="xl"
                borderWidth={1}
                borderColor="gray.200"
                data={selectedTask?.items}
                ListEmptyComponent={() => (
                  <Box alignItems="center" justifyContent="center" minH="44px">
                    <Text size="md" color="gray.500" fontWeight={500}>
                      No available task(s) found
                    </Text>
                  </Box>
                )}
                ListHeaderComponent={() => (
                  <HStack alignItems="center" justifyContent="space-between" px={4} minH={10} bg="gray.50">
                    <Text size="sm" fontWeight={500} color="gray.600" lineHeight="xs">
                      Tasks
                    </Text>
                  </HStack>
                )}
                ItemSeparatorComponent={() => <Divider bg="gray.200" />}
                renderItem={({ item }) => (
                  <HStack space={5} alignItems="center" justifyContent="space-between" py={2} px={4} minH="44px">
                    <Text size="md" fontWeight={500} color="gray.900" lineHeight="sm">
                      {translate(item.title, item?.translations)}
                    </Text>

                    <HStack space={1} alignItems="center">
                      {badgeConfig.map(({ prop, label, icon, color }) => {
                        const isDisplayed = get(item, prop);
                        return (
                          !!isDisplayed && (
                            <HStack
                              key={label}
                              space={1}
                              alignItems="center"
                              flex="none"
                              rounded="2xl"
                              pl={1}
                              pr={1.5}
                              h={5}
                              borderWidth={1}
                              borderColor={`${color}.200`}
                              bg={`${color}.50`}
                            >
                              {icon}
                              <Text size="sm" fontWeight={500} color={`${color}.700`} lineHeight="xs">
                                {label}
                              </Text>
                            </HStack>
                          )
                        );
                      })}
                    </HStack>
                  </HStack>
                )}
              />
            </Box>
          </VStack>
        </Box>
      </HStack>
    </VStack>
  );
};
