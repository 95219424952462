import { useEffect, useRef, useState } from 'react';
import { Box, CircleIcon, HStack, Input } from 'native-base';
import { isEmpty, isNaN, range } from 'lodash';

export type PinInputProps = {
  digits?: number;
  onChange?: (value: string) => void;
};

const PinInput = ({ digits = 4, ...props }: PinInputProps) => {
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const [value, setValue] = useState<string>();

  const handleChange = (index: number) => (_num: string) => {
    if (isNaN(Number(_num))) return;

    const _value = [...(value?.split('') ?? []), _num].join('');
    const inputField = inputRefs.current[index + 1];

    setValue(_value);

    inputField?.focus();

    if (_value.length === digits) {
      if (props.onChange) props.onChange(_value);
    }
  };

  const handleKeyPress = (index: number, keyCode: string) => {
    if (value && value.length > 0 && keyCode === 'Backspace') {
      setValue(value.slice(0, -1));
      inputRefs.current[index - 1]?.focus();
    }
  };

  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  return (
    <HStack space={2} w={220} h="56px">
      {range(digits).map(index => {
        const numValue = value?.charAt(index);
        const isReadOnly = !isEmpty(numValue);
        return (
          <Box key={`pin.char.${index}`} position="relative" flex={1} h="full">
            <Box
              position="absolute"
              zIndex={0}
              rounded="lg"
              alignItems="center"
              justifyContent="center"
              w="full"
              h="full"
              opacity={isReadOnly ? 1 : 0}
              borderWidth={1}
            >
              <CircleIcon size={3} color="gray.800" />
            </Box>

            <Input
              w="full"
              h="full"
              position="absolute"
              opacity={isReadOnly ? 0 : 1}
              top={0}
              autoFocus
              fontSize="7xl"
              zIndex={0}
              fontFamily="body"
              textAlign="center"
              color="black"
              borderWidth={1}
              outlineColor="gray.400"
              outlineStyle="none"
              focusOutlineColor="gray.400"
              _hover={{ outlineColor: 'gray.400', outlineStyle: 'none' }}
              _focus={{ outlineColor: 'gray.400' }}
              _disabled={{ color: 'black' }}
              // isReadOnly={isReadOnly && chars !== digits}
              ref={ref => (inputRefs.current[index] = ref)}
              keyboardType="numeric"
              maxLength={1}
              value={numValue ?? ''}
              onKeyPress={e => handleKeyPress(index, e.nativeEvent.key)}
              onChangeText={handleChange(index)}
            />
          </Box>
        );
      })}
    </HStack>
  );
};

export default PinInput;
