import { useEffect, useState } from 'react';
import { Box, HStack, Pressable } from 'native-base';
import { map, range } from 'lodash';
import { Text } from '@pimm/base';

type FlowPaginationProps = {
  _container?: React.ComponentProps<typeof HStack>;
  activePage: number;
  totalPages: number;
  onChange?: (page: number) => void;
};

export const Pagination = ({ _container, activePage, totalPages, ...props }: FlowPaginationProps) => {
  const [pages, setPages] = useState<number[]>([]);

  const handleChange = num => () => {
    if (props.onChange) props.onChange(num);
  };

  useEffect(() => {
    setPages(range(1, totalPages + 1));
  }, [totalPages]);

  return (
    <HStack space={1} {..._container}>
      {map(pages, page => {
        const isActive = page === activePage;
        return (
          <Pressable key={`page-number[${page}]`} onPress={handleChange(page)}>
            {({ isHovered }) => (
              <Box rounded="full" size={7} justifyContent="center" bg={isActive ? 'primary.50' : isHovered ? 'gray.50' : undefined}>
                <Text size="md" fontWeight={500} color={isActive ? 'black' : 'gray.600'} textAlign="center">
                  {page}
                </Text>
              </Box>
            )}
          </Pressable>
        );
      })}
    </HStack>
  );
};
