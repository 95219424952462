import { Box } from 'native-base';
import { Text } from '@pimm/base';

export const StatusText = ({ lastUpdated }: { lastUpdated?: string }) => {
  return (
    <Box flex="none" alignItems="center" bgColor={lastUpdated ? 'green.200' : 'error.100'} px={1} rounded="sm">
      <Text size="xs" color={lastUpdated ? 'green.700' : 'error.700'} fontWeight={400}>
        {lastUpdated ? 'Online' : 'Offline'}
      </Text>
    </Box>
  );
};
