import React, { useEffect, useMemo, useState } from 'react';
import { ArrowBackIcon, ArrowForwardIcon, HStack } from 'native-base';
import FeatherIcons from 'react-native-vector-icons/Feather';
import { last } from 'lodash';
import moment from 'moment';

import { Button } from '@pimm/base';
import { MainScreenProps } from '@app/navigations/root';
import { MainContainer } from '@app/components/core';
import { ScreenLayout } from '@app/components/layout';
import { ModuleTitle } from '@app/features/app';
import { DateTimePopover, Daypart, useSiteConfig, useSiteTime } from '@app/features/store-core';
import { SalesTracker, useGetForecastAmounts, useGetSaleAmounts } from '@app/features/sales-tracker';

export type SalesTrackerScreenProps = MainScreenProps<'SalesTracker'>;

// TODO: need to rewrite offsettime implementation in here
export const SalesTrackerScreen: React.FC<SalesTrackerScreenProps> = ({ route }) => {
  const { siteConfig } = useSiteConfig();
  const siteTime = useSiteTime();
  const [dayparts, setDayparts] = useState<Daypart[]>([]);

  const forecastAmounts = useGetForecastAmounts({ siteId: siteConfig?.id, startDate: dayparts[0]?.startTime });
  const salesAmounts = useGetSaleAmounts({ siteId: siteConfig?.id, startDate: dayparts[0]?.startTime });

  const today = siteTime.today();
  const maxDate = last(siteTime.toDayparts())?.startTime;
  const startOfDay = dayparts[0]?.startTime;
  const endOfDay = last(dayparts)?.endTime;
  const isToday = startOfDay && endOfDay && startOfDay >= today && today < endOfDay;

  const handleChangeDate = (date?: Date) => {
    setDayparts(siteTime.toDayparts(date));
  };

  const handleChangeDays = (days: number) => () => {
    const startOfDay = dayparts[0].startTime;
    let date = startOfDay ? moment(startOfDay).add(days, 'days').toDate() : undefined;
    if (days === 0) date = undefined;
    setDayparts(siteTime.toDayparts(date));
  };

  useEffect(() => {
    if (siteTime.siteId) setDayparts(siteTime.toDayparts());
  }, [siteTime.siteId]);

  return (
    <MainContainer>
      <ScreenLayout
        title={<ModuleTitle name={route.name} defaultText="Sales & Labor" />}
        actionTool={
          <>
            {!!startOfDay && (
              <HStack space={2} justifyContent="flex-end">
                <Button alternate outline w={10} onPress={handleChangeDays(-1)}>
                  <ArrowBackIcon size={4} color="gray.700" />
                </Button>

                <DateTimePopover selectedDate={startOfDay} max={maxDate} onChange={handleChangeDate}>
                  <Button
                    alternate
                    variant="outline"
                    minWidth={140}
                    startIcon={<FeatherIcons name="calendar" size={18} color="gray.700" style={{ marginRight: 2 }} />}
                  >
                    {moment(startOfDay).format('ll')}
                  </Button>
                </DateTimePopover>

                <Button alternate outline w={10} disabled={maxDate && endOfDay && endOfDay >= maxDate} onPress={handleChangeDays(1)}>
                  <ArrowForwardIcon size={4} color="gray.700" />
                </Button>

                <Button alternate variant="outline" disabled={isToday} onPress={handleChangeDays(0)}>
                  Today
                </Button>
              </HStack>
            )}
          </>
        }
      >
        <SalesTracker dayparts={dayparts} forecastAmounts={forecastAmounts} salesAmounts={salesAmounts} />
      </ScreenLayout>
    </MainContainer>
  );
};
