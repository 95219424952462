import React, { createContext, useContext, useEffect } from 'react';

import { SiteConfigDto } from '@pimm/services/lib/sms-tenants';
import { useGetPositionGuideDataLive } from '../hooks/get-position-guide-live.hook';

export type KitchenPositionGuideLiveContextReturn = ReturnType<typeof useGetPositionGuideDataLive>[0];

export const KitchenPositionGuideLiveContext = createContext<KitchenPositionGuideLiveContextReturn>(undefined!);

export type KitchenPositionGuideLiveProviderProps = {
  children: React.ReactNode;
  siteConfig?: Partial<SiteConfigDto>;
};

export const KitchenPositionGuideLiveProvider = ({ children, siteConfig }: KitchenPositionGuideLiveProviderProps) => {
  const [livePositionGuide, setLivePositionsParams] = useGetPositionGuideDataLive();

  useEffect(() => {
    if (siteConfig?.id) {
      const companyInfo = siteConfig.companyInfo;
      const config = siteConfig.config;
      setLivePositionsParams({
        brandId: companyInfo?.brand?.id,
        customerId: companyInfo?.customerId,
        marketId: config?.marketId,
        salesVolumeProfileId: config?.salesVolumeProfileId,
        siteId: siteConfig.id,
      });
    }
  }, [siteConfig]);

  return <KitchenPositionGuideLiveContext.Provider value={livePositionGuide}>{children}</KitchenPositionGuideLiveContext.Provider>;
};

export const KitchenPositionGuideLiveConsumer = KitchenPositionGuideLiveContext.Consumer;

export const useKitchenPositionGuideLive = () => {
  // Get the context
  const context = useContext(KitchenPositionGuideLiveContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useKitchenPositionGuideLive was used outside of its Provider');
  }
  return context;
};
