import { memo } from 'react';
import { Avatar, Box, HStack, IBoxProps, VStack, useTheme } from 'native-base';
import moment from 'moment';

import { HolderResult } from '@pimm/services/lib/coke-freestyle';
import { ProgressLine, Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { gaugeLevelVariant, typeOfIssue, attentionText } from '../_helper';

const HolderCard = ({
  holder,
  ...props
}: {
  holder: HolderResult;
} & IBoxProps) => {
  const { translate } = useAppLocale();
  const { colors } = useTheme();
  const variant = gaugeLevelVariant(holder.percentageGaugeLevel);
  const issueType = typeOfIssue(holder.issueType || '', holder.percentageGaugeLevel || 0);

  let bgColor, textColor;
  let borderColor = colors.gray[200];

  switch (holder.holderStatus) {
    case 'Empty':
      borderColor = colors.error[500];
      bgColor = colors.error[500];
      textColor = colors.white;
      break;
    case 'Low':
      bgColor = colors.gray[100];
      break;
  }

  return (
    <Box {...props}>
      <VStack
        alignItems="center"
        justifyContent="space-between"
        rounded="lg"
        pb={1.5}
        height={{ md: '200px', xl: '220px' }}
        borderWidth={1}
        borderColor={borderColor}
        bgColor={bgColor}
      >
        <Box position="relative" w="full" alignItems="center" pt={2} px={{ md: 1.5, xl: 2.5 }}>
          {/* name */}
          <Box
            rounded="sm"
            position="absolute"
            top="5px"
            right="5px"
            minWidth="30px"
            bgColor={textColor === colors.white ? textColor : colors.gray[600]}
          >
            <Text size={{ md: 'xs', xl: 'sm' }} color={textColor ? colors.black : colors.white} textAlign="center">
              {/* Property 'holderNameTranslations' does not exist on type 'HolderResult' */}
              {translate(holder.holderName, holder ? holder['holderNameTranslations'] : undefined)}
            </Text>
          </Box>

          {/* image */}
          <Avatar
            mt={4}
            height={{ md: '64px', xl: '74px' }}
            width={{ md: '64px', xl: '74px' }}
            backgroundColor="gray.200"
            source={{
              uri: holder.imageUrl || undefined,
            }}
          />

          {/* ingredient name */}
          <Box w="full" h={10} justifyContent="center">
            <Text size="sm" textAlign="center" color={textColor || colors.black} lineHeight="xs" numberOfLines={2} ellipsizeMode="tail">
              {/* Property 'holderNameTranslations' does not exist on type 'HolderResult' */}
              {translate(holder.ingredientName, holder ? holder['ingredientNameTranslations'] : undefined)}
            </Text>
          </Box>

          {/* percentage and time remaining */}
          <HStack w="full" h={5} alignItems="center" justifyContent="space-between">
            <Text size={{ md: 'xs', xl: 'sm' }} color={textColor || colors.black}>
              {`${holder.percentageGaugeLevel || 0}%`}
            </Text>
            <Text size={{ md: 'xs', xl: 'sm' }} color={textColor || colors.black}>
              {!!holder.timeRemaining ? holder.timeRemainingText : ''}
            </Text>
          </HStack>

          {/* gauge level */}
          <ProgressLine
            mt={0.5}
            w="full"
            variant={variant}
            value={holder.percentageGaugeLevel ?? 0}
            height={1.5}
            borderWidth={1}
            borderColor="gray.200"
            backgroundColor="gray.200"
            rounded="xs"
          />

          {/* enjoy by */}
          {holder.lastUpdatedUtc ? (
            <Box mt={1} w="full" alignItems="flex-start">
              <Text size={{ md: '2xs', xl: 'xs' }} fontWeight={400} color={textColor || colors.black}>
                Enjoy by{' '}
                <Text size={{ md: '2xs', xl: 'xs' }} color={textColor || colors.black} fontWeight={700}>
                  {moment(holder.lastUpdatedUtc).format('L')}
                </Text>
              </Text>
            </Box>
          ) : null}
        </Box>

        {/* issue text */}
        <Text size="sm" fontWeight={700} color={textColor || colors.black}>
          {attentionText(holder.holderStatus)}
        </Text>
      </VStack>
    </Box>
  );
};

export default memo(HolderCard);
