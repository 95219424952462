import React, { useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { useTranslation } from 'react-i18next';
import { Box, HStack, IconButton, Pressable, VStack, View, useBreakpointValue, useMediaQuery } from 'native-base';

import { ButtonGroup, LightBulbIcon, Text } from '@pimm/base';
import { useAppLocale, useStoreHoursConfig } from '@pimm/common';
import { useAppDispatch } from '@app/store/store';
import { MainNavigationParams, MainScreenProps } from '@app/navigations/root';
import { appConfigSlice } from '@app/store/slices/appConfigSlice';
import { formatTo } from '@app/utils/date-formatter';
import { ModuleTitle } from '@app/features/app';
import { MainContainer, WidgetContainer } from '@app/components/core';
import { useSiteConfig } from '@app/features/store-core';
import { ModalAppDocuments } from '@app/features/store-info/components';
import { KitchenPositionGuideLiveConsumer, KitchenPositionGuideLiveProvider } from '@app/features/kitchen-positioning';
import { KitchenTiles } from './components/kitchen-tiles';
import { LiveDayblock } from './components/live-dayblock';
import { WidgetBoxIcon } from '@app/features/kitchen-positioning/icons';

export type PositionGuideScreenProps = MainScreenProps<'PositionGuide'>;

export const PositionGuideScreen: React.FC<PositionGuideScreenProps> = ({ navigation, route }) => {
  const { t } = useTranslation();
  const { width: screenWidth } = useWindowDimensions();
  const [isSmallScreen, isLargeScreen] = useMediaQuery([{ maxWidth: 1200 }, { maxWidth: 1400 }]);
  const tabSize = useBreakpointValue({ base: 60, xl: 70 });
  const { translate } = useAppLocale();
  const { siteConfig } = useSiteConfig();
  const [tabFocus, setTabFocus] = useState<number>(0);

  const dispatch = useAppDispatch();
  const storeHoursConfig = useStoreHoursConfig();
  const rectSize = screenWidth / 3;

  const handlePressMore = () => {
    dispatch(appConfigSlice.actions.toggleWidget());
  };

  const handleNavigateTo = (screen: keyof MainNavigationParams, params?: any) => {
    navigation.navigate(screen, {
      siteId: route.params.siteId,
      ...params,
    });
  };

  return (
    <MainContainer>
      <HStack w="full" h="full" bgColor="white">
        <KitchenPositionGuideLiveProvider siteConfig={siteConfig}>
          <VStack flex={1}>
            <HStack position="relative" alignItems="center" justifyContent="space-between" px={4} height={60} borderBottomWidth={1}>
              <ModuleTitle name={route.name} defaultText="Positioning Guide" />
              <ModalAppDocuments
                identifierName="Module.Home"
                moduleType="Positioning Guide"
                trigger={triggerProps => {
                  if (!triggerProps.documents?.length) return undefined;
                  return (
                    <IconButton
                      rounded="lg"
                      borderWidth={1}
                      borderColor="gray.300"
                      ml={2}
                      p={0}
                      w={9}
                      h={9}
                      icon={<LightBulbIcon size="md" />}
                      onPress={triggerProps.onPress}
                    />
                  );
                }}
              />

              <KitchenPositionGuideLiveConsumer>
                {livePositionGuide => (
                  <>
                    {!!rectSize && (
                      <HStack space={2} position="absolute" alignItems="center" left={rectSize - tabSize} zIndex={1} width={rectSize}>
                        <Box flex={1}></Box>
                        <LiveDayblock />
                        <Box flex={1} overflow="visible">
                          {!!livePositionGuide.data?.positionSchedule?.confirmedTime && (
                            <Box minWidth={150}>
                              <Text size="sm" fontWeight={500} color="gray.700" lineHeight="xs" numberOfLines={2}>
                                {t('common:last_update')}:{'\n'}
                                <Text size="sm" fontWeight={600} color="gray.700" lineHeight="xs" numberOfLines={1}>
                                  {formatTo(livePositionGuide.data?.positionSchedule?.confirmedTime, 'lll')}
                                </Text>
                              </Text>
                            </Box>
                          )}
                        </Box>
                      </HStack>
                    )}

                    {isLargeScreen && livePositionGuide.data?.weeklyFocusSetting?.title && (
                      <HStack space={2} justifyContent="flex-end">
                        {isSmallScreen && (
                          <ButtonGroup value={tabFocus} onChange={setTabFocus}>
                            <ButtonGroup.Item alignItems="center" value={0} minW={70}>
                              {t('common:positions')}
                            </ButtonGroup.Item>
                            <ButtonGroup.Item alignItems="center" value={1}>
                              {translate(
                                livePositionGuide.data?.weeklyFocusSetting?.title,
                                livePositionGuide.data?.weeklyFocusSetting?.translations,
                              )}
                            </ButtonGroup.Item>
                          </ButtonGroup>
                        )}
                        <Pressable onPress={handlePressMore} alignItems="center" mt={-0.5}>
                          <WidgetBoxIcon size="45px" color="white" />
                        </Pressable>
                      </HStack>
                    )}
                  </>
                )}
              </KitchenPositionGuideLiveConsumer>
            </HStack>

            <Box flex={1}>
              <KitchenTiles storeHoursConfig={storeHoursConfig} onNavigateTo={handleNavigateTo} tabFocus={tabFocus} />
            </Box>
          </VStack>
        </KitchenPositionGuideLiveProvider>

        {!isLargeScreen && (
          <View width={300} borderLeftWidth={1}>
            <WidgetContainer />
          </View>
        )}
      </HStack>
    </MainContainer>
  );
};
