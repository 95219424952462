import { useDeferredValue, useMemo, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import Constants from 'expo-constants';
import { useBoolean } from 'usehooks-ts';
import { Box, Divider, FlatList, HStack, IconButton, Image, Popover, Pressable, VStack, useMediaQuery } from 'native-base';
import FeatherIcons from 'react-native-vector-icons/Feather';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { find, map, pick, reduce, startCase, toLower, values } from 'lodash';

import { ChevronSelectorVerticalIcon, NavBar, NavDropdown, SearchField, SettingsIcon, Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { NavSectionModuleDto } from '@pimm/services/lib/sms-tenants';
import { useAppDispatch, useAppSelector } from '@app/store/store';
import { appConfigSlice } from '@app/store/slices/appConfigSlice';
import { RootStackNavigation } from '@app/navigations/root';
import { CreateATicketLink, HelpCenterLink } from '@app/consts/hyperlinks';
import { completeAddress } from '@app/utils/complete-address';
import { DropdownCultures, useAppTheme, useAuthPermissions } from '@app/features/app';
import { DashboardModalType, useDashboard } from '@app/features/dashboard';
import { Clock, DayblockCountdown } from '../shared';

type DropdownAccessorKeys = 'settings' | 'help-center' | 'create-a-ticket';

type HeaderProps = { onChangeStore?: (siteId?: string) => void };

export const Header = (props: HeaderProps) => {
  const [isTabletScreen] = useMediaQuery({ maxWidth: 1200 });
  const navigation = useNavigation<RootStackNavigation>();
  const dispatch = useAppDispatch();
  const { translate } = useAppLocale();
  const { brand } = useAppTheme();
  const { storeFocus, storeList, userProfile } = useAppSelector(state => state);
  const { toggleModal } = useDashboard();
  const [keyword, setKeyword] = useState<string>();
  const [dropdownItems] = useState<{ accessorKey: DropdownAccessorKeys; icon: React.ReactElement; text: string; url?: string }[]>([
    { accessorKey: 'settings', icon: <SettingsIcon size="sm" />, text: 'Settings' },
    { accessorKey: 'help-center', icon: <FeatherIcons name="help-circle" size={16} />, text: 'Help Center', url: HelpCenterLink },
    {
      accessorKey: 'create-a-ticket',
      icon: <MaterialCommunityIcons name="ticket-confirmation-outline" size={16} />,
      text: 'Create a Ticket',
      url: CreateATicketLink,
    },
  ]);
  const searchValue = useDeferredValue(keyword);
  const popoverIsOpen = useBoolean();
  const popoverIsEnabled = storeList.status === 'fulfilled' && storeList.data?.length > 1;
  const headerTitle = Constants.expoConfig?.extra?.name ?? '';

  const titleBar = useMemo(() => {
    const platform = brand?.navSections?.find(_ => _.appType?.toLowerCase() === 'dop');
    const title = platform?.sections?.reduce((_title: NavSectionModuleDto | undefined, _section) => {
      if (_title) return _title;
      return _section.modules?.find(_ => _.identifier?.toLowerCase() === 'doptitle');
    }, undefined);

    return title;
  }, [brand, headerTitle]);

  const stores = useMemo(() => {
    const stores = storeList.data ?? [];
    if (!searchValue) return stores;
    return stores.filter(store => {
      const loweredValue = searchValue.toLowerCase();
      return (
        store?.name?.toLocaleLowerCase().includes(loweredValue) ||
        store?.address?.address1?.toLocaleLowerCase().includes(loweredValue) ||
        store?.address?.city?.toLocaleLowerCase().includes(loweredValue)
      );
    });
  }, [searchValue, storeList.data]);

  const fullName = values(pick(userProfile.data, ['firstName', 'lastName']))
    .filter(Boolean)
    .join(' ');

  const handleChangeStore = store => () => {
    if (storeFocus.data?.store?.id !== store.id) {
      // if (onChangeStore) onChangeStore(store.id);
      // // Update navigation params
      // const navigationState = navigation.getState();
      // // reset all main screen params and state
      // navigation.dispatch(
      //   CommonActions.reset({
      //     index: navigationState.index,
      //     routes: navigationState.routes.map(route => ({
      //       name: route.name,
      //       params: { siteId: store.id },
      //     })),
      //   }),
      // );
      // popoverIsOpen.setFalse();
    }
  };

  const handlePopoverClose = () => {
    popoverIsOpen.setFalse();
    setKeyword(undefined);
  };

  const handlePressMore = () => {
    dispatch(appConfigSlice.actions.toggleWidget());
  };

  const handlePressInfo = () => {
    toggleModal({ type: DashboardModalType.StoreConfig });
  };

  const handlePressDropdownItem = (accessorKey: DropdownAccessorKeys) => () => {
    const item = find(dropdownItems, ['accessorKey', accessorKey]);
    if (item?.accessorKey === 'settings') {
      toggleModal({ type: DashboardModalType.AccountMenu });
    } else if (item?.url) window.open(item.url, '_blank');
  };

  const handlePressLogout = () => {
    localStorage.removeItem('token');
    navigation.navigate('Login');
  };

  const hasStore = !!storeFocus.data?.store;

  return (
    <NavBar
      _container={isTabletScreen ? { pr: 0 } : undefined}
      title={
        <HStack alignItems="center">
          <Box alignItems="center" w={{ md: '60px', xl: '70px' }}>
            <Image
              alt={translate(titleBar?.title ?? headerTitle, titleBar?.titleTranslations)}
              source={{
                uri: titleBar?.iconUrl,
              }}
              rounded="full"
              resizeMode="contain"
              resizeMethod="scale"
              size={10}
            />
          </Box>
          <Text color="white" size="xl" fontWeight={700}>
            {translate(titleBar?.title ?? headerTitle, titleBar?.titleTranslations)}
          </Text>
        </HStack>
      }
      more={
        isTabletScreen ? (
          <Pressable pr={1.5} alignItems="center" justifyContent="center" h="full" onPress={handlePressMore}>
            <FeatherIcons name="more-vertical" color="white" size={22} />
          </Pressable>
        ) : undefined
      }
      rightComponent={
        <HStack space={3} alignItems="center">
          {!isTabletScreen && hasStore && (
            <Clock px={2} rounded="md" borderWidth={1} borderColor="white" _text={{ size: 'xl', fontWeight: 700, color: 'white' }} />
          )}

          {hasStore && (
            <>
              <DayblockCountdown />

              <Divider height="22px" bg="gray.50" orientation="vertical" />
            </>
          )}

          <DropdownCultures
            cultures={storeFocus.data.store?.companyInfo?.brand?.availableCultures}
            defaultCulture={storeFocus.data.store?.culture}
          />

          <Divider height="22px" bg="gray.50" orientation="vertical" />

          <NavDropdown
            mr={3}
            accessibilityLabel="Account Dropdown"
            person={{ name: startCase(toLower(fullName)), email: userProfile.data?.contactEmail }}
            onPressLogout={handlePressLogout}
          >
            {hasStore &&
              map(dropdownItems, item => (
                <NavDropdown.Item
                  key={item.accessorKey}
                  icon={item.icon}
                  text={item.text}
                  onPress={handlePressDropdownItem(item.accessorKey)}
                />
              ))}
          </NavDropdown>
        </HStack>
      }
    >
      {storeFocus.data?.store ? (
        <Popover
          isKeyboardDismissable
          isOpen={popoverIsOpen.value}
          onClose={handlePopoverClose}
          onOpen={popoverIsOpen.setTrue}
          trigger={triggerProps => (
            <HStack space={1} alignItems="center" w={360}>
              <Box flex={1} />
              <Pressable
                rounded="lg"
                w={300}
                bg="white"
                isDisabled={!popoverIsEnabled}
                _web={{ style: { cursor: 'pointer' } }}
                {...triggerProps}
              >
                <HStack space={2} alignItems="center" justifyContent="center" px={3} h={8} rounded="md">
                  <Text
                    color="primary.600"
                    size="lg"
                    textTransform="uppercase"
                    textAlign="center"
                    fontWeight={700}
                    lineHeight="xs"
                    numberOfLines={1}
                  >
                    {storeFocus.data?.store?.name ?? ''}
                  </Text>
                  {popoverIsEnabled && (
                    <Box position="absolute" right={1}>
                      <ChevronSelectorVerticalIcon size={5} color="primary.600" />
                    </Box>
                  )}
                </HStack>
              </Pressable>
              <Box flex={1}>
                <IconButton rounded="full" p={0} icon={<FeatherIcons name="info" size={20} color="white" />} onPress={handlePressInfo} />
              </Box>
            </HStack>
          )}
        >
          <Popover.Content accessibilityLabel="Select Store" borderWidth={0} shadow="5">
            <Popover.Arrow zIndex={0} borderWidth={0} bgColor="white" />
            <Popover.Body p={0} w="full" bgColor="white" minWidth="320px">
              <Box py={2} px={3}>
                <Text size="lg" fontWeight={600} color="gray.700">
                  Select a Store
                </Text>
              </Box>
              {storeList.data?.length > 6 && (
                <Box py={1.5} px={2} bgColor="gray.50">
                  <SearchField
                    size="sm"
                    borderColor="gray.200"
                    value={keyword}
                    onChangeText={setKeyword}
                    onClear={() => setKeyword(undefined)}
                  />
                </Box>
              )}
              {popoverIsEnabled && (
                <FlatList
                  width="full"
                  w={360}
                  maxHeight={290}
                  borderTopWidth={1}
                  borderTopColor="gray.200"
                  backgroundColor="white"
                  data={stores}
                  ItemSeparatorComponent={() => <Box h="1px" backgroundColor="gray.200" />}
                  renderItem={({ item }) => {
                    const isFocused = item.id === storeFocus.data.store?.id;
                    return (
                      <Pressable position="relative" isFocused={false} onPress={handleChangeStore(item)}>
                        {({ isHovered }) => (
                          <VStack
                            justifyContent="center"
                            py={1}
                            px={3}
                            minHeight={12}
                            backgroundColor={isFocused || isHovered ? 'gray.100' : undefined}
                          >
                            <Text size="md" fontWeight={600} color={isFocused ? 'black' : 'gray.700'} lineHeight="sm">
                              {item.name}
                            </Text>
                            <Text size="sm" color={isFocused ? 'black' : 'gray.700'} lineHeight="sm" numberOfLines={1} ellipsizeMode="tail">
                              {completeAddress(item.address)}
                            </Text>
                          </VStack>
                        )}
                      </Pressable>
                    );
                  }}
                />
              )}
            </Popover.Body>
          </Popover.Content>
        </Popover>
      ) : null}
    </NavBar>
  );
};
