import { Controller } from 'react-hook-form';
import { Box, VStack } from 'native-base';

import { ButtonGroup, Text } from '@pimm/base';
import { useFormSiteGoals } from '../hooks';
import { FormSiteGoals } from './form-site-goals';

type SettingsStoreGoalsProps = {
  form: ReturnType<typeof useFormSiteGoals>;
};

export const SettingsStoreGoals = ({ form }: SettingsStoreGoalsProps) => {
  return (
    <VStack>
      <VStack
        position="sticky"
        top={0}
        justifyContent="center"
        pt={2}
        pb={3}
        px={4}
        minH="58px"
        borderBottomWidth={1}
        zIndex={1}
        bg="white"
      >
        <Text size="xl" fontWeight={700} color="gray.900">
          Store Goals
        </Text>
        <Text size="md" fontWeight={500} color="gray.900" lineHeight="xs">
          Goals need to be set per site for each daypart. Select goal profiles from Brand or Customer level or configure your own custom
          time goals. Time durations are set by number of seconds.
        </Text>
      </VStack>

      {/* Days of the week */}
      <VStack space={3} px={4} py={3} borderBottomWidth={1}>
        <Text size="xl" fontWeight={700} color="gray.900" lineHeight="xs">
          Days of the week
        </Text>

        <Controller
          name="isSevenDays"
          control={form.control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
            return (
              <ButtonGroup value={value} onChange={onChange} w="full" minH="68px">
                {[
                  {
                    title: '7-day Goal',
                    caption: 'Goals are the same for each day of the week',
                    value: true,
                  },
                  {
                    title: 'Weekday/Weekend',
                    caption: 'Goals are set independently for weekdays and weekends',
                    value: false,
                  },
                ].map(tab => (
                  <ButtonGroup.Item key={tab.title} value={tab.value} flex={1} justifyContent="flex-start" h="container">
                    <Box w="full" px={2} overflow="hidden">
                      <Text size="md" fontWeight={700} color="gray.900">
                        {tab.title}
                      </Text>
                      <Text size="md" fontWeight={500} color="gray.900" numberOfLines={2} ellipsizeMode="tail" lineBreakMode="tail">
                        {tab.caption}
                      </Text>
                    </Box>
                  </ButtonGroup.Item>
                ))}
              </ButtonGroup>
            );
          }}
        />
      </VStack>

      <Box px={4}>
        <FormSiteGoals form={form} />
      </Box>
    </VStack>
  );
};
