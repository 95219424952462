import { useCallback, useEffect, useMemo, useState } from 'react';
import { find } from 'lodash';
import moment from 'moment';
import { DayBlock, useStoreHoursConfig } from '@pimm/common';

export const useDayBlockFocus = (
  defaultDate?: string | Date,
  defaultBlockNumber?: number,
): {
  selected?: DayBlock;
  selectAll: DayBlock[];
  selectNow: () => DayBlock;
  changeBlockNumber: (number: number) => void;
  reset: (date?: Date) => void;
} => {
  const storeHoursConfig = useStoreHoursConfig();

  let date: Date | undefined = undefined;

  if (defaultDate instanceof Date) date = defaultDate;
  else if (typeof defaultDate === 'string') date = moment(defaultDate).toDate();
  if (!moment(date).isValid()) date = undefined;

  const [blockNumber, setBlockNumber] = useState<number | undefined>(defaultBlockNumber);
  const [dayBlocks, setDayBlocks] = useState<DayBlock[]>(storeHoursConfig.selectAllDayBlocks(date));

  const memoizeBlock = useMemo(() => {
    const date = new Date().targetOffset(storeHoursConfig.utcOffset);
    const dayBlock = find<DayBlock>(dayBlocks, _ => {
      if (blockNumber) return _.blockNumber === blockNumber;
      return date >= _.startTime && date <= _.endTime;
    });

    return dayBlock ?? dayBlocks[0];
  }, [blockNumber, dayBlocks, storeHoursConfig.utcOffset]);

  const resetDayBlocks = useCallback((date?: Date) => {
    const nextDayBlocks = storeHoursConfig.selectAllDayBlocks(date);
    setDayBlocks(nextDayBlocks);
    setBlockNumber(undefined);
  }, []);

  return {
    selected: memoizeBlock,
    selectAll: dayBlocks,
    selectNow: storeHoursConfig.selectDayBlock,
    changeBlockNumber: setBlockNumber,
    reset: resetDayBlocks,
  };
};
