import { Dispatch, SetStateAction, useState } from 'react';
import { QueryKey, UseQueryResult, useQuery } from 'react-query';

import { GetKitchenLayout } from '@pimm/services/lib/sms-positioning/services';
import { GetKitchenLayoutQueryParams } from '@pimm/services/lib/sms-positioning';

export const useGetKitchenLayout = (props?: {
  queryKey?: string;
  query?: GetKitchenLayoutQueryParams;
}): [UseQueryResult<Awaited<ReturnType<typeof GetKitchenLayout>>>, Dispatch<SetStateAction<GetKitchenLayoutQueryParams>>] => {
  const [query, setQueryParams] = useState<GetKitchenLayoutQueryParams>(props?.query ?? {});

  const kitchen = useQuery({
    enabled: !!query?.siteId,
    queryKey: [props?.queryKey ?? 'GetKitchenLayout', query] as [QueryKey, GetKitchenLayoutQueryParams],
    queryFn: ({ queryKey }) => {
      return GetKitchenLayout(queryKey[1]);
    },
    cacheTime: 0,
  });

  return [kitchen, setQueryParams];
};
