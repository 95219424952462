import { Icon, IIconProps } from 'native-base';
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg';

export const OpenDoorIcon = ({ color = 'warning.500', size = 'sm', ...props }: IIconProps) => {
  return (
    <Icon size={size} viewBox="0 0 15 13" color={color} fill="none" {...props}>
      <G clipPath="url(#clip0_3701_2638)">
        <Path
          d="M4.5975 7.62681C4.17787 7.20718 3.94212 6.63804 3.94212 6.04459C3.94212 5.45114 4.17787 4.882 4.5975 4.46237M3.36688 8.85742C2.62087 8.11141 2.20177 7.09961 2.20177 6.04459C2.20177 4.98957 2.62087 3.97777 3.36688 3.23176"
          stroke="currentColor"
          strokeWidth="1.09091"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M10.4906 0.089112C10.3173 0.0137926 10.0779 0.0404616 9.93699 0.122365C9.79606 0.204158 9.72532 0.302576 9.67131 0.395415C9.5633 0.581204 9.52368 0.76777 9.52368 0.974758V11.1156C9.52368 11.3208 9.56263 11.5078 9.67131 11.6913C9.72565 11.783 9.79974 11.8785 9.92963 11.957C10.0595 12.0354 10.2665 12.0732 10.4389 12.0197L14.3764 10.7983C14.7345 10.6871 15 10.3045 15 9.8942V2.68334C15 2.26936 14.703 1.9172 14.4281 1.79768L10.4906 0.089112ZM10.5238 1.19247L14 2.70545V9.86833L10.5238 10.9459V1.19247Z"
          fill="currentColor"
        />
        <Path
          d="M7.05066 1.75335C6.53111 1.75335 6.17975 2.21585 6.17975 2.68699V9.39938C6.17975 9.87049 6.53114 10.333 7.05066 10.333H9.75626C10.031 10.332 10.2535 10.1095 10.2545 9.83481C10.2556 9.55864 10.0324 9.33391 9.75626 9.33302H7.17978V2.75318H9.75626C10.0324 2.75228 10.2556 2.52755 10.2545 2.25139C10.2535 1.97656 10.031 1.75417 9.75626 1.75317L7.05066 1.75335Z"
          fill="currentColor"
        />
        <Path
          d="M12.6064 6.04461C12.6064 6.49264 12.2432 6.85582 11.7951 6.85582C11.3471 6.85582 10.9839 6.49261 10.9839 6.04461C10.9839 5.59661 11.3471 5.2334 11.7951 5.2334C12.2432 5.2334 12.6064 5.59661 12.6064 6.04461Z"
          fill="currentColor"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_3701_2638">
          <Rect width="18" height="18" fill="white" />
        </ClipPath>
      </Defs>
    </Icon>
  );
};
