import { useState } from 'react';
import { Box, IIconProps } from 'native-base';
import { MessageRefTypeEnum } from '@pimm/services/lib/message-board';
import { WendysBrand } from '../assets/wendys-brand';
import { Building01Icon, Building02Icon } from '../icons';

export const RefTypeIcon = ({ size = 8, refType }: IIconProps & { refType?: MessageRefTypeEnum }) => {
  const [refIcon] = useState(
    {
      [MessageRefTypeEnum.Brand]: { icon: <WendysBrand /> },
      [MessageRefTypeEnum.Franchise]: { bg: 'purple.500', icon: <Building01Icon color="white" size="60%" /> },
      [MessageRefTypeEnum.Store]: { bg: 'gray.800', icon: <Building02Icon color="white" size="55%" /> },
    }[refType ?? MessageRefTypeEnum.Store],
  );

  return (
    <Box alignItems="center" justifyContent="center" w={size} h={size} rounded="full" bg={refIcon.bg}>
      {refIcon.icon}
    </Box>
  );
};
