import { ComponentProps, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { LayoutChangeEvent, LayoutRectangle } from 'react-native';
import { ArrowBackIcon, ArrowForwardIcon, Box, FlatList, HStack, VStack, useBreakpointValue } from 'native-base';
import { chunk, map } from 'lodash';

import { Button, Modal } from '@pimm/base';
import { EmployeeDto } from '@pimm/services/lib/sms-workforce';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { useFlowChartChecklist } from '../context';
import { FlowChartPosition } from '../reducers/flow-chart-checklist.reducer';
import FlowPositionChecklist from './flow-position-checklist';
import { SelectEligableEmployee } from './select-eligable-employee';
import { FlowPagination } from './flow-pagination';

type FlowChartChecklistProps = {
  isDisabled?: boolean;
};

export const FlowChartChecklist = ({ isDisabled }: FlowChartChecklistProps) => {
  const flatRef = useRef<any>(); // TODO: fix typeof ref
  const cols = useBreakpointValue({
    md: 2,
    lg: 3,
    xl: 5,
  });
  const { flowChart, completeTask, siteId, updatePosition } = useFlowChartChecklist();
  const modalPosition = useModalFocus<FlowChartPosition>();
  const [layout, setLayout] = useState<Pick<LayoutRectangle, 'width' | 'height'>>({ width: 0, height: 0 });
  const [page, setPage] = useState<number>(1);

  // create a group of positions base on the no. of columns
  const pages = useMemo(() => chunk(flowChart.positions, cols), [cols, flowChart.positions]);

  const handleChangeAssignee: ComponentProps<typeof SelectEligableEmployee>['onChange'] = assignee => {
    updatePosition({ positionId: modalPosition.payload?.positionId, assignee });
    modalPosition.setHide();
  };

  const handleChangeLayout = ({ nativeEvent }: LayoutChangeEvent) => {
    setLayout(nativeEvent.layout);
  };

  const handleChangePage = (page: number) => {
    if (flatRef.current) {
      flatRef.current.scrollToOffset({ animated: true, offset: layout.width * (page - 1) });
      setPage(page);
    }
  };

  useEffect(() => {
    // Reset the page number and scroll to offsetX = 0
    flatRef.current.scrollToOffset({ animated: false, offset: 0 });
    setPage(1);
  }, [flowChart.opsPhase]);

  return (
    <Fragment>
      <VStack w="full" h="full">
        {pages.length > 1 && (
          <HStack alignItems="center" justifyContent="space-between" py={2} px={4} borderBottomWidth={1}>
            <Button
              disabled={page === 1}
              variant="outline"
              minH={9}
              minWidth="90px"
              borderColor="gray.300"
              startIcon={<ArrowBackIcon color="gray.700" />}
              onPress={() => handleChangePage(page - 1)}
            >
              Previous
            </Button>

            <FlowPagination activePage={page} totalPages={pages.length} onChange={handleChangePage} />

            <Button
              disabled={page == pages.length}
              variant="outline"
              minH={9}
              minWidth="90px"
              borderColor="gray.300"
              endIcon={<ArrowForwardIcon color="gray.700" />}
              onPress={() => handleChangePage(page + 1)}
            >
              Next
            </Button>
          </HStack>
        )}

        <FlatList
          ref={flatRef}
          flex={1}
          w="full"
          onLayout={handleChangeLayout}
          data={pages}
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={{ height: 'full' }}
          contentOffset={{ x: 0, y: 0 }}
          pagingEnabled
          horizontal
          extraData={[cols, layout]}
          initialNumToRender={1}
          initialScrollIndex={0}
          decelerationRate={'normal'}
          scrollEventThrottle={16}
          renderItem={({ item: page, index }) => {
            if (layout.width === 0) return null;
            return (
              <HStack py={3} px={3} w={layout.width} h={layout.height}>
                {map(page, position => {
                  return (
                    <Box key={position.positionId} px={1} w={`1/${cols}`}>
                      <FlowPositionChecklist
                        opsPhaseId={flowChart.opsPhase?.id ?? 0}
                        position={position}
                        isDisabled={isDisabled}
                        onAssign={modalPosition.setOpen}
                        onChange={updatePosition}
                        onCompleteTask={completeTask}
                      />
                    </Box>
                  );
                })}
              </HStack>
            );
          }}
        />

        <Modal
          size="full"
          _content={{ rounded: 'xl', maxWidth: { md: 1080, xl: 1200 }, height: '95%', maxHeight: 900 }}
          isOpen={modalPosition.isOpen}
          noPadding
          hideClose
        >
          {!!flowChart.opsPhase && !!modalPosition.payload && (
            <SelectEligableEmployee
              assignee={modalPosition.payload.assignee}
              employees={flowChart.eligableEmployees}
              opsPhaseId={flowChart.opsPhase.id!}
              positionId={modalPosition.payload.positionId}
              salesVolumeProfileId={flowChart.salesVolumeProfileId!}
              siteId={siteId}
              startOfTask={modalPosition.payload.startTime}
              onChange={handleChangeAssignee}
              onClose={modalPosition.setHide}
            />
          )}
        </Modal>
      </VStack>
    </Fragment>
  );
};
