import { useBoolean } from 'usehooks-ts';
import { Box, ChevronDownIcon, ChevronUpIcon, HStack, IconButton, VStack, View, useBreakpointValue } from 'native-base';
import { map } from 'lodash';

import { Text } from '@pimm/base';
import { EquipmentInstancesDto } from '@pimm/services/lib/store-equipment';
import { InstanceTemperatureCard } from './instance-temperature-card';

type CollapsibleGroupProps = {
  orientation?: 'horizontal' | 'vertical';
  instanceId?: string;
  items?: EquipmentInstancesDto[];
  title: string;
  onPressItem?: (_equipment: EquipmentInstancesDto) => void;
};

export const CollapsibleGroup = ({ orientation = 'horizontal', instanceId, items, title, ...props }: CollapsibleGroupProps) => {
  const flexWidth = useBreakpointValue({ sm: '1/4', xl: '1/5', '2xl': '1/6' });
  const isCollapsed = useBoolean(true);
  const isHorizontal = orientation === 'horizontal';

  const handlePressEquipment = (equipment: EquipmentInstancesDto) => () => {
    if (props.onPressItem) props.onPressItem(equipment);
  };

  return (
    <VStack space={1}>
      <HStack alignItems="center" justifyContent="space-between" minH={10}>
        <Text size="xl" fontWeight={600} color="gray.900">
          {title}
        </Text>
        {!isHorizontal && (
          <IconButton
            p={1}
            _pressed={{ bg: 'gray.100' }}
            onPress={isCollapsed.toggle}
            icon={isCollapsed.value ? <ChevronUpIcon size={4} color="black" /> : <ChevronDownIcon size={4} color="black" />}
          />
        )}
      </HStack>

      {(isHorizontal || isCollapsed.value) && (
        <View flexDir={isHorizontal ? 'row' : 'column'} flexWrap={isHorizontal ? 'wrap' : undefined}>
          {map(items, _item => {
            if (!_item.TemperatureMetrics) return null;
            return (
              <Box
                key={_item.TemperatureMetrics.Temperature?.InstanceId}
                w={isHorizontal ? flexWidth : 'full'}
                pr={isHorizontal ? 2 : 0}
                pb={2}
              >
                <InstanceTemperatureCard
                  instance={_item.TemperatureMetrics}
                  doorInstance={_item.DoorMetrics}
                  isFocused={_item.TemperatureMetrics.Temperature?.InstanceId === instanceId}
                  onPress={handlePressEquipment(_item)}
                />
              </Box>
            );
          })}
        </View>
      )}
    </VStack>
  );
};
