import { useEffect, useState } from 'react';
import { LayoutChangeEvent, LayoutRectangle } from 'react-native';
import { Box, FlatList, HStack, Spacer, useTheme, View, VStack } from 'native-base';
import { find, first, range } from 'lodash';

import { Car01Icon, ClockStopWatchIcon, Text } from '@pimm/base';
import { Daypart, useSiteTime } from '@app/features/store-core';
import { DpTimerDataDto } from '@pimm/services/lib/sms-drivethru/types';
import { formatTo, stringToDateLocal } from '@app/utils/date-formatter';
import { ResourceLoader } from '@app/components/shared';
import { useGetTimerData } from '../hooks';
import { DaypartWidget } from './daypart-widget';
import { DaypartAnalytics } from './daypart-analytics';

type DaypartTimerGoals = Record<number, DpTimerDataDto | undefined>;

type SpeedOfServiceProps = {
  dayparts: Daypart[];
  timerData: ReturnType<typeof useGetTimerData>;
};

export const SpeedOfService = ({ dayparts, timerData }: SpeedOfServiceProps) => {
  const { colors } = useTheme();
  const siteTime = useSiteTime();
  const [tabs] = useState<number[]>(range(dayparts.length + 1));
  const [daypartFocus, setDaypartFocus] = useState<Daypart>(
    find<Daypart>(dayparts, _ => _.startTime.getTime() === siteTime.toDaypartNow().startTime.getTime()) ?? dayparts[0],
  );
  const [layout, setLayout] = useState<Pick<LayoutRectangle, 'width' | 'height'>>({ width: 0, height: 0 });
  const [daypartGoals, setDaypartGoals] = useState<DaypartTimerGoals>({});

  const handleChangeDaypart = (daypart: Daypart) => () => {
    setDaypartFocus(daypart);
  };

  const handleChangeLayout = ({ nativeEvent }: LayoutChangeEvent) => {
    setLayout(nativeEvent.layout);
  };

  useEffect(() => {
    const _timerData = dayparts.reduce((_dayparts: DaypartTimerGoals, dp) => {
      const dpTimerData = timerData.data?.timerData?.find(_ => {
        const dpStartTime = stringToDateLocal(_.dpStartTime);
        return dpStartTime && dpStartTime >= dp.startTime && dpStartTime < dp.endTime;
      });
      if (!dp.partNumber) return _dayparts;
      return { ..._dayparts, [dp.partNumber!]: dpTimerData };
    }, []);

    setDaypartGoals(_timerData);
  }, [dayparts, timerData.data]);

  return (
    <View h="full" w="full">
      <HStack space={3} borderBottomWidth={1} onLayout={handleChangeLayout}>
        {!!layout.width && (
          <FlatList
            pt={1.5}
            pb={2}
            px={3}
            data={tabs}
            contentOffset={{ x: 0, y: 0 }}
            extraData={[dayparts, layout.width]}
            horizontal
            initialNumToRender={7}
            decelerationRate={'normal'}
            scrollEventThrottle={16}
            renderItem={({ index }) => {
              const daypart = dayparts[index];
              const partNumber = daypart?.partNumber ?? 0;

              if (!daypart) {
                // 24 = total x-padding of the flatlist
                const totalCars = timerData.data?.dayTotals?.totalCars ?? 0;
                const avgTotalTime = timerData.data?.dayTotals?.avgLaneTotal ?? 0;

                return (
                  <Box pl={1} width={(layout.width - 24) / tabs.length} minWidth={180}>
                    <Box
                      rounded="lg"
                      justifyContent="center"
                      px={4}
                      h="full"
                      bg="blueLight.100"
                      borderColor="blueLight.600"
                      borderWidth={2}
                      borderStyle="dashed"
                    >
                      <VStack space={1.5}>
                        <Text size="md" fontWeight={700} color="blueLight.600" lineHeight="sm" textTransform="uppercase">
                          {formatTo(first(dayparts)?.startTime, 'ddd MM/DD') ?? ''}
                        </Text>

                        <VStack space={1}>
                          <HStack space={2} alignItems="center">
                            <ClockStopWatchIcon size="18px" color="black" />
                            <Text size="md" fontWeight={600} color="black">
                              Lane Total
                            </Text>
                            <Spacer />

                            <Text size="xl" fontWeight={700} color="black" lineHeight="sm" mr={1}>
                              {avgTotalTime}s
                            </Text>
                          </HStack>

                          <HStack space={2} alignItems="center">
                            <Car01Icon size="18px" color="black" />
                            <Text size="md" fontWeight={600} color="black">
                              Orders
                            </Text>
                            <Spacer />

                            <Text size="xl" fontWeight={700} color="black" lineHeight="sm" mr={1}>
                              {totalCars}
                            </Text>
                          </HStack>
                        </VStack>
                      </VStack>
                    </Box>
                  </Box>
                );
              }

              const daypartGoal = daypartGoals[partNumber];
              const timerDetails = daypartGoal?.details?.[0];
              const lanes = daypartGoal?.details?.length;

              return (
                <Box px={1} width={(layout.width - 24) / tabs.length} minWidth={220}>
                  <DaypartWidget
                    key={daypart.label}
                    title={daypart.label ?? ''}
                    daypart={daypart}
                    isActive={partNumber === daypartFocus.partNumber}
                    numOfLanes={lanes}
                    avgTotalTime={timerDetails?.avgTotalTime}
                    totalCars={timerDetails?.totalCars}
                    totalLane={daypartGoal?.siteDaypartGoal?.total}
                    onPress={handleChangeDaypart(daypart)}
                  />
                </Box>
              );
            }}
          />
        )}
      </HStack>

      <Box flex={1} bg="gray.50">
        <ResourceLoader h="full" isLoading={timerData.isIdle || timerData.isLoading}>
          {!!layout.width && (
            <DaypartAnalytics
              _container={{ px: 4 }}
              daypart={daypartFocus}
              timerData={daypartGoals[daypartFocus.partNumber ?? 0]}
            />
          )}
        </ResourceLoader>
      </Box>
    </View>
  );
};
