import { Icon, IIconProps } from 'native-base';
import { Path, G } from 'react-native-svg';

export const ElectricityIcon = ({ color = 'currentColor', size = 22, ...props }: IIconProps) => {
  return (
    <Icon color={color} size={size} viewBox="0 0 20 22" {...props}>
      <Path
        d="M11.0001 1L2.09356 11.6879C1.74475 12.1064 1.57035 12.3157 1.56768 12.4925C1.56537 12.6461 1.63384 12.7923 1.75336 12.8889C1.89085 13 2.16328 13 2.70814 13H10.0001L9.00011 21L17.9067 10.3121C18.2555 9.89358 18.4299 9.68429 18.4325 9.50754C18.4348 9.35388 18.3664 9.2077 18.2468 9.11111C18.1094 9 17.8369 9 17.2921 9H10.0001L11.0001 1Z"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
