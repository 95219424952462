import { EmpLearningPathStatusEnum } from '@pimm/services/lib/sms-workforce';

export const learningPathProgressValue = (value?: string): number => {
  let progress = Number(value);
  if (isNaN(progress) || !progress) return 0;
  return progress;
};

export const getLearningPathMetaStats = (
  status?: EmpLearningPathStatusEnum,
): { actionText: string; displayText: string; fillColor: string } => {
  switch (status) {
    case EmpLearningPathStatusEnum.Complete:
      return {
        actionText: 'Review',
        displayText: 'Completed',
        fillColor: 'success.500',
      };
    case EmpLearningPathStatusEnum.Started:
      return {
        actionText: 'Resume',
        displayText: 'Started',
        fillColor: 'warning.400',
      };
    case EmpLearningPathStatusEnum.Enrolled:
      return {
        actionText: 'Start',
        displayText: 'Enrolled',
        fillColor: 'gray.200',
      };
    default:
      return {
        actionText: 'Start',
        displayText: 'Pending',
        fillColor: 'gray.200',
      };
  }
};

export const assignmentMapping = [
  {
    dataField: 'servicePositionAssignments',
    title: 'Service Position Assignments',
    caption: 'Shift Checkpoints Assigned',
  },
  {
    dataField: 'nonServicePositionAssignments',
    title: 'Non-Service Assignments',
    caption: 'Breakfast Open & Lunch Readiness',
  },
  {
    dataField: 'positionalGoalAssignments',
    title: 'Daily Positional Goal Assignments',
    caption: null,
  },
];
