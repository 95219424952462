import { useState } from 'react';
import { Box, HStack, VStack, View, Image } from 'native-base';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { findIndex, forEach, isEqual } from 'lodash';

import { Modal, Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { GoalTypeDto, WeeklyFocusCategoryDto, WeeklyFocusSettingDto } from '@pimm/services/lib/sms-positioning';
import { AddUpdateWeeklyFocusRequest, WeeklyFocusDto, WeeklyFocusItemDto, WeeklyFocusItemInput } from '@pimm/services/lib/sms-workforce';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { WeeklyFocusItemList } from './weekly-focus-item-list';
import { WeeklyFocusInputBox } from './weekly-focus-input-box';
import { GoalTypesSelection } from './goal-type-selection';

type WeeklyFocusFormProps = {
  goalTypes?: GoalTypeDto[];
  isEditable?: boolean;
  weeklyFocus?: WeeklyFocusDto;
  weeklyFocusSettings: WeeklyFocusSettingDto;
  onChange: (payload: Partial<AddUpdateWeeklyFocusRequest>) => void;
};

export const WeeklyFocusForm = ({ isEditable, goalTypes, weeklyFocus, weeklyFocusSettings, ...props }: WeeklyFocusFormProps) => {
  const { translate } = useAppLocale();
  const modalGoal = useModalFocus<{ category: WeeklyFocusCategoryDto; input?: WeeklyFocusItemInput }>();
  const [numOfAreas] = useState(3);

  const handleAssignGoal = (input: WeeklyFocusItemInput) => {
    const focusItems: WeeklyFocusItemInput[] = [...(weeklyFocus?.focusItems ?? [])];
    if (modalGoal.payload) {
      const prevInput = modalGoal.payload.input;
      if (prevInput) {
        const index = findIndex(focusItems, item => isEqual(item, prevInput));
        if (index !== -1) focusItems[index] = input;
      } else focusItems.push(input);
      props.onChange({ focusItems });
    }
    modalGoal.setHide();
  };

  const handleAddGoalType = (goalType: GoalTypeDto) => {
    if (goalType.id) {
      const goalTypeIds = [...(weeklyFocus?.goalTypeIds ?? [])];
      const index = findIndex(goalTypeIds, prev => isEqual(prev, goalType.id));

      if (index !== -1) goalTypeIds.splice(index, 1);
      else goalTypeIds.push(goalType.id);

      if (goalTypeIds.length > numOfAreas) return;
      props.onChange({ goalTypeIds });
    }
  };

  const handleRemoveGoal = (categoryId: number, input?: WeeklyFocusItemDto) => {
    const focusItems: WeeklyFocusItemDto[] = [];

    forEach(weeklyFocus?.focusItems, item => {
      // Skip item that is part of the different category
      if (item.weeklyFocusCategoryId !== categoryId) focusItems.push(item);
      // Check each item if goal is provided or do nothing and remove all
      else if (input && !isEqual(item, input)) {
        focusItems.push(item);
      }
    });
    props.onChange({ focusItems });
  };

  return (
    <View height="full">
      {/* SMG Surveys */}
      <HStack space={{ md: 2, xl: 4 }} alignItems="center" justifyContent="space-between" py={2} px={5} borderBottomWidth={1}>
        <HStack alignItems="center" justifyContent="space-between" py={2} px={3} rounded="2xl" bg="error.500">
          <Image source={{ uri: weeklyFocusSettings.headerImg }} style={{ width: 90, height: 100 }} resizeMode="contain" />
          <Box w={{ md: '280px', xl: '120px' }}>
            <HStack justifyContent="flex-start">
              <MaterialCommunityIcons name="format-quote-open" size={18} color="white" style={{ marginTop: -4 }} />
              <Text size="sm" fontWeight={500} lineHeight="2xs" color="white">
                {translate(weeklyFocusSettings?.headerImgCaption, weeklyFocusSettings?.headerImgCaptionTranslations)}
                <MaterialCommunityIcons name="format-quote-close" size={18} color="white" style={{ alignSelf: 'flex-end' }} />
              </Text>
            </HStack>
          </Box>
        </HStack>

        <VStack flex={1}>
          <Text size="xl" fontWeight={700} lineHeight="xs" color="black">
            {translate(weeklyFocusSettings.subHeader, weeklyFocusSettings?.subHeaderTranslations)}
          </Text>
          <Text size="md" fontWeight={500} lineHeight="xs" color="gray.600">
            {translate(weeklyFocusSettings.subHeaderCaption, weeklyFocusSettings?.subHeaderCaptionTranslations)}
          </Text>

          <GoalTypesSelection
            _container={{ space: 1, mt: 3, height: '50px' }}
            _score={{ size: 'lg' }}
            goals={weeklyFocus?.goalTypeIds}
            goalTypes={goalTypes}
            onAddGoalType={handleAddGoalType}
          />
        </VStack>
      </HStack>

      {/* Weekly Categories */}
      <View flex={1} bg="gray.50">
        <WeeklyFocusItemList
          categories={weeklyFocusSettings.categories}
          focusItems={weeklyFocus?.focusItems}
          goalLimitPerCategory={weeklyFocusSettings.goalLimitPerCategory}
          isEditable={isEditable}
          onPressAddUpdate={(category, itemInput) => modalGoal.setOpen({ category: category, input: itemInput })}
          onPressRemove={handleRemoveGoal}
        />
      </View>

      <Modal isOpen={modalGoal.isOpen} noPadding size="lg" _content={{ rounded: 'lg', maxWidth: 600 }} hideClose>
        {!!modalGoal.payload && (
          <WeeklyFocusInputBox
            category={modalGoal.payload.category}
            input={modalGoal.payload.input}
            onPressCancel={modalGoal.setHide}
            onPressSave={handleAssignGoal}
          />
        )}
      </Modal>
    </View>
  );
};
