import { UseMutationOptions, UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { EnableInstance, DisableInstance } from '@pimm/services/lib/store-equipment/services';
import { DisableInstanceRequest } from '@pimm/services/lib/store-equipment';

interface MutateDisableEnableVariables extends DisableInstanceRequest {
  isEnabled?: boolean;
  siteId: string;
}

export const useMutateDisableEnable = (
  props?: UseMutationOptions<Awaited<ReturnType<typeof DisableInstance>>, unknown, MutateDisableEnableVariables>,
): UseMutationResult<unknown, unknown, MutateDisableEnableVariables> => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ isEnabled, siteId, ...params }: MutateDisableEnableVariables) =>
      isEnabled ? DisableInstance(siteId, params) : EnableInstance(siteId, params),
    {
      onSuccess: async (data, variables, context) => {
        const queryCache = queryClient.getQueryCache();
        const cachedQuery = queryCache
          .getAll()
          .find(cache => cache.queryKey.includes('GetDisplayGroups') && cache.queryKey[1] === variables.siteId);

        if (cachedQuery) {
          queryClient.invalidateQueries({ queryKey: cachedQuery.queryKey });
          await queryClient.refetchQueries({ queryKey: cachedQuery.queryKey });
        }

        if (props?.onSuccess) props.onSuccess(data, variables, context);
      },
      onError: (error, variables, context) => {
        console.error('Error toggling sensor status:', error);

        // Execute the user-provided onError callback if it exists
        if (props?.onError) {
          props.onError(error, variables, context);
        }
      },
    },
  );

  return mutation;
};
