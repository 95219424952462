import { memo, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Box, CloseIcon, HStack, IconButton, Pressable, Spacer, Spinner, VStack, useMediaQuery, useTheme } from 'native-base';
import Octicons from 'react-native-vector-icons/Octicons';
import { filter, get, isEmpty, map, startCase, toLower } from 'lodash';

// React JS imports
import { useDrag, useDrop } from 'react-dnd';

import {
  ArrowCircleBrokenLeftIcon,
  Button,
  ChevronSelectorVerticalIcon,
  DragVerticalIcon,
  MinusCircleIcon,
  PlusCircleIcon,
  ReverseLeftIcon,
  Text,
  UserPlusIcon,
} from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { AddUpdateAssigneeRequest, PositionSlot, SmsWorkforceApi } from '@pimm/services/lib/sms-workforce';
import { formatToISOString, stringToDateLocal } from '@app/utils/date-formatter';
import { hexToRGBA } from '@app/utils/string-formatter';
import { DayBlock, useSiteTime } from '@app/features/store-core';
import { useKitchenLayout } from '@app/features/kitchen-positioning';
import { PositioningEmployee, PositioningSlot } from '../reducers';
import { PositioningPlanContextReturn } from '../context';
import { ArrowSquareUpIcon, UnionIcon } from '../icons';
import { EmployeeShiftTime } from './employee-shift-time';

type DroppablePositionSlotProps = {
  dayBlock?: DayBlock;
  isDisabled?: boolean;
  position: PositioningSlot;
  subBlockTime?: string;
  onChange: (position: Partial<PositioningSlot>, _position?: Partial<PositioningSlot>) => void;
  onDeleteAssignee?: (position: Partial<PositioningSlot>) => void;
  onMoveTo: PositioningPlanContextReturn['moveTo'];
  onEditPosition?: (isPrimary?: boolean) => void;
  onReplaceAssignee?: (position: Partial<PositioningSlot>, employee: Partial<PositioningEmployee>) => void;
  onSelect?: () => void;
};

const DroppablePositionSlot = ({ dayBlock, isDisabled, position, subBlockTime, ...props }: DroppablePositionSlotProps) => {
  const { colors } = useTheme();
  const [isLargeScreen] = useMediaQuery({ maxWidth: 1200 });
  const { translate } = useAppLocale();
  const siteTime = useSiteTime();
  const { positionLookup = {} } = useKitchenLayout();
  const [assignee, setAssignee] = useState<PositioningEmployee | undefined>(position.assignee);

  const addUpdateJob = useMutation({ mutationFn: SmsWorkforceApi.AddUpdateSlotJob });
  const addUpdateSlot = useMutation({ mutationFn: SmsWorkforceApi.AddUpdateSlot });
  const assignEmployee = useMutation({ mutationFn: SmsWorkforceApi.AssignEmployee });
  const deleteAssignee = useMutation({ mutationFn: SmsWorkforceApi.DeleteAssignee });
  const swapAssignees = useMutation({ mutationFn: SmsWorkforceApi.SwapAssignees });

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: 'PositioningAssignee',
    item: position,
    canDrop: () => !isDisabled && !!position.assignee?.employeeId,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: 'PositioningAssignee',
    canDrop: () => !isDisabled,
    drop: async (_dropped: PositioningEmployee | PositionSlot) => {
      const timeNow = siteTime.today();
      const blockStartTime = stringToDateLocal(subBlockTime) ?? dayBlock?.startTime;
      const startTime = blockStartTime && blockStartTime > timeNow ? blockStartTime : timeNow;

      // For assign, replace or swap
      if ('assignee' in _dropped) {
        // Swap position assignees
        const _position = _dropped as PositioningSlot;
        if (position.assignee?.employeeId !== _position.assignee?.employeeId) {
          swapAssignees.mutate({
            positionJobId1: _position.positionJobId,
            employeeId1: _position.assignee!.employeeId,
            positionJobId2: position.positionJobId,
            employeeId2: position.assignee?.employeeId,
            subBlockDateTime: formatToISOString(startTime),
          });
          props.onChange(position, _position);
        }
        return;
      }

      // Replace position slot assignee from available employee
      if (!!position.assignee && 'name' in _dropped) {
        const employee = _dropped as PositioningEmployee;
        setAssignee(employee);
        const payload: AddUpdateAssigneeRequest = {
          id: position.assignee.id,
          positionJobId: position.positionJobId,
          employeeId: employee.employeeId,
        };
        assignEmployee.mutateAsync(payload);
        if (props.onReplaceAssignee) props.onReplaceAssignee(position, employee);
        return;
      }

      if (!position.assignee) {
        // Assign new employee
        const employee = _dropped as PositioningEmployee;
        setAssignee(employee);
        const payload: AddUpdateAssigneeRequest = {
          positionJobId: position.positionJobId,
          employeeId: employee.employeeId,
          startTime: formatToISOString(startTime),
        };
        const result = await assignEmployee.mutateAsync(payload);
        props.onChange({
          ...position,
          assignee: { id: result.id, ...employee, positionJobId: position.positionJobId, positionSlotId: position.id },
        });
      }
    },
    collect: monitor => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
  }));

  const kitchenPosition = position.positionId ? positionLookup[position.positionId] : undefined;
  const originalPosition = position.origPositionId ? positionLookup[position.origPositionId] : undefined;
  const hasOrigPosition = !!position?.origTitle && position.origPositionId !== position.positionId;

  const isAssigned = !!assignee?.employeeId;
  const countSecondaryJobs = position.secondaryJobs?.length;
  const color = kitchenPosition?.color ?? colors.gray[500];

  const handlePressEditPosition = (isPrimary?: boolean) => () => {
    if (props.onEditPosition) props.onEditPosition(isPrimary);
  };

  const handlePressDeleteAssignee = () => {
    deleteAssignee.mutate({ positionJobId: position.positionJobId, employeeId: position.assignee?.employeeId });
    if (props.onDeleteAssignee) props.onDeleteAssignee(position);
  };

  const handlePressMoveTo = (isNonService?: boolean) => () => {
    props.onMoveTo(position, isNonService);
    setAssignee(undefined);
  };

  const handlePressDeleteJob = (positionId?: string) => () => {
    const secondaryJobs = filter(position.secondaryJobs, _ => _.positionId !== positionId);
    addUpdateJob.mutate({
      positionSlotId: position.id,
      positionId: position.positionId,
      positionTitle: position.title,
      secondaryJobs: secondaryJobs,
    });
    props.onChange({ id: position.id, secondaryJobs: secondaryJobs });
  };

  const handlePressOpsLeader = () => {
    if (!position.isOpsLeader) {
      addUpdateSlot.mutate({ positionGroupId: position.positionGroupId, positionSlotId: position.id, isOpsLeader: true });
      props.onChange({ id: position.id, isOpsLeader: true });
    }
  };

  const handlePressReverse = () => {
    addUpdateJob.mutate({ positionSlotId: position.id, positionId: position.origPositionId, positionTitle: position.origTitle });
    props.onChange({ id: position.id, positionId: position.origPositionId, title: position.origTitle });
  };

  // Detect changes outside of this component
  useEffect(() => setAssignee(position.assignee), [position.assignee]);

  return (
    <Box
      ref={drop}
      rounded="2xl"
      p={2}
      borderWidth={1}
      borderColor={isOver ? 'gray.400' : 'white'}
      bg="white"
      style={{
        shadowColor: isOver ? colors.gray[700] : colors.gray[500],
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.5,
        shadowRadius: 7,
      }}
    >
      <VStack
        rounded="xl"
        space={2}
        p={isLargeScreen ? 1.5 : 2}
        borderWidth={1}
        borderColor={hexToRGBA(color, 0.4)}
        bg={hexToRGBA(color, 0.2)}
      >
        {/* Position Title */}
        <HStack rounded="lg" space={2} alignItems="center" h={8}>
          <Box rounded="md" alignItems="center" justifyContent="center" h="full" w={isLargeScreen ? 6 : 8} bg={hexToRGBA(color, 0.4)}>
            {position.isOpsLeader ? (
              <Box
                rounded="full"
                alignItems="center"
                justifyContent="center"
                w={5}
                h={5}
                borderWidth={1}
                borderColor="white"
                bg="warning.300"
              >
                <Octicons name="star" size={14} color="black" />
              </Box>
            ) : (
              <Text size={isLargeScreen ? 'md' : 'lg'} color="black" fontWeight={700} textAlign="center" lineHeight="xs">
                {position.slotNumber}
              </Text>
            )}
          </Box>

          <HStack flex={1} alignItems="center" justifyContent="space-between" h="full">
            {!!position.id && (
              <Box flex={!!position.title ? 1 : undefined} pr={2}>
                <VStack>
                  <Text
                    size={{ base: 'md', lg: 'lg' }}
                    color="black"
                    fontWeight={700}
                    lineHeight="xs"
                    numberOfLines={hasOrigPosition ? 1 : 2}
                  >
                    {translate(position?.title, kitchenPosition?.translations)}
                  </Text>
                  {hasOrigPosition && (
                    <HStack space={0.5} alignItems="center" overflow="hidden">
                      <ArrowSquareUpIcon size={4} color={colors.gray[700]} />
                      <Text size="md" color="gray.700" fontWeight={500} fontStyle="italic" lineHeight="xs" h="14px" numberOfLines={1}>
                        {translate(position?.origTitle, originalPosition?.translations)}
                      </Text>
                    </HStack>
                  )}
                </VStack>
              </Box>
            )}

            {/* Select Position */}
            {!position.title && (
              <Pressable
                rounded="lg"
                flex={1}
                h={8}
                borderWidth={1}
                borderColor="primary.200"
                bg="primary.50"
                isDisabled={isDisabled}
                _disabled={{ borderColor: 'gray.300', bg: 'gray.50' }}
                _hover={{ borderColor: 'primary.600' }}
                onPress={handlePressEditPosition(true)}
              >
                <HStack alignItems="center" justifyContent="space-between" pl={3} pr={2} h="full">
                  <Text size="md" fontWeight={600} color={isDisabled ? 'gray.500' : 'primary.600'}>
                    Select Position
                  </Text>

                  <ChevronSelectorVerticalIcon size={4} color={isDisabled ? 'gray.500' : 'primary.600'} />
                </HStack>
              </Pressable>
            )}

            {!!position.title && (
              <HStack space={isLargeScreen ? 1.5 : 2}>
                {/* Reverse Position */}
                {!isDisabled && !!position?.origTitle && position.origPositionId !== position.positionId && (
                  <Pressable
                    rounded="lg"
                    alignItems="center"
                    justifyContent="center"
                    w={8}
                    h={8}
                    borderWidth={1}
                    borderColor="gray.300"
                    bg="white"
                    opacity={!position.positionId ? 0.3 : 1}
                    _disabled={{ opacity: 1 }}
                    _hover={{ borderColor: 'gray.400' }}
                    onPress={handlePressReverse}
                    isDisabled={isDisabled}
                  >
                    <ReverseLeftIcon size="14px" />
                  </Pressable>
                )}

                {!isDisabled && (
                  <Pressable
                    rounded="md"
                    alignItems="center"
                    justifyContent="center"
                    w={8}
                    h={8}
                    borderWidth={1}
                    borderColor="gray.300"
                    bg="white"
                    _hover={{ borderColor: 'gray.400' }}
                    onPress={handlePressEditPosition(true)}
                  >
                    <ChevronSelectorVerticalIcon size="18px" color="black" />
                  </Pressable>
                )}
              </HStack>
            )}
          </HStack>
        </HStack>

        {/* Assignee */}
        {isAssigned ? (
          <HStack
            ref={preview}
            rounded="lg"
            alignItems="center"
            pl={{ base: isDisabled ? 1 : 0.5, xl: isDisabled ? 2 : 1 }}
            pr={2}
            h="54px"
            borderWidth={1}
            borderColor={hexToRGBA(color, 0.4)}
            opacity={isDragging ? 0.6 : 1}
            bg="white"
          >
            {/* Draggable Indicator */}
            {!isDisabled && (
              <Pressable ref={drag}>
                <DragVerticalIcon size={{ base: '18px', lg: '22px' }} color={colors.gray[400]} />
              </Pressable>
            )}

            <Box flex={1} pl={isLargeScreen ? 0 : 0.5} pr={1} overflow="hidden">
              <Text
                size={isLargeScreen ? 'md' : 'lg'}
                fontWeight={700}
                color="black"
                lineHeight="md"
                numberOfLines={1}
                ellipsizeMode="tail"
              >
                {startCase(toLower(assignee?.name ?? ''))}
              </Text>
              {!!dayBlock && !!assignee && <EmployeeShiftTime dayBlock={dayBlock} employee={assignee} />}
            </Box>

            {assignEmployee.isLoading && <Spinner size={16} color={colors.gray[300]} mr={1} />}

            {/* Delete Assignee */}
            {!isDisabled && !!assignee.employeeId && !assignEmployee.isLoading && (
              <HStack space={{ base: 1, xl: 2 }} alignItems="center">
                <IconButton
                  p={1.5}
                  rounded="lg"
                  shadow={1}
                  borderWidth={1}
                  borderColor="gray.300"
                  bg="white"
                  _pressed={{ borderColor: 'gray.400', bg: 'white' }}
                  _hover={{ borderColor: 'gray.400', bg: 'white' }}
                  icon={<ArrowCircleBrokenLeftIcon size={5} color={colors.gray[700]} />}
                  onPress={handlePressDeleteAssignee}
                />

                <IconButton
                  p={1.5}
                  rounded="lg"
                  shadow={1}
                  borderWidth={1}
                  borderColor="gray.300"
                  bg="white"
                  _pressed={{ borderColor: 'gray.400', bg: 'white' }}
                  _hover={{ borderColor: 'gray.400', bg: 'white' }}
                  icon={<MinusCircleIcon size={5} color={colors.gray[700]} />}
                  onPress={handlePressMoveTo(true)}
                />
              </HStack>
            )}
          </HStack>
        ) : (
          <HStack space={2} alignItems="center" p={2} h="54px" borderRadius={10} bg="gray.700">
            <HStack
              flex={1}
              space={2}
              rounded="lg"
              alignItems="center"
              justifyContent="center"
              h="full"
              bg={isDisabled ? 'gray.100' : 'white'}
            >
              <UnionIcon size="22px" color={colors.gray[isDisabled ? 400 : 600]} />
              <Text size="md" color={colors.gray[isDisabled ? 400 : 600]} fontWeight={500} lineHeight="xs">
                Drag and drop
              </Text>
            </HStack>
            <IconButton
              rounded="lg"
              p={2}
              boxSize={10}
              borderWidth={1}
              borderColor="primary.300"
              bg="primary.50"
              _hover={{ bg: 'primary.50' }}
              _pressed={{ bg: 'primary.50' }}
              _disabled={{ bg: 'gray.50', borderColor: 'gray.50', _icon: { color: 'gray.700' } }}
              icon={<UserPlusIcon size="22px" color={colors.primary[700]} />}
              isDisabled={isDisabled}
              onPress={props.onSelect}
            />
          </HStack>
        )}
      </VStack>

      {/* Secondary Jobs */}
      {!isEmpty(position.secondaryJobs) && (
        <HStack flexWrap="wrap" pt={2}>
          {map(position.secondaryJobs, job => {
            const secondaryJob = job.positionId ? positionLookup[job.positionId] : undefined;
            return (
              <HStack
                key={job.positionId}
                space={1}
                rounded="lg"
                alignItems="center"
                mb={1}
                mr={1}
                pl={2}
                pr={isDisabled ? 2 : 1}
                h={7}
                borderWidth={1}
                borderColor={hexToRGBA(color, 0.4)}
                bg={hexToRGBA(color, 0.2)}
              >
                <Text size={isLargeScreen ? 'sm' : 'md'} fontWeight={500} color="black">
                  {translate(job.title, secondaryJob?.translations)}
                </Text>

                {!isDisabled && (
                  <IconButton
                    p={1}
                    _hover={{ bg: hexToRGBA(color, 0.3) }}
                    icon={<CloseIcon size="10px " color="black" />}
                    onPress={handlePressDeleteJob(job.positionId)}
                  />
                )}
              </HStack>
            );
          })}
        </HStack>
      )}

      {!isDisabled && countSecondaryJobs !== 3 && (
        <HStack pt={countSecondaryJobs ? 0 : 2}>
          <Button
            alternate
            outline
            px={2}
            minH={8}
            _hover={{ borderColor: 'gray.400' }}
            startIcon={<PlusCircleIcon size="18px" color={colors.gray[600]} />}
            onPress={handlePressEditPosition(false)}
          >
            {countSecondaryJobs === 0 ? 'Add Secondary Position' : undefined}
          </Button>
          <Spacer />
        </HStack>
      )}
    </Box>
  );
};

export default memo(DroppablePositionSlot);
