import { useCallback, useState } from 'react';
import { findIndex, isEqual, isUndefined } from 'lodash';

type UseUniqValuesProps<T> = {
  defaultValues?: T[];
  maxLength?: number;
};

export const useUniqValues = <T = unknown>(
  props?: UseUniqValuesProps<T>,
): {
  values: T[];
  append: (value: T) => T[];
  reset: (value: T[]) => void;
} => {
  const [values, setValues] = useState<T[]>(props?.defaultValues ?? []);

  const handleAppendValue = useCallback(
    (value: T): T[] => {
      const nextValues = [...values];
      const index = findIndex(nextValues, prev => isEqual(prev, value));

      if (index !== -1) nextValues.splice(index, 1);
      else nextValues.push(value);

      if (props?.maxLength && nextValues.length > props?.maxLength) return values
      setValues(nextValues);
      return nextValues;
    },
    [values],
  );

  const handleResetValues = (values?: T[]) => {
    setValues(values ?? props?.defaultValues ?? []);
  };

  return { values, append: handleAppendValue, reset: handleResetValues };
};
