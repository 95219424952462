import React, { createContext, useContext, useEffect } from 'react';

import { useGetManagementTeam } from '../hooks/get-management-team.hook';

interface StoreManagementContextReturn {
  queryManagement: ReturnType<typeof useGetManagementTeam>[0];
}

const StoreManagementContext = createContext<StoreManagementContextReturn>(undefined!);

type StoreManagementProviderProps = {
  children: React.ReactNode;
  siteId?: string;
  customerId?: string;
};

export const StoreManagementTeamProvider = ({ customerId, siteId, children }: StoreManagementProviderProps) => {
  const [queryManagement, setGetManagementParams] = useGetManagementTeam();

  useEffect(() => {
    if (siteId) {
      setGetManagementParams({ customerId, siteId });
    }
  }, [siteId, customerId]);

  return <StoreManagementContext.Provider value={{ queryManagement }}>{children}</StoreManagementContext.Provider>;
};

export const StoreManagementConsumer = StoreManagementContext.Consumer;

export const useStoreManagementTeam = () => useContext(StoreManagementContext);
