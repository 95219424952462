import { createAsyncThunk } from '@reduxjs/toolkit';
import StatusCode from 'status-code-enum';
import { forEach, some } from 'lodash';

import { SiteConfigDto, SmsTenantsApi, UserProfileDto } from '@pimm/services/lib/sms-tenants';
import { GenericError, GenericState } from '@pimm/common';
import { ASYNC_STORE_LIST } from '@app/store/consts';
import { RootState } from '../store';

const getAsyncStoreList = createAsyncThunk<SiteConfigDto[], string, { rejectValue: GenericError; state: RootState }>(
  ASYNC_STORE_LIST,
  async (customerId, { getState, rejectWithValue }) => {
    try {
      const userProfile = getState().userProfile as GenericState<UserProfileDto>;
      const stores = await SmsTenantsApi.GetSites(customerId);
      let availableStores: SiteConfigDto[] = [];
      // Remove stores that are not listed in user profile
      if (stores?.length) {
        forEach(userProfile.data?.sites, store => {
          const exist = some(stores, ['id', store.id]);
          if (exist) availableStores.push(store);
        });
      }
      return availableStores ?? [];
    } catch (error: any) {
      return rejectWithValue({
        code: StatusCode.ServerErrorInternal,
        message: error?.message || 'Failed to fetch stores data',
      });
    }
  },
);

export default getAsyncStoreList;
