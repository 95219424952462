import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBoolean } from 'usehooks-ts';
import { Box, HStack, ScrollView, Spacer, VStack } from 'native-base';
import FeatherIcons from 'react-native-vector-icons/Feather';
import { filter, isEmpty, map, orderBy, size, some } from 'lodash';

import {
  AlignVerticalCenterIcon,
  ArrowSquareUpRightIcon,
  Button,
  ButtonGroup,
  CalendarClockIcon,
  Modal,
  SpacingHeightIcon,
  Text,
} from '@pimm/base';
import { AggPositionGroupDto, AggPositionSlotDto, AggScheduledEmployee, CleaningTaskStatusEnum } from '@pimm/services/lib/sms-workforce';
import { stringToDateLocal } from '@app/utils/date-formatter';
import { useSiteTime } from '@app/features/store-core';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { useKitchenPositionGuideLive } from '../context';
import { ModalShiftChanges } from './modal-shift-changes';
import { ModalPlanAssignees } from './modal-plan-assignees';
import { KitchenMenuOption } from './kitchen-menu-option';
import { KitchenAvailableEmployees } from './kitchen-available-employees';
import KitchenPositionSlot from './kitchen-position-slot';
import { KitchenNonServices } from './kitchen-non-services';
import { ShiftScope } from './shift-scope';

type KitchenLivePositionsProps = {
  onEditPositions?: (isNonService?: boolean) => void;
  onEditFlowChart?: () => void;
  onNavigateTo: (screen: string, params?: any) => void;
};

export const KitchenLivePositions = (props: KitchenLivePositionsProps) => {
  const { t } = useTranslation();
  const siteTime = useSiteTime();
  const livePositionGuide = useKitchenPositionGuideLive();
  const modalPosition = useModalFocus<AggPositionSlotDto>();
  const [availableForService, setAvailableForService] = useState<AggScheduledEmployee[]>([]);

  const positionSlots = livePositionGuide.data?.positionSchedule?.positionSlots;
  const numOfAvailableSlots = size(positionSlots);
  const isEmptySidebar = isEmpty(availableForService) && isEmpty(livePositionGuide.data?.opsTaskFlowChartWidget);

  const isCollapsed = useBoolean(isEmptySidebar && numOfAvailableSlots <= 12);

  let numOfCols = 4;
  let noOfTiles = numOfCols - (isEmptySidebar ? 0 : 1);

  const handleEditPositions = (isNonService?: boolean) => () => {
    if (props.onEditPositions) props.onEditPositions(isNonService);
  };

  const employeesWithIncompleteTasks = useMemo(() => {
    return filter(livePositionGuide.data?.cleaningTasks, employee =>
      some(employee.scheduleTypes, scheduleType =>
        some(scheduleType.assignedTasks, task => task.cleaningTaskStatus === CleaningTaskStatusEnum.Incomplete),
      ),
    ).map(employee => employee.employeeId);
  }, [livePositionGuide.data?.cleaningTasks]);

  useEffect(() => {
    let timeoutRef;

    const populateAvailableForService = (positionSchedule: AggPositionGroupDto) => {
      const timeNow = siteTime.today();

      /// Get all confirmed employees
      const assignees = map(positionSchedule?.positionSlots ?? [], slot => slot.positionAssignee).filter(Boolean);
      // Get all confirmed employees that has no position assignment
      const availableEmployees = filter(positionSchedule?.scheduledEmployees, _ => {
        const endTime = stringToDateLocal(_.shiftEndTime);
        return !some(assignees, ['employeeId', _.employeeId]) && !!endTime && timeNow < endTime;
      });

      // Get all employees that are available for service
      const availableForService = orderBy(availableEmployees, ['name', 'employee.firstName', 'employee.lastName']);
      const nearestQuarterHour = timeNow.toNearestQuarterHour();

      nearestQuarterHour.setSeconds(0, 0);

      setAvailableForService(availableForService);

      // Recheck every quarter, if the data is available
      return setTimeout(() => {
        populateAvailableForService(positionSchedule);
      }, nearestQuarterHour.getTime() - timeNow.getTime());
    };

    const positionSchedule = livePositionGuide.data?.positionSchedule;

    if (positionSchedule) {
      timeoutRef = populateAvailableForService(positionSchedule);
    }
    return () => clearInterval(timeoutRef);
  }, [livePositionGuide.data?.positionSchedule]);

  return (
    <VStack w="full" h="full">
      <HStack alignItems="center" px={3} minH="60px" borderBottomWidth={1} bg="gray.25">
        {/* Service Positions */}
        <KitchenMenuOption
          _button={{
            endIcon: <FeatherIcons name="edit" color="gray.700" size={14} />,
          }}
          _container={{ mr: 2.5, maxW: '210px', bg: 'gray.700' }}
          _text={{ color: 'white' }}
          title={t('common:service_positions')}
          textButton="Edit"
          count={numOfAvailableSlots}
          onPress={handleEditPositions()}
        />

        {/* Shift Changes */}
        <ModalShiftChanges
          onNavigate={handleEditPositions()}
          trigger={_ =>
            _.shiftChanges > 0 && (
              <KitchenMenuOption
                _container={{ mr: 2.5, maxW: '210px', bg: 'error.500' }}
                _text={{ color: 'white' }}
                title={t('kitchen_positioning:shift_changes_s')}
                textButton="Review"
                count={_.shiftChanges}
                onPress={_.onPress}
              />
            )
          }
        />

        {/* Arriving Soon */}
        <ModalPlanAssignees
          type="ArrivalSoon"
          title={t('kitchen_positioning:arriving_soon')}
          assignees={livePositionGuide.data?.employeeSchedules}
          onNavigate={props.onEditPositions}
          trigger={_ =>
            _.assignees.length ? (
              <KitchenMenuOption
                _container={{ mr: 2.5, maxW: '180px' }}
                count={_.assignees.length}
                title={t('kitchen_positioning:arriving_soon')}
                onPress={_.onPress}
              />
            ) : undefined
          }
        />

        <Spacer />

        <ButtonGroup value={isCollapsed.value} onChange={isCollapsed.setValue}>
          <ButtonGroup.Item minH={8} value={false}>
            <AlignVerticalCenterIcon size={4} color={!isCollapsed.value ? 'black' : undefined} />
          </ButtonGroup.Item>
          <ButtonGroup.Item minH={8} value={true}>
            <SpacingHeightIcon size={4} color={isCollapsed.value ? 'black' : undefined} />
          </ButtonGroup.Item>
        </ButtonGroup>
      </HStack>

      <Box flex={1}>
        <ScrollView h="full" contentContainerStyle={{ flexGrow: 1 }}>
          <HStack h="full">
            {!isEmpty(positionSlots) ? (
              // Kitchen Position Slots
              <Box flex={1} h="full" w={`${noOfTiles}/${numOfCols}`}>
                <HStack flexWrap="wrap" py={2} px={1.5}>
                  {map(positionSlots, positionSlot => {
                    return (
                      <Box key={positionSlot.slotNumber} py={1} px={1.5} w={`1/${noOfTiles}`}>
                        <KitchenPositionSlot
                          isCollapsed={isCollapsed.value}
                          hasCleaningTask={employeesWithIncompleteTasks?.includes(positionSlot.positionAssignee?.employeeId)}
                          positionSlot={positionSlot}
                          onPress={modalPosition.setOpen}
                        />
                      </Box>
                    );
                  })}
                </HStack>
              </Box>
            ) : (
              <VStack space={1} flex={1} alignItems="center" justifyContent="center">
                <Text fontWeight={500} size="md" lineHeight="md" color="black">
                  No Service Positions have been assigned
                </Text>
                <Button
                  outline
                  alternate
                  background="white"
                  onPress={handleEditPositions()}
                  startIcon={<CalendarClockIcon color="gray.700" size={5} />}
                  endIcon={<ArrowSquareUpRightIcon color="gray.500" size={5} />}
                >
                  Position Plan
                </Button>
              </VStack>
            )}

            {/* Available For Service */}
            {!isEmptySidebar && (
              <Box w={`1/${numOfCols}`} h="full" borderLeftWidth={1} borderColor="gray.300">
                <VStack space={0.5} py={2} px={1.5}>
                  {!isEmpty(availableForService) && (
                    <Box py={1} px={1.5}>
                      <KitchenAvailableEmployees employees={availableForService} />
                    </Box>
                  )}

                  {map(livePositionGuide.data?.opsTaskFlowChartWidget, opsPhase => (
                    <Box key={opsPhase.opsPhaseId} py={1} px={1.5}>
                      <KitchenNonServices opsPhase={opsPhase} onPress={props.onEditFlowChart} />
                    </Box>
                  ))}
                </VStack>
              </Box>
            )}
          </HStack>
        </ScrollView>
      </Box>

      <Modal
        size="full"
        isOpen={modalPosition.isOpen}
        noPadding
        _content={{ height: '90%', maxWidth: { md: '85%', xl: 1300 }, maxHeight: '800px' }}
        onClose={modalPosition.setHide}
      >
        {!!modalPosition.payload?.positionJob && (
          <ShiftScope positionSlot={modalPosition.payload} onClose={modalPosition.setHide} onNavigateTo={props.onNavigateTo} />
        )}
      </Modal>
    </VStack>
  );
};
