import React, { useEffect, useState } from 'react';
import { ArrowBackIcon, ArrowForwardIcon, Box, HStack, IconButton, VStack } from 'native-base';
import FeatherIcons from 'react-native-vector-icons/Feather';
import { find, first, map, sortBy } from 'lodash';
import moment from 'moment';

// React JS Imports
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { Button, ButtonGroup, LightBulbIcon } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { formatToISOString } from '@app/utils/date-formatter';
import { MainScreenProps } from '@app/navigations/root';
import { MainContainer } from '@app/components/core';
import { ModuleTitle, useAppModules } from '@app/features/app';
import { DateTimePopover, useDayBlockFocus, useSiteConfig } from '@app/features/store-core';
import { ModalAppDocuments } from '@app/features/store-info/components';
import { PositionCategoriesProvider, useGetPositionCategories } from '@app/features/positioning-plan';
import { NonServicePlan } from './components/non-service-plan';
import { ServicePlan } from './components/service-plan';

type PositionPlanTabFocus = 'service' | 'non-service';
type PositionPlanTabIdentifier = 'positionPlan.service' | 'positionPlan.nonservice';

interface PositionPlanTabItem {
  identifier: PositionPlanTabIdentifier;
  seq?: number;
  title: string;
  value: PositionPlanTabFocus;
}

export type DailyPlanScreenProps = MainScreenProps<'DailyPlan'>;

export const DailyPlanScreen: React.FC<DailyPlanScreenProps> = ({ navigation, route }) => {
  const blockNumber = Number(route.params.blockNumber);
  const { locale, translate } = useAppLocale();
  const { siteConfig } = useSiteConfig();
  const { screens } = useAppModules();
  const dayBlockFocus = useDayBlockFocus(route.params.schedule, !isNaN(blockNumber) ? blockNumber : undefined);
  const [tabFocus, setTabFocus] = useState<PositionPlanTabFocus | undefined>(route.params.tabFocus as PositionPlanTabFocus);
  const [tabItems, setTabItems] = useState<PositionPlanTabItem[]>([]);
  const [categories, setCategoryParams] = useGetPositionCategories();

  const handleChangeDays = (days: number) => {
    if (dayBlockFocus.selected) {
      const dateValue = dayBlockFocus.selected.startTime.addDays(days);
      dayBlockFocus.changeDate(dateValue);
    }
  };

  const handleChangeDate = (value?: unknown) => {
    let dateValue;
    if (value instanceof Date) dateValue = value;
    dayBlockFocus.changeDate(dateValue);
  };

  useEffect(() => {
    // Listen and remember the last state
    if (siteConfig?.id && dayBlockFocus.selected) {
      navigation.setParams({
        ...route.params,
        schedule: formatToISOString(dayBlockFocus.selected.startTime, true),
        blockNumber: dayBlockFocus.selected.blockNumber,
        tabFocus: tabFocus,
      });
    }
  }, [dayBlockFocus.selected, siteConfig.id, tabFocus]);

  useEffect(() => {
    if (siteConfig.companyInfo?.brand?.id) {
      setCategoryParams({
        brandId: siteConfig.companyInfo?.brand?.id,
        marketId: siteConfig.config?.marketId,
      });
    }
  }, [siteConfig.companyInfo?.brand?.id, siteConfig.config?.marketId]);

  useEffect(() => {
    const screen = find(screens, _ => _.name === route.name);
    const items = sortBy(
      [
        {
          seq: 1,
          identifier: 'positionPlan.service',
          title: 'Service Plan',
          value: 'service',
        },
        {
          seq: 2,
          identifier: 'positionPlan.nonservice',
          title: 'Non-Service Plan',
          value: 'non-service',
        },
      ].map(item => {
        const subModule = find(screen?.subModules, _ => _.identifier === item.identifier);
        const title = translate(subModule?.title ?? item.title, subModule?.titleTranslations);
        return {
          ...item,
          seq: subModule?.seq,
          title: title,
        } as PositionPlanTabItem;
      }),
      ['seq'],
    );

    setTabItems(items);
    // if tabFocus is undefined, select the first item in the array
    if (!tabFocus) setTabFocus(first(items)?.value);
  }, [locale, route.name, screens, siteConfig]);

  return (
    <DndProvider backend={HTML5Backend}>
      <MainContainer>
        <VStack w="full" h="full">
          <HStack px={3} height={60} alignItems="center" justifyContent="space-between" borderWidth={1}>
            <HStack flex={1} alignItems="center">
              <ModuleTitle name={route.name} defaultText="Position Plan" />
              <ModalAppDocuments
                identifierName="Module.PositionPlan"
                moduleType="Positioning Plan"
                trigger={triggerProps => {
                  if (!triggerProps.documents?.length) return undefined;
                  return (
                    <IconButton
                      rounded="lg"
                      borderWidth={1}
                      borderColor="gray.300"
                      ml={2}
                      p={0}
                      w={9}
                      h={9}
                      icon={<LightBulbIcon size="md" />}
                      onPress={triggerProps.onPress}
                    />
                  );
                }}
              />
            </HStack>

            {!!dayBlockFocus.selected && (
              // TODO: create a reusable component
              <HStack alignItems="center" justifyContent="flex-end" space={2} py={2} px={4}>
                <Button alternate outline w={10} onPress={() => handleChangeDays(-1)}>
                  <ArrowBackIcon color="gray.700" />
                </Button>
                <DateTimePopover selectedDate={dayBlockFocus.selected.startTime} onChange={handleChangeDate}>
                  <Button
                    px={4}
                    width="140px"
                    alternate
                    variant="outline"
                    justifyContent="flex-start"
                    startIcon={<FeatherIcons name="calendar" size={18} color="gray.700" style={{ marginRight: 2 }} />}
                  >
                    {moment(dayBlockFocus.selected.startTime).format('ll')}
                  </Button>
                </DateTimePopover>
                <Button alternate outline w={10} onPress={() => handleChangeDays(1)}>
                  <ArrowForwardIcon color="gray.700" />
                </Button>

                <Button ml={2} px={3} minWidth="74px" alternate outline onPress={handleChangeDate}>
                  Today
                </Button>
              </HStack>
            )}

            <Box flex={1} alignItems="flex-end">
              {tabItems.length > 1 && (
                <ButtonGroup key={locale} value={tabFocus} onChange={setTabFocus}>
                  {map(tabItems, tab => (
                    <ButtonGroup.Item key={tab.identifier} alignItems="center" value={tab.value} minW={120}>
                      {tab.title}
                    </ButtonGroup.Item>
                  ))}
                </ButtonGroup>
              )}
            </Box>
          </HStack>

          {!!siteConfig.id && (
            <VStack flex={1} width="full">
              {tabFocus === 'non-service' && <NonServicePlan dayBlockFocus={dayBlockFocus} />}
              {tabFocus === 'service' && (
                <PositionCategoriesProvider categories={categories}>
                  {!!dayBlockFocus.selected && (
                    <ServicePlan
                      dayBlock={dayBlockFocus.selected}
                      siteId={siteConfig.id}
                      onChangeBlockNumber={dayBlockFocus.changeBlockNumber}
                    />
                  )}
                </PositionCategoriesProvider>
              )}
            </VStack>
          )}
        </VStack>
      </MainContainer>
    </DndProvider>
  );
};
