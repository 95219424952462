import { chain, filter, first, forEach, last, pick, range, sortBy } from 'lodash';
import moment from 'moment';

import { PositionJobDto, PositionSlotDto, PositionTypeEnum } from '@pimm/services/lib/sms-workforce';
import { dateDiffToMinutes, millisToMinutes, stringToDateLocal } from '@app/utils/date-formatter';
import { KitchenEmployee, Timeslot } from './types';

export const TimelineLegend = {
  Available: 'success.400',
  Assigned: 'warning.400',
  Unavailable: 'gray.400',
} as const;

export const fillShiftHours = (startTime?: Date, endTime?: Date) => {
  let hours: string[] = [];
  if (startTime && endTime) {
    const durationInMinutes = millisToMinutes(endTime.getTime() - startTime.getTime());
    const numberOfHours = Math.round(durationInMinutes / 60);
    hours = range(numberOfHours).map(index => {
      return moment(startTime).add(index, 'hour').format('h:00 A');
    });
  }
  return hours;
};

export const flattenAssignees = (positionSlots?: PositionSlotDto[]): Record<string, Timeslot[]> => {
  const timeslots: Timeslot[] = [];
  forEach(positionSlots, slot => {
    forEach(slot.positionJob?.positionAssignees, assignee => {
      const startTime = stringToDateLocal(assignee.startTime);
      const endTime = stringToDateLocal(assignee.endTime);

      if (startTime && endTime) {
        const timeslot: Timeslot = {
          id: assignee.id,
          employeeId: assignee.employeeId,
          employeeNum: assignee.employeeNum,
          name: assignee.name,
          title: assignee.title,
          durationInMinutes: dateDiffToMinutes(startTime, endTime),
          startTime,
          endTime,
        };
        timeslots.push(timeslot);
      }
    });
  });

  const assignees = chain(timeslots).sortBy('startTime').groupBy('employeeId').value();
  return assignees;
};

export const mapAssigneeToTimeslot = (positionJob?: PositionJobDto) => {
  const timeslots: Timeslot[] = [];
  forEach(positionJob?.positionAssignees, assignee => {
    const startTime = stringToDateLocal(assignee.startTime);
    const endTime = stringToDateLocal(assignee.endTime);

    if (startTime && endTime) {
      const timeslot: Timeslot = {
        id: assignee.id,
        employeeId: assignee.employeeId,
        employeeNum: assignee.employeeNum,
        isNonService: positionJob?.positionType === PositionTypeEnum.NonService,
        name: assignee.name,
        title: assignee.title,
        durationInMinutes: dateDiffToMinutes(startTime, endTime),
        startTime: startTime,
        endTime: endTime,
      };
      timeslots.push(timeslot);
    }
  });

  return timeslots;
};

export const checkAvailableTimeslots = (timeslots: Timeslot[], timeslot: Timeslot) => {
  const availableTimeslots = filter(timeslots, slot => {
    // Skip timeslot with an existing assignee
    // if (!slot.employeeId) return false;

    // Check if the shift is fully covered
    if (slot.startTime >= timeslot.startTime && slot.endTime <= timeslot.endTime) {
      return true;
    }
    // Check if the shift is partially covered
    if (
      (slot.startTime >= timeslot.startTime && slot.startTime < timeslot.endTime) ||
      (slot.endTime > timeslot.startTime && slot.endTime <= timeslot.endTime)
    ) {
      return true;
    }
    // If neither fully nor partially covered, then not covered
    return false;
  });
  return availableTimeslots;
};

// Check if the employee has any available shifts that can cover the given timeslot.
export const fillAvailableTimeslot = (employee: KitchenEmployee, timeslot: Timeslot): Timeslot[] => {
  const availableShiftHours = checkAvailableTimeslots(employee.timeslots, timeslot);
  const employeeDataFields = pick(employee, ['employeeId', 'employeeNum', 'name', 'title']);
  const shiftStartTime = first(employee.timeslots)?.startTime;
  const shiftEndTime = last(employee.timeslots)?.endTime;
  let timeslotStartTime = timeslot.startTime;
  let timeslotEndTime = timeslot.endTime;
  let timeslots: Timeslot[] = [];

  if (availableShiftHours.length && shiftStartTime && shiftEndTime) {
    employee.timeslots.forEach(shift => {
      timeslotStartTime = new Date(Math.max(shift.startTime.getTime(), timeslotStartTime.getTime()));
      timeslotEndTime = new Date(Math.min(shift.endTime.getTime(), timeslotEndTime.getTime()));

      if (!shift.id) {
        if (timeslotStartTime < shift.startTime) {
          timeslots.push({
            ...employeeDataFields,
            id: timeslot.id,
            durationInMinutes: dateDiffToMinutes(timeslotStartTime, shift.startTime),
            startTime: timeslotStartTime,
            endTime: shift.startTime,
          });
          timeslotStartTime = shift.startTime;
        } else if (timeslotStartTime > shift.startTime) {
          timeslots.push({
            durationInMinutes: dateDiffToMinutes(shift.startTime, timeslotStartTime),
            startTime: shift.startTime,
            endTime: timeslotStartTime,
          });
        }

        timeslots.push({
          ...employeeDataFields,
          id: timeslot.id,
          durationInMinutes: dateDiffToMinutes(timeslotStartTime, timeslotEndTime),
          startTime: timeslotStartTime,
          endTime: timeslotEndTime,
        });
      } else {
        timeslots.push(shift);
        timeslotEndTime = shift.startTime;
      }
    });

    timeslotEndTime = last(timeslots)!.endTime;

    if (timeslotEndTime < shiftEndTime) {
      timeslots.push({
        durationInMinutes: dateDiffToMinutes(timeslotEndTime, shiftEndTime),
        startTime: timeslotEndTime,
        endTime: shiftEndTime,
      });
    }
  }

  return sortBy(timeslots, 'startTime');
};

export const fillTimeslots = (timeslots: Timeslot[], startTime: Date, endTime: Date): Timeslot[] => {
  // Make sure to sort the timeslots to prevent adding incorrect timeslot
  const sortedTimeslots = sortBy(timeslots, 'startTime');
  let shiftStartTime = startTime;
  let fill: Timeslot[] = [];

  forEach(sortedTimeslots, timeslot => {
    // Check any gap in each shift
    if (shiftStartTime < timeslot.startTime) {
      fill.push({
        durationInMinutes: millisToMinutes(timeslot.startTime.getTime() - shiftStartTime.getTime()),
        startTime: shiftStartTime,
        endTime: timeslot.startTime,
      });
    }
    // Add the unchanged shift to the shifts array
    fill.push(timeslot);
    // Update shiftStartTime using shift endTime
    // Will be use to check the gap between the current shift and the next shift
    shiftStartTime = timeslot.endTime;
  });

  if (shiftStartTime < endTime) {
    fill.push({
      durationInMinutes: millisToMinutes(endTime.getTime() - shiftStartTime.getTime()),
      startTime: shiftStartTime,
      endTime: endTime,
    });
  }

  if (fill.length === 0) {
    // If empty, fill the whole timeslot
    fill.push({
      durationInMinutes: millisToMinutes(endTime.getTime() - startTime.getTime()),
      startTime: startTime,
      endTime: endTime,
    });
  }

  return fill;
};
