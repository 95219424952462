import { ButtonGroup } from '@pimm/base';
import { RollupDateRange } from '@pimm/services/lib/store-equipment';

type RollupDaysButtons = Pick<React.ComponentProps<typeof ButtonGroup>, 'value' | 'onChange'> & {};

export const RollupDaysButtons = (props: RollupDaysButtons) => (
  <ButtonGroup {...props}>
    <ButtonGroup.Item value={RollupDateRange.SevenDay} minWidth={70}>
      Last 7 Days
    </ButtonGroup.Item>
    <ButtonGroup.Item value={RollupDateRange.ThirtyDay} minWidth={70}>
      Last 30 Days
    </ButtonGroup.Item>
    <ButtonGroup.Item value={RollupDateRange.NinetyDay} minWidth={70}>
      Last 90 Days
    </ButtonGroup.Item>
  </ButtonGroup>
);
