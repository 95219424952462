import { ArrowDownIcon, ArrowUpIcon, HStack, IBoxProps } from 'native-base';
import { Text } from '@pimm/base';
import { abbrevThousands } from '@app/utils/number-formatter';

type SpeedBadgeProps = IBoxProps & {
  actual?: number;
  goal?: number;
};

export const SpeedBadge = ({ actual = 0, goal = 0, ...props }: SpeedBadgeProps) => {
  const color = actual === 0 || actual <= goal ? 'success' : 'error';
  const value = goal - actual;

  return (
    <HStack
      space={0.5}
      rounded="md"
      flex="none"
      alignItems="center"
      justifyContent="center"
      px={1}
      h={5}
      borderWidth={1}
      borderColor={`${color}.500`}
      bg={`${color}.50`}
      {...props}
    >
      {actual <= goal ? <ArrowDownIcon size="10px" color={`${color}.600`} /> : <ArrowUpIcon size="10px" color={`${color}.600`} />}

      <Text size="sm" fontWeight={500} color={`${color}.600`} lineHeight="xs">
        {`${abbrevThousands(value)?.toString().replace('-', '')}s`}
      </Text>
    </HStack>
  );
};
