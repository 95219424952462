import { useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import { Box, Checkbox, FlatList, HStack, Pressable, VStack, useTheme } from 'native-base';
import { filter, first, some, startCase, toLower } from 'lodash';

import { Button, Text } from '@pimm/base';
import { AddUpdateAssigneeRequest, SmsWorkforceApi } from '@pimm/services/lib/sms-workforce';
import { formatToISOString } from '@app/utils/date-formatter';
import { useKitchenPositioning } from '../context';
import { KitchenEmployee, KitchenPosition, Timeslot } from '../types';
import { EmployeePartitionTitle } from './employee-partition-list';
import { EmployeeTimeline } from './employee-timeline';

type AvailableEmployeesProps = {
  position: KitchenPosition;
  onCancel?: () => void;
  onSave?: (position: Partial<KitchenPosition>) => void;
};

export const AvailableEmployees = ({ position, ...props }: AvailableEmployeesProps) => {
  const { colors } = useTheme();
  const { positioning } = useKitchenPositioning();
  const [selectedEmployee, setSelectedEmployee] = useState<KitchenEmployee>();
  const [employees] = useState<KitchenEmployee[]>(
    filter(positioning?.employees, employee =>
      some<Timeslot>(employee.timeslots, timeslot => !timeslot.employeeId && !timeslot.isNonService),
    ),
  );

  const assignEmployee = useMutation({ mutationFn: SmsWorkforceApi.AssignEmployee });

  const handleSelect = useCallback(
    (employee: KitchenEmployee) => () => {
      setSelectedEmployee(employee);
    },
    [employees],
  );

  const handlePressSave = async () => {
    const timeslot = first(position.timeslots);

    if (timeslot && position.positionJobId && selectedEmployee && props.onSave) {
      const payload: AddUpdateAssigneeRequest = {
        id: timeslot.id,
        positionJobId: position.positionJobId,
        employeeId: selectedEmployee.employeeId,
        startTime: formatToISOString(selectedEmployee.timeslots[0].startTime),
        // TODO: need to revisit why endTime is included in request payload
        // endTime: formatToISOString(selectedEmployee.timeslots[0].endTime),
      };

      const result = await assignEmployee.mutateAsync(payload);

      if (props.onSave) {
        props.onSave({
          id: position.id,
          timeslots: [
            {
              ...selectedEmployee.timeslots[0],
              id: timeslot.id ?? result?.id,
              employeeId: selectedEmployee.employeeId,
              name: selectedEmployee.name,
              title: selectedEmployee.title,
            },
          ],
        });
        if (props.onCancel) props.onCancel();
      }
    }
  };

  return (
    <Box w="full">
      <Box justifyContent="center" px={4} minHeight="48px" borderBottomWidth={1}>
        <Text size="2xl" fontWeight={700} color="gray.900">
          Select Employee
        </Text>
      </Box>

      <FlatList
        py={3}
        px={4}
        data={employees}
        extraData={[selectedEmployee]}
        ListEmptyComponent={() => (
          <Box justifyContent="center" minHeight={20} borderWidth={1} borderTopWidth={0} borderBottomRadius="lg">
            <Text size="sm" color="gray.500" textAlign="center">
              No Available Employees
            </Text>
          </Box>
        )}
        ListHeaderComponent={() => (
          <VStack space={2.5}>
            <Text size="lg" fontWeight={700} color="gray.900">
              {EmployeePartitionTitle.Unassigned}
            </Text>

            <HStack
              px={3.5}
              h={8}
              alignItems="center"
              justifyContent="space-between"
              borderWidth={1}
              borderTopLeftRadius="md"
              borderTopRightRadius="md"
              backgroundColor="gray.25"
            >
              <Text size="sm" fontWeight={500} color="gray.600" lineHeight="sm">
                Employee
              </Text>
            </HStack>
          </VStack>
        )}
        renderItem={({ item: employee, index }) => {
          const isLastItem = index === employees.length - 1;
          const isSelected = employee.employeeId === selectedEmployee?.employeeId;
          return (
            <Pressable
              w="full"
              overflow="hidden"
              borderWidth={1}
              borderTopWidth={0}
              borderColor="gray.200"
              borderBottomLeftRadius={isLastItem ? 'lg' : undefined}
              borderBottomRightRadius={isLastItem ? 'lg' : undefined}
              onPress={handleSelect(employee)}
            >
              {({ isHovered }) => (
                <Box py={2.5} px={3.5} bg={isHovered ? 'gray.50' : 'white'}>
                  <HStack space={3} alignItems="center">
                    <Box flex={1}>
                      <Text size="md" fontWeight={700} color="black" lineHeight="sm" numberOfLines={1} ellipsizeMode="tail">
                        {`${startCase(toLower(employee.name ?? ''))}`}
                      </Text>
                      <Text size="sm" fontWeight={500} color="gray.900" lineHeight="xs">
                        {employee?.title ?? 'Unknown'}
                      </Text>
                    </Box>
                    <Checkbox
                      borderWidth={1}
                      borderColor="gray.300"
                      _checked={{ borderColor: 'primary.600', backgroundColor: 'primary.50' }}
                      _disabled={{ opacity: 1, bgColor: undefined }}
                      _hover={{ borderColor: 'gray.300' }}
                      _icon={{ color: colors.primary[600] }}
                      isChecked={isSelected}
                      isDisabled
                      isHovered={false}
                      value={employee.employeeId}
                    />
                  </HStack>
                  <EmployeeTimeline employee={employee} isUnassigned />
                </Box>
              )}
            </Pressable>
          );
        }}
      />

      <HStack justifyContent="flex-end" space={2} px={4} pb={3}>
        <Button size="sm" h={9} minWidth={90} alternate disabled={assignEmployee.isLoading} outline onPress={props.onCancel}>
          Cancel
        </Button>

        <Button size="sm" h={9} minWidth={90} disabled={!selectedEmployee} isLoading={assignEmployee.isLoading} onPress={handlePressSave}>
          Save
        </Button>
      </HStack>
    </Box>
  );
};
