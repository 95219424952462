import { createElement } from 'react';
import { Box, HStack, IIconProps, useBreakpointValue, useTheme, VStack } from 'native-base';
import { decode } from 'html-entities';
import { get, isNaN } from 'lodash';

import { CircularProgress, Text } from '@pimm/base';
import { DoorEventIcon, Thermometer02Icon } from '../icons';
import { SensorEquipment } from '../context';
import { AverageTemperature } from './average-temperature';

type GraphPerformanceProps = {
  _container?: React.ComponentProps<typeof VStack>;
  sensor: SensorEquipment;
  isDoor?: boolean;
};

export const GraphPerformance = ({ _container, sensor, isDoor }: GraphPerformanceProps) => {
  const { colors } = useTheme();
  const circularRadius = useBreakpointValue({ sm: 25, xl: 30 });
  const circularStroke = useBreakpointValue({ sm: 10, xl: 11 });
  const iconSize = useBreakpointValue({ sm: '16px', xl: '18px' });
  const tempPercentage = sensor?.performance?.InTempPercentage;
  const pattern = /^100(\.0)?$/;

  return (
    <VStack space={2} {..._container}>
      <HStack space={{ base: 1, xl: 2 }}>
        <VStack flex={1} rounded="lg" alignItems="center" py={2} p={{ base: 2, xl: 3 }} bg="gray.100">
          <Text size="lg" fontWeight={600} color="black" textAlign="center" lineHeight="xs">
            {`Average Temp`}
          </Text>
          <HStack flex={1} alignItems="center" justifyContent="center" w="full">
            {!!sensor.performance?.AverageTemperature && (
              <AverageTemperature
                size={{ base: 'lg', xl: 'xl' }}
                value={sensor.performance?.AverageTemperature}
                alarmLevel={sensor?.equipment?.TemperatureMetrics?.Temperature?.AlarmLevel}
              />
            )}
          </HStack>
        </VStack>

        <VStack space={2} flex={1} rounded="lg" alignItems="center" py={2} p={{ base: 2, xl: 3 }} bg="gray.100">
          <Text size="lg" fontWeight={600} color="black" textAlign="center" lineHeight="xs">
            In-Temp %
          </Text>
          <Box flex={1} justifyContent="center">
            <CircularProgress
              _text={{
                size: { base: 'md', xl: 'lg' },
                decimalScale: 1,
                fixedDecimalScale: pattern.test(tempPercentage?.toString() ?? ''),
                suffix: '',
              }}
              activeStrokeColor={colors.primary[500]}
              inActiveStrokeColor={colors.gray[300]}
              radius={circularRadius}
              strokeWidth={circularStroke}
              value={isNaN(Number(tempPercentage)) ? 0 : Number(tempPercentage ?? 0)}
            />
          </Box>
        </VStack>

        <VStack
          space={{ base: 1, xl: 2 }}
          flex={1}
          rounded="lg"
          alignItems="center"
          py={2}
          p={{ base: 1.5, xl: 2 }}
          minW={{ base: '100px', xl: '110px' }}
          bg="gray.100"
        >
          <Text size="lg" fontWeight={600} color="black" textAlign="center">
            Events
          </Text>

          <VStack space={1} w="full">
            {[
              { icon: Thermometer02Icon, dataField: 'TemperatureFlagCounts' },
              { icon: DoorEventIcon, dataField: 'DoorFlagCounts' },
            ].map(_ => {
              const red = isDoor ? 0 : Number(get(sensor?.performance, `${_.dataField}.Red`, 0));
              const black = isDoor ? 0 : Number(get(sensor?.performance, `${_.dataField}.Black`, 0));
              return (
                <HStack
                  key={_.dataField}
                  space={2}
                  rounded="lg"
                  alignItems="center"
                  justifyContent="space-between"
                  py={1}
                  px={2}
                  bg="white"
                >
                  {createElement<IIconProps>(_.icon, { size: iconSize, color: 'black' })}

                  <HStack space={1}>
                    <Box
                      rounded={{ base: 'md', xl: 'lg' }}
                      alignItems="center"
                      justifyContent="center"
                      minWidth={{ base: 5, xl: 6 }}
                      h={{ base: '24px', xl: '26px' }}
                      bg="error.500"
                    >
                      <Text size={{ base: 'md', xl: 'lg' }} fontWeight={600} color="white" lineHeight={{ base: 'md', xl: 'lg' }}>
                        {red}
                      </Text>
                    </Box>

                    <Box
                      rounded={{ base: 'md', xl: 'lg' }}
                      alignItems="center"
                      justifyContent="center"
                      minWidth={{ base: 5, xl: 6 }}
                      h={{ base: '24px', xl: '26px' }}
                      bg="black"
                    >
                      <Text size={{ base: 'md', xl: 'lg' }} fontWeight={600} color="white" lineHeight={{ base: 'md', xl: 'lg' }}>
                        {black}
                      </Text>
                    </Box>
                  </HStack>
                </HStack>
              );
            })}
          </VStack>
        </VStack>
      </HStack>
    </VStack>
  );
};
