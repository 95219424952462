import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, HStack, Pressable, Progress, VStack, useTheme } from 'native-base';
import { map, orderBy, startCase, toLower } from 'lodash';

import { Card, Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { OpsTaskFlowChartWidgetDto } from '@pimm/services/lib/sms-workforce';
import { formatToShortTimeOnly, formatToTimeOnly, stringToDateLocal } from '@app/utils/date-formatter';

type KitchenNonServicesProps = {
  opsPhase: OpsTaskFlowChartWidgetDto;
  onPress?: () => void;
};

export const KitchenNonServices = ({ opsPhase, ...props }: KitchenNonServicesProps) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { translate } = useAppLocale();
  const [fillColors] = useState<Record<string, string>>({
    complete: 'success.500',
    partial: 'warning.500',
    unknown: 'gray.200',
  });

  const startTime = stringToDateLocal(opsPhase.startDateTime);
  const endTime = stringToDateLocal(opsPhase.endDateTime);
  const positions = orderBy(opsPhase.positions, ['tasksStartTime', 'tasksEndTime']);

  return (
    <Pressable rounded="xl" onPress={props.onPress}>
      <Card
        rounded="xl"
        p={2}
        borderWidth={0}
        bg="white"
        style={{
          shadowColor: colors.gray[500],
          shadowOffset: { width: 0, height: 1 },
          shadowOpacity: 0.8,
          shadowRadius: 3,
        }}
      >
        <Box py={2} px={2.5} borderTopRadius="lg" borderWidth={1} borderColor="#E6DBCF" bg="#E6DBCF">
          <Text size="md" fontWeight={700} color="black" lineHeight="xs" numberOfLines={2}>
            {translate(opsPhase.phase, opsPhase.phaseTranslations)}
          </Text>
          {!!startTime && !!endTime && (
            <Text size="sm" fontWeight={500} color="black" lineHeight="xs">
              {`${formatToTimeOnly(startTime)} - ${formatToTimeOnly(endTime)}`}
            </Text>
          )}
        </Box>
        <VStack borderBottomRadius="lg" borderWidth={1} borderTopWidth={0}>
          {map(positions, (position, index) => {
            const tasksStartTime = stringToDateLocal(position.tasksStartTime);
            const tasksEndTime = stringToDateLocal(position.tasksEndTime);
            const status = position.status?.toLowerCase() ?? 'unknown';
            const fillColor = fillColors[status] ?? 'gray.200';
            const fillValue = status === 'partial' ? 50 : 100;

            return (
              <HStack key={position.positionId} justifyContent="space-between" py={1} px={2.5} minH="40px" borderTopWidth={index ? 1 : 0}>
                <Box flex={1} pr={2}>
                  <Text size="sm" fontWeight={600} color="gray.700" lineHeight="xs" numberOfLines={2}>
                    {translate(position.title, position.translations)}
                  </Text>
                  <HStack mt="2px">
                    {position.employeeId ? (
                      <Text size="sm" fontWeight={700} color="black" lineHeight="xs" numberOfLines={1}>
                        {startCase(toLower([position.firstName, position.lastName].filter(Boolean).join(' ')))}
                      </Text>
                    ) : (
                      <Box flex="none" justifyContent="center" px={1} h={4} borderRadius="xl" bg="gray.800">
                        <Text size="xs" fontWeight={600} color="white" lineHeight="xs" numberOfLines={1}>
                          {t('common:unassigned')}
                        </Text>
                      </Box>
                    )}
                  </HStack>
                </Box>

                <VStack space={1} alignItems="flex-end" pt={1.5}>
                  {!!tasksStartTime && !!tasksEndTime && (
                    <Text size="xs" fontWeight={600} color="gray.700" lineHeight="xs">
                      {`${formatToShortTimeOnly(tasksStartTime)} - ${formatToShortTimeOnly(tasksEndTime)}`}
                    </Text>
                  )}
                  <Progress
                    value={fillValue}
                    w="full"
                    maxWidth="55px"
                    _filledTrack={{
                      bg: fillColor,
                    }}
                  />
                </VStack>
              </HStack>
            );
          })}
        </VStack>
      </Card>
    </Pressable>
  );
};
