import { Icon, IIconProps } from 'native-base';
import { G, Path } from 'react-native-svg';

export const AlertTriangleIcon = ({ fill = 'none', ...props }: IIconProps) => {
  return (
    <Icon viewBox="0 0 20 20" fill="none" {...props}>
      <G fill={fill}>
        <Path
          d="M2.19173 15.0819L9.0458 3.24309C9.42461 2.58879 9.61402 2.26163 9.86112 2.15176C10.0767 2.05591 10.3227 2.05591 10.5383 2.15176C10.7854 2.26163 10.9748 2.58878 11.3536 3.24309L18.2077 15.0819C18.5878 15.7386 18.7779 16.0669 18.7498 16.3364C18.7253 16.5714 18.6022 16.785 18.4111 16.924C18.1919 17.0833 17.8125 17.0833 17.0538 17.0833H3.34564C2.58687 17.0833 2.20748 17.0833 1.98835 16.924C1.79722 16.785 1.67408 16.5714 1.64957 16.3364C1.62148 16.0669 1.81156 15.7386 2.19173 15.0819Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M10.1997 7.49999V10.8333M10.1997 14.1667H10.208M9.0458 3.24309L2.19173 15.0819C1.81156 15.7386 1.62148 16.0669 1.64957 16.3364C1.67408 16.5714 1.79722 16.785 1.98835 16.924C2.20748 17.0833 2.58687 17.0833 3.34564 17.0833H17.0538C17.8125 17.0833 18.1919 17.0833 18.4111 16.924C18.6022 16.785 18.7253 16.5714 18.7498 16.3364C18.7779 16.0669 18.5878 15.7386 18.2077 15.0819L11.3536 3.24309C10.9748 2.58878 10.7854 2.26163 10.5383 2.15176C10.3227 2.05591 10.0767 2.05591 9.86112 2.15176C9.61402 2.26163 9.42461 2.58879 9.0458 3.24309Z"
          stroke="currentColor"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </G>
    </Icon>
  );
};
