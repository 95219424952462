import { useEffect } from 'react';
import { last } from 'lodash';

import { useDayBlockFocus, useSiteConfig, useSiteTime } from '@app/features/store-core';
import {
  OpsPhasePositioningProvider,
  OpsPhasesPositioning,
  useGetBrandSalesVolumeProfiles,
  useGetOpsTaskPositioning,
} from '@app/features/flow-chart';
import { ResourceLoader } from '@app/components/shared';

type NonServicePlanProps = {
  dayBlockFocus: ReturnType<typeof useDayBlockFocus>;
};

export const NonServicePlan = ({ dayBlockFocus }: NonServicePlanProps) => {
  const { siteConfig } = useSiteConfig();
  const siteTime = useSiteTime();
  const [opsTasksPositioning, setOpsTasksPositioning] = useGetOpsTaskPositioning();
  const [salesVolumeProfiles, setSalesVolumeProfilesParams] = useGetBrandSalesVolumeProfiles();

  const endOfBlock = last(dayBlockFocus.all);
  const isEditDisabled = !!endOfBlock && endOfBlock?.endTime < siteTime.today();

  useEffect(() => {
    if (siteConfig) {
      setSalesVolumeProfilesParams({
        brandId: siteConfig.companyInfo?.brand?.id,
        marketId: siteConfig.config?.marketId,
      });
    }
  }, [siteConfig]);

  useEffect(() => {
    if (dayBlockFocus.selected && siteConfig?.id) {
      setOpsTasksPositioning({
        date: dayBlockFocus.selected.startTime,
        siteId: siteConfig.id,
      });
    }
  }, [dayBlockFocus.selected, siteConfig]);

  return (
    <ResourceLoader h="full" isLoading={!siteConfig.id || opsTasksPositioning.isIdle || opsTasksPositioning.isLoading}>
      {!!siteConfig.id && (
        <OpsPhasePositioningProvider
          opsTasksPositioning={opsTasksPositioning}
          salesVolumeProfiles={salesVolumeProfiles}
          selectedDate={dayBlockFocus.selected?.startTime}
          siteId={siteConfig.id}
        >
          <OpsPhasesPositioning editable={!isEditDisabled} />
        </OpsPhasePositioningProvider>
      )}
    </ResourceLoader>
  );
};
