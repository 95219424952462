// This context is responsible for PositionGroup state manipulation

import React, { createContext, useReducer, ReactNode, Dispatch, useEffect, useContext } from 'react';
import { chain, findIndex, first, flatMap, forEach, groupBy, has, isEmpty, last, map, sortBy } from 'lodash';

import { DayBlock } from '@pimm/common';
import { PositionGroupDto, PositionGroupEmployeeDto, PositionTypeEnum } from '@pimm/services/lib/sms-workforce';
import { dateDiffToMinutes, millisToMinutes, stringToDateLocal } from '@app/utils/date-formatter';
import { fillShiftHours, fillTimeslots, mapAssigneeToTimeslot } from '../_helper';
import { KitchenEmployee, KitchenPosition, KitchenPositioning, Timeslot } from '../types';
import { useKitchenLayout } from './kitchen-layout.context';

export enum PositioningActionTypes {
  RESET = 'RESET',
  UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE',
  UPDATE_OPS_LEADER = 'UPDATE_OPS_LEADER',
  UPDATE_POSITION_JOB = 'UPDATE_POSITION_JOB',
  DELETE_POSITION_TIMESLOT = 'DELETE_POSITION_TIMESLOT',
}

// Define the action types
type Action =
  | { type: PositioningActionTypes.RESET; payload?: KitchenPositioning }
  | { type: PositioningActionTypes.UPDATE_EMPLOYEE; payload: KitchenEmployee }
  | { type: PositioningActionTypes.UPDATE_OPS_LEADER; payload: { id: string } }
  | { type: PositioningActionTypes.UPDATE_POSITION_JOB; payload: Partial<KitchenPosition> }
  | { type: PositioningActionTypes.DELETE_POSITION_TIMESLOT; payload: { timeslotId: string } };

// Define the state and dispatch types
type DispatchType = Dispatch<Action>;

const initialState: KitchenPositioning = {
  startTime: new Date(),
  endTime: new Date(),
  employees: [],
  positions: [
    {
      id: '1',
      slotNumber: 1,
      isOpsLeader: false,
      positionJobId: '10',
      positionId: '30d8db04-fb32-48e3-9f6a-5df60c4f108a',
      title: 'PUW Presenter',
      startTime: new Date(),
      endTime: new Date(),
      secondaryJobs: [],
      timeslots: [],
    },
    {
      id: '2',
      slotNumber: 2,
      isOpsLeader: false,
      positionJobId: '20',
      positionId: '2d93f346-d8a5-456c-90c7-242f314bb659',
      title: 'Grill',
      startTime: new Date(),
      endTime: new Date(),
      secondaryJobs: [],
      timeslots: [],
    },
    {
      id: '3',
      slotNumber: 3,
      isOpsLeader: false,
      positionJobId: '30',
      positionId: 'd6657de4-75eb-4520-b227-ab597be03064',
      title: 'Front Assist',
      startTime: new Date(),
      endTime: new Date(),
      secondaryJobs: [],
      timeslots: [],
    },
    {
      id: '4',
      slotNumber: 4,
      isOpsLeader: false,
      positionJobId: '40',
      positionId: '5c21cf4c-454e-4880-b6b7-a9d4efeb0abc',
      title: 'Front Register',
      startTime: new Date(),
      endTime: new Date(),
      secondaryJobs: [],
      timeslots: [],
    },
    {
      id: '5',
      slotNumber: 5,
      isOpsLeader: false,
      positionJobId: '50',
      positionId: 'f7b730e9-d170-47b0-9946-37a41fac4d26',
      title: 'PUW Coordinator',
      startTime: new Date(),
      endTime: new Date(),
      secondaryJobs: [],
      timeslots: [],
    },
    {
      id: '6',
      slotNumber: 6,
      isOpsLeader: false,
      positionJobId: '60',
      positionId: 'fd0c5ead-07af-4c02-a3a9-2b9072ba4dfe',
      title: 'Fry Station',
      startTime: new Date(),
      endTime: new Date(),
      secondaryJobs: [],
      timeslots: [],
    },
    {
      id: '7',
      slotNumber: 7,
      isOpsLeader: false,
      positionJobId: '70',
      positionId: '044a79e4-e7d0-481b-ab3f-82687c307599',
      title: 'PUW Order',
      startTime: new Date(),
      endTime: new Date(),
      secondaryJobs: [],
      timeslots: [],
    },
    {
      id: '8',
      slotNumber: 8,
      isOpsLeader: false,
      positionJobId: '80',
      positionId: '27f5597e-bc59-4815-8151-944d895667b9',
      title: 'Sandwiches Assist',
      startTime: new Date(),
      endTime: new Date(),
      secondaryJobs: [],
      timeslots: [],
    },
    {
      id: '9',
      slotNumber: 9,
      isOpsLeader: false,
      positionJobId: '90',
      positionId: '0af165ac-47e7-4a3c-9b48-8a685c2932de',
      title: 'Lunch Opener',
      startTime: new Date(),
      endTime: new Date(),
      secondaryJobs: [],
      timeslots: [],
    },
    {
      id: '10',
      slotNumber: 10,
      isOpsLeader: false,
      positionJobId: '100',
      positionId: '8c9effc0-ff58-4b6f-b29b-77e1a4e7b7f2',
      title: 'Sandwiches',
      startTime: new Date(),
      endTime: new Date(),
      secondaryJobs: [],
      timeslots: [],
    },
  ],
};

// Create the context
export const KitchenPositioningContext = createContext<{
  dayBlock?: DayBlock;
  positioning: KitchenPositioning;
  positionGroup?: PositionGroupDto;
  dispatch: DispatchType;
}>({
  positioning: initialState,
  dispatch: () => null,
});

// Create the reducer
const kitchenPositioningReducer = (state: KitchenPositioning, action: Action): KitchenPositioning => {
  switch (action.type) {
    case PositioningActionTypes.RESET:
      if (action.payload) {
        return action.payload;
      }
      return initialState;

    case PositioningActionTypes.UPDATE_EMPLOYEE:
      return {
        ...state,
        employees: map(state.employees, employee => {
          if (employee.employeeId === action.payload.employeeId) return action.payload;
          return employee;
        }),
      };

    case PositioningActionTypes.UPDATE_OPS_LEADER:
      return {
        ...state,
        positions: map(state.positions, position => {
          return { ...position, isOpsLeader: position.id === action.payload.id };
        }),
      };

    case PositioningActionTypes.UPDATE_POSITION_JOB:
    case PositioningActionTypes.DELETE_POSITION_TIMESLOT:
      const positions = map(state.positions, position => {
        if (action.type === PositioningActionTypes.UPDATE_POSITION_JOB) {
          if (position.id === action.payload.id) {
            let timeslots: Timeslot[] = position.timeslots;
            // Check if timeslots is part of the payload, don't forget to fill the gap
            if (has(action.payload, 'timeslots')) {
              timeslots = fillTimeslots(action.payload.timeslots ?? [], position.startTime, position.endTime);
            }
            return { ...position, ...action.payload, timeslots: timeslots };
          }
        } else if (action.type === PositioningActionTypes.DELETE_POSITION_TIMESLOT) {
          // Delete position timeslot
          const index = findIndex(position.timeslots, ['id', action.payload.timeslotId]);
          if (index !== -1) {
            const timeslots = position.timeslots;
            timeslots[index] = {
              durationInMinutes: timeslots[index].durationInMinutes,
              startTime: timeslots[index].startTime,
              endTime: timeslots[index].endTime,
            };
            return { ...position, timeslots: timeslots };
          }
        }
        return position;
      });

      const mapAssigneesById = chain(positions)
        .flatMap(postion => postion.timeslots)
        .groupBy('employeeId')
        .value();

      return {
        ...state,
        positions: positions,
        // Make sure to rebuild employee timeslots
        employees: map(state.employees, employee => {
          const shiftStartTime = first(employee.timeslots)?.startTime;
          const shiftEndTime = last(employee.timeslots)?.endTime;
          let filledTimeslots: Timeslot[] = [];

          if (shiftStartTime && shiftEndTime) {
            filledTimeslots = fillTimeslots(mapAssigneesById[employee.employeeId] ?? [], shiftStartTime, shiftEndTime);
          }
          return {
            ...employee,
            timeslots: filledTimeslots,
          };
        }),
      };

    default:
      return state;
  }
};

type KitchenPositioningProviderProps = {
  children: ReactNode;
  dayBlock: DayBlock;
  positionGroup?: PositionGroupDto;
};

export const KitchenPositioningProvider: React.FC<KitchenPositioningProviderProps> = ({ children, dayBlock, positionGroup }) => {
  const { positionLookup } = useKitchenLayout();
  const [positioning, dispatch] = useReducer(kitchenPositioningReducer, initialState);

  useEffect(() => {
    const dpStartTime = stringToDateLocal(positionGroup?.dpStartTime);
    const dpEndTime = stringToDateLocal(positionGroup?.dpEndTime);

    if (dpStartTime && dpEndTime) {
      const employees: KitchenEmployee[] = [];
      const confirmedTime = stringToDateLocal(positionGroup?.confirmedTime);
      const endTime = dpEndTime;
      let startTime = dpStartTime;
      let positions: KitchenPosition[] = [];

      // The "Assign Start" column should be the current time (or = Date Confirmed time-stamp)
      if (!!confirmedTime && startTime && startTime < confirmedTime) {
        startTime = confirmedTime;
      }

      if (startTime && endTime) {
        // transform PositionGroupEmployee into KitchenEmployee
        forEach(positionGroup?.positionSlots, slot => {
          // transform PositionAssigneeDto into Timeslot
          const timeslots = mapAssigneeToTimeslot(slot.positionJob);
          const position: KitchenPosition = {
            id: slot.id,
            isOpsLeader: slot.isOpsLeader ?? false,
            isNonService: slot.positionJob?.positionType === PositionTypeEnum.NonService,
            positionId: slot.positionJob?.positionId,
            positionJobId: slot.positionJob?.id,
            startTime: startTime,
            endTime: endTime,
            secondaryJobs: slot.positionJob?.secondaryJobs ?? [],
            slotNumber: slot.slotNumber,
            title: slot.positionJob?.title,
            timeslots: timeslots,
          };
          positions.push(position);
        });
      }

      const mapAssigneesById = groupBy(
        flatMap(positions, position => position.timeslots),
        'employeeId',
      );

      // transform PositionSlotDto into KitchenPosition
      forEach<PositionGroupEmployeeDto>(positionGroup?.employees ?? [], employee => {
        const employeeId = employee.employeeId as string;
        const lastPosition = employee.lastPositionId ? positionLookup[employee.lastPositionId] : undefined;

        let shiftStartTime = stringToDateLocal(employee.shiftStartTime) ?? startTime;
        let shiftEndTime = stringToDateLocal(employee.shiftEndTime) ?? endTime;

        // Change shift start and end times based on the current dayBlock.
        shiftStartTime = new Date(Math.max(startTime.getTime(), shiftStartTime.getTime()));
        shiftEndTime = new Date(Math.min(endTime.getTime(), shiftEndTime.getTime()));

        employees.push({
          employeeId: employeeId,
          employeeNum: employee.employeeNum,
          name: employee.name!,
          title: employee.title,
          lastPosition: lastPosition
            ? {
                positionId: lastPosition.positionId,
                title: lastPosition.title,
              }
            : undefined,
          durationInMinutes: dateDiffToMinutes(startTime, endTime),
          startTime: startTime,
          endTime: endTime,
          timeslots: mapAssigneesById[employeeId] ?? [],
        });
      });

      // mock position slots display, this will be use to render blurred list
      if (employees.length && isEmpty(positions)) {
        positions = map(initialState.positions, position => ({
          ...position,
          startTime: startTime,
          endTime: endTime,
          timeslots: fillTimeslots([], startTime, endTime),
        }));
      }

      dispatch({
        type: PositioningActionTypes.RESET,
        payload: {
          id: positionGroup?.id,
          hours: fillShiftHours(dpStartTime, dpEndTime),
          startTime: startTime ?? new Date(),
          endTime: endTime ?? new Date(),
          employees: sortBy(employees, 'name'),
          positions: positions,
        },
      });
    }
  }, [positionLookup, positionGroup]);

  return (
    <KitchenPositioningContext.Provider value={{ dayBlock, positioning, positionGroup, dispatch }}>
      {children}
    </KitchenPositioningContext.Provider>
  );
};

export const KitchenPositioningConsumer = KitchenPositioningContext.Consumer;

export const useKitchenPositioning = () => useContext(KitchenPositioningContext);
