import React, { createContext, useContext, useEffect, useState } from 'react';

import { useGetEmployees } from '../hooks/get-employees.hook';

interface StoreEmployeesContextReturn {
  queryEmployees: ReturnType<typeof useGetEmployees>[0];
}

const StoreEmployeesContext = createContext<StoreEmployeesContextReturn>(undefined!);

type StoreEmployeesProviderProps = {
  children: React.ReactNode;
  siteId?: string;
  showAll?: boolean;
};

export const StoreEmployeesProvider = ({ showAll = false, siteId, children }: StoreEmployeesProviderProps) => {
  const [queryEmployees, setGetEmployeesParams] = useGetEmployees();

  useEffect(() => {
    if (siteId) {
      setGetEmployeesParams({ showAll: showAll, siteId: siteId });
    }
  }, [showAll, siteId]);

  return <StoreEmployeesContext.Provider value={{ queryEmployees }}>{children}</StoreEmployeesContext.Provider>;
};

export const StoreEmployeesConsumer = StoreEmployeesContext.Consumer;

export const useStoreEmployees = () => useContext(StoreEmployeesContext);
