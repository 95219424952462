import { Dispatch, SetStateAction, useState } from 'react';
import { QueryKey, UseQueryResult, useQuery } from 'react-query';
import { isEmpty } from 'lodash';

import { GetForSite } from '@pimm/services/lib/store-management/services';
import { ContactCategoryEnum, StoreContactDto } from '@pimm/services/lib/store-management';

type GetStoreContactsParams = {
  categories?: ContactCategoryEnum[];
  siteId?: string;
};

export const useGetStoreContacts = (props?: {
  queryKey?: string;
  query?: GetStoreContactsParams;
}): [UseQueryResult<{ [key in ContactCategoryEnum]?: StoreContactDto[] }>, Dispatch<SetStateAction<GetStoreContactsParams>>] => {
  const [query, setQuery] = useState<GetStoreContactsParams>({ ...props?.query });

  const contacts = useQuery({
    cacheTime: 0,
    enabled: !isEmpty(query?.categories) && !!query?.siteId,
    queryKey: [props?.queryKey ?? 'GetStoreContacts', query] as [QueryKey, GetStoreContactsParams],
    queryFn: async ({ queryKey: [key, params] }) => {
      const requests = params.categories!.map(category =>
        GetForSite({
          category: category,
          siteId: params.siteId!,
        }),
      );

      const result = await Promise.all(requests);
      return result.reduce<{ [key in ContactCategoryEnum]?: StoreContactDto[] }>((obj, contacts, index) => {
        const category = params.categories![index];
        return { ...obj, [category]: contacts };
      }, {});
    },
  });

  return [contacts, setQuery];
};
