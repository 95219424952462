import { Icon, IIconProps } from 'native-base';
import { Path } from 'react-native-svg';

export const VectorIcon = ({ size = 'sm', ...props }: IIconProps) => {
  return (
    <Icon size={size} viewBox="0 0 12 7" fill="none" {...props}>
      <Path
        d="M0.532861 2.27863L5.00161 6.69529C5.09845 6.79293 5.21366 6.87042 5.34059 6.92331C5.46753 6.97619 5.60368 7.00342 5.74119 7.00342C5.87871 7.00342 6.01486 6.97619 6.14179 6.92331C6.26873 6.87042 6.38394 6.79293 6.48078 6.69529L10.8974 2.27863C10.9951 2.18179 11.0726 2.06658 11.1255 1.93965C11.1783 1.81271 11.2056 1.67656 11.2056 1.53905C11.2056 1.40153 11.1783 1.26538 11.1255 1.13844C11.0726 1.01151 10.9951 0.896298 10.8974 0.799462C10.7023 0.605451 10.4383 0.496553 10.1631 0.496553C9.88788 0.496553 9.62386 0.60545 9.42869 0.799462L5.74119 4.48696L2.05369 0.799461C1.85967 0.607019 1.5978 0.498528 1.32453 0.497377C1.18744 0.496585 1.05154 0.522861 0.924624 0.574699C0.79771 0.626537 0.682277 0.702918 0.584943 0.799461C0.483823 0.892813 0.402205 1.00528 0.344802 1.13036C0.2874 1.25543 0.255351 1.39065 0.250508 1.52819C0.245665 1.66572 0.268124 1.80285 0.316588 1.93166C0.365051 2.06047 0.438557 2.17839 0.532861 2.27863Z"
        fill="currentColor"
      />
    </Icon>
  );
};