import { QueryKey, UseQueryResult, useQuery } from 'react-query';

import { GetStoreEventCounts } from '@pimm/services/lib/store-equipment/services';
import { formatToISOString } from '@app/utils/date-formatter';

type GetEventCountsParams = {
  siteId?: string;
  equipmentId?: string;
  startDate?: Date;
  endDate?: Date;
};

export const useGetEventCounts = (
  query?: GetEventCountsParams,
  queryKey?: string,
): UseQueryResult<Awaited<ReturnType<typeof GetStoreEventCounts>>> => {
  const eventCounts = useQuery({
    enabled: !!query?.siteId && !!query?.equipmentId && !!query?.startDate && !!query?.endDate,
    queryKey: [queryKey ?? GetStoreEventCounts, query] as [QueryKey, GetEventCountsParams],
    queryFn: ({ queryKey: [key, params] }) =>
      GetStoreEventCounts(params.siteId!, {
        EquipmentId: params.equipmentId,
        StartTime: formatToISOString(params.startDate!),
        EndTime: formatToISOString(params.endDate!),
      }),
  });

  return eventCounts;
};
