import { Icon, IIconProps } from 'native-base';
import { Path } from 'react-native-svg';

export const SnoozeClock = ({ size = 'sm', ...props }: IIconProps) => {
  return (
    <Icon size={size} viewBox="0 0 20 20" {...props}>
      <Path
        d="M13.75 14.1667H17.9166L13.75 18.3333H17.9166M18.2921 10.8333C18.3194 10.5592 18.3333 10.2812 18.3333 9.99999C18.3333 5.39762 14.6023 1.66666 9.99996 1.66666C5.39759 1.66666 1.66663 5.39762 1.66663 9.99999C1.66663 14.6024 5.39759 18.3333 9.99996 18.3333C10.1397 18.3333 10.2786 18.3299 10.4166 18.3231C10.5564 18.3162 10.6954 18.3059 10.8333 18.2922M9.99996 4.99999V9.99999L13.1153 11.5576"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Icon>
  );
};
