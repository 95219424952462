import React, { Dispatch, SetStateAction, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { filter, first, last, unionBy } from 'lodash';
import moment from 'moment';

import { DailyPositionGoalDto } from '@pimm/services/lib/sms-workforce';
import { AddUpdateDailyPositionGoal, GetDailyPositionGoals } from '@pimm/services/lib/sms-workforce/services';
import { DayBlock } from '@app/features/store-core';
import { useGetDailyPositionGoals } from '../hooks';

interface DailyPositionGoalsContextReturn {
  blockNumber?: number;
  dailyGoals: ReturnType<typeof useGetDailyPositionGoals>[0];
  dayBlocks?: DayBlock[];
  siteId?: string;
  addUpdateQueryData: (positionGoal: DailyPositionGoalDto[], isDelete?: boolean) => void;
  setBlockNumber: Dispatch<SetStateAction<number | undefined>>;
}

type DailyPositionGoalsProviderProps = {
  children: React.ReactNode;
  dayBlocks?: DayBlock[];
  siteId?: string;
};

const DailyPositionGoalsContext = createContext<DailyPositionGoalsContextReturn>(undefined!);

export const DailyPositionGoalsProvider = ({ children, dayBlocks, siteId }: DailyPositionGoalsProviderProps) => {
  const queryClient = useQueryClient();
  const [dailyGoals, setDailyGoalsParams] = useGetDailyPositionGoals();
  const [blockNumber, setBlockNumber] = useState<number>();

  const handleAddUpdate = useCallback(
    (request: Awaited<ReturnType<typeof AddUpdateDailyPositionGoal>>, isDelete?: boolean) => {
      queryClient.setQueryData<Awaited<ReturnType<typeof GetDailyPositionGoals>>>(dailyGoals.queryKey, data => {
        // Check if, "Delete" remove the item from the list
        if (isDelete) return filter(data, _ => _.id !== first(request)?.id);

        return unionBy(request, data, 'id');
      });
    },
    [blockNumber, dailyGoals.data, dayBlocks, siteId],
  );

  useEffect(() => {
    if (dayBlocks?.length && siteId) {
      const endDateTime = moment(last(dayBlocks)?.endTime).add(-1, 'minutes').toDate();
      setBlockNumber(undefined);
      setDailyGoalsParams({
        siteId: siteId,
        startDate: first(dayBlocks)?.startTime,
        endDate: endDateTime,
      });
    }
  }, [dayBlocks, siteId]);

  return (
    <DailyPositionGoalsContext.Provider
      value={{
        blockNumber,
        dailyGoals,
        dayBlocks,
        siteId,
        addUpdateQueryData: handleAddUpdate,
        setBlockNumber,
      }}
    >
      {children}
    </DailyPositionGoalsContext.Provider>
  );
};

export const DailyPositionGoalsConsumer = DailyPositionGoalsContext.Consumer;

export const useDailyPositionGoals = () => {
  // get the context
  const context = useContext(DailyPositionGoalsContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useDailyPositionGoals was used outside of its Provider');
  }
  return context;
};
