import { create } from 'zustand';
import { groupBy } from 'lodash';
import { SiteGoalDto } from '@pimm/services/lib/sms-drivethru/types';

type SiteDailyGoals = {
  goals?: Record<number, SiteGoalDto[]>;
  setGoals: (_goals?: SiteGoalDto[]) => void;
  clear: () => void;
};

export const useSiteDailyGoals = create<SiteDailyGoals>(set => ({
  setGoals: _goals => {
    set({ goals: groupBy(_goals, 'dayOfWeek') });
  },
  clear: () => set({ goals: [] }),
}));
