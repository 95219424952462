import { useState } from 'react';
import { find, first } from 'lodash';
import { Box, HStack, VStack, Pressable, Image, ChevronDownIcon, View } from 'native-base';

import { Text } from '@pimm/base';
import { CultureData } from '@pimm/services/lib/sms-tenants';

type DropdownCulturesProps = {
  cultures?: CultureData[];
  defaultCulture?: string;
  disabled?: boolean;
  onPressCulture: (culture: string) => void;
};

export const DropdownCultures = ({ cultures, defaultCulture = 'en-us', disabled, onPressCulture }: DropdownCulturesProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const selectedCulture: CultureData = find<CultureData>(cultures, _ => _.culture?.toLowerCase() === defaultCulture?.toLowerCase()) ?? {
    culture: 'en-US',
    imageUrl: 'https://sms-cdn.b-cdn.net/assets-web/wendys/US.png',
  };

  return (
    <View position="relative" w="full" h={9}>
      <Pressable
        disabled={disabled}
        onPress={() => setIsOpen(!isOpen)}
        h={9}
        flex={1}
        px={2}
        borderWidth={1}
        borderColor="gray.300"
        borderRadius="lg"
        bg="gray.25"
      >
        <HStack justifyContent="space-between" alignItems="center" h="full">
          <HStack space={2} alignItems="center">
            <Image
              source={{
                uri: selectedCulture?.imageUrl,
              }}
              alt={selectedCulture?.culture}
              size="18px"
            />
            <Text size="lg" fontWeight={600} textTransform="uppercase" lineHeight="xs">
              {first(selectedCulture.culture?.split('-'))}
            </Text>
          </HStack>

          <Box>
            <ChevronDownIcon olor="gray.700" size="xs" />
          </Box>
        </HStack>
      </Pressable>

      {isOpen && (
        <VStack
          position="absolute"
          left={0}
          right={0}
          borderWidth={1}
          borderColor="gray.300"
          borderRadius="md"
          bg="white"
          shadow={2}
          zIndex={10000000}
        >
          {cultures?.map(_ => (
            <Pressable
              key={_.culture}
              onPress={() => {
                if (_.culture) {
                  onPressCulture(_.culture);
                }

                setIsOpen(false);
              }}
              p={2}
              _hover={{ bg: 'gray.200' }}
            >
              <HStack space={2} alignItems="center">
                <Image
                  source={{
                    uri: _?.imageUrl,
                  }}
                  alt={_.culture}
                  size="16px"
                />

                <Text size="lg" fontWeight={600} color="black" textTransform="uppercase" lineHeight="xs">
                  {first(_.culture?.split('-'))}
                </Text>
              </HStack>
            </Pressable>
          ))}
        </VStack>
      )}
    </View>
  );
};

export default DropdownCultures;
