import React, { createContext, useContext, useEffect } from 'react';

import { GetPositionCategoriesQueryParams } from '@pimm/services/lib/sms-positioning';
import { useGetPositionCategories } from '../hooks/get-position-categories.hook';

interface KitchenPositionCategoriesContextReturn {
  categories: ReturnType<typeof useGetPositionCategories>[0];
}

type KitchenPositionCategoriesProviderProps = GetPositionCategoriesQueryParams & {
  children: React.ReactNode;
};

const KitchenPositionCategoriesContext = createContext<KitchenPositionCategoriesContextReturn>(undefined!);

export const KitchenPositionCategoriesProvider = ({ children, brandId, marketId }: KitchenPositionCategoriesProviderProps) => {
  const [categories, setCategoryParams] = useGetPositionCategories();

  useEffect(() => {
    setCategoryParams({ brandId, marketId });
  }, [brandId, marketId]);

  return <KitchenPositionCategoriesContext.Provider value={{ categories }}>{children}</KitchenPositionCategoriesContext.Provider>;
};

export const KitchenPositionCategoriesConsumer = KitchenPositionCategoriesContext.Consumer;

export const useKitchenPositionCategories = () => useContext(KitchenPositionCategoriesContext);
