import { Dispatch, SetStateAction, useState } from 'react';
import { QueryKey, UseQueryResult, useQuery } from 'react-query';

import { GetWeeklyFocusSetting } from '@pimm/services/lib/sms-positioning/services';
import { GetWeeklyFocusSettingQueryParams } from '@pimm/services/lib/sms-positioning';

export const useGetWeeklyFocusSettings = (props?: {
  queryKey?: string;
  query?: GetWeeklyFocusSettingQueryParams;
}): [UseQueryResult<Awaited<ReturnType<typeof GetWeeklyFocusSetting>>>, Dispatch<SetStateAction<GetWeeklyFocusSettingQueryParams>>] => {
  const [queryKey] = useState<string>(props?.queryKey ?? 'GetWeeklyFocusSetting');
  const [query, setQuery] = useState<GetWeeklyFocusSettingQueryParams>({ ...props?.query });

  const weeklyFocusSettings = useQuery({
    enabled: !!query.brandId && !!query.customerId,
    queryKey: [queryKey, query] as [QueryKey, GetWeeklyFocusSettingQueryParams],
    queryFn: ({ queryKey }) => GetWeeklyFocusSetting(queryKey[1]),
  });

  return [weeklyFocusSettings, setQuery];
};
