import { Dispatch, SetStateAction, useState } from 'react';
import { QueryKey, UseQueryResult, useQuery } from 'react-query';
import { first, isEmpty, last } from 'lodash';

import { GetPositionScheduleLive } from '@pimm/services/lib/sms-workforce/services';
import { formatToISOString, stringToDateLocal } from '@app/utils/date-formatter';
import { DayBlock, useSiteTime } from '@app/features/store-core';

type GetPositionScheduleLiveParams = {
  dayBlock: DayBlock;
  siteId?: string;
  subBlockStartTime?: Date;
};

export const useGetPositionScheduleLive = (props?: {
  queryKey?: string;
  query?: GetPositionScheduleLiveParams;
}): [
  UseQueryResult<Awaited<ReturnType<typeof GetPositionScheduleLive>>>,
  Dispatch<SetStateAction<GetPositionScheduleLiveParams | undefined>>,
] => {
  const siteTime = useSiteTime();
  const [query, setQuery] = useState<GetPositionScheduleLiveParams | undefined>(props?.query);

  const liveSchedules = useQuery({
    cacheTime: 0,
    staleTime: 0,
    enabled: !!query?.siteId && !!query?.dayBlock.startTime && !!query?.dayBlock.endTime,
    queryKey: [props?.queryKey ?? 'GetPositionScheduleLive', query] as [QueryKey, GetPositionScheduleLiveParams],
    queryFn: async ({ queryKey: [key, { dayBlock, subBlockStartTime, ...params }] }) => {
      const today = siteTime.today();
      let liveStartTime = subBlockStartTime ?? dayBlock.startTime;
      let isDayBlockNow = false;

      // Check if the current dayBlock is now, change liveStartTime using store current time
      if (today >= dayBlock?.startTime && today < dayBlock.endTime) {
        if (!subBlockStartTime || today < subBlockStartTime) liveStartTime = today;
        isDayBlockNow = true;
      }

      // Check if staffStart time exist, we need to check which is the maximum time value between current liveDateTime or staffStartTime
      // This is to solve the issue in DayBlock 1, that shows blank employees
      if (!subBlockStartTime && dayBlock?.staffStartTime) {
        liveStartTime = new Date(Math.max(liveStartTime.getTime(), dayBlock?.staffStartTime.getTime()));
      }

      let isoSubBlockStartTime = subBlockStartTime ? formatToISOString(subBlockStartTime) : undefined;

      // Try and fetch live position group
      const result = await GetPositionScheduleLive({
        ...params,
        subBlockDateTime: isoSubBlockStartTime,
        blockStartDateTime: formatToISOString(dayBlock.startTime),
        liveStartDateTime: formatToISOString(liveStartTime),
      });

      if (result) {
        // Find pre-selected shiftSubBlock
        if (!isEmpty(result.shiftSubBlocks) && !result.positionGroup?.subBlockTime) {
          let shiftSubBlock = result.shiftSubBlocks?.find(shift => {
            const startTime = stringToDateLocal(shift.subBlockDateTime);
            return startTime && startTime >= siteTime.today();
          });

          if (!shiftSubBlock) {
            shiftSubBlock = isDayBlockNow ? last(result.shiftSubBlocks) : first(result.shiftSubBlocks);
          }
          // Tell the ui to use this pre-selected shiftSubBlock startTime
          result.positionGroup = {
            subBlockTime: isoSubBlockStartTime ?? shiftSubBlock?.subBlockDateTime,
          };
        }
      }
      return result;
    },
  });

  return [liveSchedules, setQuery];
};
