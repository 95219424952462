import { Dispatch, SetStateAction, useState } from 'react';
import { QueryKey, UseQueryResult, useQuery } from 'react-query';
import moment from 'moment';

import { GetOpsTasksPositioning } from '@pimm/services/lib/sms-workforce/services';
import { GetOpsTasksPositioningQueryParams } from '@pimm/services/lib/sms-workforce';

type GetOpsTaskPositioningParams = {
  date?: Date;
  siteId?: string;
};

export const useGetOpsTaskPositioning = (props?: {
  queryKey?: string;
  query: GetOpsTaskPositioningParams;
}): [UseQueryResult<Awaited<ReturnType<typeof GetOpsTasksPositioning>>>, Dispatch<SetStateAction<GetOpsTaskPositioningParams>>] => {
  const [queryKey] = useState<string>(props?.queryKey ?? 'GetOpsTasksFlowChart');
  const [query, setQuery] = useState<GetOpsTaskPositioningParams>({ ...props?.query });

  const opsPhases = useQuery({
    enabled: !!query.date && !!query.siteId,
    queryKey: [queryKey, query] as [QueryKey, GetOpsTasksPositioningQueryParams],
    queryFn: ({ queryKey: [key, params] }) => {
      return GetOpsTasksPositioning({
        ...params,
        date: moment(params.date).format().slice(0, 19), 
      })
    },
  });

  return [opsPhases, setQuery];
};
