import { useMemo, useState } from 'react';
import { forEachRight, last } from 'lodash';

import { ShiftSubBlock } from '@pimm/services/lib/sms-workforce';
import { useSiteTime } from '@app/features/store-core';
import { stringToDateLocal } from '@app/utils/date-formatter';

export type ShiftBlock = Pick<ShiftSubBlock, 'allAssigned' | 'notStarted'> & {
  id: string;
  startTime?: Date;
  endTime?: Date;
};

export const useShiftBlockFocus = (): {
  all: ShiftBlock[];
  selected?: string;
  changeFocus: (value?: string) => void;
  reset: (shiftSubBlocks?: ShiftSubBlock[], value?: string) => void;
} => {
  const siteTime = useSiteTime();

  const [focus, setFocus] = useState<{ blocks: ShiftSubBlock[]; selected?: string }>({ blocks: [] });

  const memoizeBlocks = useMemo(() => {
    const shiftBlocks: ShiftBlock[] = [];

    if (focus.blocks?.length) {
      const subBlockDateTime = stringToDateLocal(focus.blocks[0].subBlockDateTime);
      const dayBlocks = siteTime.toDayBlocks(subBlockDateTime);

      forEachRight(focus.blocks, _ => {
        // Add each shift time at the beginning of the array
        shiftBlocks.unshift({
          id: _.subBlockDateTime!,
          allAssigned: _.allAssigned,
          notStarted: _.notStarted,
          startTime: stringToDateLocal(_.subBlockDateTime),
          endTime: shiftBlocks[0]?.startTime ?? last(dayBlocks)?.endTime,
        });
      });
    }
    return shiftBlocks;
  }, [focus]);

  const handleResetBlocks = (shiftSubBlocks?: ShiftSubBlock[], value?: string) => {
    setFocus({ blocks: shiftSubBlocks ?? [], selected: value });
  };

  const handleChangeFocus = (value?: string) => {
    setFocus(prev => ({ ...prev, selected: value }));
  };

  return {
    all: memoizeBlocks,
    selected: focus.selected,
    changeFocus: handleChangeFocus,
    reset: handleResetBlocks,
  };
};
