import { ComponentProps, useState } from 'react';
import { Box, FlatList, HStack, VStack } from 'native-base';
import { chain, filter, map, uniq, uniqBy } from 'lodash';

import { ButtonGroup, Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { CleaningTaskStatusEnum } from '@pimm/services/lib/sms-workforce/types';
import { formatToISOString } from '@app/utils/date-formatter';
import { useSiteTime } from '@app/features/store-core';
import { CleaningPlan } from '../reducers';
import { CleaningTasksTableItem } from './cleaning-task-table-item';

type CleaningTasksTableProps = Pick<ComponentProps<typeof CleaningTasksTableItem>, 'onOpenLinkDocument'> & {
  isEditable?: boolean;
  plan: CleaningPlan;
};

export const CleaningTasksTable = ({ isEditable, plan, ...props }: CleaningTasksTableProps) => {
  const { translate } = useAppLocale();
  const siteTime = useSiteTime();
  let tabItems = uniq(map(plan.tasks, _ => _.category).filter(Boolean)) as string[];
  // If daily tasks, making sure to show a default tab items if empty
  if (tabItems.length === 0 && plan.scheduleType === 'Daily') {
    tabItems = ['Daypart 1-3', 'Daypart 4-6'];
  }
  const today = siteTime.today();
  const defaultCategory =
    plan.scheduleType === 'Daily'
      ? chain(plan.tasks)
          .clone()
          .reverse()
          .find(_ => !!_.startTime && formatToISOString(_.startTime, true) === formatToISOString(today, true) && today >= _.startTime)
          .value()?.category
      : undefined;

  const [tabFocus, setTabFocus] = useState<string>(defaultCategory ?? tabItems[0]);

  return (
    <VStack h="full">
      <HStack pb={2}>
        <ButtonGroup value={tabFocus} onChange={setTabFocus}>
          {tabItems.map(category => {
            const tasks = filter(plan.tasks, _ => _.category === category);
            const completedCount = tasks.filter(_ => _.assignee?.status === CleaningTaskStatusEnum.Complete).length;
            const isCompleted = !!completedCount && completedCount === tasks?.length;

            return (
              <ButtonGroup.Item
                key={category}
                pr={1.5}
                badgeIcon={{
                  _container: {
                    w: '45px',
                    bg: isCompleted ? 'success.300' : 'white',
                    borderColor: isCompleted ? 'success.300' : 'gray.300',
                  },
                  show: true,
                  value: `${completedCount} of ${tasks?.length ?? 0}`,
                }}
                value={category}
              >
                {translate(category, tasks[0]?.categoryTranslations)}
              </ButtonGroup.Item>
            );
          })}
        </ButtonGroup>
      </HStack>

      <Box flex={1}>
        <FlatList
          borderRadius="lg"
          borderWidth={1}
          borderColor="gray.200"
          data={plan.tasks}
          extraData={[isEditable, plan.date, plan.scheduleType, tabFocus, props.onOpenLinkDocument]}
          stickyHeaderIndices={[0]}
          scrollEnabled
          ListEmptyComponent={() => (
            <Box alignItems="center" justifyContent="center" minH="60px">
              <Text size="md" color="gray.500" fontWeight={500}>
                No available task(s) found
              </Text>
            </Box>
          )}
          ListHeaderComponent={() => (
            <HStack h={9} bg="gray.25" borderBottomWidth={1}>
              <Box alignItems="center" justifyContent="center" px={2} w="50px" h="full">
                <Text size="sm" color="gray.600">
                  Status
                </Text>
              </Box>
              <Box justifyContent="center" px={2} flex={1} h="full">
                <Text size="sm" color="gray.600">
                  Task
                </Text>
              </Box>
              <Box justifyContent="center" px={2} w="20%" maxWidth="140px" h="full">
                <Text size="sm" color="gray.600">
                  Scheduled
                </Text>
              </Box>
              <Box justifyContent="center" px={2} w="25%" maxWidth="280px" h="full">
                <Text size="sm" color="gray.600">
                  Assigned To
                </Text>
              </Box>
              <Box justifyContent="center" w="70px" h="full">
                <Text size="sm" color="gray.600" textAlign="center">
                  N/A?
                </Text>
              </Box>
            </HStack>
          )}
          renderItem={({ item: task }) => {
            if (task.category !== tabFocus) return <></>;
            return (
              <CleaningTasksTableItem
                isEditable={isEditable}
                scheduleDate={plan.date}
                scheduleType={plan.scheduleType}
                task={task}
                onOpenLinkDocument={props.onOpenLinkDocument}
              />
            );
          }}
        />
      </Box>
    </VStack>
  );
};
