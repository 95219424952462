import React from 'react';
import { HStack, View } from 'native-base';

import { Text } from '@pimm/base';
import { CalendarEvent } from '../context/calendar-events-context';
import { CalendarDateIcon, GiftIcon } from '../icons';
import { CalendarEventColor } from '../_helper';

type BadgeCalendarEventProps = React.ComponentProps<typeof View> & {
  date: Date;
  event: CalendarEvent;
};

export const BadgeCalendarEvent = ({ date, event, ...props }: BadgeCalendarEventProps) => {
  const eventData = event.data || {};
  const lastChar = eventData['lastName']?.substring(0, 1);
  const eventColor = CalendarEventColor[event.type];
  return (
    <HStack space={1.5} alignItems="center" px={1} minHeight="22px" bgColor={`${eventColor}.200`} {...props}>
      {event.type === 'Birthday' ? <GiftIcon /> : <CalendarDateIcon />}
      <Text size="sm" flex={1} color={`${eventColor}.900`} lineHeight="xs" numberOfLines={1} ellipsizeMode="tail">
        {eventData['firstName']} {lastChar ? `${lastChar}.` : ''}
      </Text>
      {event.type === 'Anniversary' && (
        <Text size="sm" color={`${eventColor}.900`}>
          {`${date.getFullYear() - event.dateTime.getFullYear()}y`}
        </Text>
      )}
    </HStack>
  );
};
