import { Box, Center, Image, useTheme, VStack } from 'native-base';
import { Asset } from 'expo-asset';
import Constants from 'expo-constants';

import { Text } from '@pimm/base';

export const BrandLogo = () => {
  const { colors } = useTheme();
  const appTitle = Constants.expoConfig?.extra?.title;

  return (
    <Box>
      <Center>
        <Box
          rounded="2xl"
          justifyContent="center"
          alignItems="center"
          bg="white"
          w="80px"
          h="80px"
          borderWidth={1}
          borderColor="gray.100"
          style={{
            shadowColor: colors.gray[600],
            shadowOffset: { width: 0, height: 1.5 },
            shadowOpacity: 0.3,
            shadowRadius: 3,
          }}
        >
          <Image
            alt={appTitle}
            source={{
              uri: Asset.fromModule(require('assets/opsbee.svg')).uri,
            }}
            resizeMode="contain"
            resizeMethod="scale"
            size={58}
            justifyContent="center"
          />
        </Box>
      </Center>
      <VStack mb={3}>
        <Text size="9xl" color="black" fontWeight={700} lineHeight="xs">
          {appTitle}
        </Text>
        <Text size="xs" fontWeight={500} color="gray.700" textAlign="right" lineHeight="xs">
          {`v${Constants.expoConfig?.version}`}
        </Text>
      </VStack>
    </Box>
  );
};
