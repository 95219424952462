import React, { useMemo } from 'react';
import { ScrollView, VStack, useMediaQuery, Box, HStack } from 'native-base';
import { chunk, find, map, range } from 'lodash';
import { ContactCategoryEnum, StoreContactDto } from '@pimm/services/lib/store-management';
import { StoreContactOptionType } from '@app/features/store-info';
import { ContactCard } from './components/card-contact';
import { EmergencyContacts } from './_helper';

type ContactsProps = {
  category?: ContactCategoryEnum;
  contacts?: StoreContactDto[];
  onPressSelect?: (contact: StoreContactDto, option: StoreContactOptionType) => void;
};

export const Contacts = ({ category, contacts = [], ...props }: ContactsProps) => {
  const [isSmallScreen] = useMediaQuery({ maxWidth: 1200 });
  const { chunks, numOfCols } = useMemo(() => {
    const typeCol = category === ContactCategoryEnum.EMERGENCY_MAIN ? 2 : 3;
    let numOfCols = isSmallScreen ? 2 : typeCol;
    let chunks: StoreContactDto[][] = [];
    if (category === ContactCategoryEnum.EMERGENCY_MAIN) {
      const result = EmergencyContacts.map(emergency => {
        const contact = find(contacts, ['emergencyMainContactType', emergency.emergencyMainContactType]);
        return {
          ...emergency,
          ...contact,
        };
      });
      chunks = chunk<StoreContactDto>(result, numOfCols);
    } else {
      chunks = chunk(contacts, numOfCols);
    }
    return { chunks, numOfCols };
  }, [category, contacts]);

  const handlePressSelect = (contact: StoreContactDto) => option => {
    if (props.onPressSelect) props.onPressSelect(contact, option);
  };

  return (
    <ScrollView px={3} h="full" w="full">
      <VStack space={2}>
        {map(chunks, (chunk, index) => {
          return (
            <HStack key={index} space={3} justifyContent="space-between">
              {map(range(numOfCols), col => {
                const contact = chunk[col];
                return (
                  <Box flex={1} key={contact?.storeContactId ?? contact?.contactName ?? `chunk[${index}][${col}]`}>
                    {!!contact && (
                      <ContactCard
                        contact={contact}
                        onPressAction={handlePressSelect(contact)}
                        _container={{
                          height: 130,
                          rounded: 'lg',
                        }}
                      />
                    )}
                  </Box>
                );
              })}
            </HStack>
          );
        })}
      </VStack>
    </ScrollView>
  );
};
