import { Box, HStack, VStack, ArrowDownIcon, ArrowUpIcon, useTheme, Image } from 'native-base';
import { useTranslation } from 'react-i18next';

import { Car01Icon, ClockStopWatchIcon, Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { formatTo, formatToShortDateTime, stringToDateLocal } from '@app/utils/date-formatter';
import { WidgetBox, WidgetBoxProps } from '@app/features/widgets/widget-box';
import { useGetWidgetData } from '@app/features/speed-of-service';
import { useSiteTime } from '@app/features/store-core';

type WidgetSpeedOfServiceProps = WidgetBoxProps & {};

export const WidgetSpeedOfService = ({ ...props }: WidgetSpeedOfServiceProps) => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const { translate } = useAppLocale();
  const siteTime = useSiteTime();
  const daypart = siteTime.toDaypartNow();
  const widgetData = useGetWidgetData({
    date: daypart?.startTime,
    siteId: siteTime.siteId,
  });

  let isOffline = widgetData.data?.importerStatus?.toLowerCase() !== 'online';
  let lastRecordDateTime;

  const daypartLaneGoal = widgetData.data?.daypartTotal?.laneTotalGoal ?? 0;
  const daypartLaneTotal = widgetData.data?.daypartTotal?.laneTotal ?? 0;

  const dayTotalLaneGoal = widgetData.data?.dayTotal.laneTotalGoal ?? 0;
  const dayTotalLane = widgetData.data?.dayTotal.laneTotal ?? 0;

  if (widgetData.data?.lastRecordDateTime) {
    lastRecordDateTime = stringToDateLocal(widgetData.data?.lastRecordDateTime);
  }

  return (
    <WidgetBox {...props}>
      <HStack alignItems="center" justifyContent="space-between" mb={1}>
        <Text size="sm" fontWeight={700} color="gray.600" lineHeight="md" textTransform="uppercase" numberOfLines={1} ellipsizeMode="tail">
          {t('common:speed_of_service')}
        </Text>

        {!!widgetData.data?.portalImageUrl && (
          <Image
            source={{
              uri: widgetData.data.portalImageUrl,
            }}
            bg="gray.100"
            style={{ aspectRatio: 1, width: 50, height: 22 }}
            alt={t('common:speed_of_service')}
            resizeMethod="scale"
            rounded="md"
            justifyContent="center"
          />
        )}
      </HStack>

      <VStack space={1.5}>
        <HStack justifyContent="space-between" alignItems="center" p={2} width="full" bgColor="gray.100" minH={36} borderRadius={6}>
          <Box flex={1} maxWidth="40%">
            <Text size="md" fontWeight={700} color="black">
              {daypart ? translate(daypart.label, daypart.translations) : ''}
            </Text>
          </Box>
          <HStack flex={1} space={1} justifyContent="space-between">
            <HStack space={1} alignItems="center" justifyContent="flex-start" pl={3} w="40%" textAlign="left">
              <Car01Icon size={4} color={colors.gray[700]} />
              <Text fontWeight={700} size="lg" color="black">
                {widgetData.data?.daypartTotal?.totalCars ?? 0}
              </Text>
            </HStack>
            <HStack space={1} alignItems="center" justifyContent="flex-start" px={1} w="55%" textAlign="left">
              <ClockStopWatchIcon size={4} color={colors.gray[700]} />
              <Box flex={1}>
                <Text fontWeight={700} size="lg" color="black">
                  {daypartLaneTotal}
                </Text>
              </Box>
              <Box w={4}>
                {!!daypartLaneGoal && !!daypartLaneTotal && (
                  <>
                    {daypartLaneTotal > daypartLaneGoal ? (
                      <ArrowDownIcon size={3.5} color="success.600" />
                    ) : (
                      <ArrowUpIcon size={3.5} color="error.600" />
                    )}
                  </>
                )}
              </Box>
            </HStack>
          </HStack>
        </HStack>

        <HStack justifyContent="space-between" alignItems="center" p={2} width="full" bgColor="blueLight.100" minH={36} borderRadius={6}>
          <Box flex={1} maxWidth="40%">
            <Text size="md" fontWeight={700} color="black">
              {!!daypart?.startTime ? formatTo(daypart.startTime, 'ddd MM/DD') : ''}
            </Text>
          </Box>
          <HStack flex={1} space={1} justifyContent="space-between">
            <HStack space={1} alignItems="center" justifyContent="flex-start" pl={3} w="40%" textAlign="left">
              <Car01Icon size={4} color={colors.gray[700]} />
              <Text fontWeight={700} size="lg" color="black">
                {widgetData.data?.dayTotal?.totalCars ?? 0}
              </Text>
            </HStack>
            <HStack space={1} alignItems="center" justifyContent="flex-start" px={1} w="55%" textAlign="left">
              <ClockStopWatchIcon size={4} color={colors.gray[700]} />
              <Box flex={1}>
                <Text fontWeight={700} size="lg" color="black">
                  {dayTotalLane}
                </Text>
              </Box>
              <Box w={4}>
                {!!dayTotalLaneGoal && !!dayTotalLane && (
                  <>
                    {dayTotalLane > dayTotalLaneGoal ? (
                      <ArrowDownIcon size={3.5} color="success.600" />
                    ) : (
                      <ArrowUpIcon size={3.5} color="error.600" />
                    )}
                  </>
                )}
              </Box>
            </HStack>
          </HStack>
        </HStack>

        <HStack justifyContent="space-between">
          <Box>
            {!!widgetData.data?.importerStatus && (
              <Box bgColor={isOffline ? 'error.50' : 'success.50'} borderRadius={8} pl={1} pr={1}>
                <Text size="xs" fontWeight={500} color={isOffline ? 'error.700' : 'success.700'}>
                  API Service: {widgetData.data?.importerStatus}
                </Text>
              </Box>
            )}
          </Box>
          {!!lastRecordDateTime && (
            <Text size="xs" fontWeight={600} color="gray.600">
              Last Record: {formatToShortDateTime(lastRecordDateTime)}
            </Text>
          )}
        </HStack>
      </VStack>
    </WidgetBox>
  );
};
