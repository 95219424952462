import { Image } from 'native-base';
import FontAwesomeIcons from 'react-native-vector-icons/FontAwesome';

const CategoryIcon = ({
  iconUri,
  size = 26,
  color = 'black',
}: {
  iconUri?: string;
  size?: number;
  color?: string;
}) => {
  const iconSize = size;
  const iconColor = color;

  if (iconUri) {
    return <Image source={{ uri: iconUri }} color={iconColor} style={{ width: iconSize, height: iconSize }} resizeMode="contain" />;
  }

  return <FontAwesomeIcons name="question" size={iconSize} color={iconColor} />;
};

export default CategoryIcon;
