import { useMemo } from 'react';
import { Box, HStack, VStack, useTheme } from 'native-base';
import FeatherIcon from 'react-native-vector-icons/Feather';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { isEmpty, map, range, sortBy } from 'lodash';

import { Button, Card, CircularProgress, Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { StringTranslation } from '@pimm/services/lib/sms-workforce';
import { useGoalTypes } from '../context/goal-types.context';

type CustomerSatisfactorySurveyProps = {
  _container?: React.ComponentProps<typeof VStack>;
};

export const CustomerSatisfactorySurvey = ({ _container }: CustomerSatisfactorySurveyProps) => {
  const { colors } = useTheme();
  const { translate } = useAppLocale();
  const goalTypes = useGoalTypes();
  const survey = useMemo(() => {
    let goals: { title: string; translations?: StringTranslation[]; value?: number }[] = map(sortBy(goalTypes.data, 'seq'), goal => ({
      title: goal.title as string,
      translations: goal.translations,
      value: goal.score,
    }));

    if (isEmpty(goals)) {
      goals = [
        { title: 'OSAT', value: undefined },
        { title: 'Taste', value: undefined },
        { title: 'Accuracy', value: undefined },
        { title: 'Speed', value: undefined },
        { title: 'Friendliness', value: undefined },
        { title: 'Cleanliness', value: undefined },
      ];
    }
    return {
      customerExperience: {
        attachment: false,
        title: 'Customer Experience',
        scores: [
          { title: 'Zone of  Defection', value: 33.3 },
          { title: 'Likely to Recommend' },
          { title: 'Likely to Return', value: 52.9 },
          { title: 'Experience a Problem', value: 27.8 },
          { title: 'Problem Resolution', value: 0 },
        ],
      },
      keyDriverAndPlays: {
        attachment: true,
        title: 'Key Drivers & Plays',
        scores: goals,
      },
    };
  }, [goalTypes.data]);

  const handlePressOpenPortal = () => {
    window.open('https://reporting.smg.com/index.aspx', '_blank', 'noreferrer');
  };

  return (
    <VStack space={3} py={1} {..._container}>
      <Text size="2xl" fontWeight={700} color="gray.900">
        Customer Satisfaction Surveys
      </Text>

      {[survey.keyDriverAndPlays].map(survey => {
        return (
          <Card key={survey.title} rounded="xl" pt={1.5}>
            <HStack mb={5} h={12} borderBottomWidth={1} alignItems="center" justifyContent="space-between">
              <Text size="lg" fontWeight={700} color="black">
                {survey.title}
              </Text>
              {/*  TODO: Will Enable after convention
               {!!survey.attachment && <IconButton rounded="full" p={2} icon={<FileAttachmentIcon size="md" color="primary.500" />} />} */}
            </HStack>
            <HStack alignItems="center">
              <HStack space={3} rounded="3xl" alignItems="center" p={4} backgroundColor="gray.100">
                <Box style={{ maxWidth: 80 }}>
                  <Text size="lg" fontWeight={700} color="black" textAlign="center">
                    {survey.scores[0].title}
                  </Text>
                </Box>
                <CircularProgress
                  _text={{ size: 'xl', decimalScale: 1, suffix: '%' }}
                  activeStrokeColor={colors.blueLight[500]}
                  inActiveStrokeColor={colors.white}
                  radius={55}
                  strokeWidth={16}
                  value={survey.scores[0].value ?? 0}
                />
              </HStack>
              {range(1, survey.scores.length).map(index => {
                const score = survey.scores[index];
                return (
                  <VStack key={score.title} flex={1} space={1} alignItems="center" justifyContent="center">
                    <Box alignItems="center" justifyContent="center" h={9} width={76}>
                      <Text size="md" fontWeight={500} color="black" textAlign="center" lineHeight="sm">
                        {translate(score.title, undefined)}
                      </Text>
                    </Box>
                    <Box position="relative" alignItems="center" justifyContent="center">
                      <CircularProgress
                        _text={{ size: 'lg', decimalScale: 1, suffix: '%' }}
                        activeStrokeColor={colors.blueLight[500]}
                        inActiveStrokeColor={colors.gray[200]}
                        radius={45}
                        strokeWidth={13}
                        value={score.value ?? 0}
                      />

                      {score.value === undefined && (
                        <Box position="absolute" alignItems="center" justifyContent="center" w={16} h={16} bgColor="white" rounded="full">
                          <Text size="lg" fontWeight={500} color="black" textAlign="center">
                            N/A
                          </Text>
                        </Box>
                      )}
                    </Box>
                  </VStack>
                );
              })}
            </HStack>
          </Card>
        );
      })}

      <HStack space={3} justifyContent="flex-end">
        <Button w={130} alternate outline pr={0} endIcon={<FeatherIcon name="arrow-up-right" size={18} />} onPress={handlePressOpenPortal}>
          SMG 360
        </Button>
        <Button
          w={130}
          alternate
          disabled
          outline
          pr={0}
          endIcon={<Ionicons name="ios-cloud-download-outline" size={18} style={{ paddingLeft: 5 }} />}
        >
          Download
        </Button>
      </HStack>
    </VStack>
  );
};
