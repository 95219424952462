import { Icon, IIconProps } from 'native-base';
import { G, Path } from 'react-native-svg';

export const Mail01Icon = ({ size = 'sm', ...props }: IIconProps) => {
  return (
    <Icon size={size} viewBox="0 0 20 20" fill="none" {...props}>
      <Path
        d="M1.6665 5.83337L8.4706 10.5962C9.02158 10.9819 9.29707 11.1748 9.59672 11.2495C9.86142 11.3154 10.1383 11.3154 10.403 11.2495C10.7026 11.1748 10.9781 10.9819 11.5291 10.5962L18.3332 5.83337M5.6665 16.6667H14.3332C15.7333 16.6667 16.4334 16.6667 16.9681 16.3942C17.4386 16.1545 17.821 15.7721 18.0607 15.3017C18.3332 14.7669 18.3332 14.0668 18.3332 12.6667V7.33337C18.3332 5.93324 18.3332 5.23318 18.0607 4.6984C17.821 4.22799 17.4386 3.84554 16.9681 3.60586C16.4334 3.33337 15.7333 3.33337 14.3332 3.33337H5.6665C4.26637 3.33337 3.56631 3.33337 3.03153 3.60586C2.56112 3.84554 2.17867 4.22799 1.93899 4.6984C1.6665 5.23318 1.6665 5.93324 1.6665 7.33337V12.6667C1.6665 14.0668 1.6665 14.7669 1.93899 15.3017C2.17867 15.7721 2.56112 16.1545 3.03153 16.3942C3.56631 16.6667 4.26637 16.6667 5.6665 16.6667Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Icon>
  );
};
