import { Icon, IIconProps } from 'native-base';
import { Path } from 'react-native-svg';

export const WifiIcon = ({ size = 'sm', ...props }: IIconProps) => {
  return (
    <Icon size={size} viewBox="0 0 18 18" fill="none" {...props}>
      <Path
        d="M9 12.625H9.0075M17.1048 4.52557C14.9697 2.56899 12.1242 1.375 8.99991 1.375C5.87561 1.375 3.03017 2.56899 0.89502 4.52557M3.54895 7.18223C5.00254 5.90174 6.91055 5.125 8.99998 5.125C11.0894 5.125 12.9974 5.90174 14.451 7.18223M11.7737 9.83133C11.0094 9.2322 10.0464 8.875 8.99991 8.875C7.93762 8.875 6.96129 9.24309 6.19153 9.85869"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Icon>
  );
};
