import { Dispatch, SetStateAction, useState } from 'react';
import { QueryKey, UseQueryResult, useQuery } from 'react-query';

import { GetWithStructure } from '@pimm/services/lib/sms-documents/services';
import { Category, GetWithStructureQueryParams } from '@pimm/services/lib/sms-documents';

export const useGetDocumentStructure = (props?: {
  queryKey?: string;
  query?: GetWithStructureQueryParams; 
}): [UseQueryResult<Category[]>, Dispatch<SetStateAction<GetWithStructureQueryParams>>] => {
  const [query, setQuery] = useState<GetWithStructureQueryParams>({
    ...props?.query,
  });

  const categories = useQuery({
    enabled: !!query?.brandId && !!query.marketId,
    queryKey: [props?.queryKey ?? 'GetDocumentStructure', query] as [QueryKey, GetWithStructureQueryParams],
    queryFn: ({ queryKey }) => {
      return GetWithStructure(queryKey[1]);
    },
  });
  return [categories, setQuery];
};
