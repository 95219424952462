import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { CloseIcon, HStack, IconButton, ScrollView, Spacer, useMediaQuery, VStack } from 'native-base';
import Feather from '@expo/vector-icons/Feather';
import { keys } from 'lodash';

import { Button, ButtonGroup, Text } from '@pimm/base';
import { AddUpdateSiteGoalRequest, DaypartProfileInput, DaysOfTheWeekEnum } from '@pimm/services/lib/sms-drivethru/types';
import { AddUpdateSiteGoal } from '@pimm/services/lib/sms-drivethru/services';
import { useSiteConfig } from '@app/features/store-core';
import { FormDataSiteGoals, useFormSiteGoals } from '../hooks';
import { useSiteDailyGoals } from '../state';
import { SettingsStoreGoals } from './settings-store-goals';
import { SettingsStoreGuidelines } from './settings-store-guidelines';

type GoalSettingsProps = {
  onClose?: () => void;
};

export const GoalSettings = (props: GoalSettingsProps) => {
  const [isSmallScreen] = useMediaQuery({ maxWidth: 1200 });
  const queryClient = useQueryClient();
  const { siteConfig } = useSiteConfig();

  const [tabFocus, setTabFocus] = useState<'guidelines' | 'goals'>('goals');

  const goals = useSiteDailyGoals(state => state.goals);
  const setGoalProfiles = useSiteDailyGoals(state => state.setGoals);
  const form = useFormSiteGoals({
    defaultValues: {
      weekdays: siteConfig.config?.storeHoursConfig?.dayparts?.map(_ => ({
        daypart: {
          label: _.label,
          partNumber: _.partNumber,
          dpStartTime: _.startTime,
        },
        goal: {
          goalProfileId: 0,
          goalProfileName: 'Custom',
          dpStartTime: _.startTime,
        },
      })),
    },
  });

  const addUpdateSiteGoals = useMutation({
    mutationFn: async (formData: FormDataSiteGoals & { siteId?: string }) => {
      const { isSevenDays, siteId, weekdays, weekends } = formData;
      const fieldInputs = {
        [DaysOfTheWeekEnum.Weekdays]: weekdays,
        [DaysOfTheWeekEnum.Weekends]: weekends,
      };

      const addUpdateRequest: AddUpdateSiteGoalRequest[] = keys(fieldInputs)
        .map(key => {
          const inputs: DaypartProfileInput[] = [];
          const fields = fieldInputs[key];
          let daysOfWeek = key as DaysOfTheWeekEnum;

          if (isSevenDays && daysOfWeek === DaysOfTheWeekEnum.Weekends) return undefined;

          fields.forEach(({ goal }) => {
            const input: DaypartProfileInput = {
              dpStartTime: goal.dpStartTime,
              goalProfileId: goal.goalProfileId,
              goalProfileName: goal.goalProfileName,
            };
            // Form custom goal values
            if (!input.goalProfileId) {
              input.greet = goal.greet;
              input.menuBoard = goal.menuBoard;
              input.pickup = goal.pickup;
              input.total = goal.total;
            }
            inputs.push(input);
          });

          // Replace daysOfWeek into All if the user didn't select weekdays/weekend
          if (isSevenDays && daysOfWeek === DaysOfTheWeekEnum.Weekdays) {
            daysOfWeek = DaysOfTheWeekEnum.All;
          }

          return {
            daysOfWeek: daysOfWeek,
            daypartProfiles: inputs,
            siteId: siteId,
          };
        })
        .filter(payload => !!payload);

      const promise = Promise.all(addUpdateRequest.map(payload => AddUpdateSiteGoal(payload)));
      await promise;
    },
    onSuccess: () => {
      const queryCache = queryClient.getQueryCache();
      queryCache.getAll().forEach(cache => {
        const isValidQuery = ['GetWeeklyData', 'GetSiteGoals', 'GetTimerData'].some(key => cache.queryKey.includes(key));

        if (isValidQuery) {
          queryClient.invalidateQueries({ queryKey: [cache.queryKey[0]] });
          queryClient.refetchQueries({ queryKey: cache.queryKey });
        }
      });
      if (props.onClose) props.onClose();
    },
  });

  const handleFormSave = () => {
    if (goals) {
      addUpdateSiteGoals.mutate({ ...form.getValues(), siteId: siteConfig.id });
    }
  };

  return (
    <VStack h="full">
      <HStack space={3} alignItems="center" pl={4} pr={2} py={2} minH="56px" borderBottomWidth={1}>
        <Text size="xl" fontWeight={700} color="gray.900">
          Goal Settings
        </Text>

        <Spacer />

        {isSmallScreen && (
          <ButtonGroup value={tabFocus} onChange={setTabFocus}>
            <ButtonGroup.Item value="guidelines">Goal Guidelines</ButtonGroup.Item>
            <ButtonGroup.Item value="goals">Store Goals</ButtonGroup.Item>
          </ButtonGroup>
        )}

        {!!props.onClose && (
          <IconButton
            p={2}
            _pressed={{ bg: 'gray.100' }}
            _hover={{ bg: 'gray.100' }}
            icon={<CloseIcon size="14px" color="gray.700" />}
            onPress={props.onClose}
          />
        )}
      </HStack>

      <HStack flex={1}>
        {(!isSmallScreen || tabFocus === 'guidelines') && (
          <ScrollView
            w={{ base: 'full', xl: '1/2' }}
            contentContainerStyle={{ flexGrow: 1 }}
            borderRightWidth={{ xl: 1 }}
            borderColor="gray.200"
          >
            <SettingsStoreGuidelines />
          </ScrollView>
        )}

        {(!isSmallScreen || tabFocus === 'goals') && (
          <ScrollView w={{ base: 'full', xl: '1/2' }} contentContainerStyle={{ flexGrow: 1 }}>
            <SettingsStoreGoals form={form} />
          </ScrollView>
        )}
      </HStack>

      <HStack alignItems="center" px={4} h="56px" borderTopWidth={1} justifyContent="flex-end" space={3}>
        <Button size="sm" alternate outline minWidth={90} disabled={addUpdateSiteGoals.isLoading} onPress={props.onClose}>
          Cancel
        </Button>
        <Button
          size="sm"
          minWidth={100}
          isLoading={addUpdateSiteGoals.isLoading}
          startIcon={<Feather name="save" size={20} color="white" />}
          disabled={!form.formState.isDirty}
          onPress={form.handleSubmit(handleFormSave)}
        >
          Save
        </Button>
      </HStack>
    </VStack>
  );
};
