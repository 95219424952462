import { Icon, IIconProps } from 'native-base';
import { Circle } from 'react-native-svg';

export const Ellipse51Icon = ({ size = 'sm', ...props }: IIconProps) => {
  return (
    <Icon size={size} viewBox="0 0 22 22" fill="none" {...props}>
      <Circle cx="11" cy="11" r="10" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeDasharray="5 5" fill="none" />
    </Icon>
  );
};
