import { useState } from 'react';
import { Box, HStack, IconButton, Popover, VStack, useTheme } from 'native-base';
import { map } from 'lodash';

import { ArrowCircleBrokenLeftIcon, ChevronSelectorVerticalIcon, HelpCircleIcon, MinusCircleIcon, ReverseLeftIcon, Text } from '@pimm/base';

export const PopoverTooltipPositionActions = () => {
  const { colors } = useTheme();
  const [tips] = useState<{ icon?: React.ReactNode; title: string; description: string }[]>([
    {
      icon: <ChevronSelectorVerticalIcon size="20px" color="gray.700" />,
      title: 'Position Selection',
      description: 'Use this button to select a different\nposition.',
    },
    {
      icon: <ReverseLeftIcon size="14px" color="gray.700" />,
      title: 'Restore Template Position',
      description: 'Use this button to remove a manual\noverride.',
    },
    {
      icon: <ArrowCircleBrokenLeftIcon size="18px" color="gray.700" />,
      title: 'Available for Service',
      description: 'Use this button to designate employee\nas available for service.',
    },
    {
      icon: <MinusCircleIcon size="18px" color="gray.700" />,
      title: 'Non-Service Position',
      description: 'Use this button to designate employee\nas non-service.',
    },
  ]);
  return (
    <Popover
      isKeyboardDismissable
      trigger={popoverProps => {
        return (
          <IconButton
            accessibilityLabel="Position Actions Tooltip"
            rounded="lg"
            p={1.5}
            borderWidth={1}
            borderColor="gray.300"
            bg="white"
            _hover={{ bg: 'gray.25', _icon: { color: colors.primary[500] } }}
            _pressed={{ _icon: { color: colors.primary[500] } }}
            _focus={{ _icon: { color: colors.primary[500] } }}
            icon={<HelpCircleIcon size={5} color="gray.700" />}
            {...popoverProps}
          />
        );
      }}
    >
      <Popover.Content accessibilityLabel="Position Actions Tooltip" rounded="lg" bg="white">
        <Popover.Arrow zIndex={1} />
        <Popover.Body py={3} px={4} w="320" bg="white">
          <Text size="lg" fontWeight={600} color="black" lineHeight="xs" mb={2}>
            Position Actions
          </Text>

          <VStack space={1}>
            {map(tips, tip => (
              <HStack key={tip.title} space={3} py={1} minH={10}>
                <Box rounded="md" alignItems="center" justifyContent="center" w="32px" h="32px" borderWidth={1} borderColor="gray.300">
                  {tip.icon}
                </Box>
                <VStack space={1}>
                  <Text size="md" fontWeight={600} color="black" lineHeight="xs">
                    {tip.title}
                  </Text>
                  <Text size="md" fontWeight={500} color="gray.700" numberOfLines={2} lineHeight="xs" lineBreakMode="tail">
                    {`${tip.description}`}
                  </Text>
                </VStack>
              </HStack>
            ))}
          </VStack>
        </Popover.Body>
      </Popover.Content>
    </Popover>
  );
};
