import { VictoryPie } from 'victory-pie';
import { Box, Center, CircleIcon, HStack, IBoxProps, Spacer, useTheme, VStack } from 'native-base';
import { map } from 'lodash';

import { Text } from '@pimm/base';
import { SiteGoalDto, TimerDataDetailDto } from '@pimm/services/lib/sms-drivethru/types';
import { SpeedBadge } from './speed-badge';

type TimeBreakdownProps = {
  _container?: IBoxProps;
  bordered?: boolean;
  title?: string;
  caption?: string;
  goal?: SiteGoalDto;
  timerDetails?: TimerDataDetailDto;
};

export const TimeBreakdown = ({ _container, bordered, caption, goal, timerDetails, title }: TimeBreakdownProps) => {
  const { colors } = useTheme();
  const goals = [
    { name: 'Greet', actual: timerDetails?.avgGreetTime ?? 0, goal: goal?.greet ?? 0, color: colors.blue[400] },
    { name: 'Menu Board', actual: timerDetails?.avgMenuTime ?? 0, goal: goal?.menuBoard ?? 0, color: colors.grayBlue[400] },
    { name: 'Pickup', actual: timerDetails?.avgPickupTime ?? 0, goal: goal?.pickup ?? 0, color: colors.fuchsia[400] },
  ];

  const actualTotal = timerDetails?.avgTotalTime ?? 0;
  const goalTotal = goal?.total ?? 0;

  return (
    <Box w="full" {..._container}>
      <Text size="xl" fontWeight={700} color="gray.900" numberOfLines={2} lineHeight="xl">
        {title}
      </Text>

      {!!caption && (
        <Text size="md" fontWeight={500} color="gray.600" numberOfLines={3} lineHeight="xs">
          {caption}
        </Text>
      )}

      <HStack
        space={2}
        rounded={bordered ? 'lg' : undefined}
        alignItems="center"
        justifyContent="center"
        mt={3}
        px={bordered ? 3 : 0}
        minH={10}
        borderWidth={bordered ? 1 : 0}
        bg={bordered ? 'gray.25' : undefined}
      >
        <Text size="lg" fontWeight={700} color="black" textTransform="uppercase" lineHeight="xs">
          Lane Total
        </Text>
        <Text size="xl" fontWeight={700} color="black" lineHeight="xs">
          {actualTotal}s
        </Text>
        {!!actualTotal && <SpeedBadge h={5} actual={actualTotal} goal={goalTotal} />}
        <Spacer />
        <Text size="md" fontWeight={600} color="gray.600" textAlign="right" lineHeight="xs">
          {`(Goal ${goalTotal}s)`}
        </Text>
      </HStack>

      <Center>
        <Box my={-1} w="90%" maxW={220}>
          <VictoryPie
            animate={{
              duration: 2000,
            }}
            innerRadius={115}
            labels={() => ''}
            colorScale={timerDetails ? goals.map(_ => _.color!) : ['#dddddd']}
            data={timerDetails ? goals.map(_ => ({ x: _.name, y: _.actual })) : [{ x: 'No Data', y: 1 }]}
          />
        </Box>
      </Center>

      <Center>
        <VStack w="full">
          {map(goals, goal => (
            <HStack key={`time-breakdown.${goal.name}`} minHeight={10}>
              <HStack w="2/6" h="full" alignItems="center" space={2}>
                <CircleIcon size={2} color={goal.color} />
                <Text size="md" fontWeight={600} color="gray.700" lineHeight="xs">
                  {goal.name}
                </Text>
              </HStack>

              <HStack w="2/6" h="full" alignItems="center" justifyContent="center">
                <Box flex={1} alignItems={!!timerDetails ? 'flex-end' : 'center'}>
                  <Text size="md" fontWeight={700} color="black">
                    {`${goal.actual ?? 0}s`}
                  </Text>
                </Box>

                {!!timerDetails && (
                  <HStack flex={1} ml={3}>
                    <SpeedBadge h={5} actual={goal.actual} goal={goal.goal} />
                  </HStack>
                )}
              </HStack>

              <Box w="2/6" h="full" justifyContent="center">
                <Text size="md" fontWeight={600} color="gray.600" textAlign="right">
                  {`(Goal ${goal.goal ?? 0}s)`}
                </Text>
              </Box>
            </HStack>
          ))}
        </VStack>
      </Center>
    </Box>
  );
};
