import { useTranslation } from 'react-i18next';
import { VStack, Pressable } from 'native-base';
import { chain, map, range } from 'lodash';

import { ResourceLoader } from '@app/components/shared';
import { BadgeCalendarEvent, useCalendarEvents } from '@app/features/calendar';
import { WidgetBox, WidgetBoxProps } from '../widget-box';
import { useSiteTime } from '@app/features/store-core';

export const WidgetCalendar = ({ title = 'Calendar', ...props }: WidgetBoxProps) => {
  const { t } = useTranslation();
  const siteTime = useSiteTime();
  const { events: allEvents } = useCalendarEvents();

  const today = siteTime.today();
  const events = chain(allEvents)
    .filter(({ dateTime }) => today.getDate() === dateTime.getDate() && today.getMonth() === dateTime.getMonth())
    .sortBy('dateTime')
    .value();

  return (
    <WidgetBox title={t('common:calendar')} {...props}>
      <ResourceLoader
        minH="90px"
        bgColor="red"
        isEmpty={events.length === 0}
        emptyMessage={t('common:no_events_today')}
        _text={{ size: 'sm' }}
      >
        <VStack w="full" h="full" space={0.5}>
          {map(range(4), index => {
            const event = events[index];
            if (!event) return undefined;
            return (
              <Pressable key={index} rounded="sm">
                <BadgeCalendarEvent date={today} event={event} rounded="sm" height={6} />
              </Pressable>
            );
          })}
        </VStack>
      </ResourceLoader>
    </WidgetBox>
  );
};
