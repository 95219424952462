import { useState } from 'react';
import { Box, HStack, VStack } from 'native-base';

import { Button, Text } from '@pimm/base';
import { TimePicker } from '@app/components/shared';
import { useSiteTime } from '@app/features/store-core';

export interface TimeInputs {
  startTime: Date;
  endTime: Date;
}

type ScheduleTimePickerProps = {
  displayText?: string;
  displaySubText?: string;
  scheduledDate?: Date;
  onApply: (dueDate: Date) => void;
  onCancel: () => void;
};

export const ScheduleTimePicker = ({ scheduledDate, ...props }: ScheduleTimePickerProps) => {
  const siteTime = useSiteTime();
  const [dateTimeInput, setDateTimeInput] = useState<Date>(scheduledDate ?? siteTime.today());
  
  const handleChangeTime = (value: Date) => {
    setDateTimeInput(value);
  };

  const handlePressApply = () => {
    props.onApply(dateTimeInput);
  };

  return (
    <VStack space={3} pt={!!props.displayText ? 3 : 5} w="full" rounded="lg" overflow="hidden">
      {!!props.displayText && (
        <Box px={4}>
          <Text size="lg" fontWeight={700} color="black">
            {props.displayText}
          </Text>
          {!!props.displaySubText && (
            <Text size="lg" fontWeight={400} color="gray.600" lineHeight="xs">
              {props.displaySubText}
            </Text>
          )}
        </Box>
      )}

      <HStack space={3} px={4} w="full">
        <VStack flex={1} space={3} rounded="xl" p={3} bg="gray.50">
          <Box rounded="xl" p={2} borderWidth={1} bg="white">
            <TimePicker value={dateTimeInput} onChange={handleChangeTime} />
          </Box>
        </VStack>
      </HStack>

      <HStack mt={2} borderBottomRadius="xl" overflow="hidden">
        <Button
          flex={1}
          size="lg"
          rounded="none"
          borderWidth="gray.100"
          backgroundColor="gray.200"
          _text={{ fontSize: 'lg', color: 'gray.700' }}
          onPress={props.onCancel}
        >
          Cancel
        </Button>

        <Button flex={1} size="lg" rounded="none" _text={{ fontSize: 'lg' }} onPress={handlePressApply}>
          Apply
        </Button>
      </HStack>
    </VStack>
  );
};
