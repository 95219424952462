import { useMemo } from 'react';
import { Box, ScrollView } from 'native-base';
import { map } from 'lodash';

import { Modal, Text } from '@pimm/base';
import { EquipmentInstancesDto, PerformanceMetricsDto, RollupDateRange } from '@pimm/services/lib/store-equipment';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { CollapsibleSensors } from './collapsible-sensors';
import { FilterDisplay } from './filter-buttons';
import { performanceFilter } from '../_helper';
import { SensorEquipment, useEquipmentSummary } from '../context';
import { DiagnosticsChartData } from './diagnostics-chart-data';

type PerformanceSensorsViewProps = {
  filter: FilterDisplay;
  rollupDays: RollupDateRange;
};

export const PerformanceSensorsView = ({ filter, rollupDays, ...props }: PerformanceSensorsViewProps) => {
  const equipmentSummary = useEquipmentSummary();

  const modalEquipment = useModalFocus<
    { isDoor?: boolean; equipment?: EquipmentInstancesDto; performance?: PerformanceMetricsDto } | undefined
  >(undefined);

  const handleOpenDiagnostics = (_equipment: SensorEquipment, isDoor?: boolean) => {
    modalEquipment.setOpen({
      isDoor: isDoor,
      equipment: _equipment.equipment,
      performance: _equipment.performance,
    });
  };

  const filteredSummary = useMemo(() => {
    const sections = equipmentSummary
      .map(_group => {
        const filteredEquipment = _group.equipment.filter(equipment => {
          return !!equipment.temperature && performanceFilter(filter, equipment);
        });
        return {
          displayGroup: _group.displayGroup,
          equipment: filteredEquipment,
        };
      })
      .filter(section => section.equipment.length > 0 && section.equipment.some(equip => !!equip.temperature));
    return sections;
  }, [equipmentSummary, filter]);

  return (
    <ScrollView flex={1} py={2} px={1.5} h="full" bg="gray.100" _contentContainerStyle={{ flexGrow: 1 }}>
      {filteredSummary.length > 0 ? (
        map(filteredSummary, _group => {
          return (
            <CollapsibleSensors
              key={_group.displayGroup}
              title={_group.displayGroup}
              equipment={_group.equipment}
              isPerformance
              onOpenModal={handleOpenDiagnostics}
            />
          );
        })
      ) : (
        <Box flex={1} alignItems="center" justifyContent="center" p={3} textAlign="center">
          <Text size="lg" fontWeight={600} color="gray.500">
            No Data Available
          </Text>
        </Box>
      )}

      <Modal size="xl" _content={{ rounded: 'xl', maxW: 1100, minH: 520 }} isOpen={modalEquipment.isOpen} noPadding hideClose>
        <DiagnosticsChartData
          key={modalEquipment.payload?.equipment?.EquipmentId}
          rollupDays={rollupDays}
          isPerformance
          onClose={modalEquipment.setHide}
          {...modalEquipment.payload}
        />
      </Modal>
    </ScrollView>
  );
};
