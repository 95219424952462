import { useMemo, useState } from 'react';
import { ArrowDownIcon } from 'native-base';

import { ButtonGroup } from '@pimm/base';
import { DoorEventIcon, Thermometer02Icon } from '../icons';

export type FilterDisplay = 'all' | 'uptime' | 'data' | 'oos' | 'temp' | 'temp-event' | 'door-event';

type FilterButtonsProps = Pick<React.ComponentProps<typeof ButtonGroup>, 'value' | 'onChange'> & {
  isPerformance: boolean;
};

export const FilterButtons = ({ isPerformance, ...props }: FilterButtonsProps) => {
  const [tabItems] = useState(() =>
    [
      { title: 'All', value: 'all', isHidden: false },
      { title: 'Uptime %', value: 'uptime', isHidden: isPerformance, startIcon: <ArrowDownIcon size={3} /> },
      { title: 'Data %', value: 'data', isHidden: isPerformance },
      { title: 'OOS', value: 'oos', isHidden: isPerformance },
      { title: 'In-Temp %', value: 'temp', isHidden: !isPerformance, startIcon: <ArrowDownIcon size={3} /> },
      { title: 'Event', value: 'temp-event', isHidden: !isPerformance, startIcon: <Thermometer02Icon size={3} /> },
      { title: 'Event', value: 'door-event', isHidden: !isPerformance, startIcon: <DoorEventIcon size={3} /> },
    ].filter(segment => !segment.isHidden),
  );

  return (
    <ButtonGroup {...props}>
      {tabItems.map(tabItem => (
        <ButtonGroup.Item
          key={tabItem.value}
          value={tabItem.value}
          startIcon={tabItem.startIcon}
          minWidth={tabItem.value === 'all' ? 60 : 70}
        >
          {tabItem.title}
        </ButtonGroup.Item>
      ))}
    </ButtonGroup>
  );
};
