import { Ellipse, Svg, SvgProps } from 'react-native-svg';

export const FreestyleLogo = (props: SvgProps) => {
  return (
    <Svg width="27" height="52" viewBox="0 0 27 52" {...props}>
      <Ellipse cx="22.5593" cy="4.75391" rx="3.73026" ry="3.69752" fill="#F04438" />
      <Ellipse cx="12.9672" cy="16.0226" rx="3.73026" ry="3.69752" fill="#F04438" />
      <Ellipse cx="19.0658" cy="24.8849" rx="4.61842" ry="4.57788" fill="#F04438" />
      <Ellipse cx="18.5922" cy="47.4221" rx="4.61842" ry="4.57788" fill="#F04438" />
      <Ellipse cx="11.9014" cy="31.0474" rx="3.73026" ry="3.69752" fill="#F04438" />
      <Ellipse cx="5.15138" cy="19.0744" rx="3.49342" ry="3.46275" fill="#F04438" />
      <Ellipse cx="10.0066" cy="39.3815" rx="3.49342" ry="3.46275" fill="#F04438" />
      <Ellipse cx="16.6974" cy="38.6185" rx="2.72368" ry="2.69977" fill="#F04438" />
      <Ellipse cx="11.5461" cy="23.3002" rx="3.01974" ry="2.99323" fill="#F04438" />
      <Ellipse cx="18.2369" cy="32.632" rx="2.13158" ry="2.11287" fill="#F04438" />
      <Ellipse cx="5.8027" cy="34.9797" rx="1.89474" ry="1.8781" fill="#F04438" />
      <Ellipse cx="15.6908" cy="8.62753" rx="2.66447" ry="2.64108" fill="#F04438" />
      <Ellipse cx="24.3356" cy="18.2528" rx="2.66447" ry="2.64108" fill="#F04438" />
      <Ellipse cx="20.7829" cy="12.6185" rx="2.42763" ry="2.40632" fill="#F04438" />
      <Ellipse cx="19.1251" cy="17.4312" rx="1.95395" ry="1.93679" fill="#F04438" />
      <Ellipse cx="25.4014" cy="13.9097" rx="1.59868" ry="1.58465" fill="#F04438" />
      <Ellipse cx="25.4014" cy="10.1535" rx="1.59868" ry="1.58465" fill="#F04438" />
      <Ellipse cx="9.65139" cy="6.5147" rx="1.59868" ry="1.58465" fill="#F04438" />
      <Ellipse cx="12.6711" cy="4.57789" rx="1.42105" ry="1.40858" fill="#F04438" />
      <Ellipse cx="12.908" cy="1.05643" rx="1.06579" ry="1.05643" fill="#F04438" />
      <Ellipse cx="11.3685" cy="10.2122" rx="1.06579" ry="1.05643" fill="#F04438" />
      <Ellipse cx="1.06585" cy="11.2686" rx="1.06579" ry="1.05643" fill="#F04438" />
      <Ellipse cx="6.9869" cy="24.1806" rx="1.06579" ry="1.05643" fill="#F04438" />
      <Ellipse cx="8.17111" cy="27.2325" rx="1.06579" ry="1.05643" fill="#F04438" />
      <Ellipse cx="6.3948" cy="31.1061" rx="1.06579" ry="1.05643" fill="#F04438" />
      <Ellipse cx="4.85534" cy="38.5011" rx="1.06579" ry="1.05643" fill="#F04438" />
      <Ellipse cx="14.0922" cy="42.2574" rx="1.06579" ry="1.05643" fill="#F04438" />
      <Ellipse cx="8.28955" cy="44.0181" rx="0.828947" ry="0.821669" fill="#F04438" />
      <Ellipse cx="22.3817" cy="33.9232" rx="0.828947" ry="0.821669" fill="#F04438" />
      <Ellipse cx="4.02637" cy="23.9458" rx="0.828947" ry="0.82167" fill="#F04438" />
      <Ellipse cx="1.89481" cy="7.86451" rx="1.42105" ry="1.40858" fill="#F04438" />
      <Ellipse cx="1.59875" cy="14.4966" rx="1.59868" ry="1.58465" fill="#F04438" />
      <Ellipse cx="4.7369" cy="27.7021" rx="1.65789" ry="1.64334" fill="#F04438" />
      <Ellipse cx="21.5527" cy="36.8578" rx="1.65789" ry="1.64334" fill="#F04438" />
      <Ellipse cx="4.7369" cy="42.8443" rx="1.65789" ry="1.64334" fill="#F04438" />
      <Ellipse cx="22.3224" cy="30.8127" rx="1.36184" ry="1.34989" fill="#F04438" />
      <Ellipse cx="21.7303" cy="40.9075" rx="1.36184" ry="1.34989" fill="#F04438" />
      <Ellipse cx="11.8422" cy="45.4267" rx="1.65789" ry="1.64334" fill="#F04438" />
      <Ellipse cx="11.8422" cy="49.7698" rx="1.65789" ry="1.64334" fill="#F04438" />
      <Ellipse cx="16.1645" cy="2.75847" rx="2.19079" ry="2.17156" fill="#F04438" />
      <Ellipse cx="9.65139" cy="2.17156" rx="2.19079" ry="2.17156" fill="#F04438" />
      <Ellipse cx="4.91455" cy="4.28447" rx="2.78289" ry="2.75846" fill="#F04438" />
      <Ellipse cx="6.51321" cy="48.009" rx="3.31579" ry="3.28668" fill="#F04438" />
      <Ellipse cx="6.45401" cy="11.2099" rx="3.375" ry="3.34537" fill="#F04438" />
    </Svg>
  );
};
