import { useState } from 'react';
import { HStack, IconButton, View } from 'native-base';
import { isEmpty } from 'lodash';

import { ButtonGroup, LightBulbIcon, Text } from '@pimm/base';
import { MainScreenProps } from '@app/navigations/root';
import { MainContainer } from '@app/components/core';
import { ScreenLayout } from '@app/components/layout';
import { ResourceLoader } from '@app/components/shared';
import { ModuleTitle } from '@app/features/app';
import { useSiteConfig } from '@app/features/store-core';
import { EventTypesProvider, useGetDisplayGroups, useGetEventTypes } from '@app/features/store-equipment';
import { ModalAppDocuments } from '@app/features/store-info/components';
import { StatusPanel } from './components/status-panel';
import { SummaryPanel } from './components/summary-panel';

type TemperatureScreenTabs = 'diagnostics' | 'performance' | 'status';

export type TemperaturesScreenProps = MainScreenProps<'Temperatures'>;

export const TemperaturesScreen: React.FC<TemperaturesScreenProps> = ({ route: { params, ...route } }) => {
  const { siteConfig } = useSiteConfig();
  const [tabFocus, setTabFocus] = useState<TemperatureScreenTabs>((params.tabFocus ?? 'status') as TemperatureScreenTabs);

  const displayGroups = useGetDisplayGroups(siteConfig.id);
  const eventTypes = useGetEventTypes(siteConfig.companyInfo?.brandId);

  const isNothingToDisplay = (displayGroups.isSuccess && isEmpty(displayGroups.data)) || displayGroups.isError;

  return (
    <MainContainer>
      <ScreenLayout
        title={
          <HStack>
            <ModuleTitle name={route.name} defaultText="Temperature" />
            <ModalAppDocuments
              identifierName="Module.Equipment"
              moduleType="Equipment"
              trigger={triggerProps => {
                if (!triggerProps.documents?.length) return undefined;
                return (
                  <IconButton
                    rounded="lg"
                    borderWidth={1}
                    borderColor="gray.300"
                    ml={2}
                    p={0}
                    w={9}
                    h={9}
                    icon={<LightBulbIcon size="md" />}
                    onPress={triggerProps.onPress}
                  />
                );
              }}
            />
          </HStack>
        }
        actionTool={
          <HStack justifyContent="flex-end">
            <ButtonGroup value={tabFocus} onChange={setTabFocus}>
              <ButtonGroup.Item value="status" minWidth={90}>
                Status
              </ButtonGroup.Item>
              <ButtonGroup.Item value="diagnostics" isDisabled={isNothingToDisplay}>
                Diagnostics
              </ButtonGroup.Item>
              <ButtonGroup.Item value="performance" isDisabled={isNothingToDisplay}>
                Performance
              </ButtonGroup.Item>
            </ButtonGroup>
          </HStack>
        }
      >
        <EventTypesProvider eventTypes={eventTypes}>
          <ResourceLoader
            h="full"
            isEmpty={isNothingToDisplay}
            isLoading={displayGroups.isIdle || displayGroups.isLoading}
            emptyMessage={
              <Text size="lg" fontWeight={600} color="gray.500" textAlign="center">
                Nothing to display.{`\n`}
                <Text size="md" fontWeight={400} color="gray.500">
                  This module is not setup correctly. Please contact your system administrator for more details.
                </Text>
              </Text>
            }
          >
            {tabFocus === 'status' ? (
              <StatusPanel displayGroups={displayGroups} instanceId={params.instanceId} />
            ) : (
              <SummaryPanel key={tabFocus} displayGroups={displayGroups} isPerformance={tabFocus === 'performance'} />
            )}
          </ResourceLoader>
        </EventTypesProvider>
      </ScreenLayout>
    </MainContainer>
  );
};
