import { useBoolean } from 'usehooks-ts';
import { Box, HStack, Pressable, VStack, View } from 'native-base';
import FeatherIcon from 'react-native-vector-icons/Feather';
import { isEmpty, last, map } from 'lodash';

import { useAppLocale } from '@pimm/common';
import { Collapse, Text } from '@pimm/base';
import { Category } from '@pimm/services/lib/sms-documents';
import { CategoryExtended } from './popover-category-selector';

type CollapsibleCategoriesProps = {
  hideToggle?: boolean;
  items: CategoryExtended[];
  selectedId?: string;
  title: string;
  onPressItem?: (category: Category) => void;
};

export const CollapsibleCategories = ({ hideToggle, items, selectedId, title, ...props }: CollapsibleCategoriesProps) => {
  const { translate } = useAppLocale();
  const expanded = useBoolean(true);

  const handlePressItem = item => () => {
    if (props.onPressItem) props.onPressItem(item);
  };

  return (
    <View w="full">
      <Pressable disabled w="full" mb={2} onPress={expanded.toggle}>
        <HStack alignItems="center" justifyContent="space-between">
          <Text size="lg" fontWeight={600} color="black">
            {title}
          </Text>
          {!hideToggle && !isEmpty(items) && <FeatherIcon name={expanded.value ? 'chevron-up' : 'chevron-down'} size={20} />}
        </HStack>
      </Pressable>
      {!isEmpty(items) && (
        <Collapse in={expanded.value}>
          <VStack borderWidth={1} borderRadius="xl" overflow="hidden">
            {map(items, item => (
              <Box key={item.id} borderBottomWidth={last(items) === item ? 0 : 1} alignItems="center">
                <Pressable
                  w="full"
                  h="full"
                  py={1}
                  px={4}
                  minHeight={{ md: 8, xl: 10 }}
                  justifyContent="center"
                  alignItems="center"
                  backgroundColor={selectedId === item.id ? 'gray.200' : undefined}
                  _hover={{ backgroundColor: selectedId === item.id ? 'gray.200' : 'gray.50' }}
                  onPress={handlePressItem(item)}
                >
                  <HStack justifyContent="space-between" w="full" alignItems="center">
                    <Text size="md" fontWeight={500} color="gray.900" lineHeight="xs">
                      {translate(item?.title, item?.translations)}
                    </Text>

                    <Box
                      alignItems="center"
                      justifyContent="center"
                      bgColor="white"
                      minW={6}
                      height={6}
                      rounded="md"
                      px={1}
                      borderWidth="1"
                      alignSelf="center"
                    >
                      <Text size="sm" color="black" fontWeight={500}>
                        {item.count ?? 0}
                      </Text>
                    </Box>
                  </HStack>
                </Pressable>
              </Box>
            ))}
          </VStack>
        </Collapse>
      )}
    </View>
  );
};
