import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import Constants from 'expo-constants';

import * as serviceWorkerRegistration from '../../service-worker-registration';

export type ServiceWorkerContextReturn = {
  availableVersion?: string;
  updateWorker: () => void;
};

export const ServiceWorkerContext = createContext<ServiceWorkerContextReturn>(undefined!);

export type ServiceWorkerProviderProps = {
  children: React.ReactNode;
};

export const ServiceWorkerProvider = ({ children }: ServiceWorkerProviderProps) => {
  const [availableVersion, setAvailableVersion] = useState<string>();

  const checkAvailableVersion = useCallback(async () => {
    const activeVersion = Constants.expoConfig?.version;
    // Check the next available version
    const release = await fetch('./release.json').then(res => res.json());
    console.log('New version is available: ', release?.version);
    // Compare previous and newly release version
    if (!release?.version || activeVersion !== release?.version) {
      setAvailableVersion(release?.version);
    }
  }, []);

  const updateWorker = useCallback(() => {
    window.location.reload();
  }, [availableVersion]);

  useEffect(() => {
    // Is not localhost. Just register service worker
    if (!__DEV__ && 'serviceWorker' in navigator) {
      serviceWorkerRegistration.register({
        onUpdate: checkAvailableVersion,
      });
      navigator.serviceWorker.ready.then(registration => {
        // there is a 1 day (24hr) built-in limit for the browser to look for updates to the service worker,
        // but what you need to be mindful of is you still need to call .update() on your
        // ServiceWorkerRegistration object otherwise the original version of the file will be used.
        registration.update();

        // repeat check every hour
        setInterval(() => registration.update(), 1000 * 60 * 5);
      });
    }
  }, []);

  return <ServiceWorkerContext.Provider value={{ availableVersion, updateWorker }}>{children}</ServiceWorkerContext.Provider>;
};

export const useServiceWorker = () => {
  // get the context
  const context = useContext(ServiceWorkerContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useServiceWorker was used outside of its Provider');
  }
  return context;
};
