import { Icon, IIconProps } from 'native-base';
import { Path } from 'react-native-svg';

export const ArrowUpBoxIcon = ({ size = 'sm', ...props }: IIconProps) => {
  return (
    <Icon viewBox="0 0 19 18" size={size} fill="none" {...props}>
      <Path
        d="M1.5 5.325C1.5 3.85486 1.5 3.11979 1.78611 2.55827C2.03778 2.06435 2.43935 1.66278 2.93327 1.41111C3.49479 1.125 4.22986 1.125 5.7 1.125H13.05C14.5201 1.125 15.2552 1.125 15.8167 1.41111C16.3106 1.66278 16.7122 2.06435 16.9639 2.55827C17.25 3.11979 17.25 3.85486 17.25 5.325V12.675C17.25 14.1451 17.25 14.8802 16.9639 15.4417C16.7122 15.9356 16.3106 16.3372 15.8167 16.5889C15.2552 16.875 14.5201 16.875 13.05 16.875H5.7C4.22986 16.875 3.49479 16.875 2.93327 16.5889C2.43935 16.3372 2.03778 15.9356 1.78611 15.4417C1.5 14.8802 1.5 14.1451 1.5 12.675V5.325Z"
        fill="#FEE4E2"
      />
      <Path d="M12.875 9L9.375 5.5L5.875 9" fill="#FEE4E2" />
      <Path
        d="M12.875 9L9.375 5.5M9.375 5.5L5.875 9M9.375 5.5V12.5M5.7 16.875H13.05C14.5201 16.875 15.2552 16.875 15.8167 16.5889C16.3106 16.3372 16.7122 15.9356 16.9639 15.4417C17.25 14.8802 17.25 14.1451 17.25 12.675V5.325C17.25 3.85486 17.25 3.11979 16.9639 2.55827C16.7122 2.06435 16.3106 1.66278 15.8167 1.41111C15.2552 1.125 14.5201 1.125 13.05 1.125H5.7C4.22986 1.125 3.49479 1.125 2.93327 1.41111C2.43935 1.66278 2.03778 2.06435 1.78611 2.55827C1.5 3.11979 1.5 3.85486 1.5 5.325V12.675C1.5 14.1451 1.5 14.8802 1.78611 15.4417C2.03778 15.9356 2.43935 16.3372 2.93327 16.5889C3.49479 16.875 4.22986 16.875 5.7 16.875Z"
        stroke="#F04438"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Icon>
  );
};
