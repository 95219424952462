import { useMemo, useState } from 'react';
import { find } from 'lodash';
import moment from 'moment';
import { DayBlock, useSiteTime } from './get-site-time.hook';
import { useSiteConfig } from '../context';

export const useDayBlockFocus = (
  defaultDate?: string | Date,
  defaultBlockNumber?: number,
): {
  selected?: DayBlock;
  all: DayBlock[];
  changeBlockNumber: (number: number) => void;
  changeDate: (date?: Date) => void;
} => {
  const { siteConfig } = useSiteConfig();
  const siteTime = useSiteTime();

  let date: Date | undefined = undefined;

  if (defaultDate instanceof Date) date = defaultDate;
  else if (typeof defaultDate === 'string') date = moment(defaultDate).toDate();
  if (!moment(date).isValid()) date = undefined;

  const [focus, setFocus] = useState<{ blockNumber?: number; date?: Date }>({
    blockNumber: defaultBlockNumber,
    date: date,
  });

  const memoize = useMemo(() => {
    const date = siteTime.today();
    const dayBlocks = siteTime.toDayBlocks(focus.date);
    const dayBlock = find<DayBlock>(dayBlocks, _ => {
      if (focus.blockNumber) return _.blockNumber === focus.blockNumber;
      return date >= _.startTime && date <= _.endTime;
    });

    return {
      dayBlocks: dayBlocks,
      selected: dayBlock ?? dayBlocks[0],
    };
  }, [focus, siteTime.utcOffsetInMinutes]);

  const changeBlockNumber = (blockNumber: number) => {
    setFocus(prev => ({ ...prev, blockNumber: blockNumber }));
  };

  const changeDate = (date?: Date) => {
    setFocus({ date: date });
  };

  return {
    selected: memoize.selected,
    all: memoize.dayBlocks,
    changeBlockNumber: changeBlockNumber,
    changeDate: changeDate,
  };
};
