// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA
type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

export function register(config?: Config) {
  navigator.serviceWorker
    .register('/sw.js')
    .then(registration => {
      if (registration.waiting && config && config.onUpdate) {
        config.onUpdate(registration);
      }

      registration.onupdatefound = () => {
        if (registration.installing) {
          // wait until the new Service worker is actually installed (ready to take over)
          registration.installing.addEventListener('statechange', event => {
            // "installing" - the install event has fired, but not yet complete
            // "installed"  - install complete
            // "activating" - the activate event has fired, but not yet complete
            // "activated"  - fully active
            // "redundant"  - discarded. Either failed install, or it's been
            //                replaced by a newer version
            if (registration.waiting) {
              // if there's an existing controller (previous Service Worker), show the prompt
              if (navigator.serviceWorker.controller) {
                // send the skip message to kick off the service worker install.
                registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                // add an listener to reload page when the new service worker is ready.
                registration.waiting.addEventListener('statechange', (event: Event) => {
                  const { state = '' } = (event.target as unknown as { state: string }) || {};
                  // Execute callback
                  if (state === 'activated' && config && config.onUpdate) {
                    config.onUpdate(registration);
                  }
                });
              } else {
                // otherwise it's the first install, nothing to do
                console.log('Service Worker initialized for the first time');
              }
            }
          });
        }
      };
    })
    .catch(error => {
      console.error('Error during service worker registration:', error);
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then(registration => {
        registration.unregister();
      })
      .catch(error => {
        console.error(error.message);
      });
  }
}
