import { ComponentProps } from 'react';
import { Box, HStack, IBoxProps } from 'native-base';

import { Text } from '@pimm/base';

type ListItemProps = ComponentProps<typeof HStack> & {
  _content?: IBoxProps;
  _title?: IBoxProps;
  text?: string;
};

export const ListItem = ({ _content, _title, children, text, ...props }: ListItemProps) => {
  return (
    <HStack justifyContent="space-between" {...props}>
      <Box pr={2} w="1/4" {..._title}>
        <Text fontWeight={600} size="md" color="gray.700">
          {text}
        </Text>
      </Box>
      <Box flex={1} {..._content}>
        {children}
      </Box>
    </HStack>
  );
};
