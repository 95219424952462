import { Icon, IIconProps } from 'native-base';
import { Path } from 'react-native-svg';

export const UnknownUser = ({ size = 22, ...props }: IIconProps) => {
  return (
    <Icon fill="none" size={size} viewBox="0 0 25 25" {...props}>
      <Path
        d="M12 16H8.5C6.32561 16 5.23841 16 4.38081 16.3552C3.23735 16.8289 2.32887 17.7373 1.85523 18.8808C1.5 19.7384 1.5 20.8256 1.5 23M14.9167 6.66667C14.9167 9.244 12.8273 11.3333 10.25 11.3333C7.67267 11.3333 5.58333 9.244 5.58333 6.66667C5.58333 4.08934 7.67267 2 10.25 2C12.8273 2 14.9167 4.08934 14.9167 6.66667Z"
        stroke="black"
        strokeWidth="2.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <Path
        d="M16.6667 13.6718C16.941 12.8921 17.4824 12.2346 18.195 11.8158C18.9076 11.397 19.7455 11.2439 20.5601 11.3836C21.3748 11.5234 22.1137 11.9469 22.646 12.5793C23.1783 13.2116 23.4696 14.0119 23.4684 14.8385C23.4684 17.1718 19.9684 18.3385 19.9684 18.3385M20.0617 23.0051H20.0734"
        stroke="black"
        strokeWidth="2.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Icon>
  );
};
