import React from 'react';
import { HStack, Pressable, VStack, View } from 'native-base';
import { ImageBackground, SafeAreaView } from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { Asset } from 'expo-asset';

import { Text } from '@pimm/base';
import { CreateATicketLink, HelpCenterLink } from '@app/consts/hyperlinks';

type WrapperProps = {
  children: React.ReactNode;
};

export const Wrapper = ({ children }: WrapperProps) => {
  const handleOpenBlankPage = (url: string) => () => {
    window.open(url, '_blank');
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <VStack w="full" h="full" bg="#ffba00">
        <ImageBackground
          resizeMode="repeat"
          style={{ height: '100%' }}
          source={{
            uri: Asset.fromModule(require('assets/pattern.png')).uri,
          }}
        >
          <HStack alignItems="center" justifyContent="space-between" w="full" px={5} height={60}>
            {/* Right Component */}
            <HStack flex={1} space={5} alignItems="center" justifyContent="flex-end" h="full">
              {/* Help Center */}
              <Pressable rounded="lg" onPress={handleOpenBlankPage(HelpCenterLink)}>
                {({ isHovered }) => (
                  <HStack space={2} rounded="lg" alignItems="center" py={1} px={3} minH={9} bg="white" shadow={isHovered ? 5 : 3}>
                    <Text size="lg" fontWeight={600} color="black" lineHeight="xs">
                      Help Center
                    </Text>
                    <MaterialCommunityIcons name="comment-question-outline" size={18} color="black" />
                  </HStack>
                )}
              </Pressable>
              {/* Create a Ticket */}
              <Pressable rounded="lg" onPress={handleOpenBlankPage(CreateATicketLink)}>
                {({ isHovered }) => (
                  <HStack space={2} rounded="lg" alignItems="center" py={1} px={3} minH={9} bg="white" shadow={isHovered ? 5 : 3}>
                    <Text size="lg" fontWeight={600} color="black" lineHeight="xs">
                      Create a Ticket
                    </Text>
                    <MaterialCommunityIcons name="ticket-confirmation-outline" size={18} color="black" />
                  </HStack>
                )}
              </Pressable>
            </HStack>
          </HStack>

          <View flex={1}>{children}</View>
        </ImageBackground>
      </VStack>
    </SafeAreaView>
  );
};
