import { Box, IBoxProps, VStack, useTheme } from 'native-base';

import { Text } from '@pimm/base';

export const PendingChangesCard = ({ children, ...props }: IBoxProps) => {
  const { colors } = useTheme();
  return (
    <Box
      rounded="xl"
      p={2}
      bg="warning.300"
      style={{
        shadowColor: colors.gray[500],
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.5,
        shadowRadius: 3,
      }}
      {...props}
    >
      <Box mb={2} px={1}>
        <Text size="xl" fontWeight={700} color="black" lineHeight="sm">
          Pending Changes
        </Text>
        <Text size="md" fontWeight={600} color="gray.800">
          Hit "Save" to commit.
        </Text>
      </Box>

      <VStack space={1} px={0.5}>
        {children}
      </VStack>
    </Box>
  );
};
