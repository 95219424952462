import { Box, HStack, IBoxProps, VStack } from 'native-base';

import { Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { DispenserResult } from '@pimm/services/lib/coke-freestyle';
import { dispenserName } from '../_helper';
import { BadgeCount } from './badge-count';
import { StatusText } from './status-text';

export type DispenserCardProps = {
  _container?: IBoxProps;
  active?: boolean;
  dispenser: DispenserResult;
};

export const DispenserCard = ({
  active = false,
  dispenser: { emptyCount = 0, lowCount = 0, unavailableCount = 0, ...dispenser },
  ...props
}: DispenserCardProps) => {
  const { translate } = useAppLocale();
  return (
    <Box py={1} px={2} rounded="md" backgroundColor={active ? 'white' : undefined} {...props._container}>
      <HStack justifyContent="space-between">
        <VStack justifyContent="center">
          <Text size="md" color="black" lineHeight="sm" fontWeight={700}>
            {/* TODO: Property 'translations' does not exist on type, waiting for service changes */}
            {translate(dispenser.displayName, dispenser ? dispenser['translations'] : undefined) || dispenserName(dispenser.type)}
          </Text>
          <HStack space={1} alignItems="center">
            <Text color="black" size="xs">
              S/N{' '}
              <Text color="black" size="xs" fontWeight={700}>
                {dispenser.serialNumber || ''}
              </Text>
            </Text>
            <StatusText lastUpdated={dispenser.lastUpdated} />
          </HStack>
        </VStack>
        <VStack space={0.5} ml={4} justifyContent="space-between" width={44}>
          <BadgeCount count={emptyCount} color="red" />
          <BadgeCount count={lowCount} color="yellow" />
        </VStack>
      </HStack>
    </Box>
  );
};
