import React from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { useTheme } from 'native-base';
import Constants from 'expo-constants';
import { isEmpty } from 'lodash';

import { pxToRem } from '@pimm/base';
import { StoreHoursConfigProvider, useAppLocale } from '@pimm/common';
import { packageBuilder } from '@app/components/core';
import { useAppSelector } from '@app/store/store';
import { selectStoreConfig, selectStoreEmployees } from '@app/store/selectors';
import { MainNavigationParams, RootScreenProps } from '@app/navigations/root';
import { MainNavigationModules } from '@app/navigations/main-navigation-modules';
import { AppModulesConsumer, AppModulesProvider, SessionTimeout } from '@app/features/app';
import { CalendarEventsProvider } from '@app/features/calendar';
import { GoalTypesProvider } from '@app/features/store-info';
import { KitchenLayoutProvider } from '@app/features/kitchen-positioning';
import { NotFoundScreen } from '@app/screens/not-found';
import { NewVersionAvailable } from '@app/components/shared';
import { MainDrawerContent } from './components/main-drawer-content';
import { ModalCokeFreestyle } from './components/modal-coke-freestyle';
import { ModalCustomerSurvey } from './components/modal-customer-survey';
import { ModalStoreConfig } from './components/modal-store-config';
import { ModalWidgets } from './components/modal-widgets';
import { ModalUserSettings } from './components/modal-user-settings';

export type MainScreenProps = RootScreenProps<'Main'> & {
  onChangeStore?: (_siteId: string) => void;
};

const Drawer = createDrawerNavigator<MainNavigationParams>();

const MainScreen: React.FC<MainScreenProps> = ({ navigation, route, ...props }) => {
  const { colors } = useTheme();
  const { translate } = useAppLocale();
  const storeConfig = useAppSelector(state => selectStoreConfig(state));
  const storeEmployees = useAppSelector(state => selectStoreEmployees(state));

  const navTitle = [storeConfig?.companyInfo?.customerName, storeConfig?.name].filter(Boolean).join(' | ');

  return (
    <React.Fragment>
      <AppModulesProvider brand={storeConfig?.companyInfo?.brand} moduleConfig={storeConfig?.config} screens={MainNavigationModules}>
        <StoreHoursConfigProvider
          siteId={storeConfig?.id}
          storeHoursConfig={storeConfig?.config?.storeHoursConfig}
          utcOffset={storeConfig?.address?.utcOffsetInMinutes}
        >
          <GoalTypesProvider siteId={storeConfig?.id}>
            <KitchenLayoutProvider kitchenLayoutId={storeConfig?.config?.kitchenLayoutId} siteId={storeConfig?.id}>
              <CalendarEventsProvider employees={storeEmployees}>
                <AppModulesConsumer>
                  {({ screens }) => (
                    <Drawer.Navigator
                      initialRouteName={isEmpty(screens) ? 'NotFound' : 'PositionGuide'}
                      detachInactiveScreens
                      drawerContent={props => <MainDrawerContent title={Constants.expoConfig?.name} {...props} />}
                      screenOptions={{
                        headerTitle: Constants.expoConfig?.name,
                        headerTintColor: 'gray.900',
                        drawerActiveBackgroundColor: 'gray.900',
                        drawerActiveTintColor: 'white',
                        drawerContentStyle: {
                          backgroundColor: colors.gray[900],
                        },
                        drawerContentContainerStyle: {
                          backgroundColor: colors.gray[900],
                        },
                        drawerInactiveTintColor: 'white',
                        drawerInactiveBackgroundColor: 'gray.900',
                        drawerType: 'front',
                        drawerLabelStyle: {
                          fontWeight: '600',
                          fontSize: pxToRem(16) as any,
                        },
                      }}
                    >
                      {screens.map(screen => {
                        if (!screen.component) return null;
                        const headerTitle = translate(screen.title, screen.titleTranslations);
                        return (
                          <Drawer.Screen
                            key={screen?.name}
                            name={screen!.name as keyof MainNavigationParams}
                            component={screen?.component as any}
                            initialParams={{ siteId: storeConfig?.id }}
                            options={{
                              headerTitle: headerTitle,
                              headerShown: false,
                              // force screen to be unmounted when navigating away from it, to reset the state
                              unmountOnBlur: true,
                              title: `${headerTitle} - ${navTitle}`,
                            }}
                          />
                        );
                      })}

                      <Drawer.Screen name="NotFound" component={NotFoundScreen} options={{ headerShown: false }} />
                    </Drawer.Navigator>
                  )}
                </AppModulesConsumer>

                {/* Shared Modal Views */}
                <ModalCokeFreestyle />
                <ModalCustomerSurvey />
                <ModalStoreConfig />
                <ModalWidgets />
                <ModalUserSettings />
              </CalendarEventsProvider>
            </KitchenLayoutProvider>
          </GoalTypesProvider>
        </StoreHoursConfigProvider>
      </AppModulesProvider>

      <SessionTimeout onPressReturn={() => navigation.navigate('Login')} />
      <NewVersionAvailable />
    </React.Fragment>
  );
};

export default packageBuilder(MainScreen);
