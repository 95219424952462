import { useQueryClient } from 'react-query';
import { ArrowBackIcon, ArrowForwardIcon, Box, HStack, Spacer, VStack } from 'native-base';
import FeatherIcons from 'react-native-vector-icons/Feather';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { Button, Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { AddUpdateWeeklyFocusRequest, AggGoalTypeDto, SmsWorkforceApi, WeeklyFocusDto } from '@pimm/services/lib/sms-workforce';
import { GetWeeklyFocus } from '@pimm/services/lib/sms-workforce/services';
import { formatTo, formatToISOString } from '@app/utils/date-formatter';
import { useDelayedState } from '@app/hooks/use-delayed-state.hook';
import { useGoalSettings } from '@app/features/ops-plan/context';
import { WeeklyFocusForm } from '@app/features/ops-plan';
import { DateTimePopover, ResourceLoader } from '@app/components/shared';
import { useGetWeeklyFocus } from '@app/features/ops-plan/hooks';
import { useSiteTime } from '@app/features/store-core';

type WeeklyPositionalGoalsProps = {
  goalTypes?: AggGoalTypeDto[];
  siteId: string;
  week: ReturnType<ReturnType<typeof useSiteTime>['toStartEndOfWeek']>;
  onChangeWeek: (startOfWeek: Date) => void;
  weeklyFocus: ReturnType<typeof useGetWeeklyFocus>[0];
};

export const WeeklyPositionalGoals = ({ goalTypes, siteId, week, weeklyFocus, ...props }: WeeklyPositionalGoalsProps) => {
  const queryClient = useQueryClient();
  const { translate } = useAppLocale();
  const siteTime = useSiteTime();
  const { weeklyFocusSettings } = useGoalSettings();

  const isEditable = week.endDate >= siteTime.today();
  const queryKey = [weeklyFocus.queryKey, weeklyFocus.queryParams];

  const delayedInputs = useDelayedState<AddUpdateWeeklyFocusRequest>({
    delay: 1000,
    onChange: async payload => {
      const result = await SmsWorkforceApi.AddUpdateWeeklyFocus(payload);
      if (result) {
        // re-update the query data to get the latest id for each focusItemInput
        queryClient.setQueryData<Awaited<ReturnType<typeof GetWeeklyFocus>>>(queryKey, result);
      }
    },
  });

  const handleSelectWeek = date => {
    props.onChangeWeek(date);
  };

  const handleChangeWeek = (days?: number) => () => {
    let startOfWeek = siteTime.toStartEndOfWeek()?.startDate;
    if (days) {
      startOfWeek = moment(week.startDate).add(days, 'days').toDate();
    }
    props.onChangeWeek(startOfWeek);
  };

  const handleFormChange = (inputs: Partial<WeeklyFocusDto>) => {
    const weeklyFocusSettingId = weeklyFocusSettings.data?.id;

    if (isEditable && weeklyFocusSettingId) {
      const prevData: WeeklyFocusDto = weeklyFocus.data?.id
        ? weeklyFocus.data
        : {
            focusItems: [],
            siteId: siteId,
            weeklyFocusSettingId: weeklyFocusSettingId,
            weekStartDate: formatToISOString(week.startDate, true),
          };
      const payload: WeeklyFocusDto = { ...prevData, ...inputs };
      // update the query data
      queryClient.setQueryData<Awaited<ReturnType<typeof GetWeeklyFocus>>>(queryKey, payload);
      // remember the changes, and await 1 second before calling the api
      delayedInputs.setValue(payload);
    }
  };

  return (
    <VStack h="full" w={{ md: 'full' }} maxWidth={{ xl: 720 }} borderRightColor="gray.200" borderRightWidth={1}>
      {!!weeklyFocusSettings.data && (
        <HStack alignItems="center" py={2} px={5} bg="white" borderBottomWidth={1}>
          <Box>
            <Text size="xl" fontWeight={700} lineHeight="xs" color="black" textTransform="uppercase">
              {translate(weeklyFocusSettings.data.title, weeklyFocusSettings.data.translations)}
            </Text>
            <Text size="md" fontWeight={500} lineHeight="xs" color="gray.700">
              {translate(weeklyFocusSettings.data.subTitle, weeklyFocusSettings.data.subTitleTranslations)}
            </Text>
          </Box>

          <Spacer />

          <HStack space={2} alignItems="center" justifyContent="space-between">
            <Button alternate outline w={10} onPress={handleChangeWeek(-7)}>
              <ArrowBackIcon size={4} color="gray.700" />
            </Button>
            <DateTimePopover
              selectedDate={week.startDate}
              mode="week"
              onChange={handleSelectWeek}
              max={siteTime.toStartEndOfWeek().endDate}
            >
              <Button alternate outline startIcon={<FeatherIcons name="calendar" size={18} color="gray.700" />}>
                {week ? `Wk ${week.weekNumber} (${formatTo(week.startDate, 'MMM DD')} - ${formatTo(week.endDate, 'MMM DD')})` : ''}
              </Button>
            </DateTimePopover>
            <Button disabled={isEditable} alternate outline w={10} onPress={handleChangeWeek(7)}>
              <ArrowForwardIcon size={4} color="gray.700" />
            </Button>

            <Button alternate outline ml={1} onPress={handleChangeWeek()}>
              Current Week
            </Button>
          </HStack>
        </HStack>
      )}

      <Box flex={1}>
        <ResourceLoader
          h="full"
          isEmpty={isEmpty(weeklyFocusSettings.data)}
          isLoading={weeklyFocusSettings.isIdle || weeklyFocusSettings.isLoading || weeklyFocus.isIdle || weeklyFocus.isLoading}
        >
          {!!weeklyFocusSettings.data && (
            <WeeklyFocusForm
              goalTypes={goalTypes}
              isEditable={isEditable}
              weeklyFocus={weeklyFocus.data}
              weeklyFocusSettings={weeklyFocusSettings.data}
              onChange={handleFormChange}
            />
          )}
        </ResourceLoader>
      </Box>
    </VStack>
  );
};
