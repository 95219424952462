import { useForm, UseFormReturn } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import deepmerge from 'deepmerge';
import { range } from 'lodash';

import { ContactCategoryEnum } from '@pimm/services/lib/store-management';

export type FormContactData = {
  name: string;
  company?: string;
  email: string;
  jobTitle?: string;
  phones: { value: string; label: string }[];
};

export const FormContactSchema = yup.object().shape({
  name: yup.string().required('Please provide a name'),
  email: yup.string().when('$category', {
    is: category => category === ContactCategoryEnum.EMERGENCY_OTHER,
    then: () =>
      yup
        .string()
        .email('Please provide a valid email')
        .required('Please provide an email'),
  }),
  phones: yup.array().test({
    name: 'must-have-phone',
    message: 'Please provide at least 1 phone number',
    test: (fields: any) => {
      return fields.filter(field => !!field.value).length > 0;
    },
  }),
});

export type FormContactReturn = UseFormReturn<FormContactData>;

export type FormContactProps = {
  context?: { category?: ContactCategoryEnum };
  defaultValues?: Partial<FormContactData>;
  info?: any;
};

export const useFormContact = (props?: FormContactProps): FormContactReturn => {
  const form = useForm<FormContactData>({
    context: props?.context,
    defaultValues: deepmerge.all([
      {
        name: '',
        email: '',
        phones: range(3).map(index => ({ value: '', label: '' })),
      },
      props?.defaultValues || {},
    ]),
    mode: 'onChange',
    resolver: yupResolver(FormContactSchema),
  });
  // const { emergencyContacts, storeContacts, toggleModal } = useStoreInfo();

  // const checkUpdate = (id, arr) => {
  //   return arr.findIndex(contact => contact.storeContactId === id);
  // };

  // const handleSubmit = async () => {
  //   // let result = null
  //   try {
  //     const result = await StoreManagementApi.CreateUpdateStoreContact(
  //       form.getValues(),
  //     );

  //     if (result) {
  //       const contactArr =
  //         result?.category === ContactCategoryEnum.EMERGENCY_MAIN
  //           ? emergencyContacts.data
  //           : storeContacts.data;
  //       const getIndex = checkUpdate(result.storeContactId, contactArr);

  //       if (getIndex !== -1) {
  //         // If an object with the same ID exists, update the existing object
  //         contactArr[getIndex] = result;
  //       } else {
  //         // If an object with the same ID doesn't exist, add the new object to the array
  //         contactArr.push(result);
  //       }
  //     }
  //     toggleModal();
  //     return result;
  //   } catch (error: any) {
  //     /* TODO: Display error */
  //   }
  //   return form.getValues();
  // };

  return form;
};
