import { useEffect, useState } from 'react';
import { CircleIcon, HStack } from 'native-base';

import { Text } from '@pimm/base';
import { DayBlock, useSiteTime } from '@app/features/store-core';
import { dateDiffToMinutes, formatTo, stringToDateLocal } from '@app/utils/date-formatter';

type TimeDisplayProps = {
  _container?: React.ComponentProps<typeof HStack>;
  _text?: React.ComponentProps<typeof Text>;
  dayBlock: DayBlock;
  startTime?: string;
  endTime?: string;
  keepAlive?: boolean;
};

export const TimeDisplay = ({ _container, _text, dayBlock, startTime, endTime, keepAlive }: TimeDisplayProps) => {
  const siteTime = useSiteTime();
  const [boxStyles, setBoxStyles] = useState<
    Partial<{
      bgColor: string;
      borderColor: string;
      iconColor: string;
    }>
  >();

  useEffect(() => {
    let timeoutRef;

    const updateStyling = (dayBlock, startTime?: string, endTime?: string, keepAlive?: boolean) => {
      const shiftStartTime = stringToDateLocal(startTime);
      const shiftEndTime = stringToDateLocal(endTime);

      if (!shiftStartTime || !shiftEndTime) return undefined;

      const timeNow = siteTime.today();
      const minsBeforeEndTime = 30;
      const style: typeof boxStyles = {
        iconColor: 'success.500',
      };

      if (shiftEndTime < dayBlock.endTime) {
        // When the persons Shift End time is before the active Dayblock end time
        style.iconColor = 'warning.500';
      }

      if (!keepAlive) {
        setBoxStyles(style);
        return;
      }

      const minutesLeft = dateDiffToMinutes(timeNow, shiftEndTime);

      // Once the employee has only 30 minutes left in their shift, it will change from grey to this yellow/orange color.
      if (minutesLeft <= minsBeforeEndTime) {
        style.borderColor = 'error.400';
        style.bgColor = 'error.100';
        style.iconColor = 'error.500';
      }

      setBoxStyles(style);

      if (minutesLeft > minsBeforeEndTime) {
        const beforeEndTime = new Date(shiftEndTime.getTime() - 60 * minsBeforeEndTime * 1000);
        // Recheck before endTime, to change the styling
        return setTimeout(() => updateStyling(startTime, endTime), beforeEndTime.getTime() - timeNow.getTime());
      }
    };

    if (startTime && endTime) {
      timeoutRef = updateStyling(dayBlock, startTime, endTime, keepAlive);
    }
    return () => clearInterval(timeoutRef);
  }, [dayBlock, keepAlive, startTime, endTime]);

  if (!startTime || !endTime) return null;

  return (
    <HStack
      rounded="md"
      space="1"
      alignItems="center"
      justifyContent="center"
      p={1}
      h="22px"
      borderWidth={1}
      borderColor={boxStyles?.borderColor ?? 'gray.300'}
      bgColor={boxStyles?.bgColor}
      {..._container}
    >
      <CircleIcon size={2} color={boxStyles?.iconColor ?? 'gray.300'} />
      <Text size="sm" fontWeight={500} color="gray.800" lineHeight="xs" {..._text}>
        {formatTo(stringToDateLocal(startTime)!, 'hh:mm A')}
        {' - '}
        {formatTo(stringToDateLocal(endTime)!, 'hh:mm A')}
      </Text>
    </HStack>
  );
};
