import React from 'react';

import { TabContainer } from '@app/features/app';
import { useDashboard } from '@app/features/dashboard';

export const MainContainer = ({ children }: { children?: React.ReactNode }) => {
  const { toggleModal } = useDashboard();

  const handlePressMenuItem = menuItem => {
    if (menuItem.payload?.modal?.type) {
      toggleModal({ type: menuItem.payload?.modal?.type });
    }
  };

  return (
    <React.Fragment>
      <TabContainer onPressMenuItem={handlePressMenuItem}>{children}</TabContainer>
    </React.Fragment>
  );
};
