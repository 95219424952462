import { useMutation } from 'react-query';
import { useBoolean } from 'usehooks-ts';
import { Box, Center, HStack, Image, Pressable, VStack, useTheme } from 'native-base';
import Constants from 'expo-constants';

import { Text } from '@pimm/base';
import { IdentityApi } from '@pimm/services/lib/access';
import { FormLogin, useFormLogin } from '@pimm/common';
import { ProcuroTextLogo } from '@app/consts/image-links';
import { RootScreenProps } from '@app/navigations/root';
import { Wrapper, useAuthToken } from '@app/features/app';
import { NewVersionAvailable } from '@app/components/shared';
import { BrandLogo } from './components/brand-logo';

export type LoginScreenProps = RootScreenProps<'Login'>;

export const LoginScreen = ({ navigation }: LoginScreenProps) => {
  const { colors } = useTheme();
  const { setAuthorization, user } = useAuthToken();
  const forgotPassword = useBoolean();
  const form = useFormLogin({
    defaultValues: {
      spid: user?.tenantId?.toLowerCase(),
      username: user?.username,
    },
  });
  const appTitle = Constants.expoConfig?.extra?.title;

  const authenticate = useMutation({
    mutationFn: IdentityApi.Authenticate,
    onSuccess: data => {
      setAuthorization(data);
      // @ts-ignore
      navigation.navigate('Main', { screen: 'PositionGuide' });
    },
    onError: (error: { code: number }) => {
      form.setError('root', {
        type: 'invalid_grant',
        message:
          error.code === 400
            ? 'You have entered an invalid username and/or password'
            : 'Sorry, something went wrong there. Please try again.',
      });
    },
  });

  const handleFormSubmit = () => {
    const { spid, username, password } = form.getValues();
    authenticate.mutate({
      username: `${spid}\\${username}`,
      password: password,
    });
  };

  return (
    <Wrapper>
      <VStack flex={1} alignItems="center" justifyContent="center" w="full" maxH={780}>
        <VStack space={5} w="full" maxW={420}>
          <Box rounded="2xl" bg="white" px={10} pt={7} pb={4} shadow={3}>
            <Center>
              <BrandLogo />
            </Center>

            {/* Login Form */}
            <FormLogin form={form} isLoading={authenticate.isLoading} onSubmit={handleFormSubmit} />

            <HStack justifyContent="center" textAlign="center" mt={4}>
              <Pressable onPress={() => navigation.replace('RecoverPassword')}>
                <Text size="lg" color="black" fontWeight={600}>
                  Forgot Password?
                </Text>
              </Pressable>
            </HStack>

            <Center mt={4}>
              <HStack space={1} alignItems="center" h={10}>
                <Text size="sm" fontWeight={600} color="black">
                  Powered by
                </Text>
                <Image
                  alt={ProcuroTextLogo.title}
                  source={{
                    uri: ProcuroTextLogo.sourceUri,
                  }}
                  resizeMethod="auto"
                  resizeMode="contain"
                  size="70px"
                />
              </HStack>
            </Center>
          </Box>
        </VStack>
      </VStack>

      <NewVersionAvailable />
    </Wrapper>
  );
};
