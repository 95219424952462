import { Dispatch, SetStateAction, useState } from 'react';
import { QueryKey, UseQueryResult, useQuery } from 'react-query';

import { GetGoalCategories } from '@pimm/services/lib/sms-positioning/services';
import { GetGoalCategoriesQueryParams } from '@pimm/services/lib/sms-positioning';

type GetGoalCategoriesParams = GetGoalCategoriesQueryParams | undefined

export const useGetGoalCategories = (props?: {
  queryKey?: string;
  query: GetGoalCategoriesParams;
}): [UseQueryResult<Awaited<ReturnType<typeof GetGoalCategories>>>, Dispatch<SetStateAction<GetGoalCategoriesParams>>] => {
  const [queryKey] = useState<string>(props?.queryKey ?? 'GetGoalCategories');
  const [query, setQuery] = useState<GetGoalCategoriesParams>(props?.query);

  const goalCategories = useQuery({
    enabled: !!query?.brandId || !!query?.customerId,
    queryKey: [queryKey, query] as [QueryKey, GetGoalCategoriesQueryParams],
    queryFn: ({ queryKey }) => GetGoalCategories(queryKey[1]),
  });

  return [goalCategories, setQuery];
};
