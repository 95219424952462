import { Box, HStack, useMediaQuery } from 'native-base';

import { useStoreHoursConfig } from '@pimm/common';
import { MainNavigationParams } from '@app/navigations/root';
import { KitchenLivePositions } from '@app/features/kitchen-positioning/components/kitchen-live-positions';
import { useKitchenPositionGuideLive } from '@app/features/kitchen-positioning';
import { KitchenGoals } from './kitchen-goals';
import { ResourceLoader } from '@app/components/shared';

type KitchenTilesProps = {
  storeHoursConfig: ReturnType<typeof useStoreHoursConfig>;
  tabFocus?: number;
  onNavigateTo: (screen: keyof MainNavigationParams, params?: any) => void;
};

export const KitchenTiles = ({ storeHoursConfig, tabFocus, ...props }: KitchenTilesProps) => {
  const livePositionGuide = useKitchenPositionGuideLive();
  const [isSmallScreen] = useMediaQuery({ maxWidth: 1200 });
  const handleNavigateTo = (screen: keyof MainNavigationParams) => () => {
    props.onNavigateTo(screen);
  };

  const handleEditPositions = (isNonService?: boolean) => {
    props.onNavigateTo('DailyPlan', { tabFocus: isNonService ? 'non-service' : undefined });
  };

  const handleEditFlowChart = () => {
    props.onNavigateTo('FlowChart');
  };
  return (
    <ResourceLoader h="full" isEmpty={livePositionGuide.isError} isLoading={livePositionGuide.isIdle || livePositionGuide.isLoading}>
      <HStack h="full" bg="gray.100">
        {(!isSmallScreen || tabFocus === 1) && (
          /* used this width condition because for md the screen is showing full size even if it is greater than 1200 up to 1400 */
          <Box width={isSmallScreen ? 'full' : 340} borderRightWidth={1} bgColor="gray.50">
            <KitchenGoals onEditGoals={handleNavigateTo('OpsPlan')} />
          </Box>
        )}
        {(!isSmallScreen || tabFocus === 0) && (
          <Box flex={1}>
            <KitchenLivePositions
              onEditPositions={handleEditPositions}
              onEditFlowChart={handleEditFlowChart}
              onNavigateTo={props.onNavigateTo}
            />
          </Box>
        )}
      </HStack>
    </ResourceLoader>
  );
};
