import { Modal, ModalProps } from '@pimm/base';
import { DayBlock } from '@pimm/common';
import { useAppSelector } from '@app/store/store';
import { selectStoreFocus } from '@app/store/selectors';
import { EmployeeSchedules } from '@app/features/employee-schedules';

type ModalEmployeeSchedulesProps = Pick<ModalProps, 'isOpen' | 'onClose'> & {
  dayBlock: DayBlock;
};

export const ModalEmployeeSchedules = ({ dayBlock, ...props }: ModalEmployeeSchedulesProps) => {
  const {
    data: { employees, store },
  } = useAppSelector(state => selectStoreFocus(state));

  return (
    <Modal _content={{ rounded: 'xl', maxWidth: '95%', height: '95%' }} size="full" noPadding {...props}>
      {!!store?.companyInfo?.customerId && !!store.id && (
        <EmployeeSchedules
          customerId={store?.companyInfo?.customerId}
          defaultDate={dayBlock.startTime}
          employees={employees}
          siteId={store.id}
          onCancel={props.onClose}
        />
      )}
    </Modal>
  );
};
