import * as Clipboard from 'expo-clipboard';
import { Box, HStack, Link, VStack, Toast, useTheme } from 'native-base';
import FeatherIcons from 'react-native-vector-icons/Feather';
import { chain, map } from 'lodash';
import moment from 'moment';

import { useAppLocale } from '@pimm/common';
import { ImageBackground, TouchableOpacity } from 'react-native';
import { Text } from '@pimm/base';
import { SiteDispenserResult } from '@pimm/services/lib/coke-freestyle';
import { StatusText } from './components/status-text';

type CokeDispensersProps = {
  siteDispenser: SiteDispenserResult;
};

export const CokeDispensers = ({ siteDispenser }: CokeDispensersProps) => {
  const { translate } = useAppLocale();
  const { colors } = useTheme();
  const groupedDispenserData = chain(siteDispenser.dispensers)
    .reduce((obj, item) => {
      const displayName = item?.displayName?.split('-').slice(0, -1).join('-');
      if (!displayName) return obj;
      return { ...obj, [displayName]: [...(obj[displayName] ?? []), item] };
    }, {})
    .map((value, key) => ({ sectionName: key, dispensers: value }))
    .value();

  const copyToClipboard = async text => {
    try {
      await Clipboard.setStringAsync(text);
      Toast.show({
        description: 'copied',
        duration: 3000,
        placement: 'bottom',
      });
    } catch (error) {
      console.error('Error copying text to clipboard:', error);
      alert('Unable to copy text to clipboard.');
    }
  };

  const handlePressNewTab = (url: string) => () => {
    window.open(url, '_blank', 'noreferrer');
  };

  return (
    <VStack py={4} px={6} minHeight={280} justifyContent="space-between">
      <HStack width={220} space={1} mb={5} p={1} backgroundColor="gray.100" rounded="sm">
        <VStack px={1}>
          <HStack space={1}>
            <Text size="md" color="black" fontWeight={600} lineHeight="sm">
              Store:
            </Text>
            <Text size="md" color="gray.900" fontWeight={400} lineHeight="sm">
              {siteDispenser.siteName ?? ''}
            </Text>
          </HStack>
          <HStack>
            <Text size="md" color="black" fontWeight={600} lineHeight="sm">
              Freestyle Outlet ID:{' '}
            </Text>
            <Text size="md" color="gray.900" fontWeight={400} lineHeight="sm">
              {siteDispenser.outletId ?? ''}
            </Text>
          </HStack>
        </VStack>
      </HStack>
      {map(groupedDispenserData, ({ sectionName, dispensers }) => (
        <VStack key={sectionName} space={2} mb={4}>
          <Text size="md" fontWeight={700} color="black" textTransform="uppercase">
            {sectionName}
          </Text>
          <HStack width="full" space={4}>
            {map(dispensers, ({ lastUpdated, ...dispenser }) => (
              <Box key={dispenser.dispenserId} height="160px" borderWidth={1} borderColor="gray.200" rounded="sm">
                <HStack h="full">
                  <Box h="full" w="140px" alignItems="center" backgroundColor="gray.100">
                    {!!dispenser.imageUrl && (
                      <ImageBackground
                        source={{
                          uri: dispenser.imageUrl,
                        }}
                        style={{ width: '65%', height: '100%', overflow: 'hidden' }}
                        imageStyle={{
                          position: 'absolute',
                          top: 5,
                          width: '100%',
                          resizeMode: 'cover', // Display the full image
                          height: 260, // Adjusting this height to play with zoom
                        }}
                      />
                    )}
                  </Box>
                  <VStack space={1} py={3} pl={4} pr={5}>
                    {/* Dispenser Name */}
                    <HStack space={1} mb={2}>
                      <Text size="md" color="black" fontWeight={600} lineHeight="sm">
                        Name:
                      </Text>
                      <Text size="md" color="black" fontWeight={500} lineHeight="sm">
                        {translate(dispenser?.displayName, dispenser?.translations) || ''}
                      </Text>
                      <TouchableOpacity>
                        <FeatherIcons name="edit" color="gray.300" size={14} />
                      </TouchableOpacity>
                    </HStack>

                    {/* Model */}
                    <HStack space={1}>
                      <Text size="sm" color="black" fontWeight={600} lineHeight="sm">
                        Model:
                      </Text>
                      <Text size="sm" color="gray.900" fontWeight={400} lineHeight="sm">
                        {dispenser.type ?? ''}
                      </Text>
                    </HStack>

                    {/* Serial Number */}
                    <HStack space={1}>
                      <Text size="sm" color="black" fontWeight={600} lineHeight="sm">
                        Serial Number:
                      </Text>
                      <Text size="sm" color="gray.900" fontWeight={400} lineHeight="sm">
                        {dispenser?.serialNumber ?? ''}
                      </Text>
                      <TouchableOpacity onPress={() => copyToClipboard(dispenser.serialNumber)}>
                        <FeatherIcons name="copy" color="gray.300" size={14} />
                      </TouchableOpacity>
                    </HStack>

                    {/* Status */}
                    <HStack space={1}>
                      <Text size="sm" color="black" fontWeight={600} lineHeight="sm">
                        Status:
                      </Text>
                      <StatusText lastUpdated={lastUpdated} />
                    </HStack>

                    {/* Last Update */}
                    <HStack space={1}>
                      <Text size="sm" color="black" fontWeight={600} lineHeight="sm">
                        Last Update:
                      </Text>
                      <Text size="sm" color="gray.900" fontWeight={400} lineHeight="sm">
                        {lastUpdated ? moment(lastUpdated).format('L hh:mm') : ''}
                      </Text>
                    </HStack>

                    {!!dispenser?.supportUrl && (
                      <Link mt={1} alignItems="center" onPress={handlePressNewTab(dispenser.supportUrl)}>
                        <Text size="sm" mr={1} color="error.500" fontWeight={600} underline>
                          Support{' '}
                        </Text>
                        <FeatherIcons name="external-link" color={colors.error[500]} size={14} />
                      </Link>
                    )}
                  </VStack>
                </HStack>
              </Box>
            ))}
          </HStack>
        </VStack>
      ))}
      <Box p={2} w="full" backgroundColor="red.600" rounded="sm">
        <Text size={{ md: 'md', xl: 'lg' }} color="white">
          Questions? Need Help? Contact a dedicated Coca-Cola Service Technician: In the U.S. 1-800-241-COKE (2653) | Canada 1-800-318-COKE
          (2653)
        </Text>
      </Box>
    </VStack>
  );
};
