import { Icon, IIconProps } from 'native-base';
import { G, Path } from 'react-native-svg';

export const DocumentIcon = ({ ...props }: IIconProps) => {
  return (
    <Icon viewBox="0 0 21 20" fill="none" {...props}>
      <G>
        <Path
          d="M3.53333 5.66666C3.53333 4.26653 3.53333 3.56647 3.80581 3.03169C4.04549 2.56128 4.42794 2.17883 4.89835 1.93915C5.43313 1.66666 6.13319 1.66666 7.53333 1.66666H11.8667L16.8667 6.66666V14.3333C16.8667 15.7335 16.8667 16.4335 16.5942 16.9683C16.3545 17.4387 15.972 17.8212 15.5016 18.0608C14.9669 18.3333 14.2668 18.3333 12.8667 18.3333H7.53333C6.13319 18.3333 5.43313 18.3333 4.89835 18.0608C4.42794 17.8212 4.04549 17.4387 3.80581 16.9683C3.53333 16.4335 3.53333 15.7335 3.53333 14.3333V5.66666Z"
          fill="white"
        />
        <Path d="M8.53333 7.5H6.86666Z" fill="white" />
        <Path
          d="M11.8667 1.89127V5.33339C11.8667 5.8001 11.8667 6.03346 11.9575 6.21172C12.0374 6.36852 12.1649 6.496 12.3217 6.5759C12.4999 6.66672 12.7333 6.66672 13.2 6.66672H16.6421M13.5333 10.8333H6.86666M13.5333 14.1667H6.86666M8.53333 7.5H6.86666M11.8667 1.66666H7.53333C6.13319 1.66666 5.43313 1.66666 4.89835 1.93915C4.42794 2.17883 4.04549 2.56128 3.80581 3.03169C3.53333 3.56647 3.53333 4.26653 3.53333 5.66666V14.3333C3.53333 15.7335 3.53333 16.4335 3.80581 16.9683C4.04549 17.4387 4.42794 17.8212 4.89835 18.0608C5.43313 18.3333 6.13319 18.3333 7.53333 18.3333H12.8667C14.2668 18.3333 14.9669 18.3333 15.5016 18.0608C15.972 17.8212 16.3545 17.4387 16.5942 16.9683C16.8667 16.4335 16.8667 15.7335 16.8667 14.3333V6.66666L11.8667 1.66666Z"
          stroke="currentColor"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </G>
    </Icon>
  );
};
