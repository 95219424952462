import { Icon, IIconProps } from 'native-base';
import { Path } from 'react-native-svg';

export const WaterIcon = ({ color = 'currentColor', size = 22, ...props }: IIconProps) => {
  return (
    <Icon fill="none" size={size} strokeWidth="0" viewBox="0 0 23 28" {...props}>
      <Path
        d="M11.5001 26.6667C13.9754 26.6667 16.3494 25.6833 18.0997 23.933C19.8501 22.1827 20.8334 19.8087 20.8334 17.3333C20.8334 14.6667 19.5001 12.1333 16.8334 10C14.1667 7.86668 12.1667 4.66668 11.5001 1.33334C10.8334 4.66668 8.83341 7.86668 6.16675 10C3.50008 12.1333 2.16675 14.6667 2.16675 17.3333C2.16675 19.8087 3.15008 22.1827 4.90042 23.933C6.65076 25.6833 9.02473 26.6667 11.5001 26.6667Z"
        stroke="#088AB2"
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Icon>
  );
};
