import { QueryKey, UseQueryResult, useQuery } from 'react-query';

import { formatToISOString } from '@app/utils/date-formatter';
import { GetChartData } from '@pimm/services/lib/store-equipment/services';

type GetChartDataParams = {
  siteId?: string;
  equipmentId?: string;
  startDate?: Date;
  endDate?: Date;
};

export const useGetChartData = (
  query?: GetChartDataParams,
  queryKey?: string,
): UseQueryResult<Awaited<ReturnType<typeof GetChartData>>> => {
  const chartData = useQuery({
    enabled: !!query?.siteId && !!query?.equipmentId && !!query?.startDate && !!query?.endDate,
    queryKey: [queryKey ?? 'GetChartData', query] as [QueryKey, GetChartDataParams],
    queryFn: ({ queryKey: [key, params] }) =>
      GetChartData(params.siteId!, {
        EquipmentId: params.equipmentId,
        StartTime: formatToISOString(params.startDate!),
        EndTime: formatToISOString(params.endDate!),
      }),
  });

  return chartData;
};
