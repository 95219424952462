import { PropsWithChildren, useState } from 'react';
import { createElement } from 'react-native';
import { HStack, IIconProps, Pressable, VStack } from 'native-base';

import { Text, RefreshIcon, ArrowSquareUpRightIcon } from '@pimm/base';
import { useAuthPermissions } from '@app/features/app';

type SensorSettingProps = PropsWithChildren & {
  onSelectSensorSetting?: (type?: SensorSettingType) => void;
};

export type SensorSettingType = 'Data Sync' | 'Enable System' | 'Troubleshoot' | 'Sensor' | 'Disable System';

export const SensorSetting = ({ onSelectSensorSetting, ...props }: SensorSettingProps) => {
  const permissions = useAuthPermissions(state => state.permissions);

  const [sensorSettings] = useState<{ icon: React.ComponentType; settingType: SensorSettingType; enabled?: boolean }[]>([
    { icon: RefreshIcon, settingType: 'Data Sync', enabled: permissions?.equipmentDevicemode?.write },
    { icon: ArrowSquareUpRightIcon, settingType: 'Troubleshoot' },
  ]);

  return (
    <VStack position="relative" flex={1} zIndex={3} rounded="md">
      {sensorSettings.map(item => (
        <Pressable
          key={item.settingType}
          disabled={!item?.enabled}
          _hover={{ bg: 'gray.100' }}
          _pressed={{ bgColor: 'primary.50' }}
          onPress={() => onSelectSensorSetting?.(item.settingType)}
        >
          <HStack alignItems="center" space={2} px={3} minHeight={9} w="full" opacity={item.enabled ? 1 : 0.5}>
            {createElement<IIconProps>(item.icon, { size: 'md', color: 'gray.700' })}
            <Text color="gray.700" size="md" fontWeight={500}>
              {item.settingType}
            </Text>
          </HStack>
        </Pressable>
      ))}
    </VStack>
  );
};
