import { useEffect, useState } from 'react';
import { Box, HStack, Spacer, VStack, Image, useMediaQuery } from 'native-base';
import { find } from 'lodash';

import { Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { KitchenWeeklyGoals, useKitchenPositionGuideLive } from '@app/features/kitchen-positioning';
import { GoalTypesSelection } from '@app/features/ops-plan';
import { useGoalTypes } from '@app/features/store-info';
import { AggGoalTypeDto } from '@pimm/services/lib/sms-workforce';

type KitchenGoalsProps = React.ComponentProps<typeof VStack> & {
  onEditGoals?: () => void;
};

export const KitchenGoals = (props: KitchenGoalsProps) => {
  const { translate } = useAppLocale();
  const goalTypes = useGoalTypes();
  const [isSmallScreen] = useMediaQuery({ maxWidth: 1200 });
  const livePositionGuide = useKitchenPositionGuideLive();
  const weeklyFocusSetting = livePositionGuide.data?.weeklyFocusSetting;
  const [osat, setOsat] = useState<AggGoalTypeDto>();

  useEffect(() => {
    setOsat(find(goalTypes.data, ['seq', 0]));
  }, [goalTypes.data]);

  return (
    <VStack h="full">
      {livePositionGuide.isSuccess && (
        <VStack space={1} pt={1} pb={{ md: 1, xl: 4 }} borderBottomWidth={1}>
          <HStack space={1} alignItems="center" px={3}>
            {!!weeklyFocusSetting?.headerImg && (
              <Image source={{ uri: weeklyFocusSetting?.headerImg }} style={{ width: 50, height: 50 }} resizeMode="contain" />
            )}

            <Text size={{ md: 'xl', xl: '2xl' }} fontWeight={700} color="black" lineHeight="xs" numberOfLines={2} ellipsizeMode="tail">
              {translate(weeklyFocusSetting?.title, weeklyFocusSetting?.translations)}
            </Text>

            <Spacer />

            <HStack space={2} alignItems="center" justifyContent="center" rounded="lg" py={2}>
              {!isSmallScreen && (
                <Text size="xl" fontWeight={700} color="blue.600" lineHeight="xs">
                  {`${translate(osat?.title, osat?.translations)} ${osat?.score ?? 0}%`}
                </Text>
              )}

              {isSmallScreen && (
                <GoalTypesSelection
                  _container={{ px: { md: 1, xl: 3 }, minW: { md: 420, xl: 'auto' } }}
                  goalTypes={goalTypes.data}
                  disabled
                  goals={weeklyFocusSetting?.goalTypeIds}
                />
              )}
            </HStack>
          </HStack>
          {!isSmallScreen && (
            <GoalTypesSelection
              _container={{ px: 3 }}
              goalTypes={goalTypes.data}
              disabled
              goals={weeklyFocusSetting?.goalTypeIds}
              hideOSAT
            />
          )}
        </VStack>
      )}

      <Box flex={1} px={1}>
        <KitchenWeeklyGoals categories={livePositionGuide.data?.weeklyFocusSetting?.categories} onEditGoals={props.onEditGoals} />
      </Box>
    </VStack>
  );
};
