import { ButtonGroup, FlagCircleSpainIcon, FlagCircleUSAIcon } from '@pimm/base';
import { Locale } from 'locale-enum';
import { includes } from 'lodash';

type CultureTabProps = {
  value?: string;
  languages?: string[];
  onChange?: (value: Locale) => void;
};

export const CultureTab = ({ value, languages = [], onChange }: CultureTabProps) => {
  const filteredLanguageCodes = languages.filter(locale => includes([Locale.en_US, Locale.en_ES, Locale.es_US], locale));

  return (
    <ButtonGroup value={value} onChange={onChange}>
      {filteredLanguageCodes.map(langCode => (
        <ButtonGroup.Item
          key={langCode}
          pl={1}
          minWidth={100}
          value={langCode}
          startIcon={langCode === Locale.en_US ? <FlagCircleUSAIcon /> : <FlagCircleSpainIcon />}
        >
          {langCode === Locale.en_US ? 'English' : 'Español'}
        </ButtonGroup.Item>
      ))}
    </ButtonGroup>
  );
};
