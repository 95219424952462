import { last, sum, values } from 'lodash';

import { Nullable } from '@pimm/base';
import { tempValue } from '@app/utils/string-formatter';
import { AlarmLevel as AlarmLevelEnum, ChartMetricDto, EventPriority, SensorSummaryDto } from '@pimm/services/lib/store-equipment';
import { FilterDisplay } from './components/filter-buttons';
import { SensorEquipment } from './context';

export const ThresholdColors = {
  Red: '#F04438',
  Yellow: '#F79009',
  Green: '#039855',
  Blue: '#2970FF',
} as const;

export const getSignalState = (alarmLevel?: AlarmLevelEnum): { color: string; text: string } => {
  switch (alarmLevel) {
    case AlarmLevelEnum.Alarm:
      return { color: 'error.500', text: 'Weak' };
    case AlarmLevelEnum.Warning:
      return { color: 'warning.400', text: 'Ok' };
    default:
      return { color: 'success.600', text: 'Strong' };
  }
};

export const getEventPriorityColor = (priority?: EventPriority, temperature?: boolean) => {
  switch (priority) {
    case EventPriority.L1:
      return temperature ? 'success.600' : 'yellow.600';
    case EventPriority.L2:
      return temperature ? 'yellow.400' : 'warning.500';
    case EventPriority.L3:
      return 'error.500';
    case EventPriority.L4:
      return 'black';
    default:
      return undefined;
  }
};

export function getSensorGroupBySensorName(value: string) {
  if (value.toLowerCase().includes('walk')) {
    return 'Walk-in';
  } else if (value.toLowerCase().includes('cooler')) {
    return 'Cooler';
  } else if (value.toLowerCase().includes('freezer')) {
    return 'Freezer';
  } else {
    return 'Other Temps';
  }
}

export function formatViolationTrigger(value: number, upper?: boolean) {
  return `${upper ? '>' : '<'}${tempValue(value)}`;
}

export function getThresholdOffset(value: number, min: number, max: number) {
  return (max - value) / (max - min);
}

export const parseTimeToSeconds = (time?: string): number => {
  if (!time || time === '00:00:00') return 0; // Handle null and '00:00:00'
  // Split the time string into hours, minutes, and seconds
  const [hours, minutes, seconds] = time.split(':').map(Number);
  // Convert to total seconds
  return (hours || 0) * 3600 + (minutes || 0) * 60 + (seconds || 0);
};

export const diagnosticsFilter = (filter: FilterDisplay, sensor?: SensorSummaryDto) => {
  if (filter === 'uptime') return (sensor?.UptimePct ?? 0) < 90;
  if (filter === 'data') return (sensor?.UploadPct ?? 0) < 90;
  if (filter === 'oos') return parseTimeToSeconds(sensor?.OosDuration) > 0;
  return true;
};

export const performanceFilter = (filter: FilterDisplay, equipment: SensorEquipment) => {
  if (filter === 'temp') return (equipment.performance?.InTempPercentage ?? 0) < 90;
  if (filter === 'temp-event') return sum(values(equipment.performance?.TemperatureFlagCounts).filter(Boolean)) > 0;
  if (filter === 'door-event') return sum(values(equipment.performance?.DoorFlagCounts).filter(Boolean)) > 0;
  return true;
};

export const parseDuration = (duration: string | undefined): string[] => {
  const timeRegex = /^(\d{1,2})(?:\.(\d{1,2}))?:([0-5]\d):([0-5]\d)$/;
  if (duration && timeRegex.test(duration)) {
    const match = duration.match(timeRegex);
    if (match) {
      const [, daysOrHours, hoursPart, minutes, seconds] = match;
      const days = hoursPart ? parseInt(daysOrHours, 10) : 0;
      const hours = hoursPart ? parseInt(hoursPart, 10) : parseInt(daysOrHours, 10);
      const formattedDays = days.toString().padStart(2, '0');
      const formattedHours = hours.toString().padStart(2, '0');
      return [formattedDays, formattedHours, minutes];
    }
  }
  return [];
};

export const createMetricsets = (_data?: ChartMetricDto[]) => {
  const metrics = _data ?? [];
  const _metrics: { startTime: Nullable<number>; endTime: Nullable<number>; value: number }[] = [];
  let currentEvent: { startTime: Nullable<number>; endTime: Nullable<number>; value: number } | undefined;

  const lastMetric = last(metrics);

  metrics?.forEach(item => {
    const timestamp = new Date(item.Timestamp as string).getTime();
    const value = item.Value ?? 0;

    // If this is the first item or value has changed, start a new event
    if (!currentEvent || value !== currentEvent.value) {
      // Close the previous event if it exists
      if (currentEvent) {
        // currentEvent.end = data[index - 1].timestamp;
        currentEvent.endTime = timestamp;
        _metrics.push(currentEvent);
      }

      // Start a new event
      currentEvent = {
        value: value,
        startTime: timestamp,
        endTime: null,
      };
    }
  });

  // Close the last event
  if (currentEvent && lastMetric) {
    currentEvent.endTime = new Date(lastMetric.Timestamp as string).getTime();
    _metrics.push(currentEvent);
  }

  return _metrics;
};
