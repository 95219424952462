import { Icon, IIconProps } from 'native-base';
import { Path } from 'react-native-svg';

export const CalendarDateIcon = ({
  color = '#3E1C96',
  size = 'sm',
  ...props
}: IIconProps) => {
  return (
    <Icon
      fill="none"
      size={size}
      strokeWidth="0"
      viewBox="0 0 15 16"
      {...props}
    >
      <Path
        d="M13.4287 6.66666H1.42865M10.0953 1.33333V3.99999M4.76198 1.33333V3.99999M6.42865 9.33333L7.42865 8.66666V12M6.59532 12H8.26198M4.62865 14.6667H10.2287C11.3488 14.6667 11.9088 14.6667 12.3366 14.4487C12.713 14.2569 13.0189 13.951 13.2107 13.5746C13.4287 13.1468 13.4287 12.5868 13.4287 11.4667V5.86666C13.4287 4.74656 13.4287 4.1865 13.2107 3.75868C13.0189 3.38236 12.713 3.0764 12.3366 2.88465C11.9088 2.66666 11.3488 2.66666 10.2287 2.66666H4.62865C3.50854 2.66666 2.94849 2.66666 2.52067 2.88465C2.14434 3.0764 1.83838 3.38236 1.64664 3.75868C1.42865 4.1865 1.42865 4.74656 1.42865 5.86666V11.4667C1.42865 12.5868 1.42865 13.1468 1.64664 13.5746C1.83838 13.951 2.14434 14.2569 2.52067 14.4487C2.94849 14.6667 3.50854 14.6667 4.62865 14.6667Z"
        stroke={color as string}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Icon>
  );
};

