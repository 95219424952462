import { useState } from 'react';
import { Box, HStack, IconButton, VStack, View } from 'native-base';
import { isEmpty } from 'lodash';

import { LightBulbIcon, SearchField } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { MainScreenProps } from '@app/navigations/root';
import { MainContainer } from '@app/components/core';
import { useSiteConfig } from '@app/features/store-core';
import { ModuleTitle } from '@app/features/app';
import { StoreDocumentsConsumer, StoreDocumentsProvider } from '@app/features/store-documents';
import { CultureTab } from '@app/components/shared';
import { DocumentListing } from './components/document-listing';
import { ModalAppDocuments } from '@app/features/store-info/components';

export type DocumentsScreenProps = MainScreenProps<'Temperatures'>;

export const DocumentsScreen: React.FC<DocumentsScreenProps> = ({ route }) => {
  const { locale } = useAppLocale();
  const { siteConfig } = useSiteConfig();
  const [searchValue, setSearchValue] = useState<string>('');

  return (
    <MainContainer>
      <StoreDocumentsProvider brandId={siteConfig.companyInfo?.brand?.id} defaultLocale={locale} marketId={siteConfig?.config?.marketId}>
        <VStack w="full" h="full">
          <HStack height={60} alignItems="center" justifyContent="space-between" borderWidth={1}>
            <Box flex={1} px={4} maxWidth="1/5">
              <HStack flex={1} alignItems="center">
                <ModuleTitle name={route.name} defaultText="Documents" />
                <ModalAppDocuments
                  identifierName="Module.Documents"
                  moduleType="Documents"
                  trigger={triggerProps => {
                    if (!triggerProps.documents?.length) return undefined;
                    return (
                      <IconButton
                        rounded="lg"
                        borderWidth={1}
                        borderColor="gray.300"
                        ml={2}
                        p={0}
                        w={9}
                        h={9}
                        icon={<LightBulbIcon size="md" />}
                        onPress={triggerProps.onPress}
                      />
                    );
                  }}
                />
              </HStack>
            </Box>
            <Box flex={1} px={4} maxWidth={{ md: 400, xl: '1/5' }} h="full" justifyContent="center" borderRightWidth={{ xl: 1 }}>
              <SearchField value={searchValue} onChangeText={setSearchValue} onClear={() => setSearchValue('')} />
            </Box>
            <StoreDocumentsConsumer>
              {({ cultureFocus, cultures, changeCulture }) => (
                <Box flex={{ xl: 1 }} px={4} h="full" justifyContent="center">
                  {!isEmpty(cultures) && (
                    <HStack flex="none">
                      <CultureTab languages={cultures} value={cultureFocus} onChange={changeCulture} />
                    </HStack>
                  )}
                </Box>
              )}
            </StoreDocumentsConsumer>
          </HStack>
          <View flex={1}>
            <DocumentListing filterBy={{ keyword: searchValue }} />
          </View>
        </VStack>
      </StoreDocumentsProvider>
    </MainContainer>
  );
};
