import { Text } from '@pimm/base';
import { Box } from 'native-base';

export const Chip = ({
  _container,
  colorScheme = 'primary',
  value,
  variant = 'solid',
  ...props
}: React.ComponentProps<typeof Text> &
  Pick<React.ComponentProps<typeof Box>, 'colorScheme'> & {
    _container?: React.ComponentProps<typeof Box>;
    value: string;
    variant?: 'solid' | 'outline';
  }) => {
  const outline = variant === 'outline';
  return (
    <Box
      alignItems="center"
      justifyContent="center"
      rounded="3xl"
      px={2.5}
      height={6}
      backgroundColor={outline ? `${colorScheme}.50` : `${colorScheme}.700`}
      {..._container}
    >
      <Text size="md" color={outline ? `${colorScheme}.700` : 'white'} fontWeight={500} {...props}>
        {value}
      </Text>
    </Box>
  );
};
