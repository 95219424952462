import { Box, HStack } from 'native-base';
import { first, map } from 'lodash';

import { Text } from '@pimm/base';
import { formatToTimeOnly, millisToMinutes } from '@app/utils/date-formatter';
import { TimelineLegend } from '../_helper';
import { KitchenEmployee } from '../types';

type EmployeeTimelineProps = {
  employee: KitchenEmployee;
  isUnassigned?: boolean;
};

export const EmployeeTimeline = ({ employee, isUnassigned }: EmployeeTimelineProps) => {
  const totalDuration = employee.durationInMinutes;

  let shiftStartTime = first(employee.timeslots)?.startTime;
  let shiftEndTime = first(employee.timeslots)?.endTime;

  if (employee.startTime && employee.endTime) {
    if (shiftStartTime && shiftEndTime) {
      shiftStartTime = new Date(Math.max(employee.startTime.getTime(), shiftStartTime.getTime()));
      shiftEndTime = new Date(Math.min(employee.endTime.getTime(), shiftEndTime.getTime()));
    }
    // For assigned, display the full shift time
    if (!isUnassigned) {
      shiftStartTime = employee.startTime;
      shiftEndTime = employee.endTime;
    }
  }

  return (
    <HStack space={2} alignItems="center" justifyContent="space-between" mt={2} w="full">
      <Text size="xs" fontWeight={500} color="gray.500" lineHeight="xs">
        {formatToTimeOnly(shiftStartTime)}
      </Text>

      <HStack position="relative" flex={1} height="6px" rounded="lg" bgColor="gray.500" overflow="hidden">
        {map(employee.timeslots, (timeslot, index) => {
          const milliStartTime = timeslot.startTime!.getTime();
          const offsetDuration = millisToMinutes(milliStartTime - employee.startTime!.getTime());
          const offset = (offsetDuration / totalDuration) * 100;
          const width = (timeslot.durationInMinutes / totalDuration) * 100;

          return (
            <Box
              key={milliStartTime}
              ml={`${index === 0 ? offset : 0}%`}
              w={`${width}%`}
              bg={timeslot.employeeId ? TimelineLegend.Assigned : TimelineLegend.Available}
            />
          );
        })}
      </HStack>

      <Text size="xs" fontWeight={500} color="gray.500" lineHeight="xs">
        {formatToTimeOnly(shiftEndTime)}
      </Text>
    </HStack>
  );
};
