import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useBoolean } from 'usehooks-ts';
import { Box, CheckIcon, HStack, IconButton, Pressable, VStack } from 'native-base';
import { find } from 'lodash';

import { FileAttachmentIcon, Modal, Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { AddUpdateCleaningTaskAssignment } from '@pimm/services/lib/sms-workforce/services';
import {
  AddUpdateTaskAssignmentRequest,
  AggAssignedTaskDto,
  CleaningScheduleTypeEnum,
  CleaningTaskStatusEnum,
} from '@pimm/services/lib/sms-workforce';
import { formatTo, formatToISOString, stringToDateLocal } from '@app/utils/date-formatter';
import { useSiteTime } from '@app/features/store-core';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { GetPositionGuideDataLiveKey, useGetPositionGuideDataLive } from '../hooks/get-position-guide-live.hook';
import { ConfirmIncompleteTask } from './confirm-incomplete-task';

type GetPositionGuideDataLiveReturn = ReturnType<typeof useGetPositionGuideDataLive>[0];

type CleaningTasksItemProps = {
  cleaningTask: AggAssignedTaskDto;
  employeeId?: string;
  scheduleType: CleaningScheduleTypeEnum;
  siteId?: string;
  onPressLinkDocument?: (task: AggAssignedTaskDto) => void;
};

export const CleaningTasksItem = ({ cleaningTask, employeeId, scheduleType, siteId, ...props }: CleaningTasksItemProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { translate } = useAppLocale();
  const siteTime = useSiteTime();
  const isCompleted = useBoolean(cleaningTask?.cleaningTaskStatus === CleaningTaskStatusEnum.Complete);
  const modalConfirm = useModalFocus();

  const addUpdateCleaningTask = useMutation({ mutationFn: AddUpdateCleaningTaskAssignment });

  const dueDate = stringToDateLocal(cleaningTask.dueDate);
  const endOfBlock = siteTime.toStartEndOfBlock(dueDate).endTime!;
  const endOfDay = siteTime.toStartEndOfBlock().endTime!;

  const isOverDue = endOfBlock < endOfDay;
  const statusColor = isOverDue && !isCompleted.value ? 'error.500' : 'black';

  const handlePressLinkedDocument = () => {
    if (props.onPressLinkDocument) props.onPressLinkDocument(cleaningTask);
  };

  const handleCompletion = () => {
    const cleaningTaskStatus = isCompleted.value ? CleaningTaskStatusEnum.Incomplete : CleaningTaskStatusEnum.Complete;
    const statusDateISO = formatToISOString(siteTime.today());

    modalConfirm.setHide();

    if (dueDate && siteId) {
      const payload: AddUpdateTaskAssignmentRequest = {
        id: cleaningTask.cleaningTaskAssignmentId,
        cleaningTaskId: cleaningTask.cleaningTaskId,
        cleaningTaskStatus: cleaningTaskStatus,
        dueDate: formatToISOString(dueDate),
        employeeId: employeeId,
        scheduleType: scheduleType,
        siteId: siteId,
        statusDate: statusDateISO,
      };
      addUpdateCleaningTask.mutate(payload);

      const queryCache = queryClient.getQueryCache();
      const queryPositionGuide = find(queryCache.getAll(), _ => _.queryKey[0] === GetPositionGuideDataLiveKey);
      // Let's update the cache return data of the GetPositionGuideLive
      if (queryPositionGuide) {
        queryClient.setQueryData<GetPositionGuideDataLiveReturn['data']>(
          queryPositionGuide.queryKey,
          (prevState: GetPositionGuideDataLiveReturn['data']) => {
            // Find and update current cleaning task assignment
            const updatedCleaningTasks = prevState?.cleaningTasks?.map(_ => {
              return {
                ..._,
                scheduleTypes: _.scheduleTypes?.map(scheduleType => {
                  return {
                    ...scheduleType,
                    assignedTasks: scheduleType?.assignedTasks?.map(task => {
                      if (
                        task.cleaningTaskId === cleaningTask.cleaningTaskId &&
                        task.cleaningTaskAssignmentId === cleaningTask.cleaningTaskAssignmentId
                      ) {
                        return { ...task, cleaningTaskStatus: cleaningTaskStatus, statusDate: statusDateISO };
                      }
                      return task;
                    }),
                  };
                }),
              };
            });

            return {
              ...prevState,
              cleaningTasks: updatedCleaningTasks,
            };
          },
        );
      }
    }
    isCompleted.toggle();
  };

  return (
    <HStack
      space={2}
      rounded="lg"
      px={2}
      py={1.5}
      alignItems="center"
      borderWidth={1}
      borderColor={isCompleted.value ? 'success.600' : 'gray.200'}
      bg={isCompleted.value ? 'success.50' : 'white'}
      minHeight="56px"
    >
      <Pressable
        w={7}
        h={7}
        alignItems="center"
        justifyContent="center"
        borderColor={isCompleted.value ? 'success.600' : 'gray.300'}
        borderWidth={1}
        bg="white"
        rounded="lg"
        _disabled={{ borderColor: 'gray.100' }}
        _hover={{ borderColor: isCompleted.value ? 'success.600' : 'gray.500' }}
        onPress={cleaningTask.cleaningTaskStatus === CleaningTaskStatusEnum.Complete ? modalConfirm.setOpen : handleCompletion}
      >
        {({ isHovered }) => {
          return (isHovered || isCompleted.value) && <CheckIcon size={6} color={isCompleted.value ? 'success.600' : 'gray.300'} />;
        }}
      </Pressable>

      <VStack space={0.5} flex={1} pl={1}>
        <HStack space={1} alignItems="flex-end">
          {/* <ConnectedDevicesIcon size={3} color="gray.500" /> */}
          <Text size="sm" fontWeight={600} color="gray.600" lineHeight="xs">
            {cleaningTask?.categoryTitle}
          </Text>
          {!!cleaningTask?.positionTitle && (
            <>
              <Text size="sm" fontWeight={600} color="gray.600" lineHeight="">
                \
              </Text>
              <Text size="sm" fontWeight={600} color="gray.600" lineHeight="xs" numberOfLines={1} ellipsizeMode="tail">
                {translate(cleaningTask?.positionTitle, cleaningTask.positionTitleTranslations)}
              </Text>
            </>
          )}
        </HStack>

        <Text size="md" fontWeight={600} color="black" lineHeight="xs" numberOfLines={1} ellipsizeMode="tail">
          {translate(cleaningTask?.title, cleaningTask.titleTranslations)}
        </Text>

        {isCompleted.value && !!cleaningTask.statusDate && (
          <Text size="md" fontWeight={500} color="black" lineHeight="xs" numberOfLines={1} ellipsizeMode="tail">
            {t('common:completed')}: {formatTo(cleaningTask.statusDate, 'ddd, MMM DD hh:mm A')}
          </Text>
        )}
      </VStack>

      <IconButton
        rounded="lg"
        p={1}
        w={6}
        h={6}
        color="primary.500"
        isDisabled={!cleaningTask.linkedDoc}
        _disabled={{ _icon: { color: 'gray.500' } }}
        _hover={{ bg: undefined }}
        icon={<FileAttachmentIcon size="sm" />}
        onPress={handlePressLinkedDocument}
      />

      <Box width={55}>
        {!!dueDate && (
          <Text size="sm" fontWeight={700} color={statusColor} lineHeight="sm">
            <Text size="md" fontWeight={700} color={statusColor} lineHeight="2xs">
              {formatTo(dueDate, 'ddd')}
            </Text>
            {`\n`}
            {formatTo(dueDate, 'MMM DD')}
            {`\n`}
            {scheduleType === CleaningScheduleTypeEnum.Daily && formatTo(dueDate, 'hh:mm A')}
          </Text>
        )}
      </Box>

      <Modal
        hideClose
        isOpen={modalConfirm.isOpen}
        onClose={modalConfirm.setHide}
        size="md"
        _content={{ rounded: 'xl', p: 5, pt: 4, maxW: 400 }}
      >
        <ConfirmIncompleteTask onClose={modalConfirm.setHide} onConfirm={handleCompletion} />
      </Modal>
    </HStack>
  );
};
