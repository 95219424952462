import React, { createContext, useContext, useEffect } from 'react';
import { useGetGoalTypes } from '../hooks/get-goal-types.hook';

type GoalTypesProviderProps = {
  children: React.ReactNode;
  siteId?: string;
};

const GoalTypesContext = createContext<ReturnType<typeof useGetGoalTypes>[0]>(undefined!);

export const GoalTypesProvider = ({ children, siteId }: GoalTypesProviderProps) => {
  const [goalTypes, setGoalTypesParams] = useGetGoalTypes();

  useEffect(() => {
    setGoalTypesParams({ siteId: siteId });
  }, [siteId]);

  return <GoalTypesContext.Provider value={goalTypes}>{children}</GoalTypesContext.Provider>;
};

export const GoalTypesConsumer = GoalTypesContext.Consumer;

export const useGoalTypes = () => {
  // get the context
  const context = useContext(GoalTypesContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useGoalTypes was used outside of its Provider');
  }
  return context;
};
