import { QueryKey, UseQueryResult, useQuery } from 'react-query';

import { GetHubStats } from '@pimm/services/lib/sms-workforce/services';
import { GetGoalsQueryParams } from '@pimm/services/lib/sms-positioning';

export const useGetHubStats = (siteId?: string, queryKey?: string): UseQueryResult<Awaited<ReturnType<typeof GetHubStats>>> => {
  const hubStats = useQuery({
    enabled: !!siteId,
    queryKey: [queryKey ?? 'GetHubStats', siteId] as [QueryKey, GetGoalsQueryParams],
    queryFn: () => GetHubStats({ siteId }),
  });

  return hubStats;
};
