import { Icon, IIconProps } from 'native-base';
import { G, Path } from 'react-native-svg';

export const AnnouncementIcon = (props: IIconProps) => {
  return (
    <Icon viewBox="0 0 20 20" fill="none" {...props}>
      <G>
        <Path
          d="M8.54162 4.58334H5.66663C4.26649 4.58334 3.56643 4.58334 3.03165 4.85582C2.56124 5.0955 2.17879 5.47795 1.93911 5.94836C1.66662 6.48314 1.66663 7.18321 1.66663 8.58334L1.66663 9.58334C1.66663 10.3599 1.66663 10.7482 1.7935 11.0545C1.96265 11.4629 2.28711 11.7873 2.69549 11.9565C3.00177 12.0833 3.39006 12.0833 4.16663 12.0833V15.625C4.16663 15.8185 4.16663 15.9152 4.17465 15.9967C4.25259 16.788 4.87864 17.414 5.66993 17.492C5.75142 17.5 5.84815 17.5 6.04163 17.5C6.2351 17.5 6.33184 17.5 6.41332 17.492C7.20462 17.414 7.83067 16.788 7.9086 15.9967C7.91663 15.9152 7.91663 15.8185 7.91663 15.625V12.0833H8.54163C10.0137 12.0833 11.8143 12.8724 13.2035 13.6297C14.014 14.0715 14.4192 14.2924 14.6846 14.2599C14.9307 14.2298 15.1168 14.1193 15.2611 13.9176C15.4166 13.7001 15.4166 13.265 15.4166 12.3948V4.27191C15.4166 3.40167 15.4166 2.96654 15.2611 2.74908C15.1168 2.54742 14.9307 2.43692 14.6846 2.40678C14.4192 2.37427 14.014 2.59517 13.2035 3.03696C11.8143 3.79426 10.0136 4.58334 8.54162 4.58334Z"
          fill="white"
        />
        <Path
          d="M18.3333 6.66667V10M8.54162 4.58334H5.66663C4.26649 4.58334 3.56643 4.58334 3.03165 4.85582C2.56124 5.0955 2.17879 5.47795 1.93911 5.94836C1.66662 6.48314 1.66663 7.18321 1.66663 8.58334L1.66663 9.58334C1.66663 10.3599 1.66663 10.7482 1.7935 11.0545C1.96265 11.4629 2.28711 11.7873 2.69549 11.9565C3.00177 12.0833 3.39006 12.0833 4.16663 12.0833V15.625C4.16663 15.8185 4.16663 15.9152 4.17465 15.9967C4.25259 16.788 4.87864 17.414 5.66993 17.492C5.75142 17.5 5.84815 17.5 6.04163 17.5C6.2351 17.5 6.33184 17.5 6.41332 17.492C7.20462 17.414 7.83067 16.788 7.9086 15.9967C7.91663 15.9152 7.91663 15.8185 7.91663 15.625V12.0833H8.54163C10.0137 12.0833 11.8143 12.8724 13.2035 13.6297C14.014 14.0715 14.4192 14.2924 14.6846 14.2599C14.9307 14.2298 15.1168 14.1193 15.2611 13.9176C15.4166 13.7001 15.4166 13.265 15.4166 12.3948V4.27191C15.4166 3.40167 15.4166 2.96654 15.2611 2.74908C15.1168 2.54742 14.9307 2.43692 14.6846 2.40678C14.4192 2.37427 14.014 2.59517 13.2035 3.03696C11.8143 3.79426 10.0136 4.58334 8.54162 4.58334Z"
          stroke="currentColor"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </G>
    </Icon>
  );
};
