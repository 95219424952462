import { Dispatch, SetStateAction, useState } from 'react';
import { QueryKey, UseQueryResult, useQuery } from 'react-query';

import { GetEmployees } from '@pimm/services/lib/sms-workforce/services';
import { Employee, GetEmployeesQueryParams } from '@pimm/services/lib/sms-workforce';

export const useGetEmployees = (props?: {
  queryKey?: string;
  query?: GetEmployeesQueryParams;
}): [UseQueryResult<Employee[]>, Dispatch<SetStateAction<GetEmployeesQueryParams | undefined>>] => {
  const [query, setQuery] = useState<GetEmployeesQueryParams>();

  const employees = useQuery({
    enabled: !!query?.siteId,
    queryKey: [props?.queryKey ?? 'GetEmployees', query] as [QueryKey, GetEmployeesQueryParams],
    queryFn: ({ queryKey }) => GetEmployees(queryKey[1]),
  });

  return [employees, setQuery];
};
