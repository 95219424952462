import { useMemo, useState } from 'react';
import { TouchableOpacity } from 'react-native';
import { useBoolean } from 'usehooks-ts';
import { Box, Divider, HStack, ScrollView, View, VStack } from 'native-base';
import { find, map } from 'lodash';
import moment from 'moment';

import { Modal, Text } from '@pimm/base';
import { HolderStatusEnum } from '@pimm/services/lib/coke-freestyle';
import { useGetSiteDispensers } from './hooks';
import HolderCard from './components/holder-card';
import OtherCard from './components/other-card';
import AttentionTabs, { AttentionType } from './components/attention-tabs';
import { DispenserCard } from './components/dispenser-card';
import { CokeDispensers } from './coke-dispensers';
import { CokeFreestyleLogo } from './assets';
import { distinctHolders } from './_helper';

interface DispenserGridConfig {
  columns: number;
}

export interface CokeFreestyleProps {
  config?: DispenserGridConfig;
  dispenserId?: string;
  siteDispenser: ReturnType<typeof useGetSiteDispensers>;
}

export const CokeFreestyle = ({
  config = {
    columns: 12,
  },
  siteDispenser,
  ...props
}: CokeFreestyleProps) => {
  const modalIsOpen = useBoolean();
  const [gridLayout, setGridLayout] = useState<{
    width: number;
    height: number;
  }>();
  const [dispenserId, setDispenserId] = useState<string | undefined>(props.dispenserId);
  const [tabFocus, setTabFocus] = useState<AttentionType>('all');
  // group and create partition
  const dispenser = useMemo(() => {
    if (!!siteDispenser.data) {
      const dispensers = siteDispenser.data[0]?.dispensers ?? [];
      const dispenser = dispenserId ? find(dispensers, ['dispenserId', dispenserId]) : dispensers[0];

      if (dispenser) {
        const { holders = [], lastUpdated } = dispenser;
        const { chunks, partition } = distinctHolders(holders);

        return {
          chunks,
          dispenserId: dispenser.dispenserId,
          partition,
          lowLevels: dispenser.lowCount ?? 0,
          requireActions: dispenser.emptyCount ?? 0,
          lastUpdated: lastUpdated ? moment(lastUpdated).format('L hh:mm a') : undefined,
        };
      }
    }
    return undefined;
  }, [dispenserId, siteDispenser?.data]);

  const handleLayoutChange = ({ nativeEvent: { layout } }) => {
    setGridLayout({
      width: layout.width ?? 0,
      height: layout.height ?? 0,
    });
  };

  const handleChangeDispenser = (dispenserId: string) => {
    setDispenserId(dispenserId);
    setTabFocus('all');
  };

  return (
    <>
      <VStack space={2} h="full">
        <HStack pt={2.5} pl={3.5} pr={12} w="full" alignItems="center" justifyContent="space-between">
          <HStack w="auto" space={2} p={1} backgroundColor="gray.100" rounded="md">
            {siteDispenser.data?.map(site =>
              site.dispensers?.map(item => (
                <TouchableOpacity key={item.dispenserId} onPress={() => handleChangeDispenser(item.dispenserId!)}>
                  <DispenserCard active={item.dispenserId === dispenser?.dispenserId} dispenser={item} />
                </TouchableOpacity>
              )),
            )}
          </HStack>

          <TouchableOpacity onPress={modalIsOpen.setTrue}>
            <HStack space={1.5} alignItems="flex-end" rounded="lg" py={1.5} px={2.5} bgColor="error.600">
              <CokeFreestyleLogo />
              <Text size="lg" fontWeight={600} color="white" mb={1} lineHeight="sm" textTransform="uppercase">
                Info
              </Text>
            </HStack>
          </TouchableOpacity>
        </HStack>

        <Divider w="full" backgroundColor="gray.200" />

        <VStack flex={1}>
          <View h="full" w="full" minHeight={500} justifyContent="center" onLayout={handleLayoutChange}>
            {!!gridLayout && dispenser ? (
              <View h="full" w="full">
                <HStack alignItems="center" justifyContent="space-between" px={3}>
                  <HStack flex={1} justifyContent="stretch">
                    {dispenser.chunks[1]?.map(holder => (
                      <OtherCard key={holder.holderId} p={{ md: 0.5, xl: 1 }} width={gridLayout.width / config.columns} holder={holder} />
                    ))}
                  </HStack>

                  <Box alignItems="flex-end" width="1/2" maxWidth={520}>
                    <AttentionTabs
                      w="full"
                      activeTab={tabFocus}
                      lowLevels={dispenser?.lowLevels || 0}
                      requireActions={dispenser?.requireActions || 0}
                      onChangeTab={value => setTabFocus(value as AttentionType)}
                    />
                    {dispenser.lastUpdated ? (
                      <Text mt={1} size="sm" fontWeight={700} color="gray.900">
                        Last Update:{' '}
                        <Text size="sm" fontWeight={400} color="gray.500" ml={1.5}>
                          {dispenser.lastUpdated}
                        </Text>
                      </Text>
                    ) : null}
                  </Box>
                </HStack>

                <ScrollView flex={1} w="full" p={3} scrollEnabled>
                  <VStack>
                    {map(dispenser.partition, (holders, index) => (
                      <HStack key={index} flex={1} justifyContent="stretch">
                        {holders.map(holder => {
                          const span = holder.doubleCartridge ? 2 : 1;
                          let highlighted = true;

                          if (tabFocus !== 'all') {
                            highlighted =
                              (tabFocus === 'action_required' &&
                                holder.holderStatus !== HolderStatusEnum.Low &&
                                holder.holderStatus !== HolderStatusEnum.Normal) ||
                              (tabFocus === 'low_levels' && holder.holderStatus === HolderStatusEnum.Low);
                          }

                          return (
                            <HolderCard
                              key={holder.holderId}
                              p={{ md: 0.5, xl: 1 }}
                              width={((gridLayout.width - 8 * 3) / config.columns) * span}
                              opacity={highlighted ? 1.0 : 0.1}
                              holder={holder}
                            />
                          );
                        })}
                      </HStack>
                    ))}
                  </VStack>
                </ScrollView>
              </View>
            ) : null}
          </View>
        </VStack>
      </VStack>

      {!!siteDispenser.data && (
        <Modal
          size="full"
          noPadding
          _content={{ maxWidth: { md: '95%', xl: '80%' } }}
          isOpen={modalIsOpen.value}
          onClose={modalIsOpen.setFalse}
        >
          <CokeDispensers siteDispenser={siteDispenser.data[0]} />
        </Modal>
      )}
    </>
  );
};
