import { useMemo } from 'react';
import { Box, FlatList } from 'native-base';
import { filter, map } from 'lodash';

import { WeeklyFocusCategoryDto } from '@pimm/services/lib/sms-positioning';
import { WeeklyFocusItemDto } from '@pimm/services/lib/sms-workforce';
import { WeeklyFocusItemBox } from './weekly-focus-item-box';

type WeeklyFocusItemListProps = Pick<
  React.ComponentProps<typeof WeeklyFocusItemBox>,
  'isEditable' | 'isReadOnly' | 'onPressAddUpdate' | 'onPressRemove'
> & {
  categories?: WeeklyFocusCategoryDto[];
  focusItems?: WeeklyFocusItemDto[];
  goalLimitPerCategory?: number;
};

export const WeeklyFocusItemList = ({
  categories,
  focusItems,
  goalLimitPerCategory,
  isEditable,
  isReadOnly,
  ...props
}: WeeklyFocusItemListProps) => {
  const entries = useMemo(() => {
    const result = map(categories, category => {
      const items = filter(focusItems, ['weeklyFocusCategoryId', category.id]);
      return {
        category: category,
        items: items,
      };
    });
    return result;
  }, [focusItems, categories]);

  return (
    <FlatList
      py={3}
      px={5}
      w="full"
      data={entries}
      scrollEnabled
      ItemSeparatorComponent={() => <Box h={3} />}
      keyExtractor={item => item.category.id!.toString()}
      renderItem={({ item }) => {
        return (
          <WeeklyFocusItemBox
            category={item.category}
            goalLimit={goalLimitPerCategory}
            items={item.items}
            isEditable={isEditable}
            isReadOnly={isReadOnly}
            onPressAddUpdate={props.onPressAddUpdate}
            onPressRemove={props.onPressRemove}
          />
        );
      }}
    />
  );
};
