import { Box, IBoxProps, Pressable, Spinner, VStack } from 'native-base';
import { isEmpty } from 'lodash';

import { Text } from '@pimm/base';
import { ComponentProps } from 'react';

export type WidgetBoxProps = ComponentProps<typeof Pressable> & {
  isLoading?: boolean;
  noPadding?: boolean;
  title?: string;
};

export const WidgetBox = ({ children, isLoading, noPadding = false, title, ...props }: WidgetBoxProps) => {
  return (
    <Pressable rounded="lg" p={noPadding ? 0 : { md: 1.5, xl: 2 }} minHeight={120} bgColor="white" {...props}>
      <VStack position="relative">
        {isLoading === true && <Spinner position="absolute" top={1.5} right={2} size={14} color="gray.500" />}
        {!isEmpty(title) && (
          <Text
            size="sm"
            mb={2}
            fontWeight={700}
            color="gray.600"
            lineHeight="md"
            textTransform="uppercase"
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {title}
          </Text>
        )}
        <Box flex={1}>{children}</Box>
      </VStack>
    </Pressable>
  );
};
