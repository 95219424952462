import { Modal } from '@pimm/base';
import { DashboardModalType, useDashboard } from '@app/features/dashboard';
import { CustomerSatisfactorySurvey } from '@app/features/store-info/components';

export const ModalCustomerSurvey = () => {
  const { modal, toggleModal } = useDashboard();
  return (
    <Modal
      _content={{ rounded: '2xl', px: 4, maxWidth: 846 }}
      size="full"
      isOpen={modal?.type === DashboardModalType.CustomerSatisfactionSurvey}
      onClose={toggleModal}
    >
      <CustomerSatisfactorySurvey />
    </Modal>
  );
};
