import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Box, HStack, IconButton, Popover, Spinner, useTheme, View, VStack } from 'native-base';
import { useBoolean } from 'usehooks-ts';
import { NavigationProp, useNavigation } from '@react-navigation/native';

import { ButtonGroup, Modal } from '@pimm/base';
import { SetDeviceMode } from '@pimm/services/lib/store-equipment/services';
import { DeviceMode, RollupDateRange } from '@pimm/services/lib/store-equipment';
import { ResourceLoader } from '@app/components/shared';
import {
  DiagnosticsSensorView,
  DiagnosticsStoreView,
  EquipmentSummaryProvider,
  PerformanceSensorsView,
  PerformanceStoreView,
  RollupDaysButtons,
  useGetDisplayGroups,
  useGetEquipmentSummary,
  useGetHardwareConfig,
} from '@app/features/store-equipment';
import { useSiteConfig } from '@app/features/store-core';
import { FilterButtons, FilterDisplay } from '@app/features/store-equipment/components/filter-buttons';
import { SensorSetting, SensorSettingType } from '@app/features/store-equipment/components/sensor-setting';
import { Tool01Icon } from '@app/features/store-equipment/icons/tool-01';
import { MainNavigationParams } from '@app/navigations/root';

type TabDisplay = 'store' | 'sensor';
type SummaryPanelProps = {
  displayGroups: ReturnType<typeof useGetDisplayGroups>;
  isPerformance?: boolean;
};

export const SummaryPanel = ({ displayGroups, isPerformance }: SummaryPanelProps) => {
  const { colors } = useTheme();
  const navigation = useNavigation<NavigationProp<MainNavigationParams, 'Temperatures'>>();
  const { siteConfig } = useSiteConfig();
  const [rollupDays, setRollupDays] = useState<RollupDateRange>(RollupDateRange.SevenDay);
  const [tabFocus, setTabFocus] = useState<TabDisplay>('sensor');
  const [tabFilter, setFilter] = useState<FilterDisplay>('all');
  const isPopOverOpen = useBoolean(false);
  
  const queryHardwareConfig = useGetHardwareConfig(siteConfig?.id);
  const queryEquipmentSummary = useGetEquipmentSummary({ siteId: siteConfig?.id, range: rollupDays });

  const syncData = useMutation((params: { siteId: string; mode: DeviceMode }) => SetDeviceMode(params.siteId, { Mode: params.mode }), {
    onSuccess: () => {
      queryHardwareConfig.refetch();
      queryEquipmentSummary.refetch();
    },
    onError: error => {
      console.error('Failed to set device mode:', error);
    },
  });

  const handlePopoverOpen = () => {
    isPopOverOpen.setTrue();
  };

  // Function to handle popover close
  const onSelectSensorSettingCallback = (type?: SensorSettingType) => {
    isPopOverOpen.setFalse();
    if (siteConfig?.id && type === 'Data Sync') {
      syncData.mutate({ siteId: siteConfig?.id, mode: DeviceMode.Locked60 });
    }
  };

  useEffect(() => {
    // Remember browsed history
    navigation.setParams({
      instanceId: undefined,
      tabFocus: isPerformance ? 'performance' : 'diagnostics',
    });
  }, [tabFocus]);

  return (
    <EquipmentSummaryProvider displayGroups={displayGroups} equipmentSummary={queryEquipmentSummary} hardwareSummary={queryHardwareConfig}>
      <VStack h="full" w="full">
        <HStack py={2} px={3} borderBottomWidth={1}>
          <HStack flex={1}>
            <RollupDaysButtons value={rollupDays} onChange={setRollupDays} />
          </HStack>

          <ButtonGroup value={tabFocus} onChange={setTabFocus}>
            <ButtonGroup.Item value="sensor" minWidth={70}>
              Sensor View
            </ButtonGroup.Item>
            <ButtonGroup.Item value="store" minWidth={70}>
              Table View
            </ButtonGroup.Item>
          </ButtonGroup>

          <HStack flex={1} justifyContent="flex-end" alignItems="center" space={3}>
            <FilterButtons isPerformance={isPerformance ?? false} value={tabFilter} onChange={setFilter} />

            <Popover
              isKeyboardDismissable
              isOpen={isPopOverOpen.value}
              onClose={onSelectSensorSettingCallback}
              onOpen={handlePopoverOpen}
              placement="bottom left"
              trigger={triggerProps => (
                <IconButton
                  rounded="lg"
                  p={0}
                  boxSize={10}
                  disabled={syncData.isLoading}
                  borderWidth={1}
                  borderColor="gray.300"
                  _hover={{ borderColor: 'primary.600', _icon: { color: colors.primary[600] } }}
                  _pressed={{ borderColor: 'primary.600', _icon: { color: colors.primary[600] } }}
                  icon={syncData.isLoading ? <Spinner size="sm" variant="primary" /> : <Tool01Icon size="md" color={colors.gray[700]} />}
                  {...triggerProps}
                />
              )}
            >
              <Popover.Content accessibilityLabel="Sensor Setting" shadow="2" mr={3} rounded="lg" borderWidth={0}>
                <Popover.Arrow zIndex={0} borderWidth={0} bg="white" />
                <Popover.Body py={0.5} px={0} w={220} justifyContent="center" borderWidth={0} bg="white">
                  <SensorSetting onSelectSensorSetting={onSelectSensorSettingCallback} />
                </Popover.Body>
              </Popover.Content>
            </Popover>
          </HStack>
        </HStack>

        <View flex={1}>
          <ResourceLoader
            h="full"
            isLoading={queryEquipmentSummary.isIdle || queryEquipmentSummary.isLoading || queryHardwareConfig.isLoading}
          >
            {isPerformance ? (
              <>
                {tabFocus === 'store' ? (
                  <PerformanceStoreView filter={tabFilter} />
                ) : (
                  <PerformanceSensorsView filter={tabFilter} rollupDays={rollupDays} />
                )}
              </>
            ) : (
              <>
                {tabFocus === 'store' ? (
                  <DiagnosticsStoreView filter={tabFilter} />
                ) : (
                  <DiagnosticsSensorView filter={tabFilter} hardwareConfig={queryHardwareConfig} rollupDays={rollupDays} />
                )}
              </>
            )}
          </ResourceLoader>
        </View>
      </VStack>
    </EquipmentSummaryProvider>
  );
};
