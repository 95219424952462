import moment from 'moment';
import { startOfWeek } from '@app/utils/date-formatter';

export const CalendarEventColor = {
  Anniversary: 'purple',
  Birthday: 'blue',
} as const;

export function isSameMonthDate(prev: Date, next?: Date): boolean {
  if (!next) return false;
  return prev.getMonth() === next.getMonth() && prev.getDate() === next.getDate();
}

export function calendarDateSpan(
  date: Date,
  firstDayOfWeek?: number,
  weekOnly?: boolean,
): {
  numOfDays: number;
  startOfDate: Date;
  endOfDate: Date;
} {
  let startOfDate: Date | undefined;
  let endOfDate: ReturnType<typeof moment> | undefined;

  if (weekOnly) {
    startOfDate = startOfWeek(date, firstDayOfWeek);
    endOfDate = moment(startOfDate).add(6, 'days');
  } else {
    const startOfMonth = moment(date).startOf('month').toDate();
    const endOfMonth = moment(startOfMonth).endOf('month').toDate();
    const startOfLastWeekOfMonth = startOfWeek(endOfMonth, firstDayOfWeek);
    const endOfCalendarMonth = moment(startOfLastWeekOfMonth).add(6, 'days');

    endOfDate = endOfCalendarMonth;
    startOfDate = startOfWeek(startOfMonth, firstDayOfWeek);
  }

  return {
    numOfDays: endOfDate.diff(startOfDate, 'days') + 1, // Add 1 to include the end day,
    endOfDate: endOfDate.toDate(),
    startOfDate: startOfDate,
  };
}
