import { Icon, IIconProps } from 'native-base';
import { Path } from 'react-native-svg';

export const HospitalIcon = ({ color = 'currentColor', size = 22, ...props }: IIconProps) => {
  return (
    <Icon fill="none" size={size} strokeWidth="0" viewBox="0 0 27 26" {...props}>
      <Path
        d="M1.5 7.4C1.5 5.15979 1.5 4.03969 1.93597 3.18404C2.31947 2.43139 2.93139 1.81947 3.68404 1.43597C4.53969 1 5.65979 1 7.9 1H19.1C21.3402 1 22.4603 1 23.316 1.43597C24.0686 1.81947 24.6805 2.43139 25.064 3.18404C25.5 4.03969 25.5 5.15979 25.5 7.4V18.6C25.5 20.8402 25.5 21.9603 25.064 22.816C24.6805 23.5686 24.0686 24.1805 23.316 24.564C22.4603 25 21.3402 25 19.1 25H7.9C5.65979 25 4.53969 25 3.68404 24.564C2.93139 24.1805 2.31947 23.5686 1.93597 22.816C1.5 21.9603 1.5 20.8402 1.5 18.6V7.4Z"
        stroke="#6938EF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <Path
        d="M15.9444 6.73333C15.9444 6.35997 15.9444 6.17328 15.8718 6.03067C15.8079 5.90523 15.7059 5.80324 15.5804 5.73933C15.4378 5.66667 15.2511 5.66667 14.8778 5.66667H12.1222C11.7489 5.66667 11.5622 5.66667 11.4196 5.73933C11.2941 5.80324 11.1921 5.90523 11.1282 6.03067C11.0556 6.17328 11.0556 6.35997 11.0556 6.73333V9.48889C11.0556 9.86226 11.0556 10.0489 10.9829 10.1915C10.919 10.317 10.817 10.419 10.6915 10.4829C10.5489 10.5556 10.3623 10.5556 9.98889 10.5556H7.23333C6.85997 10.5556 6.67328 10.5556 6.53067 10.6282C6.40523 10.6921 6.30324 10.7941 6.23933 10.9196C6.16667 11.0622 6.16667 11.2489 6.16667 11.6222V14.3778C6.16667 14.7511 6.16667 14.9378 6.23933 15.0804C6.30324 15.2059 6.40523 15.3079 6.53067 15.3718C6.67328 15.4444 6.85997 15.4444 7.23333 15.4444H9.98889C10.3623 15.4444 10.5489 15.4444 10.6915 15.5171C10.817 15.581 10.919 15.683 10.9829 15.8085C11.0556 15.9511 11.0556 16.1377 11.0556 16.5111V19.2667C11.0556 19.64 11.0556 19.8267 11.1282 19.9693C11.1921 20.0948 11.2941 20.1968 11.4196 20.2607C11.5622 20.3333 11.7489 20.3333 12.1222 20.3333H14.8778C15.2511 20.3333 15.4378 20.3333 15.5804 20.2607C15.7059 20.1968 15.8079 20.0948 15.8718 19.9693C15.9444 19.8267 15.9444 19.64 15.9444 19.2667V16.5111C15.9444 16.1377 15.9444 15.9511 16.0171 15.8085C16.081 15.683 16.183 15.581 16.3085 15.5171C16.4511 15.4444 16.6377 15.4444 17.0111 15.4444H19.7667C20.14 15.4444 20.3267 15.4444 20.4693 15.3718C20.5948 15.3079 20.6968 15.2059 20.7607 15.0804C20.8333 14.9378 20.8333 14.7511 20.8333 14.3778V11.6222C20.8333 11.2489 20.8333 11.0622 20.7607 10.9196C20.6968 10.7941 20.5948 10.6921 20.4693 10.6282C20.3267 10.5556 20.14 10.5556 19.7667 10.5556H17.0111C16.6377 10.5556 16.4511 10.5556 16.3085 10.4829C16.183 10.419 16.081 10.317 16.0171 10.1915C15.9444 10.0489 15.9444 9.86226 15.9444 9.48889V6.73333Z"
        stroke="#6938EF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Icon>
  );
};
