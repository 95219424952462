import { createElement } from 'react';
import { HStack } from 'native-base';
import { find, merge } from 'lodash';

import { Text, TextProps } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { useAppModules } from '../context';

type ModuleTitleProps = Omit<TextProps, 'children'> & {
  defaultText?: string;
  name?: string;
};

export const ModuleTitle = ({ name, defaultText, ...props }: ModuleTitleProps) => {
  const { translate } = useAppLocale();
  const { screens } = useAppModules();
  let screen;
  let titleText = defaultText;
  let icon;

  if (name) {
    screen = find(screens, _ => _.name === name);
    titleText = screen?.title ?? defaultText ?? '';
    icon = screen?.icon;
  }

  return (
    <HStack space={2} alignItems="center">
      {!!icon && createElement(icon, merge({ size: 'lg', color: 'black' }))}
      <Text size={{ base: '3xl', xl: '5xl' }} color="black" fontWeight={600} lineHeight="xs" numberOfLines={2} {...props}>
        {translate(titleText, screen.titleTranslations)}
      </Text>
    </HStack>
  );
};
