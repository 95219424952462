import { memo } from 'react';
import { useMutation } from 'react-query';
import { Box, CloseIcon, HStack, IconButton, Pressable, Spinner } from 'native-base';
import { isEqual } from 'lodash';

import { ChevronSelectorVerticalIcon, Text } from '@pimm/base';
import { DeleteOpsTaskAssignment } from '@pimm/services/lib/sms-workforce/services';
import { OpsPhasePositionAssignee } from '../types';

type PressablePositionAssigneeProps = {
  opsPhaseId: number;
  positionId: string;
  assignee?: OpsPhasePositionAssignee;
  primary?: boolean;
  onClear?: (assignee?: OpsPhasePositionAssignee, isDelete?: boolean) => void;
  onPress?: () => void;
};

const PressablePositionAssignee = ({ assignee, opsPhaseId, positionId, primary, ...props }: PressablePositionAssigneeProps) => {
  const deleteTaskAssignment = useMutation({
    mutationFn: DeleteOpsTaskAssignment,
    onSuccess: () => {
      if (props.onClear) {
        props.onClear(assignee, true);
      }
    },
  });

  const isLoading = deleteTaskAssignment.isLoading;
  const isAssigned = !!assignee?.id;

  const handlePressDelete = () => {
    if (assignee) {
      deleteTaskAssignment.mutate({ id: assignee.id });
    }
  };

  return (
    <Pressable rounded="lg" onPress={props.onPress}>
      <HStack
        rounded="lg"
        alignItems="center"
        justifyContent="space-between"
        pl={2}
        h={8}
        borderWidth={1}
        borderColor={primary ? 'primary.200' : 'gray.200'}
        bg={primary ? 'primary.50' : 'white'}
      >
        <Text
          size="md"
          fontWeight={isAssigned ? 600 : 400}
          color={isAssigned ? 'black' : primary ? 'primary.600' : 'gray.600'}
          numberOfLines={1}
        >
          {assignee?.name || 'Select Employee'}
        </Text>
        <Box alignItems="center" justifyContent="center" w={7}>
          {isLoading && <Spinner boxSize="6px" color={primary ? 'primary.400' : 'gray.400'} />}
          {!isLoading && isAssigned && (
            <IconButton p={1} icon={<CloseIcon size="10px" color={primary ? 'primary.500' : 'gray.500'} />} onPress={handlePressDelete} />
          )}
          {!isLoading && !isAssigned && <ChevronSelectorVerticalIcon color={primary ? 'primary.500' : 'gray.500'} />}
        </Box>
      </HStack>
    </Pressable>
  );
};

const areEqual = (prevProps, nextProps) => {
  const { assignee: prevAssignee } = prevProps;
  const { assignee } = nextProps;

  /* if the props are equal, it won't update */
  return isEqual(prevAssignee, assignee);
};

export default memo(PressablePositionAssignee, areEqual);
