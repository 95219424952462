import { OpsTaskItemDto } from '@pimm/services/lib/sms-positioning';
import { OpsPhaseDto, OpsPhaseProfileDto, OpsTaskTrackerDto, ShiftDto, StringTranslation } from '@pimm/services/lib/sms-workforce';
import { map } from 'lodash';
import { OpsPhasePositionAssignee } from '../types';

export interface FlowChartPositionTask {
  opsTaskId: string;
  opsTaskTrackerId?: number;
  startTime: Date;
  endTime: Date;
  durationInMinutes: number;
  offsetInMinutes: number;
  seq: number;
  isComplete?: boolean;
  items: OpsTaskItemDto[];
  title: string;
  translations?: StringTranslation[];
}

export interface FlowChartPosition {
  positionId: string;
  title: string;
  color?: string;
  iconUrl?: string;
  assignee?: OpsPhasePositionAssignee;
  opsTaskAssignmentId?: number;
  startTime?: Date;
  endTime?: Date;
  tasks: FlowChartPositionTask[];
  translations?: StringTranslation[];
}

export interface FlowChartChecklist {
  eligableEmployees?: ShiftDto[];
  opsPhase?: OpsPhaseDto;
  positions: FlowChartPosition[];
  salesVolumeProfileId?: number;
}

export enum FlowChartAction {
  RESET = 'RESET',
  COMPLETE = 'COMPLETE',
  UPDATE = 'UPDATE',
}

// Define the action types
type Action =
  | { type: FlowChartAction.RESET; payload?: FlowChartChecklist }
  | { type: FlowChartAction.COMPLETE; payload?: OpsTaskTrackerDto & { positionId: string } }
  | { type: FlowChartAction.UPDATE; payload?: Partial<FlowChartPosition> };

export const FlowChartChecklistInitialState: FlowChartChecklist = {
  positions: [],
};

// Create the reducer
export const FlowChartReducer = (state: FlowChartChecklist, action: Action): FlowChartChecklist => {
  switch (action.type) {
    case FlowChartAction.RESET:
      return action.payload ?? FlowChartChecklistInitialState;

    case FlowChartAction.COMPLETE:
      return {
        ...state,
        positions: map(state?.positions, position => {
          if (position.positionId !== action.payload?.positionId) return position;
          const tasks = map(position.tasks, task => {
            if (task.opsTaskId === action.payload?.opsTaskId) {
              // Return the updated OpsTask with the TrackerId
              const trackerId = action.payload?.id;
              return { ...task, opsTaskTrackerId: trackerId, isComplete: !!trackerId };
            }
            return task;
          });
          return { ...position, tasks: tasks };
        }),
      };

    case FlowChartAction.UPDATE:
      return {
        ...state,
        positions: map(state?.positions, position => {
          if (position.positionId === action.payload?.positionId) {
            const nextPosition = { ...position, ...action.payload };
            if (!nextPosition.assignee) {
              // If ever we delete assigne employee, clear all completed task
              nextPosition.tasks = map(nextPosition.tasks, task => ({ ...task, isComplete: false, opsTaskTrackerId: undefined }));
            }
            nextPosition.opsTaskAssignmentId = nextPosition.assignee?.id;
            return nextPosition;
          }
          return position;
        }),
      };

    default:
      return state;
  }
};
