import { QueryKey, UseQueryResult, useQuery } from 'react-query';

import { GetMetrics } from '@pimm/services/lib/store-equipment/services';
import { RollupDateRange } from '@pimm/services/lib/store-equipment';

export type GetMetricsParams = {
  siteId?: string;
  range?: RollupDateRange;
  equipmentId?: string;
};

export const useGetMetrics = (query?: GetMetricsParams, queryKey?: string): UseQueryResult<Awaited<ReturnType<typeof GetMetrics>>> => {
  const metrics = useQuery({
    enabled: !!query?.siteId && !!query.range,
    queryKey: [queryKey ?? 'GetMetrics', query] as [QueryKey, GetMetricsParams],
    queryFn: ({ queryKey: [key, params] }) => GetMetrics(params!.siteId!, { EquipmentId: params?.equipmentId, DateRange: params.range! }),
  });
  return metrics;
};
