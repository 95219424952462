import { useTranslation } from 'react-i18next';
import { VStack, HStack, Box, Pressable } from 'native-base';
import { flatMap, isEmpty } from 'lodash';

import { Text } from '@pimm/base';
import { Dispenser } from '@pimm/services/lib/coke-freestyle';
import { ResourceLoader } from '@app/components/shared';
import { dispenserName, useGetSiteDispensers } from '@app/features/coke-freestyle';
import { FreestyleLogo } from '@app/features/coke-freestyle/assets';
import { WidgetBox, WidgetBoxProps } from '../widget-box';

type WidgetCokeFreeStyleProps = WidgetBoxProps & {
  siteDispensers: ReturnType<typeof useGetSiteDispensers>;
  onPressDispenser?: (dispenser: Dispenser) => void;
};

export const WidgetCokeFreeStyle = ({ siteDispensers, title = 'Coke Freestyle', onPressDispenser, ...props }: WidgetCokeFreeStyleProps) => {
  const { t } = useTranslation();
  const handlePressDispenser = (dispenser: Dispenser) => () => {
    if (onPressDispenser) onPressDispenser(dispenser);
  };

  const dispensers = flatMap(siteDispensers.data, _ => _.dispensers ?? []);

  return (
    <WidgetBox title={t('common:coke_freestyle')} {...props}>
      <ResourceLoader h="80px" bgColor="red" isEmpty={isEmpty(dispensers)} isLoading={siteDispensers.isLoading} _text={{ size: 'sm' }}>
        <HStack space={3} w="full" h="full" alignItems="center">
          <FreestyleLogo width={27} height={52} color="red" />
          <VStack space={1} flex={1} rounded="md">
            {dispensers.map(_ => (
              <Pressable key={_.dispenserId} rounded="md" onPress={handlePressDispenser(_)}>
                <Box pl={2} pr={1} rounded="md" backgroundColor="gray.50">
                  <HStack alignItems="center" minH={7}>
                    <Box flex={1}>
                      <Text size="sm" color="black" fontWeight={600} numberOfLines={1} ellipsizeMode="tail">
                        {_.displayName || dispenserName(_.type)}
                      </Text>
                    </Box>
                    <Box flex="none" alignItems="center" px={0.5} bgColor={_?.lastUpdated ? 'green.200' : 'error.100'} rounded="sm">
                      <Text size="xs" color={_?.lastUpdated ? 'green.700' : 'green.700'} fontWeight={400}>
                        {_?.lastUpdated ? 'Online' : 'Offline'}
                      </Text>
                    </Box>
                    <HStack space={1} ml={1}>
                      <Box justifyContent="center" minW={8} h={5} rounded="md" bgColor="error.500" opacity={_.emptyCount ? 1 : 0.3}>
                        <Text size="md" fontWeight={700} color="white" textAlign="center">
                          {_.emptyCount ?? 0}
                        </Text>
                      </Box>
                      <Box justifyContent="center" minW={8} h={5} rounded="md" bgColor="warning.400" opacity={_.lowCount ? 1 : 0.3}>
                        <Text size="md" fontWeight={700} color="white" textAlign="center">
                          {_.lowCount ?? 0}
                        </Text>
                      </Box>
                    </HStack>
                  </HStack>
                </Box>
              </Pressable>
            ))}
          </VStack>
        </HStack>
      </ResourceLoader>
    </WidgetBox>
  );
};
