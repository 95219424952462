import { Box, CloseIcon, HStack, IconButton, Pressable, Spinner } from 'native-base';
import { isEmpty } from 'lodash';

import { ChevronSelectorVerticalIcon, Text, TextProps } from '@pimm/base';
import { ComponentProps, ForwardedRef, forwardRef } from 'react';

type PressableSelectProps = {
  _container?: ComponentProps<typeof Pressable>;
  _text?: TextProps;
  hideClear?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isReadOnly?: boolean;
  placeholder?: string;
  value?: string;
  onClear?: () => void;
  onPress?: () => void;
};

// TODO: move to package/base
export const PressableSelect = forwardRef<typeof Pressable, PressableSelectProps>(
  (
    { _container, _text, hideClear, isDisabled, isLoading, isReadOnly, placeholder, value, ...props }: PressableSelectProps,
    ref: ForwardedRef<typeof Pressable>,
  ) => {
    const isBlank = isEmpty(value);

    return (
      <Pressable ref={ref} isDisabled={isDisabled} rounded="lg" onPress={props.onPress} {..._container}>
        <HStack
          rounded="lg"
          alignItems="center"
          justifyContent="space-between"
          pl={2}
          h={8}
          borderWidth={1}
          borderColor="gray.200"
          bg="white"
        >
          <Text size="md" fontWeight={!isBlank ? 600 : 400} color={!isBlank ? 'black' : 'gray.600'} numberOfLines={1} {..._text}>
            {value || (placeholder ?? 'Select')}
          </Text>
          <Box alignItems="center" justifyContent="center" w={7}>
            {isLoading && <Spinner boxSize="2" color={'gray.400'} />}
            {!hideClear && !isLoading && !isBlank && !isDisabled && (
              <IconButton p={1} icon={<CloseIcon size="10px" color={'gray.500'} />} onPress={props.onClear} />
            )}
            {!isLoading && (hideClear || isBlank) && <ChevronSelectorVerticalIcon color={isReadOnly ? 'gray.200' : 'gray.500'} />}
          </Box>
        </HStack>
      </Pressable>
    );
  },
);
