import { Fragment, useDeferredValue, useState } from 'react';
import { Box, HStack, Spacer, VStack, useTheme } from 'native-base';
import { last } from 'lodash';
import moment from 'moment';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import { Button, Text } from '@pimm/base';
import { DayBlock } from '@pimm/common';
import { TimePicker } from '@app/components/shared';

export interface TimeInputs {
  startTime: Date;
  endTime: Date;
}

type ScheduleTimeEditorProps = {
  referenceId?: string | number;
  dayBlocks: DayBlock[];
  displayText?: string;
  startTime?: Date;
  endTime?: Date;
  onApply: (inputs: { referenceId?: string | number; startTime: Date; endTime: Date }) => void;
  onCancel: () => void;
};

export const ScheduleTimeEditor = ({ dayBlocks, referenceId, ...props }: ScheduleTimeEditorProps) => {
  const { colors } = useTheme();
  const _startTime = props.startTime ?? dayBlocks[0].startTime;
  // For endTime add 1 hour as default
  const _endTime = moment(props.endTime ?? new Date(dayBlocks[0].startTime.getTime() + 60 * 60 * 1000));

  const [endOfBlock] = useState<Date | undefined>(last(dayBlocks)?.endTime);
  const [timeInputs, setTimeInputs] = useState<TimeInputs>({
    // Uses the calculated _endTime and ensures the date part is the same as the _startTime.
    endTime: moment(_endTime).set({ date: _startTime.getDate() }).toDate(),
    startTime: _startTime,
  });

  const correctedEndTime = useDeferredValue(
    timeInputs.endTime <= timeInputs.startTime ? moment(timeInputs.endTime).add(1, 'day').toDate() : timeInputs.endTime,
  );

  const isValidInputs =
    timeInputs.startTime >= dayBlocks[0].startTime &&
    endOfBlock &&
    correctedEndTime <= endOfBlock &&
    timeInputs.startTime.getTime() !== correctedEndTime.getTime();

  const handleChangeTime = (field: keyof TimeInputs) => (value: Date) => {
    setTimeInputs(prev => ({ ...prev, [field]: value }));
  };

  const handlePressApply = () => {
    const payload = {
      referenceId: referenceId,
      startTime: timeInputs.startTime,
      endTime: correctedEndTime,
    };
    props.onApply(payload);
  };

  return (
    <VStack space={3} pt={!!props.displayText ? 3 : 5} w="full" rounded="lg" overflow="hidden">
      {!!props.displayText && (
        <Box px={4}>
          <Text size="xl" fontWeight={700} color="black">
            {props.displayText}
          </Text>
        </Box>
      )}

      <HStack space={3} px={4} w="full">
        <VStack flex={1} space={3} rounded="xl" p={3} bg="gray.50">
          <Text size="lg" fontWeight={600} color="gray.900" lineHeight="xs">
            Start Time
          </Text>
          <Box rounded="xl" p={2} borderWidth={1} bg="white">
            <TimePicker value={timeInputs.startTime} onChange={handleChangeTime('startTime')} />
          </Box>
        </VStack>

        <VStack flex={1} space={3} rounded="xl" p={3} bg="gray.50">
          <HStack space={1}>
            <Text size="lg" fontWeight={600} color="gray.900" lineHeight="xs">
              End Time
            </Text>
            {timeInputs.startTime.getDate() !== correctedEndTime.getDate() && (
              <Fragment>
                <Spacer />
                <MaterialCommunityIcons name="clock-plus-outline" size={15} color={colors.blue[500]} />
                <Text size="lg" fontWeight={600} color="blue.500" lineHeight="xs">
                  Next Day
                </Text>
              </Fragment>
            )}
          </HStack>

          <Box rounded="xl" p={2} borderWidth={1} bg="white">
            <TimePicker value={timeInputs.endTime} onChange={handleChangeTime('endTime')} />
          </Box>
        </VStack>
      </HStack>

      <HStack mt={2} borderBottomRadius="xl" overflow="hidden">
        <Button
          flex={1}
          size="lg"
          rounded="none"
          borderWidth="gray.100"
          backgroundColor="gray.200"
          _text={{ fontSize: 'lg', color: 'gray.700' }}
          onPress={props.onCancel}
        >
          Cancel
        </Button>

        <Button flex={1} size="lg" rounded="none" disabled={!isValidInputs} _text={{ fontSize: 'lg' }} onPress={handlePressApply}>
          Apply
        </Button>
      </HStack>
    </VStack>
  );
};
