import {
  ArrowsTriangleIcon,
  CalendarIcon,
  CalendarClockIcon,
  CleanIcon,
  CupSodaIcon,
  FaceSmileIcon,
  FileAttachmentIcon,
  HomeIcon,
  LightBulb05Icon,
  LineChartIcon,
  TargetIcon,
  ThermometerIcon,
  Settings03Icon,
  ClockStopWatchIcon,
} from '@pimm/base';
import { StringTranslation } from '@pimm/services/lib/sms-tenants';
import { ModuleScreen } from '@app/features/app';
import { DashboardModalType } from '@app/features/dashboard';
import CalendarScreen from '@app/screens/calendar';
import CleaningTasksScreen from '@app/screens/cleaning-tasks';
import FlowChartScreen from '@app/screens/flow-chart';
import DailyPlanScreen from '@app/screens/daily-plan';
import DocumentsScreen from '@app/screens/documents';
import PositionGuideScreen from '@app/screens/position-guide';
import TemperaturesScreen from '@app/screens/temperatures';
import SalesTrackerScreen from '@app/screens/sales-tracker';
import StoreInfoScreen from '@app/screens/store-info';
import MessageBoardScreen from '@app/screens/message-board';
import OpsPlanScreen from '@app/screens/ops-plan';
import AppInsightsScreen from '@app/screens/app-insights';
import SpeedOfServiceScreen from '@app/screens/speed-of-service';

type MainNavigationModule = ModuleScreen<{
  sectionTitle?: string;
  sectionTitleTranslations?: StringTranslation[];
  hasConfigs?: Record<string, any>;
  modal?: { type: DashboardModalType };
}>;

export const MainNavigationModules: MainNavigationModule[] = [
  {
    name: 'PositionGuide',
    title: 'Position Guide',
    component: PositionGuideScreen,
    icon: HomeIcon,
    iconProps: {
      size: 'md',
    },
    identifierId: 'positionGuide',
    moduleType: 'Home',
    payload: {},
  },
  {
    name: 'DailyPlan',
    title: 'Position Plan',
    component: DailyPlanScreen,
    icon: CalendarClockIcon,
    iconProps: {
      size: 'md',
    },
    identifierId: 'positionPlan',
    moduleType: 'PositionPlan',
    payload: {},
  },
  {
    name: 'OpsPlan',
    title: 'Ops Plan',
    component: OpsPlanScreen,
    icon: TargetIcon,
    iconProps: {
      size: 'md',
    },
    identifierId: 'opsPlan',
    moduleType: 'OpsPlan',
    payload: {},
  },
  {
    name: 'FlowChart',
    title: 'Flow Chart',
    component: FlowChartScreen,
    icon: ArrowsTriangleIcon,
    iconProps: {
      size: 'md',
    },
    identifierId: 'opsTasks',
    moduleType: 'OpsTasks',
    payload: {},
  },
  {
    name: 'CleaningTasks',
    title: 'Cleaning Tasks',
    component: CleaningTasksScreen,
    hidden: true,
    icon: CleanIcon,
    iconProps: {
      size: 'lg',
    },
    identifierId: 'cleaningTasks',
    moduleType: 'CleaningTasks',
    payload: {
      hasConfigs: {
        'dashboardConfig.cleaningTasks.enabled': true,
      },
    },
  },
  {
    name: 'AppInsights',
    title: 'App Insights',
    component: AppInsightsScreen,
    icon: LightBulb05Icon,
    iconProps: {
      size: 'lg',
    },
    identifierId: 'appInsights',
    moduleType: 'AppInsights',
    payload: {
      hasConfigs: { 'appInsightsConfig.enabled': true },
    },
  },
  {
    name: 'SalesTracker',
    title: 'Sales & Labor Tracker',
    component: SalesTrackerScreen,
    hidden: true,
    icon: LineChartIcon,
    iconProps: {
      size: 'sm',
    },
    identifierId: 'salesTracker',
    moduleType: 'SalesTracker',
    payload: {
      hasConfigs: {
        'dashboardConfig.salesTracker.enabled': true,
      },
    },
  },
  {
    name: 'Temperatures',
    title: 'Equipment Temperatures',
    component: TemperaturesScreen,
    icon: ThermometerIcon,
    iconProps: {
      size: 'md',
    },
    identifierId: 'equipment',
    moduleType: 'Equipment',
    payload: {
      hasConfigs: {
        'equipmentEnabled': true,
      },
    },
  },
  {
    name: 'CokeFreestyle',
    title: 'Coca-Cola Freestyle',
    hidden: true,
    icon: CupSodaIcon,
    iconProps: {
      size: 'lg',
    },
    identifierId: 'cokeFreeStyle',
    moduleType: 'CokeFreestyle',
    payload: {
      hasConfigs: { 'dashboardConfig.cokeFreeStyle.enabled': true },
      modal: {
        type: DashboardModalType.CokeFreestyle,
      },
    },
  },
  {
    name: 'SpeedOfService',
    title: 'Speed of Service',
    component: SpeedOfServiceScreen,
    icon: ClockStopWatchIcon,
    iconProps: {
      size: 'lg',
    },
    identifierId: 'driveThruTimer',
    moduleType: 'DriveThruTimer',
    payload: {
      hasConfigs: { 'dashboardConfig.driveThruTimer.enabled': true },
    },
  },
  {
    name: 'CustomerSatisfactionSurvey',
    title: 'VOC Data',
    icon: FaceSmileIcon,
    identifierId: 'survey',
    moduleType: 'Survey',
    payload: {
      modal: {
        type: DashboardModalType.CustomerSatisfactionSurvey,
      },
    },
  },
  {
    name: 'Documents',
    title: 'Documents',
    component: DocumentsScreen,
    icon: FileAttachmentIcon,
    identifierId: 'documents',
    moduleType: 'Documents',
    payload: {},
  },
  {
    name: 'Calendar',
    title: 'Calendar',
    component: CalendarScreen,
    icon: CalendarIcon,
    identifierId: 'calendar',
    moduleType: 'Calendar',
    payload: {},
  },
  {
    name: 'StoreInfo',
    title: 'Store Settings',
    component: StoreInfoScreen,
    icon: Settings03Icon,
    identifierId: 'settings',
    moduleType: 'StoreInfo',
    payload: {},
  },
];
