import { Icon, IIconProps } from 'native-base';
import { Path } from 'react-native-svg';

export const ActivityIcon = ({ size = 'sm', ...props }: IIconProps) => {
  return (
    <Icon size={size} viewBox="0 0 16 16" fill="none" {...props}>
      <Path
        d="M14.6668 8H12.0002L10.0002 14L6.00016 2L4.00016 8H1.3335"
        stroke="currentColor"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Icon>
  );
};
