import React, { createContext, useContext } from 'react';

import { Employee } from '@pimm/services/lib/sms-workforce';
import { DayBlock } from '@app/features/store-core';

export interface ScheduleEmployeesContextReturn {
  customerId?: string;
  dayBlocks: DayBlock[];
  employees?: Employee[];
  isEditable?: boolean;
  siteId?: string;
}

export const ScheduleEmployeesContext = createContext<ScheduleEmployeesContextReturn>(undefined!);

export type ScheduleEmployeesProviderProps = Pick<
  ScheduleEmployeesContextReturn,
  'customerId' | 'dayBlocks' | 'employees' | 'isEditable' | 'siteId'
> & {
  children: React.ReactNode;
};

export const ScheduleEmployeesProvider = ({ children, ...values }: ScheduleEmployeesProviderProps) => {
  return <ScheduleEmployeesContext.Provider value={values}>{children}</ScheduleEmployeesContext.Provider>;
};

export const useScheduleEmployees = () => useContext(ScheduleEmployeesContext);
