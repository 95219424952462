import React, { createContext, useContext, useEffect, useState } from 'react';
import { useGetStoreContacts } from '../hooks/get-store-contacts.hook';
import { ContactCategoryEnum, StoreContactDto } from '@pimm/services/lib/store-management';

type StoreContactOptionType = 'edit' | 'delete';

interface StoreContactsContextProps {
  category: ContactCategoryEnum;
  contacts: ReturnType<typeof useGetStoreContacts>[0];
  selectContact: (contact?: StoreContactDto, option?: StoreContactOptionType) => void;
  updateContacts: (contact: StoreContactDto, option: StoreContactOptionType) => void;
  changeCategory: (category: ContactCategoryEnum) => void;
}

export const StoreContactsContext = createContext<StoreContactsContextProps>(undefined!);

interface StoreContactsProviderProps {
  children: React.ReactNode | React.ReactElement;
  siteId?: string;
}

export const StoreContactsProvider: React.FC<StoreContactsProviderProps> = ({ children, siteId }) => {
  const [category, setCategory] = useState<ContactCategoryEnum>(ContactCategoryEnum.EMERGENCY_MAIN);
  const [contactsQuery, setContactsQuery] = useGetStoreContacts();

  useEffect(() => {
    if (siteId) {
      setContactsQuery({
        categories: [ContactCategoryEnum.EMERGENCY_MAIN, ContactCategoryEnum.EMERGENCY_OTHER],
        siteId,
      });
    }
  }, [siteId]);

  const updateContacts = (contact: StoreContactDto, option: StoreContactOptionType) => {
    // Update contacts logic
  };

  const handleSelectContact = (contact?: StoreContactDto, option?: StoreContactOptionType) => {
    // Select contact logic
  };

  const changeCategory = (category: ContactCategoryEnum) => {
    setCategory(category);
  };

  return (
    <StoreContactsContext.Provider
      value={{
        category,
        contacts: contactsQuery,
        selectContact: handleSelectContact,
        updateContacts,
        changeCategory,
      }}
    >
      {children}
    </StoreContactsContext.Provider>
  );
};

export const StoreContactsConsumer = StoreContactsContext.Consumer;
export const useStoreContacts = () => useContext(StoreContactsContext);
