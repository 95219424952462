import { ComponentProps, useEffect, useMemo, useState } from 'react';
import { flatMap, last, map, orderBy, partition, reduce, reverse, uniq } from 'lodash';
import { Box, ChevronDownIcon, ChevronUpIcon, Circle, Divider, HStack, IconButton, SectionList } from 'native-base';
import moment from 'moment';

import { ClockIcon, Text } from '@pimm/base';
import { useSiteTime } from '@app/features/store-core';
import { FlowChartPositionTask } from '../reducers/flow-chart-checklist.reducer';
import { FlowPositionTask } from './flow-position-task';

enum TimeDivision {
  Past = -1,
  Present = 0,
  Future = 1,
}

export type FlowTimelineProps = Pick<ComponentProps<typeof FlowPositionTask>, 'onComplete'> & {
  highlightTaskId?: string;
  isDisabled?: boolean;
  opsTaskAssignmentId?: number;
  tasks?: FlowChartPositionTask[];
  onPressTask?: (taskId: string) => void;
};

export const FlowTimeline = ({ opsTaskAssignmentId, highlightTaskId, isDisabled, ...props }: FlowTimelineProps) => {
  const siteTime = useSiteTime();
  const [timeNow, setTimeNow] = useState<Date>(siteTime.today());
  const [headers, setHeaders] = useState<{ collapsed?: boolean; title: string }[]>([
    { title: 'Pending Tasks' },
    { title: 'Completed Tasks' },
  ]);
   const sections: { collapsed?: boolean; title: string; data: ({ status: TimeDivision } & FlowChartPositionTask)[] }[] = useMemo(() => {
    const result = partition(props.tasks, _ => !_.isComplete)
      .map((arr, i) => {
        // we reverse the array to check from the last task and
        // to distinguish which task is already past the current time, and the rest will mark as past.
        const tasks = reduce(
          reverse(orderBy(arr, ['seq', 'startTime', 'endTime'])),
          (tasksWithStatus: ({ status: TimeDivision } & FlowChartPositionTask)[], task) => {
            const prevStatus = last(tasksWithStatus)?.status;
            let status = TimeDivision.Present;

            if (prevStatus !== undefined && prevStatus !== TimeDivision.Future) status = TimeDivision.Past;
            else {
              const startTime = moment(task.startTime).toDate();
              const endTime = moment(task.endTime).toDate();
              if (endTime < timeNow) status = TimeDivision.Past;
              else if (timeNow < startTime) status = TimeDivision.Future;
            }
            return [...tasksWithStatus, { ...task, status: status }];
          },
          [],
        );
        return { ...headers[i], data: reverse(tasks) };
      })
      .filter(_ => _.data.length > 0);
    return result;
  }, [headers, timeNow, props.tasks]);

  const handlePressTaskId = (taskId: string) => () => {
    if (props.onPressTask) props.onPressTask(taskId);
  };

  const handlePressCollapse = (title: string) => () => {
    setHeaders(prev =>
      map(prev, head => ({
        ...head,
        collapsed: head.title === title ? !head.collapsed : head.collapsed,
      })),
    );
  };

  // The purpose of this effect is to get the next reload time, so we can redraw the bar line and clock marker
  useEffect(() => {
    let timeoutRef;

    if (props.tasks?.length) {
      const timeNow = siteTime.today();
      // Get all available uniq time and find the next reload time
      const reloadTime = uniq(flatMap(props.tasks, _ => [_.startTime, _.endTime]))
        .sort()
        .find(hr => timeNow < moment(hr).toDate());
      if (reloadTime) {
        const diff = moment(reloadTime).valueOf() - timeNow.getTime();
        timeoutRef = setTimeout(() => setTimeNow(siteTime.today()), diff);
      }
    }

    return () => clearTimeout(timeoutRef);
  }, [props.tasks, timeNow]);

  return (
    <SectionList
      pt={1}
      pb={2}
      h="full"
      w="full"
      removeClippedSubviews
      scrollEnabled
      stickyHeaderIndices={[0]}
      sections={sections}
      renderSectionFooter={_ => {
        return sections.length > 1 && _.section.title === 'Pending Tasks' ? <Divider mt={2} bg="gray.200" /> : <></>;
      }}
      renderSectionHeader={({ section: { collapsed, title } }) => (
        <HStack alignItems="center" justifyContent="space-between" py={1} px={2.5}>
          <Text size="lg" fontWeight={700} color="gray.900" lineHeight="lg">
            {title}
          </Text>
          {sections.length > 1 && (
            <IconButton
              p={1.5}
              _hover={{ bg: 'gray.50' }}
              icon={collapsed ? <ChevronUpIcon size={3.5} color="black" /> : <ChevronDownIcon size={3.5} color="black" />}
              onPress={handlePressCollapse(title)}
            />
          )}
        </HStack>
      )}
      extraData={[highlightTaskId, isDisabled, timeNow]}
      keyExtractor={item => item.opsTaskId}
      renderItem={({ item: task, index, section }) => {
        if (section.collapsed) return null;

        const endOfLine = section.data.length - 1 === index;
        // create a margin between vertical line
        const breakLine = task.status === TimeDivision.Future ? 0.5 : 0;

        let timeColor = task.status === TimeDivision.Future ? 'gray.500' : 'warning.600';
        if (task.isComplete) timeColor = 'success.600';

        return (
          <HStack mt={breakLine} px={2.5} w="full">
            {!task.isComplete && (
              <Box alignItems="center" justifyContent="center" w={3}>
                <Box position="absolute" h="full" borderStyle="dashed" borderColor="gray.500" borderWidth={1} />
                {task.status !== TimeDivision.Future && (
                  <Box position="absolute" top={0} h={task.status === TimeDivision.Present ? '1/2' : 'full'} w={0.5} bg={timeColor} />
                )}
                {index === 0 && (
                  <Circle top={0} size={2} position="absolute" bgColor={task.status === TimeDivision.Future ? 'gray.500' : timeColor} />
                )}

                {endOfLine && (
                  <Circle bottom={0} size={2} position="absolute" bgColor={task.status !== TimeDivision.Past ? 'gray.500' : timeColor} />
                )}

                {task.status === TimeDivision.Present && (
                  <IconButton
                    disabled
                    rounded="full"
                    position="absolute"
                    p={0.5}
                    bg={timeColor}
                    icon={<ClockIcon size="sm" color="white" />}
                  />
                )}
              </Box>
            )}

            <Box flex={1} pl={task.isComplete ? 0 : 2} pt={breakLine > 0 ? 0.5 : 1} pb={1} minH={60}>
              <FlowPositionTask
                key={`${section.title}:${task.opsTaskId}`}
                isDisabled={isDisabled}
                isHighlighted={!!highlightTaskId && highlightTaskId === task.opsTaskId}
                isPastTime={task.status === TimeDivision.Past}
                opsTaskAssignmentId={opsTaskAssignmentId}
                task={task}
                timeNow={timeNow}
                onPress={handlePressTaskId(task.opsTaskId)}
                onComplete={props.onComplete}
              />
            </Box>
          </HStack>
        );
      }}
    />
  );
};
