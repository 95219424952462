import { Box, HStack, VStack } from 'native-base';

import { Text } from '@pimm/base';

export type ScreenLayoutProps = React.ComponentProps<typeof VStack> & {
  title?: string | React.ReactNode;
  titleProps?: React.ComponentProps<typeof Text>;
  actionTool?: React.ReactNode;
};

export const ScreenLayout = ({ actionTool, children, title, titleProps }: ScreenLayoutProps) => {
  return (
    <VStack w="full" h="full">
      <HStack px={3} height={60} alignItems="center" justifyContent="space-between" borderWidth={1}>
        {typeof title === 'string' ? (
          <Text size={{ md: '5xl', xl: '7xl' }} fontWeight={600} color="black" {...titleProps}>
            {title}
          </Text>
        ) : (
          title
        )}
        {actionTool ? <Box flex={1}>{actionTool}</Box> : null}
      </HStack>
      <Box flex={1}>{children}</Box>
    </VStack>
  );
};
