import { Icon, IIconProps } from 'native-base';
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg';

export const WifiOffIcon = ({ size = 'sm', ...props }: IIconProps) => {
  return (
    <Icon size={size} viewBox="0 0 24 25" fill="none" {...props}>
      <G clipPath="url(#clip0_3929_4378)">
        <Path
          d="M15.3119 10.7034C16.6802 11.1297 17.9624 11.8225 19.08 12.7534M22.5799 9.20334C19.6575 6.62731 15.8956 5.20599 11.9999 5.20599C11.3949 5.20599 10.7931 5.24027 10.1972 5.30784M8.52979 16.3134C9.54499 15.5921 10.7595 15.2047 12.0048 15.2047C13.2501 15.2047 14.4646 15.5921 15.4798 16.3134M12 20.2034H12.01M1.19336 9.40413C2.52697 8.18206 4.06839 7.18312 5.75851 6.46643M4.73193 12.9463C6.12934 11.7153 7.84172 10.8335 9.73265 10.4373M15.6983 16.4785C14.6792 15.6796 13.3952 15.2034 11.9999 15.2034C10.5835 15.2034 9.28172 15.6942 8.25537 16.5149M3 3.70337L21 21.7034"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_3929_4378">
          <Rect width="24" height="24" fill="white" transform="translate(0 0.703369)" />
        </ClipPath>
      </Defs>
    </Icon>
  );
};
