import { memo } from 'react';
import { useMutation } from 'react-query';
import { Box, HStack, Pressable, Spinner, VStack, useTheme } from 'native-base';
import FeatherIcons from 'react-native-vector-icons/Feather';

import { Button, Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { GoalCategoryDto, GoalDto } from '@pimm/services/lib/sms-positioning';
import { DailyPositionGoalDto, SmsWorkforceApi } from '@pimm/services/lib/sms-workforce';
import CategoryIcon from './category-icon';

type DailyGoalCardProps = {
  category: GoalCategoryDto;
  goal?: GoalDto;
  positionGoal?: DailyPositionGoalDto;
  isLoading?: boolean;
  disabled?: boolean;
  onPressSelect?: (category: GoalCategoryDto) => void;
  onChange?: (positionGoal: DailyPositionGoalDto[], isDelete?: boolean) => void;
};

const DailyGoalCard = ({ category, goal, isLoading, disabled, positionGoal, ...props }: DailyGoalCardProps) => {
  const { colors } = useTheme();
  const { translate } = useAppLocale();
  const isDisabled = disabled || !goal;

  const deleteDailyGoal = useMutation({ mutationFn: SmsWorkforceApi.DeleteDailyPositionGoal });

  const handlePressDelete = async () => {
    if (positionGoal) {
      await deleteDailyGoal.mutateAsync({ id: positionGoal.id });
      if (props.onChange) props.onChange([positionGoal], true);
    }
  };

  const handlePressSelect = () => {
    if (props.onPressSelect) props.onPressSelect(category);
  };

  return (
    <Box width="1/3">
      <Box mx={1.5} borderWidth={1} rounded="lg" bg="white">
        <VStack space={2} pt={3} pb={2} px={4}>
          <HStack space={3} alignItems="center" minH={8}>
            <Box alignItems="center" justifyContent="center" h={9} w={9} bg="gray.300" rounded="md" bgColor={category.color || 'gray.300'}>
              <CategoryIcon iconUri={category.icon} size={36} color="black" />
            </Box>
            <Text size="lg" fontWeight={700} color="black" lineHeight="xs" ellipsizeMode="tail" numberOfLines={2}>
              {translate(category.title, category.translations)}
            </Text>
          </HStack>

          <Box justifyContent="center" pb={2} minHeight={60}>
            {isLoading ? (
              <Spinner color="gray.300" />
            ) : (
              <>
                {goal ? (
                  <Text size="md" fontWeight={500} color="gray.900" lineHeight="sm" numberOfLines={4} ellipsizeMode="tail">
                    {translate(goal.description, goal.translations)}
                  </Text>
                ) : (
                  <Button
                    alternate
                    outline
                    w="full"
                    onPress={handlePressSelect}
                    disabled={disabled}
                    background="primary.50"
                    borderColor="primary.200"
                    _text={{ color: 'primary.600' }}
                    _disabled={{ bgColor: 'gray.50', borderColor: 'gray.50', _text: { color: 'gray.500' } }}
                  >
                    Select Goal
                  </Button>
                )}
              </>
            )}
          </Box>
        </VStack>
        <HStack alignItems="center" justifyContent="space-between" py={2} px={4} borderTopWidth={1}>
          <Pressable rounded="md" w={8} h={8} disabled={isDisabled || deleteDailyGoal.isLoading} onPress={handlePressDelete}>
            <Box
              alignItems="center"
              justifyContent="center"
              rounded="md"
              h="full"
              borderWidth={1}
              borderColor={isDisabled ? 'gray.100' : 'gray.300'}
            >
              {deleteDailyGoal.isLoading ? (
                <Spinner size={18} color="gray.500" />
              ) : (
                <FeatherIcons name="trash-2" size={18} color={isDisabled ? colors.gray[300] : colors.gray[700]} />
              )}
            </Box>
          </Pressable>
        </HStack>
      </Box>
    </Box>
  );
};

export default memo(DailyGoalCard);
