import { Box, Divider, HStack, IconButton, Image, Popover, Pressable, useTheme, VStack } from 'native-base';
import moment from 'moment';

import { ArrowSquareUpRightIcon, Car01Icon, InfoCircleIcon, Text } from '@pimm/base';
import { formatToShortDateTime, stringToDateLocal } from '@app/utils/date-formatter';
import { useSiteTime } from '@app/features/store-core';
import { useGetSettingsAndGoals, useGetTimerData } from '../hooks';

type ServiceConnectionProps = {
  settingsAndGoals: ReturnType<typeof useGetSettingsAndGoals>;
  timerData: ReturnType<typeof useGetTimerData>;
};

export const ServiceConnection = ({ settingsAndGoals, timerData }: ServiceConnectionProps) => {
  const { colors } = useTheme();
  const siteTime = useSiteTime();
  let lastRecordDateTime, lastImportDateTime;
  const isOffline = timerData.data?.dayTotals?.importerStatus?.toLowerCase() !== 'online';

  if (timerData.data?.dayTotals?.lastRecordDateTime) {
    lastRecordDateTime = stringToDateLocal(timerData.data?.dayTotals?.lastRecordDateTime);
    const hrsDiff = moment(siteTime.today()).diff(moment(lastRecordDateTime), 'hours');
  }

  if (timerData.data?.dayTotals?.lastImportDateTime) {
    lastImportDateTime = stringToDateLocal(timerData.data?.dayTotals?.lastImportDateTime);
  }

  const handleOpenBlankPage = () => {
    if (settingsAndGoals.data?.portalUrl) {
      window.open(settingsAndGoals.data.portalUrl, '_blank');
    }
  };

  return (
    <HStack space={2} alignItems="center" rounded="lg" py={0.5} pl={1} pr={2} height="40px" borderWidth={1} borderColor="gray.300">
      <Pressable onPress={handleOpenBlankPage}>
        <HStack space={2} alignItems="center">
          <Image
            source={{
              uri: settingsAndGoals.data?.portalImageUrl,
            }}
            bg="gray.100"
            style={{ aspectRatio: 1, width: 80, height: 30 }}
            alt={settingsAndGoals.data?.portalUrl ?? settingsAndGoals.data?.dataProviderName}
            resizeMethod="scale"
            rounded="md"
            justifyContent="center"
          />

          <ArrowSquareUpRightIcon size="md" color={colors.gray[700]} />
        </HStack>
      </Pressable>

      <Divider orientation="vertical" height="80%" bg="gray.300" />

      {settingsAndGoals.data?.importerEnabled ? (
        <VStack>
          {!!lastRecordDateTime && (
            <>
              <HStack>
                <Box flex="none" rounded="full" px={1} bg={isOffline ? 'error.50' : 'success.50'}>
                  <Text size="xs" fontWeight={500} color={isOffline ? 'error.600' : 'success.600'}>
                    API Service: {timerData.data?.dayTotals?.importerStatus ?? ''}
                  </Text>
                </Box>
              </HStack>

              <HStack space={1} alignItems="center">
                <Car01Icon size="18px" color={colors.gray[700]} />
                <Text size="sm" fontWeight={700} color="gray.700">
                  {formatToShortDateTime(lastRecordDateTime)}
                </Text>
              </HStack>
            </>
          )}
        </VStack>
      ) : (
        <HStack>
          <Box flex="none" rounded="lg" px={1.5} bg="gray.50">
            <Text size="sm" fontWeight={500} color="gray.600">
              API Service: Not Enabled
            </Text>
          </Box>
        </HStack>
      )}

      <Popover
        isKeyboardDismissable
        trigger={popoverProps => {
          return (
            <IconButton
              ml={1}
              p={0}
              _hover={{ bg: 'transparent' }}
              icon={<InfoCircleIcon size={4} color={colors.gray[700]} />}
              {...popoverProps}
            />
          );
        }}
      >
        <Popover.Content accessibilityLabel="Select Ops Leader" rounded="lg">
          <Popover.Arrow zIndex={1} />
          <Popover.Body p={3} w="280">
            <VStack space={2}>
              <HStack>
                {settingsAndGoals.data?.importerEnabled ? (
                  <Box flex="none" rounded="lg" py={0.5} px={1.5} bg={isOffline ? 'error.50' : 'success.50'}>
                    <Text size="md" fontWeight={500} color={isOffline ? 'error.600' : 'success.600'}>
                      API Service: {isOffline ? 'Offline' : 'Online'}
                    </Text>
                  </Box>
                ) : (
                  <Box flex="none" rounded="lg" px={1.5} bg="gray.50">
                    <Text size="sm" fontWeight={500} color="gray.600">
                      API Service: Not Enabled
                    </Text>
                  </Box>
                )}
              </HStack>

              <Box>
                {!!lastImportDateTime && (
                  <>
                    <Text size="sm" fontWeight={600} color="gray.700">
                      Last Data Sync
                    </Text>
                    <Text size="sm" fontWeight={700} color="black">
                      {formatToShortDateTime(lastImportDateTime)}
                    </Text>
                  </>
                )}
                {!!lastRecordDateTime && (
                  <>
                    <Text size="sm" fontWeight={600} color="gray.700" mt={1}>
                      Last Order Recorded
                    </Text>
                    <Text size="sm" fontWeight={700} color="black">
                      {formatToShortDateTime(lastRecordDateTime)}
                    </Text>
                  </>
                )}
              </Box>

              <Box>
                <Text size="sm" fontWeight={700} color="black">
                  Note:
                </Text>
                <VStack space={1}>
                  <HStack space={1} pl={1}>
                    <Text size="sm" fontWeight={600} color="gray.700" lineHeight="xs">
                      {'\u2022'}
                    </Text>
                    <Text size="sm" fontWeight={600} color="gray.700" lineHeight="xs">
                      API service fetches new data every 15 minutes.
                    </Text>
                  </HStack>
                  <HStack space={1} pl={1}>
                    <Text size="sm" fontWeight={600} color="gray.700" lineHeight="xs">
                      {'\u2022'}
                    </Text>
                    <Text size="sm" fontWeight={600} color="gray.700" lineHeight="xs">
                      The 'Last Order Transaction' time-stamp shows the last order provided by the Vendor.
                    </Text>
                  </HStack>
                </VStack>
              </Box>

              <Box>
                <Text size="sm" fontWeight={700} color="black">
                  Support:
                </Text>

                <Text size="sm" fontWeight={600} color="gray.700" lineHeight="xs">
                  If the{' '}
                  <Text size="sm" fontWeight={700} color="gray.700">
                    Last Order Recorded
                  </Text>{' '}
                  date is outdated, please check the vendor’s website before contacting opsbee support
                </Text>
              </Box>
            </VStack>
          </Popover.Body>
        </Popover.Content>
      </Popover>
    </HStack>
  );
};
