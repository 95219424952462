import { Icon, IIconProps } from 'native-base';
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg';

export const CurrencyDollarCircle = ({ size = 'sm', ...props }: IIconProps) => {
  return (
    <Icon fill="white" size={size} strokeWidth="0" viewBox="0 0 20 20" {...props}>
      <G clipPath="url(#clip0_12222_4358)" fill="white">
        <Path
          d="M7.08329 12.2222C7.08329 13.2961 7.95385 14.1667 9.02774 14.1667H10.8333C11.9839 14.1667 12.9166 13.2339 12.9166 12.0833C12.9166 10.9327 11.9839 10 10.8333 10H9.16663C8.01603 10 7.08329 9.06727 7.08329 7.91667C7.08329 6.76608 8.01603 5.83334 9.16663 5.83334H10.9722C12.0461 5.83334 12.9166 6.7039 12.9166 7.77778M9.99996 4.58334V5.83334M9.99996 14.1667V15.4167M18.3333 10C18.3333 14.6024 14.6023 18.3333 9.99996 18.3333C5.39759 18.3333 1.66663 14.6024 1.66663 10C1.66663 5.39763 5.39759 1.66667 9.99996 1.66667C14.6023 1.66667 18.3333 5.39763 18.3333 10Z"
          stroke="currentColor"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_12222_4358">
          <Rect width="20" height="20" fill="white" />
        </ClipPath>
      </Defs>
    </Icon>
  );
};
