import { useState } from 'react';
import { Box, HStack, IBoxProps, useTheme, VStack } from 'native-base';
import { map, partition } from 'lodash';

import { ButtonGroup, Text } from '@pimm/base';
import { GoalProfileDto, SiteGoalDto, TimerDataDetailDto } from '@pimm/services/lib/sms-drivethru/types';
import { hexToRGBA } from '@app/utils/string-formatter';

type CarPerformanceProps = {
  _container?: IBoxProps;
  profiles: GoalProfileDto[];
  goal?: SiteGoalDto;
  timerDetails?: TimerDataDetailDto;
};

export const CarPerformance = ({ _container, goal, profiles, timerDetails }: CarPerformanceProps) => {
  const { colors } = useTheme();
  const [tabFocus, setTabFocus] = useState<'brand' | 'store'>('brand');
  const [storeGoals, brandGoals] = partition(timerDetails?.carPerformances, _ => _.isStoreGoal);

  return (
    <VStack space={2} w="full" {..._container}>
      <HStack space={4} alignItems="flex-start" justifyContent="space-between">
        <Box flex={1}>
          <Text size="xl" fontWeight={700} color="gray.900" numberOfLines={2} lineHeight="md">
            Order Performance
          </Text>
          <Text size="md" fontWeight={500} color="gray.600" numberOfLines={2} lineHeight="xs">
            # orders completed within the Lane Total goal time(s)
          </Text>
        </Box>

        <ButtonGroup value={tabFocus} onChange={setTabFocus}>
          <ButtonGroup.Item value="store">vs. Store</ButtonGroup.Item>
          <ButtonGroup.Item value="brand">vs. Brand</ButtonGroup.Item>
        </ButtonGroup>
      </HStack>

      <Box minHeight="100px" justifyContent="center">
        {tabFocus === 'store' ? (
          <Box rounded="xl" justifyContent="center" h="full" bg="gray.50">
            <Text style={{ fontSize: 38 }} fontWeight={700} color="black" textAlign="center">
              {`${storeGoals[0]?.totals?.achieved ?? 0}/${storeGoals[0]?.totals?.total ?? 0}`}{' '}
              <Text style={{ fontSize: 38 }} fontWeight={500} color="black" textAlign="center">
                {`(${storeGoals[0]?.totals?.ratioText?.replace(' ', '') ?? '0%'})`}
              </Text>
            </Text>
            <Text size="md" fontWeight={500} color="gray.700" textAlign="center">
              Met store goal ({goal?.total ?? 0}s)
            </Text>
          </Box>
        ) : (
          <HStack space={2} alignItems="center" justifyContent="center" h="full">
            {map(profiles, (profile, index) => {
              const _goal = brandGoals?.find(_ => _.goalProfileName === profile.name);
              return (
                <Box key={`car-performance-${tabFocus}.${profile.name}`} flex={1} justifyContent="center" h="full">
                  <Box
                    rounded="xl"
                    alignItems="center"
                    justifyContent="center"
                    py={2}
                    px={3}
                    minH="90%"
                    bg={hexToRGBA(profile?.color ?? colors.gray[400], 0.3)}
                  >
                    <Text size="5xl" fontWeight={700} color="black" lineHeight="xs">
                      {`${_goal?.totals?.achieved ?? 0}/${_goal?.totals?.total ?? 0}`}
                    </Text>
                    <Text size="lg" fontWeight={500} color="gray.900" lineHeight="xs">
                      {`(${_goal?.totals?.ratioText?.replace(' ', '') ?? '0%'})`}
                    </Text>
                    <Text size="md" fontWeight={600} color="gray.700" lineHeight="xs" mt={1} numberOfLines={2} textAlign="center">
                      Met {profile.name} ({profile.total ?? 0}s)
                    </Text>
                  </Box>
                </Box>
              );
            })}
          </HStack>
        )}
      </Box>
    </VStack>
  );
};
