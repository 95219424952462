import { QueryKey, UseQueryResult, useQuery } from 'react-query';

import moment from 'moment';

import { GetForecastAmounts } from '@pimm/services/lib/sms-workforce/services';

type GetForecastAmountsParams = {
  siteId?: string;
  startDate?: Date;
};

export const useGetForecastAmounts = (
  query?: GetForecastAmountsParams,
  queryKey?: string,
): UseQueryResult<Awaited<ReturnType<typeof GetForecastAmounts>>> => {
  const saleAmounts = useQuery({
    enabled: !!query?.siteId && !!query?.startDate,
    queryKey: [queryKey ?? 'GetForecastAmounts', query] as [QueryKey, GetForecastAmountsParams],
    queryFn: async ({ queryKey: [key, params] }) => {
      const startTime = moment(params.startDate);
      const result = await GetForecastAmounts({
        siteId: params!.siteId,
        startDate: startTime.format().slice(0, 19),
        endDate: startTime.add(1, 'day').format().slice(0, 19),
      });

      return result;
    },
  });
  return saleAmounts;
};
