import { useMemo } from 'react';
import { Box, HStack, IBoxProps, useTheme, VStack } from 'native-base';
import { map, range, sumBy } from 'lodash';

import { CheckCircle, Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { GoalProfileDto, SiteGoalDto, TimerDataDetailDto } from '@pimm/services/lib/sms-drivethru/types';
import { hexToRGBA } from '@app/utils/string-formatter';

type GuidelinesProps = {
  _container?: IBoxProps;
  goal?: SiteGoalDto;
  profiles: GoalProfileDto[];
  timerDetails?: TimerDataDetailDto;
};

export const Guidelines = ({ _container, goal, profiles, timerDetails }: GuidelinesProps) => {
  const { colors } = useTheme();
  const { translate } = useAppLocale();

  const guidelines = useMemo(() => {
    const greet = timerDetails?.avgGreetTime ?? 0;
    const menuTime = timerDetails?.avgMenuTime ?? 0;
    const pickupTime = timerDetails?.avgPickupTime ?? 0;
    const avgTotalTime = timerDetails?.avgTotalTime ?? 0;

    const items = [
      { label: 'Greet', dataField: 'greet', value: greet },
      { label: 'Menu Board', dataField: 'menuBoard', value: menuTime },
      { label: 'Pickup', dataField: 'pickup', value: pickupTime },
      { label: 'Lane Total', dataField: 'total', value: avgTotalTime },
    ].map(item => {
      const goals = ['Goal A', 'Goal B', 'Goal C'].map(name => {
        const _profiles = profiles?.filter(_ => _.name === name) ?? [];
        return {
          name: name,
          translations: _profiles[0]?.translations,
          color: _profiles[0]?.color,
          value: sumBy(_profiles, item.dataField),
          isNearest: false,
        };
      });

      if (!!timerDetails) {
        // Find the nearest goal
        const index = goals.findIndex(_ => item.value <= _.value);
        if (index != -1) {
          goals[index].isNearest = true;
        }
      }

      return { ...item, goals: goals };
    });
    return items;
  }, [goal, profiles, timerDetails]);

  return (
    <Box rounded="xl" py={3} px={4} w="full" borderWidth={1} bg="white" {..._container}>
      <Box>
        <Text size="xl" fontWeight={700} color="gray.900" numberOfLines={2} lineHeight="xl">
          Brand Goal Comparison
        </Text>
        <Text size="md" fontWeight={500} color="gray.600" numberOfLines={2} lineHeight="xs">
          Compare your store times to the Brand’s goal levels for each stage
        </Text>
      </Box>

      <Box mt={3} borderTopRadius="xl" overflow="hidden">
        <HStack bg="gray.300">
          {range(4).map(i => {
            const goal = i !== 0 ? guidelines?.[0].goals[i - 1] : undefined;
            return (
              <Box key={`title_${i}`} flex={1} px={2} minH={8} justifyContent="center">
                <Text size="sm" fontWeight={500} color="black" textAlign="center" lineHeight="xs" numberOfLines={1}>
                  {goal ? translate(goal.name, goal.translations) : 'Phase'}
                </Text>
              </Box>
            );
          })}
        </HStack>

        <VStack borderBottomRadius="xl" borderColor="gray.300" borderWidth={1} borderTopWidth={0} overflow="hidden">
          {map(guidelines, (item, i) => (
            <HStack key={item.dataField} alignItems="center" borderTopWidth={i ? 1 : 0} minH="40px">
              <Box flex={1} justifyContent="center" px={2} h="full" bg="white">
                <Text size="md" fontWeight={500} color="black" textAlign="center" lineHeight="xs" numberOfLines={1}>
                  {item?.label}
                </Text>
              </Box>
              {item.goals.map(_goal => {
                return (
                  <HStack
                    key={`guidelines.${item.dataField}.${_goal.name}}`}
                    flex={1}
                    alignItems="center"
                    justifyContent="center"
                    px={2}
                    h="full"
                    bg={hexToRGBA(_goal?.color ?? colors.gray[400], 0.3)}
                  >
                    {_goal.isNearest ? (
                      <HStack
                        rounded="sm"
                        flex="none"
                        space={1}
                        alignItems="center"
                        justifyContent="center"
                        px={1}
                        h={6}
                        bg={_goal?.color ?? 'gray.300'}
                      >
                        <CheckCircle size="14px" color="black" />
                        <Text size="md" fontWeight={500} color="black" textAlign="center" lineHeight="xs">
                          {_goal?.value ?? 0}
                        </Text>
                      </HStack>
                    ) : (
                      <Text size="md" fontWeight={500} color="black" textAlign="center" lineHeight="xs">
                        {_goal?.value ?? 0}
                      </Text>
                    )}
                  </HStack>
                );
              })}
            </HStack>
          ))}
        </VStack>
      </Box>
    </Box>
  );
};
