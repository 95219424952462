import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';

import { LinkedInstancesDto } from '@pimm/services/lib/store-equipment';
import { useGetDisplayGroups } from '../hooks';
import { find } from 'lodash';

export interface EquipmentFocusContextReturn {
  linkInstanceFocus?: LinkedInstancesDto;
  changeInstance: (_instance: LinkedInstancesDto) => void;
}

export const EquipmentFocusContext = createContext<EquipmentFocusContextReturn>(undefined!);

export type EquipmentFocusProviderProps = PropsWithChildren & {
  displayGroups: ReturnType<typeof useGetDisplayGroups>;
};

export const EquipmentFocusProvider = ({ children, displayGroups }: EquipmentFocusProviderProps) => {
  const [linkInstanceFocus, setLinkInstanceFocus] = useState<LinkedInstancesDto>();
  return (
    <EquipmentFocusContext.Provider value={{ linkInstanceFocus, changeInstance: setLinkInstanceFocus }}>
      {children}
    </EquipmentFocusContext.Provider>
  );
};

export const EquipmentFocusConsumer = EquipmentFocusContext.Consumer;

export const useEquipmentFocus = () => useContext(EquipmentFocusContext);
