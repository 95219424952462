import React, { createContext, useCallback, useContext, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { map } from 'lodash';

import { UpdateStoreMessageStatusRequest } from '@pimm/services/lib/message-board';
import { GetStoreMessages } from '@pimm/services/lib/message-board/services';
import { useSiteTime } from '@app/features/store-core';
import { useGetStoreMessages } from '../hooks/useGetStoreMessages';

export interface MessageBoardContextReturn {
  messages: ReturnType<typeof useGetStoreMessages>[0];
  updateMessageData: (messageStatusRequest: UpdateStoreMessageStatusRequest) => void;
}

export const MessageBoardContext = createContext<MessageBoardContextReturn>(undefined!);

type MessageBoardProviderProps = { children: React.ReactNode; siteId?: string };

export const MessageBoardProvider = ({ children, siteId }: MessageBoardProviderProps) => {
  const queryClient = useQueryClient();
  const siteTime = useSiteTime();
  const [messages, setMessagesParams] = useGetStoreMessages();

  useEffect(() => {
    if (siteId) {
      const today = siteTime.today();
      const startDate = new Date(today.getTime())
      
      // TODO: need to update and change it to 1 or 3 months and do a paginated request
      // Subract 1 year from the current date to get 1 year record
      startDate.setFullYear(startDate.getFullYear() - 1);

      setMessagesParams({ storeId: siteId, startDate: startDate, endDate: today });
    }
  }, [siteId]);

  const updateMessageData = useCallback((messageStatusRequest: UpdateStoreMessageStatusRequest) => {
    // Update message from the current list
    queryClient.setQueryData<Awaited<ReturnType<typeof GetStoreMessages>>>(['GetStoreMessages', messages.queryParams], messages =>
      map(messages, msg => {
        return msg.id === messageStatusRequest.id ? { ...msg, status: messageStatusRequest.status } : msg;
      }),
    );
  },[messages.queryParams]);

  return <MessageBoardContext.Provider value={{ messages, updateMessageData }}>{children}</MessageBoardContext.Provider>;
};

export const MessageBoardConsumer = MessageBoardContext.Consumer;

export const useMessageBoard = () => {
  // get the context
  const context = useContext(MessageBoardContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useMessageBoard was used outside of its Provider');
  }
  return context;
};