import { QueryKey, UseQueryResult, useQuery } from 'react-query';

import { GetHardwareConfig } from '@pimm/services/lib/store-equipment/services';

export const useGetHardwareConfig = (siteId?: string, queryKey?: string): UseQueryResult<Awaited<ReturnType<typeof GetHardwareConfig>>> => {
  const hardwareConfig = useQuery({
    enabled: !!siteId,
    queryKey: [queryKey ?? 'GetHardwareConfig', siteId] as [QueryKey, string],
    queryFn: ({ queryKey: [key, siteId] }) => GetHardwareConfig(siteId),
  });
  return hardwareConfig;
};
