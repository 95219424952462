import { Box, HStack, IconButton, Spacer, VStack, useTheme } from 'native-base';
import FeatherIcons from 'react-native-vector-icons/Feather';
import { map, size } from 'lodash';

import { Button, Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { WeeklyFocusItemDto, WeeklyFocusItemInput } from '@pimm/services/lib/sms-workforce';
import { WeeklyFocusCategoryDto } from '@pimm/services/lib/sms-positioning';

type WeeklyFocusItemBoxProps = {
  category: WeeklyFocusCategoryDto;
  goalLimit?: number;
  items?: WeeklyFocusItemInput[];
  isEditable?: boolean;
  isReadOnly?: boolean;
  onPressAddUpdate: (category: WeeklyFocusCategoryDto, input?: WeeklyFocusItemInput) => void;
  onPressRemove: (categoryId: number, item?: WeeklyFocusItemDto) => void;
};

export const WeeklyFocusItemBox = ({ category, isEditable, items, isReadOnly, ...props }: WeeklyFocusItemBoxProps) => {
  const { colors } = useTheme();
  const { translate } = useAppLocale();
  const isEmpty = size(items) === 0;
  const goalLimit = props.goalLimit ?? 1;
  const canAddNewGoal = isEditable && size(items) < goalLimit;

  return (
    <Box borderWidth={1} borderColor="gray.300" bg="white" rounded="lg">
      <HStack space={2} alignItems="center" pl={4} pr={2} minH={canAddNewGoal ? 12 : 9}>
        <Box justifyContent="center" h={6} w={6} bg={isEmpty ? 'gray.100' : 'primary.100'} rounded="lg">
          <Text size="md" fontWeight={700} color="black" lineHeight="xs" textAlign="center">
            {category.seq}
          </Text>
        </Box>
        <Text size="xl" fontWeight={700} color="black" lineHeight="xs" textTransform="uppercase">
          {translate(category.title, category.translations)}
        </Text>
        <Spacer />
        {canAddNewGoal && (
          <Button
            size="sm"
            borderWidth={1}
            minH={8}
            rounded="md"
            disabled={isReadOnly}
            background="primary.25"
            _text={{ color: 'primary.600' }}
            _disabled={{ bgColor: 'gray.50', borderColor: 'gray.50', _text: { color: 'gray.500' } }}
            onPress={() => props.onPressAddUpdate(category)}
          >
            Add Goal
          </Button>
        )}

        {goalLimit > 1 && isEditable && !isEmpty && (
          <Button alternate outline minH={8} disabled={isReadOnly} onPress={() => props.onPressRemove(category.id!)}>
            Remove all
          </Button>
        )}
      </HStack>
      {!isEmpty && (
        <VStack space={2} py={1} pl={4} pr={2} borderTopWidth={1}>
          {map(items, (item, index) => (
            <HStack space={3} key={index} alignItems="center" minH={9}>
              <Text size="md" fontWeight={500} color="gray.900" lineHeight="xs">
                {item.description ?? ''}
              </Text>
              <Spacer />
              {!!isEditable && (
                // Trash Icon
                <IconButton
                  h={8}
                  w={8}
                  borderWidth={1}
                  borderColor="gray.300"
                  rounded="md"
                  disabled={isReadOnly}
                  onPress={() => props.onPressRemove(category.id!, item)}
                  icon={<FeatherIcons name="trash-2" size={16} color={colors.gray[700]} />}
                />
              )}
            </HStack>
          ))}
        </VStack>
      )}
    </Box>
  );
};
