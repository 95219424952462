import { Icon, IIconProps } from 'native-base';
import { Defs, G, Path, Rect } from 'react-native-svg';

export const WidgetBoxIcon = ({ size = 'sm', ...props }: IIconProps) => {
  return (
    <Icon viewBox="0 0 37 38" size={size} fill="none" {...props}>
      <G filter="url(#filter0_dd_12981_50905)">
        <Rect x="2.74316" y="2.27563" width="32" height="32" rx="5.81818" fill="white" />
        <Rect x="3.24316" y="2.77563" width="31" height="31" rx="5.31818" stroke="#D8D9DA" />
        <Path
          d="M17.0911 12.8184H14.7881C14.4486 12.8184 14.2789 12.8184 14.1493 12.8845C14.0352 12.9426 13.9425 13.0353 13.8844 13.1493C13.8184 13.279 13.8184 13.4487 13.8184 13.7881V16.0911C13.8184 16.4306 13.8184 16.6003 13.8844 16.7299C13.9425 16.844 14.0352 16.9367 14.1493 16.9948C14.2789 17.0608 14.4486 17.0608 14.7881 17.0608H17.0911C17.4305 17.0608 17.6002 17.0608 17.7299 16.9948C17.8439 16.9367 17.9366 16.844 17.9947 16.7299C18.0608 16.6003 18.0608 16.4306 18.0608 16.0911V13.7881C18.0608 13.4487 18.0608 13.279 17.9947 13.1493C17.9366 13.0353 17.8439 12.9426 17.7299 12.8845C17.6002 12.8184 17.4305 12.8184 17.0911 12.8184Z"
          stroke="#5B5B5B"
          strokeWidth="1.21212"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <Path
          d="M23.7578 12.8184H21.4547C21.1153 12.8184 20.9456 12.8184 20.8159 12.8845C20.7019 12.9426 20.6092 13.0353 20.5511 13.1493C20.485 13.279 20.485 13.4487 20.485 13.7881V16.0911C20.485 16.4306 20.485 16.6003 20.5511 16.7299C20.6092 16.844 20.7019 16.9367 20.8159 16.9948C20.9456 17.0608 21.1153 17.0608 21.4547 17.0608H23.7578C24.0972 17.0608 24.2669 17.0608 24.3965 16.9948C24.5106 16.9367 24.6033 16.844 24.6614 16.7299C24.7275 16.6003 24.7275 16.4306 24.7275 16.0911V13.7881C24.7275 13.4487 24.7275 13.279 24.6614 13.1493C24.6033 13.0353 24.5106 12.9426 24.3965 12.8845C24.2669 12.8184 24.0972 12.8184 23.7578 12.8184Z"
          stroke="#5B5B5B"
          strokeWidth="1.21212"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M23.7578 19.4851H21.4547C21.1153 19.4851 20.9456 19.4851 20.8159 19.5511C20.7019 19.6092 20.6092 19.702 20.5511 19.816C20.485 19.9456 20.485 20.1154 20.485 20.4548V22.7578C20.485 23.0972 20.485 23.267 20.5511 23.3966C20.6092 23.5106 20.7019 23.6033 20.8159 23.6615C20.9456 23.7275 21.1153 23.7275 21.4547 23.7275H23.7578C24.0972 23.7275 24.2669 23.7275 24.3965 23.6615C24.5106 23.6033 24.6033 23.5106 24.6614 23.3966C24.7275 23.267 24.7275 23.0972 24.7275 22.7578V20.4548C24.7275 20.1154 24.7275 19.9456 24.6614 19.816C24.6033 19.702 24.5106 19.6092 24.3965 19.5511C24.2669 19.4851 24.0972 19.4851 23.7578 19.4851Z"
          stroke="#5B5B5B"
          strokeWidth="1.21212"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <Path
          d="M17.0911 19.4851H14.7881C14.4486 19.4851 14.2789 19.4851 14.1493 19.5511C14.0352 19.6092 13.9425 19.702 13.8844 19.816C13.8184 19.9456 13.8184 20.1154 13.8184 20.4548V22.7578C13.8184 23.0972 13.8184 23.267 13.8844 23.3966C13.9425 23.5106 14.0352 23.6033 14.1493 23.6615C14.2789 23.7275 14.4486 23.7275 14.7881 23.7275H17.0911C17.4305 23.7275 17.6002 23.7275 17.7299 23.6615C17.8439 23.6033 17.9366 23.5106 17.9947 23.3966C18.0608 23.267 18.0608 23.0972 18.0608 22.7578V20.4548C18.0608 20.1154 18.0608 19.9456 17.9947 19.816C17.9366 19.702 17.8439 19.6092 17.7299 19.5511C17.6002 19.4851 17.4305 19.4851 17.0911 19.4851Z"
          stroke="#5B5B5B"
          strokeWidth="1.21212"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </G>
      <Defs>
        <filter
          id="filter0_dd_12981_50905"
          x="0.561346"
          y="0.821089"
          width="36.3636"
          height="36.3636"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
          fill="none"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="0.727273" />
          <feGaussianBlur stdDeviation="0.727273" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_12981_50905" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="0.727273" />
          <feGaussianBlur stdDeviation="1.09091" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_12981_50905" result="effect2_dropShadow_12981_50905" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_12981_50905" result="shape" />
        </filter>
      </Defs>
    </Icon>
  );
};
