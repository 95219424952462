import { useMemo } from 'react';
import { Flex } from 'native-base';
import { chain, filter, find, first, map, range, round, sumBy } from 'lodash';
import moment from 'moment';

import { CleaningInsightDto } from '@pimm/services/lib/sms-workforce';
import { useAppLocale } from '@pimm/common';
import { formatToISOString } from '@app/utils/date-formatter';
import { ProgressCircleCard } from './progress-circle-card';
import { ProgressBarCard } from './progress-bar-card';

type CleaningTasksProps = {
  isWeekly?: boolean;
  startOfWeek: Date;
  cleaningInsights?: CleaningInsightDto[];
};

export const CleaningTasks = ({ isWeekly, startOfWeek, cleaningInsights }: CleaningTasksProps) => {
  const { translate } = useAppLocale();

  const memoCleaningInsights = useMemo(() => {
    return chain(cleaningInsights)
      .groupBy('category')
      .map((tasks, category) => ({
        category,
        tasks: isWeekly ? tasks : filter(tasks, task => !!task.date && task.date.startsWith(formatToISOString(startOfWeek, true))),
      }))
      .value();
  }, [cleaningInsights, isWeekly, startOfWeek]);

  return (
    <Flex flexDirection="row" flexWrap="wrap">
      {memoCleaningInsights.map((_cleaning, index) => {
        const accessorKey = `dashboard-utilization.cleaning.${isWeekly ? 'weekly' : 'daily'}.${index}`;
        const isEven = index % 2 == 0;
        const categoryTitle = _cleaning?.category?.toLowerCase().includes('daypart')
          ? `Daily - ${translate(_cleaning.category, first(_cleaning.tasks)?.categoryTranslation)}`
          : `${translate(_cleaning.category, first(_cleaning.tasks)?.categoryTranslation)}`;

        if (isWeekly && index < 2) {
          const weeklySummary = map(range(7), day => {
            const currentDate = moment(startOfWeek).add(day, 'days');

            const task = find(_cleaning?.tasks, _task => _task.date?.startsWith(currentDate.format('YYYY-MM-DD'))) as CleaningInsightDto;

            return {
              day: currentDate.format('ddd'),
              completion: task?.completedTasksCount && task?.totalTaskCount ? (task?.completedTasksCount / task?.totalTaskCount) * 100 : 0,
            };
          });

          return (
            <ProgressBarCard
              key={accessorKey}
              _container={{ pb: 3, pr: isEven ? 1.5 : 0, pl: !isEven ? 1.5 : 0, w: '1/2' }}
              title={categoryTitle}
              weeklySummary={weeklySummary}
            />
          );
        }
        return (
          <ProgressCircleCard
            key={accessorKey}
            _container={{ pb: 3, pr: isEven ? 1.5 : 0, pl: !isEven ? 1.5 : 0, w: '1/2' }}
            title={categoryTitle}
            total={round(
              sumBy(_cleaning.tasks, task => (isWeekly ? (task.totalTaskCount ?? 0) / _cleaning.tasks?.length : task.totalTaskCount ?? 0)),
            )}
            value={round(
              sumBy(_cleaning.tasks, task =>
                isWeekly ? (task.completedTasksCount ?? 0) / _cleaning.tasks?.length : task.completedTasksCount ?? 0,
              ),
            )}
          />
        );
      })}
    </Flex>
  );
};
