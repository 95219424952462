import { useMemo, useState } from 'react';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryGroup, VictoryLabel } from 'victory';
import { Box, HStack, useMediaQuery, useTheme, VStack } from 'native-base';
import FontAwesomeIcon from 'react-native-vector-icons/FontAwesome';
import { filter, first, flatMap, range, sumBy } from 'lodash';
import moment from 'moment';

import { Text } from '@pimm/base';
import { DaypartSales } from '../types';

type SalesChartProps = {
  daypartSales: DaypartSales[];
};

export const SalesChart = ({ daypartSales }: SalesChartProps) => {
  const { colors } = useTheme();
  const [isSmallScreen] = useMediaQuery({ maxWidth: 1200 });
  const [legends] = useState<{ dataField: string; label: string; color: string }[]>([
    { dataField: 'actual', label: 'Actual Sales', color: '#016aa2' },
    { dataField: 'forecast', label: 'Forecast', color: '#b9e6fe' },
  ]);
  const [gridLayout, setGridLayout] = useState<{
    width: number;
    height: number;
  }>();

  const chartData = useMemo(() => {
    const startOfDay = first(daypartSales)?.startTime;
    const sales = flatMap(daypartSales, _ => _.sales);
    if (startOfDay) {
      const tickValues = range(24).map(hr => new Date(startOfDay.getTime() + 3600 * 1000 * hr));
      const datasets = tickValues.map(time => {
        const endTime = new Date(time.getTime() + 60 * 60 * 1000);
        const hourlySales = filter(sales, sale => sale && sale.time >= time && sale.time < endTime);

        return {
          time: time.getTime(),
          actual: sumBy(hourlySales, 'actual'),
          forecast: sumBy(hourlySales, 'forecast'),
        };
      });

      return {
        datasets,
        tickValues: tickValues.map(_ => _.getTime()),
      };
    }
    return undefined;
  }, [daypartSales]);

  const handleLayoutChange = ({ nativeEvent: { layout } }) => {
    setGridLayout({
      width: layout.width || 0,
      height: layout.height || 0,
    });
  };

  return (
    <VStack position="relative" w="full" h="full">
      <HStack position="absolute" right={0} maxHeight={10}>
        <HStack alignItems="center" space={2}>
          {legends.map(legend => (
            <HStack key={legend.label} space={2} alignItems="center">
              <FontAwesomeIcon name="circle" size={isSmallScreen ? 10 : 12} color={legend.color} />
              <Text size={{ md: 'xs', xl: 'sm' }} color="gray.700">
                {legend.label}
              </Text>
            </HStack>
          ))}
        </HStack>
      </HStack>
      <Box w="full" flex={1} onLayout={handleLayoutChange}>
        {!!gridLayout?.width && (
          <VictoryChart
            width={gridLayout?.width}
            height={gridLayout?.height}
            padding={{ top: 40, left: 60, bottom: 40, right: 0 }}
            domainPadding={{ x: [60, 60] }}
            // domain={{ x: [0, 24] }}
            theme={{
              chart: {},
              axis: {
                style: {
                  grid: { stroke: 0 },
                  axisLabel: {
                    fontFamily: "'Inter', sans-serif",
                    fontWeight: 600,
                    fontSize: isSmallScreen ? 10 : 12,
                  },
                  tickLabels: {
                    paddingTop: 10,
                    fontFamily: "'Inter', sans-serif",
                    fontWeight: 500,
                    fontSize: isSmallScreen ? 8 : 10,
                  },
                },
              },
            }}
          >
            <VictoryAxis
              scale={{ x: 'time' }}
              tickValues={chartData?.tickValues}
              tickFormat={tick => moment(tick).format('ha')}
              style={{
                grid: { stroke: 'transparent' },
                ticks: { stroke: 'transparent', size: 7 },
                tickLabels: { textAlign: 'center' },
              }}
            />

            <VictoryAxis
              dependentAxis
              tickFormat={t => (Number.isInteger(t) ? t : null)}
              tickCount={6}
              label="Sales ($)"
              labelComponent={<VictoryLabel style={{ top: 20, fontSize: isSmallScreen ? 12 : 14 }} />}
              style={{
                axis: { stroke: 'transparent' },
                grid: { stroke: colors.gray[100], strokeWidth: 1 },
                ticks: { stroke: 'transparent', size: 5 },
                tickLabels: { stroke: 'transparent' },
              }}
            />

            <VictoryGroup>
              <VictoryBar
                style={{ data: { border: 'none', width: isSmallScreen ? 28 : 40, fill: '#b9e6fe' } }}
                data={chartData?.datasets}
                x="time"
                y="forecast"
              />
              <VictoryBar
                style={{ data: { border: 'none', width: isSmallScreen ? 18 : 30, fill: '#016aa2' } }}
                data={chartData?.datasets}
                x="time"
                y="actual"
              />
            </VictoryGroup>
          </VictoryChart>
        )}
      </Box>
    </VStack>
  );
};
