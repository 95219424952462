import { createElement, useState } from 'react';
import { useMutation } from 'react-query';
import { useBoolean } from 'usehooks-ts';
import { Alert, Box, ChevronDownIcon, HStack, IIconProps, Popover, Pressable, Spinner, VStack, useTheme, useToast } from 'native-base';
import { reduce, some } from 'lodash';

import { RefreshIcon, Text, ZapFastIcon, ZapIcon } from '@pimm/base';
import { SmsWorkforceApi } from '@pimm/services/lib/sms-workforce';
import { formatToISOString, stringToDateLocal } from '@app/utils/date-formatter';
import { DayBlock, useSiteTime } from '@app/features/store-core';
import { useKitchenPositioning } from '../context';
import { useGetPositionScheduleLive } from '../hooks';

enum AutoAssignEvent {
  CurrentTab,
  AllTabs,
  Reset,
}

type PressableAutoAssignProps = {
  dayBlock: DayBlock;
  liveSchedules: ReturnType<typeof useGetPositionScheduleLive>[0];
  siteId: string;
};

export const PressableAutoAssign = ({ dayBlock, liveSchedules, siteId }: PressableAutoAssignProps) => {
  const { colors } = useTheme();
  const toast = useToast();
  const siteTime = useSiteTime();
  const isOpenDropdown = useBoolean();
  const { positioning } = useKitchenPositioning();
  const [menuItems] = useState<{ type: AutoAssignEvent; icon: React.ElementType; isDisabled?: boolean; label: string }[]>([
    { type: AutoAssignEvent.CurrentTab, icon: ZapIcon, label: 'Auto Assign Current Tab' },
    { type: AutoAssignEvent.AllTabs, icon: ZapFastIcon, label: 'Auto Assign All Tabs', isDisabled: true },
    { type: AutoAssignEvent.Reset, icon: RefreshIcon, label: 'Reset Current Tab' },
  ]);

  const confirmAndAssignGroup = useMutation({
    mutationFn: SmsWorkforceApi.ConfirmAndAssignGroup,
    onSuccess: () => {
      liveSchedules.refetch();
    },
    onError: () => {
      return toast.show({
        placement: 'top',
        render: () => (
          <Alert maxWidth="100%" alignSelf="center" flexDirection="row" status="error" variant="subtle">
            <VStack space={1} flexShrink={1} w="100%">
              <HStack space={2} flexShrink={1} alignItems="center">
                <Alert.Icon size="md" />
                <Box>
                  <Text size="lg" flexShrink={1} fontWeight={700} color="black" lineHeight="xs">
                    Confirmation Failed
                  </Text>
                  <Text size="md" color="gray.900">
                    Sorry, something went wrong. Please try again later.
                  </Text>
                </Box>
              </HStack>
            </VStack>
          </Alert>
        ),
      });
    },
  });

  const resetConfirmAndAssignByDayBlock = useMutation({
    mutationFn: SmsWorkforceApi.ResetConfirmAndAssignByDayBlock,
    onSuccess: () => {
      liveSchedules.refetch();
    },
  });

  const resetDayblock = useMutation({
    mutationFn: SmsWorkforceApi.ResetPositionGroup,
    onSuccess: () => {
      liveSchedules.refetch();
    },
  });

  const isLoading = confirmAndAssignGroup.isLoading || resetConfirmAndAssignByDayBlock.isLoading || resetDayblock.isLoading;
  const isLocked = liveSchedules.data?.positionGroup?.isLocked;

  const handlePressAction = (type: AutoAssignEvent) => async () => {
    let confirmDateTime = dayBlock.startTime;
    const today = siteTime.today();
    const subBlockDateTime = stringToDateLocal(liveSchedules.data?.positionGroup?.subBlockTime);
    const nonServiceEmployees = reduce(
      positioning.employees,
      (employeeIds: string[], { employeeId, timeslots }) => {
        if (some(timeslots, _ => _.isNonService)) return [...employeeIds, employeeId];
        return employeeIds;
      },
      [],
    );

    // Check if today, use the current time when making a POST to the confirm endpoint.
    if (today >= dayBlock.startTime && today < dayBlock.endTime) confirmDateTime = today;

    // confirmDateTime should be equal to the subBlockDateTime for all suBlocks set in the future.
    if (subBlockDateTime && confirmDateTime < subBlockDateTime) {
      confirmDateTime = subBlockDateTime;
    }

    if (type === AutoAssignEvent.CurrentTab) {
      // Confirm and auto-assign
      confirmAndAssignGroup.mutate({
        confirmDateTime: formatToISOString(confirmDateTime),
        siteId: siteId,
        isLocked: true,
        nonServiceEmployeeIds: nonServiceEmployees,
        subBlockDateTime: subBlockDateTime ? formatToISOString(subBlockDateTime) : undefined,
      });
    } else if (type === AutoAssignEvent.AllTabs) {
      // Reset and confirm all tabs
      resetConfirmAndAssignByDayBlock.mutate({
        currentDateTime: formatToISOString(siteTime.today()),
        siteId: siteId
      })
    } else if (type === AutoAssignEvent.Reset) {
      // Reset position group
      resetDayblock.mutate({ positionGroupId: liveSchedules.data?.positionGroup?.id });
    }
  };

  return (
    <Popover
      isKeyboardDismissable
      offset={5}
      isOpen={isOpenDropdown.value}
      onClose={isOpenDropdown.setFalse}
      trigger={triggerProps => (
        <Pressable
          disabled={isLoading}
          rounded="lg"
          h={9}
          borderWidth={1}
          borderColor="blueLight.600"
          onPress={handlePressAction(AutoAssignEvent.CurrentTab)}
        >
          {({ isHovered }) => {
            return (
              <HStack rounded="lg" alignItems="center" h="full" bg={isHovered ? 'blueLight.100' : 'blueLight.50'}>
                <HStack
                  space={1}
                  alignItems="center"
                  justifyContent="center"
                  px={2}
                  h="full"
                  minW="60px"
                  borderRightWidth={1}
                  borderRightColor="blueLight.600"
                >
                  <ZapIcon size={5} color={colors.blueLight[600]} />
                  <Text size="md" fontWeight={600} color="blueLight.600">
                    Auto Assign
                  </Text>
                </HStack>
                <Pressable
                  alignItems="center"
                  justifyContent="center"
                  px={1}
                  minW="36px"
                  h="full"
                  {...triggerProps}
                  disabled={isLoading}
                  onPress={isOpenDropdown.setTrue}
                >
                  {isLoading ? <Spinner size={16} color="blueLight.600" /> : <ChevronDownIcon size="14px" color="blueLight.600" />}
                </Pressable>
              </HStack>
            );
          }}
        </Pressable>
      )}
    >
      <Popover.Content accessibilityLabel="Account Dropdown" mr={3} borderWidth={0} shadow="7">
        <Popover.Body p={0} w="full" bgColor="white" minWidth="200px">
          <Box bgColor="gray.25">
            {menuItems.map(menu => {
              const isDisabled =
                menu.isDisabled || (menu.type !== AutoAssignEvent.Reset && isLocked) || (menu.type === AutoAssignEvent.Reset && !isLocked);
              return (
                <Pressable key={menu.label} isDisabled={isDisabled} onPress={handlePressAction(menu.type)}>
                  {({ isHovered }) => {
                    const color = isDisabled ? colors.gray[400] : isHovered ? colors.black : colors.gray[800];
                    return (
                      <HStack space={1} alignItems="center" py={1} px={3} minH={10} bg={isHovered ? 'gray.50' : undefined}>
                        {createElement<IIconProps>(menu.icon, { color: color, size: '16px' })}
                        <Text size="md" fontWeight={isHovered ? 600 : 500} color={color} textTransform="capitalize">
                          {menu.label}
                        </Text>
                      </HStack>
                    );
                  }}
                </Pressable>
              );
            })}
          </Box>
        </Popover.Body>
      </Popover.Content>
    </Popover>
  );
};
