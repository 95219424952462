import { useState } from 'react';
import { Box, HStack, VStack, SectionList } from 'native-base';
import moment from 'moment';
import { get, range } from 'lodash';

import { Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { formatToTimeOnly } from '@app/utils/date-formatter';
import { useSiteTime } from '@app/features/store-core';

type StoreHoursTableProps = {
  sections: { title: string; data: { title: string; dataFields: string[] }[] }[];
};

const StoreHoursTable = ({ sections }: StoreHoursTableProps) => {
  const { locale } = useAppLocale();
  const siteTime = useSiteTime();
  const week = siteTime.toStartEndOfWeek();
  const [weekDates] = useState<Date[]>(range(7).map(day => moment(week.startDate).add(day, 'day').toDate()));

  return (
    <SectionList
      sections={sections}
      extraData={[locale, weekDates]}
      scrollEnabled
      showsVerticalScrollIndicator
      h="full"
      w="full"
      mt={0}
      px={4}
      renderSectionHeader={({ section }) => {
        return (
          <Box flex={1}>
            <HStack
              justifyContent="space-between"
              alignItems="center"
              pb={3}
              my={{ md: 3, xl: 5 }}
              space={2}
              borderBottomWidth={1}
              borderBottomColor="gray.300"
            >
              <Text size={{ md: 'md', xl: 'xl' }} color="black" fontWeight={600} lineHeight="sm">
                {section.title}
              </Text>
            </HStack>

            <HStack alignItems="center" height={{ md: '30px', xl: '40px' }}>
              <Box justifyContent="center" w={{ md: '15%', xl: '12%' }}></Box>
              <HStack flex={1} h="full" alignItems="center">
                {weekDates.map((day, i) => {
                  return (
                    <Box
                      key={`${section.title}[${day.toISOString()}]`}
                      flex={1}
                      h="full"
                      justifyContent="center"
                      bg={i % 2 === 0 ? 'gray.50' : 'white'}
                    >
                      <Text size={{ md: 'sm', xl: 'lg' }} fontWeight={600} textAlign="center" color="black">
                        {moment(day).format('dddd')}
                      </Text>
                    </Box>
                  );
                })}
              </HStack>
            </HStack>
          </Box>
        );
      }}
      renderItem={({ item }) => {
        return (
          <HStack alignItems="center" flex={1}>
            <Box justifyContent="center" w={{ md: '15%', xl: '12%' }}>
              <Text
                size={{ md: 'sm', xl: 'lg' }}
                fontWeight={500}
                color="black"
                lineHeight="sm"
                textTransform="capitalize"
                numberOfLines={2}
                ellipsizeMode="tail"
              >
                {item?.title}
              </Text>
            </Box>

            <HStack flex={1}>
              {weekDates.map((day, i) => {
                const storeHours = siteTime.toOperationHours(day);
                return (
                  <HStack
                    key={`${item.title}[${day.toISOString()}]`}
                    flex={1}
                    height={{ md: '40px', xl: '50px' }}
                    bg={i % 2 === 0 ? 'gray.50' : 'white'}
                  >
                    {item.dataFields.map((field, index) => {
                      const value = get(storeHours, field);

                      return (
                        <Box
                          key={`${item.title}[${day.toISOString()}]][${field}}]`}
                          w={item.dataFields.length > 1 ? '1/2' : 'full'}
                          justifyContent="center"
                          borderColor="gray.300"
                          borderTopWidth={1}
                          alignItems="center"
                          py={2}
                        >
                          {typeof value === 'boolean' && (
                            <Text textAlign="center" fontWeight={600} color="black" size={{ md: 'sm', xl: 'md' }}>
                              {value === true ? 'Yes' : 'No'}
                            </Text>
                          )}
                          {typeof value === 'object' && (
                            <VStack justifyContent="center" alignItems="flex-start">
                              <Text textAlign="left" color="gray.600" fontWeight={500} size={{ md: 'xs', xl: 'md' }} lineHeight="sm">
                                {index === 0 ? 'Open' : 'Close'}
                              </Text>
                              <Text textAlign="left" lineHeight="sm" fontWeight={600} color="black" size={{ md: 'xs', xl: 'md' }}>
                                {value ? formatToTimeOnly(value) : '--'}
                              </Text>
                              {/* TODO: will enable: waiting for further instruction */}
                              {/* {index === 1 && (
                                <MaterialCommunityIcons
                                  name="clock-plus-outline"
                                  size={15}
                                  color={colors.blue[500]}
                                  style={{ position: 'absolute', right: -18, bottom: 5 }}
                                />
                              )} */}
                            </VStack>
                          )}
                        </Box>
                      );
                    })}
                  </HStack>
                );
              })}
            </HStack>
          </HStack>
        );
      }}
    />
  );
};

export default StoreHoursTable;
